<template>
  <div v-if="hasPermission(Admin.qrTicket)">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'company'"
              v-bind:label="'Company'"
              v-bind:defaultOption="'--select company--'"
              v-model="pagination.company"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <label class="text-white">Trip Code</label>
          <input
              v-model="pagination.tripCode"
              placeholder="Trip Code"
              type="text"
              autocomplete="on"
              class="form-control font_detail"
              maxlength="255"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DatePickerFilter
              :id="'datePickerDate'"
              v-model="pagination.date"
              :label="'Date'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            pagination.company &&
            qrTicketsList?.docs?.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Payment_Settlement_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="pagination.company === '' && selectCompany"
    >
      Please,Select a
      <a href="#" class="alert-link">company</a> from dropdown
    </div>
    <Table
        v-if="
        pagination.company !== '' &&
        filterActive &&
        qrTicketsList?.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">PNR CODE</td>
          <td class="font_detail">TRIP TIME & DATE</td>
          <td class="font_detail">COACH</td>
          <td class="font_detail">ROUTE</td>
          <td class="font_detail">BOARDING PLACE</td>
          <td class="font_detail">DROPPING PLACES</td>
          <td class="font_detail">SEAT & FARE</td>
          <td class="font_detail">Action</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in qrTicketsList"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item?.pnrCode }}</td>
          <td class="font_detail">{{ tripDateFormat(item?.tripDateTime) }}</td>
          <td class="font_detail">{{ item?.coach?.name }}</td>
          <td class="font_detail">{{ item?.trip?.route?.name }}</td>
          <td class="font_detail">{{ item?.boardingPlace }}</td>
          <td class="font_detail">{{ item?.droppingPoint }}</td>
          <td class="font_detail">
            <template v-for="(value, key) in item?.farePerSeat" :key="key">
              {{ key }} - {{ value }} <br />
            </template>
          </td>
          <td class="font_detail">
            <button
                class="btn btn-secondary text-light m-1"
                @click="qrTicketStatusUpdateModal(item._id)"
                v-if="item?.status"
            >
              Cancel
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white" style="height: 20px;">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>
  <QRTicketStatusModal
      v-if="activeModal"
      @close="close"
      @qrTicketStatusUpdate="qrTicketStatusUpdate"
      :statusUpdateSelectedData="statusUpdateSelectedData"
  />
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import {onBeforeMount, ref, inject, reactive, watch} from "vue";
import store from "@/store";
import toast from "@/modules/Toast";
const {infoToast} = toast();
import QRTicketStatusModal from "./QRTicketStatusModal.vue";
import moment from "moment";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import router from "@/router";
import web from "@/urls/web";

export default {
  name: "QRTicketsReport",
  components: {
    DropdownFilter,
    QRTicketStatusModal
  },
  setup() {
    const { hasPermission } = permission();
    const { Admin } = roles();
    let selectCompany = ref(false);
    let filterActive = ref(false);
    let qrTicketsList = ref({});
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const activeModal = ref(false);
    const statusUpdateSelectedData = ref({});
    let pagination = reactive({
      company: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      tripCode: "",
    });

    const getQRTickets = () => {
      qrTicketsList.value = [];
      showLoader();
      store
          .dispatch("getQRTicketsList", pagination)
          .then((res) => {
            if(res.data?.length === 0){
              infoToast('No Data Available')
            }
            qrTicketsList.value = res.data
          })
          .catch((error) => {
            if (error?.status === 401) {
              getQRTickets();
            }
          })
          .finally(() => hideLoader());
    };


    const getAllCompanyList = () => {
      showLoader();
      store.dispatch("getAllCompanyList").then(() => hideLoader())
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCompanyList();
            }
          });
    };

    const filterReport = () => {
      selectCompany.value = true;
      if (pagination.company !== "") {
        filterActive.value = true;
        getQRTickets()
      }
    };

    const tripDateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm A");
    };

    // const sumTotalData = computed(() => {
    //   let calculateTotalAmount = 0;
    //   let calculateTotalTicket = 0;
    //   qrTicketsList.value?.map((history) => {
    //     for (const value of Object.values(history.farePerSeat)) {
    //       calculateTotalAmount += value
    //       calculateTotalTicket++;
    //     }
    //   })
    //   return { calculateTotalTicket, calculateTotalAmount };
    // })

    const qrTicketStatusUpdate = () => {
      showLoader();
      store.dispatch("adminQRTicketCancel", {ticketId: statusUpdateSelectedData.value.ticketId})
          .finally(() => {
            hideLoader();
            getQRTickets()
          });
    }

    const qrTicketStatusUpdateModal = (itemId) =>  {
      activeModal.value = true;
      statusUpdateSelectedData.value.ticketId = itemId
    }

    const close = () => {
      activeModal.value = false;
      statusUpdateSelectedData.value = {};
    };

    onBeforeMount(() => {
      getAllCompanyList();
    });

    watch(
        () => store.getters.userRoles,
        (value) => {
          if (!value?.includes(Admin.qrTicket)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );

    return {
      dropdownVariables,
      getAllCompanyList,
      pagination,
      filterReport,
      qrTicketsList,
      selectCompany,
      filterActive,
      exportExcel,
      dateTimeFormat,
      getQRTickets,
      activeModal,
      qrTicketStatusUpdateModal,
      close,
      statusUpdateSelectedData,
      qrTicketStatusUpdate,
      tripDateFormat,
      Admin,
      hasPermission,
    };
  },
};
</script>

<style scoped></style>