<template>
  <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 order-md-4 order-xl-2 order-4 d-none d-md-block">
    <span class="fw-bold">{{ t("SEAT INFORMATION", {}, {locale: selectedLanguage}) }}</span>
    <div class="card card-body">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center font_detail m-0">
            {{ t("SEATS", {}, {locale: selectedLanguage}) }}
            <span class="d-md-none">({{ seats.length || 0 }})</span>
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">#{{ seats.map(a => Object.values(a.seat_no).join('')).join(', ') }}</span>
          </div>
          <p class="text-center font_detail m-0 d-md-block d-none">
            {{ t("Total Seats", {}, {locale: selectedLanguage}) }}: {{ seats.length || 0 }}
          </p>
        </div>
        <div class="col-4 col-md-2 my-1 my-md-0 input-col">
          <p class="text-center font_detail m-0">
            {{ t("FARE", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-if="fareType === 'predefined' || fareType === 'trip_fare'"
              v-model="ticketAccountDetails.totalAmount"
              :disabled="!allowNetPayEditable"
              class="input_field form-control rounded px-3 w-100 text-center"
              min="0"
              type="Number"
          />
          <input
              v-else
              v-model="ticketAccountDetails.inputFare"
              class="input_field form-control rounded px-3 w-100 text-center"
              type="Number"
              @input="manageInputFare"
          />
        </div>
        <div
            v-if="hasPermission(Counterman.fareDiscount)"
            class="col-4 my-1 my-md-0 col-md-2 input-col"
        >
          <p class="text-center font_detail m-0">
            {{ t("DISCOUNT", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-model="ticketAccountDetails.discount"
              class="input_field form-control rounded px-3 w-100 text-center"
              type="number"
          />
          <p v-if="discountErrorMsg" class="text-danger">
            {{ t("Discount_Fare", {}, {locale: selectedLanguage}) }}
          </p>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2 input-col">
          <p class="text-center font_detail m-0">
            {{ t("PAID(TK)", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-model="ticketAccountDetails.paid"
              class="input_field form-control rounded px-3 w-100 text-center"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              type="number"
          />
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2 input-col">
          <p class="text-center font_detail m-0 text-uppercase">
            {{ t("Payable", {}, {locale: selectedLanguage}) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
              <span class="orange-bold-text">{{ ticketAccountDetails.payable || 0 }}
              </span>
          </div>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2 input-col">
          <p class="text-center font_detail m-0">
            {{ t("DUE", {}, {locale: selectedLanguage}) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.due }}</span>
          </div>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2 input-col">
          <p class="text-center font_detail m-0">
            {{
              t("REFUND", {}, {locale: selectedLanguage})
            }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.refund }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 order-md-4 order-xl-2 order-4 d-none d-md-block">
    <span class="fw-bold">{{ t("STUFF INFORMATION", {}, {locale: selectedLanguage}) }}</span>
    <div class="card card-body stuff-card">
      <table class="table">
        <tbody>
        <tr>
          <th class="fw-bold">{{ t("Driver", {}, {locale: selectedLanguage}) }}</th>
          <td>
            {{ trip?.driver?.name ?? 'N/A' }} <br/>
            {{ trip?.driver?.phone }}
          </td>
        </tr>
        <tr>
          <th class="fw-bold">{{ t("Supervisor", {}, {locale: selectedLanguage}) }}</th>
          <td>
            {{ trip?.supervisor?.name ?? 'N/A' }} <br/>
            {{ trip?.supervisor?.phone }}
          </td>
        </tr>
        </tbody>
      </table>
      <p class="stuff-info-couch"><strong>Bus No:</strong>
        {{ trip?.coachRegistrationNumber ?? trip?.coach?.registrationNumber }}</p>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {computed, reactive, ref, watch} from "vue";
import store from "@/store";
import roles from "@/modules/roles";
import permission from "@/modules/permission";

const {hasPermission} = permission();
const {Counterman} = roles();

export default {
  name: "SeatInformation",
  props: {
    singleTripDetails: Object,
  },
  setup(props) {
    const {t, locale} = useI18n();
    const discountErrorMsg = ref('');
    let ticketAccountDetails = reactive({
      inputFare: '',
      totalAmount: 0,
      fare: 0,
      discount: 0,
      payable: 0,
      paid: 0,
      due: 0,
      refund: 0,
    });

    const selectedLanguage = computed(() => store.getters.getLang)
    const seatFares = computed(() => store.getters.getSeatFare);
    const seats = seatFares;
    let backupDiscount = 0;
    const fareType = store.getters.userCompanyData.fareType;
    const allowNetPayEditable = store.getters.allowNetPayEdit;
    const trip = computed(() => props.singleTripDetails)

    watch(() => trip.value, () => {
      resetTicketAccountDetails()
    })

    watch(() => store.getters.getResetStatus, () => {
      if (store.getters.getResetStatus === true) {
        resetTicketAccountDetails()
      }
    })

    const resetTicketAccountDetails = () => {
      ticketAccountDetails.inputFare = '';
      ticketAccountDetails.totalAmount = 0;
      ticketAccountDetails.discount = 0;
      ticketAccountDetails.fare = 0;
      ticketAccountDetails.payable = 0;
      ticketAccountDetails.paid = 0;
      ticketAccountDetails.due = 0;
      ticketAccountDetails.refund = 0;
      store.commit("setSelectedSeatFareTotal", 0)
    }


    watch(() => seatFares, () => {
      if (fareType === 'predefined' || fareType === 'trip_fare') {
        ticketAccountDetails.fare = computed(() => store.getters.getSelectedSeatFareTotal).value
      }
    }, {deep: true});

    //when input for instant fare
    watch(() => ticketAccountDetails.inputFare, () => {
      ticketAccountDetails.fare = ticketAccountDetails.inputFare !== '' ? parseInt(ticketAccountDetails.inputFare) : 0;
      store.commit("setSelectedSeatFareTotal", parseInt(ticketAccountDetails.inputFare))
    });

    //when fare editable
    watch(() => ticketAccountDetails.totalAmount, () => {
      ticketAccountDetails.fare = ticketAccountDetails.totalAmount
    });

    //fare changes effect
    watch(() => ticketAccountDetails.fare, () => {
      checkDiscountError();
      ticketAccountDetails.totalAmount = ticketAccountDetails.fare
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    });

    //discount changes effect
    watch(() => ticketAccountDetails.discount, () => {
      checkDiscountError();
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    })

    //paid changes effect
    watch(() => ticketAccountDetails.paid, () => {
      if (ticketAccountDetails.paid > ticketAccountDetails.payable) {
        ticketAccountDetails.refund = ticketAccountDetails.paid - ticketAccountDetails.payable
        ticketAccountDetails.due = 0
      } else {
        ticketAccountDetails.due = ticketAccountDetails.payable - ticketAccountDetails.paid
        ticketAccountDetails.refund = 0;
      }
    })

    watch(() => seats, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    watch(() => ticketAccountDetails, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    const manageInputFare = (event) => {
      if (event.target.value.length > 5) {
        ticketAccountDetails.inputFare = event.target.value.slice(0, 5)
      } else {
        ticketAccountDetails.inputFare = event.target.value
      }
    }

    //Discount Error
    const checkDiscountError = () => {
      if(ticketAccountDetails.discount === ''){
        ticketAccountDetails.discount = 0;
      }

      if (ticketAccountDetails.discount > ticketAccountDetails.fare || ticketAccountDetails.discount < 0) {
        ticketAccountDetails.discount = backupDiscount;
        discountErrorMsg.value = "Discount amount can't be greater than net fare";
        return true;
      } else {
        backupDiscount = ticketAccountDetails.discount;
        discountErrorMsg.value = ''
      }
    }

    return {
      t,
      locale,
      selectedLanguage,
      seats,
      Counterman,
      hasPermission,
      ticketAccountDetails,
      seatFares,
      fareType,
      allowNetPayEditable,
      discountErrorMsg,
      trip,
      manageInputFare,
    }
  }
}
</script>

<style scoped>
.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .col-md-12 {
    padding: 2px !important;
  }

  .input-col {
    padding: 1px !important;
  }

  .input_field {
    font-size: 12px !important;
  }

  .orange-bold-text {
    font-size: 12px !important;
  }

  .stuff-card {
    padding: 1px;
  }

  .vehicle_table tr th, .vehicle_table tr td {
    font-size: 12px;
  }

  .stuff-info-couch {
    font-size: 12px;
  }

}
</style>
