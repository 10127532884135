<template>
  <div v-if="hasPermission(Owner.ownerRead)">
    <Table v-if="$store.getters.getPanelOwners">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <!-- <td class="font_detail">DESIGNATION</td> -->
          <td class="font_detail">
            {{ t("Mobile", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Email", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("User_type", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td v-if="hasPermission(Owner.ownerWrite)" class="font_detail">
            {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(user, index) in $store.getters.getPanelOwners"
          :key="user._id"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ user.name }}</td>
          <!-- <td class="font_detail">{{ user.designation }}</td> -->
          <td class="font_detail">{{ user.phone }}</td>
          <td class="font_detail">{{ user.email }}</td>
          <td class="font_detail">
            {{ dropdownVariables.userType[user.userType] }}
          </td>
          <td>
            <span v-if="user.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.ownerWrite)">
            <!--            <router-link type="button" v-bind:to="{name:updateUrl,query:{id:user._id}}" @click="$emit('open')"-->
            <!--                         class="edit_table_data edit_table_button font_detail"><i-->
            <!--                class="fas fa-pencil-alt"></i></router-link>-->
            <button
              class="edit_table_data edit_table_button font_detail"
              @click="updateModal(user._id)"
            >
              <i class="fas fa-pencil-alt"></i
              ><span class="mx-1">{{
                t("Edit", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getPanelTotalRecords"
          :per-page="pagination.limit"
          @paginate="getAllOwners"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <OwnerAddUpdateModal
        v-model="showModal"
        v-bind:ownerId="ownerId"
        @getAllOwners="getAllOwners"
      />
    </div>
    <button
      v-if="hasPermission(Owner.ownerWrite)"
      @click="addModal"
      class="floating_btn"
    >
      <span><i class="fa fa-plus my-float"></i></span>
    </button>
  </div>
</template>

<script>
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import OwnerAddUpdateModal from "./OwnerAddUpdateModal.vue";
import router from "@/router";
import web from "@/urls/web";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "OwnersList",
  components: { OwnerAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    const ownerId = ref("");
    const showModal = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const options = {
      texts: {
        count: "",
      },
    };
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value.includes(Owner.ownerRead)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    const getAllOwners = () => {
      showLoader();
      store
        .dispatch("getPanelOwnersByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllOwners();
          }
        });
    };
    onBeforeMount(() => {
      getAllOwners();
    });
    const updateModal = (value) => {
      ownerId.value = value;
      showModal.value = true;
    };
    const addModal = () => {
      ownerId.value = "";
      showModal.value = true;
    };

    return {
      t,
      locale,
      getAllOwners,
      hasPermission,
      Owner,
      updateModal,
      dropdownVariables,
      pagination,
      showModal,
      ownerId,
      addModal,
      options,
    };
  },
};
</script>

<style></style>
