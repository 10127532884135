import {ref} from "vue";

const seatColors = ref({});
const seatCount = ref(0);

const colorChange = (seatNo, maxSeatPerTicket) => {
    if (seatNo in seatColors.value) {
        if (seatColors.value[seatNo] !== null) {
            seatColors.value[seatNo] = null
            seatCount.value--
        } else {
            if (seatCount.value < maxSeatPerTicket) {
                seatColors.value[seatNo] = 'bg-success'
                seatCount.value++
            }
        }
    } else {
        if (seatCount.value < maxSeatPerTicket) {
            seatColors.value[seatNo] = 'bg-success'
            seatCount.value++
        }
    }
}

export {
    colorChange,
    seatColors,
    seatCount
}
