<template>
  <div class="row p-5">
    <span v-if="errors" class="text-danger err_msg">* {{ errors }}</span>
    <div class="col-md-3">
      <Dropdown
        v-model="fareModalityData.fareModality.fromStoppageId"
        v-bind:id="'route'"
        v-bind:label="'From Stoppage'"
        v-bind:dropdown="
          $store.getters.getOwnerServicesSequenceRouteListForFilter
        "
        v-bind:hideLabel="'inactive'"
        v-bind:defaultOption="'--select sequence route--'"
        v-bind:defaultValue="''"
      />
    </div>
    <div class="col-md-3">
      <Dropdown
        v-model="fareModalityData.fareModality.toStoppageId"
        v-bind:id="'route'"
        v-bind:label="'To Stoppage'"
        v-bind:dropdown="
          $store.getters.getOwnerServicesSequenceRouteListForFilter
        "
        v-bind:hideLabel="'inactive'"
        v-bind:defaultOption="'--select sequence route--'"
        v-bind:defaultValue="''"
      />
    </div>
    <div class="col-md-3">
      <Dropdown
        v-model="fareModalityData.status"
        v-bind:id="'status'"
        v-bind:label="'Status'"
        v-bind:dropdown="status"
        v-bind:hideLabel="'inactive'"
      />
    </div>
    <div class="col-md-3 mt-4">
      <!-- <input
        type="submit"
        class="submit_button mt-1 w-50"
        @click.prevent="addFareModality"
      /> -->
      <button  class="submit_button mt-1 w-50"
        @click.prevent="addFareModality">{{ t("Submit", {}, { locale: $store.getters.getLang }) }}</button>
    </div>
    <div class="d-flex" v-if="fareModalityData.fareModality.fare.length !== 0">
      <Input
        class="m-1"
        v-for="seatClass in fareModalityData.fareModality.fare"
        :key="seatClass.index"
        v-model.number="seatClass.fare"
        v-bind:placeholder="`Enter ${seatClass.class_name} Fare`"
        v-bind:id="'number'"
        v-bind:type="'number'"
        :label="`${seatClass.class_name}`"
      />
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import store from "@/store";
import { reactive } from "@vue/reactivity";
// import { onBeforeMount, ref, watch } from "vue";
// import useInputValidation from "@/modules/useInputValidations";
import { inject, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "FareModality",
  props: {
    id: String,
    routeId: String,
    companyId: String,
  },
  components: {},

  setup(props, { emit }) {
    // let { routeSequenceValidation, errors } = useInputValidation();
    const status = dropdownVariables.status;
    const id = ref(props.id);
    const routeId = ref(props.routeId);
    const companyId = ref(props.companyId);
    const seatAllClass = ref({});
    const allSeatClass = ref([]);
    const routeSequence = ref({});
    const routeSequenceArray = ref([]);
    let errors = ref("");

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
const { t, locale } = useI18n();
    seatAllClass.value = store.getters.getAllSeatClassOwner;
    let fareModalityData = reactive({
      id: id.value,
      companyId: companyId.value,
      routeId: routeId.value,
      fareModality: {
        fromStoppageId: "",
        toStoppageId: "",
        fare: [],
      },
      status: "1",
    });
    const clear = () => {
      fareModalityData.fareModality.fromStoppageId = "";
      fareModalityData.fareModality.toStoppageId = "";
      fareModalityData.fareModality.fare.forEach((seat) => {
        seat.fare = "";
      });
    };
    allSeatClass.value = Object.entries(seatAllClass.value);
    allSeatClass.value.forEach((seatClass) => {
      fareModalityData.fareModality.fare.push({
        class_id: seatClass[0],
        class_name: seatClass[1],
        fare: "",
      });
    });

    watch(
      () => fareModalityData.fareModality.fromStoppageId,
      () => {
        errors.value = "";
      }
    );
    watch(
      () => fareModalityData.fareModality.toStoppageId,
      () => {
        errors.value = "";
      }
    );

    const arrayObjectIndexOf = (myObject, searchTerm) => {
      routeSequenceArray.value = Object.keys(myObject);
      for (var i = 0, len = routeSequenceArray.value.length; i < len; i++) {
        if (routeSequenceArray.value[i] === searchTerm) return i;
      }
      return -1;
    };

    const validate = () => {
      routeSequence.value =
        store.getters.getOwnerServicesSequenceRouteListForFilter;

      let indexOfFromStoppage = arrayObjectIndexOf(
        routeSequence.value,
        fareModalityData.fareModality.fromStoppageId
      );

      let indexOfToStoppage = arrayObjectIndexOf(
        routeSequence.value,
        fareModalityData.fareModality.toStoppageId
      );

      if (
        indexOfToStoppage !== -1 &&
        indexOfFromStoppage !== -1 &&
        indexOfToStoppage > indexOfFromStoppage
      ) {
        return true;
      } else {
        errors.value =
          "fromStoppage did not match with route sequence & fromStoppage and toStoppage will not be the same!!";
        return false;
      }
    };

    const addFareModality = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addOwnerServicesFareModality", fareModalityData)
          .then(() => {
            emit("updateFareModality");
            clear();
            hideLoader();
          })
            .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                    addFareModality();
                }
            });
      }
    };

    return {
      t,locale,
      addFareModality,
      fareModalityData,
      status,
      errors,
    };
  },
};
</script>

<style></style>
