import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {infoToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
const {errorResponse,successResponse} = apiResponse();

const state = {
    filter: {
        company: {},
        coach: {},
    },
    shipWiseReportList: [],
    coachWiseReportList:[],
    agentWiseReportList: [],

    coachWiseSeatDetails: [],
    coachWiseExtraDetails: [],
    coachWiseGoodsDetails: [],
    counterWiseGoodsDetails:[],

    shipWiseDeckDetails: [],
    shipWiseGoodDetails:[],
    counterWiseReportList: [],
    counterWiseSeatDetails: [],
    agentWiseSeatDetails: [],
    agentWiseExtraDetails: [],
    agentWiseGoodsDetails: [], 
    
    printTicketCountList:[],
    printTripWiseCountList:[],
    printDailySummaryWiseCountList:[],
    counterWiseExtraDetails:[],
    adminReservationTripList: [],

};
const getters = {
    // getTotalShipWiseDeckDetails: (state => state.shipWiseDeckDetails[0].totalTicket),
    getShipWiseDeckDetails: (state => state.shipWiseDeckDetails),
    getShipWiseGoodDetails: (state => state.shipWiseGoodDetails),

    getCoachWiseSeatDetails: (state => state.coachWiseSeatDetails),
    getCoachWiseExtraDetails: (state => state.coachWiseExtraDetails),
    getCoachWiseGoodsDetails: (state => state.coachWiseGoodsDetails),
    getCounterWiseGoodsDetails: (state => state.counterWiseGoodsDetails),

    getShipWiseReport: (state => state.shipWiseReportList),
    getCoachWiseReportAdminIntercity:(state => state.coachWiseReportList),
    getAgentWiseReport: (state => state.agentWiseReportList),
    getCompanyList: (state) => state.filter.company,
    getCoachList: (state) => state.filter.coach,
    getCounterWiseReport: (state => state.counterWiseReportList),
    getcounterWiseSeatDetails: (state => state.counterWiseSeatDetails),
    getcounterWiseExtraDetails: (state => state.counterWiseExtraDetails),
    getAgentWiseSeatDetails: (state => state.agentWiseSeatDetails),
    getAgentWiseExtraDetails: (state => state.agentWiseExtraDetails),
    getAgentWiseGoodsDetails: (state => state.agentWiseGoodsDetails),

    getPrintTicketCountList: (state => state.printTicketCountList),
    getPrintTripWiseCountList: (state => state.printTripWiseCountList),
    getPrintDailySummaryWiseCountList: (state => state.printDailySummaryWiseCountList),
    getAdminReservationTripList: (state => state.adminReservationTripList),
};
const actions = {
    async getAdminReservationTripReportList({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ADMIN_RESERVATION_HISTORY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAdminReservationTripList", data.trips);
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAdminReservationTripReportDetails(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ADMIN_RESERVATION_HISTORY_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllCompanyList({commit}) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.COMPANY_LIST, {
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let companyList = {}
                            data.company.forEach(company => {
                                companyList[company._id] = company.name;
                            });
                            commit("setCompanyList", companyList)
                            resolve(data.company)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllCoachListForFilter({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setAllCoachList", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_LIST_COACH, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        let allCoachList = {};
                        response.data.coach.forEach((coachList) => {
                            allCoachList[coachList._id] = coachList.name;
                        });
                        commit("setCoachList", allCoachList);
                        resolve(response.data.coach);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getAllCoachReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COACH_WISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        if(response.data.ticket.length === 0 ){
                            infoToast(response.data.message)

                        }
                            commit("setCoachWiseList", response.data.ticket)
                            resolve(response.data.ticket)

                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async getAllAgentList({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAgentWiseList", data.ticket)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    
    async getAllCounterList({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTER_WISE_REPORT_OWNER, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllCounterListOwner", data.ticket)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getfilterAgentWiseReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_REPORT_ADMIN, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        successResponse(response);
                        // infoToast(response.data.message)
                        commit("setFilterAgentWiseReport", response.data.ticket);
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },



    async getAgentWiseSeatDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_SEAT_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        successResponse(response);
                        // infoToast(response.data.message)
                        commit("setAgentWiseSeatDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async getDeckDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.SHIP_WISE_DECK_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setShipWiseDeckDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    
    async getGoodDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.SHIP_WISE_GOOD_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setShipWiseGoodDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async getSeatDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COACH_WISE_SEAT_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
                        commit("setCoachWiseSeatDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanWiseSeatReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
                        commit("setCounterWiseSeatDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getCountermanWiseExtraReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
                        commit("setCounterWiseExtraDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getCountermanWiseGoodsReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
                        commit("setCounterWiseGoodsDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getExtraDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COACH_WISE_SEAT_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) :   successResponse(response)

                 
                        commit("setCoachWiseExtraDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getGoodsDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COACH_WISE_SEAT_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            
                        commit("setCoachWiseGoodsDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async getFilterShipWiseReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.SHIP_WISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setFilterShipWiseReport", response.data.ticket);
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getfilterCounterWiseReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTER_WISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
                        commit("setFilterCounterWiseReport", response.data.ticket);
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getPrintCountList({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            
            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_PRINT_COUNT_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        if (response.status === 200) {
                            let data = response.data
                            commit("setCountReport", {data, payload})
                            resolve();
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgetnWiseSeatReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setAgentWiseSeatDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAgetnWiseExtraReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setAgentWiseExtraDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgentWiseGoodsReportDetails({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.AGENT_WISE_REPORT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        // infoToast(response.data.message)
                        successResponse(response);
                        commit("setAgentWiseGoodsDetails", response.data.ticket)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    // QR history
    async getQRHistoryList(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_QR_HISTORY_LIST, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAllCountermenFilterList(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_COUNTERMEN_FILTER_LIST, {
                    headers: header,
                    params: payload,
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let countermenList = {}
                            data.counterman.forEach(counterman => {
                                countermenList[counterman._id] = counterman.name;
                            });
                            resolve(countermenList)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async adminQRHistoryRefund(_, identifier) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADMIN_QR_REFUND, {identifier}, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

};
const mutations = {
    setAdminReservationTripList: ((state, list) => state.adminReservationTripList = list),//
    setFilterShipWiseReport: ((state, updateList) => state.shipWiseReportList = updateList),//
    setFilterCounterWiseReport: ((state, updateList) => state.counterWiseReportList = updateList),
    setShipWiseDeckDetails: ((state, deckDetails) => state.shipWiseDeckDetails = deckDetails),
    setShipWiseGoodDetails: ((state, goodDetails) => state.shipWiseGoodDetails = goodDetails),
    setCoachWiseSeatDetails: ((state, seatDetails) => state.coachWiseSeatDetails = seatDetails),
    setCoachWiseExtraDetails: ((state, extraDetails) => state.coachWiseExtraDetails = extraDetails),
    setCoachWiseGoodsDetails: ((state, goodsDetails) => state.coachWiseGoodsDetails = goodsDetails),
    setCounterWiseGoodsDetails: ((state, goodsDetails) => state.counterWiseGoodsDetails = goodsDetails),
    setCounterWiseSeatDetails: ((state, seatDetails) => state.counterWiseSeatDetails = seatDetails),
    setCounterWiseSeatDetail: (state, ticketId) => {
        for(const [index, element] of state.counterWiseSeatDetails.entries()){
            if (element._id === ticketId) {
                state.counterWiseSeatDetails.splice(index, 1);
                break;
            }
        }
    },

    setCounterWiseExtraDetails: ((state, extraDetails) => state.counterWiseExtraDetails = extraDetails),
    setCoachWiseList: ((state, coachWiseReport) => state.coachWiseReportList = coachWiseReport),//
    setAgentWiseList: ((state, agentWiseReport) => state.agentWiseReportList = agentWiseReport),
    setFilterAgentWiseReport: ((state, updateList) => state.agentWiseReportList = updateList),
    setAgentWiseSeatDetails: ((state, seatDetails) => state.agentWiseSeatDetails = seatDetails),
    setAgentWiseExtraDetails: ((state, extraDetails) => state.agentWiseExtraDetails = extraDetails),
    setAgentWiseGoodsDetails: ((state, goodsDetails) => state.agentWiseGoodsDetails = goodsDetails),
    
    setCompanyList: ((state, companyList) => state.filter.company = companyList),
    setCoachList: ((state, coachList) => state.filter.coach = coachList),

    setCountReport: ((state, value) => { 
        state.printTicketCountList = []
        state.printTripWiseCountList = []
        state.printDailySummaryWiseCountList = []
        if(value.payload.reportType === 'ticket'){
            state.printTicketCountList = value.data.ticket
        }else if(value.payload.reportType === 'tripWiseReport'){
            state.printTripWiseCountList = value.data.report
        }else if(value.payload.reportType === 'dailySummaryReport'){
            state.printDailySummaryWiseCountList = value.data.report
        }
    }),

};

export default {
    state,
    getters,
    actions,
    mutations
};