<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'from'"
            v-bind:label="'From Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.from"
            v-bind:defaultValue="''"
            v-bind:dropdown="
              $store.getters.getCountermanStoppageListForFilterAgentIntercity
            "
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'to'"
            v-bind:label="'To Stoppage'"
            v-bind:defaultOption="'--select stoppage--'"
            v-model="pagination.to"
            v-bind:defaultValue="''"
            v-bind:dropdown="
              $store.getters.getCountermanStoppageListForFilterAgentIntercity
            "
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'Coach'"
            v-bind:label="'Coach'"
            v-bind:defaultOption="'--select coach--'"
            v-model="pagination.coach"
            v-bind:defaultValue="''"
            v-bind:dropdown="
              $store.getters.getCountermanCoachListForFilterAgentIntercity
            "
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <!--                <div class="col-sm-6 col-md-2">-->
        <!--                    <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'"-->
        <!--                                    v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
        <!--                                    v-model="pagination.status"-->
        <!--                                    v-bind:defaultValue="''"-->
        <!--                                    v-bind:dropdown="status"/>-->
        <!--                </div>-->
      </template>
    </FilterTab>
    <div v-if="dashboardActive">
      <div
        v-if="$store.getters.getTripsForTicketingAgentIntercity"
        class="table-responsive"
      >
        <table
          class="table table-hover table-striped text-center vehicle_table"
        >
          <thead class="font_detail bg_corporate">
            <tr>
              <td class="font_detail">SL</td>
              <td class="font_detail">TRIP TIME & DATE</td>
              <td class="font_detail">TRIP CODE</td>
              <td class="font_detail">COACH</td>
              <td class="font_detail">SEATPLAN</td>
              <td class="font_detail">SEATCLASS</td>
              <td class="font_detail">ROUTE</td>
              <td class="font_detail">DIRECTION</td>
              <td class="font_detail">STATUS</td>
              <td class="font_detail">ACTION</td>
            </tr>
          </thead>
          <tbody
            v-for="(trip, index) in $store.getters
              .getTripsForTicketingAgentIntercity"
            :key="trip._id"
          >
            <tr>
              <td class="font_detail">{{ index + 1 }}</td>
              <td class="font_detail bold">
                {{ dateFormat(trip.tripDateTime) }}
              </td>
              <td class="font_detail">{{ trip.tripCode }}</td>
              <td class="font_detail bold">{{ trip.coach.name }}</td>
              <td class="font_detail bold">{{ trip.seatPlan.name }}</td>
              <td class="font_detail">{{ trip.seatPlan.seatClass }}</td>
              <td class="font_detail">{{ trip.route.name }}</td>
              <td class="font_detail bold">
                {{ trip.route.direction }}
              </td>
              <td>
                <span
                  class="badge"
                  :class="
                    'bg-' + dropdownVariables.tripStatus[trip.status].badge
                  "
                  >{{ dropdownVariables.tripStatus[trip.status].value }}</span
                >
              </td>
              <td>
                <button
                  v-if="activeTrip !== trip._id"
                  class="btn btn-primary btn-sm"
                  @click="viewTrip(trip._id, trip.route.name, trip.coach.name)"
                >
                  <i class="fas fa-chevron-down"></i
                  ><span class="mx-1">View</span>
                </button>
                <button
                  v-else
                  class="btn btn-primary btn-sm"
                  @click="viewTrip('')"
                >
                  <i class="fas fa-chevron-up"></i
                  ><span class="mx-1">Close</span>
                </button>
              </td>
            </tr>

            <tr v-if="activeTrip === trip._id">
              <td colspan="11" class="p-0">
                <TicketBooking
                  v-bind:tripId="activeTrip"
                  :tripDetails="tripDetails"
                  :paginationData="pagination"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mx-5">
          <nav aria-label="Page navigation example">
            <ul
              class="pagination justify-content-center justify-content-sm-end"
            >
              <slot name="pagination"></slot>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <TicketsDetails
      v-else
      :tripIdPass="tripIdPass"
      @close="close"
      :paginationData="pagination"
    />
  </div>
</template>

<script>
import { computed, inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import TicketBooking from "./TicketBooking/TicketBooking";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import dropdownVariables from "../../../modules/dropdownVariables";
import TicketsDetails from "./TicketBooking/TicketsDetails.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
export default {
  name: "CountermanDashboard",
  components: {
    TicketBooking,
    TicketsDetails,
  },
  setup() {
    let tripDetails = reactive({
      route: "",
      coach: "",
    });
    let tripIdPass = ref("");
    let dashboardActive = ref(true);
    let pagination = reactive({
      page: 1,
      limit: 25,
      from: "",
      to: "",
      coach: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    let payload = reactive({
      page: 1,
      limit: 25,
    });
    let activeTrip = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { hasPermission } = permission();
    const { Agent } = roles();
    // watch(() => pagination.status, () => {
    //     getAllTripsForTicketing()
    // });
    watch(
      () => pagination.from,
      () => {
        getAllTripsForTicketing();
      }
    );
    watch(
      () => pagination.to,
      () => {
        getAllTripsForTicketing();
      }
    );
    watch(
      () => pagination.date,
      () => {
        getAllTripsForTicketing();
      }
    );
    watch(
      () => pagination.coach,
      () => {
        getAllTripsForTicketing();
      }
    );
    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });
    const viewTrip = (tripId, routeName, coachName) => {
      tripDetails.route = routeName;
      tripDetails.coach = coachName;
      activeTrip.value = tripId;
    };
    const Tickets = (tripId) => {
      tripIdPass.value = tripId;
      dashboardActive.value = false;
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllTripsForTicketing = () => {
      showLoader();
      store
        .dispatch("getActiveTripsForTicketingAgentIntercity", pagination)
        .then(() => {
          hideLoader();
          // getAllStoppages();
          // getAllCoach();
        })
        .catch(() => {
          hideLoader();
          // if (error.status === 401) {
          //   getAllTripsForTicketing();
          // }
        });
    };
    const close = () => {
      tripIdPass.value = "";
      dashboardActive.value = true;
    };
    const getAllStoppages = () => {
      store
        .dispatch("getStoppageListForCountermanAgentIntercity", payload)
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllStoppages();
          }
        });
    };
    const getAllCoach = () => {
      store
        .dispatch("getShipListForCountermanAgentIntercity", payload)
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllCoach();
          }
        });
    };
    onBeforeMount(() => {
      getAllStoppages();
      getAllCoach();
      getAllTripsForTicketing();
    });
    return {
      activeTrip,
      dropdownVariables,
      tripIdPass,
      dashboardActive,
      hasPermission,
      Agent,
      status,
      pagination,
      viewTrip,
      dateFormat,
      Tickets,
      close,
      tripDetails,
    };
  },
};
</script>

<style scoped></style>
