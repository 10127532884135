<template>
  <!--  <div class="card">-->
  <div>
    <div v-if="companyDeployTabs">
      <ul
        class="nav nav-tabs justify-content-center nav-style"
        id="myTab"
        role="tablist"
      >
        <li
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :id="tab.name + '-tab'"
            data-bs-toggle="tab"
            v-bind:class="
              tab.name === activeTab
                ? 'active active-button'
                : 'inactive-button'
            "
            type="button"
            @click="setActive(tab.name)"
            role="tab"
            :aria-controls="tab.path"
            aria-selected="true"
          >
            {{ t(tab?.title, {}, { locale: $store.getters.getLang }) }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="tab-pane fade"
          v-bind:class="tab.name === activeTab ? 'show active' : ''"
          :id="tab.path"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
        ></div>
        <Stoppage v-if="activeTab === companyDeployTabs.STOPPAGE_URL?.name" />
        <Route v-if="activeTab === companyDeployTabs.ROUTE_URL?.name" />
        <Counter v-if="activeTab === companyDeployTabs.COUNTER_URL?.name" />
        <SeatClass
          v-if="activeTab === companyDeployTabs.SEAT_CLASS_URL?.name"
        />
        <SeatPlan v-if="activeTab === companyDeployTabs.SEAT_PLAN_URL?.name" />
        <div v-if="$store.getters.userCompanyData?.fareType === 'predefined'">
          <SeatFare
            v-if="activeTab === companyDeployTabs.SEAT_FARE_URL?.name"
          />
        </div>
        <Coach v-if="activeTab === companyDeployTabs.COACH_URL?.name" />
        <Trips v-if="activeTab === companyDeployTabs.TRIPS_URL?.name" />
        <Agent v-if="activeTab === companyDeployTabs.AGENT_URL?.name" />
        <CountermanSeperate
          v-if="activeTab === companyDeployTabs.COUNTERMAN_URL?.name"
        />
        <Supervisor
          v-if="activeTab === companyDeployTabs.SUPERVISOR_URL?.name"
        />
        <Driver v-if="activeTab === companyDeployTabs.DRIVER_URL?.name" />
      </div>
    </div>
    <div v-if="tabKeys.length === 0" class="text-muted text-center mt-5"><h2>No permission given</h2></div>
  </div>
</template>

<script>
import web from "@/urls/web";
import Stoppage from "./Children/Stoppage/Stoppage.vue";
import Route from "./Children/Routes/Routes";
import { onBeforeMount, onBeforeUnmount, ref, } from "vue";
import store from "@/store";
import CountermanSeperate from "./Children/counterman/CountermanSeperate.vue";
import SeatClass from "./Children/SeatClass/SeatClass.vue";
import SeatPlan from "./Children/SeatPlan/SeatPlan/SeatPlan.vue";
import SeatFare from "./Children/SeatFare/SeatFare/SeatFare.vue";
import Counter from "./Children/Counter/Counter";
import Trips from "./Children/Trips/Trips.vue";
import Coach from "./Children/Coach/Coach.vue";
import Agent from "./Children/Agent/Agent.vue";
import Supervisor from "./Children/Supervisor/Supervisor.vue";
import Driver from "./Children/Driver/Driver.vue";
import permission from "@/modules/permission";
// import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
const {hasPermission} = permission();
// const { Owner } = roles();

export default {
  name: "CompanyManage",
  components: {
    Route,
    Stoppage,
    Counter,
    Trips,
    Agent,
    CountermanSeperate,
    SeatClass,
    SeatPlan,
    SeatFare,
    Coach,
    Supervisor,
    Driver,
  },
  setup() {
    const { t, locale } = useI18n();
    let activeTab = ref("");
    const tabKeys = ref([]);
    let companyDeployTabs = ref({ ...web.Owner.SERVICES_URL.children });
    if (store.getters.userCompanyData?.fareType === "instant_fare") {
      delete companyDeployTabs.value["SEAT_FARE_URL"];
    } else if (store.getters.userCompanyData?.fareType === "trip_fare") {
      delete companyDeployTabs.value["SEAT_FARE_URL"];
    }

    // watch(
    //   () => store.getters.userRoles,
    //   (value) => {
    //     console.log('watcher calling.........')
    //     if (!value?.includes(Owner.ownerTripRead)) {
    //       delete companyDeployTabs.value["TRIPS_URL"];
    //     } else {
    //       companyDeployTabs.value = { ...web.Owner.SERVICES_URL.children };
    //       if (store.getters.userCompanyData?.fareType === "instant_fare") {
    //         delete companyDeployTabs.value["SEAT_FARE_URL"];
    //       } else if (store.getters.userCompanyData?.fareType === "trip_fare") {
    //         delete companyDeployTabs.value["SEAT_FARE_URL"];
    //       }
    //     }
    //   }
    // );
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    const manageRoles = () => {
      for (const argumentsKey in companyDeployTabs.value) {
        if (!hasPermission(companyDeployTabs.value[argumentsKey].permission)){
          delete companyDeployTabs.value[argumentsKey]
        }
      }
      handleTripFareTab()
      findForActiveTab()
    }

    const handleTripFareTab = () => {
      if (companyDeployTabs.value["TRIPS_URL"] !== undefined && store.getters.userCompanyData?.fareType !== 'predefined'){
        delete companyDeployTabs.value["SEAT_FARE_URL"];
      }
    }

    const findForActiveTab = () => {
      tabKeys.value = Object.keys(companyDeployTabs.value)
      if (tabKeys.value.length > 0){
        activeTab.value =   companyDeployTabs.value[tabKeys.value[0]].name
      }else {
        activeTab.value =  store.getters.getActiveTab
      }
    }

    onBeforeMount(() => {
      manageRoles()
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });

    return {
      t,
      locale,
      companyDeployTabs,
      activeTab,
      setActive,
      tabKeys,
    };
  },
};
</script>

<style scoped>
/*.card {*/
/*  padding: 20px 30px;*/
/*}*/

.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
