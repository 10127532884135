import roles from "@/modules/roles";
const {Admin:AdminRoles,Owner:OwnerRoles,Counterman:CountermanRoles,Agent:AgentRoles} = roles()

const Default = {
    'LOGIN_URL': {
        "title": "Login",
        "path": "/",
        "name": "login",
    },
    'LOGOUT_URL': {
        "title": "Logout",
        "path": "/logout",
        "name": "logout",
    },
    'ERROR_URL':{
        "title": "Error",
        "path": "/:pathMatch(.*)*",
        "name": "error",
    }
}

const Admin = {
    'path': '/admin',
    'name': 'admin',
    'DASHBOARD_URL': {
        "title": "Dashboard",
        "path": "dashboard",
        "name": "admin-dashboard",
        "permission":AdminRoles.dashboard,
    },
    'REPORT_URL': {
        "title": "Report",
        "path": "report",
        "name": "admin-report",
        "permission":AdminRoles.reports,
        "children":{
            'COACH_REPORT_URL':{
                "title": "Coach Wise",
                "path": "coach-report",
                "name": "admin-report-coach-report",
            },
            'COUNTER_REPORT_URL': {
                "title": "Counter Wise",
                "path": "counter-report",
                "name": "admin-report-counter-report",
            },
            'AGENT_REPORT_URL': {
                "title": "Agent Wise",
                "path": "agent-report",
                "name": "admin-report-agent-report",
            },
            'PRINT_COUNT_REPORT_URL': {
                "title": "Print Count",
                "path": "print-count",
                "name": "admin-report-print-count-report",
            },
            'RESERVATION_REPORT_URL': {
                "title": "Reservation Report",
                "path": "reservation-report",
                "name": "admin-report-reservation-report",
            },
            'QR_HISTORY_REPORT_URL': {
                "title": "QR History Report",
                "path": "qr-history-report",
                "name": "admin-qr-history-report",
            },
        }
    },
    'ADMINS_URL': {
        "title": "Admins",
        "path": "admins",
        "name": "admin-admins",
        "permission":AdminRoles.adminRead,
        "children":{
            'LIST_URL':{
                "title": "List",
                "path": "list",
                "name": "admin-admins-list",
            },
            'ADD_URL':{
                "title": "Add",
                "path": "add",
                "name": "admin-admins-add",
            },
            'UPDATE_URL':{
                "title": "Update",
                "path": "update",
                "name": "admin-admins-update",
            }
        }
    },
    'COMPANY_DEPLOY_URL': {
        "title": "Company Deploy",
        "path": "company-deploy",
        "name": "admin-company-deploy",
        "permission":AdminRoles.companyDeployRead,
        "children": {
            'LIST_URL':{
                "title": "List",
                "path": "list",
                "name": "admin-company-deploy-list",
            },
            'ADD_URL':{
                "title": "Add",
                "path": "add",
                "name": "admin-company-deploy-add",
            },
            'UPDATE_URL':{
                "title": "Update",
                "path": "update",
                "name": "admin-company-deploy-update",
            },
            'MANAGE_URL':{
                "title": "Manage",
                "path": "manage",
                "name": "admin-company-deploy-manage",
                "children":{
                    'OWNER_URL': {
                        "title": "Owner",
                        "path": "owner",
                        "name": "admin-company-deploy-owner",
                    },
                    'STOPPAGE_URL': {
                        "title": "Stoppage",
                        "path": "stoppage",
                        "name": "admin-company-deploy-stoppage",
                    },
                    // 'SEAT_CLASS_URL': {
                    //     "title": "SEAT CLASS",
                    //     "path": "seat-class",
                    //     "name": "admin-company-deploy-seat-class",
                    // },
                    // 'SEAT_PLAN_URL': {
                    //     "title": "SEAT PLAN",
                    //     "path": "seat-plan",
                    //     "name": "admin-company-deploy-seat-plan",
                    // },
                    'COUNTER_URL': {
                        "title": "Counter",
                        "path": "counter",
                        "name": "admin-company-deploy-counter",
                    },
                    'ROUTE_URL': {
                        "title": "Route",
                        "path": "route",
                        "name": "admin-company-deploy-route",
                    },
                    // 'FARES_URL': {
                    //     "title": "FARES",
                    //     "path": "fares",
                    //     "name": "admin-company-deploy-fares",
                    // },
                    // 'SHIPS_URL': {
                    //     "title": "Ships",
                    //     "path": "ships",
                    //     "name": "admin-company-deploy-ships",
                    // },
                    'SEAT_CLASS_URL': {
                        "title": "Seat-Class",
                        "path": "seatClass",
                        "name": "admin-company-deploy-seatClass",
                    },
                    'SEAT_PLAN_URL': {
                        "title": "Seat-Plan",
                        "path": "seatPlan",
                        "name": "admin-company-deploy-seatPlan",
                    },
                    'SEAT_FARE_URL': {
                        "title": "Seat-Fare",
                        "path": "seatFare",
                        "name": "admin-company-deploy-seatFare",
                    },
                    'COACH_URL': {
                        "title": "Coach",
                        "path": "coach",
                        "name": "admin-company-deploy-coach",
                    },
                    'TRIPS_URL': {
                        "title": "Trips",
                        "path": "trips",
                        "name": "admin-company-deploy-trips",
                    },
                    'AGENT_URL': {
                        "title": "Agent",
                        "path": "agent",
                        "name": "admin-company-deploy-agent",
                    },
                    'COUNTERMAN_URL': {
                        "title": "Counterman",
                        "path": "counterman",
                        "name": "admin-company-deploy-counterman",
                    },
                    // 'DECKMAN_URL': {
                    //     "title": "Deckman",
                    //     "path": "deckman",
                    //     "name": "admin-company-deploy-deckman",
                    // },
                    'SUPERVISOR_URL': {
                        "title": "Supervisor",
                        "path": "supervisor",
                        "name": "admin-company-deploy-supervisor",
                    },
                    'DRIVER_URL': {
                        "title": "Driver",
                        "path": "driver",
                        "name": "admin-company-deploy-driver",
                    },
                    'DT_ROUTE_URL': {
                        "title": "DT-Route",
                        "path": "dtroute",
                        "name": "admin-company-deploy-dt-route",
                    },
                    // 'DT_FARE_URL': {
                    //     "title": "DT-Fare",
                    //     "path": "dtfare",
                    //     "name": "admin-company-deploy-dt-fare",
                    // },
                    'TICKET_DESIGN_URL': {
                        "title": "Ticket-Design",
                        "path": "ticket-design",
                        "name": "admin-company-deploy-ticket-design",
                    },
                }
            }
        }
    },
    'DIGITAL_TICKETING_URL': {
        "title": "Digital Ticketing",
        "path": "digital-ticketing",
        "name": "admin-digital-ticketing",
        "children":{
            'STOPPAGE_URL': {
                "title": "City",
                "path": "city",
                "name": "admin-digital-ticketing-city",
            },
        }
    
    },
    'TICKET_DESIGN_URL': {
        "title": "Ticket Design",
        "path": "ticket-design",
        "name": "admin-ticket-design",
    },

    'PAYMENT_SETTLEMENT_URL': {
        "title": "Payment Settlement",
        "path": "payment-settlement",
        "name": "admin-payment-settlement",
        "permission":AdminRoles.paymentSettlement,
    },

    'QR_TICKETS_URL': {
        "title": "QR Tickets",
        "path": "qr-tickets",
        "name": "admin-qr-tickets",
        "permission":AdminRoles.qrTicket,
    },

    'SETTINGS_URL': {
        "title": "Settings",
        "path": "settings",
        "name": "admin-settings",
        "children":{
            'QR_URL': {
                "title": "QR",
                "path": "qr",
                "name": "admin-qr-settings",
            },
        }

    },

    'PROFILE_URL': {
        "title": "Profile",
        "path": "profile",
        "name": "admin-profile",
    }
}

const Owner = {
    'path': '/owner',
    'name': 'owner',
    'DASHBOARD_URL': {
        "title": "Dashboard",
        "path": "dashboard",
        "name": "owner-dashboard",
        "permission": OwnerRoles.ownerDashboard,
    },
    'REPORT_URL': {
        "title": "Report",
        "path": "report",
        "name": "owner-report",
        "permission": OwnerRoles.ownerReports,
        "children":{
            'CHALAN_REPORT_URL':{
                "title": "Chalan Report",
                "path": "chalan-report",
                "name": "owner-report-chalan-report",
            },
            'COACH_REPORT_URL':{
                "title": "Coach Wise",
                "path": "coach-report",
                "name": "owner-report-coach-report",
            },
            'COUNTER_REPORT_URL': {
                "title": "Counter Wise",
                "path": "counter-report",
                "name": "owner-report-counter-report",
            },
            'AGENT_REPORT_URL': {
                "title": "Agent Wise",
                "path": "agent-report",
                "name": "owner-report-agent-report",
            },
            'PRINT_COUNT_REPORT_URL': {
                "title": "Print Count",
                "path": "print-count",
                "name": "owner-report-print-count-report",
            },
            'RESERVATION_REPORT_URL': {
                "title": "Reservation Report",
                "path": "reservation-report",
                "name": "admin-report-reservation-report",
            },
        }
    },
    'OWNERS_URL': {
        "title": "Owners",
        "path": "owners",
        "name": "owner-owners",
        "permission": OwnerRoles.ownerRead,
        "children": {
            'LIST_URL': {
                "title": "List",
                "path": "list",
                "name": "admin-admins-list",
            },
            'ADD_URL': {
                "title": "Add",
                "path": "add",
                "name": "admin-admins-add",
            },
            'UPDATE_URL': {
                "title": "Update",
                "path": "update",
                "name": "admin-admins-update",
            }
        }
    },
    'SERVICES_URL': {
        "title": "Services",
        "path": "services",
        "name": "owner-services",
        "permission": OwnerRoles.ownerCompanySetupRead,
        "children": {
            'STOPPAGE_URL': {
                "title": "Stoppage",
                "path": "stoppage",
                "name": "owner-services-stoppage",
                "permission": OwnerRoles.stoppageRead,
            },
            'COUNTER_URL': {
                "title": "Counter",
                "path": "counter",
                "name": "owner-services-counter",
                "permission": OwnerRoles.counterRead,
            },
            'ROUTE_URL': {
                "title": "Route",
                "path": "route",
                "name": "owner-services-route",
                "permission": OwnerRoles.routeRead,
            },
            'SEAT_CLASS_URL': {
                "title": "Seat Class",
                "path": "seatClass",
                "name": "owner-services-seatClass",
                "permission": OwnerRoles.seatClassRead,
            },
            'SEAT_PLAN_URL': {
                "title": "Seat-Plan",
                "path": "seatPlan",
                "name": "owner-services-seatPlan",
                "permission": OwnerRoles.seatPlanRead,
            },
           
            'SEAT_FARE_URL': {
                "title": "Seat-Fare",
                "path": "seatFare",
                "name": "owner-services-seatFare",
                "permission": OwnerRoles.fareRead,
            },
            'COACH_URL': {
                "title": "Coach",
                "path": "coach",
                "name": "owner-services-coach",
                "permission": OwnerRoles.coachRead,
            },
            'TRIPS_URL': {
                "title": "Trips",
                "path": "trips",
                "name": "owner-services-trips",
                "permission": OwnerRoles.ownerTripRead,
            },
            'AGENT_URL': {
                "title": "Agent",
                "path": "agent",
                "name": "owner-services-agent",
                "permission": OwnerRoles.agentRead,
            },
            'COUNTERMAN_URL': {
                "title": "Counterman",
                "path": "counterman",
                "name": "owner-services-counterman",
                "permission": OwnerRoles.countermanRead,
            },
            'SUPERVISOR_URL': {
                "title": "Supervisor",
                "path": "supervisor",
                "name": "owner-services-supervisor",
                "permission": OwnerRoles.supervisorRead,
            },
            'DRIVER_URL': {
                "title": "Driver",
                "path": "driver",
                "name": "owner-services-driver",
                "permission": OwnerRoles.driverRead,
            },
           
            
        }
    },
    'PROFILE_URL': {
        "title": "PROFILE",
        "path": "profile",
        "name": "owner-profile",
    }
}

const Counterman = {
    'path': '/counterman',
    'name': 'counterman',
    'DASHBOARD_URL': {
        "title": "Dashboard",
        "path": "dashboard",
        "name": "counterman-dashboard",
        "permission":CountermanRoles.ticketBooking,
    },
    'REPORT_URL': {
        "title": "Report",
        "path": "report",
        "name": "counterman-report",
        "permission":CountermanRoles.reports,
        "children":{
            'TRIP_REPORT_URL': {
                "title": "Regular Report",
                "path": "trip-report",
                "name": "counterman-report-trip-report",
            },
            'RESERVATION_REPORT_URL': {
                "title": "Reservation Report",
                "path": "reservation-report",
                "name": "counterman-report-reservation-report",
            },
        }
    },
    'TRIPS_URL': {
        "title": "Trips",
        "path": "trip",
        "name": "counterman-trip",
        "permission": CountermanRoles.trips,
    },
    'COACH_URL': {
        "title": "Coach",
        "path": "coach",
        "name": "counterman-coach",
        "permission":CountermanRoles.coach,
    },

    'PROFILE_URL': {
        "title": "Profile",
        "path": "profile",
        "name": "counterman-profile",
    }
}

const Agent = {
    'path': '/agent',
    'name': 'agent',
    'DASHBOARD_URL': {
        "title": "Dashboard",
        "path": "dashboard",
        "name": "agent-dashboard",
        "permission":AgentRoles.ticketBooking,
    },
    'REPORT_URL': {
        "title": "Report",
        "path": "report",
        "name": "agent-report",
        "permission":AgentRoles.reports,
    },
    'COACH_URL': {
        "title": "Coach",
        "path": "coach",
        "name": "agent-coach",
        "permission":AgentRoles.coach,
    },
    'TRIPS_URL': {
        "title": "Trips",
        "path": "trip",
        "name": "agent-strip",
        "permission": AgentRoles.trips,
    },

    'PROFILE_URL': {
        "title": "Profile",
        "path": "profile",
        "name": "agent-profile",
    }
}
export default {
    Default,
    Admin,
    Owner,
    Counterman,
    Agent
}