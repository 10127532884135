import {reactive} from "@vue/reactivity";
import useValidators from "./validators";

const errors = reactive({});
const {isEmpty, isPhoneEmpty, isFound, minLength, isEmail, isValidNumber, isPhone, isBdPhone, hasRole, isJson, isEmptyCheck , isValidNumberWithZero} = useValidators();
export default function useInputValidation() {
    const requiredValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue || Object.keys(fieldValue).length === 0 ? isEmpty(fieldName, fieldValue) : "";
    };
    const notFoundValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue || Object.keys(fieldValue).length === 0 ? isFound(fieldName, fieldValue) : "";
    };
    const compareRouteSequenceValidation = (name,fromStoppage,toStoppage,arrFirstElement,arrLastElement) => {
        if(fromStoppage===arrFirstElement && toStoppage===arrLastElement){
              errors[name] = ''
        }else{
            errors[name] = 'fromStoppage/toStoppage did not match with route sequence'
        }

    };
    const searchRouteSequenceValidation = (name,fromStoppage,toStoppage,stoppages) => {
        if(stoppages.indexOf(fromStoppage) < stoppages.indexOf(toStoppage)){
            errors[name] = ''
        }else{
            errors[name] = 'Invalid route sequence for searching'
        }

    };
    const commission = (name,commssionAmount) => {
        if(commssionAmount>=0 && commssionAmount<=100){
              errors[name] = ''
        }else{
            errors[name] = 'Commission above 100% is not allowed'
        }

    };
    const validNumberValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue !== "" ? isValidNumber(fieldName, fieldValue) : isEmpty(fieldName, fieldValue);
    };
    const validNumberWithZeroValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue !== "" ? isValidNumberWithZero(fieldName, fieldValue) : isEmpty(fieldName, fieldValue);
    };
    const passwordValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : minLength(fieldName, fieldValue, 8);
    };
    const emailValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue);
    };
    const phoneValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isPhone(fieldName, fieldValue);
    };
    const validPhoneValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue ? isPhone(fieldName, fieldValue) : "";
    };
    const requiredRoleValidation = (fieldName, chosenRoles, requiredRole, role, roleObject) => {
        errors[fieldName] = chosenRoles.includes(role) && !chosenRoles.includes(requiredRole) ? hasRole(requiredRole, role, roleObject) : "";
    };
    const routeSequenceValidation = (name, obj, fromStoppageId, toStoppageId) => {
        const routeSequence = Object.keys(obj);
        const fromStoppageIndex = routeSequence.indexOf(fromStoppageId);
        const toStoppageIndex = routeSequence.indexOf(toStoppageId);

        if(fromStoppageIndex < toStoppageIndex && fromStoppageIndex !== toStoppageIndex) {
            errors[name] = ''
        } else {
            errors[name] = 'fromStoppage did not match with route sequence & fromStoppage and toStoppage will not be the same!! '
        }
    };
    const duplicateValidation = (fieldName, firstFieldName, secondFieldName, firstValue, secondValue) => {
        errors[fieldName] = firstValue === secondValue ? `${firstFieldName} and ${secondFieldName} can't be same` : "";
    }
    const  bdPhoneValidation = (fieldName, fieldValue, allowedOperator) => {
        errors[fieldName] = !fieldValue ? isPhoneEmpty(fieldName, fieldValue) : isBdPhone(fieldName, fieldValue, allowedOperator);
    }

    const jsonValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue ? isJson(fieldValue) : "";

    };

    const passwordRequiredValidation = (fieldName, fieldValue, errorMsg) => { 
        errors[fieldName] = !fieldValue || Object.keys(fieldValue).length === 0 ? isEmptyCheck(fieldValue, errorMsg) : "";
    };

    return {
        errors,
        requiredValidation,
        notFoundValidation,
        passwordValidation,
        emailValidation,
        validNumberValidation,
        phoneValidation,
        bdPhoneValidation,
        validPhoneValidation,
        requiredRoleValidation,
        compareRouteSequenceValidation,
        routeSequenceValidation,
        commission,
        duplicateValidation,
        searchRouteSequenceValidation,
        jsonValidation,
        passwordRequiredValidation,
        validNumberWithZeroValidation
    };
}
