import apisV3 from "@/urls/apis-v3";

const API_URL = process.env.VUE_APP_API;

// Auth  start

const LOGIN = API_URL + "/api/v3/auth/login"; // implemented tushar
const REFRESH = API_URL + "/api/v3/auth/refresh-token"; // implemented tushar
const LOGOUT = API_URL + "/logout"; // implemented tushar

// Auth  end

// special

const GET_SINGLE_COMPANY_DATA = API_URL + "/api/admin/company/get-company"; // implemented tushar

//Optimized api
const TRIP_DETAILS = API_URL + '/api/v3/counterman/dashboard/seat-view-by-trip-info'
const EXTRA_TICKET_PRE_PURCHASE = API_URL + '/api/v3/counterman/dashboard/extra-ticket-pre-purchase'
const TRIP_PASS_DETAILS = API_URL + '/api/v3/counterman/trip/pass-history'

// Admin  start
const GET_DIGITAL_TICKETING_STOPPAGES = API_URL + "/api/admin/digital-ticketing/stoppage"
const GET_DIGITAL_TICKETING_STOPPAGE = API_URL + "/api/admin/digital-ticketing/stoppage/one"
const ADD_DIGITAL_TICKETING_STOPPAGE = API_URL + "/api/admin/digital-ticketing/stoppage"
const UPDATE_DIGITAL_TICKETING_STOPPAGE = API_URL + "/api/admin/digital-ticketing/stoppage"
const GET_DIGITAL_TICKETING_ALL_STOPPAGE = API_URL + "/api/admin/digital-ticketing/stoppage/all"
const GET_DIGITAL_TICKETING_BOARDING_PLACES = API_URL + "/api/admin/digital-ticketing/stoppage/boarding-point"
const ADD_DIGITAL_TICKETING_BOARDING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/boarding-point"
const UPDATE_DIGITAL_TICKETING_BOARDING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/boarding-point"
const DELETE_DIGITAL_TICKETING_BOARDING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/boarding-point"
const GET_DIGITAL_TICKETING_DROPPING_PLACES = API_URL + "/api/admin/digital-ticketing/stoppage/dropping-point"
const ADD_DIGITAL_TICKETING_DROPPING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/dropping-point"
const UPDATE_DIGITAL_TICKETING_DROPPING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/dropping-point"
const DELETE_DIGITAL_TICKETING_DROPPING_PLACE = API_URL + "/api/admin/digital-ticketing/stoppage/dropping-point"


const COMPANY_LIST = API_URL + "/api/admin/company/all"; // implemented tushar
const GET_ALL_USERS = API_URL + "/api/admin/admins"; // implemented tushar
const ADD_USER = API_URL + "/api/admin/admins"; // implemented tushar
const UPDATE_USER = API_URL + "/api/admin/admins"; // implemented tushar
const DELETE_USER = API_URL + "/api/admin/admins"; // not required
const ADMIN_PRINT_COUNT_REPORT =
    API_URL + "/api/admin/report/print-count-report"; // implemented tushar
const GET_ALL_COMPANIES = API_URL + "/api/admin/company"; // implemented tushar
const ADD_COMPANY = API_URL + "/api/admin/company"; // implemented tushar
const UPDATE_COMPANY = API_URL + "/api/admin/company"; // implemented tushar
const GET_ALL_OWNERS = API_URL + "/api/admin/company/owner"; // implemented tushar
const ADD_OWNER = API_URL + "/api/admin/company/owner"; // implemented tushar
const UPDATE_OWNER = API_URL + "/api/admin/company/owner"; // implemented tushar
const GET_ALL_STOPPAGES = API_URL + "/api/admin/company/stoppage"; // implemented tushar
const ADD_STOPPAGE = API_URL + "/api/admin/company/stoppage"; // implemented tushar
const UPDATE_STOPPAGE = API_URL + "/api/admin/company/stoppage"; // implemented tushar
const GET_ALL_ROUTES = API_URL + "/api/admin/company/route"; // implemented tushar
const GET_ALL_ROUTES_FOR_TRIPS = API_URL + "/api/admin/company/route/all-route-sequence";
const ADD_ROUTE = API_URL + "/api/admin/company/route"; // implemented tushar
const UPDATE_ROUTE = API_URL + "/api/admin/company/route"; // implemented tushar
const GET_ALL_STOPPAGE = API_URL + "/api/admin/company/stoppage/all"; // implemented tushar
const GET_ALL_BOARDING_PLACES =
    API_URL + "/api/admin/company/route/boarding-point"; // implemented tushar
const ADD_BOARDING_PLACE = API_URL + "/api/admin/company/route/boarding-point"; // implemented tushar
const UPDATE_BOARDING_PLACE =
    API_URL + "/api/admin/company/route/boarding-point"; // implemented tushar
const GET_ALL_DROPPING_PLACES =
    API_URL + "/api/admin/company/route/dropping-point"; // implemented tushar
const ADD_DROPPING_PLACE = API_URL + "/api/admin/company/route/dropping-point"; // implemented tushar
const UPDATE_DROPPING_PLACE =
    API_URL + "/api/admin/company/route/dropping-point"; // implemented tushar

const GET_ALL_BOARDING_PLACES_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/boarding-point"; // implemented arif
const ADD_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/boarding-point"; // implemented arif
const UPDATE_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/boarding-point"; // implemented arif
const DELETE_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/boarding-point"; // implemented arif
const GET_ALL_DROPPING_PLACES_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/dropping-point"; // implemented arif
const ADD_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/dropping-point"; // implemented arif
const UPDATE_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/dropping-point"; // implemented arif
const DELETE_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/admin/company/stoppage/dropping-point"; // implemented arif

const GET_ALL_COUNTERS = API_URL + "/api/admin/company/counter"; // implemented tushar
const GET_ALL_COUNTERS_ALL = API_URL + "/api/admin/company/counter/all/"; // implemented tushar
const ADD_COUNTER = API_URL + "/api/admin/company/counter"; // implemented tushar
const UPDATE_COUNTER = API_URL + "/api/admin/company/counter"; // implemented tushar
const SEAT_CLASS = API_URL + "/api/admin/company/seat-class"; // implemented tushar
const ADD_SEAT_CLASS = API_URL + "/api/admin/company/seat-class"; // implemented tushar
const UPDATE_SEAT_CLASS = API_URL + "/api/admin/company/seat-class"; // implemented tushar
const ADD_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan"; // implemented tushar
const GET_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan"; // implemented tushar
const UPDATE_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan"; // implemented tushar
const OWNER_TRIP_WISE_REPORT = API_URL + "/api/owner/report/trip-wise-report"; // implemented tushar
const COACH_WISE_REPORT = API_URL + "/api/admin/report/coach-wise-report"; // implemented tushar
const COACH_WISE_SEAT_REPORT_DETAILS =
    API_URL + "/api/admin/report/coach-wise-report-details"; // implemented tushar
const COUNTERMAN_WISE_REPORT_DETAILS =
    API_URL + "/api/admin/report/counter-wise-report-details"; //arif
const AGENT_WISE_REPORT_DETAILS =
    API_URL + "/api/admin/report/agent-wise-report-details"; //arif
const ADD_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row"; // implemented tushar
const UPDATE_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row"; // implemented tushar
const GET_ALL_ROUTES_FILTER = API_URL + "/api/admin/company/route/all"; // implemented tushar
const ADD_SEAT_FARE = API_URL + "/api/admin/company/fare"; // implemented tushar
const GET_SEAT_FARE = API_URL + "/api/admin/company/fare"; // implemented tushar
const DELETE_FARE = API_URL + "/api/admin/company/fare"; // implemented tushar
const UPDATE_FARE = API_URL + "/api/admin/company/fare/update"; // implemented arif
const SEQUENCE_ROUTE_FILTER =
    API_URL + "/api/admin/company/route/route-sequence"; // implemented tushar
const GET_ALL_SEAT_CLASS = API_URL + "/api/admin/company/seat-class/all"; // implemented tushar
const FARE_MODALITY_ADD = API_URL + "/api/admin/company/fare/fare-row"; // implemented tushar
const ROURE_FARE_DELETE = API_URL + "/api/admin/company/fare/fare-row"; // implemented tushar
const UPDATE_FARE_ROUTE = API_URL + "/api/admin/company/fare/fare-row"; // implemented tushar
const GET_FARE_ALL_BY_ROUTEID = API_URL + "/api/admin/company/fare/all"; // implemented tushar
const ADMIN_DASHBOARD = API_URL + "/api/admin/dashboard"; // implemented tushar
const ADD_COACH = API_URL + "/api/admin/company/coach"; // implemented tushar
const GET_ALL_LIST_COACH = API_URL + "/api/admin/company/coach/all"; // implemented tushar
const GET_ALL_COACH = API_URL + "/api/admin/company/coach"; // implemented tushar
const UPDATE_COACH = API_URL + "/api/admin/company/coach"; // implemented tushar
const GET_ALL_SEATPLAN = API_URL + "/api/admin/company/seat-plan/all"; // implemented tushar
const ADD_TRIP_DATA = API_URL + "/api/v3/admin/company/trip"; // implemented tushar
const GET_TRIP_DATA = API_URL + "/api/admin/company/trip"; // implemented tushar
const UPDATE_TRIP_DATA = API_URL + "/api/v3/admin/company/trip"; // implemented tushar
const COPY_TRIP_DATA = API_URL + "/api/v3/admin/company/trip-copy";
const CANCEL_TRIP = API_URL + "/api/admin/company/trip/cancel"; // implemented tushar
const CANCEL_TRIP_IN_BULK = API_URL + "/api/admin/company/trip/bulk-cancel";
const GET_ALL_AGENT = API_URL + "/api/admin/company/agent"; // implemented arif
const UPDATE_AGENT = API_URL + "/api/admin/company/agent"; // implemented arif
const ADD_AGENT = API_URL + "/api/admin/company/agent"; // implemented arif
const GET_ALL_COUNTERMANS = API_URL + "/api/admin/company/counterman"; // implemented arif
const ADD_COUNTERMAN = API_URL + "/api/admin/company/counterman"; // implemented arif
const UPDATE_COUNTERMAN = API_URL + "/api/admin/company/counterman"; // implemented arif
const GET_ALL_SUPERVISORS = API_URL + "/api/admin/company/supervisor"; // implemented arif
const ADD_SUPERVISOR = API_URL + "/api/admin/company/supervisor"; // implemented arif
const UPDATE_SUPERVISOR = API_URL + "/api/admin/company/supervisor"; // implemented arif
const GET_ALL_DRIVERS = API_URL + "/api/admin/company/driver"; // implemented arif
const ADD_DRIVER = API_URL + "/api/admin/company/driver"; // implemented arif
const UPDATE_DRIVER = API_URL + "/api/admin/company/driver"; // implemented arif
const GET_ALL_COACHWISE_TRIP_ADMIN =
    API_URL + "/api/admin/company/trip/coach-wise-trip"; // implemented tushar

const GET_SEATPLAN_BY_ID = API_URL + "/api/admin/company/seat-plan/seat-plan-by-id"
const GET_COUNTER_BY_ROUTE_ID = API_URL + "/api/admin/company/counter/by-route-id"

// settings
const GET_ADMIN_SETTINGS = API_URL + "/api/v3/admin/settings"

// QR history
const ADMIN_DASHBOARD_WITH_QR = API_URL + "/api/v3/admin/dashboard";
const ADMIN_QR_HISTORY_LIST = API_URL + "/api/v3/admin/qr-history";
const ADMIN_QR_REFUND = API_URL + "/api/v3/admin/qr-history/refund";
const ADMIN_COUNTERMEN_FILTER_LIST = API_URL + "/api/v3/admin/counterman/get-all";

// payment settlement
const ADMIN_PAYMENT_SETTLEMENT_LIST = API_URL + "/api/v3/admin/withdraw-history";
const ADMIN_PAYMENT_SETTLEMENT_DETAILS = API_URL + "/api/v3/admin/withdraw-history/details";
const ADMIN_PAYMENT_SETTLEMENT_APPROVE = API_URL + "/api/v3/admin/withdraw-history/approve";
const ADMIN_PAYMENT_SETTLEMENT_REJECT = API_URL + "/api/v3/admin/withdraw-history/reject";

// qr tickets
const ADMIN_QR_TICKETS_LIST = API_URL + "/api/v3/admin/company/ticket/qr-tickets";
const ADMIN_QR_TICKET_CANCEL = API_URL + "/api/v3/admin/company/qr-ticket-cancel";

// Admin  end

// Owner Start
const GET_DIGITAL_TICKETING_ALL_STOPPAGE_OWNER = API_URL + "/api/owner/digital-ticketing/stoppage/all"
const GET_SEATPLAN_BY_ID_OWNER = API_URL + "/api/owner/services/seat-plan/seat-plan-by-id"
const GET_COUNTER_BY_ROUTE_ID_OWNER = API_URL + "/api/owner/services/counter/by-route-id"

const COPY_TRIP_DATA_OWNER = API_URL + "/api/v3/owner/services/trip-copy";
const GET_ALL_ROUTES_FOR_TRIPS_OWNER = API_URL + "/api/owner/services/route/all-route-sequence";
const OWNER_PRINT_COUNT_REPORT =
    API_URL + "/api/owner/report/print-count-report"; // implemented tushar
const AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API =
    API_URL + "/api/owner/report/agent-wise-report-details"; // implemented tushar
const AGENT_WISE_REPORT_OWNER_PANEL =
    API_URL + "/api/owner/report/agent-wise-report"; // implemented tushar
const COUNTERMAN_WISE_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner/report/counter-wise-report-details"; //arif
const GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL =
    API_URL + "/api/owner/services/counter/all/";
const COUNTER_WISE_REPORT_OWNER_PANEL =
    API_URL + "/api/owner/report/counter-wise-report";
const COACH_WISE_SEAT_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner/report/coach-wise-report-details"; // implemented tushar
const COACH_WISE_REPORT_OWNER = API_URL + "/api/owner/report/coach-wise-report"; // implemented tushar
const OWNER_DASHBOARD = API_URL + "/api/owner/dashboard"; // implemented tushar
const CHALAN_TRIP_WISE_REPORT_OWNER =
    API_URL + "/api/v3/owner/report/trip-wise-report"; // implemented tushar
const GET_ALL_COACHWISE_TRIP_OWNER =
    API_URL + "/api/v3/owner/services/trip/coach-wise-trip"; // implemented tushar
const GET_ALL_OWNERS_PANEL = API_URL + "/api/v3/owner/owners/"; //arif
const ADD_OWNER_PANEL = API_URL + "/api/v3/owner/owners/"; //arif
const UPDATE_OWNER_PANEL = API_URL + "/api/owner/owners/"; //arif
const GET_OWNER_SERVICES_STOPAGE = API_URL + "/api/v3/owner/services/stoppage"; //arif
const GET_OWNER_SERVICES_ROUTE = API_URL + "/api/v3/owner/services/route"; //arif
const GET_ALL_OWNER_SERVICES_STOPPAGE =
    API_URL + "/api/owner/services/stoppage/all"; //arif
const GET_ALL_COUNTERS_OWNER = API_URL + "/api/v3/owner/services/counter"; //arif
const GET_ALL_COUNTER_LIST_OWNER = API_URL + "/api/owner/services/counter/all"; //ARIF
const GET_ALL_STOPPAGES_OWNER = API_URL + "/api/owner/services/stoppage/all"; //arif
const ADD_COUNTER_OWNER = API_URL + "/api/v3/owner/services/counter"; //arif
const UPDATE_COUNTER_OWNER = API_URL + "/api/v3/owner/services/counter"; //arif
const GET_ALL_COUNTERMANS_OWNER = API_URL + "/api/v3/owner/services/counterman"; //arif
const ADD_COUNTERMAN_OWNER = API_URL + "/api/v3/owner/services/counterman"; //arif
const UPDATE_COUNTERMAN_OWNER = API_URL + "/api/v3/owner/services/counterman"; //arif
const GET_ALL_ROUTES_OWNER = API_URL + "/api/owner/services/route/all"; //arif
const GET_ROUTES_OWNER = API_URL + "/api/v3/owner/services/route"; //arif
const GET_SEAT_CLASS_OWNER = API_URL + "/api/v3/owner/services/seat-class"; //arif
const ADD_SEAT_CLASS_OWNER = API_URL + "/api/v3/owner/services/seat-class"; //arif
const UPDATE_SEAT_CLASS_OWNER = API_URL + "/api/v3/owner/services/seat-class"; //arif
const ADD_SEAT_ROW_OWNER = API_URL + "/api/v3/owner/services/seat-plan/seat-row"; //arif
const UPDATE_SEAT_ROW_OWNER =
    API_URL + "/api/v3/owner/services/seat-plan/seat-row"; //arif
const DELETE_SEAT_ROW_OWNER =
    API_URL + "/api/v3/owner/services/seat-plan/seat-row"; //arif
const GET_SEAT_PLAN_OWNER = API_URL + "/api/v3/owner/services/seat-plan"; //arif
const ADD_SEAT_PLAN_OWNER = API_URL + "/api/v3/owner/services/seat-plan"; //arif
const UPDATE_SEAT_PLAN_OWNER = API_URL + "/api/v3/owner/services/seat-plan"; //arif
const GET_ALL_SEAT_PLAN_OWNER = API_URL + "/api/owner/services/seat-plan/all"; //arif
const GET_SEAT_FARE_OWNER = API_URL + "/api/v3/owner/services/fare"; //arif
const DELETE_FARE_OWNER = API_URL + "/api/v3/owner/services/fare"; //arif
const UPDATE_FARE_OWNER = API_URL + "/api/v3/owner/services/fare/update"; //arif
const ADD_SEAT_FARE_OWNER = API_URL + "/api/v3/owner/services/fare"; //arif
const SEQUENCE_ROUTE_FILTER_OWNER =
    API_URL + "/api/owner/services/route/route-sequence"; //arif
const ADD_FARE_MODALITY_OWNER = API_URL + "/api/v3/owner/services/fare/fare-row"; //arif
const UPDATE_FARE_MODALITY_OWNER =
    API_URL + "/api/v3/owner/services/fare/fare-row"; //arif
const DELETE_FARE_MODALITY_OWNER =
    API_URL + "/api/v3/owner/services/fare/fare-row"; //arif
const GET_ALL_COACH_OWNER = API_URL + "/api/v3/owner/services/coach"; //arif
const ADD_COACH_OWNER = API_URL + "/api/v3/owner/services/coach"; //arif
const UPDATE_COACH_OWNER = API_URL + "/api/v3/owner/services/coach"; //arif
const GET_ALL_ROUTES_NEW_OWNER = API_URL + "/api/v3/owner/services/route"; //ARIF
const GET_TRIP_DATA_OWNER = API_URL + "/api/owner/services/trip";


const GET_ALL_STOPPAGES_OWNER_NEW = API_URL + "/api/v3/owner/services/stoppage"; // ARIF
const GET_ALL_ROUTES_FILTER_OWNER = API_URL + "/api/owner/services/route/all"; // ARIF
const GET_ALL_STOPPAGE_OWNER = API_URL + "/api/owner/services/stoppage/all"; //ARIF
const GET_CHALAN_REPORT = API_URL + "/api/v3/owner/report/chalan-report"; // implemented tushar
const GET_ALL_LIST_COACH_OWNER = API_URL + "/api/owner/services/coach/all"; // implemented tushar

// QR history owner
const OWNER_DASHBOARD_WITH_QR = API_URL + "/api/v3/owner/dashboard";

// Owner end

// Counterman  startGET_ALL_LIST_COACH
const GET_COUNTERMAN_CHALAN_REPORT = API_URL + "/api/v3/counterman/report/chalan-report";
const GET_SINGLE_TRIP_LOG_HISTORY = API_URL + "/api/counterman/trip/all-trip-history"
const TICKET_BY_TRIP = API_URL + "/api/v3/counterman/dashboard/tickets-by-trip"; // implemented tushar counterman
const REFUND_RESERVED_TICKET_PAYMENT = API_URL + "/api/counterman/dashboard/refund-reserve-amount"; // implemented tushar counterman
const COUNTERMAN_ALL_COACH = API_URL + "/api/counterman/coach"; // implemented tushar counterman
const COUNTER_ALL_TRIPS = API_URL + "/api/counterman/trip"; // implemented tushar counterman
const GET_ALL_TRIPS_FOR_TICKETING = API_URL + "/api/v3/counterman/dashboard"; // implemented tushar counterman
const TICKET_PURCHASE = API_URL + "/api/v3/counterman/dashboard/ticket-purchase"; // implemented tushar counterman
const TICKET_RESERVE = API_URL + "/api/v3/counterman/dashboard/reserve-ticket"; // implemented arif
const GET_ALL_COUNTERMAN_STOPPAGES = API_URL + "/api/counterman/stoppage/all"; // implemented tushar counterman
const GET_ALL_COUNTERMAN_SHIPS = API_URL + "/api/counterman/coach/all"; // implemented tushar counterman
const GET_ALL_TICKETS_HISTORY =
    API_URL + "/api/counterman/dashboard/ticket-history"; // implemented tushar counterman
const TICKET_CANCEL_COUNTERMAN =
    API_URL + "/api/v3/counterman/dashboard/ticket-cancel"; // implemented tushar counterman
const RESERVATION_TICKET_CANCEL_COUNTERMAN =
    API_URL + "/api/counterman/dashboard/reserve-cancel";
const RESERVED_TICKET_PAYMENT = API_URL + "/api/v3/counterman/dashboard/pay-reserve-ticket";
const EXTRA_TICKET_SEATS =
    API_URL + "/api/v3/counterman/dashboard/extra-ticket-purchase"; // implemented tushar counterman
const EXTRA_TICKET_GOODS =
    API_URL + "/api/v3/counterman/dashboard/goods-ticket-purchase"; // implemented tushar counterman
const EXTRA_TICKET_CANCEL =
    API_URL + "/api/counterman/dashboard/extra-ticket-cancel"; // implemented tushar counterman
const GOODS_TICKET_CANCEL =
    API_URL + "/api/counterman/dashboard/goods-ticket-cancel"; // implemented tushar counterman
const LAST_TICKET_PRINT_COUNTERMAN =
    API_URL + "/api/counterman/dashboard/last-ticket"; // implemented tushar counterman
const TICKET_PRINT_COUNT = API_URL + "/api/counterman/dashboard/print-count"; // implemented tushar counterman
const TRIP_PASS = API_URL + "/api/counterman/dashboard/trip-pass"; // implemented tushar counterman
const PNR_TICKET = API_URL + "/api/counterman/dashboard/pnr-ticket"; // implemented tushar counterman
const UPDATE_TRIP = API_URL + "/api/counterman/dashboard/update-trip"; // implemented tushar counterman
const ALL_DRIVER = API_URL + "/api/counterman/driver/all"; // implemented tushar counterman
const ALL_SUPERVISOR = API_URL + "/api/counterman/supervisor/all"; // implemented tushar counterman
const GET_ALL_COUNTERMAN_REPORT =
    API_URL + "/api/v3/counterman/report/counterman-report"; //arif
const GET_COUNTERMAN_TRIPWISE_REPORT =
    API_URL + "/api/counterman/report/trip-report"; //arif
const GET_COUNTERMAN_TRIPWISE_REPORT_V3 =
    API_URL + "/api/v3/counterman/report/trip-report"; //arif
const COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE = API_URL + "/api/counterman/dashboard/passenger-info-by-phone";
const GET_ADMIN_RESERVATION_HISTORY = API_URL + "/api/admin/report/trip-reserve-list";
const GET_ADMIN_RESERVATION_HISTORY_DETAILS = API_URL + "/api/admin/report/trip-reserve-details";
const GET_OWNER_RESERVATION_HISTORY = API_URL + "/api/owner/report/trip-reserve-list";
const GET_OWNER_RESERVATION_HISTORY_DETAILS = API_URL + "/api/owner/report/trip-reserve-details";
const GET_COUNTERMAN_RESERVATION_HISTORY = API_URL + "/api/counterman/report/trip-reserve-list";
const GET_COUNTERMAN_RESERVATION_HISTORY_DETAILS = API_URL + "/api/counterman/report/trip-reserve-details";
const GET_COUNTERMAN_RESERVATION_REPORTS = API_URL + "/api/v3/counterman/report/trip-reserve-list";
const GET_COUNTERMAN_RESERVATION_REPORT_DETAILS = API_URL + "/api/v3/counterman/report/trip-reserve-details";
const GET_COUNTERMAN_REPORT_DETAILS = API_URL + "/api/v3/counterman/report/counterman-report-details";

// Counterman  end

// agent start

const GET_ALL_TRIPS_FOR_TICKETING_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard"; // implemented tushar agent
const TICKET_PURCHASE_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/ticket-purchase"; // implemented tushar agent
const TICKET_CANCEL_COUNTERMAN_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/ticket-cancel"; // implemented tushar agent
const LAST_TICKET_PRINT_COUNTERMAN_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/last-ticket"; // implemented tushar agent
const PNR_TICKET_AGENT_INTERCITY = API_URL + "/api/agent/dashboard/pnr-ticket"; // implemented tushar agent
const TICKET_PRINT_COUNT_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/print-count"; // implemented tushar agent
const EXTRA_TICKET_SEATS_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/extra-ticket-purchase"; // implemented tushar agent
const EXTRA_TICKET_CANCEL_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/extra-ticket-cancel"; // implemented tushar agent
const UPDATE_TRIP_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/update-trip"; // implemented tushar agent
const TRIP_PASS_AGENT_INTERCITY = API_URL + "/api/agent/dashboard/trip-pass"; // implemented tushar agent
const GET_ALL_TICKETS_HISTORY_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/ticket-history"; // implemented tushar agent
const EXTRA_TICKET_GOODS_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/goods-ticket-purchase"; // implemented tushar agent
const GOODS_TICKET_CANCEL_AGENT_INTERCITY =
    API_URL + "/api/agent/dashboard/goods-ticket-cancel"; // implemented tushar agent
const COUNTER_ALL_TRIPS_AGENT_INTERCITY = API_URL + "/api/agent/trip"; // implemented tushar agent
const COUNTERMAN_ALL_COACH_AGENT_INTERCITY = API_URL + "/api/agent/coach"; // implemented tushar agent
const GET_ALL_COUNTERMAN_SHIPS_AGENT_INTERCITY =
    API_URL + "/api/agent/coach/all"; // implemented tushar agent
const GET_ALL_COUNTERMAN_STOPPAGES_AGENT_INTERCITY =
    API_URL + "/api/agent/stoppage/all"; // implemented tushar agent
const ALL_DRIVER_AGENT_INTERCITY = API_URL + "/api/agent/driver/all"; // implemented tushar agent
const ALL_SUPERVISOR_AGENT_INTERCITY = API_URL + "/api/agent/supervisor/all"; // implemented tushar agent
const AGENT_WISE_REPORT_ADMIN = API_URL + "/api/admin/report/agent-wise-report"; //ARIF

// agent end

// Extra Start

const GET_ALL_COUNTERS_IN_FILTER = API_URL + "/api/admin/company/counter/all";
const GET_ALL_DECKMANS = API_URL + "/api/deckman";
const ADD_DECKMAN = API_URL + "/api/deckman";
const UPDATE_DECKMAN = API_URL + "/api/deckman";

const GET_ALL_SHIPS_BY_ROUTE = API_URL + "/api/ship/by-route";
const GET_ALL_SHIPS_BY_DECK = API_URL + "/api/ship/by-deck";
const ADD_SHIP = API_URL + "/api/ship";
const UPDATE_SHIP = API_URL + "/api/ship";
const GET_SHIP_INFO = API_URL + "/api/ship/info";

// const GET_ALL_SEAT_CLASSES = API_URL + "/api/seat-class"
// const ADD_SEAT_CLASS = API_URL + "/api/seat-plan/add-class"
// const UPDATE_SEAT_CLASS = API_URL + "/api/seat-plan/update-class"
// const DELETE_SEAT_CLASS = API_URL + "/api/seat-plan/delete-class"

const ADD_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/edit-seat-row";
const DELETE_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row";
const SEAT_BLOCK = API_URL + "/api/seat-plan/seat-blocking";

const PRINT_COUNT = API_URL + "/api/report/print-count-report";
const SHIP_WISE_REPORT = API_URL + "/api/report/ship-wise-report";
const COUNTER_WISE_REPORT = API_URL + "/api/admin/report/counter-wise-report";
const SHIP_WISE_DECK_REPORT_DETAILS =
    API_URL + "/api/report/ship-wise-report-details";

const SHIP_WISE_GOOD_REPORT_DETAILS =
    API_URL + "/api/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_DETAILS =
    API_URL + "/api/report/counter-wise-report-details";

const GET_ALL_TRIPS = API_URL + "/api/trip";
const ADD_TRIP = API_URL + "/api/trip";
const TRIP_COMPLETED = API_URL + "/api/trip/complete";
const GET_ALL_DASHBOARD_TICKETS = API_URL + "/api/dashboard";

const GET_ALL_COUNTERMAN_ROUTES = API_URL + "/api/route";
const GET_ALL_COUNTERMAN_COUNTERS = API_URL + "/api/counter";
const COUNTER_ALL_SHIPS = API_URL + "/api/counterman/ship";
const TICKETS_HISTORY_COUNTER = API_URL + "/api/counterman/ticket/history";
const TICKET_CANCEL = API_URL + "/api/counterman/ticket/cancel";

const GET_LAST_TICKET = API_URL + "/api/counterman/ticket/print-last-one";
const REPORT_PRINT_COUNT =
    API_URL + "/api/counterman/report/report-print-count";
const REPORT_PRINT_COUNT_INCREASE =
    API_URL + "/api/counterman/report/report-print-count-increase";

const GET_ALL_AGENT_STOPPAGES = API_URL + "/api/agent/dashboard/stoppage";
const GET_ALL_AGENT_SHIPS = API_URL + "/api/agent/dashboard/ship";
const GET_ALL_TRIPS_FOR_TICKETING_BY_AGENT = API_URL + "/api/agent/dashboard";
const GET_LAST_TICKET_BY_AGENT = API_URL + "/api/agent/ticket/print-last-one";
const AGENT_ALL_SHIPS = API_URL + "/api/agent/ship";
const AGENT_ALL_TRIPS = API_URL + "/api/agent/trip";
const GET_AGENT_TRIPWISE_REPORT = API_URL + "/api/agent/report/trip-wise";
const GET_ALL_AGENT_REPORT = API_URL + "/api/agent/report";
const TICKET_PURCHASE_BY_AGENT = API_URL + "/api/agent/ticket/purchase";
const AGENT_LIST = API_URL + "/api/agent";
const AGENT_ADD = API_URL + "/api/agent";
const AGENT_WISE_REPORT = API_URL + "/api/report/agent-wise-report";

const AGENT_WISE_SEAT_REPORT_DETAILS =
    API_URL + "/api/report/agent-wise-report-details";
const GET_AGENT_REPORT = API_URL + "/api/agent/report/agent-report";
const GET_AGENT_TRIP_REPORT = API_URL + "/api/agent/report/trip-report";
const REPORT_PRINT_COUNT_AGENT =
    API_URL + "/api/agent/report/report-print-count";
const REPORT_PRINT_COUNT_INCREASE_AGENT =
    API_URL + "/api/agent/report/report-print-count-increase";

const GET_ALL_DASHBOARD_TICKETS_OWNER = API_URL + "/api/owner-panel/dashboard";
const SHIP_WISE_REPORT_OWNER =
    API_URL + "/api/owner-panel/report/ship-wise-report";
const GET_ALL_SHIPS_OWNER = API_URL + "/api/owner-panel/ship";
const GET_ALL_OWNER_SHIPS_BY_DECK = API_URL + "/api/owner-panel/ship/by-deck";
const GET_ALL_COUNTER_OWNER = API_URL + "/api/owner-panel/counter";
const SHIP_WISE_DECK_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_GOOD_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_SEAT_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner-panel/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_OWNER =
    API_URL + "/api/owner-panel/report/counter-wise-report";
const COUNTER_WISE_DETAILS_REPORT_OWNER =
    API_URL + "/api/owner-panel/report/counter-wise-report-details";
const AGENT_WISE_REPORT_OWNER =
    API_URL + "/api/owner-panel/report/agent-wise-report";
const AGENT_WISE_REPORT_DETAILS_OWNER =
    API_URL + "/api/owner-panel/report/agent-wise-report-details";
const GET_OWNER_SERVICES_BOARDING_PLACE =
    API_URL + "/api/v3/owner/services/route/boarding-point";
const GET_OWNER_SERVICES_DROPPING_PLACE =
    API_URL + "/api/v3/owner/services/route/dropping-point";
const GET_OWNER_SERVICES_TRIPS = API_URL + "/api/owner-panel/trip";
const GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE =
    API_URL + "/api/owner-panel/ship/by-route";
const GET_ALL_OWNER_SERVICES_SHIP_INFO = API_URL + "/api/owner-panel/ship/info";
const AGENT_LIST_OWNER = API_URL + "/api/owner-panel/agent";
const AGENT_ADD_OWNER = API_URL + "/api/owner-panel/agent";
const GET_ALL_DECKMANS_OWNER = API_URL + "/api/owner-panel/deckman";
const ADD_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const UPDATE_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const ADD_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const UPDATE_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const GET_ALL_SEAT_CLASS_OWNER = API_URL + "/api/owner/services/seat-class/all";
const DELETE_SEAT_CLASS_OWNER =
    API_URL + "/api/owner-panel/seat-plan/delete-class";

const ADD_CUSTOM_SEAT_ROW_BY_OWNER =
    API_URL + "/api/owner-panel/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW_BY_OWNER =
    API_URL + "/api/owner-panel/seat-plan/edit-seat-row";
const SEAT_BLOCK_OWNER = API_URL + "/api/owner-panel/seat-plan/seat-blocking";

const AGENT_RECHARGE_BALANCE =
    API_URL + "/api/v3/owner/services/agent/balance-recharge";
const PRINT_COUNT_OWNER =
    API_URL + "/api/owner-panel/report/print-count-report";
const GET_AGENTS_OWNER = API_URL + "/api/v3/owner/services/agent";
const ADD_AGENT_OWNER = API_URL + "/api/v3/owner/services/agent";
const UPDATE_AGENT_OWNER = API_URL + "/api/v3/owner/services/agent";

const GET_SUPERVISORS_OWNER = API_URL + "/api/v3/owner/services/supervisor";
const ADD_SUPERVISOR_OWNER = API_URL + "/api/v3/owner/services/supervisor";
const UPDATE_SUPERVISOR_OWNER = API_URL + "/api/v3/owner/services/supervisor";
const GET_DRIVERS_OWNER = API_URL + "/api/v3/owner/services/driver";
const ADD_DRIVER_OWNER = API_URL + "/api/v3/owner/services/driver";
const UPDATE_DRIVER_OWNER = API_URL + "/api/v3/owner/services/driver";
const GET_TRIPS_OWNER = API_URL + "/api/owner/services/trip";
const CANCEL_TRIP_OWNER = API_URL + "/api/v3/owner/services/trip/cancel";
const GET_FARE_ALL_BY_ROUTED_OWNER = API_URL + "/api/v3/owner/services/fare";

const ADD_TRIP_OWNER = API_URL + "/api/v3/owner/services/trip";
const UPDATE_TRIP_OWNER = API_URL + "/api/v3/owner/services/trip";

const GET_FARE_ALL_BY_ROUTEID_OWNER = API_URL + "/api/owner/services/fare/all";
const ADD_TRIP_DATA_OWNER = API_URL + "/api/v3/owner/services/trip";
const UPDATE_TRIP_DATA_OWNER = API_URL + "/api/v3/owner/services/trip";
const ROUTE_FARE_DELETE_OWNER = API_URL + "/api/v3/owner/services/fare/fare-row";

const GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/boarding-point";
const ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/boarding-point";
const UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/boarding-point";
const DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/boarding-point";
const GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/dropping-point";
const ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/dropping-point";
const UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/dropping-point";

const DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
    API_URL + "/api/v3/owner/services/stoppage/dropping-point";

const GET_DT_ROUTES =
    API_URL + "/api/admin/digital-ticketing/route";
const ADD_DT_ROUTES =
    API_URL + "/api/admin/digital-ticketing/route";
const UPDATE_DT_ROUTES =
    API_URL + "/api/admin/digital-ticketing/route";
const GET_DT_SEAT_FARE = API_URL + "/api/admin/digital-ticketing/fare";
const GET_ALL_DT_ROUTE = API_URL + "/api/admin/digital-ticketing/route/all";
const ADD_DT_SEAT_FARE = API_URL + "/api/admin/digital-ticketing/fare";
const DELETE_DT_SEAT_FARE = API_URL + "/api/admin/digital-ticketing/fare";
const UPDATE_DT_SEAT_FARE = API_URL + "/api/admin/digital-ticketing/fare";
const DT_ROUTE_SEQUENCE =
    API_URL + "/api/admin/digital-ticketing/route-sequence/by-route-id";
const ADD_DT_FARE_MODALITY = API_URL + "/api/admin/digital-ticketing//fare/fare-row";
const DELETE_DT_ROUTE_FARE = API_URL + "/api/admin/digital-ticketing//fare/fare-row";
const UPDATE_DT_ROUTE_FARE = API_URL + "/api/admin/digital-ticketing//fare/fare-row";
const GET_ALL_DT_FARE = API_URL + "/api/admin/digital-ticketing/dt-fare/all";
const GET_DT_FARE_BY_ID = API_URL + "/api/admin/digital-ticketing/dt-fare/one";
const CANCEL_DT_TICKET = API_URL + "/api/admin/ticket/dt-ticket-cancel";

const GET_ALL_TICKET_LAYOUTS = API_URL + "/api/admin/ticket-layout/all";
const GET_TICKET_LAYOUT = API_URL + "/api/admin/ticket-layout/one";
const UPDATE_TICKET_LAYOUT = API_URL + "/api/admin/ticket-layout/";
const GET_TICKET_LAYOUT_BY_COMPANY = API_URL + "/api/admin/company/ticket-layout/list";
const UPDATE_TICKET_LAYOUT_BY_COMPANY = API_URL + "/api/admin/company/ticket-layout/update";
const GET_ALL_TICKET_LAYOUT_BY_COMPANY = API_URL + "/api/admin/company/ticket-layouts/all";

const GET_TICKET_LAYOUT_COUNTERMAN = API_URL + "/api/v3/counterman/dashboard/get-ticket-layout";

const UPDATE_USER_PASSWORD = API_URL + "/api/v3/auth/change-password";





// Extra end

export default {
    ...apisV3,
    CANCEL_DT_TICKET,
    GET_TICKET_LAYOUT_COUNTERMAN,
    GET_ALL_TICKET_LAYOUT_BY_COMPANY,
    UPDATE_TICKET_LAYOUT_BY_COMPANY,
    GET_TICKET_LAYOUT_BY_COMPANY,
    UPDATE_TICKET_LAYOUT,
    GET_TICKET_LAYOUT,
    GET_ALL_TICKET_LAYOUTS,
    GET_DT_FARE_BY_ID,
    GET_ALL_DT_FARE,
    UPDATE_DT_ROUTE_FARE,
    DELETE_DT_ROUTE_FARE,
    ADD_DT_FARE_MODALITY,
    DT_ROUTE_SEQUENCE,
    UPDATE_DT_SEAT_FARE,
    DELETE_DT_SEAT_FARE,
    ADD_DT_SEAT_FARE,
    GET_ALL_DT_ROUTE,
    GET_DT_SEAT_FARE,
    ADD_DT_ROUTES,
    UPDATE_DT_ROUTES,
    GET_DT_ROUTES,
    GET_DIGITAL_TICKETING_STOPPAGES,
    GET_DIGITAL_TICKETING_STOPPAGE,
    ADD_DIGITAL_TICKETING_STOPPAGE,
    UPDATE_DIGITAL_TICKETING_STOPPAGE,
    GET_DIGITAL_TICKETING_ALL_STOPPAGE,
    GET_DIGITAL_TICKETING_BOARDING_PLACES,
    ADD_DIGITAL_TICKETING_BOARDING_PLACE,
    UPDATE_DIGITAL_TICKETING_BOARDING_PLACE,
    DELETE_DIGITAL_TICKETING_BOARDING_PLACE,
    GET_DIGITAL_TICKETING_DROPPING_PLACES,
    ADD_DIGITAL_TICKETING_DROPPING_PLACE,
    UPDATE_DIGITAL_TICKETING_DROPPING_PLACE,
    DELETE_DIGITAL_TICKETING_DROPPING_PLACE,
    
  
    GET_DIGITAL_TICKETING_ALL_STOPPAGE_OWNER,
    GET_SEATPLAN_BY_ID_OWNER,
    GET_COUNTER_BY_ROUTE_ID_OWNER,

    TRIP_DETAILS,
    LOGIN,
    LOGOUT,
    REFRESH,
    GET_ALL_USERS,
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    GET_ALL_COMPANIES,
    ADD_COMPANY,
    UPDATE_COMPANY,
    GET_ALL_OWNERS,
    ADD_OWNER,
    UPDATE_OWNER,
    GET_ALL_STOPPAGES,
    GET_ALL_STOPPAGES_OWNER_NEW,
    ADD_STOPPAGE,
    UPDATE_STOPPAGE,
    GET_ALL_BOARDING_PLACES,
    ADD_BOARDING_PLACE,
    UPDATE_BOARDING_PLACE,
    GET_ALL_DROPPING_PLACES,
    ADD_DROPPING_PLACE,
    UPDATE_DROPPING_PLACE,
    GET_ALL_BOARDING_PLACES_FOR_STOPPAGE,
    ADD_BOARDING_PLACE_FOR_STOPPAGE,
    UPDATE_BOARDING_PLACE_FOR_STOPPAGE,
    DELETE_BOARDING_PLACE_FOR_STOPPAGE,
    GET_ALL_DROPPING_PLACES_FOR_STOPPAGE,
    ADD_DROPPING_PLACE_FOR_STOPPAGE,
    UPDATE_DROPPING_PLACE_FOR_STOPPAGE,
    DELETE_DROPPING_PLACE_FOR_STOPPAGE,
    GET_SEATPLAN_BY_ID,
    GET_COUNTER_BY_ROUTE_ID,

    GET_ALL_ROUTES,
    GET_ALL_ROUTES_FOR_TRIPS,
    ADD_ROUTE,
    UPDATE_ROUTE,
    GET_ALL_COUNTERS,
    ADD_COUNTER,
    UPDATE_COUNTER,
    GET_ALL_COUNTERMANS,
    GET_COUNTERMAN_CHALAN_REPORT,
    GET_ALL_SUPERVISORS,
    ADD_SUPERVISOR,
    UPDATE_SUPERVISOR,
    GET_ALL_DRIVERS,
    ADD_DRIVER,
    UPDATE_DRIVER,
    ADD_COUNTERMAN,
    UPDATE_COUNTERMAN,
    GET_ALL_SHIPS_BY_ROUTE,
    GET_ALL_SHIPS_BY_DECK,
    ADD_SHIP,
    UPDATE_SHIP,
    // GET_ALL_SEAT_CLASSES,
    ADD_SEAT_CLASS,
    UPDATE_SEAT_CLASS,
    ADD_SEAT_ROW,
    ADD_CUSTOM_SEAT_ROW,
    EDIT_CUSTOM_SEAT_ROW,
    DELETE_SEAT_ROW,
    GET_ALL_TRIPS,
    ADD_TRIP,
    GET_ALL_DECKMANS,
    ADD_DECKMAN,
    UPDATE_DECKMAN,
    TRIP_COMPLETED,
    GET_SHIP_INFO,
    COMPANY_LIST,
    SHIP_WISE_REPORT,
    COUNTER_WISE_REPORT,
    COACH_WISE_SEAT_REPORT_DETAILS,
    COUNTERMAN_WISE_REPORT_DETAILS,
    SHIP_WISE_GOOD_REPORT_DETAILS,
    GET_ALL_TRIPS_FOR_TICKETING,
    GET_ALL_DASHBOARD_TICKETS,
    COUNTER_WISE_REPORT_DETAILS,
    TICKET_PURCHASE,
    TICKET_RESERVE,
    TICKET_PRINT_COUNT,
    PRINT_COUNT,
    REPORT_PRINT_COUNT,
    REPORT_PRINT_COUNT_INCREASE,
    COUNTER_ALL_SHIPS,
    COUNTER_ALL_TRIPS,
    GET_SINGLE_TRIP_LOG_HISTORY,
    TICKETS_HISTORY_COUNTER,
    TICKET_CANCEL,
    GET_ALL_COUNTERMAN_REPORT,
    GET_COUNTERMAN_TRIPWISE_REPORT,
    GET_COUNTERMAN_TRIPWISE_REPORT_V3,
    GET_LAST_TICKET,
    AGENT_LIST,
    AGENT_ADD,
    SEAT_BLOCK,
    AGENT_WISE_REPORT,
    AGENT_WISE_SEAT_REPORT_DETAILS,
    GET_ALL_DASHBOARD_TICKETS_OWNER,
    SHIP_WISE_REPORT_OWNER,
    GET_ALL_SHIPS_OWNER,
    SHIP_WISE_DECK_REPORT_DETAILS_OWNER,
    SHIP_WISE_GOOD_REPORT_DETAILS_OWNER,
    SHIP_WISE_SEAT_REPORT_DETAILS_OWNER,
    COUNTER_WISE_REPORT_OWNER,
    GET_ALL_OWNER_SHIPS_BY_DECK,
    GET_ALL_COUNTER_OWNER,
    COUNTER_WISE_DETAILS_REPORT_OWNER,
    AGENT_WISE_REPORT_OWNER,
    AGENT_WISE_REPORT_DETAILS_OWNER,
    GET_ALL_TRIPS_FOR_TICKETING_BY_AGENT,
    GET_LAST_TICKET_BY_AGENT,
    GET_AGENT_TRIPWISE_REPORT,
    GET_ALL_AGENT_REPORT,
    TICKET_PURCHASE_BY_AGENT,
    AGENT_ALL_SHIPS,
    AGENT_ALL_TRIPS,
    GET_ALL_OWNERS_PANEL,
    ADD_OWNER_PANEL,
    UPDATE_OWNER_PANEL,
    // DELETE_OWNER_PANEL,
    AGENT_LIST_OWNER,
    AGENT_ADD_OWNER,
    GET_ALL_DECKMANS_OWNER,
    ADD_DECKMAN_OWNER,
    UPDATE_DECKMAN_OWNER,
    GET_ALL_COUNTERS_OWNER,
    GET_ALL_STOPPAGES_OWNER,
    ADD_COUNTER_OWNER,
    UPDATE_COUNTER_OWNER,
    GET_ALL_COUNTERMANS_OWNER,
    ADD_COUNTERMAN_OWNER,
    UPDATE_COUNTERMAN_OWNER,
    GET_ALL_ROUTES_OWNER,
    ADD_SHIP_OWNER,
    UPDATE_SHIP_OWNER,
    GET_SEAT_CLASS_OWNER,
    ADD_SEAT_CLASS_OWNER,
    ADD_SEAT_ROW_OWNER,
    UPDATE_SEAT_ROW_OWNER,
    ADD_CUSTOM_SEAT_ROW_BY_OWNER,
    EDIT_CUSTOM_SEAT_ROW_BY_OWNER,
    SEAT_BLOCK_OWNER,
    DELETE_SEAT_CLASS_OWNER,
    UPDATE_SEAT_CLASS_OWNER,
    GET_OWNER_SERVICES_STOPAGE,
    GET_OWNER_SERVICES_ROUTE,
    GET_OWNER_SERVICES_BOARDING_PLACE,
    GET_OWNER_SERVICES_DROPPING_PLACE,
    GET_OWNER_SERVICES_TRIPS,
    GET_ALL_OWNER_SERVICES_STOPPAGE,
    GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE,
    GET_ALL_OWNER_SERVICES_SHIP_INFO,
    GET_ALL_COUNTERMAN_STOPPAGES,
    GET_ALL_COUNTERMAN_ROUTES,
    GET_ALL_COUNTERMAN_COUNTERS,
    GET_ALL_COUNTERMAN_SHIPS,
    GET_ALL_AGENT_STOPPAGES,
    GET_ALL_AGENT_SHIPS,
    DELETE_SEAT_ROW_OWNER,
    AGENT_RECHARGE_BALANCE,
    PRINT_COUNT_OWNER,
    GET_ALL_STOPPAGE,
    GET_ALL_STOPPAGE_OWNER,
    SEAT_CLASS,
    ADD_SEAT_PLAN,
    GET_SEAT_PLAN,
    GET_SEAT_PLAN_OWNER,
    ADD_SEAT_PLAN_OWNER,
    UPDATE_SEAT_PLAN_OWNER,
    GET_ALL_SEAT_PLAN_OWNER,
    UPDATE_SEAT_PLAN,
    UPDATE_SEAT_ROW,
    GET_ALL_ROUTES_FILTER,
    ADD_SEAT_FARE,
    GET_SEAT_FARE,
    GET_SEAT_FARE_OWNER,
    ADD_SEAT_FARE_OWNER,
    DELETE_FARE_OWNER,
    UPDATE_FARE_OWNER,
    DELETE_FARE,
    UPDATE_FARE,
    SEQUENCE_ROUTE_FILTER,
    SEQUENCE_ROUTE_FILTER_OWNER,
    GET_ALL_SEAT_CLASS,
    GET_ALL_SEAT_CLASS_OWNER,
    FARE_MODALITY_ADD,
    ADD_FARE_MODALITY_OWNER,
    UPDATE_FARE_MODALITY_OWNER,
    DELETE_FARE_MODALITY_OWNER,
    ADD_AGENT,
    UPDATE_AGENT,
    GET_ALL_AGENT,
    GET_AGENTS_OWNER,
    ADD_AGENT_OWNER,
    UPDATE_AGENT_OWNER,
    ROURE_FARE_DELETE,
    UPDATE_FARE_ROUTE,
    ADD_COACH,
    ADD_COACH_OWNER,
    UPDATE_COACH_OWNER,
    GET_ALL_SEATPLAN,
    GET_ALL_COACH,
    GET_ALL_COACH_OWNER,
    UPDATE_COACH,
    GET_ALL_LIST_COACH,
    GET_FARE_ALL_BY_ROUTEID,
    ADD_TRIP_DATA,
    GET_TRIP_DATA,
    UPDATE_TRIP_DATA,
    COPY_TRIP_DATA,
    CANCEL_TRIP,
    CANCEL_TRIP_IN_BULK,
    CANCEL_TRIP_OWNER,
    COUNTERMAN_ALL_COACH,
    GET_ALL_COUNTER_LIST_OWNER,
    GET_SUPERVISORS_OWNER,
    ADD_SUPERVISOR_OWNER,
    UPDATE_SUPERVISOR_OWNER,
    GET_DRIVERS_OWNER,
    ADD_DRIVER_OWNER,
    UPDATE_DRIVER_OWNER,
    GET_ALL_TICKETS_HISTORY,
    TICKET_CANCEL_COUNTERMAN,
    RESERVATION_TICKET_CANCEL_COUNTERMAN,
    RESERVED_TICKET_PAYMENT,
    GET_TRIPS_OWNER,
    GET_ALL_LIST_COACH_OWNER,
    GET_FARE_ALL_BY_ROUTED_OWNER,
    ADD_TRIP_OWNER,
    UPDATE_TRIP_OWNER,
    GET_ROUTES_OWNER,
    GET_ALL_ROUTES_NEW_OWNER,
    GET_TRIP_DATA_OWNER,
    GET_ALL_ROUTES_FILTER_OWNER,
    GET_FARE_ALL_BY_ROUTEID_OWNER,
    ADD_TRIP_DATA_OWNER,
    UPDATE_TRIP_DATA_OWNER,
    ROUTE_FARE_DELETE_OWNER,
    EXTRA_TICKET_SEATS,
    EXTRA_TICKET_GOODS,
    EXTRA_TICKET_CANCEL,
    GOODS_TICKET_CANCEL,
    LAST_TICKET_PRINT_COUNTERMAN,
    TRIP_PASS,
    PNR_TICKET,
    UPDATE_TRIP,
    ALL_DRIVER,
    ALL_SUPERVISOR,
    COACH_WISE_REPORT,
    ADMIN_DASHBOARD,
    ADMIN_PRINT_COUNT_REPORT,
    OWNER_TRIP_WISE_REPORT,

    COUNTERMAN_ALL_COACH_AGENT_INTERCITY,
    COUNTER_ALL_TRIPS_AGENT_INTERCITY,
    GET_ALL_TRIPS_FOR_TICKETING_AGENT_INTERCITY,
    TICKET_PURCHASE_AGENT_INTERCITY,
    GET_ALL_COUNTERMAN_STOPPAGES_AGENT_INTERCITY,
    GET_ALL_COUNTERMAN_SHIPS_AGENT_INTERCITY,
    GET_ALL_TICKETS_HISTORY_AGENT_INTERCITY,
    TICKET_CANCEL_COUNTERMAN_AGENT_INTERCITY,
    EXTRA_TICKET_SEATS_AGENT_INTERCITY,
    EXTRA_TICKET_GOODS_AGENT_INTERCITY,
    EXTRA_TICKET_CANCEL_AGENT_INTERCITY,
    GOODS_TICKET_CANCEL_AGENT_INTERCITY,
    LAST_TICKET_PRINT_COUNTERMAN_AGENT_INTERCITY,
    TICKET_PRINT_COUNT_AGENT_INTERCITY,
    TRIP_PASS_AGENT_INTERCITY,
    PNR_TICKET_AGENT_INTERCITY,
    UPDATE_TRIP_AGENT_INTERCITY,
    ALL_DRIVER_AGENT_INTERCITY,
    ALL_SUPERVISOR_AGENT_INTERCITY,
    SHIP_WISE_DECK_REPORT_DETAILS,
    GET_ALL_COUNTERS_IN_FILTER,
    GET_AGENT_REPORT,
    GET_AGENT_TRIP_REPORT,
    REPORT_PRINT_COUNT_AGENT,
    REPORT_PRINT_COUNT_INCREASE_AGENT,
    AGENT_WISE_REPORT_ADMIN,
    AGENT_WISE_REPORT_DETAILS,
    GET_SINGLE_COMPANY_DATA,
    GET_ALL_COACHWISE_TRIP_ADMIN,
    GET_ALL_COACHWISE_TRIP_OWNER,
    CHALAN_TRIP_WISE_REPORT_OWNER,
    GET_CHALAN_REPORT,
    OWNER_DASHBOARD,
    TICKET_BY_TRIP,
    COACH_WISE_REPORT_OWNER,
    COACH_WISE_SEAT_REPORT_DETAILS_OWNER,
    COUNTER_WISE_REPORT_OWNER_PANEL,
    GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL,
    COUNTERMAN_WISE_REPORT_DETAILS_OWNER,
    AGENT_WISE_REPORT_OWNER_PANEL,
    AGENT_WISE_REPORT_OWNER_PANEL_DETAILS_API,
    COPY_TRIP_DATA_OWNER,
    GET_ALL_ROUTES_FOR_TRIPS_OWNER,
    OWNER_PRINT_COUNT_REPORT,
    GET_ALL_COUNTERS_ALL,
    GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE,
    ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
    GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE,
    ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
    COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE,
    GET_ADMIN_RESERVATION_HISTORY,
    GET_ADMIN_RESERVATION_HISTORY_DETAILS,
    GET_OWNER_RESERVATION_HISTORY,
    GET_OWNER_RESERVATION_HISTORY_DETAILS,
    GET_COUNTERMAN_RESERVATION_HISTORY,
    GET_COUNTERMAN_RESERVATION_HISTORY_DETAILS,
    GET_COUNTERMAN_RESERVATION_REPORTS,
    GET_COUNTERMAN_RESERVATION_REPORT_DETAILS,
    GET_COUNTERMAN_REPORT_DETAILS,
    REFUND_RESERVED_TICKET_PAYMENT,
    EXTRA_TICKET_PRE_PURCHASE,
    TRIP_PASS_DETAILS,
    UPDATE_USER_PASSWORD,
    GET_ADMIN_SETTINGS,
    ADMIN_DASHBOARD_WITH_QR,
    ADMIN_QR_HISTORY_LIST,
    ADMIN_COUNTERMEN_FILTER_LIST,
    ADMIN_QR_REFUND,
    OWNER_DASHBOARD_WITH_QR,
    ADMIN_PAYMENT_SETTLEMENT_LIST,
    ADMIN_PAYMENT_SETTLEMENT_DETAILS,
    ADMIN_PAYMENT_SETTLEMENT_APPROVE,
    ADMIN_PAYMENT_SETTLEMENT_REJECT,
    ADMIN_QR_TICKETS_LIST,
    ADMIN_QR_TICKET_CANCEL
};
