<template>
  <div v-if="hasPermission(counterManRoles.ticketBooking)">
    <div class="accordion d-md-none">
      <div class="accordion-item">
        <h2 id="panelsStayOpen-headingOne" class="accordion-header">
          <button
              aria-controls="panelsStayOpen-collapseOne"
              aria-expanded="true"
              class="accordion-button collapsed p-2"
              data-bs-target="#panelsStayOpen-collapseOne"
              data-bs-toggle="collapse"
              type="button"
          >
            {{ t("Filter By", {}, {locale: selectedLanguage}) }}
          </button>
        </h2>
        <div
            id="panelsStayOpen-collapseOne"
            aria-labelledby="panelsStayOpen-headingOne"
            class="accordion-collapse collapse"
        >
          <div class="accordion-body p-0">
            <FilterTab>
              <template v-slot:body>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'from'"
                      v-model="pagination.from"
                      v-bind:defaultOption="'--select stoppage--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="getCountermanStoppageListForFilter"
                      v-bind:label="'From Stoppage'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'to'"
                      v-model="pagination.to"
                      v-bind:defaultOption="'--select stoppage--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="getCountermanStoppageListForFilter"
                      v-bind:label="'To Stoppage'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'Coach'"
                      v-model="pagination.coach"
                      v-bind:defaultOption="'--select coach--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="
                      $store.getters.getAllCoach
                    "
                      v-bind:label="'Coach'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <datePickerFilterCounterman
                      v-bind:id="'datePickerDate'"
                      v-model="pagination.date"
                      v-bind:label="'Date'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <label></label>
                  <div class="input-group mb-3 custom-control custom-checkbox">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input v-model="pagination.upcoming" aria-label="Checkbox for following text input" false-value="0" style="width: 20px;height: 21px"
                               true-value="1" type="checkbox">
                      </div>
                    </div>
                    <input aria-label="Text input with checkbox" class="form-control" disabled type="text"
                           :value="languageChange('UPCOMING_TRIPS')">
                  </div>
                </div>
                <div class="col-sm-6 col-md-2">
                  <form class="input-group global-filter-input" @submit.prevent="searchWithPnrPhone">
                    <label></label>
                    <input v-model="searchQuery" class="input_field form-control rounded font_detail py-2 height-34"
                           placeholder="PNR or Phone Number"
                           type="text">
                    <div class="input-group-append">
                      <button class="btn btn-secondary font-12" type="submit">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </template>
            </FilterTab>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'from'"
                v-model="pagination.from"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="getCountermanStoppageListForFilter"
                v-bind:label="'From Stoppage'"
            />
          </div>

          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'to'"
                v-model="pagination.to"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="getCountermanStoppageListForFilter"
                v-bind:label="'To Stoppage'"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'Coach'"
                v-model="pagination.coach"
                v-bind:defaultOption="'--select coach--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getAllCoach"
                v-bind:label="'Coach'"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <datePickerFilterCounterman
                v-bind:id="'datePickerDate'"
                v-model="pagination.date"
                v-bind:label="'Date'"
            />
          </div>
          <!--                <div class="col-sm-6 col-md-2">-->
          <!--                    <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'"-->
          <!--                                    v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                                    v-model="pagination.status"-->
          <!--                                    v-bind:defaultValue="''"-->
          <!--                                    v-bind:dropdown="status"/>-->
          <!--                </div>-->
          <div class="col-sm-6 col-md-2">
            <label></label>
            <div class="input-group mb-3 custom-control custom-checkbox">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input id="upcommingTrips" v-model="pagination.upcoming" aria-label="Checkbox for following text input" false-value="0"
                         style="width: 20px;height: 21px" true-value="1"
                         type="checkbox">
                </div>
              </div>
              <input aria-label="Text input with checkbox" class="form-control" disabled type="text"
                     :value="languageChange('UPCOMING_TRIPS')">
            </div>
          </div>
          <div class="col-sm-6 col-md-2">
            <form class="input-group global-filter-input" @submit.prevent="searchWithPnrPhone">
              <label></label>
              <input v-model="searchQuery" class="input_field form-control rounded font_detail py-2 height-34"
                     :placeholder="languageChange('pnr_or_phone')"
                     type="text">
              <div class="input-group-append">
                <button class="btn btn-secondary font-12" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </template>
      </FilterTab>
      <div v-if="addUpdate">
        <TripsCopyUpdateModal
            :pagination="pagination"
            v-bind:trip="tripId"
            :isCopy="copyActive"
            @close="close"
            @getAllTrips="getAllTripsForTicketing"
        />
      </div>
    </div>
    <div v-if="dashboardActive">
      <div class="table-responsive">
        <table
            class="table table-hover table-striped text-center vehicle_table"
        >
          <thead class="font_detail bg_corporate bording_table">
          <tr>
            <th class="font_detail">
              {{ t("sl", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail" style="width: 130px">
              {{
                t("TRIP TIME & DATE", {}, {locale: selectedLanguage})
              }}
            </th>
            <th class="font_detail">
              {{
                t("BOARDING TIME & DATE", {}, {locale: selectedLanguage})
              }}
            </th>
<!--            <th class="font_detail">-->
<!--              BOARDING TIME-->
<!--            </th>-->
<!--            <th class="font_detail">-->
<!--              {{ t("TRIP CODE", {}, {locale: selectedLanguage}) }}-->
<!--            </th>-->
            <th class="font_detail">
              {{ t("COACH", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail" >
              {{ t("ROUTE", {}, {locale: selectedLanguage}) }}
            </th>

            <th class="font_detail">
              {{ t("SEAT-PLAN", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail"  >
              {{ t("SEAT-CLASS", {}, {locale: selectedLanguage}) }}
            </th>
<!--            <th class="font_detail">-->
<!--              {{ t("DIRECTION", {}, {locale: selectedLanguage}) }}-->
<!--            </th>-->

            <th class="font_detail text-uppercase">
              {{ t("Ticket_Summary", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("FARE", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("ACTION", {}, {locale: selectedLanguage}) }}
            </th>
          </tr>
          </thead>
          <tbody
              v-for="(trip, index) in getTripsForTicketing"
              :key="trip._id"
              :ref="setTripRef"
              :data-trip-time="trip.tripDateTime"
              :data-trip-id="trip._id"
          >
          <tr v-if="!isMobile" :class="trip.tripDateTime === nearestTrip && 'nearest-trip-highlight'"
              class="bording_table">
            <td class="">
                <span class="">{{
                    index + 1 + (pagination.page - 1) * pagination.limit
                  }}</span>
            </td>

            <td >
              <span>
                  <span>
                    <span class="p-1"><i class="far fa-calendar-alt"></i></span>

                    <span class="">{{ dateFormat(trip.tripDateTime) }}</span>
                  </span>
                  <br/>
                  <span class="">
                    <span class="p-1 fs-6 fw-bold text-primary">
                      <i class="far fa-clock"></i>
                    </span>
                    <span class="fs-6 fw-bold text-primary" style="font-size: 12px !important;">
                        {{ timeFormat(trip.tripDateTime) }}
                    </span>
                  </span>
                </span>
            </td>

            <td >
                <span>
                  <span>
                    <span class="p-1"><i class="far fa-calendar-alt"></i></span>

                    <span
                        class="">{{ trip.boardingTripDateTime ? dateFormat(trip.boardingTripDateTime) : dateFormat(trip.tripDateTime) }}</span>
                  </span>
                  <br/>
                  <span class="">
                    <span class="p-1 fs-6 fw-bold text-primary">
                      <i class="far fa-clock"></i>
                    </span>
                    <span class="fs-6 fw-bold text-primary" style="font-size: 12px !important;">{{
                        trip.boardingTripDateTime ? timeFormat(trip.boardingTripDateTime) : timeFormat(trip.tripDateTime)
                      }}</span>
                  </span>
                </span>
            </td>


            <td >
              <span>{{ trip.coach }}</span>
            </td>
            <td >
              <span>{{ trip.route }}</span>
            </td>
            <td>
              <span>{{ trip.seatPlan }}</span>
            </td>
            <td >
                <span class="text-white px-2 py-1 rounded-pill bg-dark" style="font-size: 12px !important;">{{
                    trip.seatClass
                  }}</span>
            </td>

            <td >
              <div class="" style="font-width: 700 !important; color: #720707 !important">Sold: {{trip.sold}}</div>
              <div class="text-primary" style="font-width: 700 !important;">Reserved: {{trip.reserved}}</div>
              <div class="text-danger" style="font-width: 700 !important;">Available: {{trip.available}}</div>
            </td>
            <td class="">
              <span class=""> {{trip.fare}}</span>
            </td>

            <td style="min-width: 160px">
              <button
                  v-if="hasPermission(counterManRoles.tripCopy)"
                  class="copy_table_data font_detail copy_table_button"
                  @click="copyTrip(trip._id)"
              >
                <!--                <i class="fas fa-copy"></i>-->
                <span class="">{{ t("Copy", {}, {locale: selectedLanguage}) }}</span>
              </button>
              <button
                  class="custom_button-yellow px-0"
                  @click="showChalan(trip._id)"
              >
                <span class="mx-1">{{ t("Chalan", {}, {locale: selectedLanguage}) }}</span>
                <!-- <i class="ml-2 fas fa-chevron-up"></i> -->
              </button>
              <button
                  v-if="activeTrip !== trip._id"
                  class="custom_button btn btn-primary px-0"
                  @click="viewTrip(trip._id, trip.routeId, trip.coach, tripRef)"
              >
                <span class="mx-1">{{ t("View", {}, {locale: selectedLanguage}) }}</span>
                <!-- <i class="ml-2 fas fa-chevron-down"></i> -->
              </button>
              <button
                  v-else
                  class="custom_button btn btn-primary px-0"
                  @click="viewTrip('')"
              >
                  <span class="mx-1">{{
                      t("Close", {}, {locale: selectedLanguage})
                    }}</span>
                <!-- <i class="ml-2 fas fa-chevron-up"></i> -->
              </button>
            </td>
          </tr>
          <tr v-else :class="trip.tripDateTime === nearestTrip && 'nearest-trip-highlight'" class="bording_table_phone">
            <td colspan="14">
              <!-- mobile view start -->
              <div class="table_row_phone custom-font">
                <div class="table_single_row">
                  <div class="p-3 line_height itemShadow shadow">
                    <p class="text_size text-start">
                      <span class="fw-bold"> {{ trip.coach }}</span> -
                      {{ trip.seatClass }}
                    </p>
                    <p class="text-start">
                      <strong class="text-success">{{
                          trip.route
                        }}</strong>
                    </p>
                    <div class="d-flex gap-3">
                      <p class="text_size">
                        <i class="far fa-clock"> </i>
                        <strong class="icon_margin">
                          {{ timeFormat(trip.tripDateTime) }}
                        </strong>
                      </p>

                      <p class="text_size ms-5">
                        <i class="far fa-calendar-alt"> </i>
                        <strong class="icon_margin">
                          {{ dateFormat(trip.tripDateTime) }}
                        </strong>
                      </p>
                    </div>
                    <button
                        v-if="activeTrip !== trip._id"
                        class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                        @click="
                          viewTrip(trip._id, trip.routeId, trip.coach)
                        "
                    >
                        <span id="button_text2">{{
                            t("View Seat", {}, {locale: selectedLanguage})
                          }}</span>
                    </button>
                    <button
                        v-else
                        class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                        @click="viewTrip('')"
                    >
                        <span id="button_text3">{{
                            t("Close", {}, {locale: selectedLanguage})
                          }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- mobile view end  -->
            </td>
          </tr>

          <tr v-if="activeTrip === trip._id && tripDashboardActive">
            <td class="p-0" colspan="14">
              <TicketBooking
                  :paginationData="pagination"
                  :shouldRefreshPageGoBack="shouldRefreshPageGoBack"
                  :tripRef="tripRef"
                  @openGlobalFilter="openGlobalFilter"
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div class="row mx-5">
          <nav aria-label="Page navigation example">
            <ul
                class="pagination justify-content-center justify-content-sm-end"
            >
              <!--              <pagination-->
              <!--                  v-model="pagination.page"-->
              <!--                  :options="options"-->
              <!--                  :per-page="pagination.limit"-->
              <!--                  :records="$store.getters.getTotalTripsForTicketing"-->
              <!--                  @paginate="getAllTripsForTicketing"-->
              <!--              />-->
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <GlobalFilter
        v-else-if="globalFilterTab"
        @openGlobalFilter="openGlobalFilter"
    />
    <ChalanReports v-else-if="chalanActive" :trip-id="tripIdPass" @closeChalan="close"></ChalanReports>
    <TicketsDetails
        v-else
        :paginationData="pagination"
        :tripIdPass="tripIdPass"
        @close="close"
    />

    <div v-if="!activeTrip">
      <button class="counterman_floating_btn " @click="reload">
        <i class="fa fa-sync"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useStore} from 'vuex'
import {useI18n} from "vue-i18n";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import router from "@/router";
import web from "@/urls/web";
import dropdownVariables from "@/modules/dropdownVariables";
import TicketsDetails from "./TicketBooking/TicketsDetails.vue";
import datePickerFilterCounterman from "@/components/layouts/Filter/Inputs/DatePickerFilterCounterman.vue";
import TripsCopyUpdateModal from "@/views/Counterman/Dashboard/TripCopyModal";
import ChalanReports from '@/views/Counterman/Report/CahalanReports.vue';
import GlobalFilter from '@/views/Counterman/Dashboard/TicketBooking/GlobalFilter';
import TicketBooking from './TicketBooking/TicketBooking';
import {
  getAllTrips,
  getSearchResultWithPnrOrPhone,
  getViewTrip,
  setNearestTripScroll
} from "@/composables/counterman/trips"
import {dateFormat, dateTimeFormat, timeFormat} from "@/helpers/dateTimeFormat";

const {hasPermission} = permission();
const {Counterman: counterManRoles} = roles();

export default {
  name: "CountermanDashboard",
  components: {
    GlobalFilter,
    TicketBooking,
    TicketsDetails,
    datePickerFilterCounterman,
    ChalanReports,
    TripsCopyUpdateModal,
  },
  provide: {
    counterManRoles
  },
  setup() {
    const store = useStore();
    const {t, locale} = useI18n();
    const options = {
      texts: {
        count: "",
      },
    };

    let tripIdPass = ref("");
    const chalanActive = ref(false)
    const dashboardActive = ref(true);
    const globalFilterTab = ref(false);
    let searchQuery = ref('');
    const shouldRefreshPageGoBack = ref(false)
    const addUpdate = ref(false);
    const copyActive = ref(false);
    const tripId = ref("");

    //Mobile and Desktop list view handle start
    const isMobile = ref(false);

    onMounted(() => {
      onResize()
      window.addEventListener('resize', onResize)
      getDashboardAll()
      getTicketLayout()
      // nextTick(() => {
      //   findNearestTripe();
      // })
      // const nearestTimeoutMount = setTimeout(() => {
      //   findNearestTripe();
      //   clearTimeout(nearestTimeoutMount)
      // }, 1000)
    })

    const onResize = () => {
      isMobile.value = window.innerWidth < 768
    }
    //Mobile and Desktop list view handle end

    //composable variables
    const {viewTrip, tripDetails, activeTrip, tripDashboardActive} = getViewTrip(shouldRefreshPageGoBack);
    const {getAllTripsForTicketing, pagination} = getAllTrips();
    const {nearestTrip, setTripRef, findNearestTripe, tripRef} = setNearestTripScroll();
    const {SearchResultWithPnrOrPhone} = getSearchResultWithPnrOrPhone(dashboardActive, globalFilterTab);
    getAllTripsForTicketing();
    const getTripsForTicketing = computed(() => store.getters.getCountermanTripListByPage)
    const selectedLanguage = computed(() => store.getters.getLang)
    const userRoles = computed(() => store.getters.userRoles)
    const getCountermanStoppageListForFilter = computed(() => store.getters.getAllStoppage)
    const comingFrom = ref("")

    const copyTrip = (id) => {
      tripId.value = id;
      addUpdate.value = true;
      copyActive.value = true;
    }

    const Tickets = (tripId) => {
      tripIdPass.value = tripId;
      dashboardActive.value = false;
    };

    const close = () => {
      tripIdPass.value = "";
      dashboardActive.value = true;
      addUpdate.value = false;
      copyActive.value = false;
    };
    const openGlobalFilter = (value) => {
      dashboardActive.value = !value.isOpen;
      globalFilterTab.value = value.isOpen;
      shouldRefreshPageGoBack.value = !value.isOpen
      if (value.from === 'globalFilter' && comingFrom.value === 'search') {
        searchWithPnrPhone()
      } else {
        comingFrom.value = ''
      }
    }

    watch(
        () => userRoles,
        (value) => {
          if (!value?.includes(counterManRoles.ticketBooking)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );

    watch(
        () => pagination.upcoming,
        () => {
          pagination.page = 1
        }
    );
    // let nearestTimeout;
    watch(() => pagination, () => {
      // nearestTimeout = setTimeout(() => {
      //   findNearestTripe();
      //   clearTimeout(nearestTimeout)
      // }, 500)
      // nextTick(() => {
      //   findNearestTripe();
      // })
    }, {deep: true})

    const showChalan = (tripId) => {
      tripIdPass.value = tripId;
      chalanActive.value = true;
      dashboardActive.value = false;
    }

    const searchWithPnrPhone = () => {
      comingFrom.value = 'search'
      SearchResultWithPnrOrPhone(searchQuery.value)
      searchQuery.value = ''
    }

    const reload = () => {
      getAllTripsForTicketing();
    }

    const getDashboardAll = () => {
      store
          .dispatch("getDashboardAll")
          .then(() => { })
          .catch((error) => {
            if (error.status === 401) {
              getDashboardAll();
            }
          });
    };

    const getTicketLayout = () => {
      store
          .dispatch("getTicketLayoutCounterman")
          .then(() => {
          })
          .catch((error) => {
            if (error.status === 401) {
              getTicketLayout();
            }
          });
    };

    onBeforeUnmount(() => {
      //clearInterval(nearestTimeout)
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize)
      }
    })

    watch(() => store.getters.getCountermanTripListByPage, () => {
      nextTick(() => {
        findNearestTripe()
      })
    })

    const languageChange = (string) => {
      return t(string, {}, {locale: store.getters.getLang});
    }

    return {
      t,
      locale,
      activeTrip,
      dropdownVariables,
      tripIdPass,
      dashboardActive,
      hasPermission,
      counterManRoles,
      languageChange,
      pagination,
      options,
      viewTrip,
      Tickets,
      getAllTripsForTicketing,
      reload,
      dateFormat,
      timeFormat,
      tripDetails,
      tripDashboardActive,
      getTripsForTicketing,
      selectedLanguage,
      getCountermanStoppageListForFilter,
      setTripRef,
      nearestTrip,
      globalFilterTab,
      searchWithPnrPhone,
      searchQuery,
      openGlobalFilter,
      shouldRefreshPageGoBack,
      isMobile,
      dateTimeFormat,
      showChalan,
      chalanActive,
      copyTrip,
      tripId,
      copyActive,
      addUpdate,
      close,
      tripRef
    };
  },
};
</script>

<style scoped>
button.accordion-button {
  background-color: #242424;
  color: #fff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button::after {
  filter: invert(1);
}

/* responsive start  */
@media only screen and (min-width: 768px) {
  .bording_table_phone {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .bording_table {
    display: none;
  }

  .bording_table_content {
    display: none;
  }
}

/* responsive end  */
.phnbtn {
  width: 80%;
  background-color: #f04935;
  color: #fff;
  border: #f04935;
  border-radius: 5px 5px 5px 5px;
  /* margin-left: 20px !important; */
  padding: 10px;
}

.line_height {
  line-height: 10px;
}

.itemShadow {
  border: 1px solid #ddd;
  border-radius: 6px;
}

.custom-font {
  font-family: "Lato", sans-serif;
}

.icon_margin {
  margin-left: 5px;
}

.bording_table_phone {
  box-shadow: #0c0b0b;
  /* border: 1 px solid black; */
  /* box-shadow: orangered; */
}

.color-blue {
  color: blue;
}

.droping_place {
  color: red;
  font-weight: 700;
  letter-spacing: 2px;
}

.bording_place {
  color: #12b83e;
  font-weight: 700;
  letter-spacing: 2px;
}

.number5 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number6 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number1 {
  font-size: 16px;
  font-weight: 700;
  color: red;
}

.number4 {
  font-weight: 700;
  color: #ff9c07;
  letter-spacing: 2px;
  /* font-size: 16px; */
}

.number {
  font-size: 20px;
  font-weight: 700;
  color: #12b83e;
}

.font1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.custom_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.custom_button:hover,
.custom_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}
.custom_button-yellow {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.custom_button-yellow:hover,
.custom_button-yellow:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.font-12 {
  font-size: 14px;
}

.global-filter-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

.height-34 {
  height: 34px;
}

.nearest-trip-highlight {
  background: #59db756b;
}

#upComingTrips {
  height: 21px;
  width: 20px;
}

</style>
