<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form class="my-2">
        <div class="row">
          <div class="col-md-8 order-md-1 order-2">
            <div class="row">
              <span
                class="col-md-4 col-6 p-2"
                v-for="(seat, index) in ticketSeats"
                :key="seat + index"
              >
                <Checkbox
                  v-model="checked"
                  v-bind:label="seat"
                  v-bind:role="seat"
                  :bigger="true"
                  @toggleAllPermission="toggleAllPermission"
                />
              </span>
            </div>
          </div>
          <div
            class="col-md-4 text-end order-md-2 order-1"
            v-if="ticketType === 'SEAT'"
          >
            <div>
              <div class="form-check form-check-inline">
                <input
                  class="
                    form-check-input
                    all_permission_label
                    custom-size-input
                  "
                  type="checkbox"
                  id="select-all"
                  name="select-all"
                  @click="giveAllPermission"
                  v-model="allSelected"
                />
                <label
                  class="form-check-label all_permission_label custom-label"
                  for="select-all"
                >
                  Select All
                </label>
              </div>
            </div>
          </div>
        </div>

        <ModalError
          v-if="errors.cancelSeats"
          v-bind:error="errors.cancelSeats"
        />

        <div class="form-group row my-1 mt-3">
          <ModalDropdown
            v-model="ticketCancelData.cancellationCause"
            v-bind:id="'status'"
            v-bind:label="'Cancel reason'"
            v-bind:default-option="'Select Reason'"
            v-bind:default-value="''"
            v-bind:dropdown="ticketCancelReason"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.ReasonToCancelIssue"
                v-bind:error="errors.ReasonToCancelIssue"
              />
            </template>
          </ModalDropdown>
        </div>
        <div
          v-if="ticketCancelData.cancellationCause == 'অন্যান্য'"
          class="form-group row my-1"
        >
          <TextArea
            v-model="ticketCancelIssue"
            v-bind:placeholder="'Why are you cancelling Ticket?'"
            v-bind:id="'Cancel'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.TicketCancelIssue"
                v-bind:error="errors.TicketCancelIssue"
              />
            </template>
          </TextArea>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <button
              class="mt-3 py-2 mr-1 submit_button font_detail float-end"
              @click.prevent="ticketCancelConfirm"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, ref, onBeforeMount, reactive, watch } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
export default {
  components: {},
  name: "Cancellation cause",
  props: {
    ticketIdStore: {
      type: String,
    },
    ticketTotalSeats: {
      type: Array,
    },
    ticketTypeForProps: String,
  },
  setup(props, { emit }) {
    const ticketCancelData = reactive({
      ticketId: "",
      cancellableSeats: "",
      cancellationCause: "",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let ticketType = ref(props.ticketTypeForProps);
    let ticketCancelIssue = ref("");
    let ticketSeats = ref(props.ticketTotalSeats);
    const title = ref("Cancellation Cause");
    let checked = ref([]);
    let allSelected = ref(false);
    const ticketCancelReason = dropdownVariables.ticketCancelReason;
    let { requiredValidation, errors } = useInputValidation();
    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = ticketSeats.value;
      }
    };
    watch(
      () => checked.value,
      () => {
        if (ticketType.value === "SEAT") {
          requiredValidation("cancelSeats", checked.value);
        }
      }
    );
    watch(
      () => ticketCancelData.cancellationCause,
      () => {
        requiredValidation(
          "ReasonToCancelIssue",
          ticketCancelData.cancellationCause.trim()
        );
      }
    );
    watch(
      () => ticketCancelIssue.value,
      () => {
        requiredValidation("TicketCancelIssue", ticketCancelIssue.value.trim());
      }
    );
    const toggleAllPermission = () => {
      allSelected.value = checked.value.length === ticketSeats.value.length;
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = "";
      });
      requiredValidation(
        "ReasonToCancelIssue",
        ticketCancelData.cancellationCause.trim()
      );
      if (ticketType.value === "SEAT") {
        requiredValidation("cancelSeats", checked.value);
      }
      if (ticketCancelData.cancellationCause === "অন্যান্য") {
        requiredValidation("TicketCancelIssue", ticketCancelIssue.value.trim());
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const ticketCancelConfirm = () => {
      if (validate()) {
        showLoader();
        ticketCancelData.ticketId = props.ticketIdStore;
        if (ticketType.value === "SEAT") {
          ticketCancelData.cancellableSeats = checked.value
            .toString()
            .split(",");
        }
        if (ticketCancelData.cancellationCause === "অন্যান্য") {
          requiredValidation(
            "TicketCancelIssue",
            ticketCancelIssue.value.trim()
          );
          ticketCancelData.cancellationCause = ticketCancelIssue.value;
        }
        if (ticketType.value === "SEAT") {
          store
            .dispatch("cancelTicketAgentIntercity", ticketCancelData)
            .then(() => {
              emit("getAllTickets");
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              closeModal();
              if (error.status === 401) {
                emit("getAllTickets");
              }
            });
        } else if (ticketType.value === "EXTRA") {
          store
            .dispatch("extraTicketCancelAgentIntercity", ticketCancelData)
            .then(() => {
              emit("getAllTickets");
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              closeModal();
              if (error.status === 401) {
                emit("getAllTickets");
              }
            });
        } else if (ticketType.value === "GOODS") {
          store
            .dispatch("goodsTicketCancelAgentIntercity", ticketCancelData)
            .then(() => {
              emit("getAllTickets");
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              closeModal();
              if (error.status === 401) {
                emit("getAllTickets");
              }
            });
        }
      }
    };
    onBeforeMount(() => {});
    return {
      ticketCancelIssue,
      title,
      ticketCancelConfirm,
      closeModal,
      errors,
      ticketSeats,
      checked,
      giveAllPermission,
      toggleAllPermission,
      allSelected,
      ticketCancelReason,
      ticketCancelData,
      ticketType,
    };
  },
};
</script>
<style scoped>
.custom-size-input {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 1px;
  margin-right: 10px;
}
.custom-label {
  font-size: 16px;
  line-height: 0;
}
</style>
