<template>
  <FilterTab>
    <template v-slot:title
    ><h6>{{ title }}</h6></template
    >
    <template v-slot:body></template>
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i
        ><span class="mx-1 bold"> Go Back </span>
      </button>
    </template>
  </FilterTab>
  <form>
    <div class="row auth_form mx-0 pb-5">
      <div class="col-md-3">
        <div class="row my-2">
          <div class="col-md-12">
            <Dropdown
                v-bind:id="'route'"
                v-model="tripData.route"
                :defaultDisable="true"
                marginBottom="mb-1"
                v-bind:defaultOption="'---select a route---'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getRouteListForFilterAdmin"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Routes'"
                v-bind:trip="tripId ? 'active' : ''"
            />
            <Error v-if="errors.route" v-bind:error="errors.route"/>
            <Error v-if="error" v-bind:error="error"/>
          </div>
          <div v-if="tripId && !isCopyActive" class="col-md-12">
            <DatePickerFilter
                v-bind:id="'tripDate'"
                v-model="tripData.tripDate"
                :isModal="true"
                v-bind:label="'Trips Date'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.tripDate"
                    v-bind:error="errors.tripDate"
                />
              </template>
            </DatePickerFilter>
          </div>

          <div v-if="tripId && isCopyActive" class="col-md-12">
            <DatePickerFilter
                v-bind:id="'fromDate'"
                v-model="tripData.fromDate"
                :isModal="true"
                v-bind:label="'From'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.fromDate"
                    v-bind:error="errors.fromDate"
                />
              </template>
            </DatePickerFilter>
          </div>
          <div v-if="tripId && isCopyActive" class="col-md-12">
            <DatePickerFilter
                v-bind:id="'toDate'"
                v-model="tripData.toDate"
                :isModal="true"
                v-bind:label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" v-bind:error="errors.toDate"/>
              </template>
            </DatePickerFilter>
          </div>

          <div v-if="!tripId" class="col-md-12">
            <DatePickerFilter
                v-bind:id="'fromDate'"
                v-model="tripData.fromDate"
                :isModal="true"
                v-bind:label="'From'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.fromDate"
                    v-bind:error="errors.fromDate"
                />
              </template>
            </DatePickerFilter>
          </div>
          <div v-if="!tripId" class="col-md-12">
            <DatePickerFilter
                v-bind:id="'toDate'"
                v-model="tripData.toDate"
                :isModal="true"
                v-bind:label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" v-bind:error="errors.toDate"/>
              </template>
            </DatePickerFilter>
          </div>

          <div class="col-md-12">
            <TimePickerFilter
                v-bind:id="'tripTime'"
                v-model="tripData.tripTime"
                :isModal="true"
                v-bind:label="'Time'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.tripTime"
                    v-bind:error="errors.tripTime"
                />
              </template>
            </TimePickerFilter>
          </div>
          <div class="col-md-12">
            <Dropdown
                v-bind:id="'coach'"
                v-model="tripData.coach"
                marginBottom="mb-1"
                v-bind:defaultOption="'---select a coach---'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getAllCoachListForFilterAdmin"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Coach'"
            />
            <Error v-if="errors.coach" v-bind:error="errors.coach"/>
          </div>
          <div class="col-md-12">
            <Dropdown
                v-bind:id="'seatPlan'"
                v-model="tripData.seatPlan"
                marginBottom="mb-1"
                v-bind:defaultOption="'---select a seat plan---'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getSeatPlanListForFilterAdmin"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Seat Plan'"
            />
            <Error v-if="errors.seatPlan" v-bind:error="errors.seatPlan"/>
          </div>
          <div class="col-md-12">
            <Dropdown
                v-if="$store.getters.getStoppageListForFilter"
                v-bind:id="'fromStoppage'"
                v-model="tripData.fromStoppage"
                marginBottom="mb-1"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getStoppageListForFilter"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'From Stoppage'"
                v-bind:trip="'active'"
            />
            <Error v-if="errors.fromStoppage" :error="errors.fromStoppage"/>
          </div>
          <div class="col-md-12">
            <Dropdown
                v-if="$store.getters.getStoppageListForFilter"
                v-bind:id="'toStoppage'"
                v-model="tripData.toStoppage"
                marginBottom="mb-1"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getStoppageListForFilter"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'To Stoppage'"
                v-bind:trip="'active'"
            />
            <Error v-if="errors.toStoppage" :error="errors.toStoppage"/>
          </div>
          <div
              v-if="$store.getters.getSingleCompanyData?.fareType === 'trip_fare'"
              class="col-md-12"
          >
            <Input
                v-bind:id="'tripFare'"
                v-model="tripData.tripFare"
                v-bind:label="'Trip Fare'"
                v-bind:minNumber="true"
                v-bind:placeholder="'Enter Trip fare'"
                v-bind:type="'number'"
            />
            <Error v-if="errors.tripFare" v-bind:error="errors.tripFare"/>
          </div>
          <div v-if="isCopyActive" class="col-md-12">
            <Dropdown
                v-bind:id="'status'"
                v-model="tripData.status"
                marginBottom="mb-1"
                v-bind:defaultOption="'--select status--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="status"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Status'"
                v-bind:trip="'active'"
            />
          </div>
          <div v-else class="col-md-12">
            <Dropdown
                v-if="status"
                v-bind:id="'status'"
                v-model="tripData.status"
                marginBottom="mb-1"
                v-bind:dropdown="status"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Status'"
            />
          </div>

          <div
              v-if="companyData.allowQuota || companyData.allowDigitalTicketing"
              class="col-md-12"
          >
            <Dropdown
                v-bind:id="'quota'"
                v-model="tripData.quota"
                marginBottom="mb-1"
                v-bind:dropdown="quota"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Quota'"
            />
          </div>
          <div class="col-md-12">
            <Dropdown
                v-if="companyData.allowDigitalTicketing"
                v-bind:id="'enablefordt'"
                v-model="tripData.digitalTicketing"
                marginBottom="mb-1"
                v-bind:dropdown="digitalTicketing"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Digital Ticketing'"
            />
          </div>
          <div class="col-md-12">
            <Dropdown
                v-bind:id="'autoRenewal'"
                v-model="tripData.autoRenewal"
                marginBottom="mb-1"
                v-bind:dropdown="autoRenewal"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Auto Renewal'"
            />
          </div>
          <div class="col-md-12">
            <Dropdown
                v-if="parseInt(tripData.autoRenewal) === 1"
                v-bind:id="'autoRenewalInDays'"
                v-model="tripData.autoRenewalInDays"
                marginBottom="mb-1"
                v-bind:dropdown="autoRenewalInDays"
                v-bind:hideLabel="'inactive'"
                v-bind:label="'Auto Renewal In Days'"
            />
          </div>
        </div>
        <div class="form-group row mt-2">
          <div class="col-sm-12">
            <input
                v-if="!tripId"
                :disabled="isDisableSubmit"
                class="py-2 submit_button font_detail w-100"
                type="submit"
                @click.prevent="addTrip"
            />
            <input
                v-else-if="isCopyActive"
                :disabled="isDisableSubmit"
                class="py-2 submit_button font_detail w-100"
                type="submit"
                @click.prevent="copyTrip"
            />
            <input
                v-else
                :disabled="isDisableSubmit"
                class="py-2 submit_button font_detail w-100"
                type="submit"
                @click.prevent="updateTrip"
            />
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-header fw-bold">STOPPAGE WISE TIME</div>
          <div class="card-body scroll-height-300">
            <div class="form-group row mx-0 my-1">
              <div
                  v-for="stoppage in tripData.boardingTimeList"
                  :key="stoppage._id"
                  class="col-md-12 mb-2"
              >
                <div class="card h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <h6 class="card-title">
                          {{ stoppage.stoppageId.name }}
                        </h6>
                      </div>
                      <!--                      <div class="col-md-6">-->
                      <!--                        <div class="form-group row my-1">-->
                      <!--                          <ModalDropdown-->
                      <!--                              v-if="$store.getters.getDigitalTicketingAllStoppages"-->
                      <!--                              v-bind:id="'fromStoppageId'"-->
                      <!--                              v-model="stoppage.defaultBoarding"-->
                      <!--                              v-bind:defaultOption="'&#45;&#45;select city&#45;&#45;'"-->
                      <!--                              v-bind:defaultValue="''"-->
                      <!--                              v-bind:dropdown="$store.getters.getDigitalTicketingAllStoppages"-->
                      <!--                              v-bind:label="'Default DT Boarding'"-->
                      <!--                          >-->
                      <!--                            <template v-slot:error>-->
                      <!--                              <ModalError-->
                      <!--                                  v-if="errors.fromStoppageId"-->
                      <!--                                  v-bind:error="errors.fromStoppageId"-->
                      <!--                              />-->
                      <!--                            </template>-->
                      <!--                          </ModalDropdown>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>

                    <div class="row">
                      <div
                          v-for="(boardingTime, index) in stoppage.boardingTimes"
                          :key="index"
                          class="col-md-3"
                      >
                        <div class="card border-0 mb-2 p-0">
                          <div class="card-body p-0">
                            {{ boardingTime.name }}

                            <div class="input-group mb-3">
                              <input
                                  v-model="boardingTime.minutes"
                                  aria-describedby="basic-addon2"
                                  aria-label="Recipient's username"
                                  class="form-control"
                                  min="0"
                                  placeholder="Add Minutes"
                                  type="number"
                              />
                              <span
                                  id="basic-addon2"
                                  class="input-group-text"
                              >{{ addMinute(boardingTime.minutes) }}</span
                              >
                            </div>
                            <div
                                v-if="parseInt(tripData.digitalTicketing) === 1"
                                class="d-flex justify-content-between"
                            >
                              <div>
                                <CheckboxCommon
                                    id="dtenable"
                                    :key="index"
                                    v-model="boardingTime.enabledForDt"
                                    label="DT"
                                />
                              </div>
                              <div v-if="boardingTime.enabledForDt">
                                <CheckboxCommon
                                    id="default"
                                    :key="index"
                                    v-model="boardingTime.isDefault"
                                    label="Default"
                                    @click="
                                    handleDefaultDtBoarding(
                                      stoppage.stoppageId._id,
                                      boardingTime.sequence,
                                      boardingTime.isDefault
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CounterwiseQuota
            v-if="
            parseInt(tripData.quota) === 1 &&
            (companyData.allowQuota || companyData.allowDigitalTicketing) &&
            tripData.route &&
            tripData.seatPlan
          "
            :key="tripData.route"
            :company="companyData"
            :digital-ticketing="parseInt(tripData.digitalTicketing) === 1"
            :quota-auto-clear-time="tripData.quotaAutoClearTime"
            :seatPlanForDt="tripData.seatForDt"
            :seatPlanWiseQuota="tripData.quotaBasedSeatPlan"
            @getQuota="handleQuota"
            @getQuotaAutoClearTime="initQuotaAutoClearTime"
        />
        <div
            v-if="
            parseInt(tripData.digitalTicketing) === 1 &&
            companyData.allowDigitalTicketing &&
            parseInt(tripData.quota) === 1
          "
            class="card mt-4"
        >
          <div class="card-header fw-bold">DIGITAL TICKETING</div>
          <div class="card-body">
            <div class="row">
              <div
                  v-if="parseInt(tripData.digitalTicketing) === 1"
                  class="col-md-4"
              >
                <Dropdown
                    v-bind:id="'coachtype'"
                    v-model="tripData.coachType"
                    v-bind:dropdown="coachType"
                    v-bind:hideLabel="'inactive'"
                    v-bind:label="'Coach Type'"
                />
              </div>

              <div
                  v-if="parseInt(tripData.digitalTicketing) === 1"
                  class="col-md-4"
              >
                <Dropdown
                    v-bind:id="'dtroute'"
                    v-model="tripData.dtRoute"
                    :defaultDisable="true"
                    v-bind:defaultOption="'---select route---'"
                    v-bind:defaultValue="''"
                    v-bind:dropdown="$store.getters.getDtRouteListForFilterAdmin"
                    v-bind:hideLabel="'inactive'"
                    v-bind:label="'DT Route'"
                />
                <Error v-if="errors.dtRoute" v-bind:error="errors.dtRoute"/>
              </div>

              <!--              <div-->
              <!--                v-if="parseInt(tripData.digitalTicketing) === 1"-->
              <!--                class="col-md-4"-->
              <!--              >-->
              <!--                <Dropdown-->
              <!--                  v-bind:id="'dtfromstoppage'"-->
              <!--                  v-model="tripData.dtFromStoppage"-->
              <!--                  :defaultDisable="true"-->
              <!--                  v-bind:defaultOption="'-&#45;&#45;select city-&#45;&#45;'"-->
              <!--                  v-bind:defaultValue="''"-->
              <!--                  v-bind:dropdown="-->
              <!--                    $store.getters.getDigitalTicketingAllStoppages-->
              <!--                  "-->
              <!--                  v-bind:hideLabel="'inactive'"-->
              <!--                  v-bind:label="'City'"-->
              <!--                />-->
              <!--                <Error-->
              <!--                  v-if="errors.dtFromStoppage "-->
              <!--                  v-bind:error="errors.dtFromStoppage"-->
              <!--                />-->
              <!--                <Error-->
              <!--                    v-else-if="errors.duplication "-->
              <!--                    v-bind:error="errors.duplication"-->
              <!--                />-->
              <!--              </div>-->
              <!--              <div-->
              <!--                v-if="-->
              <!--                  parseInt(tripData.digitalTicketing) === 1 &&-->
              <!--                  $store.getters.getBoardingPlacesByStoppageId(-->
              <!--                    tripData.dtFromStoppage-->
              <!--                  )-->
              <!--                "-->
              <!--                class="col-md-4 mb-3"-->
              <!--                :key="tripData.dtFromStoppage"-->
              <!--              >-->
              <!--                <div class="mb-1 text-black">Boarding places</div>-->
              <!--                <Multiselect-->
              <!--                  placeholder="Select Boarding Places"-->
              <!--                  v-model="tripData.dtFromStoppageBoardings"-->
              <!--                  :options="-->
              <!--                    $store.getters.getBoardingPlacesByStoppageId(-->
              <!--                      tripData.dtFromStoppage-->
              <!--                    )-->
              <!--                  "-->
              <!--                  id="multiselectId"-->
              <!--                  mode="tags"-->
              <!--                  :searchable="true"-->
              <!--                  :createTag="true"-->
              <!--                  class="col font_detail w-100"-->
              <!--                />-->
              <!--                <Error-->
              <!--                  v-if="-->
              <!--                    !errors.dtFromStoppage && errors.dtFromStoppageBoardings-->
              <!--                  "-->
              <!--                  v-bind:error="errors.dtFromStoppageBoardings"-->
              <!--                />-->
              <!--              </div>-->

              <!--              <div v-if="boardingPlaceTime.length > 0" class="col-md-12 mb-4">-->
              <!--                <div class="card h-100">-->
              <!--                  <div class="card-body">-->
              <!--                    <h6 class="card-title mb-4 text-muted">-->
              <!--                      Digital ticketing boarding time-->
              <!--                    </h6>-->
              <!--                    <div class="row">-->
              <!--                      <div-->
              <!--                        v-for="(boardingTime, index) in boardingPlaceTime"-->
              <!--                        :key="index"-->
              <!--                        class="col-md-3"-->
              <!--                      >-->
              <!--                        <div class="card border-0 mb-2 p-0">-->
              <!--                          <div class="card-body p-0">-->
              <!--                            {{ boardingTime.name }}-->
              <!--                            <div class="input-group mb-3">-->
              <!--                              <input-->
              <!--                                type="number"-->
              <!--                                min="0"-->
              <!--                                class="form-control"-->
              <!--                                placeholder="Add Minutes"-->
              <!--                                aria-label="Recipient's username"-->
              <!--                                aria-describedby="basic-addon2"-->
              <!--                                v-model="boardingTime.time"-->
              <!--                              />-->
              <!--                              <span-->
              <!--                                class="input-group-text"-->
              <!--                                id="basic-addon2"-->
              <!--                                >{{ addMinute(boardingTime.time) }}</span-->
              <!--                              >-->
              <!--                            </div>-->
              <!--                          </div>-->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div-->
              <!--                v-if="parseInt(tripData.digitalTicketing) === 1"-->
              <!--                class="col-md-4"-->
              <!--              >-->
              <!--                <Dropdown-->
              <!--                  v-bind:id="'dttostoppage'"-->
              <!--                  v-model="tripData.dtStartStoppage"-->
              <!--                  :defaultDisable="true"-->
              <!--                  v-bind:defaultOption="'-&#45;&#45;select start stoppage-&#45;&#45;'"-->
              <!--                  v-bind:defaultValue="''"-->
              <!--                  v-bind:dropdown="dtFromStoppageBoardingsDropdown"-->
              <!--                  v-bind:hideLabel="'inactive'"-->
              <!--                  v-bind:label="'Start Stoppage'"-->
              <!--                />-->
              <!--                <Error-->
              <!--                  v-if="errors.dtStartStoppage"-->
              <!--                  v-bind:error="errors.dtStartStoppage"-->
              <!--                />-->
              <!--              </div>-->

              <!--              <div-->
              <!--                v-if="parseInt(tripData.digitalTicketing) === 1"-->
              <!--                class="col-md-4"-->
              <!--              >-->
              <!--                <Dropdown-->
              <!--                  v-bind:id="'dttostoppage'"-->
              <!--                  v-model="tripData.dtToStoppage"-->
              <!--                  :defaultDisable="true"-->
              <!--                  v-bind:defaultOption="'-&#45;&#45;select end stoppage-&#45;&#45;'"-->
              <!--                  v-bind:defaultValue="''"-->
              <!--                  v-bind:dropdown="-->
              <!--                    $store.getters.getDigitalTicketingAllStoppages-->
              <!--                  "-->
              <!--                  v-bind:hideLabel="'inactive'"-->
              <!--                  v-bind:label="'End Stoppage'"-->
              <!--                />-->
              <!--                <Error-->
              <!--                  v-if="errors.dtToStoppage"-->
              <!--                  v-bind:error="errors.dtToStoppage"-->
              <!--                />-->
              <!--                <Error-->
              <!--                    v-else-if="errors.duplication"-->
              <!--                    v-bind:error="errors.duplication"-->
              <!--                />-->
              <!--              </div>-->

              <!--              <div-->
              <!--                v-if="parseInt(tripData.digitalTicketing) === 1"-->
              <!--                class="col-md-4"-->
              <!--              >-->
              <!--                <Dropdown-->
              <!--                  v-bind:id="'dtendstoppage'"-->
              <!--                  v-model="tripData.dtEndStoppage"-->
              <!--                  :defaultDisable="true"-->
              <!--                  v-bind:defaultOption="'-&#45;&#45;select dropping place-&#45;&#45;'"-->
              <!--                  v-bind:defaultValue="''"-->
              <!--                  v-bind:dropdown="dtToStoppageDroppingsDropdown"-->
              <!--                  v-bind:hideLabel="'inactive'"-->
              <!--                  v-bind:label="'Dropping Place'"-->
              <!--                />-->
              <!--                <Error-->
              <!--                  v-if="errors.dtEndStoppage"-->
              <!--                  v-bind:error="errors.dtEndStoppage"-->
              <!--                />-->
              <!--              </div>-->

              <!--              <div-->
              <!--                  v-if="parseInt(tripData.digitalTicketing) === 1"-->
              <!--                  class="col-md-4"-->
              <!--              >-->
              <!--                <Dropdown-->
              <!--                    v-bind:id="'dtallfares'"-->
              <!--                    v-model="tripData.dtFare"-->
              <!--                    :defaultDisable="true"-->
              <!--                    v-bind:defaultOption="'-&#45;&#45;select dt fare-&#45;&#45;'"-->
              <!--                    v-bind:defaultValue="''"-->
              <!--                    v-bind:dropdown="$store.getters.getDtAllFareForFilter"-->
              <!--                    v-bind:hideLabel="'inactive'"-->
              <!--                    v-bind:label="'Dt Fare'"-->
              <!--                />-->
              <!--                <Error-->
              <!--                    v-if="errors.dtFare "-->
              <!--                    v-bind:error="errors.dtFare"-->
              <!--                />-->
              <!--                <Error-->
              <!--                    v-else-if="errors.Fare "-->
              <!--                    v-bind:error="errors.Fare"-->
              <!--                />-->
              <!--              </div>-->

              <!--              <div class="col-md-8"></div>-->
              <!--              <template v-if="tripData.dtFare" >-->
              <!--                <div v-for="(fareModality, index) in $store.getters.getDtFareModality" class="col-md-4" :key="index">-->
              <!--                  <div  class="card mb-2" >-->
              <!--                    <div class="card-body">-->
              <!--                      <div>Route: <span class="fw-bold">{{fareModality.fromStoppageId.name}} -> {{fareModality.toStoppageId.name}}</span></div>-->
              <!--                      <div>Fare: <span v-for="(fare, index) in fareModality.fare" :key="index"> ({{fare.class_name}} {{fare.fare}})<span v-if="!(fareModality.fare.length === index+1)">,</span></span></div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </template>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DatePickerFilter from "@/components/layouts/Filter/Inputs/DatePicker";
import CheckboxCommon from "@/components/layouts/Inputs/CheckboxCommon";
import CounterwiseQuota from "@/views/Admin/CompanyDeploy/Children/Trips/CounterwiseQuota";
import {timeFormat} from "@/helpers/dateTimeFormat";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import store from "@/store";
import {reactive} from "@vue/reactivity";
import moment from "moment";
import {computed, inject, nextTick, onBeforeMount, ref, watch} from "vue";
import {useRoute} from "vue-router";

export default {
  name: "TripsAddUpdateCopy",
  emits: ["close", "getAllTrips"],
  components: {DatePickerFilter, CounterwiseQuota, CheckboxCommon},
  props: {
    trip: String,
    isCopy: Boolean,
    routeList: Object,
    pagination: Object,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let {requiredValidation, validNumberValidation, errors} =
        useInputValidation();

    let tripData = reactive({
      id: "",
      companyId: route.query.id,
      fromDate: "",
      toDate: "",
      tripTime: "",
      route: "",
      fare: "",
      coach: "",
      seatPlan: "",
      driver: null,
      supervisor: null,
      fromStoppage: "",
      toStoppage: "",
      tripDate: "",
      blockedSeats: [],
      coachRegistrationNumber: "",
      tripFare: 0,
      status: "ACTIVE",
      quota: 0,
      boardingTimeList: [],
      quotaBasedSeatPlan: [],
      seatForDt: [],
      quotaAutoClearTime: 0,
      digitalTicketing: 0,
      coachType: "NON_AC",
      dtFromStoppage: "",
      dtToStoppage: "",
      dtFromStoppageBoardings: [],
      dtStartStoppage: "",
      dtBoardingTimes: [],
      dtEndStoppage: "",
      dtFare: "",
      dtRoute: "",
      defaultBoarding: null,
      autoRenewal: 0,
      autoRenewalInDays: 30,
    });
    const tripId = ref(props.trip);
    const isCopyActive = ref(props.isCopy);
    const showShipDropdown = ref(false);
    const ships = ref({});
    const direction = dropdownVariables.direction;
    const autoRenewal = dropdownVariables.boolean;
    const autoRenewalInDays = dropdownVariables.autoRenewalInDays;
    const title = ref("Add Trips");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let allRoutes = ref([]);
    let fareTypeObject = ref({});
    let fareType = ref("");
    let allCoaches = ref("");
    let error = ref("");
    let routeSequences = ref([]);
    const boardingMinutes = ref(0);
    const quota = dropdownVariables.quota;
    const digitalTicketing = dropdownVariables.digitalTicketing;
    const coachType = dropdownVariables.coachType;
    const boardingPlaceTime = ref([]);
    const isDisableSubmit = ref(false);
    const companyData = ref({});
    const firstTimeWatch = ref(true);
    const firstTimeWatchSeatPlan = ref(true);
    const boardingTimeList = ref([]);

    const dtFromStoppageBoardingsDropdown = computed(() => {
      let boardings = {};
      tripData.dtFromStoppageBoardings.forEach((item, index) => {
        boardings[index] = item;
      });
      return boardings;
    });

    const dtToStoppageDroppingsDropdown = computed(() => {
      let droppings = {};
      store.getters
          .getDroppingPlacesByStoppageId(tripData.dtToStoppage)
          ?.forEach((item, index) => {
            droppings[index] = item;
          });
      return droppings;
    });

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      if (tripData.status !== "CANCELLED") {
        delete allTripStatus.CANCELLED;
      }
      return allTripStatus;
    });

    watch(
        () => tripData.dtFromStoppageBoardings,
        (value) => {
          manageBoardingPlaceTime(value);
        }
    );

    const manageBoardingPlaceTime = (bPlaces) => {
      bPlaces.forEach((bPlace) => {
        if (!checkBoardingExistInBoardingPlaceTime(bPlace)) {
          boardingPlaceTime.value.push({
            name: bPlace,
            time: 0,
          });
        }
      });
      removeBoardingFromBoardingPlaceTime();
    };

    const checkBoardingExistInBoardingPlaceTime = (boarding) => {
      return (
          boardingPlaceTime.value.filter((item) => item.name === boarding)
              .length > 0
      );
    };

    const removeBoardingFromBoardingPlaceTime = () => {
      boardingPlaceTime.value.forEach((placeTime) => {
        if (!tripData.dtFromStoppageBoardings.includes(placeTime.name)) {
          boardingPlaceTime.value.splice(
              boardingPlaceTime.value.indexOf(
                  boardingPlaceTime.value.filter(
                      (item) => item.name === placeTime.name
                  )[0]
              ),
              1
          );
        }
      });
    };

    const initDtBoardingTimeIntoBoardingPlaceTime = (times) => {
      times.forEach((time, index) => {
        if (boardingPlaceTime.value) {
          boardingPlaceTime.value[index].time = time;
        }
      });
    };

    watch(
        () => tripData.digitalTicketing,
        (value) => {
          if (parseInt(value) === 1) {
            // getDigitalTicketingAllStoppage();
            // getAllDtFare();
            getRouteListFilter();
          }
        }
    );

    watch(
        () => tripData.coach,
        (value) => {
          if (!firstTimeWatchSeatPlan.value || tripId.value === "") {
            allCoaches.value.forEach((coach) => {
              if (coach._id === value) {
                tripData.seatPlan = coach.seatPlanId._id;
              }
            });
          }
          firstTimeWatchSeatPlan.value = false;
        }
    );

    watch(
        () => tripData.route,
        (value) => {
          makeBoardingTimeList(value);

          requiredValidation("route", tripData.route);
          getAllTripFare(value);
          getCounterByRoute(value);
          if (!firstTimeWatch.value) {
            clear();
          }
          nextTick(() => firstTimeWatch.value = false);
          allRoutes.value = store.getters.getAllRoute;
          allRoutes.value.forEach((route) => {
            if (route._id === value) {
              tripData.fromStoppage = route.fromStoppageId._id;
              tripData.toStoppage = route.toStoppageId._id;
            }
          });
        }
    );

    watch(
        () => tripData.coach,
        () => {
          requiredValidation("coach", tripData.coach);
        }
    );
    watch(
        () => tripData.seatPlan,
        (value) => {
          getSeatPlanById(value);

          if (!firstTimeWatch.value) {
            clear();
          }
          nextTick(() => firstTimeWatch.value = false);

          requiredValidation("seatPlan", tripData.seatPlan);
        }
    );

    watch(
        () => tripData.dtRoute,
        () => {
          requiredValidation("dtRoute", tripData.dtRoute);
        }
    );

    const initQuotaAutoClearTime = (time) => {
      tripData.quotaAutoClearTime = time;
    };

    const handleQuota = ({counterId, seat, dt}) => {
      if (counterId) {
        const seatPlan = tripData.quotaBasedSeatPlan.filter(
            (item) => item.counterId === counterId
        )[0];
        if (!seatPlan) {
          removeSeat(seat);
          removeSeatForDt(seat);
          tripData.quotaBasedSeatPlan.push({
            counterId: counterId,
            seats: [seat],
          });
        } else {
          removeSeat(seat);
          removeSeatForDt(seat);
          seatPlan.seats.push(seat);
        }
      } else if (dt) {
        removeSeat(seat);
        removeSeatForDt(seat);
        tripData.seatForDt.push(seat);
      } else {
        removeSeat(seat);
        removeSeatForDt(seat);
      }
      removeEmptySeats();
    };

    const removeEmptySeats = () => {
      tripData.quotaBasedSeatPlan = tripData.quotaBasedSeatPlan.filter(
          (item) => item.seats.length !== 0
      );
    };

    const removeSeat = (seat) => {
      tripData.quotaBasedSeatPlan.forEach((seatPlan) => {
        const index = seatPlan.seats.indexOf(seat);
        if (index !== -1) {
          seatPlan.seats.splice(index, 1);
        }
      });
    };

    const removeSeatForDt = (seat) => {
      const index = tripData.seatForDt.indexOf(seat);
      if (index !== -1) {
        tripData.seatForDt.splice(index, 1);
      }
    };

    const clear = () => {
      tripData.quotaBasedSeatPlan = [];
      tripData.seatForDt = [];
    };

    const goBack = () => {
      emit("close");
      emit("getAllTrips");
    };

    const makeBoardingTimeList = (routeId) => {
      boardingTimeList.value = tripData.boardingTimeList;
      tripData.boardingTimeList = [];
      routeSequences.value =
          store.getters.getRouteSequenceByRouteId(routeId)[0].routeSequence;
      routeSequences.value.forEach((stoppage) => {
        const boardingTimesForPayload = ref([]);
        stoppage.boardingPlace.forEach((item, index) => {
          boardingTimesForPayload.value.push({
            sequence: index,
            name: item,
            minutes: getBoardingMinutes(stoppage._id, index),
            isDefault: getIsDefault(stoppage._id, index) || false,
            enabledForDt: getEnableForDt(stoppage._id, index) || false,
          });
        });

        tripData.boardingTimeList.push({
          stoppageId: {...stoppage},
          boardingTimes: boardingTimesForPayload.value,
        });
      });
    };

    const getEnableForDt = (stoppageId, index) => {
      const boardingTime = boardingTimeList.value.filter(
          (boardingTime) => stoppageId === boardingTime.stoppageId._id
      );
      if (boardingTime.length !== 0) {
        const bTime = boardingTime[0].boardingTimes.filter(
            (item) => parseInt(item.sequence) === parseInt(index)
        );
        if (bTime.length !== 0) {
          return bTime[0].enabledForDt;
        }
        return false;
      }
      return false;
    };

    const getBoardingMinutes = (stoppageId, index) => {
      const boardingTime = boardingTimeList.value.filter(
          (boardingTime) => stoppageId === boardingTime.stoppageId._id
      );
      if (boardingTime.length !== 0) {
        const bTime = boardingTime[0].boardingTimes.filter(
            (item) => parseInt(item.sequence) === parseInt(index)
        );
        if (bTime.length !== 0) {
          return parseInt(bTime[0].minutes);
        }
        return 0;
      }
      return 0;
    };

    const getIsDefault = (stoppageId, index) => {
      const boardingTime = boardingTimeList.value.filter(
          (boardingTime) => stoppageId === boardingTime.stoppageId._id
      );
      if (boardingTime.length !== 0) {
        const bTime = boardingTime[0].boardingTimes.filter(
            (item) => parseInt(item.sequence) === parseInt(index)
        );
        if (bTime.length !== 0) {
          return bTime[0].isDefault;
        }
        return false;
      }
      return false;
    };

    const addMinute = (min) => {
      const first = new Date(
          (tripId.value !== "" && !isCopyActive.value
              ? tripData.tripDate
              : tripData.fromDate) +
          "T" +
          tripData.tripTime
      );
      return timeFormat(new Date(first.getTime() + min * 60 * 1000), 6);
    };

    const validate = () => {
      if (error.value !== "") {
        return false;
      }
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      if (store.getters.getSingleCompanyData?.fareType === "trip_fare") {
        validNumberValidation("tripFare", tripData.tripFare);
      }
      requiredValidation("coach", tripData.coach);
      requiredValidation("seatPlan", tripData.seatPlan);
      requiredValidation("route", tripData.route);
      requiredValidation("tripTime", tripData.tripTime);
      if (tripId.value !== "" && !isCopyActive.value) {
        requiredValidation("tripDate", tripData.tripDate);
      } else {
        requiredValidation("fromDate", tripData.fromDate);
        requiredValidation("toDate", tripData.toDate);
      }
      if (parseInt(tripData.digitalTicketing) === 1) {
        requiredValidation("dtRoute", tripData.dtRoute);
        // requiredValidation("dtFromStoppage", tripData.dtFromStoppage);
        // requiredValidation("dtToStoppage", tripData.dtToStoppage);
        // requiredValidation(
        //   "dtFromStoppageBoardings",
        //   tripData.dtFromStoppageBoardings[0]
        // );
        // requiredValidation(
        //   "dtStartStoppage",
        //   tripData.dtStartStoppage.toString()
        // );
        // requiredValidation("dtEndStoppage", tripData.dtEndStoppage.toString());
        // duplicateValidation("duplication", "city", "End stoppage", tripData.dtFromStoppage, tripData.dtToStoppage )
        // requiredValidation("dtFare", tripData.dtFare);
        // notFoundValidation("Fare", store.getters.getDtFareModality[0]);
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const getAllCoachList = () => {
      store
          .dispatch("getAllCoachList", {companyId: route.query.id})
          .then((coaches) => {
            allCoaches.value = coaches;
          })
          .catch((error) => {
            if (error.status === 401) {
              getAllCoachList();
            }
          });
    };

    fareTypeObject.value = store.getters.getCompany(route.query.id);
    fareType.value = fareTypeObject.value[0].fareType;

    const getAllTripFare = (value) => {
      store
          .dispatch("allTripFare", {
            companyId: route.query.id,
            routeId: value,
          })
          .then((response) => {
            error.value = "";
            if (response.data.fare[0].fareModality.length > 0) {
              response.data.fare.forEach((fare) => {
                tripData.fare = fare._id;
              });
            } else {
              error.value = "Fare not found for this route";
            }
          });
    };

    const getCounterByRoute = (value) => {
      showLoader();
      store
          .dispatch("getCounterByRoute", {
            companyId: route.query.id,
            routeId: value,
          })
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };

    const getSeatPlanById = (value) => {
      showLoader();
      store
          .dispatch("getSeatPlanById", {
            companyId: route.query.id,
            seatPlanId: value,
          })
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };

    const getRouteListFilter = () => {
      store
          .dispatch("getAllDtRoute", {companyId: route.query.id})
          .then(() => {
          })
          .catch((error) => {
            if (error.status === 401) {
              getRouteListFilter();
            }
          });
    };

    const addTrip = () => {
      if (validate()) {
        // formatStoppageIdForPayload()
        showLoader();
        isDisableSubmit.value = true;
        store
            .dispatch("addTrip", {
              tripData: {
                ...tripData,
                dtFromStoppage: tripData.dtFromStoppage
                    ? tripData.dtFromStoppage
                    : null,
                dtToStoppage: tripData.dtToStoppage
                    ? tripData.dtToStoppage
                    : null,
                dtBoardingTimes: boardingPlaceTime.value.map((item) => item.time),
              },
              fareType: {
                fareType: fareType.value,
                ...props.pagination,
              },
            })
            .then(() => {
              hideLoader();
              isDisableSubmit.value = false;
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              isDisableSubmit.value = false;
              if (error.status === 401) {
                // emit("close");
                addTrip();
              }
            });
      }
    };

    const updateTrip = () => {
      if (validate()) {
        // formatStoppageIdForPayload()
        showLoader();
        isDisableSubmit.value = true;
        store
            .dispatch("updateTrip", {
              tripData: {
                ...tripData,
                dtFromStoppage: tripData.dtFromStoppage
                    ? tripData.dtFromStoppage
                    : null,
                dtToStoppage: tripData.dtToStoppage
                    ? tripData.dtToStoppage
                    : null,
                dtBoardingTimes: boardingPlaceTime.value.map((item) => item.time),
              },
              fareType: {fareType: fareType.value},
            })
            .then(() => {
              hideLoader();
              isDisableSubmit.value = false;

              emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              isDisableSubmit.value = false;

              if (error.status === 401) {
                // emit("close");
                // emit("getAllTrips");
                updateTrip();
              }
            });
      }
    };

    const copyTrip = () => {
      if (validate()) {
        // formatStoppageIdForPayload()
        showLoader();
        isDisableSubmit.value = true;

        store
            .dispatch("copyTrip", {
              tripData: {
                ...tripData,
                dtFromStoppage: tripData.dtFromStoppage
                    ? tripData.dtFromStoppage
                    : null,
                dtToStoppage: tripData.dtToStoppage
                    ? tripData.dtToStoppage
                    : null,
                dtBoardingTimes: boardingPlaceTime.value.map((item) => item.time),
              },
              fareType: {
                fareType: fareType.value,
                page: props.pagination.page,
                limit: 25,
                companyId: route.query.id,
                status: "ACTIVE",
                date: moment(new Date()).format("yyyy-MM-DD"),
              },
            })
            .then(() => {
              hideLoader();
              isDisableSubmit.value = false;

              // emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              isDisableSubmit.value = false;
              if (error.status === 401) {
                // emit("close");
                // emit("getAllTrips");
                copyTrip();
              }
            });
      }
    };

    const initDateTimeIntoTripData = (tripDetails) => {
      if (!tripId.value) {
        tripData.fromDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.toDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.tripTime = moment(new Date()).format("HH:mm:00");
      } else if (isCopyActive.value) {
        tripData.fromDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.toDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.tripTime = moment(tripDetails[0].tripDateTime)
            .utcOffset(0, false)
            .format("HH:mm:00");
      } else {
        tripData.tripDate = moment(tripDetails[0].tripDateTime)
            .utcOffset(0, false)
            .format("yyyy-MM-DD");
        tripData.tripTime = moment(tripDetails[0].tripDateTime)
            .utcOffset(0, false)
            .format("HH:mm:00");
      }
    };

    const freeQuota = () => {
      if (!companyData.value.allowQuota) {
        tripData.quotaBasedSeatPlan = [];
      }
      if (!companyData.value.allowDigitalTicketing) {
        tripData.seatForDt = [];
      }
    };

    const handleDefaultDtBoarding = (stoppageId, index, isDefault) => {
      let data = tripData.boardingTimeList.find(
          (item) => item.stoppageId._id === stoppageId
      );
      if (data.boardingTimes[index].isDefault && !isDefault) {
        data.boardingTimes[index].isDefault = isDefault;
      } else {
        data.boardingTimes.forEach((item, i) => {
          if (index === i) {
            item.isDefault = isDefault;
          } else {
            item.isDefault = false;
          }
        });
      }
    };

    onBeforeMount(() => {
      companyData.value = store.getters.getCompany(route.query.id)[0];
      getAllCoachList();

      const tripDetails = store.getters.getTrip(tripId.value);
      if (tripId.value !== "") {
        routeSequences.value = store.getters.getRouteSequenceByRouteId(
            tripDetails[0].route._id
        )[0]?.routeSequence;
        title.value = isCopyActive.value ? "Copy Trip" : "Update Trip";
        tripData.id = tripDetails[0]._id;
        tripData.route = tripDetails[0].route._id;
        tripData.coach = tripDetails[0].coach._id;
        tripData.seatPlan = tripDetails[0].seatPlan._id;
        tripData.driver = tripDetails[0].driver;
        tripData.supervisor = tripDetails[0].supervisor;
        tripData.fromStoppage = tripDetails[0].fromStoppage._id;
        tripData.toStoppage = tripDetails[0].toStoppage._id;
        tripData.fare = tripDetails[0].fare?._id;
        tripData.boardingTimeList = tripDetails[0].boardingTimeList;
        tripData.quota = tripDetails[0].quota ? 1 : 0;
        tripData.quotaBasedSeatPlan = tripDetails[0].quotaBasedSeatPlan;
        tripData.seatForDt = tripDetails[0].seatForDt;
        tripData.quotaAutoClearTime = tripDetails[0].quotaAutoClearTime;
        tripData.digitalTicketing = tripDetails[0].digitalTicketing ? 1 : 0;
        tripData.coachType = tripDetails[0].coachType;
        tripData.dtRoute = tripDetails[0].dtRoute;
        tripData.dtFromStoppage = tripDetails[0].dtFromStoppage
            ? tripDetails[0].dtFromStoppage._id
            : "";
        tripData.dtToStoppage = tripDetails[0].dtToStoppage
            ? tripDetails[0].dtToStoppage._id
            : "";
        tripData.dtFromStoppageBoardings =
            tripDetails[0].dtFromStoppageBoardings;
        tripData.dtStartStoppage = tripDetails[0].dtStartStoppage;
        tripData.dtEndStoppage =
            tripDetails[0].dtToStoppage?.dropping?.length > 0
                ? tripDetails[0].dtEndStoppage
                : "";
        tripData.dtFare = tripDetails[0].dtFare;

        if (store.getters.getSingleCompanyData?.fareType === "instant_fare") {
          tripData.tripFare = tripDetails[0].tripFare;
        } else if (
            store.getters.getSingleCompanyData?.fareType === "trip_fare"
        ) {
          tripData.tripFare = tripDetails[0].tripFare;
        }
        tripData.status = isCopyActive.value ? "ACTIVE" : tripDetails[0].status;
        tripData.autoRenewal = tripDetails[0].autoRenewal ? 1 : 0;
        tripData.autoRenewalInDays = tripDetails[0].autoRenewalInDays;
        freeQuota();

        nextTick(() => {
          makeBoardingTimeList(tripData.route);
          initDtBoardingTimeIntoBoardingPlaceTime(
              tripDetails[0].dtBoardingTimes
          );
        });
      }
      initDateTimeIntoTripData(tripDetails);
      tripData.companyId = route.query.id;
      nextTick(() => {
        Object.keys(errors).forEach(function (key) {
          delete errors[key];
        });
      });
    });

    return {
      tripId,
      errors,
      status,
      title,
      showShipDropdown,
      ships,
      direction,
      tripData,
      error,
      addTrip,
      updateTrip,
      routeSequences,
      addMinute,
      boardingMinutes,
      isCopyActive,
      copyTrip,
      goBack,
      quota,
      handleQuota,
      digitalTicketing,
      coachType,
      initQuotaAutoClearTime,
      dtFromStoppageBoardingsDropdown,
      boardingPlaceTime,
      dtToStoppageDroppingsDropdown,
      isDisableSubmit,
      companyData,
      handleDefaultDtBoarding,
      autoRenewal,
      autoRenewalInDays,
      firstTimeWatch,
    };
  },
};
</script>

<style scoped>
.scroll-height-300 {
  height: 300px;
  overflow-y: auto;
}

.form-check .form-check-input {
  float: none;
  margin-left: 0;
}
</style>
