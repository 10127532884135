
export default function roles() {
    const Admin = {
        "dashboard": 1,
        "reports": 2,
        "adminRead": 3,
        "adminWrite": 4,
        "companyDeployRead": 5,
        "companyDeployWrite": 6,
        "tripCopy": 7,
        "ticketCancel": 8,
        "tripBulkCancel": 9,
        "paymentSettlement": 10,
        "qrTicket": 11,
    };
    const Owner = {
        "ownerDashboard": 51,
        "ownerReports": 52,
        "ownerCompanySetupRead": 53,
        "ownerCompanySetupWrite": 54,
        "ownerTripRead": 55,
        "ownerTripWrite": 56,
        "ownerRead": 57,
        "ownerWrite": 58,
        "agentBalanceRecharge": 59,
        "agentBalanceHistory": 60,
        "tripCopy": 61,
        "agentWrite": 62,
        "agentRead": 63,
        "countermanWrite": 64,
        "countermanRead": 65,
        "driverWrite": 66,
        "driverRead": 67,
        "supervisorWrite": 68,
        "supervisorRead": 69,
        "stoppageWrite": 70,
        "stoppageRead": 71,
        "counterRead": 72,
        "counterWrite": 73,
        "routeRead": 74,
        "routeWrite": 75,
        "seatClassRead": 76,
        "seatClassWrite": 77,
        "seatPlanRead": 78,
        "seatPlanWrite": 79,
        "fareRead": 80,
        "fareWrite": 81,
        "coachRead": 82,
        "coachWrite": 83,
        "quotaRead": 84,
        "quotaWrite": 85,
        "digitalTicketingRead": 86,
        "digitalTicketingWrite": 87,
        
    };
    const Counterman = {
        "ticketBooking": 101,
        "fareDiscount": 102,
        "reports": 103,
        "trips": 104,
        "coach": 105,
        "fares": 106,
        "ticketCancel": 107,
        "cancelOthersTicket": 108,
        "extraTicketBooking": 109,
        "goodsTicketBooking": 110,
        "tripPass": 111,
        "tripEdit": 112,
        "reservation": 113,
        "cancelReservation": 114,
        "cancelOtherReservation": 115,
        "tripCopy": 116,
        "allowSms": 117,
        "isPhoneNumberRequired": 118,
        "isReferCounterAllowed": 119,
        "isMultiplePrintAllowed": 120,
    };
    const Agent = {
        "ticketBooking": 151,
        "fareDiscount": 152,
        "reports": 153,
        "trips": 154,
        "coach": 155,
        "fares": 156,
        "ticketCancel": 157,
        "extraTicketBooking": 158,
        "goodsTicketBooking": 159,
        "tripPass": 160,
        "tripEdit": 161,
        "cancelOthersTicket": 162,
    };
    return {
        Admin,Owner,Counterman,Agent
    };
}
//1-49 for Admin
//50-99 for owner
//100-149 for counterman