import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import apiResponse from "@/modules/apiResponse";


const {hasValidToken, getToken} = tokenCookie();
const {errorResponse, successResponse} = apiResponse();
const state = {


};
const getters = {

};
const actions = {

    async getSettings(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ADMIN_SETTINGS, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async updateSettings(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.GET_ADMIN_SETTINGS, payload, {
                        headers: header,
                    })
                    .then((success) => {
                        successResponse(success);
                        resolve()
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },


};
const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
};
