<template>
  <Filter>
    <template v-slot:body>
      <div class="col-sm-12 col-md-4">
        <label class="text-white">Company Name</label>
        <input
            v-model="pagination.search"
            placeholder="Company Name"
            type="text"
            autocomplete="on"
            class="form-control font_detail"
            maxlength="255"
        />
      </div>
      <div class="col-sm-12 col-md-4">
        <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-model="pagination.status"
            v-bind:dropdown="status"
            defaultOption="-----Select status-----"
            defaultValue=""
        />
      </div>
      <div class="col-md-2 mt-auto">
        <button class="edit_table_data font_detail" @click="searchCompany">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </div>
    </template>
  </Filter>
  <div v-if="hasPermission(Admin.companyDeployRead)">
    <CompanyAddUpdate
        v-if="showModal"
        v-bind:company="companyId"
        @getAllCompanies="getAllCompanies"
        @close="close"
    />
    <Table v-else-if="$store.getters.getCompanies">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">MOBILE</td>
          <td class="font_detail">STATUS</td>
          <td class="font_detail">ACTION</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(company, index) in $store.getters.getCompanies"
            :key="company._id"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ company.name }}</td>
          <td class="font_detail">{{ company.phone }}</td>
          <td>
            <span v-if="company.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
            <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
          </td>
          <td>
            <!--            <router-link type="button" v-bind:to="{name:updateUrl,query:{id:company._id}}" @click="$emit('open')"-->
            <!--                         class="edit_table_data edit_table_button font_detail"><i-->
            <!--                class="fas fa-pencil-alt"></i></router-link>-->
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="edit_table_data edit_table_button font_detail"
                @click="updateModal(company._id)"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <button
                v-if="company.status"
                @click="getSingleCompanyData(company._id)"
                class="
                edit_table_data
                setting_table_button
                font_detail
                text-decoration-none
              "
            >
              <i class="fas fa-tools"> </i> <span class="mx-1"> Manage </span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalCompanies"
            :per-page="pagination.limit"
            @paginate="getAllCompanies"
            :options="options"
        />
      </template>
    </Table>

  </div>
</template>

<script>
import {inject, onMounted, ref, watch} from "vue";
import store from "@/store";
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CompanyAddUpdate from "@/views/Admin/CompanyDeploy/CompanyAddUpdate";
import {useRouter} from "vue-router";
// import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker.vue";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown.vue";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "CompanyList",
  props: {
    modelValue: Boolean,
  },
  components: {DropdownFilter, CompanyAddUpdate},
  //components: {DropdownFilter, DateTimeRangePicker, CompanyAddUpdate},
  setup(props, {emit}) {
    let pagination = reactive({
      page: 1,
      limit: 25,
      status: '',
      search: ''
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const router = useRouter();
    const companyId = ref("");
    const showModal = ref(false);
    const updateUrl = web.Admin.COMPANY_DEPLOY_URL.children.UPDATE_URL.name;
    const manageUrl = web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.name;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
        () => props.modelValue,
        (value) => {
          if (value) {
            addModal();
            emit("update:modelValue", false);
          }
        }
    );

    const searchCompany = () => {
      pagination.page = 1;
      getAllCompanies()
    }

    const getAllCompanies = () => {
      showLoader();
      store
          .dispatch("getCompaniesByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCompanies();
            }
          });
    };
    const addModal = () => {
      companyId.value = "";
      showModal.value = true;
    };
    const updateModal = (value) => {
      store.commit('setButtonStatus', false);
      companyId.value = value;
      showModal.value = true;
    };
    const close = () => {
      showModal.value = false
    }
    const getSingleCompanyData = (companyId) => {
      emit("listView");
      store
          .dispatch("getSingleCompanyData", {
            companyId: companyId,
          })
          .then(() => {
            router.push({name: manageUrl, query: {id: companyId}});
          });
    };

    const status = {
      'true': 'Active',
      'false': 'Inactive'
    }

    onMounted(getAllCompanies);

    return {
      options,
      manageUrl,
      showModal,
      companyId,
      dropdownVariables,
      pagination,
      Admin,
      updateUrl,
      hasPermission,
      getAllCompanies,
      addModal,
      updateModal,
      getSingleCompanyData,
      close,
      status,
      searchCompany
    };
  },
};
</script>

<style scoped>
.setting_table_button {
  margin: 1px 2px;
  border: 2px solid #4285f4;
  border-radius: 4px;
  background-color: #4285f4;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.setting_table_button:hover,
.setting_table_button:focus {
  border: 2px solid #4285f4;
  background-color: #fff;
  color: #4285f4;
  transition: 0.4s;
  outline: 0;
}
</style>
