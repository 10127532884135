<template>
  <div v-if="hasPermission(Admin.reports)">
    <div>
      <div v-if="reportTabs">
        <ul
          class="nav nav-tabs justify-content-center nav-style"
          id="myTab"
          role="tablist"
        >
          <li
            v-for="(tab, index) in reportTabs"
            :key="index"
            class="nav-item"
            role="presentation"
          >
            <button
              class="nav-link"
              :id="tab.name + '-tab'"
              data-bs-toggle="tab"
              v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
              type="button"
              @click="setActive(tab.name)"
              role="tab"
              :aria-controls="tab.path"
              aria-selected="true"
            >
              {{ tab.title }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            v-for="(tab, index) in reportTabs"
            :key="index"
            class="tab-pane fade"
            v-bind:class="tab.name === activeTab ? 'show active' : ''"
            :id="tab.path"
            role="tabpanel"
            :aria-labelledby="tab.name + '-tab'"
          ></div>
          <CoachReport v-if="activeTab === reportTabs.COACH_REPORT_URL.name" />
          <CounterReport
            v-if="activeTab === reportTabs.COUNTER_REPORT_URL.name"
          />
          <AgentReport v-if="activeTab === reportTabs.AGENT_REPORT_URL.name" />
          <PrintCountReport
            v-if="activeTab === reportTabs.PRINT_COUNT_REPORT_URL.name"
          />
          <ReservationReport v-if="activeTab === reportTabs.RESERVATION_REPORT_URL.name" />
          <QRHistory v-if="activeTab === reportTabs.QR_HISTORY_REPORT_URL.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CounterReport from "@/views/Admin/Report/Children/Counter/CounterReport.vue";
import AgentReport from "@/views/Admin/Report/Children/Agent/AgentReport.vue";
import CoachReport from "@/views/Admin/Report/Children/CoachReport.vue";
import PrintCountReport from "@/views/Admin/Report/Children/PrintCount/PrintCountReport.vue";
import QRHistory from "@/views/Admin/Report/Children/QRHistory/index.vue";
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import web from "@/urls/web";
import store from "@/store";
import router from "@/router";
import ReservationReport from "@/views/Admin/Report/Children/ReservationReport";

const { hasPermission } = permission();
const { Admin } = roles();
export default {
  name: "AdminReport",
  components: {ReservationReport, CoachReport, CounterReport, AgentReport, PrintCountReport, QRHistory },
  setup() {
    let activeTab = ref("");
    const reportTabs = web.Admin.REPORT_URL.children;
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Admin.reports)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : reportTabs.COACH_REPORT_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      Admin,
      CoachReport,
      CounterReport,
      activeTab,
      reportTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
