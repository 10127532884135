import SecureLS from "secure-ls";
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import createPersistedState from "vuex-persistedstate";
import Admins from "./modules/Admin/admins";
import Company from "./modules/Admin/company";
import Dashboard from "./modules/Admin/dashboard";
import Report from "./modules/Admin/report";
import DigitalTicketing from "./modules/Admin/digitalTicketing";
import DynamicTicketing from "./modules/Admin/dynamicTicketing";
import PaymentSettlement from "./modules/Admin/paymentSettlement";
import QRTickets from "./modules/Admin/qrTickets";

import AgentDashboard from "./modules/Agent/agentDashboard.js";
import AgentReport from "./modules/Agent/agentReport";
import AgentCoach from "./modules/Agent/agentCoach";
import AgentTrip from "./modules/Agent/agentTrip";

import Auth from './modules/auth';
import CountermanDashboard from "./modules/Counterman/countermanDashboard";
import CountermanReport from "./modules/Counterman/countermanReport";
import CountermanCoach from "./modules/Counterman/countermanCoach";
import CountermanTrip from "./modules/Counterman/countermanTrip";
import OwnerDashBoard from './modules/Owner/ownerDashboard';
import OwnerOwners from './modules/Owner/ownerOwners';
import OwnerReport from './modules/Owner/ownerReport';
import OwnerServices from "./modules/Owner/ownerServices";
import OwnerDigitalTicketing from "@/store/modules/Owner/ownerDigitalTicketing";
import CountermanNewDashboard from "@/store/modules/Counterman/countermanNewDashboard";
import settings from "@/store/modules/Admin/settings";
var ls = new SecureLS({encodingType: 'base64', isCompression: false});
const store = createStore(Vuex.Store, {
    modules: {
        auth: Auth,
        admin: Admins,
        company: Company,
        report: Report,
        digitalTicketing: DigitalTicketing,
        dynamicTicketing: DynamicTicketing,
        settings: settings,
        countermanDashboard: CountermanDashboard,
        countermanNewDashboard: CountermanNewDashboard,
        countermanCoach: CountermanCoach,
        countermanTrip: CountermanTrip,
        countermanReport: CountermanReport,
        dashboard: Dashboard,
        paymentSettlement: PaymentSettlement,
        qrTickets: QRTickets,
        ownerDashBoard: OwnerDashBoard,
        ownerReport: OwnerReport,
        ownerOwners: OwnerOwners,
        ownerServices: OwnerServices,
        ownerDigitalTicketing: OwnerDigitalTicketing,
        
        
        AgentDashboard: AgentDashboard,
        AgentReport: AgentReport,
        AgentCoach: AgentCoach,
        AgentTrip: AgentTrip,
    },
    plugins: [createPersistedState({
        key:'intercity',
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        }
    })]
})

export default store;