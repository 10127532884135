<template>
  <div>
    <div v-if="addUpdate"></div>
    <div v-else-if="printPreview">
      <TicketPrintPreview
          v-if="
          ticketTypeForPrint === 'lastTicket' ||
          ticketTypeForPrint === 'extraTicket' ||
          ticketTypeForPrint === 'goodsTicket' ||
          ticketTypeForPrint === 'pnrTicket' ||
          ticketTypeForPrint === 'ticket'
        "
          v-bind:ticket="lastTicketDetails"
          v-bind:ticketType="ticketTypeForPrint"
          @close="closePrintPreview"
      />
      <TripReportPrintPreview
          v-else-if="ticketTypeForPrint === 'tripReport'"
          :detailsTrip="propTripDetails"
          :printCount="printCount"
          :tripId="trip"
          :ticketType="ticketTypeForPrint"
          :trip="userTripReport"
          @close="closePrintPreview"
      />
    </div>
    <div v-else-if="passHistory">
      <TripPassHistory @hidePassHistory="hideTripHistory" :tripId="singleTripDetails.tripId" />
    </div>
    <div
        v-else
        class="card card-body py-4"
        style="background-color: whitesmoke"
    >
      <div class="row">
        <!-- Seat Information Start -->
        <div v-if="!ticketPurchased" class="row m-0 p-0">
          <SeatSummery :maleTotalSeat="singleTripDetails.totalMaleSeats"
                       :femaleTotalSeat="singleTripDetails.totalFemaleSeats"/>
          <SeatInformation :singleTripDetails="singleTripDetails" v-if="!isMobile"/>
          <div v-if="
              hasPermission(Counterman.ticketBooking) &&
              (hasPermission(Counterman.extraTicketBooking) ||
                hasPermission(Counterman.goodsTicketBooking))
            " class="col-sm-12 col-md-12 col-lg-1 col-xl-1 order-md-2 order-xl-3 ticket-print-section"
          >
            <span class="fw-bold">{{
                t("TICKET", {}, {locale: $store.getters.getLang})
              }}</span>
            <div class="card card-body">
              <div class="row">
                <div
                    v-if="hasPermission(Counterman.extraTicketBooking)"
                    class="col-6 col-md-12"
                >
                  <p class="text-center font_detail m-0">
                    {{ t("EXTRA", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button
                      class="extra_ticket_button w-100"
                      @click="ticketExtra('SEATS', 'EXTRA')"
                  >
                    <i class="fas fa-money-check-alt"></i>
                  </button>
                  <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                </div>
                <div
                    v-if="hasPermission(Counterman.goodsTicketBooking)"
                    class="col-6 col-md-12"
                >
                  <p class="text-center font_detail m-0">
                    {{ t("GOODS", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button
                      class="goods_ticket_button w-100"
                      @click="ticketExtra('GOODS', 'GOODS')"
                  >
                    <i class="fas fa-money-check-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
              class="col-sm-12 col-md-12 col-lg-1 col-xl-1 order-md-2 order-xl-3 ticket-print-section"
          >
            <span class="fw-bold">{{
                t("PRINT", {}, {locale: $store.getters.getLang})
              }}</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-6 col-md-12">
                  <p class="text-center font_detail m-0">
                    {{
                      t("TRIP REPORT", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="trip_button w-100" @click="tripReport()">
                    <i class="fas fa-print"></i>
                  </button>
                  <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                </div>
                <div class="col-6 col-md-12">
                  <p class="text-center font_detail m-0">
                    {{
                      t("LAST TICKET", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="last_ticket w-100" @click="lastTicket()">
                    <i class="fas fa-print"></i>
                  </button>
                </div>

                <!-- <div class="col-4 col-md-6">
                                  <p class="text-center font_detail m-0">PNR TICKET</p>
                                  <button class="pnr_ticket w-100" @click="pnrTicket">
                                    <i class="fas fa-print"></i>
                                  </button>
                                </div> -->
              </div>
            </div>
          </div>
          <div
              class="col-sm-12 col-md-12 col-lg-2 col-xl-2 order-md-3 order-xl-4 ticket-print-section"
          >
            <span class="fw-bold">{{
                t("ACTIONS", {}, {locale: $store.getters.getLang})
              }}</span>
            <div class="card card-body">
              <div class="row">
                <div
                    v-if="hasPermission(Counterman.tripEdit)"
                    class="col-4 col-md-6"
                >
                  <p class="text-center font_detail m-0">
                    {{
                      t("UPDATE TRIP", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="update_trip_button w-100" @click="updateTrip">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div
                    v-if="
                    $store.getters.allowPass &&
                    hasPermission(Counterman.tripPass)
                  "
                    class="col-4 col-md-6"
                >
                  <p class="text-center font_detail m-0">
                    {{ t("TRIP PASS", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button class="trip_pass_button w-100" @click="tripPass">
                    <i class="fas fa-location-arrow"></i>
                  </button>
                </div>

                <div v-if="$store.getters.allowPass" class="col-6 col-md-6">
                  <p class="text-center font_detail m-0 text-uppercase">
                    {{ t("TRIP PASS HISTORY", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button
                      class="trip_button w-100"
                      @click="showTripPassHistory"
                  >
                    <i class="fas fa-file-invoice"></i>
                  </button>
                </div>

                <div class="col-4 col-md-6">
                  <p class="text-center font_detail m-0">
                    {{ t("RESET", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button class="remove_button w-100" @click="reset">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="col-4 col-md-6">
                  <p class="text-center font_detail m-0">
                    {{ t("REFRESH", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button
                      class="refresh_button w-100"
                      @click="refreshPageGoBack()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-md-none px-0 mx-auto" v-if="isMobile">
            <span class="fw-bold">{{ t("STUFF INFORMATION", {}, {locale: $store.getters.getLang}) }}</span>
            <div class="card card-body">
              <table class="table">
                <tbody>
                <tr>
                  <th class="fw-bold">{{ t("Driver", {}, {locale: $store.getters.getLang}) }}</th>
                  <td>
                    {{ singleTripDetails?.driver?.name ?? 'N/A' }} <br/>
                    {{ singleTripDetails?.driver?.phone }}
                  </td>
                </tr>
                <tr>
                  <th class="fw-bold">{{ t("Supervisor", {}, {locale: $store.getters.getLang}) }}</th>
                  <td>
                    {{ singleTripDetails?.supervisor?.name ?? 'N/A' }} <br/>
                    {{ singleTripDetails?.supervisor?.phone }}
                  </td>
                </tr>
                </tbody>
              </table>
              <p><strong>Bus No:</strong> {{ singleTripDetails?.coachRegistrationNumber ?? singleTripDetails?.coach?.registrationNumber}}</p>
            </div>
          </div>
        </div>
        <!-- Seat Information End -->
        <div id="ticketPrintPreview" class="row m-0 mt-2">
          <div v-if="$store.getters.getTicketPurchasedDetails.purchaseStatus" class="row m-0">
            <TicketPrintPreview
                :ticket="$store.getters.getTicketPurchasedDetails.purchasedTicket"
                :ticketType="'ticket'"
                @close="close"
            />
          </div>
          <SeatList v-if="!ticketPurchased" :singleTripDetails="singleTripDetails" @openGlobalFilter="openGlobalFilter">
            <template v-if="!ticketPurchased && isMobile" v-slot:seatinfo>
              <SeatInformationMobile :singleTripDetails="singleTripDetails"/>
            </template>
          </SeatList>
          <BookingInformation
              v-if="!ticketPurchased"
              :singleTripDetails="singleTripDetails"
              @refreshPageGoBack="refreshPageGoBack"
          />
        </div>
      </div>
    </div>
  </div>
  <TicketExtraBookingModal
      v-model="ticketExtraModal"
      v-if="ticketExtraModal"
      :extraTicketModalName="extraTicketModalName"
      :extraTicketType="extraTicketType"
      :singleTripDetails="singleTripDetails"
      @extraGoodsPurchase="extraGoodsPurchase"
      @extraSeatPurchase="extraSeatPurchase"
  />
  <TripPass v-if="tripPassModal" v-model="tripPassModal" :trip="singleTripDetails.tripId"/>
  <PnrTicket v-if="pnrModal" v-model="pnrModal" @pnrPrint="pnrPrint"/>
  <UpdateTrip v-if="updateTripModal" v-model="updateTripModal" :tripId="singleTripDetails.tripId"/>
</template>

<script>
import {computed, inject, onBeforeMount, ref, watch, onBeforeUnmount, onMounted} from "vue";
import SeatList from "./SeatList";
import TripPass from "./TripPassModal.vue";
import PnrTicket from "./PnrModal.vue";
import UpdateTrip from "./UpdateTripModal.vue";
import TripPassHistory from "./TripPassHistory.vue";
import TicketExtraBookingModal from "./TicketExtraBookingModal.vue";
import {reactive} from "@vue/reactivity";
// import fareModality from "@/modules/fareModality";
import store from "@/store";
import dropdownVariables from "../../../../modules/dropdownVariables";
// import toast from "@/modules/Toast";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {useI18n} from "vue-i18n";

// const { errorToast } = toast();
const {hasPermission} = permission();
const {Counterman} = roles();

// const {getNetFare} = fareModality();
 import moment from "moment";
import SeatSummery from "@/components/counterman/SeatSummery";
import SeatInformation from "@/components/counterman/SeatInformation";
import BookingInformation from "@/components/counterman/BookingInformation";
import SeatInformationMobile from "@/components/counterman/SeatInformationMobile";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import {nextTick} from "vue";

export default {
  name: "TicketBooking",
  components: {
    SeatInformationMobile,
    BookingInformation,
    SeatInformation,
    SeatSummery,
    SeatList,
    TicketExtraBookingModal,
    TripPass,
    PnrTicket,
    UpdateTrip,
    TripPassHistory
  },
  props: {
    shouldRefreshPageGoBack: Boolean,
    paginationData: Object,
    tripRef: Array
  },
  emits: ["openGlobalFilter"],

  setup(props, {emit}) {
    const propsPaginationData = ref(props.paginationData);
    const addUpdate = ref(false);
    const printPreview = ref(false);
    const passHistory = ref(false);
    const tripIdForPrint = ref("");
    const ticketTypeForPrint = ref("");
    const userTripReport = ref([]);
    const lastTicketDetails = ref({});
    const {t, locale} = useI18n();
    let discountErrorMsg = ref("");
    let totalBookedSeatCount = ref(0);
    let totalBlockedSeatCount = ref(0);
    let totalAvailableSeatCount = ref(0);
    let printCount = ref(0);
    let seats = ref([]);
    let fare = ref("");
    let inputFare = ref("");
    let resetAllData = ref(0);
    // let fareArray = ref([]);
    let extraTicketModalName = ref("");
    let extraTicketType = ref("");
    let ticketExtraModal = ref(false);
    let tripPassModal = ref(false);
    let pnrModal = ref(false);
    let updateTripModal = ref(false);
    let calculateDue = ref(0);
    let calculateRefund = ref(0);
    let ticketPurchased = ref(false);
    let confirmButtonDisable = ref(false);
    const title = ref("Print Booking");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let singleTripDetails = computed(() => store.getters.getTripForTicketingNew)
    const tripRefData = computed(() => props.tripRef)
    let seatsDetails = reactive({
      tripId: singleTripDetails.value.tripId,
      seats: [],
      seatClassId: "",
      discount: "",
      totalAmount: 0,
      payable: 0,
      paid: "",
      refund: 0,
      due: 0,
    });

    // let pagination = reactive({
    //   page: 1,
    //   limit: 10,
    //   companyId: store.getters.userCompany,
    //   from: "",
    //   to: "",
    //   coach: "",
    //   date: moment(new Date()).format("yyyy-MM-DD"),
    //   status: "ACTIVE",
    // });

    const openGlobalFilter = (value) => {
      emit("openGlobalFilter", value)
    }

    watch(() => singleTripDetails.value.quotaAutoClearTime, ( value) => {
      cancelQuotaAuto(value);
    })

    watch(() => singleTripDetails.value.dtAutoClearDateTime, ( value) => {
      cancelQuotaAuto(value);
    })

    const ticketDashboardHide = () => {
      ticketPurchased.value = true;
    };

    const close = () => {
      addUpdate.value = false;
      store.commit("setPurchaseStatus", false)
      store.commit("setPurchasedTicket", {})
      // this place need optimise
      refreshPageGoBack();
    };

    const reset = () => {
      store.commit("setResetStatus", true)
      store.commit("setSeatResetStatus", true)
    };

    // const refreshPage = () => {
    //   showLoader();
    //   store
    //       .dispatch("getActiveTripsForTicketing", pagination)
    //       .then(() => {
    //         hideLoader();
    //       })
    //       .catch((error) => {
    //         hideLoader();
    //         if (error.status === 401) {
    //           refreshPage();
    //         }
    //       });
    // };

    const refreshPageGoBack = () => {
      if (singleTripDetails.value !== "") {
        showLoader();
        const payload = {
          tripId: singleTripDetails.value.tripId,
          fromStoppage: '',
          toStoppage: '',
          boarding: ''
        }
        store
            .dispatch("getTicketByTripNew", payload)
            .then(() => {
              ticketPurchased.value = false;
              reset();
              hideLoader();
              nextTick(() => {
                tripRefData.value.find(el => {
                      if (el.dataset.tripId === singleTripDetails.value.tripId) {
                        el.scrollIntoView({behavior: 'smooth', block: 'center'})
                      }
                    }
                );
              })
            })
            .catch((error) => {
              if(error.status === 401){
                refreshPageGoBack()
              }else {
                hideLoader();
              }

            });
      }
    };
    let cancelQuotaTimer;
    const cancelQuotaAuto = (autoClearTime) => {
      const cancelTime = moment(dateTimeFormat(autoClearTime, 0))
      const currentTime = moment(dateTimeFormat(new Date(), 6));
      const timeDifference = cancelTime.diff(currentTime)
      if (timeDifference >= 0) {
          cancelQuotaTimer = setTimeout(() => {
          refreshPageGoBack()
        }, timeDifference)
      }
    }

    cancelQuotaAuto(singleTripDetails.value.quotaAutoClearTime);
    cancelQuotaAuto(singleTripDetails.value.dtAutoClearDateTime);

    const hideTripHistory = () => {
      passHistory.value = false
      refreshPageGoBack()
    }

    const tripReport = () => {
      showLoader();
      store
          .dispatch("getCountermanTripReport", {tripId: singleTripDetails.value.tripId})
          .then((tripReport) => {
            hideLoader();
            if (tripReport) {
              console.log(tripReport)
              userTripReport.value = tripReport;
              ticketTypeForPrint.value = "tripReport";
            }
            reportPrintCount();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              tripReport(singleTripDetails.value.tripId);
            }
          });
    };

    const reportPrintCount = () => {
      showLoader();
      store
          .dispatch("reportPrintCount", {
            reportType: "tripWiseReport",
            tripId: singleTripDetails.value.tripId,
          })
          .then((data) => {
            hideLoader();
            printCount.value = data.printCount + 1;
            printPreview.value = true;
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              reportPrintCount();
            }
          });
    };
    const lastTicket = () => {
      showLoader();
      store
          .dispatch("getCountermanLastTicket", {tripId: singleTripDetails.value.tripId})
          .then((lastTicket) => {
            hideLoader();
            if (lastTicket) {
              lastTicketDetails.value = lastTicket;
              ticketTypeForPrint.value = "lastTicket";
              printPreview.value = true;
            }
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              lastTicket(singleTripDetails.value.tripId);
            }
          });
    };
    const pnrPrint = (payload) => {
      hideLoader();
      printPreview.value = true;
      lastTicketDetails.value = payload;
      ticketTypeForPrint.value = "pnrTicket";
    };
    const extraSeatPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const extraGoodsPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const closePrintPreview = () => {
      printPreview.value = false;
      tripIdForPrint.value = "";
      ticketTypeForPrint.value = "";
    };
    const showTripPassHistory = () => {
      passHistory.value = true;
    };

    const tripPass = () => {
      tripPassModal.value = true;
    };
    const updateTrip = () => {
      updateTripModal.value = true;
    };
    const pnrTicket = () => {
      pnrModal.value = true;
    };

    const ticketExtra = (name, ticketType) => {
      extraTicketModalName.value = name;
      extraTicketType.value = ticketType;
      ticketExtraModal.value = true;
    };

    onBeforeMount(() => {
      if (props.shouldRefreshPageGoBack === true) {
        ticketPurchased.value = true;
        refreshPageGoBack()
      }
      // else {
      //   tripInfo();
      //   // getTripForTicketing();
      // }
    });

    const isMobile = ref(false);
    onBeforeUnmount(() => {
      clearTimeout(cancelQuotaTimer)
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize)
      }
    })

    onMounted(() => {
      onResize()
      window.addEventListener('resize', onResize)
    })

    const onResize = () => {
      isMobile.value = window.innerWidth < 768
    }

    return {
      isMobile,
      t,
      locale,
      discountErrorMsg,
      ticketTypeForPrint,
      printPreview,
      Counterman,
      addUpdate,
      seatsDetails,
      userTripReport,
      lastTicketDetails,
      totalAvailableSeatCount,
      totalBookedSeatCount,
      dropdownVariables,
      // seatPlans,
      // seatPlan,
      title,
      totalBlockedSeatCount,
      propsPaginationData,
      extraTicketModalName,
      extraTicketType,
      tripPassModal,
      updateTripModal,
      ticketExtra,
      hasPermission,
      tripReport,
      reset,
      close,
      lastTicket,
      updateTrip,
      closePrintPreview,
      // refreshPage,
      refreshPageGoBack,
      extraSeatPurchase,
      extraGoodsPurchase,
      tripPass,
      pnrTicket,
      pnrPrint,
      printCount,
      singleTripDetails,
      seats,
      fare,
      resetAllData,
      ticketExtraModal,
      pnrModal,
      calculateDue,
      calculateRefund,
      inputFare,
      ticketPurchased,
      ticketDashboardHide,
      confirmButtonDisable,
      openGlobalFilter,
      showTripPassHistory,
      passHistory,
      hideTripHistory,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: whitesmoke;
  border-top: 1px;
}

.active-button {
  background: white !important;
  /*border: 1px solid #242424 !important;*/
  color: #000 !important;
}

.inactive-button {
  color: #888 !important;
}

.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}

.span_font {
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
}

.trip_button {
  margin: 1px 2px;
  border: 2px solid #9400d3;
  border-radius: 4px;
  background-color: #9400d3;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.trip_button:hover,
.trip_button:focus {
  border: 2px solid #9400d3;
  background-color: #fff;
  color: #9400d3;
  transition: 0.4s;
  outline: 0;
}

.extra_ticket_button {
  margin: 1px 2px;
  border: 2px solid #3cbbc4;
  border-radius: 4px;
  background-color: #3cbbc4;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.extra_ticket_button:hover,
.extra_ticket_button:focus {
  border: 2px solid #3cbbc4;
  background-color: #fff;
  color: #3cbbc4;
  transition: 0.4s;
  outline: 0;
}

.goods_ticket_button {
  margin: 1px 2px;
  border: 2px solid #6edc58;
  border-radius: 4px;
  background-color: #6edc58;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.goods_ticket_button:hover,
.goods_ticket_button:focus {
  border: 2px solid #6edc58;
  background-color: #fff;
  color: #6edc58;
  transition: 0.4s;
  outline: 0;
}

.last_ticket {
  margin: 1px 2px;
  border: 2px solid #00bfff;
  border-radius: 4px;
  background-color: #00bfff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.last_ticket:hover,
.last_ticket:focus {
  border: 2px solid #00bfff;
  background-color: #fff;
  color: #00bfff;
  transition: 0.4s;
  outline: 0;
}

.pnr_ticket {
  margin: 1px 2px;
  border: 2px solid #9b5f76;
  border-radius: 4px;
  background-color: #9b5f76;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.pnr_ticket:hover,
.pnr_ticket:focus {
  border: 2px solid #9b5f76;
  background-color: #fff;
  color: #9b5f76;
  transition: 0.4s;
  outline: 0;
}

.remove_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.update_trip_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.update_trip_button:hover,
.update_trip_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}

.trip_pass_button {
  margin: 1px 2px;
  border: 2px solid #87bb62;
  border-radius: 4px;
  background-color: #87bb62;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.trip_pass_button:hover,
.trip_pass_button:focus {
  border: 2px solid #87bb62;
  background-color: #fff;
  color: #87bb62;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.refresh_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.refresh_button:hover,
.refresh_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .ticket-print-section {
    padding: 1px;
  }
}
</style>
