<template>
  <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 back-red p-0 px-md-2 mt-2 mt-md-0">
    <div class="card card-body py-4 tableInfo_border" style="background-color: whitesmoke">
      <div class="row">
        <div class="row m-0 p-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <h5 class="txt_color fs-5 fw-normal fw-bold">
              {{ t("Booking Information", {}, {locale: $store.getters.getLang}) }}
            </h5>
            <div class="row">
              <div class="col">
                <div class="booking_info padding-left">
                  <TicketInputNew
                      :id="'name'"
                      v-model="passenger.name"
                      :icon="'fas fa-file-signature'"
                      :label="'Name'"
                      :placeholder="'Passenger Name'"
                      :type="'text'"
                  />
                  <TicketInputPhoneNew
                      v-bind:id="'mobile'"
                      v-model="passenger.phoneNo"
                      v-bind:label="'Mobile'"
                      v-bind:placeholder="'Passenger Mobile'"
                      v-bind:type="'number'"
                  >
                    <template v-slot:search-btn>
                      <button :disabled="passenger.phoneNo === ''"
                              class="btn btn-primary"
                              @click="searchWithPassengerPhone"
                      >
                        <i class="fa fa-search"/>
                      </button>
                    </template>
                  </TicketInputPhoneNew>

                  <TicketDropdownNew
                      v-bind:id="'passengerGender'"
                      v-model="passenger.gender"
                      :color="'text-danger'"
                      :icon="'fas fa-venus-mars'"
                      v-bind:defaultOption="'--select gender --'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="dropdownVariables.gender"
                      v-bind:label="'Gender'"
                  >
                  </TicketDropdownNew>
                  <TicketInputNew
                      v-bind:id="'nid'"
                      v-model="passenger.nid"
                      :color="'text-danger'"
                      :icon="'far fa-flag'"
                      v-bind:label="'NID'"
                      v-bind:placeholder="'Passenger NID'"
                      v-bind:type="'number'"
                  />

                  <TicketInputNew
                      v-bind:id="'address'"
                      v-model="passenger.address"
                      :icon="'far fa-address-card'"
                      v-bind:label="'Address'"
                      v-bind:placeholder="' '"
                      v-bind:type="'text'"
                  />
                </div>
                <div class="booking_info" v-if="isMobile">
                  <!-- <p>{{passenger.fromStoppage}}</p> -->
                  <TicketDropdownNew
                      v-bind:id="'fromStoppage'"
                      v-model="passenger.fromStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="stoppages"
                      v-bind:label="'From Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'toStoppage'"
                      v-model="passenger.toStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="stoppages"
                      v-bind:label="'To Stoppage'"
                  >
                  </TicketDropdownNew>
                  <DropdownVerticalLabel
                      :id="'boardingPlace'"
                      v-model="passenger.boardingPlace"
                      :icon="'fas fa-search-location'"
                      :defaultOption="'---select a boarding place---'"
                      :defaultValue="''"
                      :options="boardingPoints"
                      :label="'Boarding place'"
                      :showItem="'name'"
                      :showItem1="'boardingDateTime'"
                      :returnItem="''"
                  ></DropdownVerticalLabel>
                  <TicketDropdownNew
                      v-bind:id="'droppingPoint'"
                      v-model="passenger.droppingPoint"
                      :icon="'fas fa-search-location'"
                      v-bind:defaultOption="'---select a dropping point---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="droppingPoints"
                      v-bind:label="'Dropping place'"
                  >
                  </TicketDropdownNew>
                </div>
              </div>
              <div class="col d-none d-md-grid" v-if="!isMobile">
                <div class="booking_info booking_info_sm_device">
                  <TicketDropdownNew
                      v-bind:id="'fromStoppage'"
                      v-model="passenger.fromStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="stoppages"
                      v-bind:label="'From Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'toStoppage'"
                      v-model="passenger.toStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="stoppages"
                      v-bind:label="'To Stoppage'"
                  >
                  </TicketDropdownNew>
                  <DropdownVerticalLabel
                      :id="'boardingPlace'"
                      v-model="passenger.boardingPlace"
                      :icon="'fas fa-search-location'"
                      :defaultOption="'---select a boarding place---'"
                      :defaultValue="''"
                      :options="boardingPoints"
                      :label="'Boarding place'"
                      :showItem="'name'"
                      :showItem1="'boardingDateTime'"
                      :returnItem="''"
                  >
                  </DropdownVerticalLabel>

                  <TicketDropdownNew
                      v-model="passenger.droppingPoint"
                      :id="'droppingPoint'"
                      :icon="'fas fa-search-location'"
                      :defaultOption="'---select a dropping place---'"
                      :defaultValue="''"
                      :dropdown="droppingPoints"
                      :label="'Dropping place'"
                  >
                  </TicketDropdownNew>
                </div>
              </div>
              <div v-if="isReferCounterAllowed && hasPermission(Counterman.isReferCounterAllowed)">
                <div class="p-2">
                  <hr />
                </div>
                <div class="booking_info padding-left">
                  <TicketDropdownNew
                      v-bind:id="'counterAll'"
                      v-model="initialDetails.counter"
                      :color="'text-danger'"
                      :icon="'fas fa-home'"
                      v-bind:defaultOption="'--select counter --'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getAllCounter"
                      v-bind:label="'Refer Counter'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-if="initialDetails.counter"
                      v-bind:id="'countermanAllsss'"
                      v-model="initialDetails.counterman"
                      :color="''"
                      :icon="'fas fa-user'"
                      v-bind:defaultOption="'--select counter--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getAllCounterman"
                      v-bind:label="'Counterman'"
                  >
                  </TicketDropdownNew>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
          <button
              class="confirm_btn corporate_btn font_detail mx-md-3 px-3 spacing"
              @click.prevent="purchaseTicket"
          >
            {{ t("Confirm", {}, {locale: $store.getters.getLang}) }}
          </button>

          <button v-if="activeReservation && hasPermission(Counterman.reservation) && !initialDetails.counter"
                  class="booking_btn confirm_btn font_detail mx-md-3 px-3 spacing"
                  @click.prevent="reserveTicket">
            {{ t("Booking", {}, {locale: $store.getters.getLang}) }}
          </button>
        </div>
      </div>
      <Error v-if="ticketPurchaseError" v-bind:error="ticketPurchaseError"/>
      <Error v-if="errors.duplication" v-bind:error="errors.duplication"/>
      <Error v-if="errors.boardingPlace" :error="errors.boardingPlace"/>
      <Error v-if="errors.droppingPoint" :error="errors.droppingPoint"/>
      <Error v-if="errors.SeatSelect" :error="errors.SeatSelect"/>
      <Error v-if="errors.mobile" :error="errors.mobile"/>
      <Error v-if="err" :error="err"/>
    </div>
  </div>
  <ReservationModal v-if="openReserveModal" v-model="openReserveModal"
                    :passengerDetails="passengerDetailsForReservation"
                    :stoppages="stoppages"
                    @refreshPageGoBack="refreshPageGoBackForReservation"></ReservationModal>
</template>

<script>
import moment from "moment";
import {useI18n} from "vue-i18n";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {computed, onMounted, watch, reactive, inject, ref, onBeforeUnmount, nextTick} from "vue";
import dropdownVariables from "@/modules/dropdownVariables";
import store from "@/store";
import useInputValidation from "@/modules/useInputValidations";
import {checkReservationValidity, reservationSetTimeout} from "@/composables/counterman/reservation";
import {getFilterBoardingAndDroppingPlace, sanitizePhone, addCountryCode} from '@/composables/counterman/bookingInformation'
import ReservationModal from "@/views/Counterman/Dashboard/TicketBooking/Reservation/ReservationModal.vue";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import {viewTripWithStoppage} from "@/composables/counterman/trips"
export default {
  name: "BookingInformation",
  components: {ReservationModal},
  props: {
    singleTripDetails: Object,
  },
  emits: ['refreshPageGoBack'],
  setup(props, {emit}) {
    const {t, locale} = useI18n();
    const openReserveModal = ref(false)
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let {requiredValidation, duplicateValidation, bdPhoneValidation, errors} = useInputValidation();
    const {hasPermission} = permission();
    const {Counterman} = roles();
    const selectedTripDetails = computed(() => props.singleTripDetails)
    const userCompanyDetails = computed(() => store.getters.userCompanyData)
    const SeatDetails = computed(() => store.getters.getSeatDetails)
    const resetStatus = computed(() => store.getters.getResetStatus)
    const ticketPurchaseError = ref('');
    const activeReservation = ref(false)
    let passengerDetailsForReservation = reactive({})
    const loadCounter = ref(0)
    const initialDetails = ref({
      fromStoppage: '',
      toStoppage: '',
      boardingPlace: '',
      droppingPoint: '',
      counter: "",
      counterman: null,
    })
    const {
      passenger,
      searchWithPassengerPhone,
      err
    } = getFilterBoardingAndDroppingPlace();

    passenger.fromStoppage = selectedTripDetails.value.selectedFromStoppage;
    passenger.toStoppage = selectedTripDetails.value.selectedToStoppage;
    passenger.boardingPlace = selectedTripDetails.value.selectedBoarding;
    passenger.droppingPoint = selectedTripDetails.value.selectedDropping;
    passenger.trip = selectedTripDetails.value.tripId;
    passenger.coach = selectedTripDetails.value.coach._id;
    initialDetails.value.fromStoppage = selectedTripDetails.value.selectedFromStoppage
    initialDetails.value.toStoppage = selectedTripDetails.value.selectedToStoppage;
    initialDetails.value.boardingPlace = selectedTripDetails.value.selectedBoarding;
    initialDetails.value.boardingPlace = selectedTripDetails.value.selectedDropping
    store.commit("setResetStatus", false)

    onMounted(() => {
      loadCounter.value = loadCounter.value + 1;
    })

    // watch(() => passenger, () => {
    //   store.commit("setPassengerDetails", passenger)
    // }, {deep: true})

    watch(() => selectedTripDetails.value.reservationAutoClearDateTime, () => {
      checkReservationValidity(selectedTripDetails.value, refreshPageGoBack, initActiveReservation)
    })

    watch(() => passenger.fromStoppage, () => {
      if (loadCounter.value > 0) {
        viewTripDetailsWithUserPreference()
      }
    })

    watch(() => passenger.toStoppage, () => {
      if (loadCounter.value > 0) {
        viewTripDetailsWithUserPreference()
      }
    })

    watch(() => initialDetails.value.counter, (value) => {
      if(value){
        getCountermanByCounter(value)
      }else{
        initialDetails.value.counterman = null
      }
    })

    const viewTripDetailsWithUserPreference = () => {
      const payload = {
        tripId: selectedTripDetails.value.tripId,
        fromStoppage: passenger.fromStoppage,
        toStoppage: passenger.toStoppage,
      }
      viewTripWithStoppage(payload)
      store.commit("setSeatResetStatus", true)
    }
    const refreshPageGoBack = () => {
      emit("refreshPageGoBack")
    }

    const refreshPageGoBackForReservation = () => {
      passenger.phoneNo = '';
      passenger.name = '';
      passenger.nid = '';
      passenger.address = '';
      emit("refreshPageGoBack")
    }
    const initActiveReservation = (isActive) => {
      activeReservation.value = isActive
    }

    checkReservationValidity(selectedTripDetails.value, refreshPageGoBack, initActiveReservation)

    const getCountermanByCounter = (counter) => {
      store.dispatch("getCountermanByCounter", {counter})
          .then(( ) => {
          })
          .catch(() => {
          });
    }


    const reserveTicket = () => {
      if (validate()) {
        if (SeatDetails.value.ticketDetails.payable >= 0 && SeatDetails.value.seatFare.length !== 0) {
          passengerDetailsForReservation['trip'] = passenger.trip
          passengerDetailsForReservation['coach'] = passenger.coach
          passengerDetailsForReservation['fromStoppage'] = passenger.fromStoppage
          passengerDetailsForReservation['toStoppage'] = passenger.toStoppage
          passengerDetailsForReservation['ticketType'] = "RESERVED"
          passengerDetailsForReservation['passengerName'] = passenger.name
          passengerDetailsForReservation['passengerPhone'] = addCountryCode(passengerPhoneNumber.value)
          passengerDetailsForReservation['passengerGender'] = passenger.gender
          passengerDetailsForReservation['passengerNID'] = passenger.nid
          passengerDetailsForReservation['passengerAddress'] = passenger.address
          passengerDetailsForReservation['boardingPlace'] = boardingPoints.value[passenger.boardingPlace].name
          passengerDetailsForReservation['droppingPoint'] = droppingPoints.value[passenger.droppingPoint]
          passengerDetailsForReservation['farePerTicket'] = calculateReservationFareTicket().farePerTicket
          passengerDetailsForReservation['totalAmount'] = calculateReservationFareTicket().seatTotalAmount
          passengerDetailsForReservation['payable'] = calculateReservationFareTicket().seatTotalAmount
          passengerDetailsForReservation['farePerSeat'] = calculateReservationFareTicket().farePerSeat
          passengerDetailsForReservation['seatClass'] = selectedTripDetails.value.seatClass
          passengerDetailsForReservation['seatNumbers'] = SeatDetails.value.seatFare.map((seat) => seat.seat_no)
          passengerDetailsForReservation['discount'] = 0
          passengerDetailsForReservation['paid'] = 0
          passengerDetailsForReservation['dues'] = 0
          passengerDetailsForReservation['refund'] = 0
          passengerDetailsForReservation['referenced_by'] = null
          passengerDetailsForReservation['ticketDateTime'] = moment(new Date())
              .utcOffset(0, true)
              .format("yyyy-MM-DD HH:mm:ss")
          passengerDetailsForReservation['boardingTripDateTime'] = dateTimeFormat(boardingPoints.value[passenger.boardingPlace].boardingDateTime, 0),
          openReserveModal.value = true
          ticketPurchaseError.value = ''
        } else {
          errorMsg()
        }
      }
    }

    const purchaseTicket = () => {
      if (validate()) {
        showLoader()
        if (userCompanyDetails.value.fareType === 'instant_fare' && (SeatDetails.value.ticketDetails.inputFare === "" || parseInt(SeatDetails.value.ticketDetails.inputFare) < 100)) {
          errorMsg();
          hideLoader();
        } else {
          if (SeatDetails.value.ticketDetails.payable >= 0 && SeatDetails.value.seatFare.length !== 0) {
            let passengerDetails = reactive({
              trip: passenger.trip,
              coach: passenger.coach,
              fromStoppage: passenger.fromStoppage,
              toStoppage: passenger.toStoppage,
              ticketType: "SEAT",
              passengerName: passenger.name,
              passengerPhone: addCountryCode(passengerPhoneNumber.value),
              passengerGender: passenger.gender,
              passengerNID: passenger.nid,
              passengerAddress: passenger.address,
              boardingPlace: boardingPoints.value[passenger.boardingPlace].name,
              droppingPoint: droppingPoints.value[passenger.droppingPoint],
              seatClass: selectedTripDetails.value.seatClass,
              seatNumbers: SeatDetails.value.seatFare.map((seat) => seat.seat_no),
              farePerTicket: calculateFareTicket().farePerTicket,
              totalAmount: calculateFareTicket().seatTotalAmount,
              farePerSeat: calculateFareTicket().farePerSeat,
              discount: SeatDetails.value.ticketDetails.discount,
              payable: SeatDetails.value.ticketDetails.payable,
              paid: SeatDetails.value.ticketDetails.paid,
              dues: SeatDetails.value.ticketDetails.due,
              refund: SeatDetails.value.ticketDetails.refund,
              referenced_by: initialDetails.value.counterman,
              boardingTripDateTime: dateTimeFormat(boardingPoints.value[passenger.boardingPlace].boardingDateTime, 0),
              ticketDateTime: moment(new Date())
                  .utcOffset(0, true)
                  .format("yyyy-MM-DD HH:mm:ss")
            });
            store.dispatch("confirmPassengerTicket", passengerDetails)
                .then((ticket) => {
                  store.commit("setPurchasedTicket", ticket)
                  store.commit("setPurchaseStatus", true)
                  window.location.href = "#ticketPrintPreview";
                  store.commit("resetTicketDetails")
                  store.commit("setResetStatus", true)
                  hideLoader();
                })
                .catch(error => {
                  if(error.status === 401) {
                    purchaseTicket()
                  } else {
                    store.commit("resetTicketDetails")
                    store.commit("setSeatResetStatus", true)
                    refreshPageGoBack()
                    hideLoader();
                  }
                })
          } else {
            errorMsg();
            hideLoader();
          }
        }
      }
    }

    const calculateFareTicket = () => {
      let farePerSeat = {};
      let seatTotalAmount = 0;
      let farePerTicket = 0;

      if (userCompanyDetails.value.fareType === "predefined") {
        //const discountPerTicket = Math.floor(SeatDetails.value.ticketDetails.discount / SeatDetails.value.seatFare.length);
        SeatDetails.value.seatFare.forEach((seat) => {
          seatTotalAmount = seatTotalAmount + seat.fare;
        });
        //Changed fare value
        if (seatTotalAmount !== SeatDetails.value.ticketDetails.totalAmount) {
          seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        }
        farePerTicket = Math.floor(seatTotalAmount / SeatDetails.value.seatFare.length)
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = seat.fare;
        });
      } else if (userCompanyDetails.value.fareType === "instant_fare") {
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        farePerTicket = Math.floor(seatTotalAmount / SeatDetails.value.seatFare.length)
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      } else if (userCompanyDetails.value.fareType === "trip_fare") {
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        farePerTicket = Math.floor(seatTotalAmount / SeatDetails.value.seatFare.length)
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      }
      return {
        'farePerTicket': farePerTicket,
        'farePerSeat': farePerSeat,
        'seatTotalAmount': seatTotalAmount
      };
    }

    const calculateReservationFareTicket = () => {
      let farePerSeat = {};
      let seatTotalAmount = 0;
      let farePerTicket = 0;

      if (userCompanyDetails.value.fareType === "predefined") {
        SeatDetails.value.seatFare.forEach((seat) => {
          seatTotalAmount = seatTotalAmount + seat.fare;
        });
        //Changed fare value
        if (seatTotalAmount !== SeatDetails.value.ticketDetails.totalAmount) {
          seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        }
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.totalAmount / SeatDetails.value.seatFare.length)
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = seat.fare;
        });
      } else if (userCompanyDetails.value.fareType === "instant_fare") {
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.totalAmount / SeatDetails.value.seatFare.length)
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      } else if (userCompanyDetails.value.fareType === "trip_fare") {
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.totalAmount / SeatDetails.value.seatFare.length)
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      }
      return {
        'farePerTicket': farePerTicket,
        'farePerSeat': farePerSeat,
        'seatTotalAmount': seatTotalAmount,
      };
    }

    const passengerPhoneNumber = computed(() => {
      if (passenger.phoneNo !== '') {
        return '0' + passenger.phoneNo;
      }
      return passenger.phoneNo;
    })



    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });

      requiredValidation("boardingPlace", passenger.boardingPlace.toString());
      requiredValidation("droppingPoint", passenger.droppingPoint.toString());
      duplicateValidation("duplication", "from stoppage", "to stoppage", passenger.fromStoppage, passenger.toStoppage )
      if((hasPermission(Counterman.isPhoneNumberRequired) && store.getters.userCompanyData.isPhoneNumberRequired) || passengerPhoneNumber.value){
        bdPhoneValidation("mobile", sanitizePhone(passengerPhoneNumber.value));
      }

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const errorMsg = () => {
      ticketPurchaseError.value =
          "May be, You did not Select any seat / Your selected route does not have any fare!";
    };

    watch(() => resetStatus, () => {
      if (resetStatus.value === true) {
        passenger.fromStoppage = initialDetails.value.fromStoppage;
        passenger.toStoppage = initialDetails.value.toStoppage;
        initialDetails.value.counter = "";
        passenger.trip = selectedTripDetails.value.tripId;
        passenger.coach = selectedTripDetails.value.coach._id;
        passenger.name = '';
        passenger.phoneNo = '';
        passenger.nid = '';
        passenger.address = '';
        passenger.gender = 'Male';
        passenger.ticketType = "SEAT";
        nextTick(() => {
          store.commit("setResetStatus", false)
        })
      }

    }, {deep: true});

    const stoppages = computed(() => {
      return store.getters.getTripForTicketingNew.stoppages
    })

    const boardingPoints = computed(() => {
      return store.getters.getTripForTicketingNew.boardingList
    })

    const droppingPoints = computed(() => {
      return store.getters.getTripForTicketingNew.droppingList
    })

    const isMobile = ref(false);

    onBeforeUnmount(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize)
      }
      clearTimeout(reservationSetTimeout)
    })

    onMounted(() => {
      onResize()
      window.addEventListener('resize', onResize)
    })


    const onResize = () => {
      isMobile.value = window.innerWidth < 768
    }

  watch(() => selectedTripDetails.value, () => {
    passenger.boardingPlace = selectedTripDetails.value.selectedBoarding;
    passenger.droppingPoint = selectedTripDetails.value.selectedDropping;
  })

    return {
      t,
      locale,
      passenger,
      dropdownVariables,
      err,
      errors,
      purchaseTicket,
      ticketPurchaseError,
      reserveTicket,
      openReserveModal,
      passengerDetailsForReservation,
      refreshPageGoBack,
      activeReservation,
      searchWithPassengerPhone,
      passengerPhoneNumber,
      hasPermission,
      Counterman,
      refreshPageGoBackForReservation,
      stoppages,
      boardingPoints,
      droppingPoints,
      isMobile,
      initialDetails,
      isReferCounterAllowed: userCompanyDetails.value.isReferCounterAllowed,
    }
  }
}
</script>

<style scoped>
.tableInfo_border {
  border: 1px solid #f04935;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.corporate_btn {
  background-color: #00b74a;
  border: 1px solid #ece3e2;
  color: #fff;
}

.booking_btn {
  background-color: red;
  border: 1px solid #ece3e2;
  color: #fff;
}

.booking_btn:hover {
  color: red;
}

.corporate_btn:hover {
  color: #00b74a;
}

.confirm_btn {
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 8px;
  width: 170px;
}

.confirm_btn:hover {
  background-color: #fff;
}

@media (max-width: 480px) {
  .confirm_btn {
    width: 130px;
    margin-right: 10px;
  }
}

</style>
