<template>
  <div>
    <div v-if="addUpdate">
      <SeatClassAddUpdateModal
        v-model="addUpdate"
        v-bind:counter="seatClassId"
        v-bind:company="pagination.companyId"
        @getAllSeatClass="getAllSeatClass"
      />
    </div>

    <div>
      <FilterTab>
        <!-- <template v-slot:title><h6>Seat Class</h6></template> -->
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-if="hasPermission(Owner.seatClassWrite)" v-slot:button1>
          <button class="edit_table_data font_detail" @click="toggle">
            <i class="fas fa-plus"></i
            ><span class="mx-1 bold">{{
              t("Add_new", {}, { locale: $store.getters.getLang })
            }}</span>
            <!--              <span v-if="!counterman"><i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span></span>-->
            <!--              <span v-else><i class="fa fa-arrow-left"></i><span class="mx-1 bold">Go Back</span></span>-->
          </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getOwnerServicesSeatClass">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td v-if="hasPermission(Owner.seatClassWrite)" class="font_detail">
              {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(seatClass, index) in $store.getters
              .getOwnerServicesSeatClass"
            :key="seatClass._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">{{ seatClass.name }}</td>

            <td>
              <span v-if="seatClass.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>
            <td v-if="hasPermission(Owner.seatClassWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateSeatClass(seatClass._id)"
              >
                <i class="fas fa-pencil-alt"></i
                ><span class="mx-1">{{
                  t("Edit", {}, { locale: $store.getters.getLang })
                }}</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getOwnerServicesTotalSeatClass"
            :per-page="pagination.limit"
            @paginate="getAllSeatClass"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import SeatClassAddUpdateModal from "./SeatClassAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatClass",
  components: { SeatClassAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: "",
    });
    const addUpdate = ref(false);
    const counterman = ref(false);
    const seatClassId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const getAllSeatClass = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesSeatClassByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllSeatClass();
          }
        });
    };
    const close = () => {
      addUpdate.value = false;
      counterman.value = false;
    };
    const toggle = () => {
      seatClassId.value = "";
      if (counterman.value) {
        counterman.value = false;
      } else {
        addUpdate.value = !addUpdate.value;
      }
    };
    const updateSeatClass = (id) => {
      seatClassId.value = id;
      addUpdate.value = !addUpdate.value;
    };
    const updateCounterman = (id) => {
      seatClassId.value = id;
      counterman.value = true;
    };

    onBeforeMount(() => {
      pagination.companyId = store.getters.userCompany;
      getAllSeatClass();
    });

    return {
      t,
      locale,
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      counterman,
      seatClassId,
      Owner,
      hasPermission,
      getAllSeatClass,
      toggle,
      updateSeatClass,
      close,
      updateCounterman,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
