<template>
  <cancellationCause
    v-if="modelValue"
    v-model="modelValue"
    :ticketIdStore="ticketIdStore"
    :ticketTotalSeats="ticketSeats"
    :pagination="pagination"
    @getAllTripsForTicketing="getAllTripsForTicketing"
  />
  <div>
    <FilterTab>
      <template v-slot:title><h6>Ticket Cancellation</h6></template>
      <template v-slot:body> </template>
      <template v-slot:button1>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>
    <div
      v-if="$store.getters.getTicketsHistoryCounterAgentIntercity"
      class="table-responsive"
    >
      <Table>
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">PASSENGER NAME</td>
            <td class="font_detail">PHONE</td>
            <td class="font_detail">SEAT NUMBERS</td>
            <td class="font_detail">TOTAL SEATS</td>
            <td class="font_detail">SEAT CLASS</td>
            <td v-if="hasPermission(Agent.ticketCancel)" class="font_detail">
              CANCEL
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(ticket, index) in $store.getters
              .getTicketsHistoryCounterAgentIntercity"
            :key="ticket._id"
          >
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ ticket.passengerName }}</td>
            <td class="font_detail">{{ ticket.passengerPhone }}</td>
            <td class="font_detail">
              {{ ticket.seatNumbers.replaceAll(",", ", ") }}
            </td>
            <td class="font_detail">{{ ticket.totalSeat }}</td>
            <td class="font_detail">{{ ticket.seatClassName }}</td>
            <td v-if="hasPermission(Agent.ticketCancel)" class="font_detail">
              <button
                class="edit_table_data font_detail view_table_button"
                @click="cancelTicket(ticket._id, ticket.seatNumbers)"
              >
                <i></i><span class="mx-1">CANCEL</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalTicketsHistoyCounterAgentIntercity"
            :per-page="pagination.limit"
            @paginate="getTicketsHistory"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import cancellationCause from "./CancellationCause.vue";
const { hasPermission } = permission();
const { Agent } = roles();
export default {
  name: "TicketsDetails",
  components: {
    cancellationCause,
  },
  props: {
    tripIdPass: {
      type: String,
    },
    paginationData: {
      type: Object,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    let modelValue = ref(false);
    let ticketIdStore = ref("");
    let tripId = ref(props.tripIdPass);
    let ticketSeats = ref([]);
    let pagination = reactive({
      page: 1,
      limit: 25,
      tripId: tripId.value,
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const goBack = () => {
      emit("close");
    };
    const getTicketsHistory = () => {
      showLoader();
      store
        .dispatch("getTicketsHistoryAgentIntercity", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };
    const getAllTripsForTicketing = () => {
      showLoader();
      store
        .dispatch(
          "getActiveTripsForTicketingAgentIntercity",
          props.paginationData
        )
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTripsForTicketing();
          }
        });
    };
    const cancelTicket = (ticketId, seats) => {
      ticketSeats.value = seats.split(",");
      modelValue.value = true;
      ticketIdStore.value = ticketId;
    };
    onBeforeMount(() => {
      getTicketsHistory();
    });
    return {
      options,
      pagination,
      Agent,
      modelValue,
      ticketIdStore,
      hasPermission,
      cancelTicket,
      getTicketsHistory,
      goBack,
      ticketSeats,
      getAllTripsForTicketing,
    };
  },
};
</script>

<style scoped></style>
