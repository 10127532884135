<template>
  <div>
    <FilterTab>
      <template v-slot:title
        ><h6>
          {{ t("BoardingPlace", {}, { locale: $store.getters.getLang }) }}
        </h6></template
      >
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">{{
            t("Go Back", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Owner.stoppageWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i
          ><span class="mx-1 bold">{{
            t("Add_new", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
        <button v-else class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">{{
            t("Go Back", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
    <Table>
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td v-if="hasPermission(Owner.stoppageWrite)" class="font_detail">
            {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template
        v-slot:body
        v-if="$store.getters.getOwnerServicesBoardingPlaces"
      >
        <tr
          v-for="(boardingPlace, index) in $store.getters
            .getOwnerServicesBoardingPlaces"
          :key="index"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ boardingPlace }}</td>

          <td v-if="hasPermission(Owner.stoppageWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(boardingPlace)"
            >
              <i class="fas fa-edit"></i
              ><span class="mx-1">{{
                t("Edit", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </td>
        </tr>
      </template>
    </Table>
    <div v-if="showModal">
      <BoardingPlaceAddUpdateModal
        v-model="showModal"
        @getAllBoardingPlaces="getAllBoardingPlaces"
        v-bind:route="rId"
        v-bind:boardingPlace="boardingPlaceId"
        :boardingPlaceName="boardingPlace"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import BoardingPlaceAddUpdateModal from "./BoardingPlaceAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "BoardingPlace",
  components: { BoardingPlaceAddUpdateModal },
  props: {
    routeId: String,
  },
  setup(props, { emit }) {
    let pagination = reactive({
      page: 1,
      limit: 25,
      routeId: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const rId = ref(props.routeId);
    const boardingPlaceId = ref("");
    const boardingPlace = ref("");
    const showModal = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const getAllBoardingPlaces = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesBoardingPlacesByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllBoardingPlaces();
          }
        });
    };
    const goBack = () => {
      emit("toggle");
    };
    const addModal = () => {
      boardingPlaceId.value = "";
      showModal.value = true;
    };
    const updateModal = (bp) => {
      boardingPlaceId.value = bp;
      boardingPlace.value = bp;
      showModal.value = true;
    };

    onBeforeMount(() => {
      if (rId.value) {
        pagination.routeId = rId.value;
        getAllBoardingPlaces();
      }
    });

    return {
      t,
      locale,
      dropdownVariables,
      pagination,
      options,
      showModal,
      boardingPlaceId,
      rId,
      Owner,
      hasPermission,
      addModal,
      updateModal,
      getAllBoardingPlaces,
      goBack,
      boardingPlace,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
