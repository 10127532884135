import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import web from "../urls/web";
import Admin from "../views/Admin/Admin";
import AdminAdmins from "../views/Admin/Admins/Admins";
import AdminCompanyDeploy from "../views/Admin/CompanyDeploy/CompanyDeploy";
import CompanyManage from "../views/Admin/CompanyDeploy/CompanyManage";
import AdminDashboard from "../views/Admin/Dashboard/Dashboard";
import LayoutList from "@/views/Admin/TicketDesign/LayoutList";
import Settings from "@/views/Admin/Settings/index";
import AdminReport from "../views/Admin/Report/Report";
import Agent from "../views/Agent/Agent";
import AgentCoach from "../views/Agent/Coach/Coach";
import AgentDashboard from "../views/Agent/Dashboard/Dashboard";
import AgentReport from "../views/Agent/Report/Report";
import AgentTrip from "../views/Agent/Trips/Trips";
import Login from "../views/Authentication/Login";
import CountermanCoach from "../views/Counterman/Coach/Coach.vue";
import Counterman from "../views/Counterman/Counterman";
import CountermanDashboard from "../views/Counterman/Dashboard/Dashboard";
import CountermanReport from "../views/Counterman/Report/Report";
// import CountermanTrip from "../views/Counterman/Trips/Trips";
import Error from "../views/Default/Error";
import Profile from "../views/Default/Profile";
import OwnerDashboard from "../views/Owner/Dashboard/Dashboard";
import Owner from "../views/Owner/Owner";
import OwnersList from "../views/Owner/Owners/OwnersList";
import OwnerReport from "../views/Owner/Report/Report";
import OwnerServices from "../views/Owner/Services/CompanyManage";
import middlewarePipeline from "./middlewarePipeline";
import admin from "./middlewares/admin";
import agent from "./middlewares/agent";
import auth from "./middlewares/auth";
import { companyStatus } from "./middlewares/company";
import counterman from "./middlewares/counterman";
import guest from "./middlewares/guest";
import hasAccess from "./middlewares/hasAccess";
import owner from "./middlewares/owner";
import DigitalTicketing from "@/views/Admin/DigitalTecketing/DigitalTicketing";
import PaymentSettlement from "@/views/Admin/PaymentSettlement/index";
import QRTickets from "@/views/Admin/QRTickets/index";
import Stoppage from "@/views/Admin/DigitalTecketing/children/Stoppage/Stoppage";
import PasswordChange from "@/views/Default/PasswordChange.vue";






const routes = [
    {
        path: web.Default.LOGIN_URL.path,
        name: web.Default.LOGIN_URL.name,
        component: Login,
        meta: {
            title: web.Default.LOGIN_URL.title,
            middleware: [
                guest
            ]
        }
    },
    {
        path: web.Admin.path,
        name: web.Admin.name,
        component: Admin,
        meta: {
            middleware: [
                auth,
                admin,
                hasAccess
            ]
        },
        children: [
            {
                path: web.Admin.DASHBOARD_URL.path,
                name: web.Admin.DASHBOARD_URL.name,
                component: AdminDashboard,
                meta: {
                    title: web.Admin.DASHBOARD_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Admin.REPORT_URL.path,
                name: web.Admin.REPORT_URL.name,
                component: AdminReport,
                meta: {
                    title: web.Admin.REPORT_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Admin.ADMINS_URL.path,
                name: web.Admin.ADMINS_URL.name,
                component: AdminAdmins,
                meta: {
                    title: web.Admin.ADMINS_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                },
            },
            {
                path: web.Admin.COMPANY_DEPLOY_URL.path,
                name: web.Admin.COMPANY_DEPLOY_URL.name,
                component: AdminCompanyDeploy,
                meta: {
                    title: web.Admin.COMPANY_DEPLOY_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                },
                children: [
                    {
                        path: web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.path,
                        name: web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.name,
                        component: CompanyManage,
                        props: true,
                        meta: {
                            middleware: [
                                auth,
                                admin,
                                companyStatus,
                            ]
                        }
                    }
                ]
            },
            {
                path: web.Admin.DIGITAL_TICKETING_URL.path,
                name: web.Admin.DIGITAL_TICKETING_URL.name,
                component: DigitalTicketing,
                meta: {
                    title: web.Admin.DIGITAL_TICKETING_URL.title,
                    middleware: [
                        auth,
                        admin,
                    ]
                },
                children: [
                    {
                        path: web.Admin.DIGITAL_TICKETING_URL.children.STOPPAGE_URL.path,
                        name: web.Admin.DIGITAL_TICKETING_URL.children.STOPPAGE_URL.name,
                        component: Stoppage,
                        props: true,
                        meta: {
                            middleware: [
                                auth,
                                admin,
                            ]
                        }
                    }
                ]
            },
            {
                path: web.Admin.TICKET_DESIGN_URL.path,
                name: web.Admin.TICKET_DESIGN_URL.name,
                component: LayoutList,
                meta: {
                    title: web.Admin.TICKET_DESIGN_URL.title,
                    middleware: [
                        auth,
                        admin
                    ]
                }
            },
            {
                path: web.Admin.PAYMENT_SETTLEMENT_URL.path,
                name: web.Admin.PAYMENT_SETTLEMENT_URL.name,
                component: PaymentSettlement,
                meta: {
                    title: web.Admin.PAYMENT_SETTLEMENT_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Admin.QR_TICKETS_URL.path,
                name: web.Admin.QR_TICKETS_URL.name,
                component: QRTickets,
                meta: {
                    title: web.Admin.QR_TICKETS_URL.title,
                    middleware: [
                        auth,
                        admin,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Admin.SETTINGS_URL.path,
                name: web.Admin.SETTINGS_URL.name,
                component: Settings,
                meta: {
                    title: web.Admin.SETTINGS_URL.title,
                    middleware: [
                        auth,
                        admin
                    ]
                },
                children: [
                    {
                        path: web.Admin.SETTINGS_URL.children.QR_URL.path,
                        name: web.Admin.SETTINGS_URL.children.QR_URL.name,
                        component: Stoppage,
                        props: true,
                        meta: {
                            middleware: [
                                auth,
                                admin,
                            ]
                        }
                    }
                ]
            },
            {
                path: web.Admin.PROFILE_URL.path,
                name: web.Admin.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Admin.PROFILE_URL.title,
                    middleware: [
                        auth,
                        admin
                    ]
                }
            },
        ]
    },
    {
        path: web.Owner.path,
        component: Owner,
        // meta:{
        //     middleware: [
        //         auth
        //     ]
        // },
        children: [
            {
                path: web.Owner.DASHBOARD_URL.path,
                name: web.Owner.DASHBOARD_URL.name,
                component: OwnerDashboard,
                meta: {
                    title: web.Owner.DASHBOARD_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Owner.REPORT_URL.path,
                name: web.Owner.REPORT_URL.name,
                component: OwnerReport,
                meta: {
                    title: web.Owner.REPORT_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Owner.OWNERS_URL.path,
                name: web.Owner.OWNERS_URL.name,
                component: OwnersList,
                meta: {
                    title: web.Owner.OWNERS_URL.title,
                    middleware: [
                        auth,
                        owner,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Owner.SERVICES_URL.path,
                name: web.Owner.SERVICES_URL.name,
                component: OwnerServices,
                meta: {
                    title: web.Owner.SERVICES_URL.title,
                    middleware: [
                        auth,
                        owner,
                        // hasAccess
                    ]
                }
            },
            {
                path: web.Owner.PROFILE_URL.path,
                name: web.Owner.PROFILE_URL.name,
                component: Profile,
                meta: {
                    middleware: [
                        auth,
                        owner
                    ]
                }
            },
        ]
    },
    {
        // path: web.Counterman.path,
        path: web.Counterman.path,
        component: Counterman,
        meta: {
            middleware: [
                auth
            ]
        },
        children: [
            {
                path: web.Counterman.DASHBOARD_URL.path,
                name: web.Counterman.DASHBOARD_URL.name,
                component: CountermanDashboard,
                meta: {
                    title: web.Counterman.DASHBOARD_URL.title,
                    middleware: [
                        auth,
                        counterman,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Counterman.REPORT_URL.path,
                name: web.Counterman.REPORT_URL.name,
                component: CountermanReport,
                meta: {
                    title: web.Counterman.REPORT_URL.title,
                    middleware: [
                        auth,
                        counterman,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Counterman.COACH_URL.path,
                name: web.Counterman.COACH_URL.name,
                component: CountermanCoach,
                meta: {
                    title: web.Counterman.COACH_URL.title,
                    middleware: [
                        auth,
                        counterman,
                        hasAccess
                    ]
                }
            },
            // {
            //     path: web.Counterman.TRIPS_URL.path,
            //     name: web.Counterman.TRIPS_URL.name,
            //     component: CountermanTrip,
            //     meta: {
            //         title: web.Counterman.TRIPS_URL.title,
            //         middleware: [
            //             auth,
            //             counterman,
            //             hasAccess
            //         ]
            //     }
            // },
            {
                path: web.Counterman.PROFILE_URL.path,
                name: web.Counterman.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Counterman.PROFILE_URL.title,
                    middleware: [
                        auth,
                        counterman
                    ]
                }
            },
            {
                path: 'password-change',
                name: 'counterman-password-change',
                component: PasswordChange,
                meta: {
                    title: 'Password Change',
                    middleware: [
                        auth,
                        counterman
                    ]
                }
            },
        ]
    },
    {
        path: web.Agent.path,
        component: Agent,
        meta: {
            middleware: [
                auth
            ]
        },
        children: [
            {
                path: web.Agent.DASHBOARD_URL.path,
                name: web.Agent.DASHBOARD_URL.name,
                component: AgentDashboard,
                meta: {
                    title: web.Agent.DASHBOARD_URL.title,
                    middleware: [
                        auth,
                        agent,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Agent.REPORT_URL.path,
                name: web.Agent.REPORT_URL.name,
                component: AgentReport,
                meta: {
                    title: web.Agent.REPORT_URL.title,
                    middleware: [
                        auth,
                        agent,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Agent.COACH_URL.path,
                name: web.Agent.COACH_URL.name,
                component: AgentCoach,
                meta: {
                    title: web.Agent.COACH_URL.title,
                    middleware: [
                        auth,
                        agent,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Agent.TRIPS_URL.path,
                name: web.Agent.TRIPS_URL.name,
                component: AgentTrip,
                meta: {
                    title: web.Agent.TRIPS_URL.title,
                    middleware: [
                        auth,
                        agent,
                        hasAccess
                    ]
                }
            },
            {
                path: web.Agent.PROFILE_URL.path,
                name: web.Agent.PROFILE_URL.name,
                component: Profile,
                meta: {
                    title: web.Agent.PROFILE_URL.title,
                    middleware: [
                        auth,
                        agent
                    ]
                }
            },
        ]
    },
    {
        path: web.Default.ERROR_URL.path,
        name: web.Default.ERROR_URL.name,
        component: Error,
        // meta:{
        //     middleware: [
        //         guest
        //     ]
        // }
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})

router.beforeEach((to, from, next) => {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

})
export default router;