<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="dPlace.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!dpId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addDroppingPlace"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateDroppingPlace"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";


export default {
  name: "DroppingPlaceAddUpdateModal",
  props: {
    droppingPlace: String,
    stoppage: String,
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    let dPlace = reactive({
      stoppageId: "",
      name: "",
      previousName: "",
      newName: "",
      default: false,
    });
    const dpId = ref(props.droppingPlace);
    const rId = ref(props.stoppage);
    const title = ref("Add Dropping Place");

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => dPlace.name,
      () => {
        requiredValidation("name", dPlace.name);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", dPlace.name);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addDroppingPlace = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addDigitalTicketingDroppingPlace", dPlace)
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addDroppingPlace();
            }
          });
      }
    };
    const updateDroppingPlace = () => {
      if (validate()) {
        showLoader();
        dPlace.newName = dPlace.name;
        store
          .dispatch("updateDigitalTicketingDroppingPlace", dPlace)
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateDroppingPlace();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      dPlace.stoppageId = rId.value;
      if (dpId.value !== "") {
        title.value = "Update Dropping Place";
        dPlace.previousName = props.droppingPlace;
        dPlace.name = dPlace.previousName;
      }
    });

    return {
      dpId,
      dPlace,
      errors,
      title,
      addDroppingPlace,
      updateDroppingPlace,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
