<template>
  <Modal @close="closeModal" :size="'lg'">
    <template v-slot:title>
      {{ t(title, {}, { locale: $store.getters.getLang }) }}
    </template>
    <template v-slot:body>
      <Error v-if="err" v-bind:error="err" />
      <div class="mx-6">
        <form class="my-2">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="form-group row">
                <TicketInput
                    v-model="passengerDetails.passengerName"
                    v-bind:placeholder="'Passenger Name'"
                    v-bind:label="'Name'"
                    v-bind:id="'name'"
                    v-bind:type="'text'"
                />
              </div>
              <div class="form-group row my-1">
                <TicketInput
                    v-model="phoneNumber"
                    v-bind:placeholder="'Passenger Mobile'"
                    v-bind:label="'Mobile'"
                    v-bind:id="'mobile'"
                    v-bind:type="'number'"
                >
                </TicketInput>
              </div>
              <div class="form-group row my-1" v-if="passengerDetails.ticketType === 'GOODS'">
                <TicketInput
                    v-model="passengerDetails.seatNumberForGoods"
                    v-bind:placeholder="'Passenger Seat Number'"
                    v-bind:label="'Seat No'"
                    v-bind:id="'seatNumberForGoods'"
                    v-bind:type="'text'"
                >
                </TicketInput>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="form-group row my-1">
                <TicketDropdown
                    v-model="passengerDetails.fromStoppage"
                    :id="'fromStoppage'"
                    :label="'From Stoppage'"
                    :defaultOption="'---select a stoppage---'"
                    :defaultValue="''"
                    :dropdown="singleTripDetails.stoppages"
                >
                </TicketDropdown>
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                    v-model="passengerDetails.toStoppage"
                    v-bind:id="'toStoppage'"
                    v-bind:label="'To Stoppage'"
                    v-bind:defaultOption="'---select a stoppage---'"
                    v-bind:defaultValue="''"
                    v-bind:dropdown="singleTripDetails.stoppages"
                >
                </TicketDropdown>
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                    v-model="passengerDetails.boardingPlace"
                    :id="'boardingPlace'"
                    :label="'Boarding place'"
                    :defaultOption="'---select a boarding place---'"
                    :defaultValue="''"
                    :dropdown="boardingPlaces"
                    :showItem="'name'"
                    :showItem1="'boardingDateTime'"
                    :returnItem="''"
                >
                </TicketDropdown>

                <Error
                    v-if="errors.boardingPlace"
                    v-bind:error="errors.boardingPlace"
                />
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                    v-model="passengerDetails.droppingPoint"
                    v-bind:id="'droppingPoint'"
                    v-bind:label="'Dropping place'"
                    v-bind:defaultOption="'---select a dropping place---'"
                    v-bind:defaultValue="''"
                    v-bind:dropdown="droppingPlaces"
                >
                </TicketDropdown>
                <Error
                    v-if="errors.droppingPoint"
                    v-bind:error="errors.droppingPoint"
                />
              </div>
              <div class="row m-2">
                <div
                    class="col-sm-4 col-md-4"
                    v-if="passengerDetails.ticketType !== 'GOODS'"
                >
                  <p class="text-center font_detail m-0 text-info">
                    {{
                      t("TOTAL SEAT", {}, { locale: $store.getters.getLang })
                    }}
                  </p>
                  <input
                      v-model="passengerDetails.totalSeat"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <Error
                      v-if="errors.totalSeat"
                      v-bind:error="errors.totalSeat"
                  />
                </div>
                <div class="col-sm-4 col-md-4" v-else>
                  <p
                      class="text-center font_detail text-info m-0"
                      v-if="passengerDetails.ticketType == 'GOODS'"
                  >
                    {{
                      t(
                          "GOODS_WEIGHT_IN_(KG)",
                          {},
                          { locale: $store.getters.getLang }
                      )
                    }}
                  </p>
                  <input
                      v-if="passengerDetails.ticketType == 'GOODS'"
                      v-model="passengerDetails.goodsWeight"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <!-- <span v-if="passengerDetails.ticketType == 'GOODS'">
                    <Error
                      v-if="errors.goodsWeight"
                      v-bind:error="errors.goodsWeight"
                    />
                  </span> -->
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail text-info m-0">
                    {{
                      t("TOTAL AMOUNT", {}, { locale: $store.getters.getLang })
                    }}
                  </p>
                  <input
                      v-model="passengerDetails.totalAmount"
                      type="number"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      @input="manageTotalAmountInput"
                      class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <Error
                      v-if="errors.totalAmount"
                      v-bind:error="errors.totalAmount"
                  />
                </div>
                <!-- <div
                  v-if="hasPermission(Counterman.fareDiscount)"
                  class="col-sm-4 col-md-4"
                >
                  <p class="text-center font_detail text-info m-0">DISCOUNT</p>
                  <input
                    v-model="passengerDetails.discount"
                    type="number"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                </div> -->
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail text-info m-0">
                    {{
                      t("PAID AMOUNT", {}, { locale: $store.getters.getLang })
                    }}
                  </p>
                  <input
                      v-model="passengerDetails.paid"
                      min="0"
                      oninput="this.value = Math.abs(this.value)"
                      type="number"
                      class="input_field form-control rounded px-3 w-100 text-center"
                      :disabled="!$store.getters.allowDue"
                  />
                  <Error
                      v-if="errors.paidAmount"
                      v-bind:error="errors.paidAmount"
                  />
                </div>
              </div>
              <div class="row m-2">
                <div class="col-sm-4 col-md-4"></div>
                <div class="col-sm-4 col-md-4"></div>
              </div>

              <div class="row m-2">
                <!-- <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">PAYABLE</p>
                  <input
                    disabled
                    readonly
                    v-model="calculatePayable"
                    type="number"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                </div> -->
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">
                    {{ t("DUE", {}, { locale: $store.getters.getLang }) }}
                  </p>
                  <input
                      disabled
                      readonly
                      v-model="calculateDue"
                      type="number"
                      class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <!-- <p class="text-danger" v-if="discountErrorMsg">
                      Discount amount can't be greater than netfare
                    </p> -->
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">
                    {{ t("REFUND", {}, { locale: $store.getters.getLang }) }}
                  </p>
                  <input
                      disabled
                      readonly
                      v-model="calculateRefund"
                      type="number"
                      class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <!-- <p class="text-danger" v-if="discountErrorMsg">
                      Discount amount can't be greater than netfare
                    </p> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 order-2">
              <p class="text-center font_detail m-0">
                {{ t("CONFIRM", {}, { locale: $store.getters.getLang }) }}
              </p>
              <button
                  v-if="passengerDetails.ticketType === 'EXTRA'"
                  class="confirm_button btn btn-success w-100"
                  :disabled="
                  calculatePayable <= 0 ||
                  passengerDetails.totalSeat <= 0 ||
                  passengerDetails.totalAmount <= 0
                "
                  @click.prevent="confirmExtraTicket"
              >
                <i class="fas fa-check"></i>
              </button>
              <button
                  v-if="passengerDetails.ticketType === 'GOODS'"
                  class="confirm_button btn btn-success w-100"
                  :disabled="
                  calculatePayable <= 0 ||
                  passengerDetails.goodsWeight === '' ||
                  passengerDetails.totalAmount < 10
                "
                  @click.prevent="confirmExtraTicket"
              >
                <i class="fas fa-check"></i>
              </button>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 order-1">
              <p class="text-center font_detail m-0">
                {{ t("CANCEL", {}, { locale: $store.getters.getLang }) }}
              </p>
              <button
                  ref="cancel"
                  class="confirm_button btn btn-danger w-100"
                  @click.prevent="cancelModal"
                  v
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import useInputValidation from "@/modules/useInputValidations";
import { watch, ref, onBeforeMount, inject, computed } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import moment from "moment";
import store from "@/store";
import { useI18n } from "vue-i18n";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
// import {dateTimeFormat} from "@/helpers/dateTimeFormat";
export default {
  name: "TicketExtraBookingModal",
  props: {
    singleTripDetails: Object,
    extraTicketModalName: String,
    extraTicketType: String,
  },

  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let { requiredValidation, errors, validNumberValidation } =
        useInputValidation();
    const { t, locale } = useI18n();
    let phoneNumber = ref('')
    const countLoad = ref(0)
    let passengerDetails = reactive({
      trip: "",
      coach: "",
      fromStoppage: "",
      toStoppage: "",
      ticketType: props.extraTicketType,
      passengerName: "",
      passengerPhone: "",
      passengerGender: "",
      passengerNID: "",
      passengerAddress: "",
      boardingPlace: "",
      droppingPoint: "",
      totalSeat: 1,
      goodsWeight: "",
      farePerTicket: 0,
      seatClass: "",
      totalAmount: "",
      discount: "",
      payable: "",
      paid: "",
      dues: "",
      refund: "",
      referenced_by: null,
      ticketDateTime: "",
      boardingTripDateTime: "",
      seatNumberForGoods: ""
    });
    let title = ref("");
    let ticketType = ref(props.extraTicketModalName);
    if (ticketType.value === "SEATS") {
      title.value = "EXTRA TICKET";
    } else {
      title.value = "GOODS TICKET";
    }
    let err = ref("");
    let singleTripDetails = ref(props.singleTripDetails);
    const { hasPermission } = permission();
    const { Counterman } = roles();
    let calculatePayable = ref(0);
    let calculateDue = ref(0);
    let calculateRefund = ref(0);
    let payable = ref(0);
    let boardingPlaceDropDownFilter = ref({});
    let droppingPointDropdownFilter = ref({});
    // const boardingPlaceMinutes = ref(0);
    const extraTicketPreLoadData = ref({})
    const boardingPlaces = ref({})
    const droppingPlaces = ref({})

    onBeforeMount(() => {
      passengerDetails.fromStoppage = singleTripDetails.value.selectedFromStoppage;
      passengerDetails.toStoppage = singleTripDetails.value.selectedToStoppage;
      passengerDetails.boardingPlace = singleTripDetails.value.selectedBoarding;
      passengerDetails.droppingPoint = singleTripDetails.value.selectedDropping;
      passengerDetails.trip = singleTripDetails.value.tripId;
      passengerDetails.seatClass = singleTripDetails.value.seatClass;
      passengerDetails.coach = singleTripDetails.value.coach._id;
      boardingPlaces.value = singleTripDetails.value.boardingList;
      droppingPlaces.value = singleTripDetails.value.droppingList;
    });

    watch(
        () => passengerDetails.totalAmount,
        () => {
          passengerDetails.paid = passengerDetails.totalAmount;
        }
    );
    watch(
        () => phoneNumber.value,
        () => {
          if(phoneNumber.value !== '') {
            passengerDetails.passengerPhone = '0'+phoneNumber.value;
          } else {
            passengerDetails.passengerPhone = phoneNumber.value;
          }
        }
    );

    watch(
        () => passengerDetails.totalSeat,
        () => {
          validNumberValidation("totalSeat", passengerDetails.totalSeat);
        }
    );
    // watch(
    //   () => passengerDetails.goodsWeight,
    //   () => {
    //     validNumberValidation("goodsWeight", passengerDetails.goodsWeight);
    //   }
    // );

    watch(
        () => passengerDetails.fromStoppage,
        () => {
          if(passengerDetails.fromStoppage === passengerDetails.toStoppage) {
            err.value = "FromStoppage and ToStoppage must not be same"
          } else {
            err.value = "";
          }
          if(countLoad.value > 0) {
            viewTripDetailsWithUserPreference()
          }
        }
    );

    watch(
        () => passengerDetails.toStoppage,
        () => {
          if(passengerDetails.fromStoppage === passengerDetails.toStoppage) {
            err.value = "FromStoppage and ToStoppage must not be same"
          } else {
            err.value = "";
          }
          viewTripDetailsWithUserPreference()
          countLoad.value = 1
        }
    );
    watch(
        () => passengerDetails.boardingPlace,
        () => {
          if (passengerDetails.boardingPlace !== "") {
            // initBoardingPlaceMinutes()
            requiredValidation("boardingPlace", passengerDetails.boardingPlace.toString());
          }
        }
    );
    watch(
        () => passengerDetails.droppingPoint,
        () => {
          if (passengerDetails.droppingPoint !== "") {
            requiredValidation("droppingPoint", passengerDetails.droppingPoint.toString());
          }
        }
    );

    watch(
        () => passengerDetails.totalAmount,
        () => {
          payable.value = passengerDetails.totalAmount;
          validNumberValidation("totalAmount", passengerDetails.totalAmount);
        }
    );

    const manageTotalAmountInput = (event) => {
      if (event.target.value.length > 5){
        passengerDetails.totalAmount = event.target.value.slice(0, 5)
      }else {
        passengerDetails.totalAmount = event.target.value
      }
    }

    const viewTripDetailsWithUserPreference = () => {
      if(passengerDetails.fromStoppage === passengerDetails.toStoppage) {
        return;
      }
      const payload = {
        tripId: singleTripDetails.value.tripId,
        fromStoppage: passengerDetails.fromStoppage,
        toStoppage: passengerDetails.toStoppage,
      }
      store.dispatch("getExtraTicketPrePurchase", payload)
          .then((res) => {
            extraTicketPreLoadData.value = res;
            passengerDetails.boardingPlace = res.selectedBoarding;
            passengerDetails.droppingPoint = res.selectedDropping;
            boardingPlaces.value = res.boardingList;
            droppingPlaces.value = res.droppingList;
          })
      // store.commit("setSeatResetStatus", true)
    }

    calculatePayable = computed(() => {
      return payable.value - passengerDetails.discount;
    });

    calculateDue = computed(() => {
      let calculateValue = 0;
      if (passengerDetails.paid <= calculatePayable.value) {
        calculateValue = calculatePayable.value - passengerDetails.paid;
      }
      return calculateValue;
    });

    calculateRefund = computed(() => {
      let calculateValue = 0;
      if (passengerDetails.paid > calculatePayable.value) {
        calculateValue = passengerDetails.paid - calculatePayable.value;
      }
      return calculateValue;
    });

    // const filterBoardingAndDroppingPlace = () => {
    //   boardingPlaceDropDownFilter.value = {};
    //   droppingPointDropdownFilter.value = {};
    //   let stoppageListObjectBoarding =
    //       store.getters.getBoardingPlaceBasedOnStoppage;
    //   let stoppageListObjectDropping =
    //       store.getters.getDroppingPointBasedOnStoppage;
    //
    //   if (
    //       store.getters.getUserInfo?.counter?.preferredStoppage &&
    //       store.getters.getUserInfo?.counter?.preferredStoppage.length > 0 &&
    //       store.getters.getUserInfo?.counter?.preferredStoppage.some(
    //           (item) => item._id === passengerDetails.fromStoppage
    //       )
    //   ) {
    //     const preferredStoppageIds = [];
    //
    //     for (let preferredStoppageValues of store.getters.getUserInfo?.counter
    //         ?.preferredStoppage) {
    //       preferredStoppageIds.push(preferredStoppageValues._id);
    //     }
    //
    //     preferredStoppageIds.map((preferredStoppage)=>{
    //       Object.entries(stoppageListObjectBoarding).forEach(([key, value]) => {
    //         if(preferredStoppage === key){
    //           if(value.length !== 0){
    //             value.forEach((item) => {
    //               boardingPlaceDropDownFilter.value[item] = item;
    //             });
    //           }
    //         }
    //       })
    //     })
    //     passengerDetails.boardingPlace = Object.keys(
    //         boardingPlaceDropDownFilter.value
    //     )[store.getters.getUserInfo?.counter?.preferredBoardingPlace];
    //   } else {
    //     for (let [key, value] of Object.entries(stoppageListObjectBoarding)) {
    //       if (key === passengerDetails.fromStoppage) {
    //         if (value.length !== 0) {
    //           value.forEach((item) => {
    //             boardingPlaceDropDownFilter.value[item] = item;
    //           });
    //         } else {
    //           boardingPlaceDropDownFilter.value = {};
    //         }
    //       }
    //       let defaultBoardingPointIndex =
    //           store.getters.getDefaultBoardingPlaceBasedOnStoppage[
    //               passengerDetails.fromStoppage
    //               ];
    //
    //       if (Number.isInteger(defaultBoardingPointIndex)) {
    //         passengerDetails.boardingPlace = Object.keys(
    //             boardingPlaceDropDownFilter.value
    //         )[defaultBoardingPointIndex];
    //       } else {
    //         passengerDetails.boardingPlace = "";
    //       }
    //       // for (let key of Object.keys(boardingPlaceDropDownFilter.value)) {
    //       //   if (
    //       //     Object.keys(boardingPlaceDropDownFilter.value).indexOf(key) ===
    //       //     parseInt(store.getters.userCompanyCounter.preferredBoardingPlace)
    //       //   ) {
    //       //     if (
    //       //       store.getters.userCompanyCounter.preferredStoppage._id ===
    //       //       passengerDetails.fromStoppage
    //       //     ) {
    //       //       passengerDetails.boardingPlace = key;
    //       //     } else {
    //       //       passengerDetails.boardingPlace = "";
    //       //     }
    //       //     break;
    //       //   } else {
    //       //     passengerDetails.boardingPlace = "";
    //       //   }
    //       // }
    //       // if (Object.keys(boardingPlaceDropDownFilter.value).length === 0) {
    //       //   passengerDetails.boardingPlace = "";
    //       // }
    //     }
    //   }
    //   for (let [key, value] of Object.entries(stoppageListObjectDropping)) {
    //     if (key === passengerDetails.toStoppage) {
    //       if (value.length !== 0) {
    //         value.forEach((item) => {
    //           droppingPointDropdownFilter.value[item] = item;
    //         });
    //       } else {
    //         droppingPointDropdownFilter.value = {};
    //       }
    //     }
    //
    //     let defaultDroppingPointIndex =
    //         store.getters.getDefaultDroppingPointBasedOnStoppage[
    //             passengerDetails.toStoppage
    //             ];
    //     if (Number.isInteger(defaultDroppingPointIndex)) {
    //       passengerDetails.droppingPoint = Object.keys(
    //           droppingPointDropdownFilter.value
    //       )[defaultDroppingPointIndex];
    //     } else {
    //       passengerDetails.droppingPoint = "";
    //     }
    //   }
    // };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("boardingPlace", passengerDetails.boardingPlace.toString());
      requiredValidation("droppingPoint", passengerDetails.droppingPoint.toString());
      validNumberValidation("totalAmount", passengerDetails.totalAmount);
      if (passengerDetails.ticketType !== "GOODS") {
        // requiredValidation("goodsWeight", passengerDetails.goodsWeight);
        validNumberValidation("totalSeat", passengerDetails.totalSeat);
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "") || err.value !== ''
      );
    };

    const confirmExtraTicket = () => {
      passengerDetails.ticketDateTime = moment(new Date())
          .utcOffset(0, true)
          .format("yyyy-MM-DD hh:mm:ss");

      if (validate()) {
        passengerDetails.boardingTripDateTime = dateTimeFormat(boardingPlaces.value[passengerDetails.boardingPlace].boardingDateTime, 0);
        passengerDetails.payable = parseInt(calculatePayable.value);
        passengerDetails.dues = parseInt(calculateDue.value);
        passengerDetails.refund = parseInt(calculateRefund.value);
        passengerDetails.farePerTicket =
            Math.floor(parseInt(passengerDetails.totalAmount) /
                parseInt(passengerDetails.totalSeat));
        passengerDetails.boardingPlace = boardingPlaces.value[passengerDetails.boardingPlace].name;
        passengerDetails.droppingPoint = droppingPlaces.value[passengerDetails.droppingPoint];
        if (passengerDetails.ticketType === "EXTRA") {
          showLoader();
          store
              .dispatch("extraSeatPurchase", passengerDetails)
              .then((extraSeatPurchase) => {
                emit("extraSeatPurchase", {
                  printPreview: true,
                  lastTicketDetails: extraSeatPurchase.data.ticket,
                  ticketTypeForPrint: "extraTicket",
                });
                hideLoader();
                closeModal();
              })
              .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                  confirmExtraTicket();
                }
              });
        } else if (passengerDetails.ticketType === "GOODS") {
          showLoader();
          passengerDetails.seatClass = "";
          passengerDetails.goodsWeight =
              passengerDetails.goodsWeight.toString() + " Kg";
          store
              .dispatch("extraGoodsPurchase", passengerDetails)
              .then((extraGoodsPurchase) => {
                emit("extraGoodsPurchase", {
                  printPreview: true,
                  lastTicketDetails: extraGoodsPurchase.data.ticket,
                  ticketTypeForPrint: "goodsTicket",
                });
                window.location.href = "#ticketPrintPreview";
                hideLoader();
                closeModal();
              })
              .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                  confirmExtraTicket();
                }
              });
        }
      }
    };

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const cancelModal = () => {
      closeModal();
    };
    return {
      t,
      locale,
      closeModal,
      title,
      passengerDetails,
      dropdownVariables,
      err,
      errors,
      confirmExtraTicket,
      hasPermission,
      Counterman,
      cancelModal,
      calculatePayable,
      calculateDue,
      calculateRefund,
      ticketType,
      droppingPointDropdownFilter,
      boardingPlaceDropDownFilter,
      phoneNumber,
      boardingPlaces,
      droppingPlaces,
      manageTotalAmountInput,
    };
  },
};
</script>

<style scoped></style>
