<template>
  <div class="text-end">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <TableCard v-if="$store.getters.getAgentWiseGoodsDetails.length > 0">
    <template v-slot:body>
      <Table id="exportable_data">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">GOODS ID</td>
            <td class="font_detail">Ticket Date Time</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Goods Weight</td>
            <td class="font_detail">Total Amount</td>
            <td class="font_detail">Total Payable</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(item, index) in $store.getters.getAgentWiseGoodsDetails"
            :key="index"
          >
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ item?.goodsId }}</td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>
            <td class="font_detail">1</td>
            <td class="font_detail">{{ item?.goodsWeight }}</td>
            <td class="font_detail">{{ item?.totalAmount }} Taka</td>
            <td class="font_detail">{{ item?.payable }} Taka</td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              Total Ticket: {{ totalTicket }}
            </td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              Total Amount: {{ totalAmount }}
            </td>
            <td class="font_detail text-white">
              Total Paybale: {{ totalPayable }} Taka
            </td>
          </tr>
        </template>
      </Table>
    </template>
  </TableCard>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount } from "vue";
import store from "@/store";
import XLSX from "xlsx";
import moment from "moment";

export default {
  name: "GoodDetails",
  components: {},
  props: {
    agentId: String,
    agentName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
  },
  emits: ["backToAgentWiseReport"],
  setup(props, { emit }) {
    let agentId = ref(props.agentId);
    let agentNameProps = ref(props.agentName);
    let totalTicket = ref(0);
    let totalAmount = ref(0);
    let totalDiscount = ref(0);
    let totalPayable = ref(0);

    let agentWiseGoodsReport = reactive({
      ticketType: "GOODS",
      agentId: agentId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      discount: props.discount,
      status: props.status,
      page: 1,
      limit: 25,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getGoodsDetails = () => {
      store
        .dispatch("getAgentWiseGoodsReportDetails", agentWiseGoodsReport) //TODO add loader
        .then(() => {
          goodDetailsdata();
        })
        .catch((error) => {
          if (error.status === 401) {
            getGoodsDetails();
          }
        });
    };

    const goodDetailsdata = () => {
      store.getters.getAgentWiseGoodsDetails.forEach((data) => {
        totalTicket.value = store.getters.getAgentWiseGoodsDetails.length;
        totalAmount.value += data.totalAmount;
        totalDiscount.value += data.discount;
        totalPayable.value += data.payable;
      });
    };

    const goBack = () => {
      emit("backToAgentWiseReport", true);
    };

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(
              wb,
              fn || "Admin_AgentWise_Goods_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getGoodsDetails();
    });

    return {
      agentWiseGoodsReport,
      totalAmount,
      totalTicket,
      totalDiscount,
      totalPayable,
      goBack,
      exportExcel,
      agentNameProps,
      getGoodsDetails,
      options,
      dateFormat,
    };
  },
};
</script>
