<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <!-- <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'company'"
            v-bind:label="'Company'"
            v-bind:defaultOption="'--select company--'"
            v-model="counterWiseReport.companyId"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div> -->
        <div class="col-sm-6 col-md-2" v-if="counterWiseReport.companyId">
          <DropdownFilter
            v-bind:id="'counter'"
            v-bind:label="'Counter'"
            v-bind:defaultOption="'--select counter--'"
            v-model="counterWiseReport.counterId"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getCounterListForFilterOwnerPanel"
          />
        </div>
        <div class="col-sm-6 col-md-4" v-if="counterWiseReport.companyId">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          class="col-sm-6 col-md-2 col-xxl-1"
          v-if="counterWiseReport.companyId"
        >
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="''"
            v-model="counterWiseReport.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>
        <div
          class="col-sm-6 col-md-2 col-xxl-1"
          v-if="counterWiseReport.companyId"
        >
          <CheckboxSingle v-model="isDiscount" />
        </div>
        <div class="col-md-2 mt-auto">
          <button class="edit_table_data font_detail" @click="filterReport">
            <i class="fas fa-filter"></i><span class="mx-1 bold">{{ t("Filter", {}, { locale: $store.getters.getLang }) }}</span>
          </button>
        </div>
      </template>

      <template v-slot:button1>
        <button
          v-if="
            $store.getters.getCounterWiseReportOwner.length > 0 && filterActive
          "
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Admin_Counter_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">{{ t("Export", {}, { locale: $store.getters.getLang }) }}</span>
        </button>
      </template>
    </FilterTab>
    <Table
      v-if="$store.getters.getCounterWiseReportOwner.length > 0 && summary"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">{{ t("SL", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Counterman", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Extra Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Extra Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Goods Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Goods Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Details", {}, { locale: $store.getters.getLang }) }}</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters.getCounterWiseReportOwner"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.countermanName[0] === 'ONLINE' ? 'Jatri Online' : item.countermanName[0] }}</td>
          <td class="font_detail">{{ item.seatTicket }}</td>
          <td class="font_detail">{{ item.seatAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ item.extraTicket }}</td>
          <td class="font_detail">{{ item.extraAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ item.goodsTicket }}</td>
          <td class="font_detail">{{ item.goodsAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>

          <td class="font_detail">
            <button
              class="btn btn-primary m-1"
              @click="seatDetails(item.countermanId, item.countermanName[0])"
            >
              {{ t("Seat", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              class="btn btn-secondary text-light m-1"
              @click="extraDetails(item.countermanId, item.countermanName[0])"
            >
              {{ t("Extra", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              class="btn btn-success m-1"
              @click="goodsDetails(item.countermanId, item.countermanName[0])"
            >
              {{ t("Goods", {}, { locale: $store.getters.getLang }) }}
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ seatAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ extraTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ extraAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ goodsTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ goodsAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>

  <GoodDetails
    v-if="goodsDetailsActive"
    :countermanId="countermanIdPass"
    :countermanName="countermanNamePass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="counterWiseReport.discount"
    :status="parseInt(counterWiseReport.status)"
    v-on:backToCounterWiseReport="onChildClick"
  />
  <SeatDetails
    v-if="seatDetailsActive"
    :status="parseInt(counterWiseReport.status)"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="counterWiseReport.discount"
    :countermanId="countermanIdPass"
    :countermanName="countermanNamePass"
    v-on:backToCounterWiseReport="onChildClick"
  />
  <ExtraDetails
    v-if="extraDetailsActive"
    :status="parseInt(counterWiseReport.status)"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="counterWiseReport.discount"
    :countermanId="countermanIdPass"
    :countermanName="countermanNamePass"
    v-on:backToCounterWiseReport="onChildClick"
  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import GoodDetails from "./GoodDetails.vue";
import ExtraDetails from "./ExtraDetails.vue";
import SeatDetails from "./SeatDetails.vue";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "ShipReport",
  components: {
    DropdownFilter,
    SeatDetails,
    ExtraDetails,
    GoodDetails,
    CheckboxSingle,
  },
  emits: ["backToCounterWiseReport"],

  setup() {
    let extraDetailsActive = ref(false);
    let goodsDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let countermanIdPass = ref("");
    let countermanNamePass = ref("");
    let filterActive = ref(false);

    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let extraTicketTotal = ref(0);
    let extraAmountTotal = ref(0);
    let goodsTicketTotal = ref(0);
    let goodsAmountTotal = ref(0);
    let isDiscount = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    let summary = ref(false);
    const { t, locale } = useI18n();
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let counterWiseReport = reactive({
      companyId: "",
      counterId: "",
      fromDate: "",
      toDate: "",
      discount: "",
      status: 1,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (counterWiseReport.discount = 1)
          : (counterWiseReport.discount = "");
      }
    );

    watch(
      () => counterWiseReport.companyId,
      (value) => {
        counterWiseReport.companyId = value;
      }
    );
    watch(
      () => counterWiseReport.counterId,
      (value) => {
        counterWiseReport.counterId = value;
      }
    );

    watch(
      () => counterWiseReport.status,
      (value) => {
        counterWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        counterWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        counterWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    watch(
      () => counterWiseReport.companyId,
      () => {
        if (counterWiseReport.companyId !== "") {
          getAllCounter();
        }
      }
    );
    const getAllCounter = () => {
      showLoader();
      store
        .dispatch("getCounterListForFilterOwnerPanel", {
          companyId: counterWiseReport.companyId,
        })
        .then(() => hideLoader())
        .catch(() => hideLoader());
    };
    const getAllCompanyList = () => {
      counterWiseReport.companyId = store.getters.userCompany;
    };
    const getAllCounterWiseReport = () => {
      showLoader();

      store
        .dispatch("getfilterCounterWiseReportOwnerPanel", counterWiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            seatTicketTotal.value = 0;
            seatAmountTotal.value = 0;
            extraTicketTotal.value = 0;
            extraAmountTotal.value = 0;
            goodsTicketTotal.value = 0;
            goodsAmountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCounterWiseReport();
          }
        });
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllCounterWiseReport();
    };

    const sumListData = () => {
      store.getters.getCounterWiseReportOwner.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;

        extraTicketTotal.value += data.extraTicket;
        extraAmountTotal.value += data.extraAmount;

        goodsTicketTotal.value += data.goodsTicket;
        goodsAmountTotal.value += data.goodsAmount;
      });
    };

    onBeforeMount(() => {
      getAllCompanyList();
      counterWiseReport.fromDate = fromDate;
      counterWiseReport.toDate = toDate;
    });

    const extraDetails = (countermanId, countermanName) => {
      countermanNamePass.value = countermanName;
      countermanIdPass.value = countermanId;
      extraDetailsActive.value = true;
      goodsDetailsActive.value = false;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const goodsDetails = (countermanId, countermanName) => {
      countermanNamePass.value = countermanName;
      countermanIdPass.value = countermanId;
      extraDetailsActive.value = false;
      goodsDetailsActive.value = true;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const seatDetails = (countermanId, countermanName) => {
      countermanNamePass.value = countermanName;
      countermanIdPass.value = countermanId;
      extraDetailsActive.value = false;
      goodsDetailsActive.value = false;
      seatDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      extraDetailsActive.value = !value;
      goodsDetailsActive.value = !value;
      seatDetailsActive.value = !value;
    };

    return {
      t,
      locale,
      dropdownVariables,
      date,
      counterWiseReport,
      filterReport,
      extraDetails,
      goodsDetails,
      seatDetails,
      extraDetailsActive,
      seatDetailsActive,
      goodsDetailsActive,
      reportActive,
      countermanIdPass,
      filterActive,
      onChildClick,
      countermanNamePass,
      goodsTicketTotal,
      seatAmountTotal,
      goodsAmountTotal,
      extraTicketTotal,
      extraAmountTotal,
      seatTicketTotal,
      exportExcel,
      summary,
      options,
      getAllCompanyList,
      isDiscount,
    };
  },
};
</script>

<style scoped></style>
