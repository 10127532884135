<template>
  <FilterTab>
    <template v-slot:title
      ><h6>{{ title }}</h6></template
    >
    <template v-slot:body> </template>
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i
        ><span class="mx-1 bold"> Go Back </span>
      </button>
    </template>
  </FilterTab>
  <form style="padding: 0 50px">
    <div class="auth_form py-3">
      <div class="d-flex">
        <div style="width: 96px">BASIC INFO</div>
        <hr class="w-100" />
      </div>
      <div class="row my-2">
        <div class="col-md-3 mb-3">
          <Input
            v-model="companyData.name"
            v-bind:placeholder="'Enter Company Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          />
          <Error v-if="errors.name" v-bind:error="errors.name" />
        </div>
        <div class="col-md-3 mb-3">
          <Input
            v-model="companyData.phone"
            v-bind:placeholder="'Company Number'"
            v-bind:label="'Company Number'"
            v-bind:id="'companyNumber'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3 mb-3">
          <Input
            v-model="companyData.email"
            v-bind:placeholder="'Email'"
            v-bind:label="'Email'"
            v-bind:id="'email'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3 mb-3">
          <Input
            v-model="companyData.address"
            v-bind:placeholder="'Address'"
            v-bind:label="'Address'"
            v-bind:id="'address'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3 mb-3">
          <Input
            v-model="companyData.contactPerson"
            v-bind:placeholder="'Enter Contact Person'"
            v-bind:label="'Contact Person'"
            v-bind:id="'contactPerson'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3">
          <Input
            v-model="companyData.hotline"
            v-bind:placeholder="'Complain Number'"
            v-bind:label="'Complain Number'"
            v-bind:id="'complainNumber'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3">
          <Input
            v-model="companyData.fbLink"
            v-bind:placeholder="'Fb Link'"
            v-bind:label="'Fb Link'"
            v-bind:id="'fbLink'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3">
          <Input
            v-model="companyData.maxSeatPerTicket"
            v-bind:placeholder="'Enter Phone Number'"
            v-bind:label="'Max seat per ticket'"
            v-bind:id="'maxSeatPerTicket'"
            v-bind:type="'number'"
          />
          <Error
            v-if="errors.maxSeatPerTicket"
            v-bind:error="errors.maxSeatPerTicket"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.printerType"
            v-bind:label="'Print Type'"
            v-bind:id="'printType'"
            v-bind:dropdown="printerType"
            v-bind:hideLabel="'inactive'"
            v-bind:defaultOption="'Select PrintType'"
            v-bind:defaultValue="''"
          />
          <Error v-if="errors.printerType" v-bind:error="errors.printerType" />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.isMultiplePrintAllowed"
            v-bind:label="'Multiple Print'"
            v-bind:id="'multipleprint'"
            v-bind:dropdown="boolean"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.fareType"
            v-bind:label="'Fare Type'"
            v-bind:id="'fareType'"
            v-bind:dropdown="fareType"
            v-bind:hideLabel="'inactive'"
            v-bind:defaultOption="'Select FareType'"
            v-bind:defaultValue="''"
          />
          <Error v-if="errors.fareType" v-bind:error="errors.fareType" />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowNetPayEdit"
            v-bind:label="'AllowNet PayEdit'"
            v-bind:id="'allowNetPayEdit'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-if="status"
            v-model="companyData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div
            class="col-md-3"
        >
          <p class="mb-1">Ticket Layouts</p>
          <Multiselect
              id="multiselectId"
              v-model="companyData.ticketLayoutIds"
              :createTag="true"
              :options="$store.getters.getTicketLayoutsInFilter"
              :searchable="true"
              class="col font_detail w-100"
              mode="tags"
              placeholder="Select Route Sequence"
              label="'st stoppages"
          />
          <!--          <Error-->
          <!--              v-if="errors.dtStoppages"-->
          <!--              v-bind:error="errors.dtStoppages"-->
          <!--          />-->
        </div>
        <div class="col-md-3">
          <Input
              v-model="companyData.ticketHoldTime"
              v-bind:placeholder="'Enter Holding Time'"
              v-bind:label="'Ticket Holding Time (Min)'"
              v-bind:id="'holdingTime'"
              v-bind:type="'number'"
          />
          <Error
              v-if="errors.ticketHoldTime"
              v-bind:error="errors.ticketHoldTime"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.isQrEnable"
            v-bind:label="'QR Payment'"
            v-bind:id="'qrAccess'"
            v-bind:dropdown="qrAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Input
              v-model="companyData.qrCommissionValidate"
              v-bind:placeholder="'Enter QR Commission Validate'"
              v-bind:label="'QR Commission Validate (Hour)'"
              v-bind:id="'qrCommissionValidate'"
              v-bind:type="'number'"
          />
          <Error
              v-if="errors.qrCommissionValidate"
              v-bind:error="errors.qrCommissionValidate"
          />
        </div>
        <div class="col-md-6">
           <TextArea
               v-bind:id="'ticketCancel'"
               v-model="companyData.ticketJson"
               v-bind:placeholder="'Enter JSON'"
               v-bind:type="'text'"
               :label="'Ticket JSON'"
           >
            </TextArea>
          <Error v-if="errors.ticketJson" v-bind:error="errors.ticketJson" />

        </div>
      </div>
      <div class="d-flex mt-5">
        <div style="width: 84px">TRIP INFO</div>
        <hr class="w-100" />
      </div>
      <div class="row">
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowPass"
            v-bind:label="'Allow Pass'"
            v-bind:id="'allowPass'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowDiscount"
            v-bind:label="'Allow Discount'"
            v-bind:id="'allowDiscount'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>

        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowFarewiseReport"
            v-bind:label="'Allow Farewise Report'"
            v-bind:id="'allowFarewiseReport'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowCoachDetails"
            v-bind:label="'Allow Coach Details'"
            v-bind:id="'allowCoachDetails'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.isReferCounterAllowed"
            v-bind:label="'Allow Refer Counter'"
            v-bind:id="'allowReferCounter'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowCoachwiseTrip"
            v-bind:label="'Allow Coachwise Trip'"
            v-bind:id="'allowCoachwiseTrip'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.reservationStatus"
            v-bind:label="'Reservation Status'"
            v-bind:id="'Reservation Status'"
            v-bind:dropdown="reservationStatus"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div
          v-if="parseInt(companyData.reservationStatus) === 1"
          class="col-md-3"
        >
          <Input
            v-model="companyData.reservationCancelTime"
            v-bind:placeholder="'Reservation Cancel Time'"
            v-bind:label="'Reservation Cancel Time(Min.)'"
            v-bind:id="'reservationCancelTime'"
            v-bind:type="'number'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowQuota"
            v-bind:id="'allowquota'"
            v-bind:label="'Allow Quota'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowDigitalTicketing"
            v-bind:id="'allowdt'"
            v-bind:label="'Allow Digital Ticketing'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>

        <div
          class="col-md-3"
          v-if="parseInt(companyData.allowDigitalTicketing) === 1"
        >
          <p class="mb-1">DT Cities</p>
          <Multiselect
            id="multiselectId"
            v-model="companyData.dtStoppages"
            :createTag="true"
            :options="$store.getters.getDigitalTicketingAllStoppages"
            :searchable="true"
            class="col font_detail w-100"
            mode="tags"
            placeholder="Select Route Sequence"
            label="'st stoppages"
          />
          <!--          <Error-->
          <!--              v-if="errors.dtStoppages"-->
          <!--              v-bind:error="errors.dtStoppages"-->
          <!--          />-->
        </div>
        <div
            class="col-md-3"
            v-if="parseInt(companyData.allowDigitalTicketing) === 1"
        >
          <Input
              v-model="companyData.dtSeatAutoClearTime"
              v-bind:placeholder="'DT Seat Clear Time'"
              v-bind:label="'DT Seat Clear Time(Min.)'"
              v-bind:id="'dtseatautocleartime'"
              v-bind:type="'number'"
          />
        </div>
      </div>
      <div class="d-flex mt-5">
        <div style="width: 122px">SMS SERVICES</div>
        <hr class="w-100" />
      </div>
      <div class="row">
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.isPhoneNumberRequired"
            v-bind:id="'smsPhoneRequired'"
            v-bind:label="'Required Phone'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowSms"
            v-bind:label="'Allow Sms'"
            v-bind:id="'allowSms'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div v-if="companyData.allowSms != 0" class="col-md-3">
          <Input
            v-model="companyData.smsUser"
            v-bind:placeholder="'Sms User'"
            v-bind:label="'Sms User'"
            v-bind:id="'smsuser'"
            v-bind:type="'text'"
          />
        </div>
        <div v-if="companyData.allowSms != 0" class="col-md-3">
          <Input
            v-model="companyData.smsApiKey"
            v-bind:placeholder="'xxx...'"
            v-bind:label="'Sms API Key'"
            v-bind:id="'smsapikey'"
            v-bind:type="'text'"
          />
        </div>
        <div v-if="companyData.allowSms != 0" class="col-md-3">
          <Input
            v-model="companyData.smsSenderId"
            v-bind:placeholder="'xxx...'"
            v-bind:label="'Sms Sender Id'"
            v-bind:id="'smdsenderid'"
            v-bind:type="'text'"
          />
        </div>
      </div>
      <div v-if="companyData.allowSms != 0" class="row mt-5">
        <div class="col-md-4 mb-2">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between bg_corporate"
            >
              <CheckboxCommon
                v-model="companyData.smsForSoldSeats.status"
                label="Sold seats"
                id="soldseats"
              />
              <CheckboxCommon
                v-model="companyData.smsForSoldSeats.unicode"
                label="Unicode"
                id="soldseatsunicode"
              />
            </div>
            <div v-if="companyData.smsForSoldSeats.status" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <TextArea
                    v-model="companyData.smsForSoldSeats.format"
                    v-bind:placeholder="`Welcome to <company name>, <coach>... `"
                    v-bind:label="'Sms Format'"
                    v-bind:id="'Cancel'"
                    v-bind:type="'text'"
                  >
                  </TextArea>
                </div>
                <div class="col-md-12">
                  <div>Example:</div>
                  <div class="text-muted">
                    Dear
                    <span class="fw-bold">
                      {{ angleBracket }}passengerName></span
                    >, your ticket is successfully confirmed with
                    <span class="fw-bold"> {{ angleBracket }}company></span>
                    from
                    <span class="fw-bold"> {{ angleBracket }}boarding></span>,
                    <span class="fw-bold"> {{ angleBracket }}from></span> to
                    <span class="fw-bold"> {{ angleBracket }}to></span>. PNR:
                    <span class="fw-bold"> {{ angleBracket }}pnr></span>, Coach:
                    <span class="fw-bold"> {{ angleBracket }}coach></span>,
                    seats:
                    <span class="fw-bold"> {{ angleBracket }}seats></span>,
                    fare: <span class="fw-bold"> {{ angleBracket }}fare></span>.
                    Your departure is at
                    <span class="fw-bold">
                      {{ angleBracket }}departureDateTime></span
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between bg_corporate"
            >
              <CheckboxCommon
                v-model="companyData.smsForReservationSeats.status"
                label="Reservation seats"
                id="reservationSeats"
              />
              <CheckboxCommon
                v-model="companyData.smsForReservationSeats.unicode"
                label="Unicode"
                id="reservationSeatsUnicode"
              />
            </div>
            <div
              v-if="companyData.smsForReservationSeats.status"
              class="card-body"
            >
              <div class="row">
                <div class="col-md-12">
                  <TextArea
                    v-model="companyData.smsForReservationSeats.format"
                    v-bind:placeholder="`Welcome to <company name>, <coach>... `"
                    v-bind:label="'Sms Format'"
                    v-bind:id="'Cancel'"
                    v-bind:type="'text'"
                  >
                  </TextArea>
                </div>
                <div class="col-md-12">
                  <div>Example:</div>
                  <div class="text-muted">
                    Dear
                    <span class="fw-bold">
                      {{ angleBracket }}passengerName></span
                    >, your ticket is successfully reserved with
                    <span class="fw-bold"> {{ angleBracket }}company></span>
                    from
                    <span class="fw-bold"> {{ angleBracket }}boarding></span>,
                    <span class="fw-bold"> {{ angleBracket }}from></span> to
                    <span class="fw-bold"> {{ angleBracket }}to></span>. PNR:
                    <span class="fw-bold"> {{ angleBracket }}pnr></span>, Coach:
                    <span class="fw-bold"> {{ angleBracket }}coach></span>,
                    seats:
                    <span class="fw-bold"> {{ angleBracket }}seats></span>,
                    fare: <span class="fw-bold"> {{ angleBracket }}fare></span>.
                    Your departure is at
                    <span class="fw-bold">
                      {{ angleBracket }}departureDateTime></span
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between bg_corporate"
            >
              <CheckboxCommon
                v-model="companyData.smsForTicketCancel.status"
                label="Ticket Cancel"
                id="ticketCancel"
              />
              <CheckboxCommon
                v-model="companyData.smsForTicketCancel.unicode"
                label="Unicode"
                id="ticketCancelUnicode"
              />
            </div>
            <div v-if="companyData.smsForTicketCancel.status" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <TextArea
                    v-model="companyData.smsForTicketCancel.format"
                    v-bind:placeholder="`Welcome to <company name>, <coach>... `"
                    v-bind:label="'Sms Format'"
                    v-bind:id="'ticketCancelFormat'"
                    v-bind:type="'text'"
                  >
                  </TextArea>
                </div>
                <div class="col-md-12">
                  <div>Example:</div>
                  <div class="text-muted">
                    Dear
                    <span class="fw-bold">
                      {{ angleBracket }}passengerName></span
                    >, your ticket is successfully cancelled with
                    <span class="fw-bold"> {{ angleBracket }}company></span>
                    from
                    <span class="fw-bold"> {{ angleBracket }}boarding></span>,
                    <span class="fw-bold"> {{ angleBracket }}from></span> to
                    <span class="fw-bold"> {{ angleBracket }}to></span>. PNR:
                    <span class="fw-bold"> {{ angleBracket }}pnr></span>, Coach:
                    <span class="fw-bold"> {{ angleBracket }}coach></span>,
                    seats:
                    <span class="fw-bold"> {{ angleBracket }}seats></span>,
                    fare: <span class="fw-bold"> {{ angleBracket }}fare></span>.
                    Your departure is at
                    <span class="fw-bold">
                      {{ angleBracket }}departureDateTime></span
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between bg_corporate"
            >
              <CheckboxCommon
                v-model="companyData.smsForTripCancel.status"
                label="Trip Cancel"
                id="tripCancel"
              />
              <CheckboxCommon
                v-model="companyData.smsForTripCancel.unicode"
                label="Unicode"
                id="tripCancelUnicode"
              />
            </div>
            <div v-if="companyData.smsForTripCancel.status" class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <TextArea
                    v-model="companyData.smsForTripCancel.format"
                    v-bind:placeholder="`Welcome to <company name>, <coach>... `"
                    v-bind:label="'Sms Format'"
                    v-bind:id="'smsForTripCancelFormat'"
                    v-bind:type="'text'"
                  >
                  </TextArea>
                </div>
                <div class="col-md-12">
                  <div>Example:</div>
                  <div class="text-muted">
                    A trip is cancelled with
                    <span class="fw-bold"> {{ angleBracket }}company></span>,
                    <span class="fw-bold"> {{ angleBracket }}from></span> to
                    <span class="fw-bold"> {{ angleBracket }}to></span>. Trip
                    Code:
                    <span class="fw-bold"> {{ angleBracket }}tripCode></span>,
                    trip Date time:
                    <span class="fw-bold"> {{ angleBracket }}tripDateTime></span
                    >, Coach:
                    <span class="fw-bold"> {{ angleBracket }}coach></span>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="card">
            <div
              class="card-header d-flex justify-content-between bg_corporate"
            >
              <CheckboxCommon
                v-model="companyData.smsForReservationCancel.status"
                label="Reservation Cancel"
                id="ReservationCancel"
              />
              <CheckboxCommon
                v-model="companyData.smsForReservationCancel.unicode"
                label="Unicode"
                id="ReservationCancelUnicode"
              />
            </div>
            <div
              v-if="companyData.smsForReservationCancel.status"
              class="card-body"
            >
              <div class="row">
                <div class="col-md-12">
                  <TextArea
                    v-model="companyData.smsForReservationCancel.format"
                    v-bind:placeholder="`Welcome to <company name>, <coach>... `"
                    v-bind:label="'Sms Format'"
                    v-bind:id="'smsForReservationCancelFormat'"
                    v-bind:type="'text'"
                  >
                  </TextArea>
                </div>
                <div class="col-md-12">
                  <div>Example:</div>
                  <div class="text-muted">
                    Dear
                    <span class="fw-bold">
                      {{ angleBracket }}passengerName></span
                    >, your reserved ticket is cancelled with
                    <span class="fw-bold"> {{ angleBracket }}company></span>
                    from
                    <span class="fw-bold"> {{ angleBracket }}boarding></span>,
                    <span class="fw-bold"> {{ angleBracket }}from></span> to
                    <span class="fw-bold"> {{ angleBracket }}to></span>. PNR:
                    <span class="fw-bold"> {{ angleBracket }}pnr></span>, Coach:
                    <span class="fw-bold"> {{ angleBracket }}coach></span>,
                    seats:
                    <span class="fw-bold"> {{ angleBracket }}seats></span>,
                    fare: <span class="fw-bold"> {{ angleBracket }}fare></span>.
                    Your departure is at
                    <span class="fw-bold">
                      {{ angleBracket }}departureDateTime></span
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex mt-5">
        <div style="width: 116px">OTHERS INFO</div>
        <hr class="w-100" />
      </div>
      <div class="row">
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowDue"
            v-bind:label="'Allow Due'"
            v-bind:id="'allowDue'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowRefund"
            v-bind:label="'Allow Refund'"
            v-bind:id="'allowRefund'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Dropdown
            v-model="companyData.allowPandemicFares"
            v-bind:label="'Allow Pandemic Fares'"
            v-bind:id="'allowPandemicFares'"
            v-bind:dropdown="editAccess"
            v-bind:hideLabel="'inactive'"
          />
        </div>
        <div class="col-md-3">
          <Input
            v-model.number="companyData.allowGoodsWeight"
            v-bind:placeholder="'Allowable Goods Weight'"
            v-bind:label="'Allowable Goods Weight'"
            v-bind:id="'allowableGoodsWeight'"
            v-bind:type="'text'"
          />
        </div>
        <div class="col-md-3">
          <Input
            v-model.number="companyData.overweightGoodChargePerKg"
            v-bind:placeholder="'Over weight GoodCharge PerKg'"
            v-bind:label="'Over weight GoodCharge PerKg'"
            v-bind:id="'overweightGoodChargePerKg'"
            v-bind:type="'text'"
          />
        </div>
      </div> -->
    </div>
    <div class="auth_form d-flex justify-content-center">
      <input
        v-if="!companyId"
        type="submit"
        class="py-2 mb-5 submit_button width-300"
        @click.prevent="addCompany"
      />
      <input
        v-else
        type="submit"
        class="py-2 mb-5 submit_button width-300"
        @click.prevent="updateCompany"
      />
    </div>
  </form>
</template>

<script>
import CheckboxCommon from "@/components/layouts/Inputs/CheckboxCommon.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { inject, onBeforeMount, ref, watch } from "vue";

export default {
  name: "CompanyAddUpdate",
  components: {
    CheckboxCommon,
    Multiselect,
  },
  props: {
    modalValue: Boolean,
    company: String,
  },
  setup(props, { emit }) {
    const angleBracket = "<";
    let companyData = reactive({
      id: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      contactPerson: "",
      logo: "",
      printerType: "",
      maxSeatPerTicket: 1,
      fareType: "",
      allowPass: "0",
      allowCoachDetails: "1",
      allowFarewiseReport: "0",
      allowCoachwiseTrip: "0",
      allowRefund: "0",
      allowDue: "0",
      allowSms: "0",
      smsUser: "",
      allowDiscount: "0",
      allowNetPayEdit: "0",
      allowPandemicFares: "0",
      allowGoodsWeight: 0,
      overweightGoodChargePerKg: 0,
      allowQuota: "0",
      allowDigitalTicketing: "0",
      reservationCancelTime: 0,
      hotline: "",
      fbLink: "",
      status: "1",
      reservationStatus: "0",
      smsForSoldSeats: {
        status: false,
        unicode: false,
        format: "",
      },
      smsForReservationSeats: {
        status: false,
        unicode: false,
        format: "",
      },
      smsForTicketCancel: {
        status: false,
        unicode: false,
        format: "",
      },
      smsForTripCancel: {
        status: false,
        unicode: false,
        format: "",
      },
      smsForReservationCancel: {
        status: false,
        unicode: false,
        format: "",
      },
      isPhoneNumberRequired: 0,
      isReferCounterAllowed: 0,
      smsApiKey: "",
      smsSenderId: "",
      isMultiplePrintAllowed: 0,
      dtStoppages: [],
      ticketLayoutIds: [],
      ticketJson: "",
      dtSeatAutoClearTime: 0,
      ticketHoldTime: 1,
      isQrEnable: false,
      qrCommissionValidate: 0,
    });
    let companyId = ref(props.company);
    let { requiredValidation, jsonValidation, validNumberWithZeroValidation, errors } = useInputValidation();
    const title = ref("Add Company");
    const boolean = dropdownVariables.boolean;
    // const reportByIndividualSeat = dropdownVariables.reportByIndividualSeat;
    // const individualTicketPrint = dropdownVariables.individualTicketPrint;
    const status = dropdownVariables.status;
    const reservationStatus = dropdownVariables.status;
    const printerType = dropdownVariables.printerType;
    const editAccess = dropdownVariables.editAccess;
    const fareType = dropdownVariables.fareType;
    const qrAccess = dropdownVariables.qrAccess;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const isDiscount = ref(false);

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
      () => companyData.reservationStatus,
      (value) => {
        if (value === "0") {
          companyData.reservationCancelTime = 0;
        }
      }
    );

    watch(
      () => companyData.name,
      () => {
        requiredValidation("name", companyData.name);
      }
    );
    watch(
      () => companyData.maxSeatPerTicket,
      () => {
        requiredValidation(
          "maxSeatPerTicket",
          companyData.maxSeatPerTicket.toString()
        );
      }
    );
    watch(
      () => companyData.printerType,
      () => {
        requiredValidation("printerType", companyData.printerType);
      }
    );

    watch(
      () => companyData.allowSms,
      (v) => {
        if (v == 0) {
          clearSmsServices();
        }
      }
    );

    watch(
      () => companyData.smsForSoldSeats.status,
      (value) => {
        if (!value) {
          companyData.smsForSoldSeats.unicode = false;
        }
      }
    );

    watch(
      () => companyData.smsForReservationSeats.status,
      (value) => {
        if (!value) {
          companyData.smsForReservationSeats.unicode = false;
        }
      }
    );

    watch(
      () => companyData.smsForTicketCancel.status,
      (value) => {
        if (!value) {
          companyData.smsForTicketCancel.unicode = false;
        }
      }
    );

    watch(
      () => companyData.smsForTripCancel.status,
      (value) => {
        if (!value) {
          companyData.smsForTripCancel.unicode = false;
        }
      }
    );

    watch(
      () => companyData.smsForReservationCancel.status,
      (value) => {
        if (!value) {
          companyData.smsForReservationCancel.unicode = false;
        }
      }
    );

    watch(() => companyData.ticketJson, (value) => {
      jsonValidation('ticketJson', value)
    })

    // watch(
    //     () => companyData.dtStoppages,
    //     () => {
    //       requiredValidation("dtStoppages", companyData.dtStoppages[0]);
    //     }
    // );
    // watch(
    //     () => companyData.allowDigitalTicketing,
    //     () => {
    //       if(parseInt(companyData.allowDigitalTicketing) === 0){
    //        errors.dtStoppages = ''
    //       }
    //     }
    // );


    const clearSmsServices = () => {
      companyData.smsForSoldSeats = {
        status: false,
        unicode: false,
        format: "",
      };
      companyData.smsForReservationSeats = {
        status: false,
        unicode: false,
        format: "",
      };
      companyData.smsForTicketCancel = {
        status: false,
        unicode: false,
        format: "",
      };
      companyData.smsForTripCancel = {
        status: false,
        unicode: false,
        format: "",
      };
      companyData.smsForReservationCancel = {
        status: false,
        unicode: false,
        format: "",
      };
    };
    const goBack = () => {
      store.commit("setButtonStatus", true);
      emit("close");
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", companyData.name);
      requiredValidation("printerType", companyData.printerType);
      requiredValidation("fareType", companyData.fareType);
      requiredValidation(
        "maxSeatPerTicket",
        companyData.maxSeatPerTicket.toString()
      );
      jsonValidation('ticketJson', companyData.ticketJson);
      requiredValidation(
          "ticketHoldTime",
          companyData.ticketHoldTime.toString()
      );
      validNumberWithZeroValidation("ticketHoldTime",  companyData.ticketHoldTime);
      validNumberWithZeroValidation("qrCommissionValidate",  companyData.qrCommissionValidate);

      //companyData.isQrEnable = (companyData.isQrEnable === 'true')

      // if(parseInt(companyData.allowDigitalTicketing) === 1){
      //   requiredValidation(
      //       "dtStoppages",
      //       companyData.dtStoppages[0]
      //   );
      // }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addCompany = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addCompany", { companyData, pagination })
          .then(() => {
            emit("getAllCompanies");
            hideLoader();
            goBack();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addCompany();
            }
          });
      }
    };
    const updateCompany = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateCompany", { companyData, pagination })
          .then(() => {
            emit("getAllCompanies");
            hideLoader();
            goBack();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateCompany();
            }
          });
      }
    };
    const getDtStoppageList = () => {
      store
        .dispatch("getDigitalTicketingAllStoppages", {
          companyId: companyId.value,
        })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getDtStoppageList();
          }
        });
    };

    const getAllTicketLayout = () => {
      store
          .dispatch("getAllTicketLayout", )
          .then(() => {
          })
          .catch((error) => {
            if (error.status === 401) {
              getAllTicketLayout();
            }
          });
    }

    onBeforeMount(() => {
      getDtStoppageList();
      getAllTicketLayout();
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (companyId.value !== "") {
        const companyDetails = store.getters.getCompany(companyId.value);
        title.value = "Update Company";
        companyData.id = companyDetails[0]._id;
        companyData.name = companyDetails[0].name;
        companyData.phone = companyDetails[0].phone;
        companyData.email = companyDetails[0].email;
        companyData.address = companyDetails[0].address;
        companyData.contactPerson = companyDetails[0].contactPerson;
        companyData.logo = companyDetails[0].logo;
        companyData.printerType = companyDetails[0].printerType;
        companyData.maxSeatPerTicket = companyDetails[0].maxSeatPerTicket;
        companyData.fareType = companyDetails[0].fareType;
        companyData.allowPass = companyDetails[0].allowPass ? 1 : 0;
        companyData.allowCoachDetails = companyDetails[0].allowCoachDetails
          ? 1
          : 0;
        companyData.allowFarewiseReport = companyDetails[0].allowFarewiseReport
          ? 1
          : 0;
        companyData.allowCoachwiseTrip = companyDetails[0].allowCoachwiseTrip
          ? 1
          : 0;
        companyData.allowRefund = companyDetails[0].allowRefund ? 1 : 0;
        companyData.allowDue = companyDetails[0].allowDue ? 1 : 0;
        companyData.allowSms = companyDetails[0].allowSms ? 1 : 0;
        companyData.smsUser = companyDetails[0].smsUser;
        companyData.reservationCancelTime =
          companyDetails[0].reservationCancelTime;
        companyData.allowDiscount = companyDetails[0].allowDiscount ? 1 : 0;
        companyData.allowNetPayEdit = companyDetails[0].allowNetPayEdit ? 1 : 0;
        companyData.allowPandemicFares = companyDetails[0].allowPandemicFares
          ? 1
          : 0;
        companyData.allowGoodsWeight = companyDetails[0].allowGoodsWeight;
        companyData.overweightGoodChargePerKg =
          companyDetails[0].overweightGoodChargePerKg;
        companyData.allowQuota = companyDetails[0].allowQuota ? 1 : 0;
        companyData.allowDigitalTicketing = companyDetails[0]
          .allowDigitalTicketing
          ? 1
          : 0;

        companyData.hotline = companyDetails[0].hotline;
        companyData.fbLink = companyDetails[0].fbLink;
        companyData.status = companyDetails[0].status ? 1 : 0;
        companyData.reservationStatus = companyDetails[0].reservationStatus
          ? 1
          : 0;
        companyData.isPhoneNumberRequired = companyDetails[0]
          .isPhoneNumberRequired
          ? 1
          : 0;
        companyData.isReferCounterAllowed = companyDetails[0]
          .isReferCounterAllowed
          ? 1
          : 0;
        companyData.smsForSoldSeats = {
          ...companyDetails[0].smsForSoldSeats,
          status: companyDetails[0].smsForSoldSeats?.status,
          unicode: companyDetails[0].smsForSoldSeats?.unicode,
        };
        companyData.smsForReservationSeats = {
          ...companyDetails[0].smsForReservationSeats,
          status: companyDetails[0].smsForReservationSeats?.status,
          unicode: companyDetails[0].smsForReservationSeats?.unicode,
        };
        companyData.smsForTicketCancel = {
          ...companyDetails[0].smsForTicketCancel,
          status: companyDetails[0].smsForTicketCancel?.status,
          unicode: companyDetails[0].smsForTicketCancel?.unicode,
        };
        companyData.smsForTripCancel = {
          ...companyDetails[0].smsForTripCancel,
          status: companyDetails[0].smsForTripCancel?.status,
          unicode: companyDetails[0].smsForTripCancel?.unicode,
        };
        companyData.smsForReservationCancel = {
          ...companyDetails[0].smsForReservationCancel,
          status: companyDetails[0].smsForReservationCancel?.status,
          unicode: companyDetails[0].smsForReservationCancel?.unicode,
        };
        companyData.smsApiKey = companyDetails[0].smsApiKey;
        companyData.smsSenderId = companyDetails[0].smsSenderId;
        companyData.dtStoppages = companyDetails[0].dtStoppages;
        companyData.isMultiplePrintAllowed = companyDetails[0]
          .isMultiplePrintAllowed
          ? 1
          : 0;
        companyData.ticketLayoutIds = companyDetails[0].ticketLayoutIds;
        companyData.ticketJson = companyDetails[0].ticketJson;
        companyData.dtSeatAutoClearTime = companyDetails[0].dtSeatAutoClearTime;
        companyData.ticketHoldTime = companyDetails[0].ticketHoldTime >= 0 ? companyDetails[0].ticketHoldTime : '';
        companyData.isQrEnable = companyDetails[0].isQrEnable || false;
        companyData.qrCommissionValidate = companyDetails[0]?.qrCommissionValidate || 0;
      }
    });

    return {
      companyData,
      status,
      title,
      errors,
      companyId,
      boolean,
      printerType,
      editAccess,
      fareType,
      addCompany,
      updateCompany,
      reservationStatus,
      goBack,
      isDiscount,
      angleBracket,
      qrAccess
    };
  },
};
</script>

<style scoped>
.width-300 {
  width: 300px;
}
</style>
