<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-md-12 mb-3">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">
            {{ t("Seat", {}, { locale: $store.getters.getLang }) }}
          </h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-sm-center justify-content-lg-around align-items-md-center align-items-lg-stretch flex-wrap flex-md-nowrap">
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.totalSeatTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Total Tickets", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.totalSeatAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.cashTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Cash Tickets", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.cashTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Cash Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.qrTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("QR Tickets", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.qrTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("QR Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.dtTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("DT Tickets", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.dtTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("DT Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">
            {{ t("Goods", {}, { locale: $store.getters.getLang }) }}
          </h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-around align-items-stretch">
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.goodsTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("TICKET", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.goodsAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">
            {{ t("Extra_Seat", {}, { locale: $store.getters.getLang }) }}
          </h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-around align-items-stretch">
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTicketsOwner[0]?.extraTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("TICKET", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTicketsOwner[0]?.extraAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>
                    {{ t("Amount", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "DashboardReports",

  setup() {
    const { t, locale } = useI18n();

    return {
      t,
      locale,
    };
  },
};
</script>

<style scoped>
.dashedBorder {
  border-right: 3px dashed #cf200d21;
}
@media (max-width: 767px) {
  .dashedBorder {display: none}
}
.background {
  background-color: #f04835d8;
}
.backgroundBody {
  background-color: #eee;
}
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>
