<template>
  <div>
    <div v-if="addUpdate">
      <!-- <TicketPurchaseModal v-model="addUpdate" v-bind:seatInfo="seatsDetails" @refreshPage="refreshPage"/>
      <TicketPurchase
        v-model="addUpdate"
        v-bind:seatInfo="seatsDetails"
        @refreshPageGoBack="refreshPageGoBack"
        :passPagination="propsPaginationData"
      /> -->
    </div>
    <div v-else-if="printPreview">
      <TicketPrintPreview
        v-if="
          ticketTypeForPrint === 'lastTicket' ||
          ticketTypeForPrint === 'extraTicket' ||
          ticketTypeForPrint === 'goodsTicket' ||
          ticketTypeForPrint === 'pnrTicket' ||
          ticketTypeForPrint === 'ticket'
        "
        v-bind:ticket="lastTicketDetails"
        v-bind:ticketType="ticketTypeForPrint"
        v-bind:panel="$store.getters.userType"
        @close="closePrintPreview"
      />
      <AgentTripReportPrintPreview
        v-if="ticketTypeForPrint === 'tripReport'"
        v-bind:trip="userTripReport"
        v-bind:ticketType="ticketTypeForPrint"
        :printCount="printCount"
        :tripId="trip"
        :detailsTrip="propTripDetails"
        @close="closePrintPreview"
      />
    </div>
    <div
      v-else
      class="card card-body py-4"
      style="background-color: whitesmoke"
    >
      <div class="row">
        <div class="row m-0">
          <div
            class="col-sm-2 col-md-2 col-lg-1 col-xl-1 order-md-1 order-xl-1"
          >
            <span class="fw-bold">TICKET</span>
            <div class="card card-body">
              <div class="row">
                <div
                  class="col-sm-12 col-md-12 col-xl-12 mt-md-12 mt-xl-0 mt-xxl-2"
                >
                  <p class="text-center font_detail m-0">Sold</p>
                  <div>
                    <div>
                      <div
                        class="rounded-end sold_seats common_btn span_font w-100"
                      >
                        <span>{{ totalBookedSeatCount || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="col-sm-4 col-md-12 col-xl-12 mt-md-2 mt-xl-0 mt-xxl-2"
                >
                  <p class="text-center font_detail m-0">Available</p>
                  <div
                    class="
                      rounded-end
                      available_other available
                      span_font
                      w-100
                    "
                  >
                    {{ totalAvailableSeatCount || 0 }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div
            class="col-sm-4 col-md-4 col-lg-6 col-xl-6 order-md-4 order-xl-2"
          >
            <span class="fw-bold">SEAT INFORMATION</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-md-12">
                  <p class="text-center font_detail m-0">SEATS</p>
                  <div
                    class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text"
                      >#{{ seats.toString() || "" }}</span
                    >
                  </div>
                  <p class="text-center font_detail m-0">
                    Total Seats: {{ seats.length || 0 }}
                  </p>
                </div>
                <div class="col-sm-2 col-md-2">
                  <p class="text-center font_detail m-0">FARE(TK)</p>
                  <input
                    v-if="
                      $store.getters.userCompanyData.fareType ===
                        'predefined' ||
                      $store.getters.userCompanyData.fareType === 'trip_fare'
                    "
                    v-model="fare"
                    :disabled="!$store.getters.allowNetPayEdit"
                    type="Number"
                    class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <input
                    v-if="
                      $store.getters.userCompanyData.fareType === 'instant_fare'
                    "
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    v-model="inputFare"
                    type="Number"
                    class="input_field form-control rounded px-3 w-100 text-center"
                  />
                </div>
                <div
                  v-if="hasPermission(Agent.fareDiscount)"
                  class="col-sm-2 col-md-2"
                >
                  <p class="text-center font_detail m-0">DISCOUNT</p>
                  <input
                    v-model="seatsDetails.discount"
                    :disabled="!$store.getters.allowDiscount"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    type="Number"
                    class="input_field form-control rounded px-3 w-100 text-center"
                  />
                  <p class="text-danger" v-if="discountErrorMsg">
                    Discount amount can't be greater than netfare
                  </p>
                </div>

                <div class="col-sm-2 col-md-2">
                  <p class="text-center font_detail m-0">PAID(TK)</p>

                  <input
                    v-model="seatsDetails.paid"
                    :disabled="!$store.getters.allowDue"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    type="number"
                    class="input_field form-control rounded px-3 w-100 text-center"
                  />
                </div>
                <div class="col-sm-2 col-md-2">
                  <p class="text-center font_detail m-0">PAYABLE(TK)</p>
                  <div
                    class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text">{{
                      seatsDetails.payable || 0
                    }}</span>
                  </div>
                </div>
                <div class="col-sm-2 col-md-2">
                  <p class="text-center font_detail m-0">DUE(TK)</p>
                  <div
                    class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text">{{ calculateDue }}</span>
                  </div>
                </div>
                <div class="col-sm-2 col-md-2">
                  <p class="text-center font_detail m-0">REFUND(TK)</p>
                  <div
                    class="rounded-end available_other available span_font w-100"
                  >
                    <span class="orange-bold-text">{{ calculateRefund }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-2 col-md-2 col-lg-1 col-xl-1 order-md-2 order-xl-3"
          >
            <span class="fw-bold">TICKET</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <p class="text-center font_detail m-0">EXTRA</p>
                  <button
                    class="extra_ticket_button w-100"
                    @click="ticketExtra('SEATS', 'EXTRA')"
                  >
                    <i class="fas fa-money-check-alt"></i>
                  </button>
                  <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                </div>
                <div class="col-sm-12 col-md-12">
                  <p class="text-center font_detail m-0">GOODS</p>
                  <button
                    class="goods_ticket_button w-100"
                    @click="ticketExtra('GOODS', 'GOODS')"
                  >
                    <i class="fas fa-money-check-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-2 col-md-2 col-lg-2 col-xl-2 order-md-2 order-xl-3"
          >
            <span class="fw-bold">PRINT</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">TRIP REPORT</p>
                  <button class="trip_button w-100" @click="tripReport(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                  <!-- <p class="text-center font_detail m-0">SHIP NAME</p> -->
                </div>
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">LAST TICKET</p>
                  <button class="last_ticket w-100" @click="lastTicket(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">PNR TICKET</p>
                  <button class="pnr_ticket w-100" @click="pnrTicket">
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-2 col-md-2 col-lg-2 col-xl-2 order-md-3 order-xl-4"
          >
            <span class="fw-bold">ACTIONS</span>
            <div class="card card-body">
              <div class="row">
                <div
                  class="col-sm-6 col-md-6"
                  v-if="hasPermission(Agent.tripEdit)"
                >
                  <p class="text-center font_detail m-0">UPDATE TRIP</p>
                  <button class="update_trip_button w-100" @click="updateTrip">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div
                  class="col-sm-6 col-md-6"
                  v-if="
                    $store.getters.allowPass && hasPermission(Agent.tripPass)
                  "
                >
                  <p class="text-center font_detail m-0">TRIP PASS</p>
                  <button class="trip_pass_button w-100" @click="tripPass">
                    <i class="fas fa-location-arrow"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">RESET</p>
                  <button class="remove_button w-100" @click="reset">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="col-sm-6 col-md-6">
                  <p class="text-center font_detail m-0">REFRESH</p>
                  <button
                    class="refresh_button w-100"
                    @click="refreshPageGoBack()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeatList
          :resetAllData="resetAllData"
          :fromStoppage="fromStoppage"
          :toStoppage="toStoppage"
          :singleTripDetails="singleTripDetails"
          :discount="seatsDetails.discount"
          :inputFare="inputFare"
          :due="calculateDue"
          :refund="calculateRefund"
          :paid="seatsDetails.paid"
          @seatBookedAndFare="seatBookedAndFare"
          @tripSeatsRefresh="refreshPageGoBack"
        />
        <!-- <div v-if="seatPlans.length !== 0" style="margin: 5px 0">
          <div class="card card-body p-0 tab-content" id="myTabContent">
            <div
              v-for="(tab, index) in seatPlans"
              :key="index"
              class="tab-pane fade"
              v-bind:class="tab._id === seatPlan._id ? 'show active' : ''"
              :id="tab._id"
              role="tabpanel"
              :aria-labelledby="tab.name + '-tab'"
            ></div>
            <h1>Seat List</h1>
            <SeatList v-model="seatsDetails.seats" :seatPlan="seatPlan" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <TicketExtraBookingModal
    v-if="ticketExtraModal"
    v-model="ticketExtraModal"
    :fromStoppage="fromStoppage"
    :toStoppage="toStoppage"
    :singleTripDetails="singleTripDetails"
    :extraTicketModalName="extraTicketModalName"
    :extraTicketType="extraTicketType"
    @extraSeatPurchase="extraSeatPurchase"
    @extraGoodsPurchase="extraGoodsPurchase"
  />
  <TripPass v-if="tripPassModal" v-model="tripPassModal" :trip="trip" />
  <PnrTicket v-if="pnrModal" v-model="pnrModal" @pnrPrint="pnrPrint" />
  <UpdateTrip v-if="updateTripModal" v-model="updateTripModal" :tripId="trip" />
</template>

<script>
import { computed, inject, onBeforeMount, ref, watch } from "vue";
import SeatList from "./SeatList";
// import TicketPurchaseModal from "./TicketPurchaseModal";
// import TicketPurchase from "./TicketPurchase";
import AgentTripReportPrintPreview from "./AgentTripReportPrintPreview.vue";
import TripPass from "./TripPassModal.vue";
import PnrTicket from "./PnrModal.vue";
import UpdateTrip from "./UpdateTripModal.vue";
import TicketExtraBookingModal from "./TicketExtraBookingModal.vue";
import { reactive } from "@vue/reactivity";
import fareModality from "@/modules/fareModality";
import store from "@/store";
import dropdownVariables from "../../../../modules/dropdownVariables";
import toast from "@/modules/Toast";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
const { errorToast } = toast();
const { hasPermission } = permission();
const { Agent } = roles();
const { getNetFare } = fareModality();
import moment from "moment";
export default {
  name: "TicketBooking",
  components: {
    SeatList,
    TicketExtraBookingModal,
    TripPass,
    PnrTicket,
    UpdateTrip,
    AgentTripReportPrintPreview,
  },
  props: {
    tripId: String,
    tripDetails: Object,
    paginationData: Object,
  },
  setup(props) {
    const propsPaginationData = ref(props.paginationData);
    const propTripDetails = ref(props.tripDetails);
    const trip = ref(props.tripId);
    const addUpdate = ref(false);
    const printPreview = ref(false);
    const tripIdForPrint = ref("");
    const ticketTypeForPrint = ref("");
    const userTripReport = ref([]);
    const lastTicketDetails = ref({});
    let discountErrorMsg = ref("");
    let totalBookedSeatCount = ref(0);
    let totalBlockedSeatCount = ref(0);
    let totalAvailableSeatCount = ref(0);
    let printCount = ref(0);
    let fromStoppage = ref("");
    let toStoppage = ref("");
    let singleTripDetails = ref({});
    let seats = ref([]);
    let fare = ref(0);
    let resetAllData = ref(0);
    let fareArray = ref([]);
    let extraTicketModalName = ref("");
    let extraTicketType = ref("");
    let ticketExtraModal = ref(false);
    let tripPassModal = ref(false);
    let pnrModal = ref(false);
    let updateTripModal = ref(false);
    let calculateDue = ref(0);
    let calculateRefund = ref(0);
    let inputFare = ref(0);
    const title = ref("Print Booking");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let seatsDetails = reactive({
      tripId: trip.value,
      seats: [],
      seatClassId: "",
      discount: 0,
      totalAmount: 0,
      payable: 0,
      paid: 0,
      refund: 0,
      due: 0,
    });
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: store.getters.userCompany,
      from: "",
      to: "",
      coach: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    const seatPlans = [];
    let seatPlan = ref();
    watch(
      () => seatsDetails.discount,
      (value) => {
        if (parseInt(seatsDetails.discount) > parseInt(fare.value)) {
          discountErrorMsg.value =
            "Discount amount can't be greater than netfare";
          seatsDetails.discount = 0;
          seatsDetails.payable = 0;
        } else {
          seatsDetails.payable = fare.value - value;
        }
      }
    );
    watch(
      () => fare.value,
      () => {
        seatsDetails.payable = fare.value - seatsDetails.discount;
      }
    );
    watch(
      () => inputFare.value,
      () => {
        fare.value = inputFare.value;
      }
    );
    const close = () => {
      addUpdate.value = false;
    };
    calculateDue = computed(() => {
      let calculateValue = 0;
      if (seatsDetails.paid <= seatsDetails.payable) {
        calculateValue = seatsDetails.payable - seatsDetails.paid;
      }
      return calculateValue;
    });
    calculateRefund = computed(() => {
      let calculateValue = 0;
      if (seatsDetails.paid > seatsDetails.payable) {
        calculateValue = seatsDetails.paid - seatsDetails.payable;
      }
      return calculateValue;
    });
    const calculateFare = computed(() => {
      let netFare = 0;
      seatPlans.forEach((plan) => {
        if (plan._id === seatsDetails.seatClassId) {
          netFare = getNetFare(
            seatsDetails.seats,
            plan.upFare,
            plan.downFare,
            plan.direction
          );
        }
      });
      return netFare;
    });
    const reset = () => {
      inputFare.value = 0;
      seatsDetails.seats = [];
      seatsDetails.discount = 0;
      resetAllData.value = Math.random();
    };
    const discountedFare = computed(() => {
      return calculateFare.value - seatsDetails.discount;
    });
    const setSeatPlan = (plan) => {
      seatsDetails.discount = 0;
      seatsDetails.seatClassName = plan.name;
      seatPlan.value = plan;
      seatsDetails.seatClassId = seatPlan.value._id;
      seatsDetails.seats = [];
    };
    const refreshPage = () => {
      showLoader();
      store
        .dispatch("getActiveTripsForTicketingAgentIntercity", pagination)
        .then(() => {
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    };
    const refreshPageGoBack = () => {
      showLoader();
      store
        .dispatch(
          "getActiveTripsForTicketingAgentIntercity",
          propsPaginationData.value
        )
        .then(() => {
          getTripForTicketing();
          hideLoader();
        })
        .catch(() => {
          getTripForTicketing();
          hideLoader();
        });
    };
    const tripReport = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
        .dispatch("getAgentTripReport", {
          tripId: tripIdForPrint.value,
        })
        .then((tripReport) => {
          hideLoader();
          if (tripReport) {
            userTripReport.value = tripReport;
            ticketTypeForPrint.value = "tripReport";
          }
          reportPrintCount();
        })
        .catch(() => {
          hideLoader();
          errorToast("Something wrong.Please try again later.");
        });
    };
    const reportPrintCount = () => {
      showLoader();
      store
        .dispatch("reportPrintCountForAgent", {
          reportType: "tripWiseReport",
          tripId: trip.value,
        })
        .then((data) => {
          hideLoader();
          printCount.value = data.printCount + 1;
          printPreview.value = true;
        })
        .catch(() => {
          hideLoader();
          errorToast("Something wrong.Please try again later.");
        });
    };
    const lastTicket = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
        .dispatch("getCountermanLastTicketAgentIntercity", {
          tripId: tripIdForPrint.value,
        })
        .then((lastTicket) => {
          hideLoader();
          if (lastTicket.length > 0) {
            lastTicketDetails.value = lastTicket[0];
            ticketTypeForPrint.value = "lastTicket";
            printPreview.value = true;
          }
        })
        .catch(() => {
          hideLoader();
          errorToast("Something wrong.Please try again later.");
        });
    };
    const pnrPrint = (payload) => {
      hideLoader();
      printPreview.value = true;
      lastTicketDetails.value = payload;
      ticketTypeForPrint.value = "pnrTicket";
    };
    const extraSeatPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const extraGoodsPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const closePrintPreview = () => {
      printPreview.value = false;
      tripIdForPrint.value = "";
      ticketTypeForPrint.value = "";
    };
    const tripInfo = () => {
      if (printPreview.value) {
        const tripSingle = store.getters.getTripForTicketingAgentIntercity(
          trip.value
        )[0];
        const bookedSeats = tripSingle.bookedSeats;
        const seatPlan = tripSingle.seatPlan;
        // if (bookedSeats.length === 0) {
        //
        //    totalBookedSeat.value = [];
        //
        // } else {
        //    bookedSeats.forEach(bookedSeat => {
        //       totalBookedSeatCount.value += bookedSeat.seat_no.split(',').length
        //    });
        // }
        // if (bookedSeats.length > 0) {
        //   bookedSeats.forEach((bookedSeat) => {
        //     totalBookedSeatCount.value += bookedSeat.seat_no.split(",").length;
        //   });
        // }
        // if (seatPlan.length !== 0) {
        //
        //    seatPlan.forEach(availableSeat => {
        //       // totalAvailableSeat.value;
        //       availableSeat.seatList.forEach(seat => {
        //          totalAvailableSeat.value.push(seat.split(','));
        //       });
        //    });
        //
        //    totalAvailableSeat.value.forEach(item => {
        //       totalAvailableSeatCount.value += item.length;
        //    });
        //    totalAvailableSeatCount.value -= totalBookedSeatCount.value;
        // }
        seatPlan.forEach((item) => {
          let flag = false;
          item.seatList.forEach((seat) => {
            totalAvailableSeatCount.value += seat.split(",").length;
          });
          if (item.blockedSeat) {
            totalBlockedSeatCount.value += item.blockedSeat.split(",").length;
          }
          bookedSeats.forEach((seat) => {
            if (item._id === seat.seat_class_id) {
              const object = {
                bookedList: seat.seat_no,
                blockedSeats: item.blockedSeat,
                seatList: item.seatList,
                _id: item._id,
                name: item.name,
                upFare: item.upFare,
                downFare: item.downFare,
                direction: store.getters.getTripForTicketingAgentIntercity(
                  trip.value
                )[0].direction,
              };
              seatPlans.push(object);
              flag = true;
            }
          });
          if (!flag) {
            const object = {
              bookedList: "",
              blockedSeats: item.blockedSeat,
              seatList: item.seatList,
              _id: item._id,
              name: item.name,
              upFare: item.upFare,
              downFare: item.downFare,
              direction: store.getters.getTripForTicketingAgentIntercity(
                trip.value
              )[0].direction,
            };
            seatPlans.push(object);
            flag = false;
          }
        });
        totalAvailableSeatCount.value -=
          totalBookedSeatCount.value + totalBlockedSeatCount.value;
      }
    };
    const confirmSeats = () => {
      if (seatsDetails.seats.length > 0) {
        seatsDetails.totalAmount = calculateFare.value;
        seatsDetails.payable = discountedFare.value;
        addUpdate.value = true;
      } else {
        errorToast("Please select any seat");
      }
    };
    const getTripForTicketing = () => {
      singleTripDetails.value = store.getters.getTripForTicketingAgentIntercity(
        trip.value
      );
      totalBookedSeatCount.value = Object.entries(
        singleTripDetails.value[0].bookedSeats
      ).length;
      fromStoppage.value = singleTripDetails.value[0].route.fromStoppageId;
      toStoppage.value = singleTripDetails.value[0].route.toStoppageId;
      store.commit(
        "setRouteSequenceAgentIntercity",
        singleTripDetails.value[0].route.routeSequence
      );
      store.commit(
        "setBoardingPlaceAgentIntercity",
        singleTripDetails.value[0].route.boardingPlace
      );
      store.commit(
        "setDroppingPointAgentIntercity",
        singleTripDetails.value[0].route.droppingPoint
      );
    };
    const reducer = (accumulator, curr) => accumulator + curr;
    const seatBookedAndFare = ({ seatFare, selectedSeatsArray }) => {
      if (seatFare.length === 0 && selectedSeatsArray.length === 0) {
        fare.value = 0;
        seatsDetails.payable = fare.value;
        seatsDetails.due = 0;
        seatsDetails.refund = 0;
        seatsDetails.paid = 0;
        seatsDetails.discount = 0;
        inputFare.value = 0;

        seats.value = [];
      } else {
        if (store.getters.userCompanyData.fareType === "predefined") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare.fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value;
          }
        } else if (store.getters.userCompanyData.fareType === "trip_fare") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value;
          }
        } else if (store.getters.userCompanyData.fareType === "instant_fare") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value;
          }
        }
      }
    };
    const tripPass = () => {
      tripPassModal.value = true;
    };
    const updateTrip = () => {
      updateTripModal.value = true;
    };
    const pnrTicket = () => {
      pnrModal.value = true;
    };
    const ticketExtra = (name, ticketType) => {
      extraTicketModalName.value = name;
      extraTicketType.value = ticketType;
      ticketExtraModal.value = true;
    };
    onBeforeMount(() => {
      tripInfo();
      getTripForTicketing();
    });
    return {
      discountErrorMsg,
      ticketTypeForPrint,
      printPreview,
      Agent,
      addUpdate,
      seatsDetails,
      userTripReport,
      lastTicketDetails,
      totalAvailableSeatCount,
      totalBookedSeatCount,
      dropdownVariables,
      seatPlans,
      title,
      trip,
      seatPlan,
      calculateFare,
      propTripDetails,
      discountedFare,
      totalBlockedSeatCount,
      propsPaginationData,
      extraTicketModalName,
      extraTicketType,
      tripPassModal,
      updateTripModal,
      ticketExtra,
      hasPermission,
      tripReport,
      reset,
      close,
      lastTicket,
      confirmSeats,
      updateTrip,
      closePrintPreview,
      setSeatPlan,
      refreshPage,
      refreshPageGoBack,
      seatBookedAndFare,
      extraSeatPurchase,
      extraGoodsPurchase,
      tripPass,
      pnrTicket,
      pnrPrint,
      printCount,
      fromStoppage,
      toStoppage,
      singleTripDetails,
      seats,
      fare,
      resetAllData,
      ticketExtraModal,
      pnrModal,
      calculateDue,
      calculateRefund,
      inputFare,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: whitesmoke;
  border-top: 1px;
}
.active-button {
  background: white !important;
  /*border: 1px solid #242424 !important;*/
  color: #000 !important;
}
.inactive-button {
  color: #888 !important;
}
.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}
.span_font {
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
}
.trip_button {
  margin: 1px 2px;
  border: 2px solid #9400d3;
  border-radius: 4px;
  background-color: #9400d3;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.trip_button:hover,
.trip_button:focus {
  border: 2px solid #9400d3;
  background-color: #fff;
  color: #9400d3;
  transition: 0.4s;
  outline: 0;
}
.extra_ticket_button {
  margin: 1px 2px;
  border: 2px solid #3cbbc4;
  border-radius: 4px;
  background-color: #3cbbc4;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.extra_ticket_button:hover,
.extra_ticket_button:focus {
  border: 2px solid #3cbbc4;
  background-color: #fff;
  color: #3cbbc4;
  transition: 0.4s;
  outline: 0;
}
.goods_ticket_button {
  margin: 1px 2px;
  border: 2px solid #6edc58;
  border-radius: 4px;
  background-color: #6edc58;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.goods_ticket_button:hover,
.goods_ticket_button:focus {
  border: 2px solid #6edc58;
  background-color: #fff;
  color: #6edc58;
  transition: 0.4s;
  outline: 0;
}
.last_ticket {
  margin: 1px 2px;
  border: 2px solid #00bfff;
  border-radius: 4px;
  background-color: #00bfff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.last_ticket:hover,
.last_ticket:focus {
  border: 2px solid #00bfff;
  background-color: #fff;
  color: #00bfff;
  transition: 0.4s;
  outline: 0;
}
.pnr_ticket {
  margin: 1px 2px;
  border: 2px solid #9b5f76;
  border-radius: 4px;
  background-color: #9b5f76;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.pnr_ticket:hover,
.pnr_ticket:focus {
  border: 2px solid #9b5f76;
  background-color: #fff;
  color: #9b5f76;
  transition: 0.4s;
  outline: 0;
}
.remove_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
.update_trip_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.update_trip_button:hover,
.update_trip_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}
.trip_pass_button {
  margin: 1px 2px;
  border: 2px solid #87bb62;
  border-radius: 4px;
  background-color: #87bb62;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.trip_pass_button:hover,
.trip_pass_button:focus {
  border: 2px solid #87bb62;
  background-color: #fff;
  color: #87bb62;
  transition: 0.4s;
  outline: 0;
}
.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
.refresh_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.refresh_button:hover,
.refresh_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
</style>
