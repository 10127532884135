<template>
  <!-- <div class="mx-auto text-primary font-weight-bold h5 pt-2">
    Total Seats: {{ seatLists }}
  </div> -->
  <li
    v-for="(plan, index) in seatLists"
    :key="'plan' + index"
    class="list-group-item"
  >
    <div class="row my-2">
      <div class="col-md-11 right_border">
        <div class="row">
          <div
            v-for="(seat, seatId) in plan"
            :key="'seat' + seatId + index"
            class="col-auto"
          >
            <div
              v-if="seat.seat_no !== ''"
              :class="
                blockedSeats.includes(seat.seat_no)
                  ? 'seat my-1 px-1 py-1 rounded text-center bg-secondary'
                  : 'seat my-1 px-1 py-1 rounded text-center'
              "
            >
              <span>{{ seat.seat_no }}</span>
            </div>
            <div
              v-if="seat.seat_no === ''"
              :class="
                blockedSeats.includes(seat.seat_no)
                  ? 'seat my-1 px-1  rounded text-center'
                  : 'seat my-1 px-1 rounded text-center'
              "
            >
              <span>{{ seat.seat_no }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hasPermission(Owner.seatPlanWrite)"
        class="col-md-1 d-flex align-items-center gap-2 justify-content-center"
      >
        <button class="edit_button" @click="editSeatRow(index)">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button
          v-if="
            seatLists.length === index + 1 && hasPermission(Owner.seatPlanWrite)
          "
          class="remove_button"
          @click="removeSeatRow(index)"
        >
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
  </li>
  <EditSeatRowModal
    v-if="editModal"
    v-model="editModal"
    v-bind:seatList="seatListNew[listIndex]"
    v-bind:row="listIndex"
    v-bind:seatPlan="seat.id"
    v-bind:companyId="seat.companyId"
    v-bind:seatLists="newSeatList[listIndex]"
    @getSeatPlan="getSeatPlan"
  />
</template>

<script>
import { inject, ref, onBeforeMount, watch } from "vue";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import EditSeatRowModal from "./EditSeatRowModal";

const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "SeatList",
  props: {
    seatList: Array,
    company: String,
    seatPlanId: String,
  },
  components: {
    EditSeatRowModal,
  },
  emits: ["getSeatPlan", "selectedForBlocked"],
  setup(props, { emit }) {
    let seatListNew = ref(props.seatList);
    let seat = reactive({
      id: props.seatPlanId,
      companyId: props.company,
    });

    let seatLists = ref(props.seatList);
    let editModal = ref(false);
    let listIndex = ref(-1);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let totalSeatsArray = ref([]);
    let blockedSeats = [];
    let newSeatList = ref([]);

    watch(
      () => [...props.seatList],
      () => {
        seatLists.value = [...props.seatList];
        seat.companyId = props.company;
        seat.id = props.seatPlanId;
        seatListNew.value = [...props.seatList];
        seatJoinArryOfString();
      }
    );

    const init = () => {
      seat.id = "";
      seat.companyId = "";
    };
    const getSeatPlan = () => {
      emit("getSeatPlan");
    };
    const selectedForBlocked = (seat) => {
      emit("selectedForBlocked", seat);
    };
    const removeSeatRow = () => {
      showLoader();
      store
        .dispatch("deleteOwnerServicesSeatRow", seat)
        .then(() => {
          hideLoader();
          getSeatPlan();
          init();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            removeSeatRow();
          }
        });
    };
    const editSeatRow = (rowIndex) => {
      listIndex.value = rowIndex;
      editModal.value = true;
    };

    const seatJoinArryOfString = () => {
      const arr = [];
      const finalArr = [];
      const a = seatLists.value;
      a.forEach((item) => {
        const arr2 = [];
        item.forEach((item) => {
          arr2.push(item.seat_no);
        });
        arr.push(arr2);
      });
      arr.forEach((item) => finalArr.push(item.join()));
      newSeatList.value = finalArr;
    };

    onBeforeMount(() => {
      seatJoinArryOfString();
    });

    return {
      seatLists,
      Owner,
      editModal,
      listIndex,
      hasPermission,
      removeSeatRow,
      selectedForBlocked,
      getSeatPlan,
      totalSeatsArray,
      editSeatRow,
      blockedSeats,
      seat,
      seatListNew,
      newSeatList,
    };
  },
};
</script>

<style scoped>
.remove_button {
  border: 2px solid #ff072c;
  border-radius: 20px;
  background-color: #ff072c;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.edit_button {
  border: 2px solid #ffc107;
  border-radius: 20px;
  background-color: #ffc107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.edit_button:hover,
.edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.right_border {
  border-right: 1px solid #ccc;
}

.seat {
  cursor: pointer;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
}
</style>
