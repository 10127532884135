<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Reservation_List_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div class="card h-100 mt-4">
    <div class="card-header text-center bg-white">
    </div>
    <div class="card-body mt-0 py-0">
      <table-simple id="exportable_data">
        <template v-slot:header>
           <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>
            <td class="font_detail">PNR Code</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Seat Numbers</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Status</td>
             <td class="font_detail">Total Amount</td>
             <td class="font_detail">Issued By</td>
             <td class="font_detail">Referenced By</td>
             <td class="font_detail">Cancelled By & Time</td>

           </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in reservationDetails" :key="index">
            <td class="font_detail">
              {{ index + 1 }}
            </td>
            <td class="font_detail">{{ dateTimeFormat(item?.ticketDateTime, 6) }}</td>
            <td class="font_detail">
              <span v-if="item?.pnrCode" class="text-white px-2 py-1 rounded-pill bg-success">{{ item?.pnrCode }}</span>
            </td>
            <td class="font_detail">{{ item?.passengerName }}</td>
            <td class="font_detail">{{ item?.passengerPhone }}</td>
            <td class="font_detail">{{ item?.boardingPlace }}</td>
            <td class="font_detail">{{ item?.droppingPoint }}</td>
            <td class="font_detail">{{ item?.seatNumbers.join(', ') }}</td>
            <td class="font_detail">{{ item?.totalSeat }}</td>
            <td class="font_detail">
               <span class="text-white px-2 py-1 rounded-pill" :class="item?.status ? 'bg-success' : 'bg-danger'">
                {{ item.status ? 'Sold' : 'Cancelled' }}
              </span>
            </td>
            <td class="font_detail">{{ item?.payable }}</td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>
            <td class="font_detail">{{ item?.referenced_by?.name }}</td>

            <td class="font_detail">
              <span > {{ item?.cancelledBy?.name }} </span>
              <br v-if="item?.cancelledBy"/>
              <small v-if="item?.cancelledBy" class="text-muted">{{ dateFormat(item.updatedAt, 6) }}</small>
            </td>

          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>

<script>
import { inject, ref, onBeforeMount} from "vue";
import store from "@/store";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import moment from "moment/moment";

export default {
  name: "TripReportDetails",
  emits: ["backToTripReport"],
  props: {
    selectedCompanyId: String,
    selectedTripId: String
  },
  setup(props,{ emit }){
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const reservationDetails = ref([]);
    const exportExcel = inject("exportExcel");
    const getReservationReportDetails = () => {
      showLoader()
      store.dispatch("getTripReportDetails", {tripId:  props.selectedTripId})
          .then(res => {
            console.log(res)
            reservationDetails.value = res.tickets;
            hideLoader()
          })
          .catch(err => {
            console.log(err)
            hideLoader()
          })
    }

    const dateFormat = (date, offSet = 0) => {
      return moment(String(date))
          .utcOffset(offSet, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };

    onBeforeMount(() => {
      getReservationReportDetails();
    })

    const goBack = () => {
      emit("backToTripReport", true);
    };
    return {
      goBack,
      dateTimeFormat,
      reservationDetails,
      exportExcel,
      dateFormat
    }
  }
}
</script>