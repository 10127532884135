import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import apiResponse from "@/modules/apiResponse";


const {hasValidToken, getToken} = tokenCookie();
const {errorResponse, successResponse} = apiResponse();
const state = {};
const getters = {};

const actions = {

    async getPaymentSettlementList(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_PAYMENT_SETTLEMENT_LIST, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getPaymentSettlementDetails(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_PAYMENT_SETTLEMENT_DETAILS, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async adminPaymentSettlementApprove(_, identifier) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADMIN_PAYMENT_SETTLEMENT_APPROVE, identifier, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async adminPaymentSettlementReject(_, identifier) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADMIN_PAYMENT_SETTLEMENT_REJECT, identifier, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
