<template>
  <div>
    <FilterTab>
      <template v-slot:title
        ><h6>
          {{ t("coachWiseTrip", {}, { locale: $store.getters.getLang }) }}
        </h6></template
      >
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.tripStatusBaria"
          />
        </div>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <CoachwiseTripAddUpdateModal
        @close="close"
        @getAllTrips="getAllCoach"
        :coachId="coachId"
        :date="pagination.date"
      />
    </div>
    <div>
      <Table v-if="$store.getters.getAllCoachwiseTripListOwner">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{
                t("REGISTRATION_NUMBER", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ t("tripRoute", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ t("tripTime", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ t("SEAT-PLAN", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ t("SEAT-CLASS", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td v-if="hasPermission(Owner.ownerTripRead)" class="font_detail">
              {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(coach, index) in $store.getters
              .getAllCoachwiseTripListOwner"
            :key="coach._id"
          >
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ coach?.name }}</td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ coach?.registrationNumber }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ coach?.coachRoute?.name }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ coach?.coachTime }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ coach?.seatPlanId?.name }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.userCompanyData?.allowCoachDetails"
            >
              {{ coach?.seatClass?.name }}
            </td>
            <!-- <td>
                          <span v-if="coach.status" class="badge bg-success">{{
                            dropdownVariables.status[1]
                          }}</span>
                          <span v-else class="badge bg-danger">{{
                            dropdownVariables.status[0]
                          }}</span>
                        </td> -->
            <td v-if="hasPermission(Owner.ownerTripRead)">
              <span
                v-if="coach?.tripStatus !== 'INACTIVE'"
                class="badge bg-success edit_table_data font_detail edit_table_button"
                @click="
                  coachwiseTripActive(
                    coach._id,
                    coach?.tripStatus,
                    coach.tripId
                  )
                "
              >
                {{ t("Active", {}, { locale: $store.getters.getLang }) }}
              </span>
              <span
                v-else
                class="badge bg-danger edit_table_data font_detail edit_table_button"
                @click="
                  coachwiseTripActive(
                    coach._id,
                    coach?.tripStatus,
                    coach.tripId
                  )
                "
              >
                {{ t("Inactive", {}, { locale: $store.getters.getLang }) }}
              </span>
            </td>
          </tr>
        </template>
        <!-- <template v-slot:pagination>
                  <pagination
                    v-model="pagination.page"
                    :records="$store.getters.getTotalCoachwiseTripList"
                    :per-page="pagination.limit"
                    @paginate="getAllCoach"
                    :options="options"
                  />
                </template> -->
      </Table>
    </div>
  </div>
  <TripCancelModal
    v-model="tripCancelModal"
    v-if="tripCancelModal"
    :tripId="tripIdValue"
    @close="close"
    @getAllTrips="getAllCoach"
  />
</template>
<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import CoachwiseTripAddUpdateModal from "./CoachwiseTripAddUpdateModal.vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TripCancelModal from "./TripCancelModal.vue";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "CoachwiseTrip",
  components: { CoachwiseTripAddUpdateModal, TripCancelModal },
  setup() {
    let tripCancelModal = ref(false);
    let tripIdValue = ref("");
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: store.getters.userCompany,
      date: "",
      status: "",
    });
    pagination.date = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const addUpdate = ref(false);
    const coachId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    watch(
      () => pagination.date,
      () => {
        getAllCoach();
      }
    );
    watch(
      () => pagination.status,
      () => {
        getAllCoach();
      }
    );
    const close = () => {
      addUpdate.value = false;
      tripCancelModal.value = false;
    };
    const coachwiseTripActive = (id, tripStatus, tripId) => {
      if (tripStatus === "INACTIVE") {
        coachId.value = id;
        addUpdate.value = true;
      } else {
        tripCancelModal.value = true;
        tripIdValue.value = tripId;
      }
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllSeatClass = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesSeatClassByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllSeatClass();
          }
        });
    };
    const getAllCoach = () => {
      showLoader();
      pagination.date = moment(new Date(pagination.date)).format(
        "yyyy-MM-DD" + " 00:00:00"
      );
      store
        .dispatch("getAllCoachwiseTripByPageOwner", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCoach();
          }
        });
    };
    onBeforeMount(() => {
      getAllSeatClass();
      getAllCoach();
    });
    return {
      t,
      locale,
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      coachId,
      Owner,
      hasPermission,
      getAllCoach,
      coachwiseTripActive,
      close,
      dateFormat,
      tripIdValue,
      tripCancelModal,
    };
  },
};
</script>
<style scoped>
.bold {
  font-weight: bold;
}
</style>
