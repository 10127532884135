<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="stoppageData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="stoppageData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!stoppageId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addStoppage"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateStoppage"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";

export default {
  name: "StoppageAddUpdateModal",
  props: {
    stoppage: String,

  },
  setup(props, { emit }) {
    let { requiredValidation, errors } =
      useInputValidation();
    let stoppageData = reactive({
      _id: "",
      name: "",
      status: 1,
    });
    const stoppageId = ref(props.stoppage);
    const status = dropdownVariables.status;
    const title = ref("Add City");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    // let pagination = reactive({
    //   page: 1,
    //   limit: 25,
    // });

    watch(
      () => stoppageData.name,
      () => {
        requiredValidation("name", stoppageData.name);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", stoppageData.name);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addStoppage = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addDigitalTicketingStoppage", { stoppageData })
          .then(() => {
            hideLoader();
            emit("getAllStoppages");

            emit("close");
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addStoppage();
            }
          });
      }
    };
    const updateStoppage = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateDigitalTicketingStoppage", { stoppageData })
          .then(() => {
            hideLoader();
            emit("close");
            emit("getAllStoppages");
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateStoppage();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (stoppageId.value !== "") {
        const stoppageDetails = store.getters.getDigitalTicketingStoppageById(stoppageId.value);
        title.value = "Update City";
        stoppageData._id = stoppageDetails[0]._id;
        stoppageData.name = stoppageDetails[0].name;
        stoppageData.status = stoppageDetails[0].status ? 1 : 0;
        stoppageData.sequence = stoppageDetails[0].sequence;
      }
    });

    return {
      stoppageId,
      stoppageData,
      errors,
      status,
      title,
      addStoppage,
      updateStoppage,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
