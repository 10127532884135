<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                v-model="owner.name"
                v-bind:placeholder="'Enter Owner Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>

            <div class="col-md-3">
              <Input
                v-model="owner.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="owner.password"
                v-bind:placeholder="'Enter Password'"
                autocomplete="off"
                v-bind:label="'Password'"
                v-bind:id="'password'"
                v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="owner.email"
                v-bind:placeholder="'Email Address'"
                autocomplete="off"
                v-bind:label="'Email'"
                v-bind:id="'email'"
                v-bind:type="fieldTypes.email"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-3">
              <Dropdown
                v-if="status"
                v-model="owner.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
              />
            </div>
          </div>
        </div>

        <hr class="section_devider" />
        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5>
                <b>{{ t("Roles", {}, { locale: $store.getters.getLang }) }}</b>
              </h5>
<!--              <div class="form-check form-check-inline">-->
<!--                <input-->
<!--                  class="form-check-input all_permission_label"-->
<!--                  type="checkbox"-->
<!--                  id="select-all"-->
<!--                  name="select-all"-->
<!--                  @click="giveAllPermission"-->
<!--                  v-model="allSelected"-->
<!--                />-->
<!--                <label-->
<!--                  class="form-check-label all_permission_label"-->
<!--                  for="select-all"-->
<!--                >-->
<!--                  {{-->
<!--                    t(-->
<!--                      "Give_all_permission",-->
<!--                      {},-->
<!--                      { locale: $store.getters.getLang }-->
<!--                    )-->
<!--                  }}-->
<!--                </label>-->
<!--              </div>-->
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">
            {{ errors.roles }}
          </span>
          <span
            v-else-if="errors[Owner.ownerCompanySetupWrite]"
            class="text-danger err_msg"
            >{{ errors[Owner.ownerCompanySetupWrite] }}</span
          >
          <span
            v-else-if="errors[Owner.ownerTripWrite]"
            class="text-danger err_msg"
            >{{ errors[Owner.ownerTripWrite] }}</span
          >
          <span
            v-else-if="errors[Owner.ownerWrite]"
            class="text-danger err_msg"
            >{{ errors[Owner.ownerWrite] }}</span
          >
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                    {{ t("Dashboard", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Owner.ownerDashboard"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                    {{ t("Report", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Owner.ownerReports"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                    {{ t("Owners", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Owner.ownerRead"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Write'"
                    v-bind:role="Owner.ownerWrite"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                    {{ t("Recharge", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Write'"
                    v-bind:role="Owner.agentBalanceRecharge"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                   Trip Copy
                  </h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Copy'"
                      v-bind:role="Owner.tripCopy"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Company Setup</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerCompanySetupRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.ownerCompanySetupWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>

            </div>
            <hr/>
            <div v-if="showCompanySetup"  class="row">
              <h5 class="text-muted">company setup</h5>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Agent</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.agentRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.agentWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Counterman</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.countermanRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.countermanWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Driver</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.driverRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.driverWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Supervisor</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.supervisorRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.supervisorWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Stoppage</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.stoppageRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.stoppageWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Counter</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.counterRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.counterWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Route</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.routeRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.routeWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>
                    {{ t("Trips", {}, { locale: $store.getters.getLang }) }}
                  </h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerTripRead"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.ownerTripWrite"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Seat Class</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.seatClassRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.seatClassWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Seat Plan</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.seatPlanRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.seatPlanWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Fare</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.fareRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.fareWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Coach</h5>
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.coachRead"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      :key="allSelected"
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.coachWrite"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>

<!--              <div class="col-6 col-sm-4 col-md-3">-->
<!--                <div class="role_select_area">-->
<!--                  <h5>Quota</h5>-->
<!--                  <Checkbox-->
<!--                      :key="allSelected"-->
<!--                      v-model="checked"-->
<!--                      v-bind:label="'Read'"-->
<!--                      v-bind:role="Owner.quotaRead"-->
<!--                      @toggleAllPermission="toggleAllPermission"-->
<!--                  />-->
<!--                  <Checkbox-->
<!--                      :key="allSelected"-->
<!--                      v-model="checked"-->
<!--                      v-bind:label="'Write'"-->
<!--                      v-bind:role="Owner.quotaWrite"-->
<!--                      @toggleAllPermission="toggleAllPermission"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-6 col-sm-4 col-md-3">-->
<!--                <div class="role_select_area">-->
<!--                  <h5>Digital Ticketing</h5>-->
<!--                  <Checkbox-->
<!--                      :key="allSelected"-->
<!--                      v-model="checked"-->
<!--                      v-bind:label="'Read'"-->
<!--                      v-bind:role="Owner.digitalTicketingRead"-->
<!--                      @toggleAllPermission="toggleAllPermission"-->
<!--                  />-->
<!--                  <Checkbox-->
<!--                      :key="allSelected"-->
<!--                      v-model="checked"-->
<!--                      v-bind:label="'Write'"-->
<!--                      v-bind:role="Owner.digitalTicketingWrite"-->
<!--                      @toggleAllPermission="toggleAllPermission"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              v-if="!ownerIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addOwner"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateOwner"
            /> -->
            <button
              v-if="!ownerIdForUpdate"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addOwner"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              v-else
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateOwner"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import roles from "@/modules/roles";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "OwnerAddUpdateModal",
  props: {
    modelValue: Boolean,
    ownerId: String,
  },
  setup(props, { emit }) {
    let owner = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "owner",
      companyId: "",
      counterId: "",
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      reset_token: "",
    });
    let ownerIdForUpdate = ref(props.ownerId);
    let checked = ref([]);
    let allSelected = ref(false);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      requiredRoleValidation,
      errors,
    } = useInputValidation();
    const { Owner } = roles();
    const status = dropdownVariables.status;
    const title = ref("Add Owner");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });
    const showCompanySetup = ref(false)


    watch(
      () => owner.name,
      () => {
        requiredValidation("name", owner.name);
      }
    );
    watch(
      () => owner.phone,
      () => {
        phoneValidation("phone", owner.phone);
      }
    );
    watch(
      () => owner.roles,
      () => {
        requiredValidation("roles", owner.roles);
      }
    );
    watch(
      () => owner.password,
      () => {
        passwordValidation("password", owner.password);
      }
    );
    watch(
      () => owner.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => owner.email,
      () => {
        fieldTypes.email = "email";
      }
    );
    watch(()=>checked.value, (roles)=>{
      giveReadPermission(roles)
      giveWritePermission(roles)
      renderBasedOnCompanySetup()
    }, {deep: true, flush: "post"})

    const renderBasedOnCompanySetup = () => {
      showCompanySetup.value = checked.value.includes(Owner.ownerCompanySetupRead)
      if (!showCompanySetup.value){
        removeFromArrayByElement(Owner.ownerCompanySetupWrite)
        removeFromArrayByElement(Owner.ownerTripRead)
        removeFromArrayByElement(Owner.ownerTripWrite)
        removeFromArrayByElement(Owner.agentRead)
        removeFromArrayByElement(Owner.agentWrite)
        removeFromArrayByElement(Owner.countermanRead)
        removeFromArrayByElement(Owner.countermanWrite)
        removeFromArrayByElement(Owner.driverRead)
        removeFromArrayByElement(Owner.driverWrite)
        removeFromArrayByElement(Owner.supervisorRead)
        removeFromArrayByElement(Owner.supervisorWrite)
        removeFromArrayByElement(Owner.stoppageRead)
        removeFromArrayByElement(Owner.stoppageWrite)
        removeFromArrayByElement(Owner.counterRead)
        removeFromArrayByElement(Owner.counterWrite)
        removeFromArrayByElement(Owner.routeRead)
        removeFromArrayByElement(Owner.routeWrite)
        removeFromArrayByElement(Owner.seatClassRead)
        removeFromArrayByElement(Owner.seatClassWrite)
        removeFromArrayByElement(Owner.seatPlanRead)
        removeFromArrayByElement(Owner.seatPlanWrite)
        removeFromArrayByElement(Owner.fareRead)
        removeFromArrayByElement(Owner.fareWrite)
        removeFromArrayByElement(Owner.coachRead)
        removeFromArrayByElement(Owner.coachWrite)
        // removeFromArrayByElement(Owner.quotaRead)
        // removeFromArrayByElement(Owner.quotaWrite)
        // removeFromArrayByElement(Owner.digitalTicketingRead)
        // removeFromArrayByElement(Owner.digitalTicketingWrite)

      }
    }
    const removeFromArrayByElement = (item) => {
      const index =  checked.value.indexOf(item);
      if (index !== -1) {
        checked.value.splice(index, 1);
      }
    }
    const giveReadPermission = (roles) => {
      if (roles.includes(Owner.ownerWrite) && !roles.includes(Owner.ownerRead))
        checked.value.push(Owner.ownerRead)
      if (roles.includes(Owner.ownerTripWrite) && !roles.includes(Owner.ownerTripRead))
        checked.value.push(Owner.ownerTripRead)
      if (roles.includes(Owner.agentWrite) && !roles.includes(Owner.agentRead))
        checked.value.push(Owner.agentRead)
      if (roles.includes(Owner.countermanWrite) && !roles.includes(Owner.countermanRead))
        checked.value.push(Owner.countermanRead)
      if (roles.includes(Owner.driverWrite) && !roles.includes(Owner.driverRead))
        checked.value.push(Owner.driverRead)
      if (roles.includes(Owner.supervisorWrite) && !roles.includes(Owner.supervisorRead))
        checked.value.push(Owner.supervisorRead)
      if (roles.includes(Owner.stoppageWrite) && !roles.includes(Owner.stoppageRead))
        checked.value.push(Owner.stoppageRead)
      if (roles.includes(Owner.counterWrite) && !roles.includes(Owner.counterRead))
        checked.value.push(Owner.counterRead)
      if (roles.includes(Owner.routeWrite) && !roles.includes(Owner.routeRead))
        checked.value.push(Owner.routeRead)
      if (roles.includes(Owner.seatClassWrite) && !roles.includes(Owner.seatClassRead))
        checked.value.push(Owner.seatClassRead)
      if (roles.includes(Owner.seatPlanWrite) && !roles.includes(Owner.seatPlanRead))
        checked.value.push(Owner.seatPlanRead)
      if (roles.includes(Owner.fareWrite) && !roles.includes(Owner.fareRead))
        checked.value.push(Owner.fareRead)
      if (roles.includes(Owner.coachWrite) && !roles.includes(Owner.coachRead))
        checked.value.push(Owner.coachRead)

      // if (roles.includes(Owner.quotaWrite) && !roles.includes(Owner.quotaRead))
      //   checked.value.push(Owner.quotaRead)
      // if (roles.includes(Owner.digitalTicketingWrite) && !roles.includes(Owner.digitalTicketingRead))
      //   checked.value.push(Owner.digitalTicketingRead)
    }

    const giveWritePermission = (roles) => {
      if (roles.includes(Owner.ownerCompanySetupRead) && !roles.includes(Owner.ownerCompanySetupWrite))
        checked.value.push(Owner.ownerCompanySetupWrite)
    }

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Owner);
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const toggleAllPermission = () => {
      allSelected.value = checked.value.length === Object.values(Owner).length;
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", owner.name);
      phoneValidation("phone", owner.phone);
      requiredValidation("roles", owner.roles);
      requiredRoleValidation(
        Owner.ownerCompanySetupWrite.toString(),
        checked.value,
        Owner.ownerCompanySetupRead,
        Owner.ownerCompanySetupWrite,
        Owner
      );
      requiredRoleValidation(
        Owner.ownerTripWrite.toString(),
        checked.value,
        Owner.ownerTripRead,
        Owner.ownerTripWrite,
        Owner
      );
      requiredRoleValidation(
        Owner.ownerWrite.toString(),
        checked.value,
        Owner.ownerRead,
        Owner.ownerWrite,
        Owner
      );

      if (ownerIdForUpdate.value === "") {
        passwordValidation("password", owner.password);
      } else {
        if (owner.password !== "") {
          passwordValidation("password", owner.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addOwner = () => {
      owner.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("addPanelOwner", owner)
          .then(() => {
            hideLoader();
            emit("getAllOwners");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addOwner();
            }
          });
      }
    };
    const updateOwner = () => {
      owner.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("updatePanelOwner", owner)
          .then(() => {
            hideLoader();
            emit("getAllOwners");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateOwner();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (ownerIdForUpdate.value !== "") {
        const ownerDetails = store.getters.getPanelOwner(
          ownerIdForUpdate.value
        );
        title.value = "Update Owner";
        owner.id = ownerDetails[0]._id;
        owner.name = ownerDetails[0].name;
        owner.designation = ownerDetails[0].designation;
        owner.phone = ownerDetails[0].phone;
        owner.email = ownerDetails[0].email;
        owner.address = ownerDetails[0].address;
        owner.transactionType = ownerDetails[0].transactionType;
        owner.balance = ownerDetails[0].balance;
        owner.commission = ownerDetails[0].commission;
        owner.ownerDetailsType = ownerDetails[0].ownerDetailsType;
        owner.companyId = ownerDetails[0].companyId;
        owner.counterId = ownerDetails[0].counterId;
        owner.commissionType = ownerDetails[0].commissionType;
        owner.nid = ownerDetails[0].nid;
        owner.status = ownerDetails[0].status ? 1 : 0;
        ownerDetails[0].roles[0]
          .split(",")
          .forEach((element) => checked.value.push(parseInt(element)));
        Object.keys(Owner).length === checked.value.length
          ? (allSelected.value = true)
          : (allSelected.value = false);
      }
    });

    return {
      t,
      locale,
      status,
      owner,
      errors,
      Owner,
      title,
      checked,
      allSelected,
      ownerIdForUpdate,
      giveAllPermission,
      toggleAllPermission,
      updateOwner,
      addOwner,
      closeModal,
      fieldTypes,
      showCompanySetup,
    };
  },
};
</script>

<style scoped></style>
