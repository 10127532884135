<template>
  <div class="row">
    <div v-if="purchaseStatus" class="row m-0">
      <TicketPrintPreview
        v-bind:ticket="purchasedTicket"
        v-bind:ticketType="'ticket'"
        @close="close"
      />
    </div>
    <div
      class="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-4"
      v-if="!purchaseStatus"
    >
      <ul style="margin: 0; padding: 0">
        <li
          v-for="(plan, index) in seatLists"
          :key="index"
          class="list-group-item"
        >
          <div class="row my-2">
            <div class="col-md-11">
              <div class="row justify-content-center">
                <div
                  v-for="(seat, seatId) in plan"
                  :key="'seat' + seatId + index"
                  class="col-auto"
                >
                  <div
                    @click="selectedSeats(seat.seat_no, seat.class_id)"
                    v-if="
                      seat.seat_no !== '' &&
                        !selectedSeatsBookedDatabase.includes(seat.seat_no)
                    "
                    :class="
                      selectedSeatsBooked.includes(seat.seat_no)
                        ? 'seatSelect my-1 px-1 py-1 rounded text-center bg-success'
                        : 'seatNormal my-1 px-1 py-1 rounded text-center'
                    "
                  >
                    <span>{{ seat.seat_no }}</span>
                  </div>
                  <div
                    v-if="
                      seat.seat_no !== '' &&
                        selectedSeatsBookedDatabase.includes(seat.seat_no)
                    "
                    :class="
                      selectedSeatsBookedDatabase.includes(seat.seat_no)
                        ? 'seat my-1 px-1 py-1 rounded text-center bg-danger disabled'
                        : 'seatNormal my-1 px-1 py-1 rounded text-center'
                    "
                  >
                    <span>{{ seat.seat_no }}</span>
                  </div>
                  <div
                    v-if="seat.seat_no === ''"
                    :class="
                      selectedSeatsBooked.includes(seat.seat_no)
                        ? 'seat my-1 px-1  rounded text-center'
                        : 'seat my-1 px-1 rounded text-center'
                    "
                  >
                    <span>{{ seat.seat_no }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-8"
      v-if="!purchaseStatus"
    >
      <div class="card card-body py-4" style="background-color: whitesmoke">
        <div class="row">
          <!-- <div v-if="ticketPurchase" class="row m-0">
            <TicketPrintPreview
              v-bind:ticket="''"
              v-bind:ticketType="'ticket'"
              @close="close"
              :dashboardTripData="propsPaginationData"
            />
          </div> -->
          <div class="row m-0">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
              <span class="fw-bold">BOOKING INFORMATION</span>
              <div class="card card-body">
                <Error v-if="err" v-bind:error="err" />
                <div class="mx-6">
                  <form class="my-2">
                    <div class="row">
                      <div class="col-md-6 col-lg-12 col-xl-6">
                        <div class="form-group row">
                          <TicketInput
                            v-model="passengerDetails.passengerName"
                            v-bind:placeholder="'Passenger Name'"
                            v-bind:label="'Name'"
                            v-bind:id="'name'"
                            v-bind:type="'text'"
                          />
                        </div>
                        <div class="form-group row my-1">
                          <TicketInput
                            v-model="passengerDetails.passengerPhone"
                            v-bind:placeholder="'Passenger Mobile'"
                            v-bind:label="'Mobile'"
                            v-bind:id="'mobile'"
                            v-bind:type="'text'"
                          >
                            <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.passengerPhone"
                                v-bind:error="err.passengerPhone"
                              />
                            </template> -->
                          </TicketInput>
                        </div>
                        <div class="form-group row my-1">
                          <TicketDropdown
                            v-model="passengerDetails.passengerGender"
                            v-bind:id="'passengerGender'"
                            v-bind:label="'Gender'"
                            v-bind:dropdown="dropdownVariables.gender"
                            v-bind:defaultOption="'--select gender --'"
                            v-bind:defaultValue="''"
                          >
                          </TicketDropdown>
                        </div>
                        <div class="form-group row my-1">
                          <TicketInput
                            v-model="passengerDetails.passengerNID"
                            v-bind:placeholder="'Passenger NID'"
                            v-bind:label="'NID'"
                            v-bind:id="'nid'"
                            v-bind:type="'text'"
                          />
                        </div>
                        <div class="form-group row my-1">
                          <div class="col-sm-3">
                            <label
                              for="address"
                              class="col-form-label font_detail"
                              >Address</label
                            >
                          </div>
                          <div class="col-sm-9">
                            <textarea
                              class="form-control font_detail"
                              id="address"
                              v-model="passengerDetails.passengerAddress"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-12 col-xl-6">
                        <div class="form-group row my-1">
                          <TicketDropdown
                            v-model="passengerDetails.fromStoppage"
                            v-bind:id="'fromStoppage'"
                            v-bind:label="'From Stoppage'"
                            v-bind:defaultOption="'---select a stoppage---'"
                            v-bind:defaultValue="''"
                            v-bind:dropdown="
                              $store.getters
                                .getRouteSequenceFilterAgentIntercity
                            "
                          >
                            <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                          </TicketDropdown>
                        </div>
                        <div class="form-group row my-1">
                          <TicketDropdown
                            v-model="passengerDetails.toStoppage"
                            v-bind:id="'toStoppage'"
                            v-bind:label="'To Stoppage'"
                            v-bind:defaultOption="'---select a stoppage---'"
                            v-bind:defaultValue="''"
                            v-bind:dropdown="
                              $store.getters
                                .getRouteSequenceFilterAgentIntercity
                            "
                          >
                            <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                          </TicketDropdown>
                        </div>
                        <div class="form-group row my-1">
                          <TicketDropdown
                            v-model="passengerDetails.boardingPlace"
                            v-bind:id="'boardingPlace'"
                            v-bind:label="'Boarding place'"
                            v-bind:defaultOption="
                              '---select a boarding place---'
                            "
                            v-bind:defaultValue="''"
                            v-bind:dropdown="
                              $store.getters
                                .getBoardingPlaceFilterAgentIntercity
                            "
                          >
                            <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.boardingPlace"
                                v-bind:error="err.boardingPlace"
                              />
                            </template> -->
                          </TicketDropdown>
                          <Error
                            v-if="errors.boardingPlace"
                            v-bind:error="errors.boardingPlace"
                          />
                        </div>
                        <div class="form-group row my-1">
                          <TicketDropdown
                            v-model="passengerDetails.droppingPoint"
                            v-bind:id="'droppingPoint'"
                            v-bind:label="'Dropping place'"
                            v-bind:defaultOption="
                              '---select a dropping point---'
                            "
                            v-bind:defaultValue="''"
                            v-bind:dropdown="
                              $store.getters
                                .getDroppingPointFilterAgentIntercity
                            "
                          >
                            <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                          </TicketDropdown>
                          <Error
                            v-if="errors.droppingPoint"
                            v-bind:error="errors.droppingPoint"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <span class="fw-bold">ACTIONS</span>
              <div class="card card-body">
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-xl-12">
                    <p class="text-center font_detail m-0">CONFIRM</p>
                    <button
                      class="confirm_button w-100"
                      @click.prevent="purchaseTicket"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
              <Error
                v-if="ticketPurchaseError"
                v-bind:error="ticketPurchaseError"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, watch, inject } from "vue";
// import TicketPurchase from '../'
// import store from "@/store";
// import {reactive} from "@vue/reactivity";
import store from "@/store";
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import moment from "moment";
import useInputValidation from "@/modules/useInputValidations";
import toast from "@/modules/Toast";
const { warningToast, errorToast } = toast();

export default {
  name: "SeatList",
  props: {
    modelValue: Array,
    seatPlan: Object,
    fromStoppage: String,
    toStoppage: String,
    singleTripDetails: Object,
    discount: [Number, String],
    due: [Number, String],
    inputFare: [Number, String],
    refund: [Number, String],
    paid: [Number, String],
    resetAllData: Number,
  },
  setup(props, { emit }) {
    // const seats = ref(props.seatPlan);
    // const selectedSeats = ref(props.modelValue);
    // const alreadyBooked = ref(seats.value.bookedList.split(","));
    let inputFareTotal = ref(props.inputFare);
    const fromStoppage = ref(props.fromStoppage);
    const toStoppage = ref(props.toStoppage);
    let singleTripDetails = ref(props.singleTripDetails);
    const blockedSeats = [];
    let seatLists = ref([]);
    let ticketPurchase = ref(false);
    let selectedSeatsArray = ref([]);
    let seatFare = ref([]);
    let selectedSeatsBooked = ref([]);
    let bookedSeats = ref([]);
    let selectedSeatsBookedDatabase = ref([]);
    let err = ref("");
    let ticketPurchaseError = ref("");
    let purchaseStatus = ref(false);
    let purchasedTicket = ref({});

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let { requiredValidation, errors } = useInputValidation();
    const status = dropdownVariables.status;
    let passengerDetails = reactive({
      trip: "",
      coach: "",
      fromStoppage: "",
      toStoppage: "",
      ticketType: "SEAT",
      passengerName: "",
      passengerPhone: "",
      passengerGender: "",
      passengerNID: "",
      passengerAddress: "",
      boardingPlace: "",
      droppingPoint: "",
      farePerTicket: 0,
      seatClass: "",
      seatNumbers: [],
      totalAmount: 0,
      discount: 0,
      payable: 0,
      farePerSeat: {},
      paid: 0,
      dues: 0,
      refund: 0,
      referenced_by: null,
      ticketDateTime: "",
    });
    const clear = () => {
      passengerDetails.passengerName = "";
      passengerDetails.passengerPhone = "";
      passengerDetails.passengerGender = "";
      passengerDetails.passengerNID = "";
      passengerDetails.passengerAddress = "";
      passengerDetails.boardingPlace = "";
      passengerDetails.droppingPoint = "";
      passengerDetails.farePerTicket = 0;
      passengerDetails.seatClass = "";
      passengerDetails.seatNumbers = [];
      passengerDetails.totalAmount = 0;
      passengerDetails.discount = 0;
      passengerDetails.payable = 0;
      passengerDetails.paid = 0;
      passengerDetails.dues = 0;
      passengerDetails.refund = 0;
    };
    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = "";
      });
      requiredValidation("boardingPlace", passengerDetails.boardingPlace);
      requiredValidation("droppingPoint", passengerDetails.droppingPoint);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const purchaseTicket = () => {
      if (validate()) {
        if (store.getters.userCompanyData.fareType === "predefined") {
          for(let key of Object.keys(passengerDetails.farePerSeat)){
            if(!selectedSeatsBooked.value.includes(key)){
              delete passengerDetails.farePerSeat[key];
            }
          }

          seatFare.value.forEach((seat) => {
            passengerDetails.seatNumbers.push(seat.seat_no);
            passengerDetails.totalAmount += seat.fare;
          });
          passengerDetails.payable =
            passengerDetails.totalAmount - passengerDetails.discount;
          passengerDetails.farePerTicket = passengerDetails.payable/passengerDetails.seatNumbers.length
          if (passengerDetails.payable !== 0) {
            passengerDetails.ticketDateTime = moment(new Date())
              .utcOffset(0, true)
              .format("yyyy-MM-DD hh:mm:ss");
            if (passengerDetails.payable > store.getters.agentBalance) {
              errorToast("Out of Balance!!!");
              clear();
            } else {
              showLoader();
              store
                .dispatch(
                  "confirmPassengerTicketAgentIntercity",
                  passengerDetails
                )
                .then((ticket) => {
                  clear();
                  purchaseStatus.value = true;
                  purchasedTicket.value = ticket;
                  seatFare.value = [];
                  selectedSeatsArray.value = [];
                  store.commit("updateBalance", ticket.issuedBy.balance);
                  emitSeatsAndFare();
                  hideLoader();
                })
                .catch(() => {
                  clear();
                  hideLoader();
                });
            }
          } else {
            passengerDetails.seatNumbers = [];
            errorMsg();
          }
        } else if (store.getters.userCompanyData.fareType === "instant_fare") {
          for(let key of Object.keys(passengerDetails.farePerSeat)){
            if(!selectedSeatsBooked.value.includes(key)){
              delete passengerDetails.farePerSeat[key];
            }
          }

          passengerDetails.totalAmount += inputFareTotal.value;
          passengerDetails.seatNumbers = selectedSeatsBooked.value;
          passengerDetails.payable =
            passengerDetails.totalAmount - passengerDetails.discount;
          passengerDetails.farePerTicket = passengerDetails.payable/passengerDetails.seatNumbers.length
          if (passengerDetails.payable !== 0) {
            passengerDetails.ticketDateTime = moment(new Date())
              .utcOffset(0, true)
              .format("yyyy-MM-DD hh:mm:ss");
            if (passengerDetails.payable > store.getters.agentBalance) {
              errorToast("Out of Balance!!!");
              clear();
            } else {
              showLoader();
              store
                .dispatch(
                  "confirmPassengerTicketAgentIntercity",
                  passengerDetails
                )
                .then((ticket) => {
                  clear();
                  purchaseStatus.value = true;
                  purchasedTicket.value = ticket;
                  seatFare.value = [];
                  selectedSeatsArray.value = [];
                  store.commit("updateBalance", ticket.issuedBy.balance);
                  emitSeatsAndFare();
                  hideLoader();
                })
                .catch(() => {
                  clear();
                  hideLoader();
                });
            }
          } else {
            passengerDetails.seatNumbers = [];
            errorMsg();
          }
        } else if (store.getters.userCompanyData.fareType === "trip_fare") {
          for(let key of Object.keys(passengerDetails.farePerSeat)){
            if(!selectedSeatsBooked.value.includes(key)){
              delete passengerDetails.farePerSeat[key];
            }
          }

          passengerDetails.totalAmount +=
            selectedSeatsArray.value.length *
            singleTripDetails.value[0].tripFare;
          passengerDetails.seatNumbers = selectedSeatsBooked.value;
          passengerDetails.payable =
            passengerDetails.totalAmount - passengerDetails.discount;
          passengerDetails.farePerTicket = passengerDetails.payable/passengerDetails.seatNumbers.length
          if (passengerDetails.payable !== 0) {
            passengerDetails.ticketDateTime = moment(new Date())
              .utcOffset(0, true)
              .format("yyyy-MM-DD hh:mm:ss");
            showLoader();
            store
              .dispatch(
                "confirmPassengerTicketAgentIntercity",
                passengerDetails
              )
              .then((ticket) => {
                clear();
                purchaseStatus.value = true;
                purchasedTicket.value = ticket;
                seatFare.value = [];
                selectedSeatsArray.value = [];
                emitSeatsAndFare();
                hideLoader();
              })
              .catch(() => {
                clear();
                hideLoader();
              });
          } else {
            passengerDetails.seatNumbers = [];
            errorMsg();
          }
        }
      }
    };

    watch(
      () => props.inputFare,
      (value) => {
        inputFareTotal.value = value;
      }
    );

    watch(
      () => props.paid,
      (value) => {
        passengerDetails.paid = value;
      }
    );
    watch(
      () => props.due,
      (value) => {
        passengerDetails.dues = value;
      }
    );
    watch(
      () => props.refund,
      (value) => {
        passengerDetails.refund = value;
      }
    );
    watch(
      () => props.singleTripDetails,
      (value) => {
        singleTripDetails.value = value;
        bookedSeatsPushToArray();
      }
    );
    watch(
      () => props.resetAllData,
      () => {
        clear();
        seatFare.value = [];
        selectedSeatsArray.value = [];
        selectedSeatsBooked.value = [];
        emitSeatsAndFare();
      }
    );
    watch(
      () => props.discount,
      (value) => {
        if (value === "NaN") {
          passengerDetails.discount = 0;
        } else {
          passengerDetails.discount = parseInt(value);
        }
      }
    );
    watch(
      () => passengerDetails.fromStoppage,
      () => {
        err.value = "";
        seatFare.value = [];
        selectedSeatsArray.value = [];
        selectedSeatsBooked.value = [];
        emitSeatsAndFare();
        renderSeatListBasedOnStoppageSelect();
        fareCalculation();
      }
    );
    watch(
      () => passengerDetails.toStoppage,
      () => {
        err.value = "";
        seatFare.value = [];
        selectedSeatsArray.value = [];
        selectedSeatsBooked.value = [];
        emitSeatsAndFare();
        renderSeatListBasedOnStoppageSelect();
        fareCalculation();
      }
    );
    watch(
      () => passengerDetails.boardingPlace,
      () => {
        if (passengerDetails.boardingPlace !== "") {
          requiredValidation("boardingPlace", passengerDetails.boardingPlace);
        }
      }
    );
    watch(
      () => passengerDetails.droppingPoint,
      () => {
        if (passengerDetails.droppingPoint !== "") {
          requiredValidation("droppingPoint", passengerDetails.droppingPoint);
        }
      }
    );
    const boardingPlaceDropDown = computed(() => {
      let boardingPlace = {};
      return boardingPlace;
    });
    const droppingPointDropDown = computed(() => {
      let droppingPoint = {};
      return droppingPoint;
    });
    const compare = (actualArray, selectArray) => {
      let comp = false;
      actualArray.find((seat) => {
        if (seat.seat_no === selectArray.seat_no) {
          comp = true;
        }
      });
      return comp;
    };
    const emitSeatsAndFare = () => {
      if (store.getters.userCompanyData.fareType === "trip_fare") {
        emit("seatBookedAndFare", {
          seatFare: [
            selectedSeatsArray.value.length *
              singleTripDetails.value[0].tripFare,
          ],
          selectedSeatsArray: selectedSeatsArray.value,
        });
      } else if (store.getters.userCompanyData.fareType === "predefined") {
        emit("seatBookedAndFare", {
          seatFare: seatFare.value,
          selectedSeatsArray: selectedSeatsArray.value,
        });
      } else if (store.getters.userCompanyData.fareType === "instant_fare") {
        emit("seatBookedAndFare", {
          seatFare: [inputFareTotal.value],
          selectedSeatsArray: selectedSeatsArray.value,
        });
      }
    };
    const selectedSeats = (seatNo, seatId) => {
      ticketPurchaseError.value = "";
      let selectedSeat = {
        seat_no: seatNo,
        class_id: seatId,
      };
      if (compare(selectedSeatsArray.value, selectedSeat)) {
        selectedSeatsArray.value = selectedSeatsArray.value.filter(
          (alreadySelected) => alreadySelected.seat_no !== selectedSeat.seat_no
        );
        seatFare.value = seatFare.value.filter(
          (alreadySelected) => alreadySelected.seat_no !== selectedSeat.seat_no
        );
        selectedSeatsBooked.value = selectedSeatsBooked.value.filter(
          (seat) => seat !== selectedSeat.seat_no
        );
        emitSeatsAndFare();
      } else {
        if (selectedSeatsBooked.value.length < store.getters.maxSeatPerTicket) {
          selectedSeatsArray.value.push(selectedSeat);
          selectedSeatsBooked.value.push(selectedSeat.seat_no);
          fareCalculation(seatNo, seatId);
        } else {
          warningToast(
            `You can sell ${store.getters.maxSeatPerTicket} tickets at a time !!`
          );
        }
      }
    };
    const arrayObjectIndexOf = (myArray, searchTerm, property) => {
      for (var i = 0, len = myArray.length; i < len; i++) {
        if (myArray[i][property] === searchTerm) return i;
      }
      return -1;
    };
    const renderSeatListBasedOnStoppageSelect = () => {
      let routeSequence = singleTripDetails.value[0].route.routeSequence;
      let indexOfFromStoppage = arrayObjectIndexOf(
        routeSequence,
        passengerDetails.fromStoppage,
        "_id"
      );
      let indexOfToStoppage = arrayObjectIndexOf(
        routeSequence,
        passengerDetails.toStoppage,
        "_id"
      );
      if (
        indexOfToStoppage !== -1 &&
        indexOfFromStoppage !== -1 &&
        indexOfToStoppage > indexOfFromStoppage
      ) {
        selectedSeatsBookedDatabase.value = [];
        const tripRouteSequence = singleTripDetails.value[0].route.routeSequence.map(
          (item) => item._id
        );
        const indexOfFromStoppage = tripRouteSequence.indexOf(
          passengerDetails.fromStoppage
        );
        const indexOfToStoppage = tripRouteSequence.indexOf(
          passengerDetails.toStoppage
        );
        const fromToSequence = tripRouteSequence.slice(
          indexOfFromStoppage,
          indexOfToStoppage + 1
        );
        for (let [key, value] of Object.entries(
          singleTripDetails.value[0].bookedSeats
        )) {
          for (let v of Object.values(value)) {
            const intersectValue = v.filter((s) => fromToSequence.includes(s))
              .length;
            if (intersectValue > 1) {
              if (!selectedSeatsBookedDatabase.value.includes(key)) {
                selectedSeatsBookedDatabase.value.push(key);
                break;
              }
            } else {
              if (selectedSeatsBookedDatabase.value.includes(key)) {
                selectedSeatsBookedDatabase.value.splice(
                  selectedSeatsBookedDatabase.value.indexOf(key),
                  1
                );
              }
            }
          }
        }
      } else {
        err.value = "fromStoppage to toStoppage route sequence did not match";
      }
    };
    const errorMsg = () => {
      ticketPurchaseError.value =
        "May be, You did not Select any seat / Your selected route does not have any fare!";
    };
    const bookedSeatsPushToArray = () => {
      seatLists.value = singleTripDetails.value[0].seatPlan.seatList;
      for (const key of Object.keys(singleTripDetails.value[0].bookedSeats)) {
        bookedSeats.value.push(key);
      }
      selectedSeatsBookedDatabase.value = bookedSeats.value;
    };
    const fareCalculation = (seatNo = "", classId = "") => {
      seatLists.value = singleTripDetails.value[0].seatPlan.seatList;
      if (store.getters.userCompanyData.fareType === "predefined") {
        singleTripDetails.value[0].fare?.fareModality.forEach((stoppage) => {
          let fromStoppageLoop = stoppage.fromStoppageId;
          let toStoppageLoop = stoppage.toStoppageId;
          if (
            fromStoppageLoop === passengerDetails.fromStoppage &&
            toStoppageLoop === passengerDetails.toStoppage
          ) {
            stoppage.fare.forEach((fare) => {
              if (
                fare.class_id === classId &&
                (fare.fare !== 0 || fare.fare !== "" || fare.fare !== undefined)
              ) {
                seatFare.value.push({
                  seat_no: seatNo,
                  fare: fare.fare,
                });
                if (seatNo !== "") {
                  passengerDetails.farePerSeat[seatNo] = fare.fare;
                }
                emitSeatsAndFare();
              }
            });
          }
        });
      } else if (store.getters.userCompanyData.fareType === "trip_fare") {
        if (seatNo !== "") {
          passengerDetails.farePerSeat[seatNo] =
            singleTripDetails.value[0].tripFare;
        }
        emitSeatsAndFare();
      } else if (store.getters.userCompanyData.fareType === "instant_fare") {
        if (seatNo !== "") {
          passengerDetails.farePerSeat[seatNo] =
            inputFareTotal.value/selectedSeatsBooked.value.length;
        }
        emitSeatsAndFare();
      }
    };
    const close = () => {
      emit("tripSeatsRefresh");
      purchaseStatus.value = false;
    };
    onBeforeMount(() => {
      passengerDetails.fromStoppage = fromStoppage.value;
      passengerDetails.toStoppage = toStoppage.value;
      passengerDetails.trip = singleTripDetails.value[0]._id;
      passengerDetails.coach = singleTripDetails.value[0].coach._id;
      passengerDetails.seatClass =
        singleTripDetails.value[0].seatPlan.seatClass;
      if (singleTripDetails.value[0].seatPlan.seatClass === "Mixed") {
        passengerDetails.farePerTicket = 0;
      }
      fareCalculation();
      bookedSeatsPushToArray();
    });
    return {
      err,
      blockedSeats,
      seatLists,
      passengerDetails,
      status,
      dropdownVariables,
      boardingPlaceDropDown,
      droppingPointDropDown,
      purchaseTicket,
      ticketPurchase,
      selectedSeats,
      selectedSeatsBooked,
      selectedSeatsBookedDatabase,
      ticketPurchaseError,
      purchaseStatus,
      purchasedTicket,
      clear,
      close,
      errors,
    };
  },
};
</script>

<style scoped>
.remove_button {
  border: 2px solid #ff072c;
  border-radius: 20px;
  background-color: #ff072c;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}
.edit_button {
  border: 2px solid #ffc107;
  border-radius: 20px;
  background-color: #ffc107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.edit_button:hover,
.edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
.right_border {
  border-right: 1px solid #ccc;
}
.seatSelect {
  cursor: pointer;
  width: 45px;
  color: #fff;
}
.seat {
  cursor: default;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
}
.seatNormal {
  cursor: pointer;
  background-color: rgb(216, 215, 215);
  width: 45px;
  color: rgb(20, 19, 19);
}
.ticket-info {
  font-size: 12px !important;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
.reset_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}
.reset_button:hover,
.reset_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
</style>
