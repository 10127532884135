<template>
  <Modal @close="closeModal">
    <template v-slot:title>Trip Pass</template>
    <template v-slot:body>
      Are you sure, you want to pass the trip? If so, you will not purchase any
      ticket!!
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Confirm"
            @click.prevent="tripPass"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref, inject } from "vue";
import store from "@/store";
export default {
  name: "TripPassModal",
  props: {
    trip: String,
  },
  setup(props, { emit }) {
    let tripId = ref(props.trip);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const tripPass = () => {
      showLoader();
      store
        .dispatch("tripPassAgentIntercity", { trip: tripId.value })
        .then(() => {
          closeModal();
          hideLoader();
        })
        .catch(() => {
          closeModal();
          hideLoader();
        });
    };
    return {
      closeModal,
      tripPass,
    };
  },
};
</script>

<style scoped></style>
