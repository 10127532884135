<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="row py-1">
          <div class="col-sm-6 col-md-4">
            <DateTimeRangePicker v-model="date.range" />
          </div>
          <div class="col-md-2 mt-md-auto mt-2">
            <button
              class="edit_table_data font_detail"
              @click="getChalanTripList"
            >
              <i class="fas fa-filter"></i
              ><span class="mx-1 bold">{{
                t("Filter", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </div>
        </div>

        <!-- <div class="col-sm-6 col-md-2 col-xxl-1">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.tripStatusBaria"
          />
        </div> -->
      </template>

      <!-- <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Admin_Coach_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template> -->
    </FilterTab>

    <Table
      v-if="$store.getters.getChalanTripList.length > 0 && summary"
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TRIP TIME & DATE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TRIP CODE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            COACH NAME
          </td>
          <td class="font_detail">
            {{ t("SEAT_TICKET", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("SEAT_AMOUNT", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("GOODS TICKET", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("GOODS_AMOUNT", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("EXTRA TICKET", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("EXTRA_AMOUNT", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TOTAL AMOUNT", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("DETAILS", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(trip, index) in $store.getters.getChalanTripList"
          :key="trip._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">
            {{ dateFormat(trip?.tripInfo?.tripDateTime) }}
          </td>
          <td class="font_detail">{{ trip?.tripInfo?.tripCode }}</td>
          <td class="font_detail">{{ trip?.tripInfo?.coach.name }}</td>
          <td class="font_detail">{{ trip?.seatTicket }}</td>
          <td class="font_detail">
            {{ trip?.seatAmount }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">{{ trip?.goodsTicket }}</td>
          <td class="font_detail">
            {{ trip?.goodsAmount }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">{{ trip?.extraTicket }}</td>
          <td class="font_detail">
            {{ trip?.extraAmount }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ trip?.extraAmount + trip?.seatAmount + trip?.goodsAmount }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            <button
              class="btn btn-primary m-1"
              @click="chalanDetails(trip._id)"
            >
              {{ t("Details", {}, { locale: $store.getters.getLang }) }}
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ seatAmountTotal }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ goodsTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ goodsAmountTotal }} Taka{{
              t("", {}, { locale: $store.getters.getLang })
            }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ extraTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ extraAmountTotal }}
            {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>

          <td class="font_detail text-white">
            {{ t("Gross Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ grossAmountTotal }}
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>
  <ChalanDetails
    v-if="chalanDetailsActive"
    :tripId="tripId"
    v-on:backToChalanWiseReport="onChildClick"
  ></ChalanDetails>
  <router-view></router-view>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { inject, watch, ref } from "vue";
import store from "@/store";
import ChalanDetails from "./ChalanDetails.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "ShipReport",
  components: {
    ChalanDetails,
  },
  emits: ["backToCoachWiseReport"],
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    const { t, locale } = useI18n();
    let chalanDetailsActive = ref(false);
    let reportActive = ref(true);
    let tripId = ref("");
    let filterActive = ref(false);
    let summary = ref(false);
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let extraTicketTotal = ref(0);
    let extraAmountTotal = ref(0);
    let goodsTicketTotal = ref(0);
    let goodsAmountTotal = ref(0);
    let grossAmountTotal = ref(0);

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let pagination = reactive({
      fromDate: date.range.start,
      toDate: date.range.end,
      status: true,
    });

    watch(
      () => date.range,
      (value) => {
        pagination.fromDate = moment(new Date(value.start)).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
        pagination.toDate = moment(new Date(value.end)).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
        // getChalanTripList();
      }
    );
    watch(
      () => pagination.status,
      (value) => {
        pagination.status = value;
        // getChalanTripList();
      }
    );

    const onChildClick = (value) => {
      reportActive.value = value;
      chalanDetailsActive.value = !value;
    };

    const chalanDetails = (id) => {
      tripId.value = id;
      chalanDetailsActive.value = true;
      reportActive.value = false;
    };

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getChalanTripList = () => {
      filterActive.value = true;

      showLoader();
      store
        .dispatch("getChalanTripList", pagination)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            seatTicketTotal.value = 0;
            seatAmountTotal.value = 0;
            extraTicketTotal.value = 0;
            extraAmountTotal.value = 0;
            goodsTicketTotal.value = 0;
            goodsAmountTotal.value = 0;
            grossAmountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getChalanTripList();
          }
        });
    };

    const sumListData = () => {
      store.getters.getChalanTripList.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;

        extraTicketTotal.value += data.extraTicket;
        extraAmountTotal.value += data.extraAmount;

        goodsTicketTotal.value += data.goodsTicket;
        goodsAmountTotal.value += data.goodsAmount;
        grossAmountTotal.value +=
          data.seatAmount + data.extraAmount + data.goodsAmount;
      });
    };

    // onBeforeMount(() => {
    //   getChalanTripList();
    // });

    return {
      t,
      locale,
      dropdownVariables,
      date,
      pagination,
      dateFormat,
      chalanDetails,
      reportActive,
      chalanDetailsActive,
      onChildClick,
      tripId,
      getChalanTripList,
      goodsTicketTotal,
      seatAmountTotal,
      goodsAmountTotal,
      extraTicketTotal,
      extraAmountTotal,
      seatTicketTotal,
      grossAmountTotal,
      summary,
    };
  },
};
</script>

<style scoped></style>
