<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <!-- <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'company'"
            v-bind:label="'Company'"
            v-bind:defaultOption="'--select company--'"
            v-model="coachWiseReport.companyId"
            v-bind:defaultValue="''"
            v-bind:dropdown="''"
          />
        </div> -->
        <div class="col-sm-6 col-md-2" v-if="coachWiseReport.companyId">
          <DropdownFilter
            v-bind:id="'coach'"
            v-bind:label="'Coach'"
            v-bind:defaultOption="'--select coach--'"
            v-model="coachWiseReport.coachId"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getCoachListForFilterOwner"
          />
        </div>
        <div class="col-sm-6 col-md-4" v-if="coachWiseReport.companyId">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div
          class="col-sm-6 col-md-2 col-xxl-1"
          v-if="coachWiseReport.companyId"
        >
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="''"
            v-model="coachWiseReport.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>

        <div
          class="col-sm-6 col-md-2 col-xxl-1"
          v-if="coachWiseReport.companyId"
        >
          <CheckboxSingle v-model="isDiscount" />
        </div>
        <div class="col-md-2 mt-auto">
          <button class="edit_table_data font_detail" @click="filterReport">
            <i class="fas fa-filter"></i
            ><span class="mx-1 bold">{{
              t("Filter", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
        </div>
      </template>
      <template v-slot:button1>
        <button
          v-if="
            $store.getters.getCoachWiseReportOwnerIntercity.length > 0 &&
            filterActive
          "
          class="edit_table_data font_detail"
          @click="
            exportExcel(
              'xlsx',
              'Admin_Coach_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">{{ t("Export", {}, { locale: $store.getters.getLang }) }}</span>
        </button>
      </template>
    </FilterTab>

    <Table
      v-if="
        $store.getters.getCoachWiseReportOwnerIntercity.length > 0 && summary
      "
      id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">{{ t("SL", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Coach Name", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Seat Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Extra Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Extra Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Goods Ticket", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Goods Amount", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ t("Details", {}, { locale: $store.getters.getLang }) }}</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(item, index) in $store.getters
            .getCoachWiseReportOwnerIntercity"
          :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item.coachName[0] }}</td>
          <td class="font_detail">{{ item.seatTicket }}</td>
          <td class="font_detail">{{ item.seatAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ item.extraTicket }}</td>
          <td class="font_detail">{{ item.extraAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>
          <td class="font_detail">{{ item.goodsTicket }}</td>
          <td class="font_detail">{{ item.goodsAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}</td>

          <td class="font_detail">
            <button
              class="btn btn-primary m-1"
              @click="seatDetails(item.coachId, item.coachName[0])"
            >
              {{ t("Seat", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              class="btn btn-secondary text-light m-1"
              @click="extraDetails(item.coachId, item.coachName[0])"
            >
              {{ t("Extra", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              class="btn btn-success m-1"
              @click="goodsDetails(item.coachId, item.coachName[0])"
            >
              {{ t("Goods", {}, { locale: $store.getters.getLang }) }}
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ seatAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ extraTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ extraAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}: {{ goodsTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}: {{ goodsAmountTotal }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>

  <GoodDetails
    v-if="goodsDetailsActive"
    :coachId="coachIdPass"
    :coachName="coachNamePass"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="coachWiseReport.discount"
    :status="parseInt(coachWiseReport.status)"
    v-on:backToCoachWiseReport="onChildClick"
  />
  <SeatDetails
    v-if="seatDetailsActive"
    :status="parseInt(coachWiseReport.status)"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="coachWiseReport.discount"
    :coachId="coachIdPass"
    :coachName="coachNamePass"
    v-on:backToCoachWiseReport="onChildClick"
  />
  <ExtraDetails
    v-if="extraDetailsActive"
    :status="parseInt(coachWiseReport.status)"
    :fromDate="date.range.start"
    :toDate="date.range.end"
    :discount="coachWiseReport.discount"
    :coachId="coachIdPass"
    :coachName="coachNamePass"
    v-on:backToCoachWiseReport="onChildClick"
  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import moment from "moment";
import { watch, onBeforeMount, ref, inject } from "vue";
import store from "@/store";
import GoodDetails from "./GoodDetails.vue";
import ExtraDetails from "./ExtraDetails.vue";
import SeatDetails from "./SeatDetails.vue";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "ShipReport",
  components: {
    DropdownFilter,
    SeatDetails,
    ExtraDetails,
    GoodDetails,
    CheckboxSingle,
  },
  emits: ["backToCoachWiseReport"],

  setup() {
    let extraDetailsActive = ref(false);
    let goodsDetailsActive = ref(false);
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let coachIdPass = ref("");
    let coachNamePass = ref("");
    let filterActive = ref(false);

    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let extraTicketTotal = ref(0);
    let extraAmountTotal = ref(0);
    let goodsTicketTotal = ref(0);
    let goodsAmountTotal = ref(0);
    let isDiscount = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const { t, locale } = useI18n();
    let summary = ref(false);

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let coachWiseReport = reactive({
      companyId: "",
      coachId: "",
      fromDate: "",
      toDate: "",
      discount: 0,
      status: 1,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => isDiscount.value,
      () => {
        isDiscount.value
          ? (coachWiseReport.discount = 1)
          : (coachWiseReport.discount = "");
      }
    );

    watch(
      () => coachWiseReport.companyId,
      (value) => {
        coachWiseReport.companyId = value;
      }
    );
    watch(
      () => coachWiseReport.coachId,
      (value) => {
        coachWiseReport.coachId = value;
      }
    );

    watch(
      () => coachWiseReport.status,
      (value) => {
        coachWiseReport.status = value;
      }
    );
    watch(
      () => date.range.start,
      (value) => {
        coachWiseReport.fromDate = moment(value).format(
          "yyyy-MM-DD" + " 00:00:00"
        );
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        coachWiseReport.toDate = moment(value).format(
          "yyyy-MM-DD" + " 23:59:59"
        );
      }
    );
    watch(
      () => coachWiseReport.companyId,
      () => {
        if (coachWiseReport.companyId !== "") {
          getAllShips();
        }
      }
    );

    const getAllShips = () => {
      store.dispatch("getAllCoachListOwner", {
        companyId: store.getters.userCompany,
      });
    };

    const getAllCompanyList = () => {
      coachWiseReport.companyId = store.getters.userCompany;
    };
    const getAllCoachReport = () => {
      showLoader();
      store
        .dispatch("getAllCoachReportOwner", coachWiseReport)
        .then((response) => {
          if (response.length === 0) {
            summary.value = false;
          } else {
            summary.value = true;
            seatTicketTotal.value = 0;
            seatAmountTotal.value = 0;
            extraTicketTotal.value = 0;
            extraAmountTotal.value = 0;
            goodsTicketTotal.value = 0;
            goodsAmountTotal.value = 0;
            sumListData();
          }
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    };
    const filterReport = () => {
      filterActive.value = true;
      getAllCoachReport();
    };

    const sumListData = () => {
      store.getters.getCoachWiseReportOwnerIntercity.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;

        extraTicketTotal.value += data.extraTicket;
        extraAmountTotal.value += data.extraAmount;

        goodsTicketTotal.value += data.goodsTicket;
        goodsAmountTotal.value += data.goodsAmount;
      });
    };

    onBeforeMount(() => {
      getAllCompanyList();
      coachWiseReport.fromDate = fromDate;
      coachWiseReport.toDate = toDate;
    });

    const extraDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      extraDetailsActive.value = true;
      goodsDetailsActive.value = false;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const goodsDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      extraDetailsActive.value = false;
      goodsDetailsActive.value = true;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const seatDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      extraDetailsActive.value = false;
      goodsDetailsActive.value = false;
      seatDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      extraDetailsActive.value = !value;
      goodsDetailsActive.value = !value;
      seatDetailsActive.value = !value;
    };

    return {
      t,
      locale,
      dropdownVariables,
      date,
      coachWiseReport,
      filterReport,
      extraDetails,
      goodsDetails,
      seatDetails,
      extraDetailsActive,
      seatDetailsActive,
      goodsDetailsActive,
      reportActive,
      coachIdPass,
      filterActive,
      onChildClick,
      coachNamePass,
      goodsTicketTotal,
      seatAmountTotal,
      goodsAmountTotal,
      extraTicketTotal,
      extraAmountTotal,
      seatTicketTotal,
      exportExcel,
      summary,
      options,
      getAllCompanyList,
      isDiscount,
    };
  },
};
</script>

<style scoped></style>
