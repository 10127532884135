<template>
  <div>
    <div class="card mx-5 my-2">
      <div
          class="card-header"
          :id="'heading' + seatPlan[0]._id"
          data-toggle="collapse"
          :data-target="'#collapse' + seatPlan[0]._id"
          aria-expanded="true"
          :aria-controls="'collapse' + seatPlan[0]._id"
      >
        <div class="row my-1">
          <div class="col-md-2 bold">
            <span
                v-for="[key, value] of Object.entries(
                $store.getters.getRouteListForFilterAdmin
              )"
                :key="key.index"
            >
              <span
                  v-if="
                  key === seatPlan[0].routeId._id || key === seatPlan[0].routeId
                "
              >
                {{ value }}
              </span>
            </span>
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-6 text-center">
                Status:
                <span class="bold">{{
                    seatPlan[0].status === true ? "Active" : "Inactive"
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <button
                v-if="collapse"
                class="header_show_button font_detail float-end"
                @click="showData(seatPlan[0].routeId._id || seatPlan[0].routeId)"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
                v-else
                class="header_show_button font_detail float-end"
                @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_edit_button font_detail float-end"
                @click="updateSeatFare()"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="header_delete_button font_detail float-end"
                @click="deleteRouteFare(seatPlan[0]._id)"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <ul
          v-if="seatPlan[0]"
          :id="'collapse' + seatPlan[0]._id"
          :aria-labelledby="'heading' + seatPlan[0]._id"
          data-parent="#accordion"
          class="collapse list-group list-group-flush"
          :class="!collapse ? 'show' : ''"
      >
        <FareModality
            :id="seatPlan[0]._id"
            :routeId="seatPlan[0].routeId._id"
            :companyId="companyId"
            @updateFareModality="updateFareModality"
        />
        <RouteFareCard
            :id="seatPlan[0]._id"
            :routeId="seatPlan[0].routeId._id"
            :companyId="companyId"
            :routeFare="routeFare"
            @updateFareModality="updateFareModality"
        />
      </ul>
    </div>
    <SeatFareCardConfirmDeleteModal
        :fareId="payload.fareId"
        v-if="deleteModal"
        v-model="deleteModal"
        @deleteSeatFare="deleteSeatFare"
    />
    <SeatFareUpdateModal
        v-if="updateModal"
        :seatFare="seatPlan[0]"
        v-model="updateModal"
    />
  </div>
</template>

<script>
import SeatFareUpdateModal from "./SeatFareUpdateModal.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import SeatFareCardConfirmDeleteModal from "./SeatFareCardConfirmDeleteModal.vue";
import { onBeforeMount, ref, inject, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { reactive } from "@vue/reactivity";
import FareModality from "./FareModality.vue";
import RouteFareCard from "./routeFareCard.vue";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "SeatClassCard",
  props: {
    seatPlanId: String,
    company: String,
  },
  components: {
    FareModality,
    RouteFareCard,
    SeatFareCardConfirmDeleteModal,
    SeatFareUpdateModal,
  },

  setup(props, { emit }) {
    let spId = ref(props.seatPlanId);
    let companyId = ref(props.company);
    let updateModal = ref(false);
    let deleteModal = ref(false);
    let collapse = ref(true);
    let seatPlan = ref({});
    let routeFare = ref("");
    const route = useRoute();
    const status = dropdownVariables.status;
    let selectedForBlocked = ref([]);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
        () => updateModal.value,
        () => {
          if (updateModal.value === false) {
            emit("getAllSeatFare");
          }
        }
    );
    let payload = reactive({
      page: 1,
      limit: 25,
      fareId: "",
      companyId: route.query.id,
    });

    const getSeatPlan = () => {
      seatPlan.value = store.getters.getFare(spId.value);
    };

    const updateSeatFare = () => {
      getSeatPlan();
      updateModal.value = true;
    };
    const showData = (routeId) => {
      collapse.value = false;
      showLoader();
      store
          .dispatch("getSequenceRouteListFilter", { routeId: routeId })
          .then(() => {
            getSeatPlan();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              showData(routeId);
            } else {
              getSeatPlan();
            }
          });
    };
    const updateFareModality = () => {
      // getSeatPlan()
      routeFare.value = Math.random();
    };
    const deleteRouteFare = (id) => {
      payload.fareId = id;
      deleteModal.value = true;
    };
    const deleteSeatFare = (id) => {
      payload.fareId = id;
      showLoader();
      store
          .dispatch("deleteSeatFare", payload)
          .then(() => {
            getSeatPlan();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              deleteSeatFare(id);
            } else {
              getSeatPlan();
            }
          });
    };
    onBeforeMount(() => {
      getSeatPlan();
    });

    return {
      status,
      seatPlan,
      spId,
      updateModal,
      companyId,
      deleteModal,
      collapse,
      Admin,
      hasPermission,
      updateSeatFare,
      deleteSeatFare,
      getSeatPlan,
      showData,
      selectedForBlocked,
      updateFareModality,
      deleteRouteFare,
      routeFare,
      payload,
    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>