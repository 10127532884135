<template>
  <div class="row m-0">
    <div
      class="card col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1"
    >
      <div id="print_div">
        <table
          width="100%"
          cellspacing="0"
          cellpadding="0"
          style="border-collapse: collapse; border-bottom: 1px solid #ddd"
        >
          <tr>
            <th style="text-align: left; line-height: 7px" colspan="3">
              <h2>{{ purchasedTicket.companyName }}</h2>
              <p>Complain Number: {{ purchasedTicket.complainNumber }}</p>
              <p>Agent Name: {{ purchasedTicket.issuedBy }}</p>
            </th>
            <th
              style="border: 1px solid #ddd; line-height: 10px; padding: 4px"
              colspan="3"
            >
              <h5>QR Code & Scan Code</h5>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
                alt=""
                style="height: 100px"
              />
              <p>15289643565</p>
            </th>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2">
              <p>
                PNR Number: <span>{{ purchasedTicket.pnrCode }}</span>
              </p>
            </td>
            <td style="text-align: right" colspan="4">
              <p>
                Print Date & Time:
                <span>{{ dateFormat(purchasedTicket.ticketDateTime) }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="6">
              <p>
                Seat Numbers: <span>{{ purchasedTicket.seatNumbers }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="6">
              <p>
                Customer Name: <span>{{ purchasedTicket.passengerName }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="6">
              <p>
                Mobile Number: <span>{{ purchasedTicket.passengerPhone }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="6">
              <p>
                Address: <span>{{ purchasedTicket.passengerAddress }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="3">
              <p>
                Ship Name: <span>{{ purchasedTicket.shipName }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="3">
              <p>
                Trip Date:
                <span>{{ tripDateFormat(purchasedTicket.tripDateTime) }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="3">
              <p>
                From: <span>{{ purchasedTicket.boardingPlace }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="3">
              <p>
                To: <span>{{ purchasedTicket.droppingPoint }}</span>
              </p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2">
              <p>
                <b>Paid Fare:</b> <span>{{ purchasedTicket.paid }}</span>
              </p>
            </td>
            <!-- <td style="text-align: left" colspan="2">
                            <p>
                                <b>Due Fare:</b> <span>{{ purchasedTicket.dues }}</span>
                            </p>
                        </td> -->
            <td style="text-align: left" colspan="2">
              <p>
                <b>Total Fare:</b> <span>{{ purchasedTicket.payable }}</span>
              </p>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <div>
          <h3 style="text-align: center">
            কাউন্টার থেকে টিকেট সংগ্রহ করতে এই ইনভয়েসটি সঙ্গে রাখুন।
          </h3>
          <h4>নির্দেশনাঃ</h4>
          <ul>
            <li>জাহাজ ছাড়ার ৩০ মিনিট পূর্বে জাহাজে উপস্থিত থাকবেন।</li>
            <li>
              প্রতি আসন একজন যাত্রীর নির্ধারিত, অতিরিক্ত যাত্রীর জন্য ডেকের
              টিকেট নিতে হবে।
            </li>
            <li>
              মালামাল নিজ দায়িত্বে রাখবেন, মালামাল হারানো বা চুরি হওয়ার জন্য
              কর্তৃপক্ষ দায়ী নয়।
            </li>
            <li>
              অতিরিক্ত যাত্রী হয়ে জাহাজে উঠবেন না, অপরিচিত লোকের দেওয়া কিছু
              খাবেন না।
            </li>
            <li>ময়লা আবর্জনা নির্দিষ্ট স্থানে ফেলুন।</li>
            <li>আপনার অভিযোগ ও সু-পরামর্শ অভিযোগ বক্সে ফেলবেন।</li>
          </ul>

          <div
            style="
              border: 1px solid #ddd;
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <a style="text-decoration: none" href="https://jatri.co"
              >www.jatri.co</a
            >
            <p>সার্বিক সহযোগিতায় যাত্রী সার্ভিস লিমিটেড</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">ACTIONS</span>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <p class="text-center font_detail m-0">GO BACK</p>
            <button class="cancel_button w-100" @click="close">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <p class="text-center font_detail m-0">PRINT</p>
            <p style="display: none" id="purchasedTicket">
              {{ purchasedTicket }}
            </p>
            <p style="display: none" id="type">{{ type }}</p>
            <button class="confirm_button w-100" @click="PrintDiv">
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--onclick="PrintAndroid(document.getElementById('purchasedTicket').innerHTML,document.getElementById('type').innerHTML)"-->
<script>
import { ref } from "vue";
import moment from "moment";

export default {
  name: "TicketPrintPreviewForAgent",
  props: {
    ticket: Object,
    ticketType: String,
  },
  setup(props, { emit }) {
    let purchasedTicket = ref(props.ticket);
    let type = ref(props.ticketType);

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const tripDateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const close = () => {
      emit("close");
    };

    const PrintDiv = () => {
      let divContents = window.document.getElementById("print_div").innerHTML;

      let printWindow = window.open();
      printWindow.document.write("<html>");
      printWindow.document.write("<body>");
      printWindow.document.write(divContents);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      setTimeout(function () {
        printWindow.print();
        printWindow.close();
      }, 200);
    };
    return {
      purchasedTicket,
      type,
      dateFormat,
      tripDateFormat,
      close,
      PrintDiv,
    };
  },
};
</script>

<style scoped>
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
