import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import apiResponse from "@/modules/apiResponse";
const {successResponse, errorResponse} = apiResponse();


const {hasValidToken, getToken} = tokenCookie();

const state = {
    filter: {
        layouts: {},
        companyLayouts: {},
    },
    layouts: [],
    layoutsCompany: [],
    layout: {},

};
const getters = {
    getAllTicketLayoutsInFilter: (state) => state.filter.companyLayouts,

    getTicketLayoutListCompany: (state) => state.layoutsCompany,
    getTicketLayoutsInFilter: (state) => state.filter.layouts,
    getTicketLayout: (state) => state.layout,
    getTicketLayoutList: (state) => state.layouts,
};
const actions = {

    async getAllTicketLayoutByCompany({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_TICKET_LAYOUT_BY_COMPANY, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        let layoutList = {};
                        response.data.ticketLayouts.forEach((layout) => {
                            layoutList[layout._id] = layout.name;
                        });
                        commit("setCompanyTicketLayoutInFilter", layoutList)
                        commit("setTicketLayoutsCompany", response.data.ticketLayouts)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async updateTicketLayoutByCompany(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_TICKET_LAYOUT_BY_COMPANY, payload, {
                        headers: header,
                    })
                    .then((success) => {
                        successResponse(success);
                        resolve()
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getTicketLayoutByCompany({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TICKET_LAYOUT_BY_COMPANY, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        commit("setTicketLayoutsCompany", response.data.ticketLayouts)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async getAllTicketLayout({commit}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_TICKET_LAYOUTS, {
                        headers: header,
                    })
                    .then((response) => {
                        let layoutList = {};
                        response.data.ticketLayouts.forEach((layout) => {
                            layoutList[layout._id] = layout.name;
                        });
                        commit("setTicketLayoutInFilter", layoutList)
                        commit("setTicketLayoutList", response.data.ticketLayouts)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async getTicketLayout({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TICKET_LAYOUT, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        commit("setTicketLayout", response.data.ticketLayout)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async updateTicketLayout(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_TICKET_LAYOUT, payload, {
                        headers: header,
                        params: {ticketLayoutId: payload._id}
                    })
                    .then((success) => {
                        successResponse(success);
                        resolve()
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
};
const mutations = {
    setCompanyTicketLayoutInFilter: (state, ticketLayouts) =>
        (state.filter.companyLayouts = ticketLayouts),
    setTicketLayoutsCompany: (state, ticketLayouts) =>
        (state.layoutsCompany = ticketLayouts),
    setTicketLayout: (state, ticketLayout) =>
        (state.layout = ticketLayout),
    setTicketLayoutList: (state, ticketLayouts) =>
        (state.layouts = ticketLayouts),
    setTicketLayoutInFilter: (state, ticketLayouts) =>
        (state.filter.layouts = ticketLayouts),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
