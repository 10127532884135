import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
// import { useRouter } from "vue-router";


const {hasValidToken,getToken} = tokenCookie();
const { errorResponse, successResponse } = apiResponse();

const state = {
    dashboardTickets: [],
    updatePasswordMessage: ''
};
const getters = {
    getDashboardTickets: (state) => state.dashboardTickets, 
};
const actions = {
    async getDashboardTickets({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDashboardTickets", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_DASHBOARD_WITH_QR, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDashboardTickets", data.ticket);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    // Password update for all users
    async updateUserPassword({commit}, payload) { 
        if (await hasValidToken()) {
            commit("setUpdatePasswordMessage", "");
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.UPDATE_USER_PASSWORD, payload, { 
                    headers: header
                })
                .then((res) => {  
                    if (res.status === 200) { 
                        resolve({'success': 'success'});                    
                        successResponse(res);
                        setTimeout(() => { 
                            window.location.href = '/counterman/profile';
                        }, 1500);
                        // const router = useRouter();
                        // router.push('/counterman/profile');
                    } 
                })
                .catch(error => {
                    console.log(error);
                    if (error.status || error.response.status) {
                        reject(errorResponse(error))
                    }
                });
            });
        }
    },

};
const mutations = {
    setDashboardTickets: (state, tickets) => (state.dashboardTickets = tickets),
    setUpdatePasswordMessage: (state, message) => (state.updatePasswordMessage = message),
};

export default {
    state,
    getters,
    actions,
    mutations
};