<template>
  <div v-if="hasPermission(Admin.paymentSettlement)">
    <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'company'"
              v-bind:label="'Company'"
              v-bind:defaultOption="'--select company--'"
              v-model="pagination.company"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DropdownFilter
              v-bind:id="'counter'"
              v-bind:label="'Counter'"
              v-bind:defaultOption="'--select counter--'"
              v-model="pagination.counter"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCounterListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DropdownFilter
              v-bind:id="'counterman'"
              v-bind:label="'Counterman'"
              v-bind:defaultOption="'--select counterman--'"
              v-model="pagination.user"
              v-bind:defaultValue="''"
              v-bind:dropdown="countermenFilterList"
          />
        </div>
        <div
            class="col-sm-6 col-md-2"
            v-if="pagination.company"
        >
          <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:defaultOption="'ALL'"
              v-model="pagination.withdrawStatus"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.WithdrawStatus"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            pagination.company &&
            qrHistoryList?.docs?.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Payment_Settlement_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="pagination.company === '' && selectCompany"
    >
      Please,Select a
      <a href="#" class="alert-link">company</a> from dropdown
    </div>
    <Table
        v-if="
        pagination.company !== '' &&
        filterActive &&
        qrHistoryList?.docs?.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Counterman Info</td>
          <td class="font_detail">Counter</td>
          <td class="font_detail">Date & Time</td>
          <td class="font_detail">No. of Tickets</td>
          <td class="font_detail">Withdraw Amount</td>
          <td class="font_detail">Payment Method</td>
          <td class="font_detail">Account Number</td>
          <td class="font_detail">Status</td>
          <td class="font_detail">Action</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in qrHistoryList.docs"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 + (pagination.page - 1) * pagination.limit }}</td>
          <td class="font_detail">{{ item?.userId?.name }} <br />
            {{ item?.userId?.phone }}
          </td>
          <td class="font_detail">{{ item?.counter?.name }}</td>
          <td class="font_detail">{{ dateFormat(item?.createdAt) }}</td>
          <td class="font_detail">{{ item?.numberOfTickets }}</td>
          <td class="font_detail">{{ item?.amount }}</td>
          <td class="font_detail">{{ item?.paymentMethod?.gateway }}</td>
          <td class="font_detail">{{ item?.paymentMethod?.accountNumber }}</td>
          <td class="font_detail">{{ item?.withdrawStatus }}</td>
          <td class="font_detail">
            <button
                class="btn btn-primary m-1"
                @click="details(item)"
            >
              Details
            </button>
            <template v-if="item?.withdrawStatus === dropdownVariables.WithdrawStatus.PENDING">
              <button
                  class="btn btn-success m-1"
                  @click="settlementStatusUpdateModal('adminPaymentSettlementApprove', 'approve', item._id)"
              >
                Success
              </button>
              <button
                  class="btn btn-secondary text-light m-1"
                  @click="settlementStatusUpdateModal('adminPaymentSettlementReject', 'cancel', item._id)"
              >
                Cancel
              </button>
            </template>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">Total Tickets: {{sumTotalData.calculateTotalTicket}}</td>
          <td class="font_detail text-white">Total Amount: {{sumTotalData.calculateTotalAmount}} Taka</td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
            v-model="pagination.page"
            :records="qrHistoryList.totalDocs"
            :per-page="pagination.limit"
            @paginate="getPaymentSettlement"
            :options="options"
        />
      </template>
    </Table>
  </div>
  </div>
  <PaymentSettlementStatusModal
      v-if="activeModal"
      @close="close"
      @getPaymentSettlement="getPaymentSettlement"
      @settlementStatusUpdate="settlementStatusUpdate"
      :statusUpdateSelectedData="statusUpdateSelectedData"
  />

  <PaymentSettlementReportDetails
    v-if="detailsActive"
    :reportDetails="reportDetails"
    v-on:backToSettlementReport="onChildClick"

  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import {onBeforeMount, ref, inject, watch, reactive, computed} from "vue";
import store from "@/store";
import toast from "@/modules/Toast";
const {infoToast} = toast();
import PaymentSettlementReportDetails from "./details.vue";
import PaymentSettlementStatusModal from "./PaymentSettlementStatusModal.vue";
import moment from "moment";
import router from "@/router";
import web from "@/urls/web";
import roles from "@/modules/roles";
import permission from "@/modules/permission";

export default {
  name: "PaymentSettlementReport",
  components: {
    DropdownFilter,
    PaymentSettlementReportDetails,
    PaymentSettlementStatusModal
  },
  setup() {
    const { hasPermission } = permission();
    const { Admin } = roles();
    let selectCompany = ref(false);
    let filterActive = ref(false);
    let qrHistoryList = ref({});
    const countermenFilterList = ref([])
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const detailsActive = ref(false);
    let reportActive = ref(true);
    let reportDetails = ref({});
    const activeModal = ref(false);
    const statusUpdateSelectedData = ref({});
    let pagination = reactive({
      page: 1,
      limit: 25,
      company: "",
      counter: "",
      user: "",
      withdrawStatus: dropdownVariables.WithdrawStatus.PENDING
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const getPaymentSettlement = () => {
      qrHistoryList.value = [];
      showLoader();
      store
          .dispatch("getPaymentSettlementList", pagination)
          .then((res) => {
            if(res.data?.docs?.length === 0){
              infoToast('No Data Available')
            }
            qrHistoryList.value = res.data
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getPaymentSettlement();
            }
          })
          .finally(() => hideLoader());
    };


    const getAllCompanyList = () => {
      showLoader();
      store.dispatch("getAllCompanyList").then(() => hideLoader())
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCompanyList();
            }
          });
    };

    const getAllCounter = () => {
      showLoader();
      store
          .dispatch("getCounterListForFilter", {
            companyId: pagination.company,
          })
          .finally(() => {
            hideLoader();
          })
    };


    const getAllCounterman = () => {
      showLoader();
      store.dispatch("getAllCountermenFilterList", {
        company: pagination.company,
        counter: pagination.counter,
      })
          .then((res) => {
            countermenFilterList.value = res
          })
          .catch((error) => {
            if (error.status === 401) {
              getAllCompanyList();
            }
          })
          .finally(() => {
            hideLoader();
          });
    };

    const filterReport = () => {
      selectCompany.value = true;
      if (pagination.company !== "") {
        filterActive.value = true;
        pagination.page = 1;
        getPaymentSettlement()
      }
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, true)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const sumTotalData = computed(() => {
      let calculateTotalAmount = 0;
      let calculateTotalTicket = 0;
      qrHistoryList.value.docs?.map((history) => {
        calculateTotalAmount += history?.amount
        calculateTotalTicket += history?.numberOfTickets
      })
      return { calculateTotalTicket, calculateTotalAmount };
    })

    const settlementStatusUpdate = () => {
      showLoader();
      store.dispatch(statusUpdateSelectedData.value.statusType, {_id: statusUpdateSelectedData.value.itemId})
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            hideLoader();
            getPaymentSettlement()
          });
    }

    const settlementStatusUpdateModal = (statusType, title, itemId) =>  {
      activeModal.value = true;
      statusUpdateSelectedData.value.statusType = statusType
      statusUpdateSelectedData.value.title = title
      statusUpdateSelectedData.value.itemId = itemId
    }

    const close = () => {
      activeModal.value = false;
      statusUpdateSelectedData.value = {};
    };



    const details = (details) => {
      reportDetails.value = details
      detailsActive.value = true;
      reportActive.value = false;
    };

    watch(
        () => pagination.company,
        () => {
          pagination.counter  = ""
          pagination.user  = ""
          if (pagination.company !== "") {
            getAllCounter();
          }
        }
    );

    watch(
        () => pagination.counter,
        () => {
          pagination.user  = ""
          if (pagination.company !== "" && pagination.counter  !== "") {
            getAllCounterman();
          }
        }
    );

    const onChildClick = (value) => {
      reportActive.value = value;
      detailsActive.value = !value;
    };

    onBeforeMount(() => {
      getAllCompanyList();
    });

    watch(
        () => store.getters.userRoles,
        (value) => {
          if (!value?.includes(Admin.paymentSettlement)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );

    return {
      dropdownVariables,
      getAllCompanyList,
      getAllCounterman,
      pagination,
      filterReport,
      qrHistoryList,
      countermenFilterList,
      selectCompany,
      filterActive,
      exportExcel,
      dateTimeFormat,
      getPaymentSettlement,
      options,
      details,
      PaymentSettlementReportDetails,
      detailsActive,
      reportActive,
      reportDetails,
      onChildClick,
      sumTotalData,
      activeModal,
      settlementStatusUpdateModal,
      close,
      statusUpdateSelectedData,
      settlementStatusUpdate,
      dateFormat,
      Admin,
      hasPermission,
    };
  },
};
</script>

<style scoped></style>