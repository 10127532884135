<template>
  <!-- <div>
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.status"
          />
        </div>
      </template>
    </Filter> -->
  <div>
    <Table v-if="$store.getters.getAllCoachesCounterAgentIntercity">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">REGISTRATION NUMBER</td>
          <td class="font_detail">SEAT PLAN</td>
          <td class="font_detail">STATUS</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(coach, index) in $store.getters
            .getAllCoachesCounterAgentIntercity"
          :key="index"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ coach?.name }}</td>
          <td class="font_detail">{{ coach?.registrationNumber }}</td>
          <td class="font_detail">{{ coach?.seatPlanId?.name }}</td>
          <td>
            <span v-if="coach.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalCoachesCounterAgentIntercity"
          :per-page="pagination.limit"
          @paginate="getAllCoachCounter"
          :options="options"
        />
      </template>
    </Table>
  </div>
  <!-- </div> -->
</template>

<script>
import { onBeforeMount, inject, watch } from "vue";
import store from "@/store";
import moment from "moment";
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
export default {
  name: "Coach",
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let pagination = reactive({
      page: 1,
      limit: 25,
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "1",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    watch(
      () => pagination.status,
      () => {
        getAllCoachCounter();
      }
    );
    watch(
      () => pagination.date,
      () => {
        getAllCoachCounter();
      }
    );
    const getAllCoachCounter = () => {
      showLoader();
      store
        .dispatch("getAllCoachCounterAgentIntercity", pagination)
        .then(() => {
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    };
    onBeforeMount(() => {
      getAllCoachCounter();
    });
    return {
      pagination,
      dropdownVariables,
      options,
      getAllCoachCounter,
    };
  },
};
</script>
