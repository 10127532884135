<template>
  <div class="row px-0 mx-auto">
    <span class="fw-bold">{{ t("SEAT INFORMATION", {}, { locale: selectedLanguage })}}</span>
    <div class="card card-body">
      <div class="row">
        <div class="col-md-12">

          <p class="text-center font_detail m-0">
            {{ t("SEATS", {}, { locale: selectedLanguage }) }}
            <span class="d-md-none">({{ seats.length || 0 }})</span>
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">#{{ seats.map(a => Object.values(a.seat_no).join('')).join(', ') }}</span>
          </div>
          <p class="text-center font_detail m-0 d-md-block d-none">
            {{t("Total Seats", {}, { locale: selectedLanguage }) }}: {{ seats.length || 0 }}
          </p>
        </div>
        <div class="col-4 col-md-2 my-1 my-md-0">
          <p class="text-center font_detail m-0">
            {{ t("FARE(TK)", {}, { locale: selectedLanguage }) }}
          </p>
          <input
              v-if="fareType === 'predefined' || fareType === 'trip_fare'"
              :disabled="!allowNetPayEditable"
              v-model="ticketAccountDetails.totalAmount"
              type="Number"
              min="0"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
          <input
              v-else
              v-model="ticketAccountDetails.inputFare"
              type="Number"
              min="0"
              @input="manageInputFare"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
        </div>
        <div
            v-if="hasPermission(Counterman.fareDiscount)"
            class="col-4 my-1 my-md-0 col-md-2"
        >
          <p class="text-center font_detail m-0">
            {{ t("DISCOUNT", {}, { locale: selectedLanguage }) }}
          </p>
          <input
              v-model="ticketAccountDetails.discount"
              type="number"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
          <p class="text-danger" v-if="discountErrorMsg">
            {{ t("Discount_Fare", {}, { locale: selectedLanguage })  }}
          </p>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2">
          <p class="text-center font_detail m-0">
            {{ t("PAID(TK)", {}, { locale: selectedLanguage }) }}
          </p>
          <input
              v-model="ticketAccountDetails.paid"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              type="number"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2">
          <p class="text-center font_detail m-0">
            {{ t("PAYABLE(TK)", {}, { locale: selectedLanguage }) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
              <span class="orange-bold-text">{{ ticketAccountDetails.payable || 0 }}
              </span>
          </div>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2">
          <p class="text-center font_detail m-0">
            {{ t("DUE(TK)", {}, { locale: selectedLanguage }) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.due }}</span>
          </div>
        </div>
        <div class="col-4 my-1 my-md-0 col-md-2">
          <p class="text-center font_detail m-0">
            {{
              t("REFUND(TK)", {}, { locale: selectedLanguage })
            }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.refund }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {computed, watch, reactive, ref} from "vue";
import store from "@/store";
import roles from "@/modules/roles";
import permission from "@/modules/permission";

const { hasPermission } = permission();
const { Counterman } = roles();

export default {
  name: "SeatInformationMobile",
  props: {
    singleTripDetails: Object,
  },
  setup(props) {
    const {t, locale} = useI18n();
    const discountErrorMsg = ref('');
    let ticketAccountDetails = reactive({
      inputFare: '',
      totalAmount: 0,
      fare: 0,
      discount: 0,
      payable: 0,
      paid: 0,
      due: 0,
      refund: 0,
    });

    const selectedLanguage = computed(() => store.getters.getLang)
    const seatFares =  computed(() => store.getters.getSeatFare);
    const seats = seatFares;
    let backupDiscount = 0;
    const fareType = store.getters.userCompanyData.fareType;
    const allowNetPayEditable = store.getters.allowNetPayEdit;
    const trip = computed(() => props.singleTripDetails)

    watch(() => store.getters.getSeatResetStatus, () => {
      if(store.getters.getSeatResetStatus === true) {
        ticketAccountDetails.inputFare = '';
        ticketAccountDetails.totalAmount = 0;
        ticketAccountDetails.discount = 0;
        ticketAccountDetails.fare = 0;
        ticketAccountDetails.payable = 0;
        ticketAccountDetails.paid = 0;
        ticketAccountDetails.due = 0;
        ticketAccountDetails.refund = 0;
        store.commit("setSelectedSeatFareTotal", 0)
      }
    })

    watch(()=> seatFares, ()=> {
      if(fareType === 'predefined' || fareType === 'trip_fare') {
        ticketAccountDetails.fare = computed(() => store.getters.getSelectedSeatFareTotal).value
      }
    }, {deep:true});

    //when input for instant fare
    watch(()=> ticketAccountDetails.inputFare, () => {
      ticketAccountDetails.fare = ticketAccountDetails.inputFare !== '' ? parseInt(ticketAccountDetails.inputFare) : 0;
      //store.commit("setSeatFare", [parseInt(ticketAccountDetails.inputFare)])
      store.commit("setSelectedSeatFareTotal", parseInt(ticketAccountDetails.inputFare))
    });

    //when fare editable
    watch(()=> ticketAccountDetails.totalAmount, () => {
      ticketAccountDetails.fare = ticketAccountDetails.totalAmount
    });

    //fare changes effect
    watch(()=> ticketAccountDetails.fare, () => {
      checkDiscountError();
      ticketAccountDetails.totalAmount = ticketAccountDetails.fare
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    });

    //discount changes effect
    watch(()=> ticketAccountDetails.discount, () => {
      checkDiscountError();
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    })

    //paid changes effect
    watch(()=> ticketAccountDetails.paid, () => {
      if(ticketAccountDetails.paid > ticketAccountDetails.payable) {
        ticketAccountDetails.refund = ticketAccountDetails.paid - ticketAccountDetails.payable
        ticketAccountDetails.due = 0
      } else {
        ticketAccountDetails.due = ticketAccountDetails.payable - ticketAccountDetails.paid
        ticketAccountDetails.refund = 0;
      }
    })

    watch(() => seats, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    watch(() => ticketAccountDetails, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    const manageInputFare = (event) => {
      if (event.target.value.length > 5){
        ticketAccountDetails.inputFare = event.target.value.slice(0, 5)
      }else {
        ticketAccountDetails.inputFare = event.target.value
      }
    }

    //Discount Error
    const checkDiscountError = () => {
      if(ticketAccountDetails.discount > ticketAccountDetails.fare) {
        ticketAccountDetails.discount = backupDiscount;
        discountErrorMsg.value = "Discount amount can't be greater than net fare";
        return true;
      } else {
        backupDiscount = ticketAccountDetails.discount;
        discountErrorMsg.value = ''
      }
    }

    return {
      t,
      locale,
      selectedLanguage,
      seats,
      Counterman,
      hasPermission,
      ticketAccountDetails,
      seatFares,
      fareType,
      allowNetPayEditable,
      discountErrorMsg,
      trip,
      manageInputFare,
    }
  }
}
</script>

<style scoped>
.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}
</style>
