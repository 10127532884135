import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import apiResponse from "@/modules/apiResponse";


const {hasValidToken, getToken} = tokenCookie();
const {errorResponse, successResponse} = apiResponse();
const state = {};
const getters = {};

const actions = {

    async getQRTicketsList(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.ADMIN_QR_TICKETS_LIST, {
                    params: payload,
                    headers: header
                })
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async adminQRTicketCancel(_, identifier) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADMIN_QR_TICKET_CANCEL, identifier, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
