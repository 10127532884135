<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Agent</h6></template> -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-if="hasPermission(Owner.agentWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i
          ><span class="mx-1 bold">{{
            t("Add_new", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getOwnerServicesAgents">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Mobile", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Email", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td v-if="hasPermission(Owner.agentWrite)" class="font_detail">
            {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(agent, index) in $store.getters.getOwnerServicesAgents"
          :key="agent._id"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ agent.name }}</td>
          <td class="font_detail">{{ agent.phone }}</td>
          <td class="font_detail">{{ agent.email }}</td>

          <td>
            <span v-if="agent.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Owner.agentWrite)">
            <button
              v-if="hasPermission(Owner.agentBalanceRecharge)"
              class="edit_table_data font_detail recharge_button"
              @click="rechargeBalance(agent._id)"
            >
              <i class="fas fa-edit"></i
              ><span class="mx-1">{{
                t("Recharge", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
            <button
              v-if="hasPermission(Owner.agentWrite)"
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(agent._id)"
            >
              <i class="fas fa-edit"></i
              ><span class="mx-1">{{
                t("Edit", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalAgents"
          :per-page="pagination.limit"
          @paginate="getAgentList"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <AgentAddUpdateModal
        v-model="showModal"
        @getAllAgents="getAgentList"
        :agentId="agentId"
        :companyId="pagination.companyId"
      />
    </div>
    <div v-if="showRechargeModal">
      <RechargeModal
        v-model="showRechargeModal"
        @getAllAgents="getAgentList"
        :agentId="agentId"
        :companyId="pagination.companyId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import AgentAddUpdateModal from "./AgentAddUpdateModal.vue";
import RechargeModal from "./RechargeModal";

import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
const { hasPermission } = permission();
const { Owner } = roles();
export default {
  name: "Counterman",
  components: { AgentAddUpdateModal, RechargeModal },

  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const route = useRoute();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const agentId = ref("");
    const showModal = ref(false);
    const showRechargeModal = ref(false);
    const { t, locale } = useI18n();

    const getAgentList = () => {
      showLoader();
      store
        .dispatch("getOwnerServicesAgentsByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          if (error.status === 401) {
            getAgentList();
          }
        });
    };
    // const goBack = () => {
    //   emit("toggle")
    // };
    const addModal = () => {
      agentId.value = "";
      showModal.value = true;
    };
    const updateModal = (value) => {
      agentId.value = value;
      showModal.value = true;
    };
    const rechargeBalance = (value) => {
      agentId.value = value;
      showRechargeModal.value = true;
    };
    onBeforeMount(() => {
      pagination.companyId = route.query.id;
      getAgentList();
    });

    return {
      t,
      locale,
      dropdownVariables,
      pagination,
      options,
      showModal,
      agentId,
      Owner,
      hasPermission,
      addModal,
      getAgentList,
      updateModal,
      rechargeBalance,
      showRechargeModal,
    };
  },
};
</script>

<style scoped>
.recharge_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.recharge_button:hover,
.recharge_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
.bold {
  font-weight: bold;
}
</style>
