<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
        t(title, {}, {locale: $store.getters.getLang})
      }}
    </template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
              v-model="counterData.name"
              v-bind:placeholder="'Enter Name'"
              v-bind:label="'Name'"
              v-bind:id="'name'"
              v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>

        <!-- <div class="form-group row my-1">
          <ModalDropdown
            v-if="$store.getters.getStoppagesOwner"
            v-model="counterData.preferredStoppage"
            v-bind:id="'stoppageId'"
            v-bind:label="'Preferred'"
            v-bind:dropdown="$store.getters.getStoppagesOwner"
            v-bind:defaultOption="'--select preferred stoppage--'"
            v-bind:defaultValue="''"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.preferredStoppage"
                v-bind:error="errors.preferredStoppage"
              />
            </template>
          </ModalDropdown>
        </div> -->

        <div class="form-group row my-1">
          <div class="col-sm-3 col-form-label font_detail">
            <label>Preferred Stoppages:</label>
          </div>
          <div class="col-sm-9">
            <Multiselect
                placeholder="Select Preferred Stoppages"
                v-if="$store.getters.getStoppagesOwner"
                v-model="counterData.preferredStoppage"
                :options="$store.getters.getStoppagesOwner"
                id="multiselectId"
                mode="tags"
                :searchable="true"
                :createTag="true"
                class="col font_detail w-100"
            />
            <Error
                v-if="errors.preferredStoppage"
                v-bind:error="errors.preferredStoppage"
            />
          </div>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="showPreferredBoarding"
              v-model="counterData.preferredBoardingPlace"
              v-bind:id="'boardingPointId'"
              v-bind:label="'Preferred Boardingpoint'"
              v-bind:dropdown="preferredBoarding"
              v-bind:defaultOption="'--select preferred boardingpoint--'"
              v-bind:defaultValue="''"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.preferredBoardingPlace"
                  v-bind:error="errors.preferredBoardingPlace"
              />
            </template>
          </ModalDropdown>
        </div>
        <!-- <div class="form-group row my-1">
          <ModalDropdown
            v-if="showPreferredBoarding"
            v-model="counterData.preferredDroppingPoint"
            v-bind:id="'droppingPointId'"
            v-bind:label="'Preferred droppingpoint'"
            v-bind:dropdown="preferredDropping"
            v-bind:defaultOption="'--select preferred droppingpoint--'"
            v-bind:defaultValue="''"
          >
          </ModalDropdown>
        </div> -->

        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-model="counterData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
          ></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-model="counterData.sequence"
              v-bind:placeholder="'Enter Sequence'"
              v-bind:label="'Sequence'"
              v-bind:id="'sequence'"
              v-bind:type="'number'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.sequence"
                  v-bind:error="errors.sequence"
              />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              v-if="!counterId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addStoppage"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateStoppage"
            /> -->

            <button
                v-if="!counterId"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addStoppage"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
            <button
                v-else
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateStoppage"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useI18n} from "vue-i18n";
import Multiselect from "@vueform/multiselect";

export default {
  name: "CounterAddUpdateModal",
  props: {
    counter: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, {emit}) {
    let counterData = reactive({
      id: "",
      companyId: "",
      preferredStoppage: [],
      preferredBoardingPlace: "",
      preferredDroppingPoint: "",
      name: "",
      status: 1,
      sequence: "",
    });
    let {requiredValidation, validNumberValidation, errors} =
        useInputValidation();
    const counterId = ref(props.counter);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;

    const title = ref("Add Counter");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const {t, locale} = useI18n();
    const showPreferredBoarding = ref(false);
    const preferredBoarding = ref([]);
    const preferredDropping = ref([]);

    watch(
        () => counterData.name,
        () => {
          requiredValidation("name", counterData.name);
        }
    );
    watch(
        () => counterData.sequence,
        () => {
          validNumberValidation("sequence", counterData.sequence);
        }
    );
    watch(
        () => counterData.preferredStoppage,
        () => {
          requiredValidation("preferredStoppage", counterData.preferredStoppage);
        }
    );
    watch(
        () => counterData.preferredBoardingPlace,
        () => {
          requiredValidation("preferredBoardingPlace", counterData.preferredBoardingPlace.toString());
        }
    );

    watch(
        () => counterData.preferredStoppage,
        () => {
          preferredBoarding.value = [];
          preferredDropping.value = [];
          if (
              counterData.preferredStoppage &&
              counterData.preferredStoppage.length > 0
          ) {
            counterData.preferredStoppage.map((preferredStoppage) => {
              store.getters.getOwnerServicesStoppagesNew.map((stoppage) => {
                if (stoppage._id === preferredStoppage) {
                  preferredBoarding.value = [
                    ...preferredBoarding.value,
                    ...stoppage.boardingPlace,
                  ];
                  preferredDropping.value = [
                    ...preferredDropping.value,
                    ...stoppage.droppingPoint,
                  ];
                }
              });
            });

            // store.getters.getOwnerServicesStoppagesNew.map((item) => {
            //   if (counterData.preferredStoppage.includes(item._id)) {
            //     preferredBoarding.value = [
            //       ...preferredBoarding.value,
            //       ...item.boardingPlace,
            //     ];
            //     preferredDropping.value = [
            //       ...preferredDropping.value,
            //       ...item.droppingPoint,
            //     ];
            //   }
            // });

            showPreferredBoarding.value = true;
          }
        }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", counterData.name);
      validNumberValidation("sequence", counterData.sequence);
      requiredValidation("preferredStoppage", counterData.preferredStoppage);
      requiredValidation("preferredBoardingPlace", counterData.preferredBoardingPlace.toString());

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addStoppage = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addCounterOwner", counterData)
            .then(() => {
              hideLoader();
              emit("getAllCounters");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addStoppage();
              }
            });
      }
    };
    const updateStoppage = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateCounterOwner", counterData)
            .then(() => {
              hideLoader();
              emit("getAllCounters");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateStoppage();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (counterId.value !== "") {
        const counterDetails = store.getters.getCounterOwner(counterId.value);
        title.value = "Update Counter";
        counterData.id = counterDetails[0]._id;
        counterData.name = counterDetails[0].name;
        counterData.preferredStoppage = counterDetails[0].preferredStoppage;
        counterData.preferredBoardingPlace =
            counterDetails[0].preferredBoardingPlace;
        counterData.status = counterDetails[0].status ? 1 : 0;
        counterData.sequence = counterDetails[0].sequence;
      }
      counterData.companyId = companyId.value;
    });

    return {
      t,
      locale,
      counterId,
      counterData,
      errors,
      status,
      title,
      closeModal,
      addStoppage,
      updateStoppage,
      showPreferredBoarding,
      preferredBoarding,
      preferredDropping,
    };
  },
};
</script>

<style scoped></style>