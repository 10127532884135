<template>
  <div class="row  custom-font my-auto">
    <div class="d-flex justify-content-start mb-1 col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SOLD_SELF_MALE"></div><div class="color-line-height">sold self male</div></div>
    <div class="d-flex justify-content-start mb-1 col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SOLD_OTHER_MALE"></div><div class="color-line-height">sold other male</div></div>
    <div class="d-flex justify-content-start col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SOLD_DT"></div><div class="color-line-height">sold online</div></div>
    <div class="d-flex justify-content-start mb-1 col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SOLD_SELF_FEMALE"></div><div class="color-line-height">sold self female</div></div>
    <div class="d-flex justify-content-start col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SOLD_OTHER_FEMALE"></div><div class="color-line-height">sold other female</div></div>
<!--    <div class="d-flex justify-content-start col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.SEAT_IN_PROGRESS"></div><div class="color-line-height">Seat in progress</div></div>-->
    <div class="d-flex justify-content-start mb-1 col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.BOOKED_SELF"></div><div class="color-line-height">booked self</div></div>
    <div class="d-flex justify-content-start col-4 p-0"><div class="me-1 color-box-size" :class="seatColor.BOOKED_OTHER"></div><div class="color-line-height">booked other</div></div>
    <div class="d-flex justify-content-start mb-1 col-4 p-0"><div class="me-1 color-box-size"></div><div class="color-line-height"></div></div>

  </div>
</template>

<script>
import variables from "@/modules/variables";

export default {
  name: "SeatColorIndicator",
  setup() {
    const seatColor = variables.seatColor;
    return {
      seatColor,
    }
  }

}
</script>

<style scoped>
.color-box-size{
  width: 10px;
  height: 10px;
}
.color-line-height{
  line-height: 10px;
}
.custom-font{
  font-size: x-small;
}

</style>