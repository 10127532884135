<template>
  <div class="text-end">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">{{
            t("Go Back", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="$store.getters.getCounterWiseGoodsDetailsOwner.length > 0"
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i
          ><span class="mx-1 bold">{{
            t("Export", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <TableCard v-if="$store.getters.getCounterWiseGoodsDetailsOwner.length > 0">
    <template v-slot:body>
      <Table id="exportable_data">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{
                t("Ticket_Date_Time", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td class="font_detail">Issued By</td>

            <td class="font_detail">
              {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Goods Weight", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Passenger Ticket", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Goods Id", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Total_payable", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(item, index) in $store.getters
              .getCounterWiseGoodsDetailsOwner"
            :key="index"
          >
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>

            <td class="font_detail">1</td>
            <td class="font_detail">{{ item.goodsWeight }}</td>
            <td class="font_detail">{{ item.seatNumberForGoods }}</td>
            <td class="font_detail">{{ item.goodsId }}</td>
            <td class="font_detail">
              {{ item.totalAmount }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ item.payable }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
              {{ totalTicket }}
            </td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
              {{ totalAmount }}
            </td>

            <td class="font_detail text-white">
              {{ t("Total_payable", {}, { locale: $store.getters.getLang }) }}:
              {{ totalPayable }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
      </Table>
    </template>
  </TableCard>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount } from "vue";
import store from "@/store";
import XLSX from "xlsx";
import moment from "moment";
import { useI18n } from "vue-i18n";
export default {
  name: "GoodDetails",
  components: {},
  props: {
    countermanId: String,
    countermanName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
  },
  emits: ["backToCounterWiseReport"],
  setup(props, { emit }) {
    let countermanId = ref(props.countermanId);
    let countermanNameProps = ref(props.countermanName);
    let totalTicket = ref(0);
    let totalAmount = ref(0);

    let totalPayable = ref(0);
    const { t, locale } = useI18n();
    let countermanWiseGoodsReport = reactive({
      ticketType: "GOODS",
      countermanId: countermanId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      discount: props.discount,
      status: props.status,
      page: 1,
      limit: 25,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getGoodsDetails = () => {
      //TODO add loader
      store
        .dispatch("getCounterWiseGoodsDetailsOwner", countermanWiseGoodsReport)
        .then(() => {
          goodDetailsdata();
        })
        .catch((error) => {
          if (error.status === 401) {
            getGoodsDetails();
          }
        });
    };

    const goodDetailsdata = () => {
      store.getters.getCounterWiseGoodsDetailsOwner.forEach((data) => {
        totalTicket.value =
          store.getters.getCounterWiseGoodsDetailsOwner.length;
        totalAmount.value += data.totalAmount;
        totalPayable.value += data.payable;
      });
    };

    const goBack = () => {
      emit("backToCounterWiseReport", true);
    };

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
        return dl
          ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
          : XLSX.writeFile(
              wb,
              fn || "Admin_CounterWise_Goods_Report." + (type || "xlsx")
            );
      }
    };

    onBeforeMount(() => {
      getGoodsDetails();
    });

    return {
      t,
      locale,
      countermanWiseGoodsReport,
      totalAmount,
      totalTicket,
      totalPayable,
      goBack,
      exportExcel,
      countermanNameProps,
      getGoodsDetails,
      options,
      dateFormat,
    };
  },
};
</script>
