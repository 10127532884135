<template>
  <div>
    <label for="basic-url" class="form-label">{{ t(inputLabel, {}, {locale: selectedLanguage}) }}
    </label>
    <div class="input-group mb-3">
      <span class="input-group-text w-100 overflow-hidden" id="basic-addon3">{{ input }}</span>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import store from "@/store";

export default {
  name: "Input",
  props: {
    text: String,
    label: String,
    id: String,

  },
  setup(props,) {
    const {t, locale} = useI18n();
    const input = computed(()=> props.text)
    const inputLabel = computed(()=> props.label)
    const inputId = computed(()=> props.id)
    const selectedLanguage = computed(() => store.getters.getLang)


    return {
      inputLabel,
      inputId,
      input,
      t,
      locale,
      selectedLanguage,
    };
  },
};
</script>

<style scoped></style>
