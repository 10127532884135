<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Coach</h6></template> -->
      <template v-slot:body> </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div v-if="addUpdate">
      <CoachAddUpdateModal
        @close="close"
        v-bind:coach="coachId"
        @getAllCoach="getAllCoach"
      />
    </div>
    <div>
      <Table v-if="$store.getters.getCoachs">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">NAME</td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachDetails"
            >
              REGISTRATION NUMBER
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
            >
              COACH ROUTE
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
            >
              COACH TIME
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachDetails"
            >
              SEAT PLAN
            </td>
            <td
              class="font_detail"
              v-if="!$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
            >
              STATUS
            </td>
            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(coach, index) in $store.getters.getCoachs"
            :key="coach._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">{{ coach?.name }}</td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachDetails"
            >
              {{ coach?.registrationNumber }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
            >
              {{ coach?.coachRoute?.name }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
            >
              {{ coach?.coachTime }}
            </td>
            <td
              class="font_detail"
              v-if="$store.getters.getSingleCompanyData?.allowCoachDetails"
            >
              {{ coach?.seatPlanId?.name }}
            </td>
            <td v-if="!$store.getters.getSingleCompanyData?.allowCoachwiseTrip">
              <span v-if="coach.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateModal(coach._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalCoachs"
            :per-page="pagination.limit"
            @paginate="getAllCoach"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { useRoute } from "vue-router";
import CoachAddUpdateModal from "@/views/Admin/CompanyDeploy/Children/Coach/CoachAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Coach",
  components: { CoachAddUpdateModal },
  setup() {
    const route = useRoute();
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
    });
    const addUpdate = ref(false);
    const coachId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      coachId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      coachId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllCoach = () => {
      showLoader();
      store
        .dispatch("getAllCoachByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCoach();
          }
        });
    };

    onBeforeMount(() => {
      getAllCoach();
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      coachId,
      Admin,
      hasPermission,
      getAllCoach,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
