<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div  class="col-sm-6 col-md-2">
          <DatePickerFilter
              :id="'datePickerDate'"
              v-model="reservationWiseReport.date"
              :label="'Date'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            reservationWiseReport.companyId &&
            reservationTripList.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Reservation_List_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="filterActive && reservationTripList?.length > 0"
           id="exportable_data">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">TRIP TIME & DATE</td>
          <td class="font_detail">COACH</td>
          <td class="font_detail">BUS NO</td>
          <td class="font_detail">RESERVED SEATS</td>
          <td class="font_detail">TOTAL RESERVATION</td>
          <td class="font_detail">PENDING</td>
          <td class="font_detail">REFUND PENDING</td>
          <td class="font_detail">CANCELLED</td>
          <td class="font_detail"></td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(trip, index) in reservationTripList"
            :key="trip._id"
        >
          <td class="font_detail">
            {{ index + 1 }}
          </td>
          <td class="font_detail bold">{{ dateTimeFormat(trip?.tripInfo.tripDateTime) }}</td>
          <td class="font_detail">{{ trip?.coach }}</td>
          <td class="font_detail">{{ trip?.coachRegistrationNumber || trip?.registrationNumber }}</td>
          <td class="font_detail">{{ trip?.seatTicket }}</td>
          <td class="font_detail">{{ trip?.totalReservedTicket }}</td>
          <td class="font_detail">{{ trip?.pendingStatus }}</td>
          <td class="font_detail">{{ trip?.refundPendingStatus }}</td>
          <td class="font_detail">{{ trip?.cancelledStatus }}</td>
          <td class="font_detail">
            <button class="edit_table_data font_detail"
                    @click=" tripReservationList(trip._id)">
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail text-white" colspan="5">Total</td>
          <td class="font_detail text-white">{{ totalReservedTicket }}</td>
          <td class="font_detail text-white">{{ pendingStatus }}</td>
          <td class="font_detail text-white">{{ refundPendingStatus }}</td>
          <td class="font_detail text-white">{{ cancelledStatus }}</td>
          <td></td>
        </tr>
      </template>
    </Table>
  </div>
  <ReservationDetails
      v-if="tripReservationListActive"
      :selectedTripId="selectedTripId"
      v-on:backToReservationReport="onChildClick"
  />
</template>

<script>
import {inject, onBeforeMount, reactive, ref, watch} from "vue";
import store from "@/store";
import moment from "moment";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import ReservationDetails from "@/views/Counterman/Report/Children/Reservation/ReservationDetails";

export default {
  name: "ReservationReport",
  components: {
    ReservationDetails,
  },
  emits: ["backToReservationReport"],
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let tripReservationListActive = ref(false);
    let reportActive = ref(true);
    let filterActive = ref(false);
    const exportExcel = inject("exportExcel");
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const selectedTripId = ref(null);
    const selectedCompanyId = ref(null);
    let reservationWiseReport = reactive({
      date: moment(new Date()).format("yyyy-MM-DD"),
    });
    // let pagination = reactive({
    //   page: 1,
    //   limit: 20,
    // });
    const options = {
      texts: {
        count: "",
      },
    };

    const reservationTripList = ref([]);

    const filterReport = () => {
      filterActive.value = true;
      getReservationReports();

    };

    const getReservationReports = () => {
      let payload = {...reservationWiseReport};
      showLoader();
      store
          .dispatch("getReservationReports", payload)
          .then((res) => {
            reservationTripList.value = res.trips;
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getReservationReports();
            }
          });
    };

    const tripReservationList = (tripId) => {
      selectedTripId.value = tripId;
      tripReservationListActive.value = true;
      reportActive.value = false;
    }

    const onChildClick = (value) => {
      tripReservationListActive.value = !value;
      reportActive.value = value
    };

    watch(reservationTripList, () => {
      sumListData();
    })

    const seatTicket = ref(0);
    const totalReservedTicket = ref(0);
    const cancelledStatus = ref(0);
    const pendingStatus = ref(0);
    const refundPendingStatus = ref(0);

    const sumListData = () => {
      seatTicket.value = 0;
      totalReservedTicket.value = 0;
      cancelledStatus.value = 0;
      pendingStatus.value = 0;
      refundPendingStatus.value = 0;
      reservationTripList.value.forEach((data) => {
        seatTicket.value += data.seatTicket;
        totalReservedTicket.value += data.totalReservedTicket;
        cancelledStatus.value += data.cancelledStatus;
        pendingStatus.value += data.pendingStatus;
        refundPendingStatus.value += data.refundPendingStatus;
      });
    };

    onBeforeMount(() => {
      filterReport();
    })

    return {
      getReservationReports,
      reportActive,
      filterActive,
      exportExcel,
      fromDate,
      reservationWiseReport,
      filterReport,
      reservationTripList,
      dateTimeFormat,
      options,
      tripReservationListActive,
      tripReservationList,
      onChildClick,
      selectedCompanyId,
      selectedTripId,
      seatTicket,
      totalReservedTicket,
      cancelledStatus,
      pendingStatus,
      refundPendingStatus
    }
  }
}
</script>