<template>
  <div v-if="loader"
       id="laser-print"
       style="height:374.4px; width: 796.8px; background-color: #E5E5E5; display: none; position: relative">
    <div style=" height: 100%; display: flex;">
      <div
          v-for="(layer, index) in properties"
          :key="index"
          :style="{width: `${layer.width}px`, paddingTop: `${layer.topLayer}px`}"
      >
        <div v-for="(property, key) in layer.properties" :key="key" :class="property.name ? '' : 'gridClass'"
             :style="{marginLeft: `${property.left}px`, marginTop: `${property.top}px`, fontSize: `${property.fontSize}px`}">
          <div v-if="property.example1 && property.example2 && property.nameValue1 && property.nameValue2"
               :style="{fontWeight: property.fontWeight}">{{ property.example1 }} {{ property.separator }}
            {{ property.example2 }}
          </div>
          <div v-else>
            <span
                :style="[{width: `${property.nameWidth}px`}, property.name ? {display: 'inline-grid', textAlign: 'left', lineHeight: '12px', wordBreak: 'break-word' } : null]">{{
                property.name
              }}</span>
            <span v-if="property.name"> {{ property.separator + ' ' }} </span>
            <span
                :style="[{width: `${property.nameValueWidth}px`, fontWeight: property.fontWeight}, property.example1 ? {display: 'inline-grid', textAlign: 'left', lineHeight: '12px', wordBreak: 'break-word' } : null ]"> {{
                property.example1
              }} </span>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import {dateFormat, dateTimeFormat, timeFormat} from "@/helpers/dateTimeFormat";
import store from "@/store";

export default {
  name: "LaserPrinter",
  props: {
    purchasedTicket: Object
  },
  setup(props) {
    const ticket = ref(props.purchasedTicket)
    const trip = computed(() => store.getters.getTripForTicketing)

    let properties = ref(
        [...store.getters.getTicketLayoutCounterman]
    );

    let loader = ref(false);

    const initTicketValue = () => {
      properties.value.forEach((layer) => {
        layer.properties.forEach((property) => {
          if (property.nameValue1 === 'issuedBy' || property.nameValue1 === 'referenced_by') {
            property.example1 = ticket.value[property.nameValue1]?.name
            property.example2 = ticket.value[property.nameValue2]?.name
          } else if (property.nameValue1 === 'coach') {
            if (typeof ticket.value[property.nameValue1] === "object") {
              property.example1 = ticket.value[property.nameValue1]?.name
              property.example2 = ticket.value[property.nameValue2]?.name
            } else {
              property.example1 = ticket.value[property.nameValue1]
              property.example2 = ticket.value[property.nameValue2]
            }
          } else if (property.nameValue1 === 'fromStoppage' || property.nameValue1 === 'toStoppage') {
            if (typeof ticket.value[property.nameValue1] === "object") {
              property.example1 = ticket.value[property.nameValue1]?.name
              property.example2 = ticket.value[property.nameValue2]?.name
            } else {
              property.example1 = ticket.value[property.nameValue1]
              property.example2 = ticket.value[property.nameValue2]
            }
          } else if (property.nameValue1 === 'route') {
            property.example1 = ticket.value['trip'].route?.name

          } else if (property.nameValue1 === 'boardingTripDateTime' || property.nameValue1 === 'tripDateTime') {
            property.example1 = dateTimeFormat(ticket.value[property.nameValue1])
          } else if (property.nameValue1 === 'createdAt' || property.nameValue1 === 'ticketDateTime') {
            property.example1 = dateTimeFormat(ticket.value[property.nameValue1], 6)
          } else {
            property.example1 = ticket.value[property.nameValue1]
            property.example2 = ticket.value[property.nameValue2]
          }
        })
      })
      loader.value = true
    }

    onBeforeMount(() => {
      initTicketValue()
    })

    return {
      ticket,
      dateTimeFormat,
      dateFormat,
      timeFormat,
      trip,
      properties,
      loader,
    }
  }
}
</script>

<style scoped>
/*.propertyWidth {*/
/*  display: inline-grid;*/
/*  text-align: right;*/
/*  line-height: 12px;*/
/*  word-break: break-all;*/

/*}*/
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  /*@page {*/
  /*  size: A4;*/
  /*  margin: 0;*/
  /*}*/
  .print_hide {
    display: none !important;
  }

  /*body {*/
  /*  padding: 0;*/
  /*  margin: 0;*/
  /*  -webkit-print-color-adjust: exact;*/
  /*}*/
  /*.ticket_head {*/
  /*  -webkit-print-color-adjust: exact;*/
  /*}*/
  /*.pessenger_copy_logo {*/
  /*  margin-left: 10px;*/
  /*}*/
  /*.logo {*/
  /*  padding-top: 8px;*/
  /*}*/
}

/*table {*/
/*  font-family: Arial, Helvetica, sans-serif;*/
/*}*/

/*body {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/
</style>
