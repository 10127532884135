<template>
  <div
    class="card"
    style="
      width: 15rem;
      border: 0;
      border-bottom: 7px solid #f04935;
      border-radius: 5px;
    "
    v-if="Object.keys(ticketShortInfo).length > 0"
  >
    <div class="card-header p-1" style="background-color: #f04935">
      {{ seatNo }}
    </div>
    <div v-if="seatDetails.status === 'SEAT_IN_PROGRESS'" class="card-body p-2">
      <table class="table table-striped mb-0">
        <tbody>
          <tr>
            <td>{{t("Counter", {}, { locale: $store.getters.getLang }) }}</td>
            <td>
              {{ ticketShortInfo?.counter }}
            </td>
          </tr>
          <tr>
            <td>{{t("Counterman", {}, { locale: $store.getters.getLang }) }}</td>
            <td>
              {{ ticketShortInfo?.counterman }}
            </td>
          </tr>
          <tr>
            <td>{{t("Phone", {}, { locale: $store.getters.getLang }) }}</td>
            <td>{{ ticketShortInfo?.phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="card-body p-2">
      <table class="table table-striped mb-0">
        <tbody>
          <tr>
            <td>{{t("Name", {}, { locale: $store.getters.getLang }) }}</td>
            <td>{{ ticketShortInfo?.name }}</td>
          </tr>
          <tr>
            <td>{{t("Phone", {}, { locale: $store.getters.getLang }) }}</td>
            <td>{{ ticketShortInfo?.phone }}</td>
          </tr>
          <tr>
            <td>{{t("From", {}, { locale: $store.getters.getLang }) }} - {{t("To", {}, { locale: $store.getters.getLang }) }}</td>
            <td>
              {{ ticketShortInfo?.from }}-{{ ticketShortInfo?.to }}
            </td>
          </tr>
          <tr>
            <td>{{t("Counterman", {}, { locale: $store.getters.getLang }) }}</td>
            <td>
              {{ ticketShortInfo?.counterman }}

            </td>
          </tr>
          <tr>
            <td>{{t("PNR", {}, { locale: $store.getters.getLang }) }}</td>
            <td>
              <span class="badge bg-primary my-1">{{ ticketShortInfo?.pnr }}</span>
            </td>
          </tr>
          <tr>
            <td>{{t("ISSUED ON", {}, { locale: $store.getters.getLang }) }}</td>
            <td>({{ dateFormat(ticketShortInfo?.issuedOn) }})</td>
          </tr>
          <tr v-if="ticketShortInfo.referenced_by">
            <td>{{t("REFERENCED BY", {}, { locale: $store.getters.getLang }) }}</td>
            <td>{{ticketShortInfo.referenced_by.name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {computed,  onMounted,  ref} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n/dist/vue-i18n.esm-bundler";
import store from "@/store";
export default {

  props: ['seat', 'seatDetails'],

  setup(props) {
    const { t, locale } = useI18n();
    const tripDetailsNew = ref({})

    const ticketShortInfo = computed(() => store.getters.getTicketShortInfo)

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const seatNo = computed(() => props.seat)


    const getTicketShortInfo = ( ) => {

      store
          .dispatch("getTicketShortInfo", {
            tripId: tripDetailsNew.value.tripId,
            seatNo: seatNo.value,
            from: tripDetailsNew.value.fromStoppage,
            to: tripDetailsNew.value.toStoppage,
          })
          .then(() => {
          })
          .catch((error) => {
            if (error.status === 401) {
              getTicketShortInfo();
            }
          });
    }

    const getInProgressTicketShortInfo = ( ) => {

      store
          .dispatch("getInProgressTicketShortInfo", {
            tripId: tripDetailsNew.value.tripId,
            seat: seatNo.value,
          })
          .then(() => {
          })
          .catch((error) => {
            if (error.status === 401) {
              getInProgressTicketShortInfo();
            }
          });
    }

    onMounted(() => {
      tripDetailsNew.value = store.getters.getTripForTicketingNew
      if(props.seatDetails.status === 'SEAT_IN_PROGRESS'){
        getInProgressTicketShortInfo()
      }
      else {
        getTicketShortInfo()
      }
    })



    return {
      dateFormat,
      t,
      locale,
      ticketShortInfo,
      seatNo,
    };
  },
};
</script>

<style scoped>
td {
  padding: 2px;
  font-size: 12px !important;
}
</style>
