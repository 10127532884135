<template>
  <Modal @close="closeModal" v-bind:size="'lg'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <Error v-if="err" v-bind:error="err" />
      <div class="mx-6">
        <form class="my-2">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="form-group row">
                <TicketInput
                  v-model="passengerDetails.passengerName"
                  v-bind:placeholder="'Passenger Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
                />
              </div>
              <div class="form-group row my-1">
                <TicketInput
                  v-model="passengerDetails.passengerPhone"
                  v-bind:placeholder="'Passenger Mobile'"
                  v-bind:label="'Mobile'"
                  v-bind:id="'mobile'"
                  v-bind:type="'text'"
                >
                  <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.passengerPhone"
                                v-bind:error="err.passengerPhone"
                              />
                            </template> -->
                </TicketInput>
              </div>
              <div class="form-group row my-1" v-if="ticketType !== 'GOODS'">
                <TicketDropdown
                  v-model="passengerDetails.passengerGender"
                  v-bind:id="'passengerGender'"
                  v-bind:label="'Gender'"
                  v-bind:dropdown="dropdownVariables.gender"
                  v-bind:defaultOption="'--select gender --'"
                  v-bind:defaultValue="''"
                >
                </TicketDropdown>
              </div>
              <div class="form-group row my-1" v-if="ticketType !== 'GOODS'">
                <TicketInput
                  v-model="passengerDetails.passengerNID"
                  v-bind:placeholder="'Passenger NID'"
                  v-bind:label="'NID'"
                  v-bind:id="'nid'"
                  v-bind:type="'text'"
                />
              </div>
              <div class="form-group row my-1" v-if="ticketType !== 'GOODS'">
                <div class="col-sm-3">
                  <label for="address" class="col-form-label font_detail"
                    >Address</label
                  >
                </div>
                <div class="col-sm-9">
                  <textarea
                    class="form-control font_detail"
                    id="address"
                    v-model="passengerDetails.passengerAddress"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-12 col-xl-12">
              <div class="form-group row my-1">
                <TicketDropdown
                  v-model="passengerDetails.fromStoppage"
                  v-bind:id="'fromStoppage'"
                  v-bind:label="'From Stoppage'"
                  v-bind:defaultOption="'---select a stoppage---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="
                    $store.getters.getRouteSequenceFilterAgentIntercity
                  "
                >
                  <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                </TicketDropdown>
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                  v-model="passengerDetails.toStoppage"
                  v-bind:id="'toStoppage'"
                  v-bind:label="'To Stoppage'"
                  v-bind:defaultOption="'---select a stoppage---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="
                    $store.getters.getRouteSequenceFilterAgentIntercity
                  "
                >
                  <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                </TicketDropdown>
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                  v-model="passengerDetails.boardingPlace"
                  v-bind:id="'boardingPlace'"
                  v-bind:label="'Boarding place'"
                  v-bind:defaultOption="'---select a boarding place---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="
                    $store.getters.getBoardingPlaceFilterAgentIntercity
                  "
                >
                  <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.boardingPlace"
                                v-bind:error="err.boardingPlace"
                              />
                            </template> -->
                </TicketDropdown>
                <Error
                  v-if="errors.boardingPlace"
                  v-bind:error="errors.boardingPlace"
                />
              </div>
              <div class="form-group row my-1">
                <TicketDropdown
                  v-model="passengerDetails.droppingPoint"
                  v-bind:id="'droppingPoint'"
                  v-bind:label="'Dropping place'"
                  v-bind:defaultOption="'---select a dropping point---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="
                    $store.getters.getDroppingPointFilterAgentIntercity
                  "
                >
                  <!-- <template v-slot:error>
                              <TicketError
                                v-if="err.droppingPoint"
                                v-bind:error="err.droppingPoint"
                              />
                            </template> -->
                </TicketDropdown>
                <Error
                  v-if="errors.droppingPoint"
                  v-bind:error="errors.droppingPoint"
                />
              </div>
              <div class="row m-2">
                <div
                  class="col-sm-4 col-md-4"
                  v-if="passengerDetails.ticketType !== 'GOODS'"
                >
                  <p class="text-center font_detail m-0 text-info">
                    TOTAL SEAT
                  </p>
                  <input
                    v-model="passengerDetails.totalSeat"
                    type="number"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <Error
                    v-if="errors.totalSeat"
                    v-bind:error="errors.totalSeat"
                  />
                </div>
                <div class="col-sm-4 col-md-4" v-else>
                  <p
                    class="text-center font_detail text-info m-0"
                    v-if="passengerDetails.ticketType == 'GOODS'"
                  >
                    GOODS WEIGHT IN (KG)
                  </p>
                  <input
                    v-if="passengerDetails.ticketType == 'GOODS'"
                    v-model="passengerDetails.goodsWeight"
                    placeholder="Ex: 12"
                    type="number"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <span v-if="passengerDetails.ticketType == 'GOODS'">
                    <Error
                      v-if="errors.goodsWeight"
                      v-bind:error="errors.goodsWeight"
                    />
                  </span>
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail text-info m-0">
                    TOTAL AMOUNT
                  </p>
                  <input
                    v-model="passengerDetails.totalAmount"
                    type="number"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <Error
                    v-if="errors.totalAmount"
                    v-bind:error="errors.totalAmount"
                  />
                </div>
                <div
                  v-if="hasPermission(Agent.fareDiscount)"
                  class="col-sm-4 col-md-4"
                >
                  <p class="text-center font_detail text-info m-0">DISCOUNT</p>
                  <input
                    v-model="passengerDetails.discount"
                    type="number"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <!-- <p class="text-danger" v-if="discount">
                    {{ discount }}
                  </p> -->
                </div>
              </div>
              <div class="row m-2">
                <div class="col-sm-4 col-md-4"></div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail text-info m-0">
                    PAID AMOUNT
                  </p>
                  <input
                    v-model="passengerDetails.paid"
                    min="0"
                    oninput="this.value = Math.abs(this.value)"
                    type="number"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <Error
                    v-if="errors.paidAmount"
                    v-bind:error="errors.paidAmount"
                  />
                </div>
                <div class="col-sm-4 col-md-4"></div>
              </div>

              <div class="row m-2">
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">PAYABLE</p>
                  <input
                    disabled
                    readonly
                    v-model="calculatePayable"
                    type="number"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <!-- <p class="text-danger" v-if="discountErrorMsg">
                      Discount amount can't be greater than netfare
                    </p> -->
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">DUE</p>
                  <input
                    disabled
                    readonly
                    v-model="calculateDue"
                    type="number"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <!-- <p class="text-danger" v-if="discountErrorMsg">
                      Discount amount can't be greater than netfare
                    </p> -->
                </div>
                <div class="col-sm-4 col-md-4">
                  <p class="text-center font_detail m-0">REFUND</p>
                  <input
                    disabled
                    readonly
                    v-model="calculateRefund"
                    type="number"
                    class="
                      input_field
                      form-control
                      rounded
                      px-3
                      w-100
                      text-center
                    "
                  />
                  <!-- <p class="text-danger" v-if="discountErrorMsg">
                      Discount amount can't be greater than netfare
                    </p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="row mt-3">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <p class="text-center font_detail m-0">CANCEL</p>
                <button
                  class="confirm_button btn btn-danger w-100"
                  @click.prevent="cancelModal"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <p class="text-center font_detail m-0">CONFIRM</p>
                <button
                  v-if="passengerDetails.ticketType === 'EXTRA'"
                  class="confirm_button btn btn-success w-100"
                  :disabled="
                    calculatePayable <= 0 ||
                      passengerDetails.totalSeat <= 0 ||
                      passengerDetails.totalAmount <= 0
                  "
                  @click.prevent="confirmExtraTicket"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  v-if="passengerDetails.ticketType === 'GOODS'"
                  class="confirm_button btn btn-success w-100"
                  :disabled="
                    calculatePayable <= 0 ||
                      passengerDetails.goodsWeight === '' ||
                      passengerDetails.totalAmount <= 0
                  "
                  @click.prevent="confirmExtraTicket"
                >
                  <i class="fas fa-check"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import useInputValidation from "@/modules/useInputValidations";
import { watch, ref, onBeforeMount, inject, computed } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import moment from "moment";
import store from "@/store";
import toast from "@/modules/Toast";

const { errorToast } = toast();
export default {
  name: "TicketExtraBookingModal",
  props: {
    fromStoppage: String,
    toStoppage: String,
    singleTripDetails: Object,
    extraTicketModalName: String,
    extraTicketType: String,
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    let passengerDetails = reactive({
      trip: "",
      coach: "",
      fromStoppage: "",
      toStoppage: "",
      ticketType: props.extraTicketType,
      passengerName: "",
      passengerPhone: "",
      passengerGender: "",
      passengerNID: "",
      passengerAddress: "",
      boardingPlace: "",
      droppingPoint: "",
      totalSeat: 0,
      goodsWeight: "",
      farePerTicket: 0,
      seatClass: "",
      totalAmount: 0,
      discount: 0,
      payable: 0,
      paid: 0,
      dues: 0,
      refund: 0,
      referenced_by: null,
      ticketDateTime: "",
    });
    let title = ref("");
    let ticketType = ref(props.extraTicketModalName);
    if (ticketType.value === "SEATS") {
      title.value = "EXTRA TICKET";
    } else {
      title.value = "GOODS TICKET";
    }
    let err = ref("");
    const fromStoppage = ref(props.fromStoppage);
    const toStoppage = ref(props.toStoppage);
    let singleTripDetails = ref(props.singleTripDetails);
    const { hasPermission } = permission();
    const { Agent } = roles();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let calculatePayable = ref(0);
    let calculateDue = ref(0);
    let calculateRefund = ref(0);
    let payable = ref(0);
    watch(
      () => passengerDetails.totalSeat,
      () => {
        requiredValidation("totalSeat", passengerDetails.totalSeat);
      }
    );
    watch(
      () => passengerDetails.goodsWeight,
      () => {
        requiredValidation("goodsWeight", passengerDetails.goodsWeight);
      }
    );
    watch(
      () => passengerDetails.goodsWeight,
      () => {
        requiredValidation("goodsWeight", passengerDetails.goodsWeight);
      }
    );
    watch(
      () => passengerDetails.fromStoppage,
      () => {
        err.value = "";
        routeSequenceCheck();
      }
    );
    watch(
      () => passengerDetails.toStoppage,
      () => {
        err.value = "";
        routeSequenceCheck();
      }
    );
    watch(
      () => passengerDetails.boardingPlace,
      () => {
        if (passengerDetails.boardingPlace !== "") {
          requiredValidation("boardingPlace", passengerDetails.boardingPlace);
        }
      }
    );
    watch(
      () => passengerDetails.droppingPoint,
      () => {
        if (passengerDetails.droppingPoint !== "") {
          requiredValidation("droppingPoint", passengerDetails.droppingPoint);
        }
      }
    );
    watch(
      () => passengerDetails.totalAmount,
      () => {
        payable.value = passengerDetails.totalAmount;
        requiredValidation("totalAmount", passengerDetails.totalAmount);
      }
    );
    calculatePayable = computed(() => {
      return payable.value - passengerDetails.discount;
    });
    calculateDue = computed(() => {
      let calculateValue = 0;
      if (passengerDetails.paid <= calculatePayable.value) {
        calculateValue = calculatePayable.value - passengerDetails.paid;
      }
      return calculateValue;
    });
    calculateRefund = computed(() => {
      let calculateValue = 0;
      if (passengerDetails.paid > calculatePayable.value) {
        calculateValue = passengerDetails.paid - calculatePayable.value;
      }
      return calculateValue;
    });
    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = "";
      });
      requiredValidation("boardingPlace", passengerDetails.boardingPlace);
      requiredValidation("droppingPoint", passengerDetails.droppingPoint);
      requiredValidation("totalAmount", passengerDetails.totalAmount);
      if (passengerDetails.ticketType === "GOODS") {
        requiredValidation("goodsWeight", passengerDetails.goodsWeight);
      } else {
        requiredValidation("totalSeat", passengerDetails.totalSeat);
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const confirmExtraTicket = () => {
      passengerDetails.ticketDateTime = moment(new Date())
        .utcOffset(0, true)
        .format("yyyy-MM-DD hh:mm:ss");
      if (validate() && routeSequenceCheck()) {
        passengerDetails.payable = parseInt(calculatePayable.value);
        passengerDetails.dues = parseInt(calculateDue.value);
        passengerDetails.refund = parseInt(calculateRefund.value);
        passengerDetails.farePerTicket =
          parseInt(passengerDetails.payable) /
          parseInt(passengerDetails.totalSeat);

        if (passengerDetails.payable > store.getters.agentBalance) {
          errorToast("Out of Balance!!!");
          closeModal();
        } else {
          if (passengerDetails.ticketType === "EXTRA") {
            showLoader();
            store
              .dispatch("extraSeatPurchaseAgentIntercity", passengerDetails)
              .then((extraSeatPurchase) => {
                store.commit(
                  "updateBalance",
                  extraSeatPurchase.data.ticket.issuedBy.balance
                );

                emit("extraSeatPurchase", {
                  printPreview: true,
                  lastTicketDetails: extraSeatPurchase.data.ticket,
                  ticketTypeForPrint: "extraTicket",
                });
                hideLoader();
                closeModal();
              })
              .catch(() => {
                hideLoader();
                closeModal();
              });
          } else if (passengerDetails.ticketType === "GOODS") {
            showLoader();
            passengerDetails.seatClass = "";
            passengerDetails.goodsWeight =
              passengerDetails.goodsWeight.toString() + " Kg";
            store
              .dispatch("extraGoodsPurchaseAgentIntercity", passengerDetails)
              .then((extraGoodsPurchase) => {
                store.commit(
                  "updateBalance",
                  extraGoodsPurchase.data.ticket.issuedBy.balance
                );
                emit("extraGoodsPurchase", {
                  printPreview: true,
                  lastTicketDetails: extraGoodsPurchase.data.ticket,
                  ticketTypeForPrint: "goodsTicket",
                });
                hideLoader();
                closeModal();
              })
              .catch(() => {
                hideLoader();
                closeModal();
              });
          }
        }
      }
    };
    const arrayObjectIndexOf = (myArray, searchTerm, property) => {
      for (var i = 0, len = myArray.length; i < len; i++) {
        if (myArray[i][property] === searchTerm) return i;
      }
      return -1;
    };
    const routeSequenceCheck = () => {
      let routeSequence = singleTripDetails.value[0].route.routeSequence;
      let indexOfFromStoppage = arrayObjectIndexOf(
        routeSequence,
        passengerDetails.fromStoppage,
        "_id"
      );
      let indexOfToStoppage = arrayObjectIndexOf(
        routeSequence,
        passengerDetails.toStoppage,
        "_id"
      );
      if (
        indexOfToStoppage !== -1 &&
        indexOfFromStoppage !== -1 &&
        indexOfToStoppage > indexOfFromStoppage
      ) {
        return true;
      } else {
        err.value = "fromStoppage to toStoppage route sequence did not match";
        return false;
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const cancelModal = () => {
      closeModal();
    };
    onBeforeMount(() => {
      passengerDetails.fromStoppage = fromStoppage.value;
      passengerDetails.toStoppage = toStoppage.value;
      passengerDetails.trip = singleTripDetails.value[0]._id;
      passengerDetails.seatClass =
        singleTripDetails.value[0].seatPlan.seatClass;
      passengerDetails.coach = singleTripDetails.value[0].coach._id;
    });
    return {
      closeModal,
      title,
      passengerDetails,
      dropdownVariables,
      err,
      errors,
      confirmExtraTicket,
      hasPermission,
      Agent,
      cancelModal,
      calculatePayable,
      calculateDue,
      calculateRefund,
      ticketType,
    };
  },
};
</script>

<style scoped></style>
