export default function useValidators() {
    const isEmpty = (fieldName, fieldValue) => {
        return !fieldValue || Object.keys(fieldValue).length === 0 ? "The " + fieldName + " field is required" : "";
    };
    const isPhoneEmpty = (fieldName, fieldValue) => {
        return !fieldValue || Object.keys(fieldValue).length === 0 ? "The " + fieldName + " field is required / invalid" : "";
    };
    const isFound = (fieldName, fieldValue) => {
        return !fieldValue || Object.keys(fieldValue).length === 0 ? fieldName + " not found" : "";
    };
    const isValidNumber = (fieldName, fieldValue) => {
        return parseInt(fieldValue) <= 0 ? "Must be a valid number" : "";
    };
    const isValidNumberWithZero = (fieldName, fieldValue) => {
        return parseInt(fieldValue) < 0 ? "Must be a valid number" : "";
    };
    const minLength = (fieldName, fieldValue, min) => {
        return fieldValue.length < min ? "The " + fieldName + " field must be atleast " + min + " characters long" : "";
    };
    const isEmail = (fieldName, fieldValue) => {
        const re = /\w+@\w+\.\w+/;
        return re.test(fieldValue) ? null : "Must be a valid email address";
    };
    const isPhone = (fieldName, fieldValue) => {
        const re = /^\+?[0-9]+$/;
        return re.test(fieldValue) ? null : "Must be a valid phone number";
    };
    const isBdPhone = (fieldName, fieldValue, allowedOperator=3456789) => {
        const bdPhoneRegex = new RegExp(`(^(\\+88|0088)?(01){1}[${allowedOperator}]{1}(\\d){8})$`);
        return bdPhoneRegex.test(fieldValue.toString()) ? null : "Must be a valid mobile number";
    };
    const hasRole = (requiredRole, role, roleObject) => {
        return requiredRole && role ? Object.keys(roleObject).find(key => roleObject[key] === requiredRole) + " Role required for " + Object.keys(roleObject).find(key => roleObject[key] === role) + " Role" : "";
    };

    const isEmptyCheck = (fieldValue, errorMsg) => {
        return !fieldValue || Object.keys(fieldValue).length === 0 ? "The " + errorMsg + " field is required" : "";
    };

    const isJson = (value) => {
        try {
            JSON.parse(value);
        } catch (e) {
            console.log(e)
            return 'Must be a valid json';
        }
        return null;
    }

    return {
        isEmpty, isFound, minLength, isEmail, isValidNumber, isPhone, hasRole, isBdPhone, isPhoneEmpty, isJson, isEmptyCheck, isValidNumberWithZero
    };
}