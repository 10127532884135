<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div
            class="col-sm-6 col-md-3 col-xxl-3"
        >
          <DropdownFilter
              v-bind:id="'paymentType'"
              v-bind:label="'Payment Type'"
              v-bind:defaultOption="'ALL'"
              v-model="countermanWiseSeatReport.paymentType"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.counterReportPaymentType"
          />
        </div>
      </template>
      <template v-slot:button3>
        <button class="edit_table_data font_detail" @click="getSeatDetails">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div
    class="card h-100 mt-4"
    v-for="(itemValue, itemKey) in classWiseReport"
    :key="itemKey"
  >
    <div class="card-header text-center bg-white" v-if="classWiseReport">
      <div class="row">
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Code: {{ itemValue.trip.tripCode }}
          </h5>
        </div>
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Date Time: {{ tripDateFormat(itemValue.trip.tripDateTime) }}
          </h5>
        </div>
      </div>
    </div>

    <div class="card-body mt-0 py-0" v-if="classWiseReport">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>
            <td class="font_detail">Counterman Name</td>
            <td class="font_detail">PNR Code</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Seat Numbers</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Total Amount</td>
            <td v-if="countermanWiseSeatReport.discount" class="font_detail">
              Discount
            </td>
            <td class="font_detail">Payment Type</td>
            <td class="font_detail">Issued By</td>
            <td class="font_detail">Referenced By</td>
            <td class="font_detail" v-if="cancelledBy === 0">Cancelled By</td>
            <td class="font_detail" v-if="cancelledBy === 0">
              Cancellation Cause
            </td>
            <td
              class="font_detail"
              v-if="cancelledBy !== 0 && countermanNameProps === 'ONLINE' && hasPermission(Admin.ticketCancel)"
            >
              Action
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>

            <td class="font_detail">{{ countermanNameProps === 'ONLINE' ? 'Jatri Online' : countermanNameProps }}</td>
            <td class="font_detail">{{ item?.pnrCode }}</td>
            <td class="font_detail">{{ item?.passengerName }}</td>
            <td class="font_detail">{{ item?.passengerPhone }}</td>
            <td class="font_detail">{{ item?.boardingPlace }}</td>
            <td class="font_detail">{{ item?.droppingPoint }}</td>
            <td class="font_detail">
              {{ item?.seatNumbers.toString() }}
            </td>

            <td class="font_detail">{{ item?.totalSeat }}</td>
            <td class="font_detail">
              {{
                countermanWiseSeatReport.discount === 1
                  ? item?.totalAmount
                  : item?.payable
              }}
            </td>
            <td v-if="countermanWiseSeatReport.discount" class="font_detail">
              {{ item?.discount }}
            </td>
            <td class="font_detail">{{ !item?.userType ? item?.paymentType : 'DT' }}</td>
            <td class="font_detail">{{ countermanNameProps === 'ONLINE' ? 'Jatri' : item.issuedBy?.name }}</td>
            <td class="font_detail">{{ item.referenced_by?.name }}</td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item?.cancelledBy?.name }}
            </td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item?.cancellationCause }}
            </td>
            <td
              class="font_detail"
              v-if="cancelledBy !== 0 && countermanNameProps === 'ONLINE' && hasPermission(Admin.ticketCancel)"
            >
              <button
                  v-if="item.cancelRequest"
                class="header_cancel_button font_detail"
                @click="cancelDtTicket(item)"
              >
                <span class="mx-1">Requested for cancel</span>
              </button>
              <button
                  v-else
                  class="header_delete_button font_detail"
                  @click="cancelDtTicket(item)"
              >
                <span   class="mx-1">Cancel</span>
              </button>
            </td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              Total Ticket: {{ itemValue.totalTicket }}
            </td>
            <td class="font_detail text-white">
              Total Amount: {{ itemValue.totalAmount }} Taka
            </td>
            <td
              v-if="countermanWiseSeatReport.discount"
              class="font_detail text-white"
            >
              Total Discount: {{ itemValue.totalDiscount }} Taka
            </td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
            <td
              class="font_detail"
              v-if="cancelledBy !== 0 && countermanNameProps === 'ONLINE' && hasPermission(Admin.ticketCancel)"
            ></td>
          </tr>
        </template>
      </table-simple>
    </div>
    <CancelTicketModal v-model="isCancel" v-if="isCancel" :ticketDetails="ticketForCancel" @ticketCancelled="ticketCancelled" />
  </div>
</template>

<script>
import {inject, onBeforeMount, ref, watch,} from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TableSimple from "@/components/layouts/Tables/TableSimple.vue";
import CancelTicketModal from "@/views/Admin/Report/Children/Counter/CancelTicketModal";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown.vue";
import dropdownVariables from "@/modules/dropdownVariables";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "SeatDetails",
  computed: {
    dropdownVariables() {
      return dropdownVariables
    }
  },
  components: {DropdownFilter, TableSimple, CancelTicketModal },
  props: {
    companyId: String,
    countermanId: String,
    countermanName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
  },
  emits: ["backToCounterWiseReport", "getCounterReports"],
  setup(props, { emit }) {
    let countermanId = ref(props.countermanId);
    let companyId = ref(props.companyId);
    let countermanNameProps = ref(props.countermanName);
    let totalAmountSeat = ref(0);
    let totalTicket = ref(0);
    let totalDiscount = ref(0);
    let summary = ref(false);
    let cancelledBy = ref(props.status);
    let isCancel = ref(false);
    let ticketForCancel = ref({});
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");

    let countermanWiseSeatReport = reactive({
      ticketType: "SEAT",
      countermanId: countermanId.value,
      companyId: companyId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      discount: props.discount,
      status: props.status,
      paymentType: ""
    });

    let classWiseReport = ref({});

    const options = {
      texts: {
        count: "",
      },
    };

    watch(() => store.getters.getcounterWiseSeatDetails, (value) => {
      initSeatDetails(value)
    }, {
      deep: true,
    })

    const initSeatDetails = (response) => {
      if (response.length !== 0) {
        classifyByClassName(response);
      } else {
        classWiseReport.value = {};
        summary.value = false;
      }
    }

    const tripDateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm A");
    };
    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getSeatDetails = () => {
      showLoader();
      store
        .dispatch(
          "getCountermanWiseSeatReportDetails",
          countermanWiseSeatReport
        )
        .then(() => {
          hideLoader();

        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getSeatDetails();
          }
        });
    };

    const cancelDtTicket = (ticket) => {
      ticketForCancel.value = ticket;
      isCancel.value = true;
    };

    const ticketCancelled = () => {
      emit('getCounterReports')
    }

    const classifyByClassName = (reports) => {
      classWiseReport.value = {};

      reports.forEach((report) => {
        if (report?.trip?._id !== undefined){
          if (report.trip._id in classWiseReport.value) {
            classWiseReport.value[report.trip._id].totalReport.push(report);
          } else {
            classWiseReport.value[report.trip._id] = {
              totalReport: [],
              totalTicket: 0,
              totalAmount: 0,
              totalDiscount: 0,
              trip: report.trip,
            };
            classWiseReport.value[report.trip._id].totalReport.push(report);
          }
        }
      });

      calculateTotaldata();
      summary.value = true;
    };

    const calculateTotaldata = () => {
      for (const key in classWiseReport.value) {
        classWiseReport.value[key].totalReport.forEach((data) => {
          classWiseReport.value[key].totalAmount +=
            countermanWiseSeatReport.discount === 1
              ? data.totalAmount
              : data.payable;
          classWiseReport.value[key].totalTicket += data.totalSeat;
          classWiseReport.value[key].totalDiscount += data.discount;
        });
      }
    };

    const goBack = () => {
      emit("backToCounterWiseReport", true);
    };

    const exportTable = (type) => {
      for (const key in classWiseReport.value) {
        exportExcel(
          type,
          key + "_Admin_CounterWise_Seat_Report.",
          undefined,
          key
        );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      countermanWiseSeatReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalTicket,
      countermanNameProps,
      summary,
      getSeatDetails,
      options,
      cancelledBy,
      totalDiscount,
      dateFormat,
      tripDateFormat,
      classWiseReport,
      cancelDtTicket,
      ticketForCancel,
      isCancel,
      ticketCancelled,
      hasPermission,
      Admin,
    };
  },
};
</script>
<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}
.header_cancel_button {
  margin: 0 2px;
  border: 2px solid #7e7d78;
  border-radius: 4px;
  background-color: #7e7d78;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_cancel_button:hover,
.header_cancel_button:focus {
  border: 2px solid #7e7d78;
  background-color: #fff;
  color: #7e7d78;
  transition: 0.4s;
  outline: 0;
}
</style>
