<template>
  <Modal size="xl" @close="closeModal">
    <template v-slot:title> Reservation</template>
    <template v-slot:body>
      <div class="row my-4">

        <div class="col-md-6 text-muted order-md-0 order-1">
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("Name", {}, {locale: selectedLanguage})}}<i :class="'fas fa-file-signature'"
                                               class="ms-1 me-1 "></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.passengerName }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("Mobile", {}, {locale: selectedLanguage})}}<i :class="'fas fa-mobile-alt'"
                                                 class="ms-1 me-1 "></i>:
            </div>
            <div class="col-6 text-start ">{{ reservationPayload.passengerPhone }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("Gender", {}, {locale: selectedLanguage})}}<i :class="'fas fa-venus-mars'"
                                                 class="ms-1 me-1 text-danger"></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.passengerGender }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("NID", {}, {locale: selectedLanguage})}}<i :class="'far fa-flag'"
                                              class="ms-1 me-1 text-danger"></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.passengerNID }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("Address", {}, {locale: selectedLanguage})}}<i :class="'far fa-address-card'"
                                                  class="ms-1 me-1 "></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.passengerAddress }}</div>
          </div>

          <div class="row mb-3">
            <div class="col-6 text-end">{{t("From Stoppage", {}, {locale: selectedLanguage})}}<i :class="'fas fa-map-marker-alt'"
                                                        class="ms-1 me-1 text-danger"></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{
                stoppagesList[reservationPayload.fromStoppage]
              }}
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">{{t("To Stoppage", {}, {locale: selectedLanguage})}}<i :class="'fas fa-map-marker-alt'"
                                                      class="ms-1 me-1 text-danger"></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{
                stoppagesList[reservationPayload.toStoppage]
              }}
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">Boarding Place<i :class="'fas fa-search-location'"
                                                         class="ms-1 me-1 "></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.boardingPlace }}</div>
          </div>
          <div class="row mb-3">
            <div class="col-6 text-end">Dropping Point<i :class="'fas fa-search-location'"
                                                         class="ms-1 me-1 "></i>:
            </div>
            <div class="col-6 text-start text-capitalize p-0">{{ reservationPayload.droppingPoint }}</div>
          </div>
        </div>
        <div class="col-md-6 order-md-1 order-0 mb-5 mb-md-0">
          <div class="card">
            <div class="card-header">
              Selected Seats : <span v-for="(seat, index) in reservationPayload.seatNumbers" :key="index"
                                     class="badge rounded-pill bg-info text-dark me-1">{{
                seat
              }} </span>
            </div>
            <div class="card-body">
              <form>
                <div class="form-group row my-md-3">
                  <ModalInput
                      v-bind:id="'payable'"
                      v-model="reservationPayload.payable"
                      disabled
                      v-bind:label="'Total Payable'"
                      v-bind:placeholder="'Total Payable'"
                      v-bind:type="'number'"

                  >
                  </ModalInput>
                </div>
                <div class="form-group row">
                  <ModalInput
                      v-bind:id="'partialpayment'"
                      v-model="reservationPayload.paid"
                      minNumber
                      v-bind:label="'Partial Payment'"
                      v-bind:placeholder="'Enter Partial Amount'"
                      v-bind:type="'number'"
                  >
                    <template v-slot:error>
                      <div v-if="reservationError" class="text-danger text-start">{{ reservationError }}</div>
                    </template>
                  </ModalInput>
                </div>
                <div class="form-group row my-1">
                  <label class="col-sm-3 col-form-label"></label>
                  <div class="col-sm-9">

                    <button
                        class="mt-md-3 py-2 submit_button font_detail float-end"
                        @click.prevent="reserveTicket"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import {ref, inject, watch} from "vue";
import store from "@/store";
import {useI18n} from "vue-i18n";

export default {
  name: "ReservationModal",
  props: {
    passengerDetails: Object,
    stoppages: Object
  },
  emits: ['refreshPageGoBack'],
  setup(props, {emit}) {
    const {t, locale} = useI18n();
    const reservationPayload = ref(props.passengerDetails)
    const stoppagesList = ref(props.stoppages)

    let reservationError = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    watch(() => reservationPayload.value.paid, (paid) => {
      if (paid >= reservationPayload.value.payable) {
        reservationError.value = 'Partial Payment must be less than Payable'
      } else {
        reservationError.value = ''
      }
    })
    const reserveTicket = () => {
      if (!reservationError.value) {
        showLoader()
        store.dispatch("reserveTicket", reservationPayload.value)
            .then(() => {
              //emit("refreshPageGoBack")
              refreshPageGoBack()
              store.commit("resetTicketDetails")
              store.commit("setResetStatus", true)
              hideLoader();
            })
            .catch((error) => {
              if(error.status === 401) {
                reserveTicket()
              }else {
                refreshPageGoBack()
                store.commit("resetTicketDetails")
                store.commit("setResetStatus", true)
                hideLoader();
              }
            });
        emit("update:modelValue", false)
      }
    }

    const refreshPageGoBack = () => {
      const payload = {
        tripId: reservationPayload.value.trip,
        fromStoppage: '',
        toStoppage: '',
        boarding: ''
      }
      store.dispatch("getTicketByTripNew", payload)
    };

    return {
      reserveTicket,
      closeModal,
      reservationPayload,
      reservationError,
      t,
      locale,
      stoppagesList
    };
  },
};
</script>

<style scoped></style>
