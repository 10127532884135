<template>
  <Modal @close="closeModal">
    <template v-slot:title>Trip Cancel</template>
    <template v-slot:body>
      <h4 v-if="props.trip.value.digitalTicketing">
        <div class="text-danger fw-normal " style="font-size: 16px; line-height: 1.6;">এই ট্রিপটি ডিজিটাল টিকেটিং এর জন্য বরাদ্য। <br/>
          এই ট্রিপে অনলাইন থেকে {{dtAndQrTicketCount.numberOfDTTickets}} টি টিকেট কাটা হয়েছে।
        </div>
      </h4>
      <template v-if="dtAndQrTicketCount.numberOfQrTickets">
        <h4>
          <div class="text-danger fw-normal " style="font-size: 16px; line-height: 1.6;">
            এই ট্রিপে QR থেকে {{dtAndQrTicketCount.numberOfQrTickets}} টি টিকেট কাটা হয়েছে।
          </div>
        </h4>
        <span> Trip Cancellation Requires Canceling the Active Digital Payment Ticket First</span>
      </template>
      <template v-if="!dtAndQrTicketCount.numberOfQrTickets">
        Are you sure you want to cancel this trip?
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <div class="float-end">
              <input
                  type="submit"
                  class="mt-3 me-5 py-2  submit_button  font_detail"
                  value="No"
                  @click="modalClose"
              />
              <input
                  type="submit"
                  class="mt-3 py-2 submit_button font_detail"
                  value="Yes"
                  @click.prevent="tripCancel"
              />
            </div>
          </div>
        </div>
        </template>
    </template>
  </Modal>
</template>

<script>
import {inject, onBeforeMount,ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
export default {
  name: "TripCancelModal",
  props: {
    trip: Object,
    company: String,
  },
  emits: ['getAllTrip'],
  setup(props, { emit }) {
    let route = useRoute();
    const companyId = ref(props.company)
    const tripId = ref(props.trip.value._id)
    const dtAndQrTicketCount = ref({})
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const tripCancel = () => {
      showLoader();
      emit("close");
      store
        .dispatch("cancelTrip", {
          payload: { id: tripId.value, companyId: route.query.id },
        })
        .then(() => {
          store.commit('removeTrip',tripId.value )
          emit("close");
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if(error.status===401){
              tripCancel()
          }
          // emit("getAllTrips");
          // emit("close");
        });
    };

    const getDtTicketCount = () => {
      showLoader();
      store
          .dispatch("getDtAndQRTicketCount", {
            tripId: tripId.value, companyId: companyId.value
          })
          .then((res) => {
            dtAndQrTicketCount.value = res
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if(error.status===401){
              getDtTicketCount()
            }
          });
    };

    const modalClose = () => {
      emit('close')
    }

    onBeforeMount(()=>{
      // if(props.trip.value.digitalTicketing) {
      //   getDtTicketCount()
      // }
      getDtTicketCount()
    })

    return {
      closeModal,
      tripCancel,
      props,
      modalClose,
      dtAndQrTicketCount
    };
  },
};
</script>

<style scoped></style>
