<template>
  <div>
    <div v-if="addUpdate">
      <RoutesAddUpdateModal
        v-model="addUpdate"
        v-bind:route="routeId"
        v-bind:company="pagination.companyId"
        @getAllDtRoutes="getAllDtRoutes"
      />
    </div>
    <div v-if="boardingPlace">
      <BoardingPlace v-bind:routeId="routeId" @close="close" @toggle="toggle" />
    </div>
    <div v-else-if="droppingPlace">
      <DroppingPlace v-bind:routeId="routeId" @close="close" @toggle="toggle" />
    </div>
    <div v-else>
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
          <button class="edit_table_data font_detail" @click="toggle">
            <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
          </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getDtRoutes">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">NAME</td>
            <td class="font_detail">STATUS</td>
            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(routes, index) in $store.getters.getDtRoutes"
            :key="routes._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">{{ routes.name }}</td>
            <td>
              <span v-if="routes.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateRoutes(routes._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalDtRoutes"
            :per-page="pagination.limit"
            @paginate="getAllDtRoutes"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import RoutesAddUpdateModal from "./DtRoutesAddUpdateModal";
import { useRoute } from "vue-router";
import BoardingPlace from "./DtBoardingPlace/BoardingPlace";
import DroppingPlace from "./DtDroppingPlace/DroppingPlace";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "DtRoutes",
  components: { RoutesAddUpdateModal, BoardingPlace, DroppingPlace },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: "",
    });
    const addUpdate = ref(false);
    const boardingPlace = ref(false);
    const droppingPlace = ref(false);
    const routeId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const route = useRoute();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllDtRoutes = () => {
      showLoader();
      store
        .dispatch("getDtRoutesByPage", pagination)
        .then(() => {
          getDtStoppageList();
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllDtRoutes();
          }
        });
    };
    const getDtStoppageList = () => {
      store
        .dispatch("getDigitalTicketingAllStoppages", { companyId: pagination.companyId })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getDtStoppageList();
          }
        });
    };

    const close = () => {
      addUpdate.value = false;
      boardingPlace.value = false;
      droppingPlace.value = false;
    };
    const toggle = () => {
      routeId.value = "";
      if (boardingPlace.value || droppingPlace.value) {
        boardingPlace.value = false;
        droppingPlace.value = false;
      } else {
        addUpdate.value = !addUpdate.value;
      }
    };
    const updateRoutes = (id) => {
      routeId.value = id;
      addUpdate.value = !addUpdate.value;
    };
    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllDtRoutes();
      }
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      boardingPlace,
      droppingPlace,
      routeId,
      Admin,
      hasPermission,
      getAllDtRoutes,
      toggle,
      updateRoutes,
      close,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
