<template>
  <div v-if="$store.getters.getCountermanChalanReport">
    <div>
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-slot:button2>
          <button class="edit_table_data font_detail" @click="goBack">
            <i class="fas fa-arrow-left"></i
            ><span class="mx-1 bold">{{
              t("Go Back", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
        </template>
        <template v-slot:button1>
          <button class="edit_table_data font_detail" @click="print">
            <i class="fas fa-print"></i
            ><span class="mx-1 bold">{{
              t("PRINT", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
        </template>
      </FilterTab>
    </div>
    <div class="m-2 p-5" >
      <div style="width:100%; background-color: #f04935;">
        <div
            style="display: flex; justify-content: space-between; color: white; padding-left: 10px; padding-right: 10px;">
          <p style="font-size: 20px; font-weight: 600;">{{ $store.getters.userCompanyData?.name  }}</p>
          <!-- <a href="#" style="font-size: 20px; font-weight: 600; text-align: center; margin-top: 10px;"><img src="" style="height: 20px; width: 20px;" alt="">Company Logo</a> -->
          <p style="font-size: 20px; font-weight: 600;"></p>
          <div>
            <p style="font-size: 14px; font-weight: 600;">printed by: {{$store.getters.userName}}</p>
            <p style="font-size: 14px; font-weight: 600; margin-top: 5px;">{{
                dateFormat(
                    $store.getters.getCountermanChalanReport?.trip
                        ?.tripDateTime
                )
              }}</p>
          </div>
        </div>
      </div>
      <div style="padding: 10px;">

        <div
            style="display: flex; justify-content: space-between; color: black; padding-left: 10px; padding-right: 10px;">
          <div>
            <p style="color: rgb(58, 51, 51);">Trip : <span style="color: black; font-weight: 600;">{{ $store.getters.getCountermanChalanReport?.trip?.tripCode }}</span>
            </p>
            <p style="color: rgb(58, 51, 51);">Route : <span
                style="color: black; font-weight: 600;">{{ $store.getters.getCountermanChalanReport?.trip?.route?.name }}</span></p>
            <p style="color: rgb(58, 51, 51);">Date : <span style="color: black; font-weight: 600;">{{dateFormat($store.getters.getCountermanChalanReport?.trip?.tripDateTime) }}</span></p>
          </div>
          <div>
            <p style="color: rgb(58, 51, 51);">Coach : <span style="color: black; font-weight: 600;">
              {{ $store.getters.getCountermanChalanReport?.trip?.coach?.name }}
            </span></p>
            <p style="color: rgb(58, 51, 51);">Challan Serial : <span
                style="color: black; font-weight: 600;">{{ $store.getters.getCountermanChalanReport?.trip?.tripCode }}</span></p>
            <p style="color: rgb(58, 51, 51);">Bus Type : <span style="color: black; font-weight: 600;">{{ $store.getters.getCountermanChalanReport?.trip?.coachType }}</span></p>
          </div>
        </div>

        <div style="border: 2px solid #C4C4C4; width: 100%; text-align: center;">
          <div style="display:flex; justify-content: space-between;">
            <div style="width: 33%; border-right: 2px solid #C4C4C4;">
              <p style="color: rgb(58, 51, 51);">Supervisor : <span style="color: black; font-weight: 600;">{{$store.getters.getCountermanChalanReport?.trip?.supervisor?.name}}</span></p>
              <p style="color: rgb(58, 51, 51); font-size: 14px;">Mobile : <span
                  style="color: black; font-weight: 600; font-size: 14px;">{{ $store.getters.getCountermanChalanReport?.trip?.supervisor?.phone }}</span></p>
            </div>

            <div style="width: 33%; border-right: 2px solid #C4C4C4;">
              <p style="color: rgb(58, 51, 51);">Driver : <span style="color: black; font-weight: 600;">{{$store.getters.getCountermanChalanReport?.trip?.driver?.name}}</span></p>
              <p style="color: rgb(58, 51, 51); font-size: 14px;">Mobile : <span
                  style="color: black; font-weight: 600; font-size: 14px;">{{ $store.getters.getCountermanChalanReport?.trip?.driver?.phone }}</span></p>
            </div>
            <div style="width: 33%;">
              <p style="color: rgb(58, 51, 51);">Reg.No : <span style="color: black; font-weight: 600;">{{ $store.getters.getCountermanChalanReport?.trip?.coach?.registrationNumber }}</span></p>
              <p style="color: rgb(58, 51, 51); font-size: 14px;"> <span
                  style="color: black; font-weight: 600; font-size: 14px;"></span></p>
            </div>
          </div>
        </div>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;">
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;">
            <td colspan="14"
                style="border: 1px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Seat_Ticket_Details", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;">
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Seat", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Issued By", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td   colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("REFERENCED BY", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1" style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("PNR", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              <table width=100%>
                <th style="text-align: center; border-bottom: 2px solid #C4C4C4;" colspan="3">
                  {{ t("Passenger_info", {}, { locale: $store.getters.getLang }) }}

                </th>
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 33%;"> {{ t("Name", {}, { locale: $store.getters.getLang }) }}</td>
                  <td style="border-right: 2px solid #C4C4C4; width: 33%;">{{ t("Sex", {}, { locale: $store.getters.getLang }) }}</td>
                  <td style="width: 33%;">{{ t("Mobile", {}, { locale: $store.getters.getLang }) }}</td>
                </tr>
              </table>
            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Boarding", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Dropping", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Price", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-for="seat in seats"
              :key="seat.index"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.seat }}
            </td>

            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.issuedBy?.name ? seat?.issuedBy?.name : `Jatri(${seat?.userType.toLowerCase()} ticket)`}}

            </td>

            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{seat?.referenced_by?.name}}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.pnrCode }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              <table width="100%">
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 33%;">{{ seat?.passengerName }}</td>
                  <td style="border-right: 2px solid #C4C4C4; width: 33%;">{{ seat?.passengerGender === "Male" ? "M" : "F" }}</td>
                  <td style="width: 33%;">{{ seat?.passengerPhone }}</td>
                </tr>
              </table>
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.boardingPlace }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.droppingPoint }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.price }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.userType === 'ONLINE' ? 0 : seat?.discount }}
            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ seat?.price - (seat?.userType === 'ONLINE' ? 0 : seat?.discount) }}
            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;">

            <td colspan="12"
                style="border-right: 2px solid #C4C4C4; text-align: left; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("SubTotal", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ subTotal }}
            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
              v-if="freeSeats.length !== 0"
          >
            <td colspan="100"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Free_Seats", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
          <template
              v-if="freeSeats.length !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="value in freeSeats"
                :key="value[0]"
            >

              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;"
                  v-for="seat in value"
                  :key="seat.index"
              >
                {{ seat }}
              </td>

            </tr>
          </template>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Total", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Cash", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanCashSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanCashSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Digital", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanQrSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanQrSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Extra_Ticket_Report", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanExtraReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
                {{
                  item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ countermanExtraReportData.finalAmount }}

            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;" v-if="goodsTicket && goodsTicket.length > 0"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;">
            <td colspan="8"
                style="border: 1px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Goods_Ticket_Details", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;">
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Goods Id", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td colspan="1" style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Issued By", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              <table width=100%>
                <th style="text-align: center; border-bottom: 2px solid #C4C4C4;" colspan="3">
                  {{ t("Customer_info", {}, { locale: $store.getters.getLang }) }}

                </th>
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%;">
                    {{ t("Name", {}, { locale: $store.getters.getLang }) }}

                  </td>
                  <td style="width: 50%;">
                    {{ t("Mobile", {}, { locale: $store.getters.getLang }) }}
                  </td>
                </tr>
              </table>
            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Boarding", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Dropping", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Weight", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Charge", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-for="item in goodsTicket"
              :key="item.index"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.goodsId }}

            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.issuedBy?.name }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              <table width="100%">
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%;">{{ item?.passengerName }}</td>
                  <td style="width: 50%;">{{ item?.passengerPhone }}</td>
                </tr>
              </table>
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.boardingPlace }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.droppingPoint }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.goodsWeight }}
            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px;">
              {{ item?.price }}
            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;">
            <td colspan="7"
                style="border-right: 2px solid #C4C4C4; text-align: left; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              {{ goodsTicketPrice }}
            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 10px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 10px 10px; text-align: center;"
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-top:10px; padding-bottom:10px; padding-left: 10px; font-weight: 600; font-size: 20px;">
              <div class="p-3" style="text-align: center">
                <!-- <div style="display: flex; justify-content: space-around"> -->
                <h5 style="padding: 5px">{{ t("Balance", {}, { locale: $store.getters.getLang }) }}</h5>
                <!-- </div> -->
                <!-- <div style="text-align: center"> -->
                <h6 style="font-weight: bold; color: green">
                  {{ t("Received_Amount_Total", {}, { locale: $store.getters.getLang }) }}: {{ receivedAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
                </h6>
                <!-- </div> -->
              </div>

            </td>
          </tr>

        </table>

        <div style="margin-top:10px ; border: 2px solid #C4C4C4; width: 100%;">
          <div
              style="display: flex; justify-content: space-between; align-items: center; margin-top:100px; padding-left: 10px; padding-right: 10px;">
            <!-- 1st sign -->
            <div style="width: 25%; ">
              <div style="border-top: 2px solid black; width: 50%;"> </div>
              <p style="margin-top: 5px; color:black">Signature</p>
              <p style="margin-top: 5px; color:black">(Counter Master)</p>
            </div>

            <!-- 2nd sign -->
            <div style="width: 25%; ">
              <div style="border-top: 2px solid black; width: 50%;"> </div>
              <p style="margin-top: 5px; color:black">Signature</p>
              <p style="margin-top: 5px; color:black">(Guide)</p>
            </div>

            <!-- 3rd sign -->
            <div style="width: 25%; ">
              <div style="border-top: 2px solid black; width: 50%;"> </div>
              <p style="margin-top: 5px; color:black">Signature</p>
              <p style="margin-top: 5px; color:black">(Checker 1)</p>
            </div>

            <!-- 4th sign -->
            <div style="width: 25%; ">
              <div style="border-top: 2px solid black; width: 50%;"> </div>
              <p style="margin-top: 5px; color:black">Signature</p>
              <p style="margin-top: 5px; color:black">(Checker 2)</p>
            </div>
          </div>
        </div>

      </div>
      <div style="width:100%; background-color: #f04935;">
        <div
            style="display: flex; justify-content: space-between; align-items: center; color: white; padding-left: 10px; padding-right: 10px;">
          <p style="font-size: 14px; font-weight: 400; display: flex; column-gap: 10px; justify-content: center; align-items: center;">Powered by: <span><img src="@/assets/images/logo-white.png"  style="width: 40px; height: 20px;" alt=""></span></p>

          <div>
            <a href="https://jatri.co/" style="font-size: 14px; font-weight: 600; color: white; text-decoration: none;">www.Jatri.co</a>
          </div>
        </div>
      </div>
    </div>
<!--    print view start-->
    <div class="p-5 d-none" id="chalanReport">
      <div style="width:100%; background-color: #f04935;">
        <div
            style="display: flex; margin-top: 10px;  justify-content: space-between; color: white; padding-left: 10px; padding-right: 10px;">
          <p style="font-size: 9px; font-weight: 600;">{{ $store.getters.userCompanyData?.name  }}</p>
          <p style="font-size: 9px; font-weight: 600;"></p>
          <div style="display: flex; justify-content: space-between">
            <p style="font-size: 9px; font-weight: 600;">printed by: {{$store.getters.userName}}</p>
            <p style="font-size: 9px; font-weight: 600; margin-left: 10px">{{
                dateFormat(
                    $store.getters.getCountermanChalanReport?.trip
                        ?.tripDateTime
                )
              }}</p>
          </div>
        </div>
      </div>
      <div style="padding:0 5px 0 5px;">

        <div
            style="display: flex; justify-content: space-between; color: black; padding-left: 10px; padding-right: 10px;">
          <div>
            <div style="display: flex; column-gap: 5px">
              <p style="color: rgb(58, 51, 51); font-size: 7px; line-height: 8px">Trip : <span style="color: black; font-weight: 600;font-size: 9px;">{{ $store.getters.getCountermanChalanReport?.trip?.tripCode }}</span>
              </p>
              <p style="color: rgb(58, 51, 51); font-size: 7px; line-height: 8px">Route : <span
                  style="color: black; font-weight: 600; font-size: 9px; line-height: 3px">{{ $store.getters.getCountermanChalanReport?.trip?.route?.name }}</span></p>
              <p style="color: rgb(58, 51, 51); font-size: 7px; line-height: 8px">Date : <span style="color: black; font-weight: 600; font-size: 9px;">{{dateFormat($store.getters.getCountermanChalanReport?.trip?.tripDateTime) }}</span></p>
            </div>
          </div>

          <div>
            <div style="display: flex; column-gap: 5px">
              <p style="color: rgb(58, 51, 51); font-size: 7px; line-height: 8px">Coach : <span style="color: black; font-weight: 600; font-size: 11px;">{{ $store.getters.getCountermanChalanReport?.trip?.coachRegistrationNumber ?? $store.getters.getCountermanChalanReport?.trip?.coach?.name }}</span></p>
              <p style="color: rgb(58, 51, 51); font-size: 7px; line-height: 8px">Challan Serial : <span
                  style="color: black; font-weight: 600; font-size: 7px; line-height: 8px">{{ $store.getters.getCountermanChalanReport?.trip?.tripCode }} ({{ $store.getters.getCountermanChalanReport?.trip?.coachType }})</span></p>
            </div>
          </div>
        </div>

        <div style="border: 1px solid #C4C4C4; display: flex; justify-content: space-around; padding: 0; margin: 0; font-size: 8px; align-items: center; height: 15px">
          <div style="width:33.33%; border-right: 1px solid #C4C4C4; padding: 0; display: flex; justify-content: space-around">
            <p>SuperVisor: {{$store.getters.getCountermanChalanReport?.trip?.supervisor?.name}}</p>
            <p>Mobile: {{$store.getters.getCountermanChalanReport?.trip?.supervisor?.phone}}</p>
          </div>
          <div style="width:33.33%;border-right: 1px solid #C4C4C4; padding: 0; display: flex; justify-content: space-around">
            <p>Driver: {{$store.getters.getCountermanChalanReport?.trip?.driver?.name}}</p>
            <p>Mobile: {{$store.getters.getCountermanChalanReport?.trip?.supervisor?.phone}}</p>
          </div>
          <div style="width:33.33%; display: flex; padding: 0; justify-content: center">
            <p>Reg.No: {{$store.getters.getCountermanChalanReport?.trip?.coach?.registrationNumber}}</p>
          </div>
        </div>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 0;">
          <tr
              style="border: 2px solid #C4C4C4; font-size: 8px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 0; text-align: center; ">
            <td colspan="14"
                style="border: 1px solid #C4C4C4; text-align: center; font-weight: 600; font-size: 10px; padding: 0 !important;">
              {{ t("Seat_Ticket_Details", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;">
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("Seat", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("Issued By", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("REFERENCED BY", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1" style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("PNR", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              <table width=100%>
                <th style="text-align: center; font-size: 9px; border-bottom: 2px solid #C4C4C4;" colspan="3">
                  {{ t("Passenger_info", {}, { locale: $store.getters.getLang }) }}

                </th>
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%; font-size: 8px"> {{ t("Name", {}, { locale: $store.getters.getLang }) }}</td>
                  <td style="border-right: 2px solid #C4C4C4; width: 10%; font-size: 8px">{{ t("Sex", {}, { locale: $store.getters.getLang }) }}</td>
                  <td style="width: 40%; font-size: 8px">{{ t("Mobile", {}, { locale: $store.getters.getLang }) }}</td>
                </tr>
              </table>
            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("Boarding", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("Dropping", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ t("Price", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px; font-size: 8px">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px; font-size: 8px">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
              v-for="seat in seats"
              :key="seat.index"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.seat }}
            </td>

            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.issuedBy?.name ? seat?.issuedBy?.name : `Jatri(${seat?.userType.toLowerCase()} ticket)`}}
            </td>

            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.referenced_by?.name}}
            </td>


            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 8px">
              {{ seat?.pnrCode }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              <table width="100%">
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%; font-size: 8px">{{ seat?.passengerName }}</td>
                  <td style="border-right: 2px solid #C4C4C4; width: 10%; font-size: 8px">{{ seat?.passengerGender === "Male" ? "M" : "F" }}</td>
                  <td style="width: 40%; font-size: 9px">{{ seat?.passengerPhone }}</td>
                </tr>
              </table>
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.boardingPlace }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.droppingPoint }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 8px">
              {{ seat?.price }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 8px">
              {{ seat?.userType === 'ONLINE' ? 0 : seat?.discount }}
            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 8px">
              {{ seat?.price - (seat?.userType === 'ONLINE' ? 0 : seat?.discount) }}

            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;">

            <td colspan="12"
                style="border-right: 2px solid #C4C4C4; text-align: left; padding-left: 10px; font-weight: 600; font-size: 11px;">
              {{ t("SubTotal", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-left: 10px; font-weight: 600; font-size: 11px;">
              {{ subTotal }}
            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 11px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 5px; padding: 0 0 2px 2px; text-align: center;"
              v-if="freeSeats.length !== 0"
          >
            <td colspan="100"
                style="border: 1px solid transparent; text-align: center; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ t("Free_Seats", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
          <template
              v-if="freeSeats.length !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 9px; font-weight: 500;"
                v-for="value in freeSeats"
                :key="value[0]"
            >

              <td style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 9px"
                  v-for="seat in value"
                  :key="seat.index"
              >
                {{ seat }}
              </td>

            </tr>
          </template>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 11px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 5px; padding: 0 0 2px 2px; text-align: center;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Total", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 9px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 9px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanSeatReport &&
              countermanSeatReportData.fare !== 0
            "
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 11px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 5px; padding: 0 0 2px 2px; text-align: center;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Cash", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 9px; font-weight: 600;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanCashSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 9px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanCashSeatReportData.fare !== 0"
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanCashSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>
        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 11px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 5px; padding: 0 0 2px 2px; text-align: center;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ t("Counter_Wise_Seat_Report", {}, { locale: $store.getters.getLang }) }} ({{ t("Digital", {}, { locale: $store.getters.getLang }) }})
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 9px; font-weight: 600;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanQrSeatReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 'Jatri Online' : item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 9px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? 0 : item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.counter === 'ONLINE' ? item?.fare : item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
                {{
                  item?.counter === 'ONLINE' ? item?.fare : item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
              v-if="$store.getters.getCountermanChalanReport.countermanSeatReport &&
              $store.getters.getCountermanChalanReport.countermanCashSeatReport &&
              $store.getters.getCountermanChalanReport.countermanQrSeatReport &&
              countermanQrSeatReportData.fare !== 0"
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ countermanQrSeatReportData.finalAmount }}

            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 20px; padding: 0 0 2px 2px; text-align: center;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="10"
                style="border: 1px solid transparent; text-align: center; padding-left: 10px; font-weight: 600; font-size: 10px;">
              {{ t("Extra_Ticket_Report", {}, { locale: $store.getters.getLang }) }}

            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 600;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px">
              {{ t("Counter", {}, { locale: $store.getters.getLang }) }}
            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("User_Name", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Sold_Seats", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Fare", {}, { locale: $store.getters.getLang }) }} (F)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }} (D)

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Receive_Amount", {}, { locale: $store.getters.getLang }) }} (R) <br />

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Commission", {}, { locale: $store.getters.getLang }) }} (C)

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Final_Amount", {}, { locale: $store.getters.getLang }) }} <br /> (R-C)

            </td>
          </tr>
          <template
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >
            <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
                v-for="item of Object.values($store.getters.getCountermanChalanReport.countermanExtraReport)"
                :key="item"
            >
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.counter }}
              </td>

              <td colspan="1"
                  style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 10px;">
                {{ item?.name }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.soldSeats }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.fare }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.discount }}

              </td>
              <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.receiveAmount }}


              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{ item?.commission }}

              </td>
              <td colspan="2"
                  style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
                {{
                  item?.finalAmount
                }}
              </td>
            </tr>
          </template>
          <tr style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500;"
              v-if="
              $store.getters.getCountermanChalanReport.countermanExtraReport &&
              countermanExtraReportData.fare !== 0
            "
          >

            <td colspan="3"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ countermanExtraReportData.soldSeats }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ countermanExtraReportData.fare }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ countermanExtraReportData.discount }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px">
              {{ countermanExtraReportData.receiveAmount }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 10px;">
              {{ countermanExtraReportData.commission }}

            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 10px;">
              {{ countermanExtraReportData.finalAmount }}

            </td>
          </tr>
        </table>

        <table width="100%" cellspacing="0" cellpadding="0" style="border-collapse: collapse; margin-top: 2px;" v-if="goodsTicket && goodsTicket.length > 0"
        >
          <tr
              style="border: 2px solid #C4C4C4; font-size: 10px; font-weight: 500; background-color: rgb(219, 219, 219); margin-top: 5px; padding: 0 0 2px 2px; text-align: center;">
            <td colspan="8"
                style="border: 1px solid #C4C4C4; text-align: center; font-size: 10px; padding-left: 10px; font-weight: 600;">
              {{ t("Goods_Ticket_Details", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
          <tr style="border: 2px solid #C4C4C4; font-size: 9px; font-weight: 600;">
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px;">
              {{ t("Goods Id", {}, { locale: $store.getters.getLang }) }}

            </td>

            <td colspan="1" style="border-right: 2px solid #C4C4C4; text-align: center;">
              {{ t("Issued By", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center;">
              <table width=100%>
                <th style="text-align: center; font-size: 10px; border-bottom: 2px solid #C4C4C4;" colspan="3">
                  {{ t("Customer_info", {}, { locale: $store.getters.getLang }) }}

                </th>
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%; font-size: 9px">
                    {{ t("Name", {}, { locale: $store.getters.getLang }) }}

                  </td>
                  <td style="width: 50%; font-size: 9px">
                    {{ t("Mobile", {}, { locale: $store.getters.getLang }) }}
                  </td>
                </tr>
              </table>
            </td>

            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Boarding", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ t("Dropping", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px; font-size: 9px">
              {{ t("Weight", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="2" style="border-right: 2px solid #C4C4C4; text-align: center; padding: 0 2px; font-size: 9px">
              {{ t("Charge", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;"
              v-for="item in goodsTicket"
              :key="item.index"
          >
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center;font-size: 9px">
              {{ item?.goodsId }}

            </td>

            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              {{ item?.issuedBy?.name }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px">
              <table width="100%">
                <tr>
                  <td style="border-right: 2px solid #C4C4C4; width: 50%;">{{ item?.passengerName }}</td>
                  <td style="width: 50%;">{{ item?.passengerPhone }}</td>
                </tr>
              </table>
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ item?.boardingPlace }}

            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ item?.droppingPoint }}
            </td>
            <td style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ item?.goodsWeight }}
            </td>
            <td colspan="2"
                style="border-right: 2px solid #C4C4C4; text-align: center; font-size: 9px;">
              {{ item?.price }}
            </td>
          </tr>

          <tr style="border: 2px solid #C4C4C4; font-size: 12px; font-weight: 500;">
            <td colspan="7"
                style="border-right: 2px solid #C4C4C4; text-align: left; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ t("Total", {}, { locale: $store.getters.getLang }) }}

            </td>
            <td colspan="1"
                style="border-right: 2px solid #C4C4C4; text-align: center; padding-left: 10px; font-weight: 600; font-size: 9px;">
              {{ goodsTicketPrice }}
            </td>
          </tr>
        </table>


        <div style="display: flex; justify-content: space-around; border: 2px solid #C4C4C4">
          <small style="font-size: 11px; ">
            {{ t("Balance", {}, { locale: $store.getters.getLang }) }}

          </small>
          <small style="font-size: 9px; color: green">
            {{ t("Received_Amount_Total", {}, { locale: $store.getters.getLang }) }}: {{ receivedAmount }} {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
          </small>
        </div>


        <div style="margin-top:5px ; border: 2px solid #C4C4C4; width: 100%;">
          <div
              style="display: flex; justify-content: space-between; align-items: center; margin-top:5px; padding-left: 10px; padding-right: 10px;">
            <!-- 1st sign -->
            <div style="width: 25%; ">
              <div style="border-top: 1px solid black; width: 50%; margin-top: 5px;"> </div>
              <div style="display: flex; column-gap: 2px">
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">Signature</p>
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">(Counter Master)</p>
              </div>

            </div>

            <!-- 2nd sign -->
            <div style="width: 25%; ">
              <div style="border-top: 1px solid black; width: 50%; margin-top: 5px;"> </div>
              <div style="display: flex; column-gap: 2px">
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">Signature</p>
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">(Guide)</p>
              </div>

            </div>

            <!-- 3rd sign -->
            <div style="width: 25%; ">
              <div style="border-top: 1px solid black; width: 50%; margin-top: 5px;"> </div>
              <div style="display: flex; column-gap: 2px">
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">Signature</p>
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">(Checker 1)</p>
              </div>

            </div>

            <!-- 4th sign -->
            <div style="width: 25%; ">
              <div style="border-top: 1px solid black; width: 50%; margin-top: 5px;"> </div>
              <div style="display: flex; column-gap: 2px">
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">Signature</p>
                <p style="margin-top: 2px; font-size: 9px; line-height: 5px; color:black">(Checker 2)</p>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div style="width:100%; background-color: #f04935;">
        <div
            style="display: flex; margin-bottom: 10px; justify-content: space-between; align-items: center; color: white; padding-left: 10px; padding-right: 10px;">
          <p style="font-size: 9px; font-weight: 400; display: flex; column-gap: 10px; justify-content: center; align-items: center;">Powered by: <span><img src="@/assets/images/logo-white.png"  style="width: 20px; height: 10px;" alt=""></span></p>

          <div>
            <a href="https://jatri.co/" style="font-size: 9px; font-weight: 600; color: white; text-decoration: none; ">www.Jatri.co</a>
          </div>
        </div>
      </div>
    </div>
    <!--    print view emd-->
  </div>
</template>


<script>
import store from "@/store";
import { onBeforeMount, inject, ref, reactive } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
export default {
  props: {
    tripId: String,
  },
  setup(props, { emit }) {
    let tripID = ref(props.tripId);
    let countermanSeatReport = ref({});
    let countermanCashSeatReport = ref({});
    let countermanQrSeatReport = ref({});
    let seats = ref([]);
    let subTotal = ref(0);
    let goodsTicket = ref([]);
    let receivedAmount = ref(0);
    let goodsTicketPrice = ref(0);
    let freeSeats = ref([]);
    let countermanSeatReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanCashSeatReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanQrSeatReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanExtraReport = ref({});
    let countermanExtraReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    let countermanGoodsReport = ref({});
    let countermanGoodsReportData = reactive({
      soldSeats: 0,
      discount: 0,
      fare: 0,
      commission: 0,
      receiveAmount: 0,
      finalAmount: 0,
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const goBack = () => {
      emit("closeChalan");
    };

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const dataCalculate = () => {
      freeSeats.value = store.getters.getCountermanChalanReport.freeSeats;

      const newArr = [];
      while (freeSeats.value.length) newArr.push(freeSeats.value.splice(0, 20));
      freeSeats.value = newArr;

      countermanSeatReport.value = Object.entries(
          store.getters.getCountermanChalanReport.countermanSeatReport
      );
      countermanSeatReport.value?.forEach((item) => {
        countermanSeatReportData.soldSeats += item[1]?.soldSeats;
        countermanSeatReportData.discount += item[1]?.counter === 'ONLINE' ? 0 : item[1]?.discount;
        countermanSeatReportData.fare += item[1]?.fare;
        countermanSeatReportData.commission += item[1]?.commission;
        countermanSeatReportData.receiveAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.receiveAmount;
        countermanSeatReportData.finalAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.finalAmount;
      });

      // qr new field
      countermanCashSeatReport.value = Object.entries(
          store.getters.getCountermanChalanReport.countermanCashSeatReport
      );
      countermanCashSeatReport.value?.forEach((item) => {
        countermanCashSeatReportData.soldSeats += item[1]?.soldSeats;
        countermanCashSeatReportData.discount += item[1]?.counter === 'ONLINE' ? 0 : item[1]?.discount;
        countermanCashSeatReportData.fare += item[1]?.fare;
        countermanCashSeatReportData.commission += item[1]?.commission;
        countermanCashSeatReportData.receiveAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.receiveAmount;
        countermanCashSeatReportData.finalAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.finalAmount;
      });

      countermanQrSeatReport.value = Object.entries(
          store.getters.getCountermanChalanReport.countermanQrSeatReport
      );
      countermanQrSeatReport.value?.forEach((item) => {
        countermanQrSeatReportData.soldSeats += item[1]?.soldSeats;
        countermanQrSeatReportData.discount += item[1]?.counter === 'ONLINE' ? 0 : item[1]?.discount;
        countermanQrSeatReportData.fare += item[1]?.fare;
        countermanQrSeatReportData.commission += item[1]?.commission;
        countermanQrSeatReportData.receiveAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.receiveAmount;
        countermanQrSeatReportData.finalAmount += item[1]?.counter === 'ONLINE' ? item[1]?.fare : item[1]?.finalAmount;
      });


      countermanExtraReport.value = Object.entries(
          store.getters.getCountermanChalanReport.countermanExtraReport
      );
      countermanExtraReport.value.forEach((item) => {
        countermanExtraReportData.soldSeats += item[1]?.soldSeats;
        countermanExtraReportData.discount += item[1]?.discount;
        countermanExtraReportData.fare += item[1]?.fare;
        countermanExtraReportData.commission += item[1]?.commission;
        countermanExtraReportData.receiveAmount += item[1]?.receiveAmount;
        countermanExtraReportData.finalAmount += item[1]?.finalAmount;
      });


      countermanGoodsReport.value = Object.entries(
          store.getters.getCountermanChalanReport.countermanGoodsReport
      );
      countermanGoodsReport.value.forEach((item) => {
        countermanGoodsReportData.soldSeats += item[1]?.soldSeats;
        countermanGoodsReportData.discount += item[1]?.discount;
        countermanGoodsReportData.fare += item[1]?.fare;
        countermanGoodsReportData.commission += item[1]?.commission;
        countermanGoodsReportData.receiveAmount += item[1]?.receiveAmount;
      });

      countermanGoodsReportData.finalAmount =
          countermanGoodsReportData.receiveAmount -
          (countermanGoodsReportData.discount +
              countermanGoodsReportData.commission);

      Object.keys(store.getters.getCountermanChalanReport.seatTickets).forEach(
          (key) => {
            store.getters.getCountermanChalanReport.seatTickets[key].forEach(
                (seat) => {
                  seats.value.push(seat);
                }
            );
          }
      );

      // sorted by seat (string value)
      seats.value.sort((i1, i2) => {
        var m = i1.seat.toLowerCase();
        var n = i2.seat.toLowerCase();
        return m < n ? -1 : m > n ? 1 : 0;
      });

      seats.value.forEach((seat) => {
        subTotal.value += seat.price - (seat.userType === 'ONLINE' ? 0 : seat.discount);
      });

      goodsTicket.value = store.getters.getCountermanChalanReport.goodsTickets;

      goodsTicket.value.forEach((price) => {
        goodsTicketPrice.value += price.price;
      });

      receivedAmount.value =
          countermanSeatReportData.finalAmount +
          countermanExtraReportData.finalAmount +
          countermanGoodsReportData.finalAmount;
    };

    const print = () => {
      let divContents =
          window.document.getElementById("chalanReport").innerHTML;
      var printWindow = window.open();
      var is_chrome = Boolean(window.chrome);
      printWindow.document.write("<html>");
      printWindow.document.write("<head>");
      printWindow.document.write(`<style>
							table {
								page-break-after: auto;
							}
							tr {
								page-break-inside: avoid;
								page-break-after: auto;
							}
							td {
								page-break-inside: avoid;
								page-break-after: auto;
							}
							thead {
								display: table-header-group;
							}
							tfoot {
								display: table-footer-group;
							}
							@media print {
								* {
									-webkit-print-color-adjust: exact !important;
									color-adjust: exact !important;
								}
								@page {
									size: auto;
									margin: 0 20mm 0 20mm;
									padding: 0;
								}

								body {
									padding: 0;
									margin: 0;
									-webkit-print-color-adjust: exact;
								}
							}
							</style>`);
      printWindow.document.write("</head>");
      printWindow.document.write("<body>");
      printWindow.document.write(divContents);
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.focus();
      if (is_chrome) {
        printWindow.onload = function () {
          setTimeout(function () { // wait until all resources loaded
            printWindow.print();  // change window to winPrint
            printWindow.close();// change window to winPrint
          }, 500);
        };
      } else {
        printWindow.print()
        printWindow.close()
      }
      // printWindow.print();
      // printWindow.close();
    };

    const getChalanDetails = () => {
      showLoader();
      store
          .dispatch("getChalanReportsCounterman", { tripId: tripID.value })
          .then(() => {
            dataCalculate();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getChalanDetails();
            }
          });
    };

    onBeforeMount(() => {
      getChalanDetails();
    });
    return {
      t,
      locale,
      goBack,
      dateFormat,
      countermanSeatReportData,
      countermanQrSeatReportData,
      countermanCashSeatReportData,
      countermanExtraReportData,
      countermanGoodsReportData,
      seats,
      subTotal,
      goodsTicket,
      receivedAmount,
      goodsTicketPrice,
      print,
      freeSeats,
    };
  },
};
</script>

<style scoped>
table {
  page-break-after: auto;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
td {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  @page {
    size: A4;
    margin: 0;
  }

  .print_hide {
    display: none !important;
  }

  body {
    padding: 0;
    margin: 0;
    -webkit-print-color-adjust: exact;
  }

  .ticket_head {
    background-color: #EF3A1A;
    -webkit-print-color-adjust: exact;
  }

  .pessenger_copy_logo {
    margin-left: 10px;
  }

  .logo {
    padding-top: 8px;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
  }

  table {
    page-break-after: auto
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  td {
    page-break-inside: avoid;
    page-break-after: auto
  }

  thead {
    display: table-header-group
  }

  tfoot {
    display: table-footer-group
  }

}

</style>
