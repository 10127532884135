<template>
  <div v-if="routefare[0].fareModality.length !== 0">
    <div
      class="card mx-5 my-2 card-header"
      v-for="routeFare in routefare[0].fareModality"
      :key="routeFare.index"
    >
      <div class="row my-1">
        <div>
          {{ t("Route", {}, { locale: $store.getters.getLang }) }}:
          <span class="col-md-1 bold">
            <span
              v-for="[key, value] of Object.entries(
                $store.getters.getStoppagesOwner
              )"
              :key="key.index"
            >
              <span v-if="routeFare.fromStoppageId === key">
                {{ value }}
              </span>
            </span>
            <span
              v-for="[key, value] of Object.entries(
                $store.getters.getStoppagesOwner
              )"
              :key="key.index"
            >
              <span v-if="routeFare.toStoppageId === key"> ->{{ value }} </span>
            </span>
          </span>
        </div>
        <div>
          {{ t("Fare", {}, { locale: $store.getters.getLang }) }}:
          <span v-for="fare in routeFare.fare" :key="fare.index">
            <span v-if="fare.fare > 0">
              ({{ fare.class_name }}: {{ fare.fare }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}),</span
            >
          </span>
        </div>
        <div>
          <span class="font-weight-bold"
            >{{ t("Status", {}, { locale: $store.getters.getLang }) }}:
          </span>
          <span class="bold"
            >{{ routefare[0].status === true ? "Active" : "Inactive" }}
          </span>
        </div>

        <div v-if="hasPermission(Owner.fareWrite)">
          <button
            class="header_edit_button font_detail float-end"
            @click="
              editRouteFare(
                routeFare.fromStoppageId,
                routeFare.toStoppageId,
                routeFare.fare
              )
            "
          >
            <i class="fas fa-pencil-alt"></i
            ><span class="mx-1">{{
              t("Edit", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
          <button
            class="header_delete_button font_detail float-end"
            @click="
              deleteRouteFare(
                routefare[0]._id,
                routefare[0].companyId,
                routefare[0].routeId._id,
                routeFare.fromStoppageId,
                routeFare.toStoppageId,
                routefare[0].status
              )
            "
          >
            <i class="fas fa-trash"></i
            ><span class="mx-1">{{
              t("Delete", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
        </div>
      </div>
    </div>
    <EditRoute
      v-if="modelShow"
      v-model="modelShow"
      :id="fareEditModal.id"
      :companyId="fareEditModal.companyId"
      :routeId="fareEditModal.routeId"
      :fromStoppageId="fareEditModal.fromStoppageId"
      :toStoppageId="fareEditModal.toStoppageId"
      :fare="fareEditModal.fare"
      :statuss="fareEditModal.statuss"
      @getroutefare="getroutefare"
    />
  </div>
</template>

<script>
import store from "@/store";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
const { hasPermission } = permission();
const { Owner } = roles();
import { onBeforeMount, reactive, ref, watch, inject } from "vue";
import EditRoute from "./EditRoute.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "routeFareCard",
  props: {
    id: String,
    routeId: String,
    companyId: String,
    routeFare: [String, Number],
  },
  components: {
    EditRoute,
  },
  setup(props, { emit }) {
    const id = ref(props.id);
    const routefare = ref({});
    const modelShow = ref(false);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const getroutefare = () => {
      routefare.value = store.getters.getOwnerServicesFare(id.value);
    };
    let fareEditModal = reactive({
      id: "",
      companyId: "",
      routeId: "",
      fromStoppageId: "",
      toStoppageId: "",
      fare: "",
      statuss: "",
    });
    watch(
      () => props.routeFare,
      () => {
        getroutefare();
      }
    );
    const deleteRouteFare = (
      id,
      companyId,
      routeId,
      fromStoppageId,
      toStoppageId,
      status
    ) => {
      const deleteRouteFareData = reactive({
        id: id,
        companyId: companyId,
        routeId: routeId,
        fromStoppageId: fromStoppageId,
        toStoppageId: toStoppageId,
        status: status,
      });
      showLoader();
      store
        .dispatch("deleteOwnerServicesRouteFare", deleteRouteFareData)
        .then(() => {
          hideLoader();
          emit("updateFareModality");
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            deleteRouteFare(
              id,
              companyId,
              routeId,
              fromStoppageId,
              toStoppageId,
              status
            );
          }
        });
    };

    const editRouteFare = (routeFromStoppage, routeToStoppage, routeFare) => {
      fareEditModal.fromStoppageId = routeFromStoppage;
      fareEditModal.toStoppageId = routeToStoppage;
      fareEditModal.fare = routeFare;
      fareEditModal.id = routefare.value[0]._id;
      fareEditModal.companyId = routefare.value[0].companyId;
      fareEditModal.routeId = routefare.value[0].routeId._id;
      fareEditModal.statuss = routefare.value[0].status;
      modelShow.value = true;
    };

    const getAllStoppages = () => {
      showLoader();
      store
          .dispatch("getStoppageListOwner",  )
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllStoppages();
            }
          });
    };

    onBeforeMount(() => {
      getAllStoppages();
      getroutefare();
    });

    return {
      t,
      locale,
      routefare,
      hasPermission,
      deleteRouteFare,
      editRouteFare,
      Owner,
      modelShow,
      fareEditModal,
      getroutefare,
    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>
