import {ref, reactive} from "vue";
import store from "@/store";


export const sanitizePhone = (phone) => {
    if (phone.startsWith('088')){
        return phone.slice(3);
    }else {
        return phone;
    }
}

export const addCountryCode = (phone) => {
    if (phone){
        return '+88' + sanitizePhone(phone);
    }
    return '';
}


const getFilterBoardingAndDroppingPlace = () => {
    const passenger = reactive({
        name: "",
        phoneNo: "",
        gender: "Male",
        nid: "",
        address: "",
        fromStoppage: "",
        toStoppage: "",
        boardingPlace: "",
        droppingPoint: "",
        trip: "",
        coach: "",
        ticketType: "SEAT",
    })
    let err = ref("");

    const searchWithPassengerPhone = (e) => {
        e.preventDefault();
        const payload = {
            phone: addCountryCode('0' + passenger.phoneNo)
        }

        store.dispatch("searchWithPassengerPhone", payload).then(res => {
            if(res.data.passenger[0]) {
                passenger.name = res.data.passenger[0]?.name;
                passenger.gender = res.data.passenger[0]?.gender;
                passenger.nid = res.data.passenger[0]?.nid;
                passenger.address = res.data.passenger[0]?.address;
            }
        })
        .catch(error => {
            if(error.status === 401){
                searchWithPassengerPhone(e)
            }
        })
    }

    return {
        passenger,
        searchWithPassengerPhone,
        err
    }
}

export {getFilterBoardingAndDroppingPlace}
