<template>
 <div class="d-flex justify-content-center align-items-center w-100 mt-5">
   <div class="card card-outline-secondary w-50">
     <div class="card-header" style="background: #3b3b3b; color: white">
       <h3 class="mb-0">Change Password</h3>
     </div>
     <div class="card-body">
       <div>
         <div class="form-group">
           <label for="inputPasswordOld">Old Password <span class="text-danger">*</span></label>
           <input
               v-model="dataParams.oldPassword"
               type="password"
               placeholder="Old password"
               autocomplete="on"
               class="form-control font_detail mt-1"
           />
           <Error v-if="errors.oldPassword" v-bind:error="errors.oldPassword"/> 
         </div>
         <div class="form-group">
           <label for="inputPasswordOld">New Password <span class="text-danger">*</span></label>
           <input
               v-model="dataParams.newPassword"
               type="password"
               placeholder="New password"
               autocomplete="on"
               class="form-control font_detail mt-1"
               @keyup="checkConfirmPassword"
           />
           <Error v-if="errors.newPassword" v-bind:error="errors.newPassword"/>
         </div>

         <div class="form-group">
           <label for="inputPasswordOld">Confirm Password <span class="text-danger">*</span></label>
           <input
               v-model="dataParams.confirmPassword"
               type="password"
               placeholder="Confirm password"
               autocomplete="on"
               class="form-control font_detail mt-1"
               @keyup="checkConfirmPassword"
           />
           <Error v-if="errors.confirmPassword" v-bind:error="errors.confirmPassword"/>
           <p v-if="dataParams.confirmPasswordValidation" class="text-danger text-sm">New Password and Confirm Password doesn't match</p>
         </div>
         <div class="form-group d-flex justify-content-end align-items-center">
           <button @click.prevent="updatePassword" class="btn btn-success btn-sm float-right mt-2" type="submit">Update</button>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import { reactive } from "@vue/reactivity";   
import {inject, watch} from "vue";
import store from "@/store";
import useInputValidation from "@/modules/useInputValidations";

export default {
  name: "Profile",
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let {passwordRequiredValidation, errors} = useInputValidation();

    const dataParams = reactive({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      confirmPasswordValidation: false,
    })

    watch(
      () => dataParams.oldPassword,
      () => {
        passwordRequiredValidation("oldPassword", dataParams.oldPassword, "Old password");
      }
    );

    watch(
      () => dataParams.newPassword,
      () => {
        passwordRequiredValidation("newPassword", dataParams.newPassword, "New password");
      }
    );
    watch(
      () => dataParams.confirmPassword,
      () => {
        passwordRequiredValidation("confirmPassword", dataParams.confirmPassword, "Confirm password");
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      passwordRequiredValidation("oldPassword", dataParams.oldPassword, "Old password");
      passwordRequiredValidation("newPassword", dataParams.newPassword, "New password");
      passwordRequiredValidation("confirmPassword", dataParams.confirmPassword, "Confirm password"); 
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    function checkConfirmPassword() {
      if (dataParams.confirmPassword !== '') {
        if (dataParams.newPassword !== dataParams.confirmPassword) {
          dataParams.confirmPasswordValidation = true;
        } else {
          dataParams.confirmPasswordValidation = false;
        }
      }    
    }

    const updatePassword = () => {
      if (validate()) {
        if (dataParams.newPassword !== dataParams.confirmPassword) return; 

        showLoader();
        const payload = {
          oldPassword: dataParams.oldPassword,
          newPassword: dataParams.newPassword,
        };

        store
          .dispatch("updateUserPassword", payload)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            console.log(error);
            hideLoader();
          });
      }
    }

    return {
      dataParams,
      errors,
      checkConfirmPassword,
      updatePassword
    }
  }
  
}
</script>

<style scoped>
.coming-soon {
  padding:50px 100px;
  background-color: #c2c2c2;
}
.form-control{
  focus: outline-none;
}
</style>