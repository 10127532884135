import { dom, library } from "@fortawesome/fontawesome-svg-core"
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mosha-vue-toastify/dist/style.css'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css';
import Pagination from 'v-pagination-3'
import { createApp } from 'vue'
import App from './App.vue'
import layouts from './Plugins/layouts'
import loading from './Plugins/loading'
import options from './Plugins/options'
import Popper from "vue3-popper";
import router from './router'
import store from './store'
import i18n from './i18n'

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(App).use(i18n);
app.use(store);
app.use(router);
app.use(loading);
app.use(layouts);
app.use(options);
app.use(VCalendar, {})
app.component("font-awesome-icon", FontAwesomeIcon);
app.component('pagination', Pagination);
app.component("Popper", Popper);

app.mount('#app')
// createApp(App).mount('#app')
// VUE_APP_API = https://dev.api.intercityv2.jatri.co
