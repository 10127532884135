import store from "@/store";
import router from "@/router";
// import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import web from "@/urls/web";
import SecureLS from "secure-ls";

// const cryptoJs = require("crypto-js");
const ls = new SecureLS({isCompression: false});

export default function tokenCookie() {
    let tokenExpiryTime = null;
    let refreshTokenExpiryTime = null;

    const hasValidToken = () => {
        return new Promise((resolve) => {
            if (getToken()) {
                resolve(true)
            }else if (getRefreshToken()) {
                store.dispatch('refreshToken', {refreshToken: getRefreshToken()}).then(() => {
                    resolve(true)
                }).catch(() => {
                    removeCookies()
                    store.reset()
                    router.push(web.Default.LOGIN_URL.path).catch(error => (console.log(error)))
                    ls.remove('intercity')
                    resolve(false)
                });
            } else {
                removeCookies()
                store.reset()
                router.push(web.Default.LOGIN_URL.path).catch(error => (console.log(error)))
                ls.remove('intercity')
                resolve(false)
            }
        });

    };

    // const encryptWithAES = (text) => {
    //     const passphrase = process.env.VUE_APP_TOKEN_ENCRYPTION_CODE;
    //     return cryptoJs.AES.encrypt(text, passphrase).toString();
    // };
    //
    // const decryptWithAES = (ciphertext) => {
    //     const passphrase = process.env.VUE_APP_TOKEN_ENCRYPTION_CODE;
    //     const bytes = cryptoJs.AES.decrypt(ciphertext, passphrase);
    //     return bytes.toString(cryptoJs.enc.Utf8);
    // };

    const setCookies = (data) =>{
        let token = data.token;
        let refreshToken = data.refreshToken;
        setTokenExpiryTime(jwt_decode(token).exp)
        setRefreshTokenExpiryTime(jwt_decode(refreshToken).exp)
        setToken(token)
        setRefreshToken(refreshToken)
    }

    const removeCookies = () =>{

        // Object.keys(Cookies.get()).forEach(function(cookieName) {
        //     Cookies.remove(cookieName);
        // });
        localStorage.clear();
        setTokenExpiryTime(null)
        setRefreshTokenExpiryTime(null)
    }

    const setTokenExpiryTime = (time) =>{
        // time? tokenExpiryTime = new Date(new Date().getTime() + 10 * 1000) : time;
        time? tokenExpiryTime = new Date(time * 1000) : time;
    }
    const setRefreshTokenExpiryTime = (time) =>{
        // time? refreshTokenExpiryTime = new Date(new Date().getTime() + 15 * 1000) : time;
        time? refreshTokenExpiryTime = new Date(time * 1000) : time;
    }
    const getTokenExpiryTime = () =>{
        return tokenExpiryTime
    }
    const getRefreshTokenExpiryTime = () =>{
        return refreshTokenExpiryTime
    }
    const setToken = (token) => {
        // Cookies.set('intercity_access_token', token, {expires: getTokenExpiryTime()});
        localStorage.setItem('intercity_access_token', token);
    };
    const getToken = () => {
        // return Cookies.get('intercity_access_token');
        return localStorage.getItem('intercity_access_token');
    };
    const removeToken = () => {
        // return Cookies.remove('intercity_access_token');
        localStorage.removeItem('intercity_access_token');
    };

    const setRefreshToken = (token) => {
        // Cookies.set('refreshToken', token, {expires: getRefreshTokenExpiryTime()});
        localStorage.setItem('refreshToken', token);
    };
    const getRefreshToken = () => {
        // return Cookies.get('refreshToken');
        return localStorage.getItem('refreshToken');
    };


    return {
        setCookies,
        removeCookies,
        hasValidToken,
        removeToken,
        getToken,
        getRefreshToken,
        getTokenExpiryTime,
        getRefreshTokenExpiryTime,
    };
}

