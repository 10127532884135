<template>
  <div class="row px-0 m-0" id="ticketPrintPreview">
    <div
      class="col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1 px-0"
    >
      <span class="fw-bold">{{
        t("ticket_information", {}, { locale: $store.getters.getLang })
      }}</span>
      <div
        class="card card-body bg-dark border-3 rounded-3 border-secondary px-0"
      >
        <div class="row px-0 my-1">
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <div class="row px-0" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("PNR", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.pnrCode
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Trip_Date", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">
                  {{ purchasedTicket.boardingTripDateTime ? tripDateFormat(purchasedTicket.boardingTripDateTime) :  tripDateFormat(purchasedTicket.trip.tripDateTime)}}
                </span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("From", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.fromStoppage.name
                }}</span>
                <br />

                <span class="orange-bold-text ticket-info"
                  >({{ purchasedTicket.boardingPlace }})</span
                >
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("To", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.toStoppage.name
                }}</span>
                <br />
                <span class="orange-bold-text ticket-info"
                  >({{ purchasedTicket.droppingPoint }})</span
                >
              </div>
            </div>
            <!-- <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Boarding Point</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.boardingPlace
                }}</span>
              </div>
            </div> -->
            <!-- <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Dropping Point</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.droppingPoint
                }}</span>
              </div>
            </div> -->
            <div class="row px-0 mt-1" v-if="type === 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Goods_Weight", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.goodsWeight
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1" v-if="type === 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Goods ID", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.goodsId
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Name", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerName
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Phone", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerPhone
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mt-1">
            <div class="row px-0" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Seat Class", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span
                  class="orange-bold-text ticket-info"
                  v-if="purchasedTicket.seatClass !== ''"
                  >{{ purchasedTicket.seatClass }}</span
                >
                <span class="orange-bold-text ticket-info" v-else>{{
                  t("Mixed", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Total Seats", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.totalSeat
                }}</span>
              </div>
            </div>
            <div
              class="row px-0 mt-1"
              v-if="type === 'lastTicket' || type === 'ticket'"
            >
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Seats", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.seatNumbers
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Fare", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.totalAmount
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Discount", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.discount
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Payable", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.payable
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info"
                  >{{ t("Paid", {}, { locale: $store.getters.getLang }) }}
                </span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="orange-bold-text ticket-info">{{
                  purchasedTicket.paid
                }}</span>
              </div>
            </div>
<!--            <div class="row px-0 mt-1">-->
<!--              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">-->
<!--                <span class="text-info ticket-info">{{-->
<!--                  t("Dues", {}, { locale: $store.getters.getLang })-->
<!--                }}</span>-->
<!--              </div>-->
<!--              <div class="col-8 col-md-8 col-lg-9 text-start">-->
<!--                <span class="orange-bold-text ticket-info">{{-->
<!--                  purchasedTicket.dues-->
<!--                }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row px-0 mt-1">-->
<!--              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">-->
<!--                <span class="text-info ticket-info">{{-->
<!--                  t("Refund", {}, { locale: $store.getters.getLang })-->
<!--                }}</span>-->
<!--              </div>-->
<!--              <div class="col-8 col-md-8 col-lg-9 text-start">-->
<!--                <span class="orange-bold-text ticket-info">{{-->
<!--                  purchasedTicket.refund-->
<!--                }}</span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Issued By", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.issuedBy.name
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">{{
                  t("Ticket_Date", {}, { locale: $store.getters.getLang })
                }}</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  dateFormat(purchasedTicket.ticketDateTime)
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-4">
            <!-- <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Name</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerName
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Phone</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerPhone
                }}</span>
              </div>
            </div> -->
            <!-- <div class="row px-0 mt-1" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Gender</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerGender
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">NID</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerNID
                }}</span>
              </div>
            </div>
            <div class="row px-0 mt-1" v-if="type !== 'goodsTicket'">
              <div class="col-4 col-md-4 col-lg-3 border-1 border_end text-end">
                <span class="text-info ticket-info">Address</span>
              </div>
              <div class="col-8 col-md-8 col-lg-9 text-start">
                <span class="text-white ticket-info">{{
                  purchasedTicket.passengerAddress
                }}</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">{{
        t("ACTION", {}, { locale: $store.getters.getLang })
      }}</span>
      <div class="card card-body">
        <div class="row px-0">
          <div class="col-md-12 col-lg-6 col-xl-6 col-6">
            <p class="text-center font_detail m-0">
              {{ t("Go Back", {}, { locale: $store.getters.getLang }) }}
            </p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left px-0-left"></i>
            </button>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6 col-6">
            <p class="text-center font_detail m-0">
              {{ t("PRINT", {}, { locale: $store.getters.getLang }) }}
            </p>
            <p style="display: none" id="purchasedTicket">
              {{ purchasedTicket }}
            </p>
            <p style="display: none" id="type">{{ type }}</p>
            <p style="display: none" id="printCount">{{ printCount }}</p>
            <p style="display: none" id="once">{{ once }}</p>
            <button
              class="confirm_button w-100"
              onclick="PrintAndroid(document.getElementById('purchasedTicket').innerHTML,document.getElementById('type').innerHTML,document.getElementById('printCount').innerHTML,document.getElementById('once').innerHTML)"
              @click="submitPrintCount"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <LaserPrinter :purchasedTicket="purchasedTicket" />
  </div>
</template>

<script>
import {ref, inject, onMounted, computed} from "vue";
import moment from "moment";
import store from "@/store";
import { useI18n } from "vue-i18n";
import LaserPrinter from "@/views/Counterman/Dashboard/TicketBooking/LaserPrint";
export default {
  name: "TicketPrintPreview",
  components: {LaserPrinter},
  props: {
    ticket: Object,
    ticketType: String,
  },
  setup(props, { emit }) {
    let purchasedTicket = ref(props.ticket);
    let type = ref(props.ticketType);
    const printCount = ref("");
    const once = ref(1);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const tripDateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    // const dateFormatBangla = (date) => {
    //   let newDate = new Date(String(date)).toLocaleDateString("bn-BD", {
    //     hour12: true,
    //     timeZone: "UTC",
    //     day: "2-digit",
    //     month: "short",
    //     year: "numeric",
    //   });
    //   return newDate.replace(" ", "-").replaceAll(", ", "-");
    // };
    // const tripDateFormatBangla = (date) => {
    //   return new Date(String(date)).toLocaleDateString("bn-BD", {
    //     hour12: true,
    //     timeZone: "UTC",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   });
    // };

    // const tripDateTimeFormatBangla = (date) => {
    //   return new Date(String(date)).toLocaleString("bn-BD", {
    //     hour12: true,
    //     timeZone: "UTC",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //     day: "2-digit",
    //     month: "short",
    //     year: "numeric",
    //   });
    // };

    const submitPrintCount = () => {
      showLoader();
      if (store.getters.userType === "Counterman" && type.value === 'lastTicket') {
        once.value += 1;
        store
            .dispatch("ticketPrintCount", { ticketId: props.ticket._id })
            .then(() => {
              hideLoader();
              goBack();
            })
            .catch(() => {
              hideLoader();
            });
      } else if (store.getters.userType === "Agent") {
        once.value += 1;
        store
            .dispatch("ticketPrintCountAgentIntercity", {
              ticketId: props.ticket._id,
            })
            .then(() => {
              goBack();
              hideLoader();
            })
            .catch(() => {
              hideLoader();
            });
      } else {
        hideLoader();
        goBack();
      }
    };
    const goBack = () => {
      emit("close");
    };
    const userInfo = computed(() => store.getters.getUserInfo)
    onMounted(() => {
      const ticketPrint = setTimeout(() => {
        if(userInfo.value.printerType === "laser_printer") {
          printTicket()
        } else if(userInfo.value.printerType === "" && userInfo.value.company.printerType === "laser_printer") {
          printTicket()
        }
        clearTimeout(ticketPrint)
      }, 300)
    })

    const printTicket = () => {
      let divContents = window.document.getElementById("laser-print").innerHTML
      var printWindow = window.open();
      var is_chrome = Boolean(window.chrome);
      printWindow.document.write('<html>')
      printWindow.document.write('<body>')
      printWindow.document.write('<style>')
      printWindow.document.write('@page { size: auto;  margin: 0mm; }')
      printWindow.document.write('</style>')
      printWindow.document.write(divContents)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      if (is_chrome) {
        printWindow.onload = function () {
          const cromeDelay = setTimeout(function () { // wait until all resources loaded
             printWindow.print();  // change window to winPrint
             printWindow.close();// change window to winPrint
            printWindow.onunload = function(){
              goBack();
            }
            clearTimeout(cromeDelay)
          }, 200);
        };
      } else {
        printWindow.print()
        printWindow.close()
        printWindow.onunload = function(){
          goBack();
        }
      }
    }

    return {
      t,
      locale,
      purchasedTicket,
      type,
      dateFormat,
      // dateFormatBangla,
      tripDateFormat,
      // tripDateFormatBangla,
      // tripDateTimeFormatBangla,
      submitPrintCount,
      printCount,
      once,
      goBack,
    };
  },
};
</script>

<style scoped>
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
