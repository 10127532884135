export default {
  boolean: {
    1: "Yes",
    0: "No",
  },
  direction: {
    UP: "Up",
    DOWN: "Down",
  },
  editAccess: {
    1: "Yes",
    0: "No",
  },
  adminDirection: {
    UP: "Up",
    DOWN: "Down",
    BOTH: "Both",
  },
  seatTypes: {
    SEAT: "SEAT",
    EXTRA: "EXTRA",
    GOODS: "GOODS",
  },
  userType: {
    admin: "Admin",
    owner: "Owner",
    counterman: "Counterman",
    agent: "Agent",
  },
  tripStatusBaria: {
    true: "Confirmed",
    false: "Cancelled",
  },
  tripStatus: {
    ACTIVE: { value: "Active", badge: "success" },
    CANCELLED: { value: "Cancelled", badge: "danger" },
    COMPLETE: { value: "Completed", badge: "primary" },
  },
  status: {
    1: "Active",
    0: "Inactive",
  },
  statusTrip: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  commissionType: {
    PERCENTAGE: "Percentage",
    CASH: "Cash",
  },
  reportStatus: {
    1: "Confirmed",
    0: "Cancelled",
  },
  printingType: {
    Individual: "Individual",
    Group: "Group",
  },
  reportByIndividualSeat: {
    1: "Yes",
    0: "No",
  },
  gender: {
    Male: "Male",
    Female: "Female",
  },
  fareType: {
    predefined: "Predefined",
    trip_fare: "Trip Fare",
    instant_fare: "Instant Fare",
  },
  printerType: {
    laser_printer: "Laser Printer",
    desktop_pos: "Desktop POS",
    android_pos: "Android POS",
    bluetooth_pos: "Bluetooth POS",
  },
  countermanPrinterType: {
    T2mini: "T2mini",
    Bluetooth: "Bluetooth",
    USB: "USB",
  },
  ticketCancelReason: {
    "প্রিন্টার সমস্যা": "প্রিন্টার সমস্যা",
    "আসন পরিবর্তন": "আসন পরিবর্তন",
    "ক্লাস পরিবর্তন": "ক্লাস পরিবর্তন",
    "টিকেট ফেরত": "টিকেট ফেরত",
    অন্যান্য: "অন্যান্য",
  },
  ticketCancelReasonDt: {
    "Cancel Request From User": "Cancel Request From User",
    "Cancel By Admin": "Cancel By Admin",
    "Other": "Other",
  },
  discount: {
    0: "No Discount",
    1: "With Discount",
  },
  reportType: {
    ticket: "Ticket",
    tripWiseReport: "Trip Wise Report",
    dailySummaryReport: "Daily Summary Report",
  },
  sequences: {},
  quota: {
    1: "Active",
    0: "Inactive",
  },
  digitalTicketing: {
    1: "Enable",
    0: "Disable",
  },
  coachType: {
    AC: "AC",
    NON_AC: "NON-AC",
  },
  autoRenewalInDays: {
    3: '3 days',
    7: '7 days',
    15: '15 days',
    20: '20 days',
    30: '30 days',
  },
  qrAccess: {
    true: "Yes",
    false: "No",
  },

  convenienceFeeType: {
    FIXED: 'FIXED',
    PERCENTAGE: 'PERCENTAGE'
  },

  reportPaymentType: {
    QR: 'QR',
    DT: 'DT',
    CASH: 'CASH',
  },

  counterReportPaymentType: {
    QR: 'QR',
    CASH: 'CASH',
  },

  QRHistoryStatusType: {
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED',
    INVALID: 'INVALID',
  },

  WithdrawStatus: {
    PENDING: 'PENDING',
    SUCCESSFUL: 'SUCCESSFUL',
    CANCELLED: 'CANCELLED'
  }
};

// 'ACTIVE': {value:"Active",badge:"success"},
// 'CANCELLED': {value:"Cancelled",badge:"success"},
// 'COMPLETE': {value:"Completed",badge:"success"},
