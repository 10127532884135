<template>
  <div v-if="hasPermission(Admin.companyDeployRead)">
    <TicketDesign
        v-if="showModal"
        :layout="layoutId"
        @getAllTicketLayout="getAllTicketLayout"
        @close="close"
    />
    <Table v-else-if="$store.getters.getTicketLayoutList">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">ACTION</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(layout, index) in $store.getters.getTicketLayoutList"
            :key="layout._id"
        >
          <td class="font_detail">
            {{ index + 1 }}
          </td>
          <td class="font_detail">{{ layout.name }}</td>

          <td>
            <button
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="edit_table_data edit_table_button font_detail"
                @click="updateModal(layout._id)"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>

          </td>
        </tr>
      </template>

    </Table>

  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TicketDesign from "@/views/Admin/TicketDesign/TicketDesign";
const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "DesignList",
  props: {
  },
  components: { TicketDesign },
  setup() {

    const layoutId = ref("");
    const showModal = ref(false);
    const manageUrl = web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.name;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllTicketLayout = () => {
      showLoader();
      store
          .dispatch("getAllTicketLayout")
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTicketLayout();
            }
          });
    };

    const updateModal = (value) => {
      layoutId.value = value;
      showModal.value = true;
    };
    const close = () => {
      showModal.value = false
    }


    onMounted(getAllTicketLayout);

    return {
      manageUrl,
      showModal,
      layoutId,
      dropdownVariables,
      Admin,
      hasPermission,
      getAllTicketLayout,
      updateModal,
      close,
    };
  },
};
</script>

<style scoped>
.setting_table_button {
  margin: 1px 2px;
  border: 2px solid #4285f4;
  border-radius: 4px;
  background-color: #4285f4;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.setting_table_button:hover,
.setting_table_button:focus {
  border: 2px solid #4285f4;
  background-color: #fff;
  color: #4285f4;
  transition: 0.4s;
  outline: 0;
}
</style>
