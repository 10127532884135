<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Supervisor</h6></template> -->
      <template v-slot:body> </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Admin.companyDeployWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getSupervisors">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td class="font_detail">MOBILE</td>
          <td class="font_detail">EMAIL</td>
          <td class="font_detail">STATUS</td>
          <td
            v-if="hasPermission(Admin.companyDeployWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>

      <template v-slot:body>
        <tr
          v-for="(supervisor, index) in $store.getters.getSupervisors"
          :key="supervisor._id"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ supervisor.name }}</td>
          <td class="font_detail">{{ supervisor.phone }}</td>
          <td class="font_detail">{{ supervisor.email }}</td>

          <td>
            <span v-if="supervisor.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td v-if="hasPermission(Admin.companyDeployWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(supervisor._id)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalSupervisors"
          :per-page="pagination.limit"
          @paginate="getAllSupervisor"
          :options="options"
        />
      </template>
    </Table>
    <div v-if="showModal">
      <SupervisorAddUpdateModal
        v-model="showModal"
        @getAllSupervisors="getAllSupervisor"
        v-bind:company="companyId"
        v-bind:supervisor="supervisorId"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { useRoute } from "vue-router";
import { onBeforeMount, reactive, ref, inject } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import dropdownVariables from "@/modules/dropdownVariables";
import SupervisorAddUpdateModal from "./SupervisorAddUpdateModal";

const { hasPermission } = permission();
const { Admin } = roles();
export default {
  name: "Supervisor",
  components: {
    SupervisorAddUpdateModal,
  },
  setup() {
    const companyId = ref("");
    const supervisorId = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const showModal = ref(false);

    const pagination = reactive({
      companyId,
      page: 1,
      limit: 25,
    });
    const options = {
      texts: {
        count: "",
      },
    };

    const getAllSupervisor = () => {
      showLoader();
      store
        .dispatch("getSupervisorsByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllSupervisor();
          }
        });
    };

    const updateModal = (value) => {
      supervisorId.value = value;
      showModal.value = true;
    };
    const addModal = () => {
      supervisorId.value = "";
      showModal.value = true;
    };

    onBeforeMount(() => {
      companyId.value = useRoute().query.id;
      getAllSupervisor();
    });
    return {
      companyId,
      supervisorId,
      pagination,
      getAllSupervisor,
      updateModal,
      hasPermission,
      Admin,
      options,
      dropdownVariables,
      showModal,
      addModal,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
