<template>
  <div class="col-sm-3">
    <label :for="inputId" class="col-form-label font_detail"
      >{{ t(inputLabel, {}, { locale: $store.getters.getLang }) }} :</label
    >
  </div>
  <div class="col-sm-9">
    <select
        class="form-select form-control font_detail w-100"
        v-model="input"
        :id="inputId"
    >
      <option v-if="inputDefaultOption" :value="inputDefaultValue">
        {{ t(inputDefaultOption, {}, { locale: $store.getters.getLang }) }}
      </option>
      <option
          v-for="(type, index) in inputDropdown"
          :key="index"
          :value="returnItem ? type[returnItem] : index"
          :disabled="type[showItemName1] === null"
          :style="type[showItemName1] === null && colorDisable"
      >
        <span v-if="showItemName">{{ type[showItemName] }}  {{ (showItemName1 && type[showItemName1] !== null) ? ' - '+ modifyDate(type[showItemName1]) : '' }}</span>
        <span v-else>{{ type }}</span>
      </option>
    </select>
    <slot name="error"></slot>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
export default {
  name: "Dropdown",
  props: {
    modelValue: [String, Number],
    dropdown: Object,
    defaultOption: String,
    defaultValue: String,
    label: String,
    id: String,
    showItem: String,
    showItem1: String,
    returnItem: String,
  },
  setup(props, { emit }) {
    const inputDropdown = ref(props.dropdown);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    const { t, locale } = useI18n();
    const showItemName = ref(props.showItem);
    const showItemName1 = ref(props.showItem1);
    const colorDisable = {
      background: '#eee',
      color: 'black'
    }
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.dropdown,
      (value) => {
        inputDropdown.value = value;
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    const modifyDate = (date) => {
      return dateTimeFormat(date, 0, 'DD MMM YY, LT')
    }
    return {
      t,
      locale,
      inputDropdown,
      inputDefaultOption,
      inputDefaultValue,
      inputLabel,
      inputId,
      input,
      showItemName,
      showItemName1,
      modifyDate,
      colorDisable
    };
  },
};
</script>

<style scoped></style>
