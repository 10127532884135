<template>
  <div class="d-flex justify-content-center align-items-center w-100 mt-5">
    <div class="card card-outline-secondary w-50">
      <div class="card-header" style="background: #3b3b3b; color: white">
        <h3 class="mb-0">Profile</h3>
      </div>
      <div class="card-body">
        <div>
          <div class="form-group d-flex justify-content-center align-items-center gap-2">
            <label class="w-25" for="inputPasswordOld">Name:</label>
            <div
                type="text"
                placeholder="Old password"
                autocomplete="on"
                class="w-75 form-control font_detail mt-1"
            >{{$store.getters.getUserInfo.name}}</div>
          </div>
          <div class="form-group d-flex justify-content-center align-items-center gap-2">
            <label class="w-25" for="inputPasswordOld">User Type:</label>
            <div
                type="text"
                placeholder="Old password"
                autocomplete="on"
                class="w-75 form-control font_detail mt-1"
            >{{$store.getters.getUserInfo.userType}}</div>
          </div>
          <div class="form-group d-flex justify-content-end align-items-center">
            <button @click="$router.push('password-change')" class="btn btn-primary btn-sm float-right mt-2 text-white" type="submit">Change Password</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile"
}
</script>

<style scoped>
.coming-soon {
  padding:50px 100px;
  background-color: #c2c2c2;
}

</style>