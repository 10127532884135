<template>
  <div class="row m-0">
    <div
      class="col-md-10 col-xl-9 col-lg-9 order-sm-2 order-md-1 order-lg-1 order-xl-1 text-center"
    >
      <span class="fw-bold">{{
        t("USER_TRIP_REPORT", {}, { locale: $store.getters.getLang })
      }}</span>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="table-responsive">
          <table class="table table-hover table-striped text-center m-0">
            <thead class="table_header">
              <tr>
                <td class="font_detail">
                  {{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}
                </td>
                <td class="font_detail">
                  {{
                    t("Extra Ticket", {}, { locale: $store.getters.getLang })
                  }}
                </td>
                <td class="font_detail">
                  {{
                    t("Goods Ticket", {}, { locale: $store.getters.getLang })
                  }}
                </td>
              </tr>
            </thead>
            <tbody class="table_body">
              <tr v-for="(ticket, i) in userReport" :key="i">
                <td class="font_detail">{{ ticket.seatTicket }}</td>
                <td class="font_detail">{{ ticket.extraTicket }}</td>
                <td class="font_detail">{{ ticket.goodsTicket }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="col-md-2 col-lg-3 col-xl-3 order-sm-1 order-md-2 order-lg-2 order-xl-2 text-center"
    >
      <span class="fw-bold">{{
        t("ACTIONS", {}, { locale: $store.getters.getLang })
      }}</span>
      <div class="card card-body">
        <div class="row">
          <div class="col-12">
            <p class="text-center font_detail m-0">
              {{ t("GO BACK", {}, { locale: $store.getters.getLang }) }}
            </p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <!-- <div v-if="count < 2" class="col-12"> -->
          <div class="col-12">
            <p class="text-center font_detail m-0">
              {{ t("PRINT", {}, { locale: $store.getters.getLang }) }}
            </p>
            <p style="display: none" id="fullUserReport">
              {{ fullUserReport }}
            </p>
            <p style="display: none" id="type">{{ type }}</p>
            <p style="display: none" id="printCount">{{ count }}</p>
            <button
              class="confirm_button w-100"
              onclick="PrintAndroid(document.getElementById('fullUserReport').innerHTML,document.getElementById('type').innerHTML,document.getElementById('printCount').innerHTML)"
              @click="submitPrintCount"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import moment from "moment";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "UserReportPrint",
  props: {
    report: Object,
    ticketType: String,
    printCount: Number,
  },
  setup(props, { emit }) {
    let userReport = ref(props.report.ticket);
    let fullUserReport = ref(props.report);
    let type = ref(props.ticketType);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const count = ref(props.printCount);
    const { t, locale } = useI18n();
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const submitPrintCount = () => {
      showLoader();
      count.value += 1;
      store
        .dispatch("reportPrintCountIncrease", {
          reportType: "dailySummaryReport",
          dailySummaryDate: moment(new Date()).format("yyyy-MM-DD"),
        })
        .then(() => {
          hideLoader();
          goBack();
        })
        .catch(() => {
          hideLoader();
          goBack();
        });
    };

    const goBack = () => {
      emit("close");
    };

    return {
      t,
      locale,
      userReport,
      type,
      fullUserReport,
      dateFormat,
      submitPrintCount,
      count,
      goBack,
    };
  },
};
</script>

<style scoped>
.table_header {
  background-color: #3561f0;
  color: #fff;
}
.table_body {
  background-color: #fff;
  color: #212529;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
