import apiResponse from "@/modules/apiResponse";
// import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
// import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

// const {successToast} = toast();

const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const { errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
    },
    allStoppage: [],
    numberOfDTTickets: null,
    
    
};
const getters = {
    getOwnerServicesNumberOfDTTickets: (state) => state.numberOfDTTickets,
    getOwnerServicesBoardingPlacesByStoppageId: (state) =>  (id) => {
        return state.allStoppage.filter((item) => item._id === id)[0]?.boarding
    },
    getOwnerServicesDroppingPlacesByStoppageId: (state) =>  (id) => {
        return state.allStoppage.filter((item) => item._id === id)[0]?.dropping
    },
    getOwnerServicesDigitalTicketingAllStoppages: (state) => state.filter.stoppage,
};
const actions = {
    async getOwnerServicesDtTicketCount({commit}, payload) {
        if (await hasValidToken()) {
            
            commit("setNumberOfDtTickets",  0);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DT_TICKET_COUNT_OWNER, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setNumberOfDtTickets", data.numberOfDTTickets);
                            resolve(data.numberOfDTTickets);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getOwnerServicesDigitalTicketingAllStoppages({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllStoppagesInFilter", {});
            commit("setAllDtStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_ALL_STOPPAGE_OWNER, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {};
                            data.dtStoppages.forEach((stoppage) => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setAllStoppagesInFilter", stoppageList);
                            commit("setAllDtStoppages", data.dtStoppages);
                            resolve(data.dtStoppages);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    // new qr and dt ticket count api
    async getOwnerServicesDtAndQRTicketCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_OWNER_DT_AND_QR_TICKET_COUNT, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

};
const mutations = {
    setNumberOfDtTickets: (state, count) => (state.numberOfDTTickets = count),
    setAllDtStoppages: (state, allStoppage) => (state.allStoppage = allStoppage),
    setAllStoppagesInFilter: (state, stoppageList) =>
        (state.filter.stoppage = stoppageList),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
