<template>
  <FilterTab>
    <template v-slot:title
    ><h6>Update Layout</h6></template
    >
    <template v-slot:body> </template>
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i
        ><span class="mx-1 bold"> Go Back </span>
      </button>
    </template>
  </FilterTab>
  <div style="height: 374.4px; width: 796.8px; border: 1px solid black; position: relative" class="m-5" id="laser-print-preview">
    <div style=" height: 100%; display: flex;">
      <div
          style="border-right: 1px solid black;"
          :style="{width: `${layer.width}px`, paddingTop: `${layer.topLayer}px`}"
          v-for="(layer, index) in $store.getters.getTicketLayout.layers"
          :key="index"
      >
        <div v-for="(property, key) in layer.properties" :key="key" :class="property.name ? '' : 'gridClass'"
             :style="{marginLeft: `${property.left}px`, marginTop: `${property.top}px`, fontSize: `${property.fontSize}px`}">
          <div v-if="property.example1 && property.example2 && property.nameValue1 && property.nameValue2"
               :style="{fontWeight: property.fontWeight}">{{ property.example1 }} {{ property.separator }}
            {{ property.example2 }}
          </div>
          <div v-else>
            <span :style="[{width: `${property.nameWidth}px`}, property.name ? {display: 'inline-grid', textAlign: 'left', lineHeight: '12px', wordBreak: 'break-word' } : null]">{{ property.name }}</span>
            <span v-if="property.name"> {{ property.separator + ' ' }} </span>
            <span :style="[{width: `${property.nameValueWidth}px`, fontWeight: property.fontWeight}, property.example1 ? {display: 'inline-grid', textAlign: 'left', lineHeight: '12px', wordBreak: 'break-word' } : null]"
                   > {{ property.example1 }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row px-5">
    <div class="col-md-6">
      <input
          type="button"
          value="Print Preview"
          class="py-2  width-300  "
          @click="printTicket"
      />
    </div>
    <div class="col-md-6 text-end">
      <input
          type="submit"
          class="py-2 mb-2 submit_button width-300  "
          @click="updateTicketDesign"
      />
    </div>
  </div>
  <div style="height: 500px" class="p-5 overflow-auto">
    <div class="mb-5" v-for="(layer, key) in properties" :key="key">
      <div class="row">
        <div class="col-md-1">
          <h5>Layer-{{ key + 1 }}</h5>
        </div>
        <div class="col-md-1">
          <Input
              v-model="layer.topLayer"
              :placeholder="'Enter top Margin'"
              :label="'Top Margin'"
              :id="'toppadding'"
              :type="'number'"
          />
        </div>
        <div class="col-md-1">
          <Input
              v-model="layer.width"
              :placeholder="'Enter Layer Width'"
              :label="'Layer Width'"
              :id="'layerwidth'"
              :type="'number'"
          />
        </div>
      </div>
      <div class="row" v-for="(property, index) in layer.properties" :key="index">
        <div class="col">
          <Input
              v-model="property.name"
              :placeholder="'Enter name'"
              :label="'Name'"
              :id="'nameproperty'"
              :type="'text'"
          />
        </div>
        <div class="col">
          <Input
              v-model="property.example1"
              :placeholder="'Enter example1'"
              :label="'Example1'"
              :id="'example1'"
              :type="'text'"
          />
        </div>
        <div class="col">
          <Dropdown
              v-model="property.nameValue1"
              :label="'Assign Value'"
              :id="'printType'"
              :dropdown="nameValues"
              :hideLabel="'inactive'"
              :defaultOption="'Select Name Value'"
              :defaultValue="''"
          />

        </div>
        <div class="col">
          <Dropdown
              v-model="property.separator"
              :label="'Separator'"
              :id="'printType'"
              :dropdown="separators"
              :hideLabel="'inactive'"
              :defaultOption="'Select separator'"
              :defaultValue="''"
          />
        </div>
        <div class="col">
          <Input
              v-model="property.example2"
              :placeholder="'Enter example2'"
              :label="'Example2'"
              :id="'example2'"
              :type="'text'"
          />
        </div>
        <div class="col">
          <Dropdown
              v-model="property.nameValue2"
              :label="'Assign Value2'"
              :id="'printType'"
              :dropdown="nameValues"
              :hideLabel="'inactive'"
              :defaultOption="'Select Value2'"
              :defaultValue="''"
          />

        </div>
        <div class="col">
          <Input
              v-model="property.nameWidth"
              :placeholder="'width'"
              :label="'Title Width'"
              :id="'leftpadding'"
              :type="'number'"
          />
        </div>
        <div class="col">
          <Input
              v-model="property.nameValueWidth"
              :placeholder="'Enter left padding'"
              :label="'Title Value'"
              :id="'leftpadding'"
              :type="'number'"
          />
        </div>
        <div class="col">
          <Input
              v-model="property.left"
              :placeholder="'Enter left Margin'"
              :label="'Left Margin'"
              :id="'leftpadding'"
              :type="'number'"
          />
        </div>
        <div class="col">
          <Input
              v-model="property.top"
              :placeholder="'Enter top Margin'"
              :label="'Top Margin'"
              :id="'topmargin'"
              :type="'number'"
          />
        </div>
        <div class="col">
          <Dropdown
              v-model="property.fontWeight"
              :label="'Font-Weight'"
              :id="'fontWeight'"
              :dropdown="fontWeights"
              :hideLabel="'inactive'"
              :defaultOption="''"
              :defaultValue="''"
          />

        </div>

        <div class="col">
          <Input
              v-model="property.fontSize"
              :placeholder="'xx'"
              :label="'Font Size'"
              :id="'fontsize'"
              :type="'number'"
          />
        </div>
        <div class="col">
          <button @click="addDesignProperty(key)" type="button" class="btn btn-success mt-4" v-if="index === 0">+
          </button>
          <button @click="removeTicketProperty(key, index)" type="button" class="btn btn-danger mt-4" v-else>-</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, onBeforeMount, ref} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
// import {dateTimeFormat} from "@/helpers/dateTimeFormat";

export default {
  name: "TicketDesign",
  props: {
    layout: [String, Number],
  },
  setup(props, { emit }) {
    const layoutId = ref(props.layout)
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const propertyObject = reactive({
      name: "",
      example1: "",
      nameValue1: "",
      example2: "",
      nameValue2: "",
      left: 10,
      top: 0,
      fontWeight: 0,
      fontSize: 11,
      nameWidth: 66,
      nameValueWidth: 66,
      separator: ':',
    })
    let properties = ref([]);

    const nameValues = reactive({
      fromStoppage: "fromStoppage",
      toStoppage: "toStoppage",
      passengerName: "passengerName",
      passengerPhone: "passengerPhone",
      pnrCode: "pnrCode",
      seatNumbers: "seatNumbers",
      totalAmount: "totalAmount",
      discount: "discount",
      payable: "payable",
      tripDateTime: "tripDateTime",
      boardingTripDateTime: "boardingTripDateTime",
      boardingPlace: "boardingPlace",
      droppingPoint: "droppingPoint",
      coach: "coach",
      issuedBy: "issuedBy",
      referenced_by: "referenced_by",
      createdAt: "createdAt",
      route: "route",
      ticketDateTime: "ticketDateTime",
    })

    const separators = reactive({
      ":": "colon",
      "-": "dash",
    })

    const fontWeights = reactive({
      "400": "normal",
      "500": "bold",
      "700": "bolder",
    })

    const goBack = () => {
      emit("close");
    };

    const updateTicketDesign = () => {
      showLoader();
      store
          .dispatch("updateTicketLayoutByCompany",{...store.getters.getTicketLayout, layers: properties.value})
          .then(() => {
            hideLoader();
            emit("close");
            emit("getTicketLayoutByCompany")
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateTicketDesign();
            }
          });
    }

    const getTicketLayout = () => {
      showLoader();
      store
          .dispatch("getTicketLayout",{ticketLayoutId: layoutId.value})
          .then(() => {
            hideLoader();
            properties.value =  store.getters.getTicketLayout.layers
            // initTicketValue()
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getTicketLayout();
            }
          });
    };

    const addDesignProperty = (index) => {
      properties.value[index].properties.push({...propertyObject})
    }

    const removeTicketProperty = (key, index) => {
      properties.value[key].properties.splice(index, 1);
    }

    const printTicket = () => {
      let data = window.document.getElementById("laser-print-preview").innerHTML
      let divContents = data.replace(/border-right: 1px solid black;/g, 'border-right: 0px solid black;')
      var printWindow = window.open();
      var is_chrome = Boolean(window.chrome);
      printWindow.document.write('<html>')
      printWindow.document.write('<body>')
      printWindow.document.write('<style>')
      printWindow.document.write('@page { size: auto;  margin: 0mm; }')
      printWindow.document.write('</style>')
      printWindow.document.write(divContents)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      if (is_chrome) {
        printWindow.onload = function () {
          const cromeDelay = setTimeout(function () { // wait until all resources loaded
            printWindow.print();  // change window to winPrint
            printWindow.close();// change window to winPrint

            clearTimeout(cromeDelay)
          }, 200);
        };
      } else {
        printWindow.print()
        printWindow.close()

      }
    }

    onBeforeMount(() => {
      getTicketLayout()
    })


    return {
      addDesignProperty,
      properties,
      removeTicketProperty,
      nameValues,
      separators,
      goBack,
      updateTicketDesign,
      fontWeights,
      printTicket,
    };
  },
};
</script>

<style scoped>
.width-300 {
  width: 300px;
}
</style>
