<template>
  <!-- v-if="count < 2" -->
  <div class="row m-0">
    <div class="col-md-10 col-xl-10 col-lg-10 order-md-1 order-lg-1 order-xl-1">
      <span class="fw-bold">Trip Pass History</span>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="table-responsive">
          <table class="table table-hover table-striped text-center m-0">
            <thead class="table_header">
            <tr>
              <td class="font_detail">Counter Name</td>
              <td class="font_detail">Stoppage Name</td>
              <td class="font_detail">Passed By</td>
              <td class="font_detail">Trip Time</td>
            </tr>
            </thead>
            <tbody class="table_body">
            <tr v-for="(history, index) in passHistory" :key="index">
              <td class="font_detail">{{ history.counter }}</td>
              <td class="font_detail">{{ history.stoppage }}</td>
              <td class="font_detail">{{ history.passedBy }}</td>
              <td class="font_detail">{{ dateFormat(history.time) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-xl-2 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">ACTIONS </span>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center font_detail m-0">GO BACK</p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import {inject,ref} from "vue";
export default {
  name: "TripPassHistory",
  props: {
    tripId: Object,
  },
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const passHistory = ref({});
    const getTripPass = () => {
      showLoader()
      store.dispatch("getTripPassDetails", {tripId: props.tripId})
          .then(res => {
            passHistory.value = res.trip.tripPasses
            hideLoader()
          })
    }
    getTripPass();

    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, true)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const goBack = () => {
      emit("hidePassHistory");
    };

    return {
      goBack,
      dateFormat,
      passHistory
    };
  },
};
</script>

<style scoped>
.table_header {
  background-color: #875ee6;
  color: #fff;
}
.table_body {
  background-color: #fff;
  color: #212529;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}
</style>