import apiResponse from "@/modules/apiResponse";
// import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
// import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

// const {successToast} = toast();

const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const {successResponse, errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
        routes: {},
        routeSequence: {},
        fares: {},
    },
    stoppages: {},
    stoppage: {},
    boardingPlaces: [],
    droppingPlaces: [],
    allStoppage: [],
    numberOfDTTickets: null,
    routes: [],
    allRoute: [],
    fares: [],
    dtFareModality: [],
    
};
const getters = {
    getDtFareModality: (state) => state.dtFareModality,
    getDtAllFareForFilter: (state) => state.filter.fares,
    getDtRouteSequenceForFilter: (state) => state.filter.routeSequence,
    getDtRouteListForFilterAdmin: (state) => state.filter.routes,
    getAllDtRoute: (state) => state.allRoute,
    getDtFares: (state) => state.fares.docs,
    getTotalDtFares: (state) => state.fares.totalDocs,
    getDtFare: (state) => (id) => {
        return state.fares.docs.filter((plan) => plan._id === id);
    },

    getDtRoutes: (state) => state.routes.docs,
    getTotalDtRoutes: (state) => state.routes.totalDocs,
    getDtRoute: (state) => (id) =>
        state.routes.docs.filter(function (route) {
            return route._id === id;
        }),
    getDroppingPlacesByStoppageId: (state) =>  (id) => {
        return state.allStoppage.filter((item) => item._id === id)[0]?.dropping
    },
    getNumberOfDTTickets: (state) => state.numberOfDTTickets,
    getBoardingPlacesByStoppageId: (state) =>  (id) => {
        return state.allStoppage.filter((item) => item._id === id)[0]?.boarding
    },
    getDigitalTicketingAllStoppages: (state) => state.filter.stoppage,
    getDigitalTicketingStoppages: (state) => state.stoppages.docs,
    getDigitalTicketingTotalStoppages: (state) => state.stoppages.totalDocs,
    getDigitalTicketingStoppage: (state) => state.stoppage,
    getDigitalTicketingStoppageById: (state) => (id) =>
        state.stoppages.docs.filter(function (stoppage) {
            return stoppage._id === id;
        }),
    getDigitalTicketingBoardingPlaces: (state) => state.boardingPlaces,
    getDigitalTicketingDroppingPlaces: (state) => state.droppingPlaces,
};
const actions = {

    async cancelDtTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.CANCEL_DT_TICKET, payload, {
                        headers: header,
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDtFareById({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DT_FARE_BY_ID, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        commit("setDtFareModality", response.data.dtFare.fareModality)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async getAllDtFare({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DT_FARE, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        commit("setDtFaresInFilter", response.data.dtFares)
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },

    async updateDtFareModality({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DT_ROUTE_FARE, payload, {headers: header})
                    .then((success) => {
                        commit("addSeatFareNew", success.data.fare);
                        successResponse(success);
                        resolve(success.data.fare.fareModality[0].fare);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async deleteDtRouteFare({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_DT_ROUTE_FARE, {data: payload, headers: header})
                    .then((response) => {
                        commit("addSeatFareNew", response.data.fare);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async addDtFareModality({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DT_FARE_MODALITY, payload, {headers: header})
                    .then((success) => {
                        commit("addSeatFareNew", success.data.fare);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDtRouteSequence({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.DT_ROUTE_SEQUENCE, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {

                        let routeList = {};
                        response.data.dtRoute.routeSequence.forEach((route) => {
                            routeList[route._id] = route.name;
                        });
                        commit("setRouteSequences", routeList);
                        resolve(routeList);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async updateDtSeatFare(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DT_SEAT_FARE, payload, {
                        headers: header,
                        params: {fare_id: payload.fare_id},
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async deleteDtSeatFare({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_DT_SEAT_FARE, {headers: header, params: payload})
                    .then((response) => {
                        commit("addSeatFare", response.data.fareList);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async addDtNewSeatFare({commit}, {seatPlan, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DT_SEAT_FARE, seatPlan, {
                        headers: header,
                        params: pagination,
                    })
                    .then((response) => {
                        commit("addSeatFare", response.data.fareList);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getAllDtRoute({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DT_ROUTE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data}) => {
                        let routeList = {};
                        data.dtRoutes.forEach((route) => {
                            routeList[route._id] = route.name;
                        });
                        commit("setAllDtRoutesInFilter", routeList);
                        commit("setAllRoutes", data.dtRoutes);
                        resolve(routeList);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDtSeatFare({commit}, payload) {
        if (await hasValidToken()) {
            commit("addSeatFare", []);

            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DT_SEAT_FARE, {headers: header, params: payload})
                    .then((response) => {
                        commit("addSeatFare", response.data.dtFares);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async updateDtRoute({commit}, {routeData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DT_ROUTES, routeData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setRoute", success.data.updatedRoute);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async addDtRoute({commit}, {routeData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DT_ROUTES, routeData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setRoutes", success.data.routeList);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDtRoutesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRoutes", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DT_ROUTES, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setRoutes", response.data.dtRoutes);
                        resolve(response.data.dtRoutes);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDtTicketCount({commit}, payload) {
        if (await hasValidToken()) {
           
            commit("setNumberOfDtTickets",  0);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DT_TICKET_COUNT, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setNumberOfDtTickets", data.numberOfDTTickets);
                            resolve(data.numberOfDTTickets);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    // new qr and dt ticket count api
    async getDtAndQRTicketCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ADMIN_DT_AND_QR_TICKET_COUNT, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDigitalTicketingAllStoppages({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllStoppagesInFilter", {});
            commit("setAllDtStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_ALL_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {};
                            data.dtStoppages.forEach((stoppage) => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setAllStoppagesInFilter", stoppageList);
                            commit("setAllDtStoppages", data.dtStoppages);
                            resolve(data.dtStoppages);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDigitalTicketingStoppagesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_STOPPAGES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setStoppages", data.dtStoppages);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDigitalTicketingStoppage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setStoppage", data.dtStoppage);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDigitalTicketingStoppage({commit}, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DIGITAL_TICKETING_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setStoppages", success.data.dtStoppages);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDigitalTicketingStoppage({commit}, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DIGITAL_TICKETING_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setStoppageById", success.data.dtStoppages);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDigitalTicketingBoardingPlaces({commit}, payload) {
        if (await hasValidToken()) {
            commit("setBoardingPlaces", []);
            commit("setDefaultBoardingPlace", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_BOARDING_PLACES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setBoardingPlaces", data.boarding);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDigitalTicketingBoardingPlace({commit}, {bPlace}) {
        if (await hasValidToken()) {
            commit("setBoardingPlaces", []);
            commit("setDefaultBoardingPlace", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DIGITAL_TICKETING_BOARDING_PLACE, bPlace, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setBoardingPlace", response.data.boarding);
                      
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDigitalTicketingBoardingPlace({commit}, {bPlace}) {
        if (await hasValidToken()) {
            commit("setBoardingPlaces", []);
            commit("setDefaultBoardingPlace", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DIGITAL_TICKETING_BOARDING_PLACE, bPlace, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setBoardingPlace", response.data.boarding);
                      
                        
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteDigitalTicketingBoardingPlace(_, {bPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_DIGITAL_TICKETING_BOARDING_PLACE, {
                        data: bPlace,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDigitalTicketingDroppingPlaces({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlaces", []);
            commit("setDefaultDroppingPlace", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_DIGITAL_TICKETING_DROPPING_PLACES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDroppingPlaces", data.dropping);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDigitalTicketingDroppingPlace({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlaces", []);
            commit("setDefaultDroppingPlace", null);
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DIGITAL_TICKETING_DROPPING_PLACE, payload, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setDroppingPlaces", response.data.dropping);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDigitalTicketingDroppingPlace({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlace", []);
            commit("setDefaultDroppingPlace", null);
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DIGITAL_TICKETING_DROPPING_PLACE, payload, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setDroppingPlace", response.data.dropping);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteDigitalTicketingDroppingPlace(_, {dPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_DIGITAL_TICKETING_DROPPING_PLACE, {
                        data: dPlace,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
};
const mutations = {
    setDtFareModality: (state, fareModality) => (state.dtFareModality = fareModality),
    setDtFaresInFilter: (state, fares) => (state.filter.fares = fares),
    addSeatFareNew: (state, newSeatFare) =>
        state.fares.docs.filter((element, index) => {
            if (element._id === newSeatFare._id) {
                state.fares.docs.splice(index, 1, newSeatFare);
            }
        }),
    setRouteSequences: (state, routeSequences) => {
        state.filter.routeSequence = routeSequences;
    },
    setAllDtRoutesInFilter: (state, routeList) => (state.filter.routes = routeList),
    setAllRoutes: (state, allRoute) => (state.allRoute = allRoute),
    addSeatFare: (state, fare) => (state.fares = fare),
    setRoute: (state, route) =>
        state.routes.docs.filter((element, index) => {
            if (element._id === route._id) {
                state.routes.docs.splice(index, 1, route);
            }
        }),
    setRoutes: (state, routes) => (state.routes = routes),
    setNumberOfDtTickets: (state, count) => (state.numberOfDTTickets = count),
    setAllDtStoppages: (state, allStoppage) => (state.allStoppage = allStoppage),
    setAllStoppagesInFilter: (state, stoppageList) =>
        (state.filter.stoppage = stoppageList),
    setStoppages: (state, stoppages) => (state.stoppages = stoppages),
    setStoppage: (state, stoppage) => (state.stoppage = stoppage),
    setStoppageById: (state, stoppage) =>
        state.stoppages.docs.filter((element, index) => {
            if (element._id === stoppage._id) {
                state.stoppages.docs.splice(index, 1, stoppage);
            }
        }),
    setBoardingPlaces: (state, boardingPlaces) => {
        state.boardingPlaces = boardingPlaces;
    },
    setBoardingPlace: (state, boardingPlace) =>
        (state.boardingPlaces = boardingPlace),
    setDroppingPlaces: (state, droppingPlaces) =>
        (state.droppingPlaces = droppingPlaces),
    setDroppingPlace: (state, droppingPlace) =>
        (state.droppingPlaces = droppingPlace),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
