<template>
  <!-- v-if="count < 2" -->
  <div class="row m-0">
    <div class="col-md-10 col-xl-9 col-lg-9 order-md-1 order-lg-1 order-xl-1">
      <span class="fw-bold">{{
        t("USER_TRIP_REPORT", {}, { locale: $store.getters.getLang })
      }}</span>
      <div class="card card-body bg-dark border-3 rounded-3 border-secondary">
        <div class="table-responsive">
          <table class="table table-hover table-striped text-center m-0">
            <thead class="table_header">
              <tr>
                <td class="font_detail">{{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}</td>
                <td class="font_detail">{{ t("Extra Ticket", {}, { locale: $store.getters.getLang }) }}</td>
                <td class="font_detail">{{ t("Goods Ticket", {}, { locale: $store.getters.getLang }) }}</td>
              </tr>
            </thead>
            <tbody class="table_body">
              <tr>
                <td class="font_detail">{{ tripTicket?.seatTicket }}</td>
                <td class="font_detail">{{ tripTicket?.extraTicket }}</td>
                <td class="font_detail">{{ tripTicket?.goodsTicket }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-lg-3 col-xl-3 order-md-2 order-lg-2 order-xl-2">
      <span class="fw-bold">{{ t("ACTIONS", {}, { locale: $store.getters.getLang }) }}</span>
      <div class="card card-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center font_detail m-0">{{ t("GO BACK", {}, { locale: $store.getters.getLang }) }}</p>
            <button class="cancel_button w-100" @click="goBack">
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>

          <div class="col-md-12">
            <p class="text-center font_detail m-0">{{ t("PRINT", {}, { locale: $store.getters.getLang }) }}</p>
            <p style="display: none" id="fullReport">{{ fullReport }}</p>
            <p style="display: none" id="type">{{ type }}</p>
            <p style="display: none" id="printCount">{{ count }}</p>
            <button
              class="confirm_button w-100"
              onclick="PrintAndroid(document.getElementById('fullReport').innerHTML,document.getElementById('type').innerHTML, document.getElementById('printCount').innerHTML)"
              @click="submitPrintCount"
            >
              <i class="fas fa-print"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="laser-print" class="d-none">
      <h1>{{ fullReport?.tripReport?.companyName }}</h1>
      <p>Coach: {{ fullReport?.tripReport?.coachName }}</p>
      <p>J.Date: {{ fullReport?.tripReport?.journeyDateTime }}</p>
      <h2>সিট নং: {{ tripTicket?.seats?.join(", ") }}</h2>
      <p>Seat Ticket: {{ tripTicket?.seatTicket }}</p>
      <p>Seat Amount: {{ tripTicket?.seatAmount }}</p>
      <p>Goods Ticket: {{ tripTicket?.goodsTicket }}</p>
      <p>Goods Amount: {{ tripTicket?.goodsAmount }}</p>
      <p>Extra Ticket: {{ tripTicket?.extraTicket }}</p>
      <p>Extra Amount: {{ tripTicket?.extraAmount }}</p>
      <h2>Total Ticket: {{ tripTicket?.seatTicket }}</h2>
      <h2>Total Amount: {{ tripTicket?.seatAmount }}</h2>
      <p>User: {{ fullReport?.tripReport?.countermanName }}</p>
      <p>Counter: {{ fullReport?.tripReport?.companyName }}</p>
      <table style="border-collapse: collapse;" v-if="fullReport?.tripReport?.farewiseticket.length > 0">
        <thead>
          <tr style="border-bottom: 1pt solid #000000">
            <th>Fare</th>
            <th>Count</th>
            <th>Discount</th>
            <th>Commission</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(report, index) in fullReport?.tripReport?.farewiseticket" :key="index">
          <td>{{ report.fare }}:</td>
          <td>{{ report.seatTicket }}</td>
          <td>{{ report.discount }}</td>
          <td>{{ report.commission }}</td>
          <td>{{ report.amount }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted } from "vue";
import moment from "moment";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "TripReportPrintPreview",
  props: {
    trip: Object,
    ticketType: String,
    printCount: Number,
    tripId: [Number, String],
    detailsTrip: Object,
  },
  setup(props, { emit }) {
    let tripTicket = ref(props.trip.tripReport.validTickets);
    let fullReport = computed(() => props.trip);
    let type = ref(props.ticketType);
    let count = ref(props.printCount);
    let idTrip = ref(props.tripId);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const tripDetails = ref(props.detailsTrip);
    const { t, locale } = useI18n();

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    // const dateFormat2 = (date) => {
    //   return moment(String(date))
    //     .utcOffset(0, true)
    //     .format("yyyy-MMM-DD hh:mm:ss A");
    // };

    const submitPrintCount = () => {
      showLoader();
      count.value += 1;
      store
        .dispatch("reportPrintCountIncrease", {
          reportType: "tripWiseReport",
          tripId: idTrip.value,
          tripDateTime: props.trip.tripReport.journeyDateTime,
          route: tripDetails.value.route,
          coach: tripDetails.value.coach,
        })
        .then(() => {
          hideLoader();
          goBack();
        })
        .catch(() => {
          hideLoader();
        });
    };

    const goBack = () => {
      emit("close");
    };

    const userInfo = computed(() => store.getters.getUserInfo)
    onMounted(() => {
      const ticketPrint = setTimeout(() => {
        if(userInfo.value.printerType === "laser_printer") {
          printTicket()
        } else if(userInfo.value.printerType === "" && userInfo.value.company.printerType === "laser_printer") {
          printTicket()
        }
        clearTimeout(ticketPrint)
      }, 300)
    })

    const printTicket = () => {
      let divContents = window.document.getElementById("laser-print").innerHTML
      var printWindow = window.open();
      var is_chrome = Boolean(window.chrome);
      printWindow.document.write('<html>')
      printWindow.document.write('<body>')
      printWindow.document.write('<style>')
      printWindow.document.write('@page { size: auto;  margin-top: 0mm; }')
      printWindow.document.write('</style>')
      printWindow.document.write(divContents)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      if (is_chrome) {
        printWindow.onload = function () {
          setTimeout(function () { // wait until all resources loaded
            printWindow.print();  // change window to winPrint
            printWindow.close();// change window to winPrint
            printWindow.onunload = function(){
              goBack();
            }
          }, 500);
        };
      } else {
        printWindow.print()
        printWindow.close()
        printWindow.onunload = function(){
          goBack();
        }
      }
    }

    return {
      t,
      locale,
      tripTicket,
      type,
      fullReport,
      dateFormat,
      submitPrintCount,
      count,
      idTrip,
      goBack,
    };
  },
};
</script>

<style scoped>
.table_header {
  background-color: #3561f0;
  color: #fff;
}
.table_body {
  background-color: #fff;
  color: #212529;
}
.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}
</style>
