<template>
  <div>
    <div v-if="addUpdate">
      <StoppageAddUpdateModal
        v-bind:stoppage="stoppageId"
        v-bind:company="pagination.companyId"
        @close="close"
        @getAllStoppages="getDigitalTicketingStoppagesByPage"
      />
    </div>
    <div v-if="boardingPlace">
      <BoardingPlace
        v-bind:stoppageId="stoppageId"
        :stoppageName="stoppageName"
        @close="close"
        @toggle="toggle"
      />
    </div>
    <div v-else-if="droppingPlace">
      <DroppingPlace
        v-bind:stoppageId="stoppageId"
        :stoppageName="stoppageName"
        @close="close"
        @toggle="toggle"
      />
    </div>
    <div v-else>
      <FilterTab>
        <!-- <template v-slot:title><h6>Stoppage</h6></template> -->
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
          <button class="edit_table_data font_detail" @click="addModal">
            <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
          </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getDigitalTicketingStoppages">
        {{$store.getters.getDigitalTicketingStoppages}}
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">NAME</td>
<!--            <td class="font_detail">BOARDING PLACES</td>-->
<!--            <td class="font_detail">DROPPING PLACES</td>-->
            <td class="font_detail">STATUS</td>
            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(stoppage, index) in $store.getters.getDigitalTicketingStoppages"
            :key="stoppage._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * pagination.limit }}
            </td>
            <td class="font_detail">{{ stoppage.name }}</td>
<!--            <td class="font_detail">-->
<!--              <button-->
<!--                class="edit_table_data font_detail add_table_button px-2"-->
<!--                @click="-->
<!--                  updateBoardingPlace(-->
<!--                    stoppage._id,-->
<!--                    stoppage.defaultBoardingPlace,-->
<!--                    stoppage.name-->
<!--                  )-->
<!--                "-->
<!--              >-->
<!--                <i class="fas fa-map-marker-alt"></i-->
<!--                ><span class="mx-1">Create</span>-->
<!--              </button>-->
<!--            </td>-->
<!--            <td class="font_detail">-->
<!--              <button-->
<!--                class="edit_table_data font_detail add_table_button px-2"-->
<!--                @click="-->
<!--                  updateDroppingPlace(-->
<!--                    stoppage._id,-->
<!--                    stoppage.defaultDroppingPoint,-->
<!--                    stoppage.name-->
<!--                  )-->
<!--                "-->
<!--              >-->
<!--                <i class="fas fa-map-marker-alt"></i-->
<!--                ><span class="mx-1">Create</span>-->
<!--              </button>-->
<!--            </td>-->
            <td>
              <span v-if="stoppage.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>

            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateModal(stoppage._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalStoppages"
            :per-page="pagination.limit"
            @paginate="getDigitalTicketingStoppagesByPage"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import StoppageAddUpdateModal from "./StoppageAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import BoardingPlace from "./BoardingPlace/BoardingPlace.vue";
import DroppingPlace from "./DroppingPlace/DroppingPlace";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Stoppage",
  components: { StoppageAddUpdateModal, BoardingPlace, DroppingPlace },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 10,
    });
    const addUpdate = ref(false);
    const stoppageId = ref("");
    let defaultIndex = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const options = {
      texts: {
        count: "",
      },
    };

    const boardingPlace = ref(false);
    const droppingPlace = ref(false);
    const stoppageName = ref("");

    const updateBoardingPlace = (id, index, selectedStoppageName) => {
      stoppageId.value = id;
      defaultIndex.value = index;
      boardingPlace.value = true;
      stoppageName.value = selectedStoppageName;
    };
    const updateDroppingPlace = (id, index, selectedStoppageName) => {
      stoppageId.value = id;
      defaultIndex.value = index;
      droppingPlace.value = true;
      stoppageName.value = selectedStoppageName;
    };
    const getDigitalTicketingStoppagesByPage = () => {
      showLoader();
      store
        .dispatch("getDigitalTicketingStoppagesByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getDigitalTicketingStoppagesByPage();
          }
        });
    };
    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      stoppageId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      stoppageId.value = id;
      addUpdate.value = true;
    };

    const toggle = () => {
      stoppageId.value = "";
      if (boardingPlace.value || droppingPlace.value) {
        boardingPlace.value = false;
        droppingPlace.value = false;
      } else {
        addUpdate.value = !addUpdate.value;
      }
    };

    onBeforeMount(() => {
        getDigitalTicketingStoppagesByPage();
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      stoppageId,
      Admin,
      hasPermission,
      getDigitalTicketingStoppagesByPage,
      addModal,
      updateModal,
      close,
      updateBoardingPlace,
      updateDroppingPlace,
      boardingPlace,
      droppingPlace,
      toggle,
      defaultIndex,
      stoppageName
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
