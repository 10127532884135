import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import moment from "moment/moment";


let reservationSetTimeout;

const checkReservationValidity = (singleTripDetails, refreshPageGoBack, activateReservation) => {
    const reservationCancelTime = singleTripDetails.reservationAutoClearDateTime
    console.log(reservationCancelTime)
    const reservationStatus = singleTripDetails.reservationStatus
    const currentTime = dateTimeFormat(new Date(), 6);
    const tripTimeDifference = moment(dateTimeFormat(reservationCancelTime, 0)).diff(moment(currentTime))
    if (reservationStatus && tripTimeDifference  >= 0) {
        if(tripTimeDifference > 2147483647){
            activateReservation(true)
        }else {
            activateReservation(true)
            reservationSetTimeout = setTimeout(() => {
                activateReservation(false)
                refreshPageGoBack()
                clearTimeout(reservationSetTimeout)
            }, tripTimeDifference)
        }

    } else {
        activateReservation(false)
    }
}

export {
    checkReservationValidity, reservationSetTimeout,
}
