<template>
  <form style="padding: 0 50px">
    <div class="auth_form py-3">
      <div class="row my-2 mb-3">
        <div class="col-md-3">
          <Input
              v-model="qrData.convenienceFee"
              v-bind:placeholder="'Enter convenience Fee'"
              v-bind:label="'Convenience  Fee'"
              v-bind:id="'convenienceFee'"
              v-bind:type="'number'"
          />
          <Error v-if="errors.convenienceFee" v-bind:error="errors.convenienceFee" />
        </div>
        <div class="col-md-3">
          <Dropdown
              v-model="qrData.convenienceFeeType"
              v-bind:label="'Convenience Fee Type'"
              v-bind:id="'convenienceFeeType'"
              v-bind:dropdown="convenienceFeeType"
              v-bind:hideLabel="'inactive'"
              v-bind:defaultOption="'Select Convenience Fee Type'"
              v-bind:defaultValue="''"
          />
          <Error v-if="errors.convenienceFeeType" v-bind:error="errors.convenienceFeeType" />
        </div>
        <div class="col-md-3">
          <Input
              v-model="qrData.gatewayBlockTime"
              v-bind:placeholder="'Enter Gateway Block Time'"
              v-bind:label="'Gateway Block Time (Min)'"
              v-bind:id="'gatewayBlockTime'"
              v-bind:type="'number'"
          />
         <Error v-if="errors.gatewayBlockTime" v-bind:error="errors.gatewayBlockTime" />
        </div>
        <div class="col-md-3">
          <Input
              v-model="qrData.userBlockTime"
              v-bind:placeholder="'Enter User Block Time'"
              v-bind:label="'User Block Time (Min)'"
              v-bind:id="'userBlockTime'"
              v-bind:type="'number'"
          />
          <Error v-if="errors.userBlockTime" v-bind:error="errors.userBlockTime" />
        </div>
        <div class="col-md-6">
          <TextArea
              v-model="qrData.description"
              v-bind:placeholder="'Enter description'"
              v-bind:label="'Description'"
              v-bind:id="'description'"
              v-bind:type="'text'"
          />
          <!--          <Error v-if="errors.name" v-bind:error="errors.name" />-->
        </div>
        <div class="col-md-6">
          <TextArea
              v-model="qrData.information"
              v-bind:placeholder="'Enter information'"
              v-bind:label="'Information'"
              v-bind:id="'information'"
              v-bind:type="'text'"
          />
          <!--          <Error v-if="errors.name" v-bind:error="errors.name" />-->
        </div>
      </div>
    </div>
    <div class="auth_form d-flex justify-content-center">
      <input
          type="submit"
          class="py-2 mb-5 submit_button width-300"
          @click.prevent="updateQRSettings"
      />
    </div>
  </form>
</template>

<script>
import {inject, onBeforeMount, reactive} from "vue";
import dropdownVariables from "@/modules/dropdownVariables";
import store from "@/store";
import useInputValidation from "@/modules/useInputValidations";
import toast from "@/modules/Toast";

export default {
  name: "QRSettings",
  setup() {
  const {errorToast} = toast();
  let qrData = reactive({
    convenienceFee: 0,
    convenienceFeeType: "",
    gatewayBlockTime: 0,
    userBlockTime: 0,
    description: "",
    information: ""
  })

    let { requiredValidation, validNumberValidation, validNumberWithZeroValidation, errors } = useInputValidation();
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      validNumberWithZeroValidation("convenienceFee", qrData.convenienceFee);
      requiredValidation("convenienceFeeType", qrData.convenienceFeeType);
      validNumberValidation("gatewayBlockTime", qrData.gatewayBlockTime);
      validNumberValidation("userBlockTime",  qrData.userBlockTime);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const convenienceFeeType = dropdownVariables.convenienceFeeType;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getQRSettingsData = () => {
      showLoader()
      store
          .dispatch("getSettings", {key: 'QR'})
          .then((res) => {
            Object.assign(qrData, res?.value)
          })
          .catch((error) => {
            if (error.status === 401) {
              getQRSettingsData();
            }
          })
          .finally(() => {
            hideLoader();
          })
    }

    const updateQRSettings = () => {
      if (validate()) {
          if(qrData.gatewayBlockTime <= qrData.userBlockTime) {
            return errorToast('Gateway Block Time must be greater than User Block Time');
          }
          showLoader();
          store
              .dispatch("updateSettings", {key: 'QR', value: qrData})
              .then(() => {
              })
              .catch((error) => {
                if (error.status === 401) {
                  updateQRSettings();
                }
              })
              .finally(() => {
                hideLoader();
              })
      }
    }

    onBeforeMount(() => {
      getQRSettingsData()
    })

    return {
      qrData,
      convenienceFeeType,
      getQRSettingsData,
      updateQRSettings,
      showLoader,
      hideLoader,
      errors
    }
  }
}
</script>