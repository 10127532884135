<template>
  <div class="card mt-4 ">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6 fw-bold">QUOTA</div>
        <div class="col-md-6">
          <div class="input-group input-group-sm ">
            <span class="input-group-text" id="inputGroup-sizing-sm">Quota auto clear time(minutes)</span>
<!--            <input-->
<!--                v-model="quotaClearTime"-->
<!--                   placeholder="minute"-->
<!--                   class="form-control"-->
<!--                   aria-label="Sizing example input"-->
<!--                   aria-describedby="inputGroup-sizing-sm"-->
<!--                   type="number"-->
<!--                   min="0"-->
<!--                   oninput="this.value = Math.abs(this.value)">-->
            <input
                v-model="quotaClearTime"
                placeholder="minute"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                type="number"
                >
          </div>
        </div>
      </div>
    </div>
    <div class="card-body scroll-height-300 p-0">
      <div id="quota-table">
        <table>
          <tbody>
          <tr>
            <th class="column-sticky "></th>
            <th v-for="(seat, i) in seatList" :key="i"><span class="bg-seat">{{seat}}</span></th>
          </tr>
          <tr>
            <td class="column-sticky  px-2 bg-seat text-start custom-width"></td>
            <td v-for="(seat, i) in seatList" :key="i">
              <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  :name="seat"
                  id="exampleRadios1"
                  checked
                  @change="manageQuota(null, seat)"
                  :disabled="isDtSeatAndHasNoDtWritePermission(seat, i) === i"
              />
              </div>
            </td>
          </tr>
          <tr v-if="isDtActive && hasPermission(Owner.digitalTicketingWrite)">
            <td class="column-sticky  px-2 bg-seat text-start custom-width">Digital ticketing</td>
            <td v-for="(seat, i) in seatList" :key="i">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    :name="seat"
                    id="exampleRadios1"
                    :checked="checkQuotaForDt(seat)"
                    @change="manageQuota(null, seat, 'dt')"
                    :disabled="!hasPermission(Owner.digitalTicketingWrite)"
                />
              </div>
            </td>
          </tr>
          <template v-if="$store.getters.userCompanyData.allowQuota && hasPermission(Owner.quotaWrite)">
            <tr v-for="(counter, i) in counterList" :key="i">
              <td class="column-sticky px-2 bg-seat text-start custom-width">{{counter.name}} </td>
              <td v-for="(seat, j) in seatList" :key="j">
                <div class="form-check">
                  <input
                      class="form-check-input"
                      type="radio"
                      :name="seat"
                      id="exampleRadios14"
                      :checked="checkQuota(counter,seat)"
                      @change="manageQuota(counter._id, seat)"
                      :disabled="isDtSeatAndHasNoDtWritePermission(seat, i) === i"
                  />
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="seatPlanQuota.length > 0 || seatPlanDt.length > 0" class="card-footer bg-white">
      <div id="quota-table">
        <table>
          <tbody>
          <tr v-if="seatPlanDt.length > 0 && isDtActive">
            <td class="column-sticky px-2 bg-seat custom-width text-start">Digital ticketing</td>
            <td v-for="(seat, j) in seatPlanDt" :key="j">
              <span class="seat bg-seat">{{seat}}</span>
            </td>
          </tr>
          <tr v-for="(seatPlan, i) in seatPlanQuota" :key="i">
            <td class="column-sticky px-2 bg-seat custom-width text-start">{{findCounterName(seatPlan.counterId)}} </td>
            <td v-for="(seat, j) in seatPlan.seats" :key="j">
               <span class="seat bg-seat">{{seat}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import store from "@/store";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
const { hasPermission } = permission();
const { Owner } = roles();

export default {
  name: "CounterwiseQuota",

  props: {
    seatPlanWiseQuota: Array,
    seatPlanForDt: Array,
    digitalTicketing: Boolean,
    quotaAutoClearTime: Number,
  },
  setup(props,{emit} ) {
    const quotaClearTime = ref(props.quotaAutoClearTime)
    const seatPlanQuota = computed(() => props.seatPlanWiseQuota)
    const seatPlanDt = computed(() => props.seatPlanForDt)
    const isDtActive = computed(() => props.digitalTicketing)
    const seatList = computed(() => {
      let seatListLocal = [];
      const {seatList : seats} = store.getters.getOwnerServicesSeatPlanById
      if (seats && seats.length > 0){
        seats.forEach((itemArray) => {
          itemArray.forEach(item => {
            if (item.seat_no !== ""){
              seatListLocal.push(item.seat_no)
            }
          })
        })
      }
      return seatListLocal;
    })
    const counterList = computed(()=> store.getters.getOwnerServicesCounterByRoute)

    watch(() =>  quotaClearTime.value, (value) => {
      emit('getQuotaAutoClearTime',  value)
    })

    const manageQuota = (counter, seat, dt) => {

        emit('getQuota', {counterId: counter, seat: seat, dt})

    }

    const checkQuota = (counter, seat) => {
      for (let i = 0; i < seatPlanQuota.value.length; i++) {
        if (seatPlanQuota.value[i].counterId === counter._id && seatPlanQuota.value[i].seats.includes(seat)){
          return true
        }
      }
    }

    const checkQuotaForDt = (seat) => (
         seatPlanDt.value.includes(seat)
    )

    const findCounterName = (counterId) => {
      return counterList.value.filter(item => counterId === item._id)[0]?.name
    }

    const isDtSeatAndHasNoDtWritePermission = (seat, index) => {
      if (!hasPermission(Owner.digitalTicketingWrite)){
        let isDtSeat = seatPlanDt.value.includes(seat)
        if (isDtSeat){
          return index
        }
        return -1
      }
    }


    return {
      counterList,
      seatList,
      manageQuota,
      checkQuota,
      seatPlanQuota,
      findCounterName,
      seatPlanDt,
      checkQuotaForDt,
      isDtActive,
      quotaClearTime,
      isDtSeatAndHasNoDtWritePermission,
      hasPermission,
      Owner
    };
  },
};
</script>

<style scoped>

.column-sticky {
  background-color: #ffffff;
  left: 0;
  position: sticky;
  z-index: 9999;
}
.scroll-height-300 {
  height: 300px;
  overflow-y: auto;
}
.form-check{
  padding-left: 0;
}
.form-check .form-check-input {
  float: none;
  margin-left: 0;
}
#quota-table table {
  border: none;
  border-collapse: separate;
  border-spacing: 2px; /* Apply cell spacing */
}
#quota-table tr {
  /*background: linear-gradient(to right, #0033FF, #3399FF);*/
}
#quota-table td {
  border: none;
  padding: 5px;
  text-align: center;
}
#quota-table th {
  color: #ff6347;
  padding: 5px;
  border: none;
  background: white;
  position: sticky;
  top: 0;
}
.bg-seat{
  background-color: #e9ecef;
  padding: 5px;
}

.seat{
  color: #ff6347;
  padding: 5px;
  border: none;
  font-weight: bold;
}
.custom-width{
  min-width: 150px;
}

</style>
