<template>
  <Modal @close="closeModal">
    <template v-slot:title>UPDATE TRIP</template>
    <template v-slot:body>
      <div class="form-group row">
        <TicketInput
          v-model="updateTripData.coachRegistrationNumber"
          v-bind:placeholder="'Coach Registration Number'"
          v-bind:label="'Coach Registration Number'"
          v-bind:id="'Coach Registration Number'"
          v-bind:type="'text'"
        />
      </div>
      <div class="form-group row">
        <TicketDropdown
          v-model="updateTripData.driver"
          v-bind:id="'Driver'"
          v-bind:label="'Driver'"
          v-bind:dropdown="$store.getters.getAllDriversAgentIntercity"
          v-bind:defaultOption="'--select a driver --'"
          v-bind:defaultValue="''"
        >
        </TicketDropdown>
      </div>
      <div class="form-group row">
        <TicketDropdown
          v-model="updateTripData.supervisor"
          v-bind:id="'Supervisor'"
          v-bind:label="'Supervisor'"
          v-bind:dropdown="$store.getters.getAllSupervisorsAgentIntercity"
          v-bind:defaultOption="'--select a supervisor --'"
          v-bind:defaultValue="''"
        >
        </TicketDropdown>
      </div>
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Confirm"
            @click.prevent="updateTrip"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { inject, onBeforeMount, reactive } from "vue";
import store from "@/store";
export default {
  name: "PnrModal",
  props: {
    tripId: String,
  },
  setup(props, { emit }) {
    let updateTripData = reactive({
      tripId: props.tripId,
      driver: "",
      supervisor: "",
      coachRegistrationNumber: "",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const updateTrip = () => {
      showLoader();
      store
        .dispatch("updateTripCountermanAgentIntercity", updateTripData)
        .then(() => {
          closeModal();
          hideLoader();
        })
        .catch(() => {
          closeModal();
          hideLoader();
        });
    };
    const getAllDriversAndSupervisors = () => {
      store
        .dispatch("getAllDriverAgentIntercity")
        .then(() => {})
        .catch(() => {});
      store
        .dispatch("getAllSupervisorAgentIntercity")
        .then(() => {})
        .catch(() => {});
    };
    onBeforeMount(() => {
      let tripData = store.getters.getTripForTicketingAgentIntercity(
        updateTripData.tripId
      );
      updateTripData.driver = tripData[0].driver;
      updateTripData.supervisor = tripData[0].supervisor;
      updateTripData.coachRegistrationNumber =
        tripData[0].coachRegistrationNumber;
      getAllDriversAndSupervisors();
    });
    return {
      closeModal,
      updateTrip,
      updateTripData,
    };
  },
};
</script>

<style scoped></style>
