<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
        t("UPDATE_TRIP", {}, { locale: $store.getters.getLang })
      }}</template>
    <template v-slot:body>
      <div class="form-group row">
        <TicketInput
            v-model="updateTripData.coachRegistrationNumber"
            v-bind:placeholder="'Coach Registration Number'"
            v-bind:label="'Coach Registration Number'"
            v-bind:id="'Coach Registration Number'"
            v-bind:type="'text'"
        />
      </div>
      <div class="form-group row">
        <TicketDropdown
            v-model="updateTripData.driver"
            v-bind:id="'Driver'"
            v-bind:label="'Driver'"
            v-bind:dropdown="$store.getters.getAllDrivers"
            v-bind:defaultOption="'-- select a driver --'"
            v-bind:defaultValue="null"
        >
        </TicketDropdown>
      </div>
      <div class="form-group row mt-1">
        <TicketDropdown
            v-model="updateTripData.supervisor"
            v-bind:id="'Supervisor'"
            v-bind:label="'Supervisor'"
            v-bind:dropdown="$store.getters.getAllSupervisors"
            v-bind:defaultOption="'-- select a supervisor --'"
            v-bind:defaultValue="null"
        >
        </TicketDropdown>
      </div>
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <!-- <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Confirm"
            @click.prevent="updateTrip"
          /> -->
          <Button
              @click.prevent="updateTrip"
              class="mt-3 py-2 submit_button float-end font_detail"
          >{{ t("Confirm", {}, { locale: $store.getters.getLang }) }}</Button
          >
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import {computed, inject, onBeforeMount, reactive} from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "UpdateTripModal",
  props: {
    tripId: String,
  },

  setup(props, { emit }) {
    let updateTripData = reactive({
      tripId: props.tripId,
      driver: "",
      supervisor: "",
      coachRegistrationNumber: "",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();

    onBeforeMount(() => {
      updateTripData.driver = tripData.value.driver ? tripData.value.driver._id : null;
      updateTripData.supervisor = tripData.value.supervisor ? tripData.value.supervisor._id : null;
      updateTripData.coachRegistrationNumber = tripData.value.coachRegistrationNumber;
      getAllDriversAndSupervisors();
    });
    let tripData = computed(() => store.getters.getTripForTicketingNew);
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const updateTrip = () => {
      showLoader();
      store
          .dispatch("updateTripCounterman", updateTripData)
          .then(() => {
            closeModal();
            refreshPageGoBack()
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateTrip();
            }
          });
    };
    const getAllDriversAndSupervisors = () => {
      //TODO make it separate function
      store
          .dispatch("getAllDriver")
          .then(() => {})
          .catch(() => {});
      store
          .dispatch("getAllSupervisor")
          .then(() => {})
          .catch(() => {});
    };
    const refreshPageGoBack = () => {
      if (tripData.value !== "") {
        showLoader();
        const payload = {
          tripId: tripData.value.tripId,
          fromStoppage: '',
          toStoppage: '',
          boarding: ''
        }
        store
            .dispatch("getTicketByTripNew", payload)
            .then(() => {
              reset();
              hideLoader();
            })
            .catch(() => {
              hideLoader();
            });
      }
    };



    const reset = () => {
      store.commit("setResetStatus", true)
      store.commit("setSeatResetStatus", true)
    };
    return {
      t,
      locale,
      closeModal,
      updateTrip,
      updateTripData,
    };
  },
};
</script>

<style scoped></style>
