// import validationMessage from "@/modules/ValidationMessage"
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {successToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const {errorResponse} = apiResponse();

const state = {
    filter: {
        stoppageAgentIntercity: {},
        shipAgentIntercity: {},
        routeAgentIntercity: {},
        counterAgentIntercity: {},
        supervisorsAgentIntercity:{},
        driversAgentIntercity:{}
    },
    tripsAgentIntercity: [],
    routeSequenceAgentIntercity:[],
    boardingPlaceAgentIntercity:[],
    droppingPointAgentIntercity:[],
    ticketsAgentIntercity: {}
};
const getters = {
    getAllSupervisorsAgentIntercity: (state) => state.filter.supervisorsAgentIntercity,
    getAllDriversAgentIntercity: (state) => state.filter.driversAgentIntercity,

    getCountermanStoppageListForFilterAgentIntercity: (state) => state.filter.stoppageAgentIntercity,
    getCountermanCoachListForFilterAgentIntercity: (state) => state.filter.shipAgentIntercity,
    getCountermanRouteListForFilterAgentIntercity: (state) => state.filter.routeAgentIntercity,

    getTicketsHistoryCounterAgentIntercity: (state) => state.ticketsAgentIntercity.docs,
    getTotalTicketsHistoyCounterAgentIntercity: (state) => state.ticketsAgentIntercity.totalDocs,
    getTripsForTicketingAgentIntercity: (state) => state.tripsAgentIntercity.docs,
    getTotalTripsForTicketingAgentIntercity: (state) => state.tripsAgentIntercity.totalDocs,
    getTripForTicketingAgentIntercity: (state) => (id) => state.tripsAgentIntercity.docs.filter(function (trip) {
        return trip._id === id;
    }),
    getRouteSequenceFilterAgentIntercity: (state) => {
        let routeSequenceList = {}
        state.routeSequenceAgentIntercity.forEach(routeSequence => {
            routeSequenceList[routeSequence._id] = routeSequence.name;
        });
        return routeSequenceList
    },
    getBoardingPlaceFilterAgentIntercity: (state) => {
        let boardingPlaceList = {}
        state.boardingPlaceAgentIntercity.forEach(boardingPlace => {
            boardingPlaceList[boardingPlace] = boardingPlace;
        });
        return boardingPlaceList
    },
    getDroppingPointFilterAgentIntercity: (state) => {
        let droppingPointList = {}
        state.droppingPointAgentIntercity.forEach(droppingPoint => {
            droppingPointList[droppingPoint] = droppingPoint;
        });
        return droppingPointList
    },
};
const actions = {
    async getStoppageListForCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppageListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_STOPPAGES_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {}
                            data.stoppage.forEach(stoppage => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setStoppageListInFilterAgentIntercity", stoppageList)
                            resolve(data.stoppage);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getRouteListForCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRouteListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_ROUTES, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let routeList = {}
                            data.route.forEach(route => {
                                routeList[route._id] = route.name;
                            });
                            commit("setRouteListInFilterAgentIntercity", routeList)
                            resolve(data.route);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCounterListForCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_COUNTERS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let counterList = {}
                            data.counter.forEach(counter => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilterAgentIntercity", counterList)
                            resolve(data.counter);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getShipListForCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_SHIPS_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let coachList = {}
                            data.coach.forEach(coach => {
                                coachList[coach._id] = coach.name;
                            });
                            commit("setShipListInFilterAgentIntercity", coachList)
                            resolve(data.coach);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getTicketsHistoryAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.TICKETS_HISTORY_COUNTER, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTicketsHistoryAgentIntercity", data.ticket)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async ticketDeleteAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_CANCEL, payload, {headers: header})
                    .then((response) => {
                        successToast('Ticket Cancelled Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async tripPassAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TRIP_PASS_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast('Ticket Passed Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async pnrTicketAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.PNR_TICKET_AGENT_INTERCITY,{headers: header,params:payload})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async updateTripCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.UPDATE_TRIP_AGENT_INTERCITY,payload,{headers: header})
                    .then((response) => {
                        commit('updateTripAgentIntercity',response.data.trip)
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async extraSeatPurchaseAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.EXTRA_TICKET_SEATS_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async extraGoodsPurchaseAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.EXTRA_TICKET_GOODS_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async confirmPassengerTicketAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PURCHASE_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getActiveTripsForTicketingAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            commit("setTrips", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TRIPS_FOR_TICKETING_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTripsAgentIntercity", data.trip);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllDriverAgentIntercity({commit}) {
        if (await hasValidToken()) {
            commit("setDrivers", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ALL_DRIVER_AGENT_INTERCITY, {
                    headers: header
                })
                    .then(({data}) => {
                            let driverList = {}
                            data.drivers.forEach(driver => {
                                driverList[driver._id] = driver.name;
                            });
                            commit("setDriversAgentIntercity", driverList);
                            resolve({'success': 'success'});
                        
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAllSupervisorAgentIntercity({commit}) {
        if (await hasValidToken()) {
            commit("setSupervisors", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ALL_SUPERVISOR_AGENT_INTERCITY, {
                    headers: header
                })
                    .then(({data}) => {
                            let superVisorList = {}
                            data.supervisors.forEach(supervisor => {
                                superVisorList[supervisor._id] = supervisor.name;
                            });
                            commit("setSupervisorsAgentIntercity", superVisorList);
                            resolve({'success': 'success'});
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanLastTicketAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.LAST_TICKET_PRINT_COUNTERMAN_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data.ticket);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async ticketPrintCountAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PRINT_COUNT_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(error)
                    });
            });
        }
    },
    async reportPrintCountAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async reportPrintCountIncreaseAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT_INCREASE, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async reportPrintCountForAgent(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT_AGENT, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async reportPrintCountIncreaseForAgent(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT_INCREASE_AGENT, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setSupervisorsAgentIntercity : (state, supervisors) => (state.filter.supervisorsAgentIntercity = supervisors),
    setDriversAgentIntercity : (state, drivers) => (state.filter.driversAgentIntercity = drivers),
    setStoppageListInFilterAgentIntercity: (state, stoppageList) => (state.filter.stoppageAgentIntercity = stoppageList),
    setShipListInFilterAgentIntercity: (state, shipList) => (state.filter.shipAgentIntercity = shipList),
    setRouteListInFilterAgentIntercity: (state, routeList) => (state.filter.routeAgentIntercity = routeList),
    setCounterListInFilterAgentIntercity: (state, counterList) => (state.filter.counterAgentIntercity = counterList),
    setTicketsHistoryAgentIntercity: (state, tickets) => (state.ticketsAgentIntercity = tickets),
    setTripsAgentIntercity: (state, trips) => (state.tripsAgentIntercity = trips),
    setRouteSequenceAgentIntercity: (state, routeSequence) => (state.routeSequenceAgentIntercity = routeSequence),
    setBoardingPlaceAgentIntercity: (state, boardingPlace) => (state.boardingPlaceAgentIntercity = boardingPlace),
    setDroppingPointAgentIntercity: (state, droppingPoint) => (state.droppingPointAgentIntercity = droppingPoint),
    updateTripAgentIntercity: (state,trip) => state.tripsAgentIntercity.docs.filter((element,index) => {
        if(element._id===trip._id){
            state.tripsAgentIntercity.docs.splice(index,1,trip)
        }
    }),
};

export default {
    state,
    getters,
    actions,
    mutations
};