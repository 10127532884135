<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="coachData.name"
            v-bind:placeholder="'Enter Coach Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>
        <div
          class="form-group row my-1"
          v-if="$store.getters.userCompanyData?.allowCoachDetails"
        >
          <ModalInput
            v-model="coachData.registrationNumber"
            v-bind:placeholder="'Registration Number'"
            v-bind:label="'Registration Number'"
            v-bind:id="'registration'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.registrationNumber"
                v-bind:error="errors.registrationNumber"
              />
            </template>
          </ModalInput>
        </div>
        <!-- <div class="form-group row my-1">
          <ModalDropdown
            v-model="coachData.coachRoute"
            v-bind:id="'route'"
            v-bind:label="'Routes'"
            v-bind:defaultOption="'---select a route---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getRouteListForFilterAdmin"
          >
            <template v-slot:error>
              <ModalError v-if="errors.coachRoute" v-bind:error="errors.coachRoute" />
            </template>
          </ModalDropdown>
        </div> -->

        <div
          class="form-group row my-1"
          v-if="$store.getters.userCompanyData?.allowCoachDetails"
        >
          <ModalDropdown
            v-model="coachData.seatPlanId"
            v-bind:id="'seatPlan'"
            v-bind:label="'Seat Plan'"
            v-bind:defaultOption="'---select a seat plan---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="
              $store.getters.getOwnerServicesSeatPlanListForFilter
            "
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.seatPlanId"
                v-bind:error="errors.seatPlanId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div
          class="form-group row my-1"
          v-if="$store.getters.userCompanyData?.allowCoachwiseTrip"
        >
          <ModalDropdown
            v-model="coachData.coachRoute"
            v-bind:id="'coachRoute'"
            v-bind:label="'Coach Route'"
            v-bind:defaultOption="'---select a coach route---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="$store.getters.getRouteListForFilterAdmin"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.coachRoute"
                v-bind:error="errors.coachRoute"
              />
            </template>
          </ModalDropdown>
        </div>
        <div
          class="form-group row my-1"
          v-if="$store.getters.userCompanyData?.allowCoachwiseTrip"
        >
          <TimePicker
            v-model="coachData.coachTime"
            v-bind:id="'tripTime'"
            v-bind:label="'Time'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.coachTime"
                v-bind:error="errors.coachTime"
              />
            </template>
          </TimePicker>
        </div>

        <!-- <div v-if="showShipDropdown" class="form-group row my-1">
          <ModalDropdown
            v-if="ships"
            v-model="coachData.ship"
            v-bind:id="'ship'"
            v-bind:label="'Ship'"
            v-bind:defaultOption="'---select a ship---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="ships"
          >
            <template v-slot:error>
              <ModalError v-if="errors.ship" v-bind:error="errors.ship" />
            </template>
          </ModalDropdown>
        </div> -->

        <!-- <div class="form-group row my-1">
          <ModalDropdown
            v-if="direction"
            v-model="coachData.direction"
            v-bind:id="'direction'"
            v-bind:label="'Direction'"
            v-bind:defaultOption="'---select a direction---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="direction"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.direction"
                v-bind:error="errors.direction"
              />
            </template>
          </ModalDropdown>
        </div> -->

        <!-- <div v-if="coachId" class="form-group row my-1">
          <DatePicker
            v-model="coachData.tripDate"
            v-bind:id="'tripDate'"
            v-bind:label="'Trips Date'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.tripDate"
                v-bind:error="errors.tripDate"
              />
            </template>
          </DatePicker>
        </div> -->

        <!-- <div v-else>
          <div class="form-group row my-1">
            <DatePicker
              v-model="coachData.fromDate"
              v-bind:id="'fromDate'"
              v-bind:label="'From'"
            >
              <template v-slot:error>
                <ModalError
                  v-if="errors.fromDate"
                  v-bind:error="errors.fromDate"
                />
              </template>
            </DatePicker>
          </div> -->

        <!-- <div class="form-group row my-1">
            <DatePicker
              v-model="coachData.toDate"
              v-bind:id="'toDate'"
              v-bind:label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" v-bind:error="errors.toDate" />
              </template>
            </DatePicker>
          </div>
        </div> -->
        <!-- 
        <div class="form-group row my-1">
          <TimePicker
            v-model="coachData.coachTime"
            v-bind:id="'coachTime'"
            v-bind:label="'Time'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.coachTime"
                v-bind:error="errors.coachTime"
              />
            </template>
          </TimePicker>
        </div> -->

        <!-- <div class="form-group row my-1">
          <ModalCheckSwitch
            v-model="coachData.deckAvailability"
            v-bind:label="'Deck Available'"
            v-bind:id="'deck-available'"
            :disability="!shipDeckAvailability"
          >
            <template v-slot:error>
              <Error
                v-if="errors.deckAvailability"
                v-bind:error="errors.deckAvailability"
              />
            </template>
          </ModalCheckSwitch>
        </div> -->

        <!-- <div v-if="coachData.deckAvailability" class="form-group row my-1">
          <ModalInput
            v-model="coachData.deckFare"
            v-bind:placeholder="'Enter Deck Fare'"
            v-bind:label="'Deck Fare'"
            v-bind:id="'deck-fare'"
            v-bind:type="'number'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.deckFare"
                v-bind:error="errors.deckFare"
              />
            </template>
          </ModalInput>
        </div> -->
        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="coachData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          />
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              v-if="!coachId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCoach"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateCoach"
            /> -->

            <button
              v-if="!coachId"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCoach"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              v-else
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateCoach"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "CoachAddUpdateModal",
  props: {
    coach: String,
  },
  setup(props, { emit }) {
    const route = useRoute();
    let { requiredValidation, errors } = useInputValidation();
    let coachData = reactive({
      companyId: route.query.id,
      name: "",
      registrationNumber: "",
      seatPlanId: "",
      coachTime: "",
      coachRoute: null,
      status: "1",
    });
    let updateCoachData = reactive({
      id: "",
      companyId: route.query.id,
      name: "",
      registrationNumber: "",
      seatPlanId: "",
      seatCapacity: null,
      coachTime: null,
      coachRoute: null,
      status: "",
    });
    const coachId = ref(props.coach);
    const showShipDropdown = ref(false);
    const ships = ref({});
    const direction = dropdownVariables.direction;
    const title = ref("Add Coach");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const status = dropdownVariables.status;
    let shipDeckAvailability = ref(true);
    const { t, locale } = useI18n();
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    watch(
      () => coachData.coachRoute,
      () => {
        requiredValidation("coachRoute", coachData.coachRoute);
      }
    );
    watch(
      () => coachData.coachTime,
      () => {
        requiredValidation("coachTime", coachData.coachTime);
      }
    );

    watch(
      () => coachData.name,
      () => {
        requiredValidation("name", coachData.name);
      }
    );
    watch(
      () => coachData.registrationNumber,
      () => {
        requiredValidation("registrationNumber", coachData.registrationNumber);
      }
    );
    watch(
      () => coachData.seatPlanId,
      () => {
        requiredValidation("seatPlanId", coachData.seatPlanId);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", coachData.name);
      if (store.getters.userCompanyData?.allowCoachDetails) {
        requiredValidation("registrationNumber", coachData.registrationNumber);
        requiredValidation("seatPlanId", coachData.seatPlanId);
      }
      if (store.getters.userCompanyData?.allowCoachwiseTrip) {
        requiredValidation("coachRoute", coachData.coachRoute);
        requiredValidation("coachTime", coachData.coachTime);
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    store.dispatch("getOwnerServicesAllSeatPlan", {
      //TODO make it function
      companyId: route.query.id,
    });
    const addCoach = () => {
      if (validate()) {
        showLoader();
        if (!store.getters.userCompanyData?.allowCoachDetails) {
          coachData.registrationNumber = "";
          coachData.seatPlanId = null;
        }
        store
          .dispatch("addOwnerServicesCoach", { coachData, pagination })
          .then(() => {
            emit("close");
            hideLoader();
          })
          .catch((error) => {
            emit("close");
            hideLoader();
            if (error.status === 401) {
              addCoach();
            }
          });
      }
    };
    const updateCoach = () => {
      updateCoachData.name = coachData.name;
      updateCoachData.registrationNumber = coachData.registrationNumber;
      updateCoachData.seatPlanId = coachData.seatPlanId;
      updateCoachData.coachRoute = coachData.coachRoute;
      updateCoachData.coachTime = coachData.coachTime;
      updateCoachData.status = coachData.status;
      if (validate()) {
        showLoader();
        store
          .dispatch("updateOwnerServicesCoach", updateCoachData)
          .then(() => {
            emit("close");
            hideLoader();
          })
          .catch(() => {
            emit("close");
            hideLoader();
            // if (error.status === 401) {
            //   updateCoach();
            // }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      if (coachId.value !== "") {
        const coachDetails = store.getters.getOwnerServicesCoach(coachId.value);
        updateCoachData.id = coachId.value;
        title.value = "Update Coach";
        coachData.name = coachDetails[0].name;
        if (store.getters.userCompanyData?.allowCoachDetails) {
          coachData.registrationNumber = coachDetails[0]?.registrationNumber;
          coachData.seatPlanId = coachDetails[0]?.seatPlanId?._id;
        } else {
          coachData.registrationNumber = coachDetails[0]?.registrationNumber;
          coachData.seatPlanId = coachDetails[0]?.seatPlanId?._id;
        }
        coachData.coachRoute = coachDetails[0]?.coachRoute?._id;
        coachData.coachTime = coachDetails[0]?.coachTime;
        coachData.status = coachDetails[0].status ? 1 : 0;
      } else {
        coachData.coachTime = moment(new Date()).format("HH:mm:00");
      }
      coachData.companyId = route.query.id;
    });

    return {
      t,
      locale,
      coachId,
      errors,
      status,
      title,
      showShipDropdown,
      ships,
      direction,
      coachData,
      addCoach,
      updateCoach,
      closeModal,
      shipDeckAvailability,
    };
  },
};
</script>

<style scoped></style>
