import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";
const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();
import toast from "@/modules/Toast";
const {successToast} = toast();
import axios from "axios";
import Apis from "@/urls/apis";

const state = {
    tripsAgentIntercity: {},
};
const getters = {
    getCountermanTripsAgentIntercity: (state) => state.tripsAgentIntercity.docs,
    getCountermanTotalTripsAgentIntercity: (state) => state.tripsAgentIntercity.totalDocs,
};
const actions = {
    async cancelTicketAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_CANCEL_COUNTERMAN_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async extraTicketCancelAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.EXTRA_TICKET_CANCEL_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async goodsTicketCancelAgentIntercity(_, payload) {
        if (await hasValidToken()) {                
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.GOODS_TICKET_CANCEL_AGENT_INTERCITY, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAllTripsForCountermanAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTER_ALL_TRIPS_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllTripsForCountermanAgentIntercity", data.trip)
                            resolve(data.trip)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllTripsForCountermanAgentIntercity: (state, allTrips) => (state.tripsAgentIntercity = allTrips),
};

export default {
    state,
    getters,
    actions,
    mutations
};