<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'company'"
              v-bind:label="'Company'"
              v-bind:defaultOption="'--select company--'"
              v-model="coachWiseReport.companyId"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="coachWiseReport.companyId">
          <DatePickerFilter
              :id="'datePickerDate'"
              v-model="coachWiseReport.date"
              :label="'Date'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            coachWiseReport.companyId &&
            reservationTripList.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Reservation_List_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="coachWiseReport.companyId === '' && selectCompany"
    >
      Please,Select a
      <a href="#" class="alert-link">company</a> from dropdown
    </div>
    <Table v-if="coachWiseReport.companyId !== '' && filterActive && reservationTripList?.length > 0"
        id="exportable_data">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">TRIP TIME & DATE</td>
          <td class="font_detail">TRIP CODE</td>
          <td class="font_detail">ROUTE NAME</td>
          <td class="font_detail">FROM STOPPAGE</td>
          <td class="font_detail">TO STOPPAGE</td>
          <td class="font_detail">SEAT PLAN</td>
          <td class="font_detail">RESERVED SEATS</td>
          <td class="font_detail">TOTAL RESERVATION</td>
          <td class="font_detail">PENDING</td>
          <td class="font_detail">REFUND PENDING</td>
          <td class="font_detail">CANCELLED</td>
          <td class="font_detail">SEAT NUMBER</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(trip, index) in reservationTripList"
            :key="trip._id"
        >
          <td class="font_detail">
            {{ index + 1 }}
          </td>
          <td class="font_detail bold">{{ dateTimeFormat(trip?.tripInfo.tripDateTime) }}</td>
          <td class="font_detail">{{ trip?.tripInfo?.tripCode }}</td>
          <td class="font_detail">{{ trip?.routeInfo }} </td>
          <td class="font_detail">{{ trip?.fromStoppage }} </td>
          <td class="font_detail">{{ trip?.toStoppage }} </td>
          <td class="font_detail">{{ trip?.seatPlan }} </td>
          <td class="font_detail">{{ trip?.seatTicket }} </td>
          <td class="font_detail">{{ trip?.totalReservedTicket }} </td>
          <td class="font_detail">{{ trip?.pendingStatus }} </td>
          <td class="font_detail">{{ trip?.refundPendingStatus }} </td>
          <td class="font_detail">{{ trip?.cancelledStatus }} </td>
          <td class="font_detail">
            <button @click=" tripReservationList(trip._id, trip?.tripInfo?.companyId)" class="edit_table_data font_detail">
              Details
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="8" class="font_detail text-white">Total</td>
          <td class="font_detail text-white">{{ totalReservedTicket }}</td>
          <td class="font_detail text-white">{{ pendingStatus }}</td>
          <td class="font_detail text-white">{{ refundPendingStatus }}</td>
          <td class="font_detail text-white">{{ cancelledStatus }}</td>
          <td></td>
        </tr>
      </template>
<!--      <template v-slot:pagination>-->
<!--        <pagination-->
<!--            v-model="pagination.page"-->
<!--            :records="reservationTripList.totalDocs"-->
<!--            :per-page="pagination.limit"-->
<!--            @paginate="getAllReservationTickets"-->
<!--            :options="options"-->
<!--        />-->
<!--      </template>-->
    </Table>
  </div>
  <TripReservationList
      v-if="tripReservationListActive"
      v-on:backToCoachWiseReport="onChildClick"
      :selectedTripId="selectedTripId"
      :selectedCompanyId="selectedCompanyId"
  />
</template>

<script>
import {computed, inject, reactive, ref, watch} from "vue";
import store from "@/store";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import moment from "moment";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import TripReservationList from "@/views/Admin/Report/Children/TripReservationList";

export default {
    name: "ReservationReport",
    components: {
      TripReservationList,
      DropdownFilter,
    },
    emits: ["backToCoachWiseReport"],
    setup() {
      const showLoader = inject("showLoader");
      const hideLoader = inject("hideLoader");
      let tripReservationListActive = ref(false);
      let reportActive = ref(true);
      let filterActive = ref(false);
      let selectCompany = ref(false);
      const exportExcel = inject("exportExcel");
      const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
      const selectedTripId = ref(null);
      const selectedCompanyId = ref(null);
      let coachWiseReport = reactive({
        companyId: "",
        date: moment(new Date()).format("yyyy-MM-DD"),
      });
      let pagination = reactive({
        page: 1,
        limit: 20,
      });
      const options = {
        texts: {
          count: "",
        },
      };

      const reservationTripList = computed(() => store.getters.getAdminReservationTripList);

      const filterReport = () => {
        selectCompany.value = true;
        if (coachWiseReport.companyId !== "") {
          filterActive.value = true;

          getAllReservationTickets();
        }
      };

      const getAllReservationTickets = () => {
        let payload = {...pagination, ...coachWiseReport};
        showLoader();
        store
            .dispatch("getAdminReservationTripReportList", payload)
            .then(() => {
              hideLoader();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                getAllReservationTickets();
              }
            });
      };
      const getAllCompanyList = () => {
        showLoader();
        store.dispatch("getAllCompanyList").then(() => hideLoader())
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                getAllCompanyList();
              }
            });
      };

      const tripReservationList = (tripId, companyId) => {
        selectedTripId.value =  tripId;
        selectedCompanyId.value = companyId;
        tripReservationListActive.value = true;
        reportActive.value = false;
      }

      const onChildClick = (value) => {
        tripReservationListActive.value = !value;
        reportActive.value = value
      };

      watch(reservationTripList, () => {
        sumListData();
      })

      const seatTicket = ref(0);
      const totalReservedTicket = ref(0);
      const cancelledStatus = ref(0);
      const pendingStatus = ref(0);
      const refundPendingStatus = ref(0);

      const sumListData = () => {
        seatTicket.value = 0;
        totalReservedTicket.value = 0;
        cancelledStatus.value = 0;
        pendingStatus.value = 0;
        refundPendingStatus.value = 0;
        reservationTripList.value.forEach((data) => {
          seatTicket.value += data.seatTicket;
          totalReservedTicket.value += data.totalReservedTicket;
          cancelledStatus.value += data.cancelledStatus;
          pendingStatus.value += data.pendingStatus;
          refundPendingStatus.value += data.refundPendingStatus;
        });
      };

      return {
        getAllReservationTickets,
        reportActive,
        getAllCompanyList,
        filterActive,
        selectCompany,
        exportExcel,
        fromDate,
        coachWiseReport,
        filterReport,
        reservationTripList,
        pagination,
        dateTimeFormat,
        options,
        tripReservationListActive,
        tripReservationList,
        onChildClick,
        selectedCompanyId,
        selectedTripId,
        seatTicket,
        totalReservedTicket,
        cancelledStatus,
        pendingStatus,
        refundPendingStatus
      }
    }
  }
</script>