<template>
  <header id="topHeader">
    <nav class="navbar navbar-expand-lg navbar-light navbar_bg">
      <div class="container-fluid">
        <router-link
          v-if="hasPermission(Agent.ticketBooking)"
          v-bind:to="{ name: web.Agent.DASHBOARD_URL.name }"
          class="navbar-brand brand_name menu_font_detail home-logout"
          >{{ web.Agent.DASHBOARD_URL.title }}
        </router-link>
        <button
          class="navbar-toggler mobile_nav_toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul
            class="navbar-nav dashboard_nav me-auto mb-2 mb-lg-0 menu_font_detail"
          >
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Agent.reports)"
                v-bind:to="{ name: web.Agent.REPORT_URL.name }"
                class="nav-link menu_font_detail"
                >{{ web.Agent.REPORT_URL.title }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Agent.trips)"
                v-bind:to="{ name: web.Agent.TRIPS_URL.name }"
                class="nav-link menu_font_detail"
                >{{ web.Agent.TRIPS_URL.title }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Agent.coach)"
                v-bind:to="{ name: web.Agent.COACH_URL.name }"
                class="nav-link menu_font_detail"
                >{{ web.Agent.COACH_URL.title }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <li class="nav-item m-1">
              <div class="rounded-2 nav-link menu_font_detail p-0">
                <span
                  v-if="$store.getters.agentBalance <= 0"
                  class="badge bg-danger text-dark px-4 balance"
                  >{{ $store.getters.agentBalance }}</span
                >
                <span v-else class="badge bg-info text-dark px-4 balance">{{
                  $store.getters.agentBalance
                }}</span>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle menu_font_detail"
                href="#"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  v-bind:class="
                    currentPath ===
                    web.Agent.path + '/' + web.Agent.PROFILE_URL.path
                      ? 'nav-active'
                      : ''
                  "
                  >{{ $store.getters.userName }}</span
                >
              </a>
              <ul
                class="dropdown-menu intercity_login_dropdown"
                aria-labelledby="navbarScrollingDropdown"
              >
                <!-- <li>
                  <router-link v-bind:to="{name:web.Agent.PROFILE_URL.name}" class="dropdown-item text-dark menu_font_detail">
                    {{ web.Agent.PROFILE_URL.title }}
                  </router-link>
                </li> -->
                <!-- <li>
                  <hr class="dropdown-divider">
                </li> -->
                <li>
                  <button
                    @click="logout"
                    class="dropdown-item text-dark menu_font_detail"
                  >
                    {{ web.Default.LOGOUT_URL.title }}
                  </button>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import store from "@/store";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
const { hasPermission } = permission();
const { Agent } = roles();
export default {
  name: "AgentNav",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentPath = computed(() => route.path);
    const logout = () => {
      store
        .dispatch("logout")
        .then(() => {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return {
      currentPath,
      web,
      Agent,
      hasPermission,
      logout,
    };
  },
};
</script>

<style scoped>
nav .active,
nav .exact-active,
.nav-active {
  color: #ff6347 !important;
  outline: none;
}
.dashboard_nav li a:hover {
  color: #ff6347 !important;
}
</style>
