<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form class="my-2">
        <div class="row">
          <div class="col-md-8 order-md-1 order-2">
            <div class="row">
              <span
                class="col-md-4 col-6 p-2"
                v-for="(seat, index) in ticketSeats"
                :key="seat + index"
              >
                <Checkbox
                  v-model="checked"
                  v-bind:label="seat"
                  v-bind:role="seat"
                  :bigger="true"
                  @toggleAllPermission="toggleAllPermission"
                />
              </span>
            </div>
          </div>
          <div class="col-md-4 text-end order-md-2 order-1">
            <div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input all_permission_label custom-size-input"
                  type="checkbox"
                  id="select-all"
                  name="select-all"
                  @click="giveAllPermission"
                  v-model="allSelected"
                />
                <label
                  class="form-check-label all_permission_label custom-label"
                  for="select-all"
                >
                  Select All
                </label>
              </div>
            </div>
          </div>
        </div>

        <ModalError
          v-if="errors.cancelSeats"
          v-bind:error="errors.cancelSeats"
        />

        <div class="form-group row my-1 mt-3">
          <ModalDropdown
            v-model="ticketCancelData.cancellationCause"
            v-bind:id="'status'"
            v-bind:label="'Cancel reason'"
            v-bind:default-option="'Select Reason'"
            v-bind:default-value="''"
            v-bind:dropdown="ticketCancelReason"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.ReasonToCancelIssue"
                v-bind:error="errors.ReasonToCancelIssue"
              />
            </template>
          </ModalDropdown>
        </div>
        <div
          v-if="ticketCancelData.cancellationCause == 'অন্যান্য'"
          class="form-group row my-1"
        >
          <TextArea
            v-model="ticketCancelIssue"
            v-bind:placeholder="'Why are you cancelling Ticket?'"
            v-bind:id="'Cancel'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.TicketCancelIssue"
                v-bind:error="errors.TicketCancelIssue"
              />
            </template>
          </TextArea>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <button
              class="mt-3 py-2 mr-1 submit_button font_detail float-end"
              @click.prevent="ticketCancelConfirm"
            >
              {{ t("Confirm", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import useInputValidation from "@/modules/useInputValidations";
import { inject, ref, onBeforeMount, reactive } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { useI18n } from "vue-i18n";
export default {
  components: {},
  name: "Cancellation cause",
  props: {
    ticketIdStore: {
      type: String,
    },
    pagination: {
      type: Object,
    },
    ticketTotalSeats: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const ticketCancelData = reactive({
      ticketId: "",
      cancellationCause: "",
      totalCancelledAbleSeats: "",
      cancelledAbleSeats: "",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let ticketCancelIssue = ref("");
    let ticketSeats = ref(props.ticketTotalSeats);
    const title = ref("Cancellation Cause");
    let checked = ref([]);
    let allSelected = ref(false);
    const ticketCancelReason = dropdownVariables.ticketCancelReason;
    const { t, locale } = useI18n();
    let { requiredValidation, errors } = useInputValidation();

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = ticketSeats.value;
      }
    };

    const toggleAllPermission = () => {
      allSelected.value = checked.value.length === ticketSeats.value.length;
    };

    const getTicketsHistory = () => {
      showLoader();
      store
        .dispatch("getTicketsHistory", props.pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getTicketsHistory();
          }
        });
    };

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation(
        "ReasonToCancelIssue",
        ticketCancelData.cancellationCause.trim()
      );
      requiredValidation("cancelSeats", checked.value);

      if (ticketCancelData.cancellationCause === "অন্যান্য") {
        requiredValidation("TicketCancelIssue", ticketCancelIssue.value.trim());
      }

      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const ticketCancelConfirm = () => {
      if (validate()) {
        showLoader();

        ticketCancelData.ticketId = props.ticketIdStore;
        ticketCancelData.totalCancelledAbleSeats = checked.value.length;
        ticketCancelData.cancelledAbleSeats = checked.value.toString();

        if (ticketCancelData.cancellationCause === "অন্যান্য") {
          requiredValidation(
            "TicketCancelIssue",
            ticketCancelIssue.value.trim()
          );
          ticketCancelData.cancellationCause = ticketCancelIssue.value;
        }
        store
          .dispatch("ticketDelete", ticketCancelData)
          .then(() => {
            getTicketsHistory();
            emit("getAllTripsForTicketing");
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              ticketCancelConfirm();
            }
          });
      }
    };

    onBeforeMount(() => {});

    return {
      t,
      locale,
      ticketCancelIssue,
      title,
      ticketCancelConfirm,
      closeModal,
      errors,
      ticketSeats,
      checked,
      giveAllPermission,
      toggleAllPermission,
      allSelected,
      ticketCancelReason,
      ticketCancelData,
    };
  },
};
</script>

<style scoped>
.custom-size-input {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 1px;
  margin-right: 10px;
}
.custom-label {
  font-size: 16px;
  line-height: 0;
}
</style>
