<template>
  <!-- <div>
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="status"
          />
        </div>
      </template>
    </Filter> -->
  <TicketHistory
    v-if="ticketHistoryActive"
    v-model="ticketHistoryActive"
    :tripId="tripIdIndividual"
  />
  <div v-else>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-model="pagination.status"
            v-bind:dropdown="status"
          />
        </div>
      </template>
    </FilterTab>
    <Table v-if="$store.getters.getCountermanTripsAgentIntercity">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">TRIP TIME & DATE</td>
          <td class="font_detail">TRIP CODE</td>
          <td class="font_detail">ROUTE NAME</td>
          <td class="font_detail">COACH NAME</td>
          <td class="font_detail">FROM STOPPAGE</td>
          <td class="font_detail">TO STOPPAGE</td>
          <td class="font_detail">SEATPLAN NAME</td>
          <td class="font_detail">CLASS NAME</td>
          <td class="font_detail">STATUS</td>
          <td class="font_detail" v-if="hasPermission(Agent.ticketCancel)">
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(trip, index) in $store.getters
            .getCountermanTripsAgentIntercity"
          :key="trip._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ dateFormat(trip.tripDateTime) }}</td>
          <td class="font_detail">{{ trip.tripCode }}</td>
          <td class="font_detail">{{ trip.route.name }}</td>
          <td class="font_detail">{{ trip.coach.name }}</td>
          <td class="font_detail">{{ trip.fromStoppage.name }}</td>
          <td class="font_detail">{{ trip.toStoppage.name }}</td>
          <td class="font_detail">{{ trip.seatPlan.name }}</td>
          <td class="font_detail">{{ trip.seatPlan.seatClass }}</td>
          <td class="font_detail">
            <span v-if="trip.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
          <td class="font_detail bold" v-if="hasPermission(Agent.ticketCancel)">
            <button class="btn btn-warning btn-sm" @click="Ticket(trip._id)">
              <i></i><span class="mx-1">Ticket History</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getCountermanTotalTripsAgentIntercity"
          :per-page="pagination.limit"
          @paginate="getAllTrips"
          :options="options"
        />
      </template>
    </Table>
  </div>
  <!-- </div> -->
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, watch, ref, computed } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TicketHistory from "./TicketHistory.vue";
export default {
  name: "Trips",
  components: {
    TicketHistory,
  },
  setup() {
    const { hasPermission } = permission();
    const { Agent } = roles();
    let ticketHistoryActive = ref(false);
    let tripIdIndividual = ref("");
    // moment(new Date()).format("yyyy-MM-DD")
    let pagination = reactive({
      page: 1,
      limit: 25,
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const options = {
      texts: {
        count: "",
      },
    };
    watch(
      () => pagination.status,
      () => {
        getAllTrips();
      }
    );
    watch(
      () => pagination.date,
      () => {
        getAllTrips();
      }
    );
    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const Ticket = (tripId) => {
      ticketHistoryActive.value = true;
      tripIdIndividual.value = tripId;
    };
    const getAllTrips = () => {
      showLoader();
      store
        .dispatch("getAllTripsForCountermanAgentIntercity", pagination)
        .then(() => {
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    };
    onBeforeMount(() => {
      getAllTrips();
    });
    return {
      pagination,
      dropdownVariables,
      close,
      dateFormat,
      status,
      options,
      getAllTrips,
      hasPermission,
      Agent,
      Ticket,
      ticketHistoryActive,
      tripIdIndividual,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
