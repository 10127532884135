<template>
  <div>
    <label v-if="inputLabel" :for="inputId" class="label_font">{{
      t(inputLabel, {}, { locale: $store.getters.getLang })
    }}</label>
    <textarea
      :id="inputId"
      class="input_field form-control rounded py-2 px-3 w-100 mt-1"
      v-model="input"
      :type="inputType"
      autocomplete="off"
      rows="4"
      cols="50"
      :placeholder="inputPlaceholder"
    ></textarea>
    <slot name="error"></slot>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
  },
  setup(props, { emit }) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const { t, locale } = useI18n();
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    watch(
      () => props.type,
      () => {
        if (props.type === "password") {
          inputType.value = "password";
        }
        if (props.type === "email") {
          inputType.value = "email";
        }
        if (props.type === "text") {
          inputType.value = "text";
        }
      }
    );
    return {
      t,
      locale,
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
    };
  },
};
</script>

<style scoped></style>
