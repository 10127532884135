<template>
  <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 bus_border">
    <div class="rounded mt-2 py-2">
      <div class="row borderinner">
        <div class="col-2">
          <img alt="stairing" src="@/assets/images/jatri_stair.svg"/>
        </div>
        <div class="col-8">
          <SeatColorIndicator/>
        </div>
        <div class="col-2">
          <img alt="" class="stairing" src="@/assets/images/jatri_wheel.svg"/>
        </div>
      </div>
      <div v-for="(plan, index) in tripDetails.seatList"
           :key="index"
           class="row row-cols-12 mt-1 py-2 text-center fw-bold"
      >
        <div v-for="(seat, seatId) in plan"
             :key="'seat' + seatId + index"
             class="col"
        >
          <div v-if="seat.seat_no === ''">
            <span>{{ seat.seat_no }}</span>
          </div>
          <div v-else-if="seat.status === 'AVAILABLE' || seat.status === 'BOOKED_QUOTA'">
            <span
                :class=" seatColors[seat.seat_no]"
                class="seat selected cursor user-select-none"
                @click="selectSeatHandler(seat, {row: index, col: seatId})"
            >
              <div v-if="seat.status === 'BOOKED_QUOTA'" class="custom-lock" title="Not available">
               <i class='fa fa-lock'></i>
              </div>
                    {{ seat.seat_no }}
                    <div class="hand"></div>
              </span>
          </div>
<!--          <div v-else-if="seat.status === 'SEAT_IN_PROGRESS'">-->
<!--            <span-->
<!--                :class="seatColor[seat.status] || seatColors[seat.seat_no]"-->
<!--                class="seat selected user-select-none">-->
<!--               {{ seat.seat_no }}-->
<!--               <div class="hand"></div>-->
<!--            </span>-->
<!--          </div>-->
          <div v-else>
            <Popper   hover @mouseenter="enterMouse(seat)" @mouseleave="mouseLeave">
              <span
                  :class="seatColor[seat.status] || seatColors[seat.seat_no]"
                  class="seat selected cursor user-select-none text-white"
                  @click="seat.status === 'SEAT_IN_PROGRESS' ? false : getTicketsInfo(seat)"
              >
                  {{ seat.seat_no }}
                  <div class="hand"></div>
              </span>
              <template #content>
                <PopperBody v-if="seatHovered === seat.seat_no" :seat="seat.seat_no" :seatDetails="seat"/>
              </template>
            </Popper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot name="seatinfo"></slot>
</template>
<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import store from "@/store";
import {
  colorChange,
  seatColors,
  seatCount,
} from "@/composables/counterman/seatList"
import PopperBody from "@/components/layouts/Popper/PopperBody.vue";
import SeatColorIndicator from "@/components/counterman/SeatColorIndicator";
import toast from "@/modules/Toast";
import variables from "@/modules/variables";
import {seatFareCalculate} from "@/helpers/fareCalculate";
export default {
  name: "SeatList",
  props: {
    singleTripDetails: Object,
  },
  emits: ["openGlobalFilter"],

  components: {
    PopperBody,
    SeatColorIndicator,
  },
  setup(props, {emit}) {
    const seatColor = variables.seatColor;
    const seatHovered = ref("")
    const isActiveTicketModal = ref(false)
    const {warningToast} = toast();
    const selectedSeatFare = ref([]);
    const maxSeatPerTicket = computed(() => store.getters.maxSeatPerTicket)
    const tripDetails = computed(() => props.singleTripDetails)
    let interval = null

    watch(() => store.getters.getSeatResetStatus, () => {
      if (store.getters.getSeatResetStatus === true) {
        seatColors.value = {};
        seatCount.value = 0;
        selectedSeatFare.value = [];
        store.commit("resetTicketDetails")
      }
    })

    const enterMouse = (seat) => {
      interval = setTimeout(()=>{
        seatHovered.value = seat.seat_no;
      },500)
    }

    const mouseLeave = () => {
      store.commit("setTicketShortInfo", {})
      seatHovered.value = ""
      clearTimeout(interval)
    }

    const openGlobalFilter = (value) => {
      emit("openGlobalFilter", value)
    }

    const selectSeatHandler = (seat, seatPosition=null) => {
      if(seat.status === 'AVAILABLE'){
        selectSeat(seat, seatPosition)
      }
    }

    const getTicketsInfo = (seat) => {
      store
          .dispatch("getTicketsInfo", {
            tripId: tripDetails.value.tripId,
            seatNo: seat.seat_no,
          })
          .then(( ) => {
            openModalReservation(seat)
          }).catch(()=>{
      });
    }

    //TODO::Commenting for future seat on progress feature add


    // const addOnProgressSeat = async (seat) => {
    //   return await store
    //       .dispatch("addOnProgressSeat", {
    //         tripId: tripDetails.value.tripId,
    //         seat: seat.seat_no,
    //       })
    // }

    // const removeOnProgressSeat = async (seat) => {
    //   await store
    //       .dispatch("removeOnProgressSeat", {
    //         tripId: tripDetails.value.tripId,
    //         seat: seat.seat_no,
    //       })
    //       .then(() => {})
    //       .catch((error)=>{
    //         console.log(error)
    //       });
    // }

    const openModalReservation = () => {
      store.commit("setSearchTickets", store.getters.getTicketsInfo)
      openGlobalFilter({isOpen: true, from: 'seatList'})
      isActiveTicketModal.value = true
    }

    //TODO::Commenting for future seat on progress feature add

    // const selectSeat = async (seat, seatPosition = null) => {
    //   if (seat.status === 'AVAILABLE') {
    //     if (store.getters.getSeatResetStatus === true) store.commit("setSeatResetStatus", false);
    //     let findSeat = selectedSeatFare.value.find(s => s.seat_no === seat.seat_no);
    //
    //     if (selectedSeatFare.value.length === 0 || !findSeat) {
    //       if(userCompanyDetails.value?.fareType !== "instant_fare" && (seat.fare === 0 || seat.fare === null || seat.fare === '')) {
    //         return warningToast(`Seat fare not found!`);
    //       }
    //       if (selectedSeatFare.value.length > maxSeatPerTicket.value - 1) {
    //         warningToast(`You can sell ${maxSeatPerTicket.value} tickets at a time !!`);
    //         return;
    //       }
    //
    //       await addOnProgressSeat(seat).then(() => {
    //         colorChange(seat.seat_no, maxSeatPerTicket.value)
    //         store.commit("setSeatFareIntoSeatDetails", selectedSeatFare.value)
    //         selectedSeatFare.value.push(seat);
    //         seatFareCalculate(selectedSeatFare);
    //       }).catch((error)=>{
    //         console.log(error)
    //         store.commit("setSeatInProgress", seatPosition)
    //       });
    //     } else {
    //       await removeOnProgressSeat(seat)
    //       await colorChange(seat.seat_no, maxSeatPerTicket.value)
    //       selectedSeatFare.value = selectedSeatFare.value.filter(s => s.seat_no !== seat.seat_no);
    //       store.commit("setSeatFareIntoSeatDetails", selectedSeatFare.value)
    //       seatFareCalculate(selectedSeatFare);
    //     }
    //   }
    // }

    const selectSeat = async (seat) => {
      if (seat.status === 'AVAILABLE') {
        if (store.getters.getSeatResetStatus === true) store.commit("setSeatResetStatus", false);
        let findSeat = selectedSeatFare.value.find(s => s.seat_no === seat.seat_no);

        if (selectedSeatFare.value.length === 0 || !findSeat) {
          if(userCompanyDetails.value?.fareType !== "instant_fare" && (seat.fare === 0 || seat.fare === null || seat.fare === '')) {
            return warningToast(`Seat fare not found!`);
          }
          if (selectedSeatFare.value.length > maxSeatPerTicket.value - 1) {
            warningToast(`You can sell ${maxSeatPerTicket.value} tickets at a time !!`);
            return;
          }
          await colorChange(seat.seat_no, maxSeatPerTicket.value)
          selectedSeatFare.value.push(seat);
        } else {
          await colorChange(seat.seat_no, maxSeatPerTicket.value)
          selectedSeatFare.value = selectedSeatFare.value.filter(s => s.seat_no !== seat.seat_no);
        }
        store.commit("setSeatFareIntoSeatDetails", selectedSeatFare.value)
        seatFareCalculate(selectedSeatFare);
      }
    }

    onBeforeMount(() => {
      seatColors.value = {};
      seatCount.value = 0;
      selectedSeatFare.value = [];
      store.commit("setSelectedSeatFareTotal", 0)
      store.commit("setSeatResetStatus", false)
    })

    watch(() => store.getters.getTripForTicketingNew, () => {
      seatColors.value = {};
      seatCount.value = 0;
      selectedSeatFare.value = [];
    })

    const userCompanyDetails = computed(() => store.getters.userCompanyData)

    return {
      selectSeatHandler,
      openModalReservation,
      isActiveTicketModal,
      openGlobalFilter,
      seatColors,
      colorChange,
      tripDetails,
      seatColor,
      seatHovered,
      enterMouse,
      mouseLeave,
      getTicketsInfo,
      selectedSeatFare,
      userCompanyDetails
    }
  }
}
</script>

<style scoped>
/* responsive start  */
@media only screen and (min-width: 1400px) {
  .font_detail {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .font_detail {
    width: 200px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font_detail {
    overflow-x: scroll;
    width: 100%;
  }

  button.font_detail {
    overflow-x: unset;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .font_detail {
    height: auto;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .padding-left {
    padding-left: 32px;
  }

  .font_detail,
  .booking_info {
    margin-bottom: 4px;
  }

  /* .booking_info_sm_device{
          margin-left: 13px;
      } */
}

/* responsive end */

.custom-lock {
  position: absolute;
  left: 0;
  transform: translate(0%, 10%);
  width: 30px;
  background: #ffffff;
  z-index: 100;
  top: 1px;
  cursor: not-allowed;
}

.fa-lock {
  color: #b2b2b2;
  font-size: 20px;
  padding: 3px;
  border-radius: 3px;
}

.spacing {
  letter-spacing: 1px;
}

.corporate_btn {
  background-color: #00b74a;
  border: 1px solid #ece3e2;
  color: #fff;
}

.corporate_btn:hover {
  color: #00b74a;
}

.confirm_btn {
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 8px;
  width: 170px;
}

.confirm_btn:hover {
  background-color: #fff;
}

.tableInfo_border {
  border: 1px solid #f04935;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.booking_info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}

.booking_info label {
  text-align: right;
}

.booking_info label:after {
  content: ":";
}

.disabled {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.seat.selected,
.seat.selected:before {
  border-color: #645f5f;
  background: #fff;
}

.seat {
  position: relative;
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid #645f5f;
  display: inline-block;
  border-radius: 3px;
  color: #000;
}

/* .seat:after, .seat:before {
  content: "";
  position: absolute;
  width: 20px;
  background: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
} */
.seat:before {
  height: 5px;
  border: 1px solid #645f5f;
  bottom: 3px;
}

.seat:after {
  position: absolute;
  top: -5px;
  height: 8px !important;
  width: 10px !important;
  background-color: #f7f7f7;
  border: 1px solid #645f5f;
}

.seat.selected .hand,
.seat.selected:after {
  background: #fff;
}

.seat .hand {
  width: 18px;
  height: 3px;
  left: 2px;
  top: 23px;
}

.seat .hand,
.seat .hand:before {
  position: absolute;
  background: #fff;
}

.seat.selected .hand:after,
.seat.selected .hand:before {
  border-color: #645f5f;
  background: #fff;
}

.seat .hand:after,
.seat .hand:before {
  content: "";
  width: 6px;
  height: 13px;
  border: 1px solid #645f5f;
  bottom: -1px;
}

.seat .hand:before {
  right: -14px;
}

.seat.selected .hand:after,
.seat.selected .hand:before {
  border-color: #645f5f;
  background: #fff;
}

.seat .hand:after {
  position: absolute;
  background: #fff;
  left: -5px;
}

.seat .hand:after,
.seat .hand:before {
  content: "";
  width: 7px;
  height: 15px;
  border: 1px solid #645f5f;
  bottom: 0px;
}

.seat.selected .hand,
.seat.selected:after {
  background: #fff;
}

.seat:after {
  height: 3px;
  bottom: 0;
}

.seat:after,
.seat:before {
  content: "";
  position: absolute;
  width: 30px;
  background: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.borderinner {
  border-bottom: 1px dashed rgb(73, 71, 71);
}

.stairing {
  height: 50px;
}

.txt_color {
  color: #f04935;
}

.bus_border {
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #f04935;
}

.remove_button {
  border: 2px solid #ff072c;
  border-radius: 20px;
  background-color: #ff072c;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.edit_button {
  border: 2px solid #ffc107;
  border-radius: 20px;
  background-color: #ffc107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.edit_button:hover,
.edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.right_border {
  border-right: 1px solid #ccc;
}

.seatSelect {
  cursor: pointer;
  width: 45px;
  color: #fff;
}

/* .seat {
  cursor: default;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
} */
.seatNormal {
  cursor: pointer;
  background-color: rgb(216, 215, 215);
  width: 45px;
  color: rgb(20, 19, 19);
}

.ticket-info {
  font-size: 12px !important;
}

.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.reset_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.reset_button:hover,
.reset_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.seat-empty {
  cursor: default;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
}
</style>
