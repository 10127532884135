import store from "@/store";
// import {seatColors, seatCount} from "@/composables/counterman/seatList";
// import toast from "@/modules/Toast";
// const {warningToast} = toast();

const seatFareCalculate = (selectedSeatFare) => {
    const fareType = store.getters.userCompanyData.fareType;
    if (fareType === "predefined" || fareType === 'trip_fare'  ) {
        let selectedSeatTotalFareLocal = 0
        for (let i = 0; i < selectedSeatFare.value.length; i++) {
            selectedSeatTotalFareLocal += selectedSeatFare.value[i].fare
        }
        store.commit("setSelectedSeatFareTotal", selectedSeatTotalFareLocal)
    }
}

export {
    seatFareCalculate
}
