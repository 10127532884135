import store from "@/store";
import {inject, ref, reactive, watch, onBeforeUpdate, nextTick} from "vue";
import moment from "moment";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";


const getViewTrip = (shouldRefreshPageGoBack) => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let tripDetails = reactive({
        route: "",
        coach: "",
    });
    let activeTrip = ref("");
    let tripDashboardActive = ref(false);

    const viewTrip = (tripId, routeName, coachName, tripRef) => {
        shouldRefreshPageGoBack.value = false;
        store.commit("setPurchaseStatus", false);
        if (tripId !== "") {
            showLoader();
            const payload = {
                tripId: tripId,
                fromStoppage: '',
                toStoppage: '',
                boarding: '',
            }
            store.dispatch("getTicketByTripNew", payload)
                .then(() => {
                    tripDetails.route = routeName;
                    tripDetails.coach = coachName;
                    activeTrip.value = tripId;
                    tripDashboardActive.value = true;
                    store.commit("resetTicketDetails")
                    hideLoader();
                    nextTick(() => {
                        tripRef.find(el => {
                            if (el.dataset.tripId === tripId) {
                                el.scrollIntoView({behavior: 'smooth', block: 'center'})
                            }
                            }
                        );
                    })
                })
                .catch(() => {
                    hideLoader();
                });
        } else {
            tripDetails.route = routeName;
            tripDetails.coach = coachName;
            activeTrip.value = tripId;
            tripDashboardActive.value = true;
        }
    };

    return {
        viewTrip,
        tripDetails,
        activeTrip,
        tripDashboardActive
    }
}

const getAllTrips = () => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let pagination = reactive({
        page: 1,
        limit: 500,
        from: "",
        to: "",
        coach: "",
        upcoming: "0",
        date: moment(new Date()).format("yyyy-MM-DD"),
        status: "ACTIVE",
    });
    const getAllTripsForTicketing = () => {
        showLoader();
        store
            .dispatch("getTripListByPage", pagination)
            .then(() => {
                hideLoader();
            })
            .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                    getAllTripsForTicketing();
                }
            });
    };

    watch(
        () => pagination,
        () => {
            getAllTripsForTicketing();
        },
        {deep: true}
    );


    watch(
        () => pagination.date,
        () => {
            pagination.upcoming = "0"
        }
    );

    watch(
        () => pagination.from,
        () => {
            pagination.page = 1
        }
    );
    watch(
        () => pagination.coach,
        () => {
            pagination.page = 1
        }
    );

    return {getAllTripsForTicketing, pagination}
}

// const getAllRouteForTrips = () => {
//     store.dispatch("getCountermanAllRouteForTrips", {companyId: store.getters.userCompany}).then(() => {
//
//     }).catch(() => {
//     })
// }

// const getTripFromAndToStoppageGenerate = (singleTripDetails) => {
//     let fromStoppage = ref("");
//     let toStoppage = ref("");
//     if (
//         store.getters.getUserInfo?.counter?.preferredStoppage &&
//         store.getters.getUserInfo?.counter?.preferredStoppage.length > 0
//     ) {
//         fromStoppage.value = "";
//         for (let item of store.getters.getUserInfo?.counter
//             ?.preferredStoppage) {
//             let withoutLastStoppage = [
//                 ...singleTripDetails.route.routeSequence,
//             ];
//             withoutLastStoppage.pop();
//             if (withoutLastStoppage.some((data) => data._id === item._id)) {
//                 fromStoppage.value = item._id;
//                 break;
//             }
//         }
//         if (fromStoppage.value === "") {
//             fromStoppage.value = singleTripDetails.route.fromStoppageId;
//         }
//     } else {
//         fromStoppage.value = singleTripDetails.route.fromStoppageId;
//     }
//     toStoppage.value = singleTripDetails.route.toStoppageId;
//
//     return {fromStoppage, toStoppage}
// }

const setNearestTripScroll = () => {
    const nearestTrip = ref('');
    let tripRef = ref([]);

    const findNearestTripe = () => {
        let minDiff = null;
        const DateList = store.getters.getCountermanTripListByPage && store.getters.getCountermanTripListByPage.map((trip) => trip.tripDateTime)
        if(DateList === undefined) return;
        let mostAccurateDate = DateList[0];

        DateList.map((item, index) => {
            const currentTime = dateTimeFormat(new Date(), 6);
            const tripTime = dateTimeFormat(item, 0);
            if (new Date(tripTime) > new Date(currentTime)) {
                const diff = (new Date(tripTime) - new Date(currentTime));
                if (!minDiff || diff < minDiff) {
                    minDiff = diff;
                    mostAccurateDate = DateList[index]
                }
            }
        });
        nearestTrip.value = mostAccurateDate;
        findTripAndScroll()
    }

    const findTripAndScroll = () => {
        tripRef.value.find(el => {
            if (el.dataset.tripTime === nearestTrip.value) {
                el.scrollIntoView({behavior: 'smooth', block: 'center'})
            }
            }
        );
    }

    const setTripRef = el => {
        if (el) {
            tripRef.value.push(el)
        }
    }
    onBeforeUpdate(() => {
        tripRef.value = []
    })

    return {
        tripRef,
        nearestTrip,
        findNearestTripe,
        setTripRef,
    }

}

const getSearchResultWithPnrOrPhone = (dashboardActive, globalFilterTab) => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const SearchResultWithPnrOrPhone = (searchQuery) => {
        showLoader()
        store
            .dispatch("searchWithPnrOrPhone", {search: searchQuery})
            .then(() => {
                dashboardActive.value = false;
                globalFilterTab.value = true;
                hideLoader();
            })
            .catch(() => {
                hideLoader();
            });
    };

    return {
        SearchResultWithPnrOrPhone
    }
}

const viewTripWithStoppage = (payload) => {
    if (payload.toStoppage === payload.fromStoppage) {
        return;
    }
    store.dispatch("getTicketByTripNew", payload)
};

export {
    getViewTrip,
    getAllTrips,
    // getAllRouteForTrips,
    // getTripFromAndToStoppageGenerate,
    setNearestTripScroll,
    getSearchResultWithPnrOrPhone,
    viewTripWithStoppage
};
