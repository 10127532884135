<template>
  <AgentNav />
  <router-view></router-view>
</template>

<script>
import AgentNav from "@/components/layouts/Nav/AgentNav";
export default {
  name: "Agent",
  components: { AgentNav },
};
</script>

<style scoped></style>
