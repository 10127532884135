<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">{{
            t("Go Back", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="classWiseReport.length > 0"
          class="edit_table_data font_detail"
          @click="exportTable('xlsx')"
        >
          <i class="fas fa-download"></i
          ><span class="mx-1 bold">{{
            t("Export", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div
    class="card h-100 mt-4"
    v-for="(itemValue, itemKey) in classWiseReport"
    :key="itemKey"
  >
    <div class="card-header text-center bg-white">
      <h5 class="mb-0 text-uppercase">
        {{ t("EXTRA TICKET", {}, { locale: $store.getters.getLang }) }}
      </h5>
    </div>
    <div class="card-body mt-0 py-0">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{
                t("Ticket_Date_Time", {}, { locale: $store.getters.getLang })
              }}
            </td>

            <td class="font_detail">
              {{ t("COACH NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("PNR CODE", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Passenger Name", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{
                t("Passenger Mobile", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td class="font_detail">
              {{ t("Boarding place", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Dropping place", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td v-if="coachWiseSeatReport.discount" class="font_detail">
              {{ t("Discount", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("Issued By", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ t("Cancelled_by", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{
                t("Cancellation Cause", {}, { locale: $store.getters.getLang })
              }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">{{ dateFormat(item.ticketDateTime) }}</td>

            <td class="font_detail">{{ coachNameProps }}</td>
            <td class="font_detail">{{ item.pnrCode }}</td>
            <td class="font_detail">{{ item.passengerName }}</td>
            <td class="font_detail">{{ item.passengerPhone }}</td>
            <td class="font_detail">{{ item.boardingPlace }}</td>
            <td class="font_detail">{{ item.droppingPoint }}</td>

            <td class="font_detail">{{ item.totalSeat }}</td>
            <td class="font_detail">
              {{
                coachWiseSeatReport.discount === 1
                  ? item.totalAmount
                  : item.payable
              }}
            </td>
            <td v-if="coachWiseSeatReport.discount" class="font_detail">
              {{ item.discount }}
            </td>
            <td class="font_detail">{{ item.issuedBy?.name }}</td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item.cancelledBy?.name }}
            </td>
            <td class="font_detail" v-if="cancelledBy === 0">
              {{ item.cancellationCause }}
            </td>
          </tr>
          <tr class="bg-dark text-white" v-if="summary">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
              {{ itemValue.totalTicket }}
            </td>
            <td class="font_detail text-white">
              {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
              {{ itemValue.totalAmount }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td
              v-if="coachWiseSeatReport.discount"
              class="font_detail text-white"
            >
              {{ t("Total Discount", {}, { locale: $store.getters.getLang }) }}:
              {{ itemValue.totalDiscount }}
              {{ t("Taka", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
            <td class="font_detail" v-if="cancelledBy === 0"></td>
          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onBeforeMount, inject } from "vue";
import store from "@/store";
import moment from "moment";
import TableSimple from "@/components/layouts/Tables/TableSimple.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "SeatDetails",
  components: { TableSimple },
  props: {
    coachId: String,
    coachName: String,
    status: Number,
    discount: [Number, String],
    fromDate: String,
    toDate: String,
  },
  emits: ["backToCoachWiseReport"],
  setup(props, { emit }) {
    let coachId = ref(props.coachId);
    let coachNameProps = ref(props.coachName);
    let totalAmountSeat = ref(0);
    let totalTicket = ref(0);
    let totalDiscount = ref(0);
    let summary = ref(false);
    let cancelledBy = ref(props.status);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    const { t, locale } = useI18n();
    let coachWiseSeatReport = reactive({
      ticketType: "EXTRA",
      coachId: coachId.value,
      fromDate: props.fromDate,
      toDate: props.toDate,
      discount: props.discount,
      status: props.status,
    });

    let classWiseReport = reactive({});

    const options = {
      texts: {
        count: "",
      },
    };

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm A");
    };

    const getSeatDetails = () => {
      showLoader();
      store
        .dispatch("getExtraDetailsOwner", coachWiseSeatReport)
        .then((response) => {
          hideLoader();
          if (response.length !== 0) {
            classifyByClassName(response);
          } else {
            summary.value = false;
          }
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getSeatDetails();
          }
        });
    };

    const classifyByClassName = (reports) => {
      reports.forEach((report) => {
        if (report.seatClass in classWiseReport) {
          classWiseReport[report.seatClass].totalReport.push(report);
        } else {
          classWiseReport[report.seatClass] = {
            totalReport: [],
            totalTicket: 0,
            totalAmount: 0,
            totalDiscount: 0,
          };
          classWiseReport[report.seatClass].totalReport.push(report);
        }
      });
      seatDetailsdata();
      summary.value = true;
    };

    const seatDetailsdata = () => {
      for (const key in classWiseReport) {
        classWiseReport[key].totalReport.forEach((data) => {
          classWiseReport[key].totalAmount +=
            coachWiseSeatReport.discount === 1
              ? data.totalAmount
              : data.payable;
          classWiseReport[key].totalTicket += data.totalSeat;
          classWiseReport[key].totalDiscount += data.discount;
        });
      }
    };

    const goBack = () => {
      emit("backToCoachWiseReport", true);
    };

    const exportTable = (type) => {
      for (const key in classWiseReport) {
        exportExcel(
          type,
          key + "_Owner_CoachWise_ExtraSeat_Report.",
          undefined,
          key
        );
      }
    };

    onBeforeMount(() => {
      getSeatDetails();
    });

    return {
      t,
      locale,
      coachWiseSeatReport,
      totalAmountSeat,
      goBack,
      exportTable,
      totalTicket,
      coachNameProps,
      summary,
      getSeatDetails,
      options,
      cancelledBy,
      totalDiscount,
      dateFormat,
      classWiseReport,
    };
  },
};
</script>
<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>
