<template>
  <div>
    <FilterTab>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="Object.keys(reportData).length"
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Payment_Settlement_Report_Details.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div
      class="card h-100 mt-4"
  >
    <div class="card-header bg-white" v-if="Object.keys(reportData).length">
      <div class="row">
        <h4>Withdraw Request Details</h4>
        <div class="col-6">
          <h4 class="mb-2 fw-bold">
            Counterman Info
          </h4>
          <p><b>Name:</b> {{reportData?.userId?.name}}</p>
          <p><b>Mobile:</b> {{reportData?.userId?.phone}}</p>
          <p><b>Counter:</b> {{reportData?.counter?.name}}</p>
        </div>
        <div class="col-6">
          <h4 class="mb-2 fw-bold">
            Payment Info
          </h4>
          <p><b>Payment Method:</b> {{reportData?.paymentMethod?.gateway}}</p>
          <p><b>Account Number:</b> {{reportData?.paymentMethod?.accountNumber}}</p>
          <p><b>Amount:</b> {{reportData?.amount}} Taka</p>
        </div>

      </div>
    </div>
    <div class="card-body mt-0 py-0" v-if="Object.keys(reportData).length">
      <Table id="exportable_data">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Description</td>
            <td class="font_detail">Status</td>
            <td class="font_detail">Date & Time</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-if="reportData?.actionBy?.name">
            <td class="font_detail">
              1
            </td>
            <td class="font_detail">
              Withdraw Request {{reportData?.withdrawStatus?.toLowerCase()}} By {{reportData?.actionBy?.name}} ({{reportData?.actionBy?.phone}} - <span class="text-capitalize">{{reportData?.actionBy?.userType}}</span>)
            </td>
            <td class="font_detail">
              {{reportData?.withdrawStatus}}
            </td>
            <td class="font_detail">
              {{ dateFormat(reportData?.actionAt) }}
            </td>
          </tr>
          <tr>
            <td class="font_detail">
              {{ reportData?.actionBy?.name ? "2" : "1"}}
            </td>
            <td class="font_detail">
              Withdraw Request created By {{reportData?.userId?.name}} ({{reportData?.userId?.phone}})
            </td>
            <td class="font_detail">
              PENDING
            </td>
            <td class="font_detail">
              {{ dateFormat(reportData?.createdAt) }}
            </td>
          </tr>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import {ref, inject} from "vue";
import moment from "moment/moment";

export default {
  name: "PaymentSettlementReportDetails",
  props: {
    reportDetails: Object,
  },
  emits: ["backToSettlementReport"],
  setup(props, { emit }) {
    let reportData = ref(props?.reportDetails);
    const exportExcel = inject("exportExcel");

    const dateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, true)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const goBack = () => {
      emit("backToSettlementReport", true);
    };

    return {
      goBack,
      reportData,
      dateFormat,
      exportExcel,
    };
  },
};
</script>
<style scoped>
h3,
h5 {
  font-size: 36px;

}
h5 {
  font-size: 18px;
}
</style>