<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalDropdown
            v-model="tripData.seatPlan"
            v-bind:id="'seatPlan'"
            v-bind:label="'Seat Plan'"
            v-bind:defaultOption="'---select a seat plan---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="
              $store.getters.getOwnerServicesSeatPlanListForFilter
            "
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.seatPlan"
                v-bind:error="errors.seatPlan"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
            v-model="tripData.seatClass"
            v-bind:id="'seatClass'"
            v-bind:label="'Seat Class'"
            v-bind:defaultOption="'---select a seat class---'"
            v-bind:defaultValue="''"
            v-bind:dropdown="allSeatClass"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.seatClass"
                v-bind:error="errors.seatClass"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
            v-model="tripData.tripFare"
            v-bind:placeholder="'Enter Trip fare'"
            v-bind:label="'Trip Fare'"
            v-bind:id="'tripFare'"
            v-bind:type="'number'"
            v-bind:minNumber="true"
          >
            <template v-slot:error>
              <ModalError
                v-if="errors.tripFare"
                v-bind:error="errors.tripFare"
              />
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCoachwiseTrip"
            /> -->
            <button
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addCoachwiseTrip"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { ref, inject, onBeforeMount, watch } from "vue";
import store from "@/store";
import useInputValidation from "@/modules/useInputValidations";
import moment from "moment";
import { useI18n } from "vue-i18n";
export default {
  name: "CoachwiseTripAddUpdateModal",
  props: {
    coachId: String,
    date: String,
  },
  setup(props, { emit }) {
    const title = ref("Coachwise Trip");
    let coachID = ref(props.coachId);
    let dateProp = ref(props.date);
    let detailsTrip = ref({});
    let allSeatClass = ref({});
    let fareTypeObject = ref({});
    let fareType = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let { requiredValidation, errors, validNumberValidation } =
      useInputValidation();
    detailsTrip.value = store.getters.getCoachwiseTripOwner(coachID.value);
    let tripData = reactive({
      companyId: store.getters.userCompany,
      fromDate: moment(new Date(dateProp.value)).format("yyyy-MM-DD"),
      toDate: moment(new Date(dateProp.value)).format("yyyy-MM-DD"),
      tripTime: detailsTrip.value[0]?.coachTime,
      route: detailsTrip.value[0]?.coachRoute?._id,
      fare: null,
      coach: detailsTrip.value[0]?._id,
      seatPlan: "",
      seatClass: "",
      driver: null,
      supervisor: null,
      fromStoppage: detailsTrip.value[0]?.coachRoute?.fromStoppageId?._id,
      toStoppage: detailsTrip.value[0]?.coachRoute?.toStoppageId?._id,
      tripDate: "",
      blockedSeats: [],
      coachRegistrationNumber: "",
      tripFare: 0,
      status: "ACTIVE",
    });
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("seatPlan", tripData.seatPlan);
      requiredValidation("seatClass", tripData.seatClass);
      validNumberValidation("tripFare", tripData.tripFare);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    watch(
      () => tripData.seatPlan,
      () => {
        requiredValidation("seatPlan", tripData.seatPlan);
      }
    );
    watch(
      () => tripData.seatClass,
      () => {
        requiredValidation("seatClass", tripData.seatClass);
      }
    );
    watch(
      () => tripData.tripFare,
      () => {
        validNumberValidation("tripFare", tripData.tripFare);
      }
    );
    fareTypeObject.value = store.getters.userCompanyData;
    fareType.value = fareTypeObject.value.fareType;
    const addCoachwiseTrip = () => {
      tripData.tripFare = parseInt(tripData.tripFare);
      if (validate()) {
        showLoader();
        store
          .dispatch("addOwnerServicesTrip", {
            tripData: tripData,
            fareType: {
              fareType: fareType.value,
              page: 1,
              limit: 25,
              status: "",
              date: moment(new Date(dateProp.value)).format("yyyy-MM-DD"),
            },
          })
          .then(() => {
            hideLoader();
            emit("getAllTrips");
            emit("close");
          })
          .catch((error) => {
            hideLoader();
            // emit("close");
            if (error.status === 401) {
              // emit("getAllTrips");
              // emit("close");
              addCoachwiseTrip();
            }
          });
      }
    };
    onBeforeMount(() => {
      let seatClass = store.getters.getOwnerServicesSeatClass;
      seatClass.forEach((item) => {
        allSeatClass.value[item._id] = item.name;
      });
    });
    return {
      t,
      locale,
      tripData,
      closeModal,
      title,
      dropdownVariables,
      addCoachwiseTrip,
      errors,
      allSeatClass,
    };
  },
};
</script>

<style scoped></style>
