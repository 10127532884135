<template>
  <Modal @close="closeModal">
    <template v-slot:title><span class="text-capitalize">{{statusUpdateSelectedData?.title}} Payment</span></template>
    <template v-slot:body>
      <template v-if="true">
        Are you sure you want to <span class="text-capitalize fw-bold">{{statusUpdateSelectedData?.title}}</span> this payment?
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <div class="float-end">
              <input
                  type="submit"
                  class="mt-3 me-5 py-2  submit_button  font_detail"
                  value="No"
                  @click="modalClose"
              />
              <input
                  type="submit"
                  class="mt-3 py-2 submit_button font_detail"
                  value="Yes"
                  @click.prevent="settlementStatusUpdate"
              />
            </div>
          </div>
        </div>
      </template>

    </template>
  </Modal>
</template>

<script>
export default {
  name: "PaymentSettlementStatusModal",
  props: {
    statusUpdateSelectedData: Object,
  },
  emits: ['settlementStatusUpdate'],
  setup(props, { emit }) {

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const modalClose = () => {
      emit('close')
    }

    const settlementStatusUpdate = () => {
      emit("settlementStatusUpdate");
      emit("close");
    }

    return {
      closeModal,
      props,
      modalClose,
      settlementStatusUpdate
    };
  },
};
</script>

<style scoped></style>
