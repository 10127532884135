<template>
  <div>
    <div v-if="QRSettingTabs">
      <ul
          class="nav nav-tabs justify-content-center nav-style"
          id="myTab"
          role="tablist"
      >
        <li
            v-for="(tab, index) in QRSettingTabs"
            :key="index"
            class="nav-item"
            role="presentation"
        >
          <button
              class="nav-link"
              :id="tab.name + '-tab'"
              data-bs-toggle="tab"
              v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
              type="button"
              @click="setActive(tab.name)"
              role="tab"
              :aria-controls="tab.path"
              aria-selected="true"
          >
            {{ tab.title }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
            v-for="(tab, index) in QRSettingTabs"
            :key="index"
            class="tab-pane fade"
            v-bind:class="tab.name === activeTab ? 'show active' : ''"
            :id="tab.path"
            role="tabpanel"
            :aria-labelledby="tab.name + '-tab'"
        ></div>
        <QRSettings v-if="activeTab === QRSettingTabs.QR_URL.name" />
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import web from "@/urls/web";
import store from "@/store";
import QRSettings from "@/views/Admin/Settings/children/qr/index.vue";

const { hasPermission } = permission();
const { Admin } = roles();
export default {
  name: "Settings",
  components: {QRSettings},
  setup() {
    let activeTab = ref("");
    const QRSettingTabs = web.Admin.SETTINGS_URL.children;
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
          ? store.getters.getActiveTab
          : QRSettingTabs.QR_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      Admin,
      activeTab,
      QRSettingTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
