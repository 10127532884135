<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                v-model="admin.name"
                v-bind:placeholder="'Enter Admin Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="admin.designation"
                v-bind:placeholder="'Designation'"
                v-bind:label="'Designation'"
                v-bind:id="'designation'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                v-model="admin.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="admin.password"
                v-bind:placeholder="'Enter Password'"
                autocomplete="off"
                v-bind:label="'Password'"
                v-bind:id="'password'"
                v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password" />
            </div>
            <div class="col-md-3">
              <Input
                v-model="admin.email"
                v-bind:placeholder="'Email Address'"
                autocomplete="off"
                v-bind:label="'Email'"
                v-bind:id="'email'"
                v-bind:type="fieldTypes.email"
              />
            </div>
            <div class="col-md-3">
              <Input
                v-model="admin.nid"
                v-bind:placeholder="'NID Number'"
                v-bind:label="'NID'"
                v-bind:id="'nid'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                v-if="status"
                v-model="admin.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
                v-bind:hideLabel="'inactive'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                v-if="editAccess"
                v-model="admin.seatPlanEditAccess"
                v-bind:id="'seatPlanEdit'"
                v-bind:label="'Seat Plan Edit'"
                v-bind:dropdown="editAccess"
                v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-6">
              <label for="address" class="form-label all_permission_label"
                >Address</label
              >
              <div>
                <textarea
                  class="form-control font_detail"
                  id="address"
                  v-model="admin.address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <hr class="section_devider" />
        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5><b>Roles</b></h5>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input all_permission_label"
                  type="checkbox"
                  id="select-all"
                  name="select-all"
                  @click="giveAllPermission"
                  v-model="allSelected"
                />
                <label
                  class="form-check-label all_permission_label"
                  for="select-all"
                  >Give All Permission</label
                >
              </div>
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">{{
            errors.roles
          }}</span>
          <span
            v-else-if="errors[Admin.companyDeployWrite]"
            class="text-danger err_msg"
            >{{ errors[Admin.companyDeployWrite] }}</span
          >
          <span
            v-else-if="errors[Admin.adminWrite]"
            class="text-danger err_msg"
            >{{ errors[Admin.adminWrite] }}</span
          >
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Dashboard</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Admin.dashboard"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Report</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Admin.reports"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Admin</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Admin.adminRead"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Write'"
                    v-bind:role="Admin.adminWrite"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Company Deploy</h5>
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Read'"
                    v-bind:role="Admin.companyDeployRead"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                    v-model="checked"
                    v-bind:label="'Write'"
                    v-bind:role="Admin.companyDeployWrite"
                    :key="allSelected"
                    @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trip Copy</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Copy'"
                      v-bind:role="Admin.tripCopy"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Cancel DT Ticket</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Cancel'"
                      v-bind:role="Admin.ticketCancel"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trip Bulk Cancel</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Cancel'"
                      v-bind:role="Admin.tripBulkCancel"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>QR Menu</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Payment Settlement'"
                      v-bind:role="Admin.paymentSettlement"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'QR Ticket'"
                      v-bind:role="Admin.qrTicket"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!adminIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addAdmin"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateAdmin"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import roles from "@/modules/roles";
import store from "@/store";

export default {
  name: "AdminAddUpdateModal",
  props: {
    modelValue: Boolean,
    adminId: String,
  },
  setup(props, { emit }) {
    let admin = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      passport: "",
      direction: "UP",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "admin",
      companyId: null,
      counterId: null,
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      reset_token: "",
      seatPlanEditAccess: "0",
      counterReportAccess: "",
    });
    let adminIdForUpdate = ref(props.adminId);
    let checked = ref([]);
    let allSelected = ref(false);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      requiredRoleValidation,
      errors,
    } = useInputValidation();
    const { Admin } = roles();
    const status = dropdownVariables.status;
    const editAccess = dropdownVariables.editAccess;
    const title = ref("Add Admin");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
      () => admin.name,
      () => {
        requiredValidation("name", admin.name);
      }
    );
    watch(
      () => admin.phone,
      () => {
        phoneValidation("phone", admin.phone);
      }
    );
    watch(
      () => admin.roles,
      () => {
        requiredValidation("roles", admin.roles);
      }
    );
    // watch(() => admin.password, () => {
    //   passwordValidation("password", admin.password);
    // });
    watch(
      () => admin.password,
      () => {
        fieldTypes.password = "password";
      }
    );
    watch(
      () => admin.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Admin);
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const toggleAllPermission = () => {
      allSelected.value = checked.value.length === Object.values(Admin).length;
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", admin.name);
      phoneValidation("phone", admin.phone);
      requiredValidation("roles", admin.roles);
      requiredRoleValidation(
        Admin.companyDeployWrite.toString(),
        checked.value,
        Admin.companyDeployRead,
        Admin.companyDeployWrite,
        Admin
      );
      requiredRoleValidation(
        Admin.adminWrite.toString(),
        checked.value,
        Admin.adminRead,
        Admin.adminWrite,
        Admin
      );
      if (adminIdForUpdate.value === "") {
        passwordValidation("password", admin.password);
      } else {
        if (admin.password !== "") {
          passwordValidation("password", admin.password);
        }
      }
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addAdmin = () => {
      admin.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("addAdmin", { admin, pagination })
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addAdmin();
            }
          });
      }
    };

    const updateAdmin = () => {
      admin.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
          .dispatch("updateAdmin", { admin, pagination })
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateAdmin();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (adminIdForUpdate.value !== "") {
        const adminDetails = store.getters.getAdmin(adminIdForUpdate.value);
        title.value = "Update Admin";
        admin.id = adminDetails[0]._id;
        admin.name = adminDetails[0].name;
        admin.designation = adminDetails[0].designation;
        admin.phone = adminDetails[0].phone;
        admin.email = adminDetails[0].email;
        admin.address = adminDetails[0].address;
        admin.transactionType = adminDetails[0].transactionType;
        admin.balance = adminDetails[0].balance;
        admin.passport = adminDetails[0].passport;
        admin.commission = adminDetails[0].commission;
        admin.companyId = adminDetails[0].companyId;
        admin.counterId = adminDetails[0].counterId;
        admin.seatPlanEditAccess = adminDetails[0].seatPlanEditAccess ? 1 : 0;
        admin.commissionType = adminDetails[0].commissionType;
        admin.nid = adminDetails[0].nid;
        admin.status = adminDetails[0].status ? 1 : 0;
        adminDetails[0].roles[0]
          .split(",")
          .forEach((element) => checked.value.push(parseInt(element)));
        Object.keys(Admin).length === checked.value.length
          ? (allSelected.value = true)
          : (allSelected.value = false);
      }
    });

    return {
      status,
      admin,
      errors,
      Admin,
      title,
      checked,
      allSelected,
      adminIdForUpdate,
      editAccess,
      giveAllPermission,
      toggleAllPermission,
      updateAdmin,
      addAdmin,
      closeModal,
      fieldTypes,
    };
  },
};
</script>

<style scoped></style>
