<template>
  <label :for="inputId" class="my-auto">
    <span> {{ t(inputLabel, {}, { locale: $store.getters.getLang }) }}</span>
    <span class="mx-2" :class="inputColor"><i :class="inputIcon"></i></span>
  </label>

  <div class="col-sm-9">
    <select
      class="form-select form-select-sm form_width font_detail p-2 mt-md-2"
      v-model="input"
      :id="inputId"
    >
      <option v-if="inputDefaultOption" :value="inputDefaultValue">
        {{ t(inputDefaultOption, {}, { locale: $store.getters.getLang }) }}
      </option>
      <option
        v-for="(type, index) in inputDropdown"
        :key="index"
        :value="returnItem ? type[returnItem] : index"
        :disabled="type[showItemName1] === null"
        :style="type[showItemName1] === null && colorDisable"
      >
        <span v-if="showItemName">{{ type[showItemName] }}  {{ (showItemName1 && type[showItemName1] !== null) ? ' - '+ modifyDate(type[showItemName1]) : '' }}</span>
        <span v-else>{{ type }}</span>
      </option>
    </select>
    <slot name="error"></slot>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import { useI18n } from "vue-i18n";
export default {
  name: "DropdownVerticalLabel",
  props: {
    modelValue: [String, Number],
    options: [Object, Array],
    defaultOption: String,
    defaultValue: String,
    label: String,
    id: String,
    icon: String,
    color: String,
    showItem: String,
    showItem1: String,
    returnItem: String,
  },
  setup(props, { emit }) {
    const inputDropdown = computed(() => props.options);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    const inputIcon = ref(props.icon);
    const inputColor = ref(props.color);
    const showItemName = ref(props.showItem);
    const showItemName1 = ref(props.showItem1);
    const { t, locale } = useI18n();
    const colorDisable = {
      background: '#eee',
      color: 'black'
    }
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.dropdown,
      (value) => {
        inputDropdown.value = value;
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    const modifyDate = (date) => {
      return dateTimeFormat(date, 0, 'DD MMM YY, LT')
    }
    return {
      t,
      locale,
      inputDropdown,
      inputDefaultOption,
      inputDefaultValue,
      inputLabel,
      inputId,
      input,
      inputIcon,
      inputColor,
      showItemName,
      showItemName1,
      modifyDate,
      colorDisable
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .booking_info {
    margin-bottom: 4px;
  }
  label {
    font-size: 11px;
  }
  select.form_width {
    width: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  select.form_width {
    width: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  select.form_width {
    width: 200px !important;
  }
}
/* @media only screen and (max-width: 613px) and (min-width: 360px) {
  select.form_width {
    width: 210px !important;
  }
} */
.danger {
  color: red;
}
.font_detail {
  /* font-size: 15px !important; */
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.form_width {
  width: 350px !important;
}
.booking_info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
.form-select:focus {
  border: 1px solid #f04935;
}
</style>
