import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

const {successToast} = toast();

const {hasValidToken, getToken} = tokenCookie();
const {messageExtractor} = validationMessage();
const {successResponse, errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
        seatPlan: {},
        route: {},
        counter: {},
        routes: {},
        sequenceRoutes: {},
        seatClass: {},
        seatPlanList: {},
        allCoachList: {},
        coach: {},
        counterAll: {},
    },
    coachwiseTrip: [],
    singleCompanyData: {},
    coachs: [],
    companies: [],
    owners: [],
    stoppages: [],
    routes: [],
    boardingPlaces: [],
    droppingPlaces: [],
    boardingPlacesForStoppage: [],
    droppingPlacesForStoppage: [],
    counters: [],
    seatClasses: [],
    counterMans: {},
    ships: [],
    trips: [],
    deckMans: [],
    seatPlans: [],
    fares: [],
    agents: [],
    supervisors: {},
    drivers: {},
    allStoppageList: [],
    defaultBoardingPlace: null,
    defaultDroppingPoint: null,
    routesForTrips: [],
    seatPlanById: {},
    counterByRoute: [],
    allRoute: [],
    buttonStatus: true,
};
const getters = {
    getButtonStatus: (state) => state.buttonStatus,
    getSeatPlanById: (state) => state.seatPlanById,
    getCounterByRoute: (state) => state.counterByRoute,
    getRouteSequenceByRouteId: (state) => (id) => state.routesForTrips.filter(item => item._id === id),
    getAllRoute: (state) => state.allRoute,
    getSingleCompanyData: (state) => state.singleCompanyData,
    getCoachs: (state) => state.coachs.docs,
    getCoach: (state) => (id) =>
        state.coachs.docs.filter(function (coach) {
            return coach._id === id;
        }),
    getAllCoachwiseTripList: (state) => state.coachwiseTrip.docs,
    // getTotalCoachwiseTripList:(state)=> state.coachwiseTrip.totalDocs,
    getCoachwiseTrip: (state) => (id) =>
        state.coachwiseTrip.docs.filter(function (trip) {
            return trip._id === id;
        }),
    getTotalCoachs: (state) => state.coachs.totalDocs,
    getFares: (state) => state.fares.docs,
    getTotalFares: (state) => state.fares.totalDocs,
    getFare: (state) => (id) => {
        return state.fares.docs.filter((plan) => plan._id === id);
    },
    getSeatPlans: (state) => state.seatPlans.docs,
    getTotalSeatPlans: (state) => state.seatPlans.totalDocs,
    getSeatPlan: (state) => (id) => {
        return state.seatPlans.docs.filter((plan) => plan._id === id);
    },
    getStoppageListForFilter: (state) => state.filter.stoppage,
    getAllStoppageList: (state) => state.allStoppageList,
    
    getCoachListForFilter: (state) => state.filter.coach,
    
    getRouteListForFilter: (state) => state.filter.route,
    getCompanies: (state) => state.companies.docs,
    getTotalCompanies: (state) => state.companies.totalDocs,
    getCompany: (state) => (id) =>
        state.companies.docs.filter(function (company) {
            return company._id === id;
        }),
    
    getOwners: (state) => state.owners.docs,
    getTotalOwners: (state) => state.owners.totalDocs,
    getOwner: (state) => (id) =>
        state.owners.docs.filter(function (owner) {
            return owner._id === id;
        }),
    
    getAgents: (state) => state.agents.docs,
    getTotalAgents: (state) => state.agents.totalDocs,
    getAdminAgent: (state) => (id) =>
        state.agents.docs.filter(function (owner) {
            return owner._id === id;
        }),
    
    getStoppages: (state) => state.stoppages.docs,
    getTotalStoppages: (state) => state.stoppages.totalDocs,
    getStoppage: (state) => (id) =>
        state.stoppages.docs.filter(function (stoppage) {
            return stoppage._id === id;
        }),
    
    getRoutes: (state) => state.routes.docs,
    getTotalRoutes: (state) => state.routes.totalDocs,
    getRoute: (state) => (id) =>
        state.routes.docs.filter(function (route) {
            return route._id === id;
        }),
    
    getBoardingPlaces: (state) => state.boardingPlaces,
    // getTotalBoardingPlace: (state) => state.boardingPlaces.totalDocs,
    // getBoardingPlace: (state) => (id) => state.boardingPlaces.filter(function (boardingPlace) {
    //     return boardingPlace._id === id;
    // }),
    
    getDroppingPlaces: (state) => state.droppingPlaces,
    // getTotalDroppingPlace: (state) => state.droppingPlaces.totalDocs,
    // getDroppingPlace: (state) => (id) => state.droppingPlaces.docs.filter(function (droppingPlace) {
    //     return droppingPlace._id === id;
    // }),
    
    getBoardingPlacesForStoppage: (state) => state.boardingPlacesForStoppage,
    
    getDroppingPlacesForStoppage: (state) => state.droppingPlacesForStoppage,
    getDefaultBoardingPlace: (state) => state.defaultBoardingPlace,
    getDefaultDroppingPoint: (state) => state.defaultDroppingPoint,
    
    getCounters: (state) => state.counters.docs,
    getTotalCounters: (state) => state.counters.totalDocs,
    getSeatClass: (state) => state.seatClasses.docs,
    getTotalSeatClass: (state) => state.seatClasses.totalDocs,
    getSeatCls: (state) => (id) =>
        state.seatClasses.docs.filter(function (seatClass) {
            return seatClass._id === id;
        }),
    getTotalCounter: (state) => state.counters.totalDocs,
    getCounter: (state) => (id) =>
        state.counters.docs.filter(function (counter) {
            return counter._id === id;
        }),
    getCounterListForFilter: (state) => state.filter.counter,
    getCounterListForFilterAll: (state) => state.filter.counterAll,
    
    getCounterMans: (state) => state.counterMans.docs,
    getTotalCounterMans: (state) => state.counterMans.totalDocs,
    getTotalCounterMan: (state) => state.counterMans.totalDocs,
    getCounterMan: (state) => (id) =>
        state.counterMans.docs.filter(function (counterMan) {
            return counterMan._id === id;
        }),
    
    getSupervisors: (state) => state.supervisors.docs,
    getTotalSupervisors: (state) => state.supervisors.totalDocs,
    getTotalSupervisor: (state) => state.supervisors.totalDocs,
    getSupervisor: (state) => (id) =>
        state.supervisors.docs.filter(function (supervisor) {
            return supervisor._id === id;
        }),
    
    getDrivers: (state) => state.drivers.docs,
    getTotalDrivers: (state) => state.drivers.totalDocs,
    getTotalDriver: (state) => state.drivers.totalDocs,
    getDriver: (state) => (id) =>
        state.drivers.docs.filter(function (driver) {
            return driver._id === id;
        }),
    
    getDeckMans: (state) => state.deckMans.docs,
    getTotalDeckMan: (state) => state.deckMans.totalDocs,
    getDeckMan: (state) => (id) =>
        state.deckMans.docs.filter(function (deckMan) {
            return deckMan._id === id;
        }),
    
    getShips: (state) => state.ships.docs,
    getTotalShip: (state) => state.ships.totalDocs,
    getShip: (state) => (id) =>
        state.ships.docs.filter(function (ship) {
            return ship._id === id;
        }),
    // getSeatPlan: (state) => (id, shipId) => {
    //     return state.ships.docs.find((ship) => ship._id === shipId).seatPlan.find((plan) => plan._id === id);
    // },
    
    getTrips: (state) => state.trips.docs,
    getTotalTrips: (state) => state.trips.totalDocs,
    getTrip: (state) => (id) =>
        state.trips.docs.filter(function (trip) {
            return trip._id === id;
        }),
    getRouteListForFilterAdmin: (state) => state.filter.routes,
    getSeatPlanListForFilterAdmin: (state) => state.filter.seatPlanList,
    getAllCoachListForFilterAdmin: (state) => state.filter.allCoachList,
    getSequenceRouteListForFilterAdmin: (state) => state.filter.sequenceRoutes,
    getAllSeatClassAdmin: (state) => state.filter.seatClass,
};
const actions = {
    async getSeatPlanById({commit}, payload) {
        if (await hasValidToken()) {
            commit("setSeatPlanById", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_SEATPLAN_BY_ID, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setSeatPlanById", response.data.seatPlan);
                        resolve(response.data.seatPlan);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCounterByRoute({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterByRoute", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTER_BY_ROUTE_ID, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setCounterByRoute", response.data.counters);
                        resolve(response.data.counters);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCompaniesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCompanies", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COMPANIES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setCompanies", data.company);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addCompany({commit}, {companyData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_COMPANY, companyData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setCompanies", success.data.company);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateCompany({commit}, {companyData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_COMPANY, companyData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setCompany", success.data.company);
                        
                        //need to clear single company
                        commit("setSingleComapanyData", {})
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getOwnersByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setOwners", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_OWNERS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setOwners", data.owner);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addOwner({commit}, {owner, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_OWNER, owner, {headers: header, params: pagination})
                    .then((success) => {
                        commit("setOwners", success.data.owner);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateOwner({commit}, {owner, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_OWNER, owner, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setOwner", success.data.owner);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAgentsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAgents", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_AGENT, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAgents", data.agent);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addAdminAgent({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_AGENT, payload, {headers: header})
                    .then((success) => {
                        commit("setAgents", success.data.agent);
                        successToast(success.data.message);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateAdminAgent({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_AGENT, payload, {headers: header})
                    .then((success) => {
                        commit("setAgent", success.data.agent);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getStoppagesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppages", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_STOPPAGES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setStoppages", data.stoppage);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getStoppageList({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppageListInFilter", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {};
                            data.stoppage.forEach((stoppage) => {
                                stoppageList[stoppage._id] = stoppage.name;
                            });
                            commit("setStoppageListInFilter", stoppageList);
                            commit("setAllStoppageList", data.stoppage);
                            resolve(data.stoppage);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addStoppage({commit}, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setStoppages", success.data.stoppage);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateStoppage({commit}, {stoppageData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_STOPPAGE, stoppageData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setStoppage", success.data.stoppage);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllRouteForTrips({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRoutesForTrips", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_ROUTES_FOR_TRIPS, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setRoutesForTrips", response.data.routes);
                        resolve(response.data.routes);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getRoutesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRoutes", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_ROUTES, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setRoutes", response.data.route);
                        resolve(response.data.route);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addRoute({commit}, {routeData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_ROUTE, routeData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setRoutes", success.data.route);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateRoute({commit}, {routeData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_ROUTE, routeData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setRoute", success.data.result);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getRouteList({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRouteListInFilter", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_ROUTES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let routeList = {};
                            data.route.forEach((route) => {
                                routeList[route._id] = route.name;
                            });
                            commit("setRouteListInFilter", routeList);
                            resolve(data.route);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllSeatPlan({commit}, payload) {
        if (await hasValidToken()) {
            commit("setSeatPlanAll", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SEATPLAN, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        let seatPlanList = {};
                        response.data.seatPlan.forEach((seatPlan) => {
                            seatPlanList[seatPlan._id] = seatPlan.name;
                        });
                        commit("setSeatPlanAll", seatPlanList);
                        resolve(response.data.seatPlan);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllCoachList({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllCoachList", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_LIST_COACH, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        let allCoachList = {};
                        response.data.coach.forEach((coachList) => {
                            allCoachList[coachList._id] = coachList.name;
                        });
                        commit("setAllCoachList", allCoachList);
                        resolve(response.data.coach);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async allTripFare(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_FARE_ALL_BY_ROUTEID, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
    
    async getBoardingPlacesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setBoardingPlaces", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_BOARDING_PLACES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setBoardingPlaces", data.boardingPlace);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addBoardingPlace({commit}, {bPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_BOARDING_PLACE, bPlace, {headers: header})
                    .then((response) => {
                        commit("setBoardingPlaces", response.data.route.boardingPlace);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateBoardingPlace({commit}, {bPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_BOARDING_PLACE, bPlace, {headers: header})
                    .then((response) => {
                        commit("setBoardingPlace", response.data.route.boardingPlace);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDroppingPlacesByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlaces", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DROPPING_PLACES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDroppingPlaces", data.droppingPoint);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDroppingPlace({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DROPPING_PLACE, payload, {headers: header})
                    .then((response) => {
                        commit("setDroppingPlaces", response.data.route.droppingPoint);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDroppingPlace({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DROPPING_PLACE, payload, {headers: header})
                    .then((response) => {
                        commit("setDroppingPlace", response.data.route.droppingPoint);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getBoardingPlacesForStoppageByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setBoardingPlacesForStoppage", []);
            commit("setDefaultBoardingPlaceForStoppage", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_BOARDING_PLACES_FOR_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setBoardingPlacesForStoppage", data.boardingPlace);
                            commit(
                                "setDefaultBoardingPlaceForStoppage",
                                data.defaultBoardingPlace
                            );
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addBoardingPlaceForStoppage({commit}, {bPlace}) {
        if (await hasValidToken()) {
            commit("setBoardingPlacesForStoppage", []);
            commit("setDefaultBoardingPlaceForStoppage", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_BOARDING_PLACE_FOR_STOPPAGE, bPlace, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setBoardingPlaceForStoppage", response.data.boardingPlace);
                        commit(
                            "setDefaultBoardingPlaceForStoppage",
                            response.data.defaultBoardingPlace
                        );
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateBoardingPlaceForStoppage({commit}, {bPlace}) {
        if (await hasValidToken()) {
            commit("setBoardingPlacesForStoppage", []);
            commit("setDefaultBoardingPlaceForStoppage", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_BOARDING_PLACE_FOR_STOPPAGE, bPlace, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setBoardingPlaceForStoppage", response.data.boardingPlace);
                        commit(
                            "setDefaultBoardingPlaceForStoppage",
                            response.data.defaultBoardingPlace
                        );
                        
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteBoardingPlaceForStoppage(_, {bPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_BOARDING_PLACE_FOR_STOPPAGE, {
                        data: bPlace,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDroppingPlacesForStoppageByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlacesForStoppage", []);
            commit("setDefaultDroppingPlaceForStoppage", null);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DROPPING_PLACES_FOR_STOPPAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDroppingPlacesForStoppage", data.droppingPoint);
                            commit(
                                "setDefaultDroppingPlaceForStoppage",
                                data.defaultDroppingPoint
                            );
                            
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDroppingPlaceForStoppage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlacesForStoppage", []);
            commit("setDefaultDroppingPlaceForStoppage", null);
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DROPPING_PLACE_FOR_STOPPAGE, payload, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setDroppingPlacesForStoppage", response.data.droppingPoint);
                        commit(
                            "setDefaultDroppingPlaceForStoppage",
                            response.data.defaultDroppingPoint
                        );
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDroppingPlaceForStoppage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDroppingPlaceForStoppage", []);
            commit("setDefaultDroppingPlaceForStoppage", null);
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DROPPING_PLACE_FOR_STOPPAGE, payload, {
                        headers: header,
                    })
                    .then((response) => {
                        commit("setDroppingPlaceForStoppage", response.data.droppingPoint);
                        commit(
                            "setDefaultDroppingPlaceForStoppage",
                            response.data.defaultDroppingPoint
                        );
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteDroppingPlaceForStoppage(_, {dPlace}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_DROPPING_PLACE_FOR_STOPPAGE, {
                        data: dPlace,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCountersByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounters", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setCounters", data.counter);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getSeatClassByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setSeatClass", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.SEAT_CLASS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setSeatClass", data.seatClass);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async addSeatClass({commit}, {seatClassData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SEAT_CLASS, seatClassData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setSeatClass", success.data.seatClass);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async updateSeatClass({commit}, {seatClassData}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.ADD_SEAT_CLASS, seatClassData, {headers: header})
                    .then((success) => {
                        commit("setSeatCls", success.data.seatClass);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addCounter({commit}, {counterData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_COUNTER, counterData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("setCounters", success.data.counter);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateCounter({commit}, {counterData}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_COUNTER, counterData, {headers: header})
                    .then((success) => {
                        commit("setCounter", success.data.counter);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCounterList({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilter", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let counterList = {};
                            data.counter.docs.forEach((counter) => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilter", counterList);
                            resolve(data.counter);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getCounterListALL({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilterAll", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERS_ALL, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let counterList = {};
                            data.counter.forEach((counter) => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilterAll", counterList);
                            resolve(data.counter);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCounterListForFilter({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilter", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERS_IN_FILTER, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let counterList = {};
                            data.counter.forEach((counter) => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilter", counterList);
                            resolve(data.counter);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCounterMansByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterMans", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERMANS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setCounterMans", data.counterman);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addCounterMan(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_COUNTERMAN, payload, {headers: header})
                    .then((success) => {
                        // successToast(success.data.message)
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateCounterMan(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_COUNTERMAN, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getSupervisorsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setSupervisors", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SUPERVISORS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setSupervisors", data.supervisor);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addSupervisor(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SUPERVISOR, payload, {headers: header})
                    .then((success) => {
                        // successToast(success.data.message)
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateSupervisor(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_SUPERVISOR, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDriversByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDrivers", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DRIVERS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDrivers", data.driver);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDriver(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DRIVER, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDriver(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DRIVER, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getShipsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setShips", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SHIPS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setShips", data.ship);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getShipsByRoute(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SHIPS_BY_ROUTE, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data.ship);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getShipsByDeck({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SHIPS_BY_DECK, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let shipList = {};
                            data.ship.forEach((ship) => {
                                shipList[ship._id] = ship.name;
                            });
                            commit("setShipListInFilter", shipList);
                            resolve(data.ship);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getShipsInfo(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_SHIP_INFO, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data.ship);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addShip(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SHIP, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateShip(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_SHIP, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addNewSeatPlan({commit}, {seatPlan, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SEAT_PLAN, seatPlan, {
                        headers: header,
                        params: pagination,
                    })
                    .then((response) => {
                        commit("addSeatPlan", response.data.route);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateSeatPlan({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_SEAT_PLAN, payload, {headers: header})
                    .then((response) => {
                        commit("updateSeatPln", response.data.stoppage);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getNewSeatPlan({commit}, pagination) {
        if (await hasValidToken()) {
            commit("addSeatPlan", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_SEAT_PLAN, {headers: header, params: pagination})
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("addSeatPlan", data.seatPlan);
                            resolve(data.message);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addNewSeatFare({commit}, {seatPlan, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SEAT_FARE, seatPlan, {
                        headers: header,
                        params: pagination,
                    })
                    .then((response) => {
                        commit("addSeatFare", response.data.fare);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getNewSeatFare({commit}, payload) {
        if (await hasValidToken()) {
            commit("addSeatFare", []);
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_SEAT_FARE, {headers: header, params: payload})
                    .then((response) => {
                        commit("addSeatFare", response.data.fare);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteSeatFare({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_FARE, {headers: header, params: payload})
                    .then((response) => {
                        commit("addSeatFare", response.data.fareList);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateSeatFare(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_FARE, payload, {
                        headers: header,
                        params: {fare_id: payload.fare_id},
                    })
                    .then((response) => {
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteRouteFare({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.ROURE_FARE_DELETE, {data: payload, headers: header})
                    .then((response) => {
                        commit("addSeatFareNew", response.data.fare);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    // async getSeatPlanListFinal({commit}, pagination) {
    //     if (await hasValidToken()) {
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    
    //         return new Promise((resolve, reject) => {
    //             axios.get(Apis.GET_SEAT_PLAN, {headers: header,params:pagination})
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         commit("addSeatPlan", data.seatPlan);
    //                         resolve(data.seatPlan);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },
    
    // async deleteSeatClass({commit}, payload) {
    //     if (await hasValidToken()) {
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    
    //         return new Promise((resolve, reject) => {
    //             axios.delete(Apis.DELETE_SEAT_CLASS, {data: payload, headers: header})
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         messageExtractor(data, status)
    //                         // commit("removeSeatClass", payload);
    //                         commit("updateShip", data.ship);
    //                         resolve(data.message);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },
    
    // async updateSeatClass({commit}, payload) {
    //     if (await hasValidToken()) {
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    
    //         return new Promise((resolve, reject) => {
    //             axios.put(Apis.UPDATE_SEAT_CLASS, payload, {headers: header})
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         messageExtractor(data, status)
    //                         commit("updateShip", data.ship);
    //                         resolve(data.message);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },
    
    async addSeatRow({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_SEAT_ROW, payload, {headers: header})
                    .then((response) => {
                        commit("updateSeatPln", response.data.seatPlan);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    // async updateSeatRow({commit}, payload) {
    //     if (await hasValidToken()) {
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    
    //         return new Promise((resolve, reject) => {
    //             axios.post(Apis.UPDATE_SEAT_ROW, payload, {headers: header})
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         messageExtractor(data, status)
    //                         commit("updateSeatPln", data);
    //                         resolve(data.message);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },
    
    async addCustomSeatRow({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_CUSTOM_SEAT_ROW, payload, {headers: header})
                    .then((response) => {
                        messageExtractor(response.data, response.status);
                        commit("updateShip", response.data.ship);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async editCustomSeatRow({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_SEAT_ROW, payload, {headers: header})
                    .then((response) => {
                        commit("updateSeatPln", response.data.updatedSeatPlan);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async deleteSeatRow({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .delete(Apis.DELETE_SEAT_ROW, {data: payload, headers: header})
                    .then((response) => {
                        commit("updateSeatPln", response.data.seatPlans);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getTripsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setTrips", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TRIP_DATA, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setTrips", response.data.trip);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllCoachByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("addCoach", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COACH, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("addCoach", response.data.coach);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllCoachwiseTripByPageAdmin({commit}, payload) {
        if (await hasValidToken()) {
            commit("addCoach", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COACHWISE_TRIP_ADMIN, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("addCoachwiseTripList", response.data.coaches);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async blockSeats({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.SEAT_BLOCK, payload, {headers: header})
                    .then((response) => {
                        messageExtractor(response.data, response.status);
                        commit("updateShip", response.data.ship);
                        resolve(successResponse(response));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addTrip({commit}, {tripData, fareType}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_TRIP_DATA, tripData, {
                        headers: header,
                        params: fareType,
                    })
                    .then((success) => {
                        commit("setTrips", success.data.trip);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async updateTrip(_, {tripData, fareType}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_TRIP_DATA, tripData, {
                        headers: header,
                        params: fareType,
                    })
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async copyTrip({commit}, {tripData, fareType}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
        
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.COPY_TRIP_DATA, tripData, {
                        headers: header,
                        params: fareType,
                    })
                    .then((success) => {
                        commit("setTrips", success.data.trip);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async cancelTrip(_, {payload}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.CANCEL_TRIP, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async cancelTripsInBulk(_, params) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            console.log(header)

            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.CANCEL_TRIP_IN_BULK,null, {headers: header, params: params})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addCoach({commit}, {coachData, pagination}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_COACH, coachData, {
                        headers: header,
                        params: pagination,
                    })
                    .then((success) => {
                        commit("addCoach", success.data.coach);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async updateCoach({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_COACH, payload, {headers: header})
                    .then((success) => {
                        commit("updateCoach", success.data.coach);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getDackmanByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setDeckMans", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DECKMANS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setDeckMans", data.deckMan);
                            resolve({success: "success"});
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addDeckMan(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.ADD_DECKMAN, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async addFareModality({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.FARE_MODALITY_ADD, payload, {headers: header})
                    .then((success) => {
                        commit("addSeatFareNew", success.data.fare);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateFareModality({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_FARE_ROUTE, payload, {headers: header})
                    .then((success) => {
                        commit("addSeatFareNew", success.data.fare);
                        successResponse(success);
                        resolve(success.data.fare.fareModality[0].fare);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async updateDeckMan(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .put(Apis.UPDATE_DECKMAN, payload, {headers: header})
                    .then((success) => {
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getShipList({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_LIST_COACH, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let coachList = {};
                            data.coach.forEach((coach) => {
                                coachList[coach._id] = coach.name;
                            });
                            commit("setCoachListInFilter", coachList);
                            resolve(data.coach);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getRouteListFilter({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_ROUTES_FILTER, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data}) => {
                        let routeList = {};
                        data.route.forEach((route) => {
                            routeList[route._id] = route.name;
                        });
                        commit("setAllRoutesInFilter", routeList);
                        commit("setAllRoutes", data.route);
                        resolve(routeList);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getAllSeatClass({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_SEAT_CLASS, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data}) => {
                        let seatClass = {};
                        data.seatClass.forEach((seat) => {
                            seatClass[seat._id] = seat.name;
                        });
                        commit("setAllSeatClass", seatClass);
                        resolve(seatClass);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getSequenceRouteListFilter({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.SEQUENCE_ROUTE_FILTER, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        let routeList = {};
                        response.data.route.routeSequence.forEach((route) => {
                            routeList[route._id] = route.name;
                        });
                        commit("setAllSequenceRoutes", routeList);
                        resolve(routeList);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getSingleCompanyData({commit}, payload) {
        if (await hasValidToken()) {
            
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_SINGLE_COMPANY_DATA, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setSingleComapanyData", response.data.company);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
};
const mutations = {
    setSeatPlanById: (state, data) => (state.seatPlanById = data),
    setCounterByRoute: (state, data) => (state.counterByRoute = data),
    
    setRoutesForTrips: (state, data) => (state.routesForTrips = data),
    setSingleComapanyData: (state, data) => (state.singleCompanyData = data),
    setStoppageListInFilter: (state, stoppageList) =>
        (state.filter.stoppage = stoppageList),
    setAllStoppageList: (state, stoppageList) =>
        (state.allStoppageList = stoppageList),
    setCoachListInFilter: (state, coachList) => (state.filter.coach = coachList),
    setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
    setAllCoachList: (state, coachList) =>
        (state.filter.allCoachList = coachList),
    setSeatPlanAll: (state, seatPlanList) =>
        (state.filter.seatPlanList = seatPlanList),
    setCounterListInFilter: (state, counterList) =>
        (state.filter.counter = counterList),
    setCounterListInFilterAll: (state, counterList) =>
        (state.filter.counterAll = counterList),
    setCompanies: (state, companies) => (state.companies = companies),
    setCompany: (state, company) =>
        
        state.companies.docs.filter((element, index) => {
            if (element._id === company._id) {
                state.companies.docs.splice(index, 1, company);
            }
        }),
    setOwners: (state, owners) => (state.owners = owners),
    setOwner: (state, owner) =>
        state.owners.docs.filter((element, index) => {
            if (element._id === owner._id) {
                state.owners.docs.splice(index, 1, owner);
            }
        }),
    setAgents: (state, agents) => (state.agents = agents),
    setAgent: (state, agent) =>
        state.agents.docs.filter((element, index) => {
            if (element._id === agent._id) {
                state.agents.docs.splice(index, 1, agent);
            }
        }),
    setStoppages: (state, stoppages) => (state.stoppages = stoppages),
    setStoppage: (state, stoppage) =>
        state.stoppages.docs.filter((element, index) => {
            if (element._id === stoppage._id) {
                state.stoppages.docs.splice(index, 1, stoppage);
            }
        }),
    setRoutes: (state, routes) => (state.routes = routes),
    setRoute: (state, route) =>
        state.routes.docs.filter((element, index) => {
            if (element._id === route._id) {
                state.routes.docs.splice(index, 1, route);
            }
        }),
    setBoardingPlaces: (state, boardingPlaces) =>
        (state.boardingPlaces = boardingPlaces),
    setBoardingPlace: (state, boardingPlace) =>
        (state.boardingPlaces = boardingPlace),
    setDroppingPlaces: (state, droppingPlaces) =>
        (state.droppingPlaces = droppingPlaces),
    setDroppingPlace: (state, droppingPlace) =>
        (state.droppingPlaces = droppingPlace),
    
    setBoardingPlacesForStoppage: (state, boardingPlaces) => {
        state.boardingPlacesForStoppage = boardingPlaces;
    },
    setDefaultBoardingPlaceForStoppage: (state, defaultBoarding) =>
        (state.defaultBoardingPlace = defaultBoarding),
    setBoardingPlaceForStoppage: (state, boardingPlace) =>
        (state.boardingPlacesForStoppage = boardingPlace),
    setDroppingPlacesForStoppage: (state, droppingPlaces) =>
        (state.droppingPlacesForStoppage = droppingPlaces),
    setDefaultDroppingPlaceForStoppage: (state, defaultDropping) =>
        (state.defaultDroppingPoint = defaultDropping),
    setDroppingPlaceForStoppage: (state, droppingPlace) =>
        (state.droppingPlacesForStoppage = droppingPlace),
    
    setCounters: (state, counters) => (state.counters = counters),
    setSeatClass: (state, seatClass) => (state.seatClasses = seatClass),
    setSeatCls: (state, seatClass) =>
        state.seatClasses.docs.filter((element, index) => {
            if (element._id === seatClass._id) {
                state.seatClasses.docs.splice(index, 1, seatClass);
            }
        }),
    setCounter: (state, counter) =>
        state.counters.docs.filter((element, index) => {
            if (element._id === counter._id) {
                state.counters.docs.splice(index, 1, counter);
            }
        }),
    addSeatPlan: (state, seatPlan) => {
        state.seatPlans = seatPlan;
    },
    updateSeatPln: (state, seatPlan) =>
        state.seatPlans.docs.filter((element, index) => {
            if (element._id === seatPlan._id) {
                state.seatPlans.docs.splice(index, 1, seatPlan);
            }
        }),
    setCounterMans: (state, counterMans) => (state.counterMans = counterMans),
    setSupervisors: (state, supervisor) => (state.supervisors = supervisor),
    setDrivers: (state, driver) => (state.drivers = driver),
    setShips: (state, ships) => (state.ships = ships),
    updateShip: (state, updatedShip) => {
        const index = state.ships.docs.findIndex(
            (ship) => ship._id === updatedShip._id
        );
        if (index !== -1) {
            state.ships.docs.splice(index, 1, updatedShip);
        }
    },
    setTrips: (state, trips) => (state.trips = trips),
    setTrip: (state, trip) =>
        state.trips.docs.filter((element, index) => {
            if (element._id === trip._id) {
                state.trips.docs.splice(index, 1, trip);
            }
        }),
    removeTrip: (state, tripId) =>
        state.trips.docs.filter((element, index) => {
            if (element._id === tripId) {
                state.trips.docs.splice(index, 1);
            }
        }),
    setDeckMans: (state, deckMans) => (state.deckMans = deckMans),
    setAllRoutesInFilter: (state, routeList) => (state.filter.routes = routeList),
    setAllRoutes: (state, allRoute) => (state.allRoute = allRoute),
    setAllSeatClass: (state, seatClass) => (state.filter.seatClass = seatClass),
    setAllSequenceRoutes: (state, sequenceRouteList) => {
        state.filter.sequenceRoutes = sequenceRouteList;
    },
    addSeatFare: (state, fare) => (state.fares = fare),
    addCoach: (state, coachs) => (state.coachs = coachs),
    addCoachwiseTripList: (state, coachwiseTrip) =>
        (state.coachwiseTrip = coachwiseTrip),
    updateCoach: (state, coach) =>
        state.coachs.docs.filter((element, index) => {
            if (element._id === coach._id) {
                state.coachs.docs.splice(index, 1, coach);
            }
        }),
    addSeatFareNew: (state, neSeatFare) =>
        state.fares.docs.filter((element, index) => {
            if (element._id === neSeatFare._id) {
                state.fares.docs.splice(index, 1, neSeatFare);
            }
        }),
    setButtonStatus: (state, payload) => {
        state.buttonStatus = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
