<template>
  <div>
    <div
      class="text-center py-1 text-white nav-style fw-bold text-uppercase mb-5"
    >
      <a href="/counterman/dashboard" class="text-white text-decoration-none">{{ $store.getters.userCompanyData.name }}</a>
    </div>
    <CountermanNav />
    <router-view></router-view>
  </div>
</template>

<script>
import CountermanNav from "../../components/layouts/Nav/CountermanNav";
export default {
  name: "Counterman",
  components: { CountermanNav },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
}
body {
  position: relative !important;
}
</style>