<template>
  <label v-if="hideLabel === 'inactive'" :for="inputId" class="label_font">{{
      t(inputLabel, {}, {locale: $store.getters.getLang})
    }}</label>
  <select
      :id="inputId"
      v-model="input"
      :disabled="disableSelect"
      aria-label="example"
      class="form-select form-select-sm py-2 px-3 w-100 mt-1"
      :class="bottomMargin ? bottomMargin : 'mb-4'"
  >
    <option v-if="inputDefaultOption" :disabled="defaultOptionDisable" :value="inputDefaultValue">
      {{ t(inputDefaultOption, {}, {locale: $store.getters.getLang}) }}
    </option>
    <option v-for="(type, index) in inputDropdown" :key="index" :value="index">
      {{ t(type, {}, {locale: $store.getters.getLang}) }}
    </option>
  </select>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "Dropdown",
  props: {
    modelValue: [String, Number],
    dropdown: Object,
    defaultOption: String,
    defaultValue: String,
    label: String,
    id: String,
    hideLabel: String,
    trip: String,
    defaultDisable: Boolean,
    marginBottom: String,
  },
  setup(props, {emit}) {
    let disableSelect = ref(false);
    const inputDropdown = ref(props.dropdown);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    const {t, locale} = useI18n();
    const defaultOptionDisable = ref(props.defaultDisable);
    const bottomMargin = ref(props.marginBottom);
    if (props.trip == "active") {
      disableSelect.value = true;
    } else {
      disableSelect.value = false;
    }
    watch(
        () => input.value,
        () => {
          emit("update:modelValue", input.value);
        }
    );
    watch(
        () => props.dropdown,
        (value) => {
          inputDropdown.value = value;
        }
    );
    watch(
        () => props.modelValue,
        (value) => {
          input.value = value;
        }
    );
    return {
      t,
      locale,
      inputDropdown,
      disableSelect,
      inputDefaultOption,
      inputDefaultValue,
      inputLabel,
      inputId,
      input,
      defaultOptionDisable,
      bottomMargin,
    };
  },
};
</script>

<style scoped></style>
