const API_URL = process.env.VUE_APP_API;


const GET_ALL_COUNTERMAN_BY_COUNTER = API_URL + "/api/v3/counterman/public/countermen/alL";
const SEARCH_WITH_PNR_OR_PHONE = API_URL + "/api/v3/counterman/public/global-search";
const GET_TICKETS_INFO = API_URL + "/api/v3/counterman/dashboard/get-ticket-info";
const GET_PRE_TRIP_COPY = API_URL + "/api/v3/counterman/trip/pre-trip-copy";
const GET_ALL_DASHBOARD = API_URL + "/api/v3/counterman/public/dashboard/all";
const GET_TRIP_LIST_BY_PAGE = API_URL + "/api/v3/counterman/dashboard/list";
const GET_TICKET_SHORT_INFO = API_URL + "/api/v3/counterman/dashboard/ticket-short-info";
const GET_IN_PROGRESS_TICKET_SHORT_INFO = API_URL + "/api/v3/counterman/dashboard/get-on-progress-seat";
const COPY_COUNTERMAN_TRIP_DATA = API_URL + "/api/v3/counterman/trip/trip-copy";
const GET_COUNTERMAN_ALL_ROUTES_FOR_TRIPS = API_URL + "/api/v3/counterman/public/all-route-sequence";
const GET_COUNTERMAN_FARE_ALL_BY_ROUTEID = API_URL + "/api/v3/counterman/public/fare/all";
const RESERVED_TICKET_PAYMENT = API_URL + "/api/v3/counterman/dashboard/pay-reserve-ticket";
const GET_COUNTERMAN_ALL_ROUTES = API_URL + "/api/v3/counterman/public/route/all";
const GET_COUNTERMAN_ALL_COUCH = API_URL + "/api/v3/counterman/public/coach/all";
const GET_COUNTERMAN_ALL_SEAT_PLAN = API_URL + "/api/V3/counterman/public/seatplan/all";
const GET_DT_TICKET_COUNT = API_URL + "/api/v3/admin/company/ticket/dt-ticket-count";
const GET_DT_TICKET_COUNT_OWNER = API_URL + "/api/v3/owner/services/ticket/dt-ticket-count";
const GET_TRIP_BY_ID = API_URL + "/api/v3/counterman/trip/find-by-id";
const ADD_ON_PROGRESS_SEAT = API_URL + "/api/v3/counterman/dashboard/add-on-progress-seat";
const REMOVE_ON_PROGRESS_SEAT = API_URL + "/api/v3/counterman/dashboard/remove-on-progress-seat";

// qr related apis
const GET_ADMIN_DT_AND_QR_TICKET_COUNT = API_URL + "/api/v3/admin/company/ticket/ticket-count";
const GET_OWNER_DT_AND_QR_TICKET_COUNT = API_URL + "/api/v3/owner/services/ticket/ticket-count";



export default{
    GET_ALL_COUNTERMAN_BY_COUNTER,
    SEARCH_WITH_PNR_OR_PHONE,
    GET_TICKETS_INFO,
    GET_PRE_TRIP_COPY,
    GET_ALL_DASHBOARD,
    GET_TRIP_LIST_BY_PAGE,
    GET_TICKET_SHORT_INFO,
    GET_IN_PROGRESS_TICKET_SHORT_INFO,
    COPY_COUNTERMAN_TRIP_DATA,
    GET_COUNTERMAN_ALL_ROUTES_FOR_TRIPS,
    GET_COUNTERMAN_FARE_ALL_BY_ROUTEID,
    RESERVED_TICKET_PAYMENT,
    GET_COUNTERMAN_ALL_ROUTES,
    GET_COUNTERMAN_ALL_COUCH,
    GET_COUNTERMAN_ALL_SEAT_PLAN,
    GET_DT_TICKET_COUNT,
    GET_DT_TICKET_COUNT_OWNER,
    GET_TRIP_BY_ID,
    ADD_ON_PROGRESS_SEAT,
    REMOVE_ON_PROGRESS_SEAT,
    GET_ADMIN_DT_AND_QR_TICKET_COUNT,
    GET_OWNER_DT_AND_QR_TICKET_COUNT
}