<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Reservation_List_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
  </div>
  <div class="card h-100 mt-4">
    <div class="card-header text-center bg-white">
<!--      <h5 class="mb-0 text-uppercase">Header</h5>-->
    </div>
    <div class="card-body mt-0 py-0">
      <table-simple id="exportable_data">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Reservation Time</td>
            <td class="font_detail">PNR</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Seat Number</td>
            <td class="font_detail">Paid Amount</td>
            <td class="font_detail">Reserved By</td>
            <td class="font_detail">Confirmed By</td>
            <td class="font_detail">Cancelled by</td>
            <td class="font_detail">Ticket Info</td>
            <td class="font_detail">Reservation Status</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in reservationDetails" :key="index">
            <td class="font_detail">
              {{ index + 1 }}
            </td>
            <td class="font_detail">{{ dateTimeFormat(item?.reserveDateTime, 6) }}</td>
            <td class="font_detail">
              <span class="text-white px-2 py-1 rounded-pill bg-success">{{ item?.ticket?.pnrCode }}</span>
            </td>
            <td class="font_detail">{{ item?.ticket.passengerName }}</td>
            <td class="font_detail">{{ item?.ticket.passengerPhone }}</td>
            <td class="font_detail">{{ item?.ticket?.seatNumbers.join(', ') }}</td>
            <td class="font_detail">{{ item?.paidAmount }}</td>
            <td class="font_detail">{{ item?.reservedBy?.name }}</td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>
            <td class="font_detail">{{ item?.cancelledBy?.name }}</td>
            <td class="font_detail">
              <span> {{ item.reservationStatus === 'sold' ? 'Ticket Confirmed' : item.reservationStatus === 'pending' ? 'Ticket Reserved' : item?.ticket?.cancellationCause }} </span>
              <br/>
              <small class="text-muted">{{ dateFormat(item.updatedAt, 6) }}</small>
             </td>
            <td class="font_detail">
              <span class="text-white px-2 py-1 rounded-pill" :class="item?.reservationStatus === 'cancelled' ? 'bg-danger' : item.reservationStatus === 'sold'? 'bg-success' : item?.reservationStatus === 'pending' ? 'bg-secondary' : 'bg-warning'">
                {{ item?.reservationStatus.replace(/_/g, " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}
              </span>
            </td>
          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>

<script>
import { inject, ref, onBeforeMount} from "vue";
  import store from "@/store";
  import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import moment from "moment/moment";

  export default {
    name: "TripReservationList",
    emits: ["backToCoachWiseReport"],
    props: {
      selectedCompanyId: String,
      selectedTripId: String
    },
    setup(props,{ emit }){
      const showLoader = inject("showLoader");
      const hideLoader = inject("hideLoader");
      const reservationDetails = ref([]);
      const exportExcel = inject("exportExcel");
      const getAdminReservationTripReportDetails = () => {
        showLoader()
        store.dispatch("getAdminReservationTripReportDetails", {companyId: props.selectedCompanyId, tripId:  props.selectedTripId})
        .then(res => {
          reservationDetails.value = res.reservationHistoryDetails
          hideLoader()
        })
        .catch(err => {
          console.log(err)
          hideLoader()
        })
      }

      const dateFormat = (date, offSet = 0) => {
        return moment(String(date))
            .utcOffset(offSet, false)
            .format("DD-MMM-yyyy hh:mm:ss A");
      };

      onBeforeMount(() => {
        getAdminReservationTripReportDetails();
      })

      const goBack = () => {
        emit("backToCoachWiseReport", true);
      };
      return {
        goBack,
        dateTimeFormat,
        reservationDetails,
        exportExcel,
        dateFormat
      }
    }
  }
</script>