// import validationMessage from "@/modules/ValidationMessage"
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {successToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const {successResponse, errorResponse} = apiResponse();

const state = {
    filter: {
        stoppage: {},
        ship: {},
        coach: {},
        route: {},
        counter: {},
        drivers: {},
        supervisors: {},
        seatPlanList: {},
        droppingPoint: {},
        boardingPlace: {},
        defaultBoardingPlace: {},
        defaultDroppingPoint: {},
    },
    trip: {},
    trips: [],
    routeSequence: [],
    boardingPlace: [],
    droppingPoint: [],
    tickets: {},
    ticketByTrip: [],
    blockedSeatsTicketsCounterman: [],
    bookedSeatList: [],
    selectedSeatsBookedDatabase: [],
    seatDetails: {
        selectedSeat: [],
        seatFare: [],
        farePerSeat: [],
        ticketDetails: []
    },
    ticketPurchasedDetails: {
        purchasedTicket: {},
        purchaseStatus: false
    },
    resetStatus: false,
    seatResetStatus: false,
    searchTickets: [],
    maleFemaleCounter: {},
    selectedSeatFareTotal: 0,
    boardingTripDateTime: "",
    coachs: [],
    routesForTrips: [],
    allRoutes: [],
    tripById: {},
    ticketLayout: [
        {
            "width": 209.2,
            "topLayer": 100,
            "properties": [
                {
                    "name": "",
                    "example1": "dhaka",
                    "nameValue1": "fromStoppage",
                    "example2": "chittagong stoppage",
                    "nameValue2": "toStoppage",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 66,
                    "separator": "-"
                },
                {
                    "name": "Name",
                    "example1": "Arif Ibrahim",
                    "nameValue1": "passengerName",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": ""
                },
                {
                    "name": "Mobile",
                    "example1": "01717923954",
                    "nameValue1": "passengerPhone",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": ""
                },
                {
                    "name": "PNR",
                    "example1": "XDMTGV98M2AH1",
                    "nameValue1": "pnrCode",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 500,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": ""
                },
                {
                    "name": "Seats No",
                    "example1": "[C3, A4]",
                    "nameValue1": "seatNumbers",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": ""
                },
                {
                    "name": "Fare",
                    "example1": 500,
                    "nameValue1": "totalAmount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Discount",
                    "example1": 0,
                    "nameValue1": "discount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Payable",
                    "example1": 500,
                    "nameValue1": "payable",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Journey",
                    "example1": "19-Sep-2022 11:09:00 AM",
                    "nameValue1": "boardingTripDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":"
                },
                {
                    "name": "Boarding",
                    "example1": "fakirapool",
                    "nameValue1": "boardingPlace",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Dropping",
                    "example1": "moheshkhali",
                    "nameValue1": "droppingPoint",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Trip No",
                    "example1": "unique",
                    "nameValue1": "coach",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Issued By",
                    "example1": "counterman dhaka",
                    "nameValue1": "issuedBy",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":"
                },
                {
                    "name": "Issued Time",
                    "example1": "19-Sep-2022 06:24:32 AM",
                    "nameValue1": "ticketDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":"
                }
            ]
        },
        {
            "width": 199.2,
            "topLayer": 117,
            "properties": [
                {
                    "name": "",
                    "nameValue1": "",
                    "nameValue2": "",
                    "left": 120,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 0,
                    "nameValueWidth": 160,
                    "separator": ":",
                    "example1": "",
                    "top": ""
                },
                {
                    "name": "Name",
                    "example1": "Arif Ibrahim",
                    "nameValue1": "passengerName",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": -14
                },
                {
                    "name": "Mobile",
                    "example1": "01717923954",
                    "nameValue1": "passengerPhone",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "PNR",
                    "example1": "XDMTGV98M2AH1",
                    "nameValue1": "pnrCode",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 500,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Seats No",
                    "example1": [
                        "C2"
                    ],
                    "nameValue1": "seatNumbers",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Fare",
                    "example1": 500,
                    "nameValue1": "totalAmount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Discount",
                    "example1": "0",
                    "nameValue1": "discount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Payable",
                    "example1": 500,
                    "nameValue1": "payable",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                }
            ]
        },
        {
            "width": 199.2,
            "topLayer": 117,
            "properties": [
                {
                    "name": "Journey Date",
                    "example1": "19-Sep-2022 11:09:00 AM",
                    "nameValue1": "boardingTripDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":",
                    "top": -15
                },
                {
                    "name": "Boarding",
                    "example1": "fakirapool",
                    "nameValue1": "boardingPlace",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Dropping",
                    "example1": "moheshkhali",
                    "nameValue1": "droppingPoint",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Trip No",
                    "example1": "unique",
                    "nameValue1": "coach",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Issued By",
                    "example1": "counterman dhaka",
                    "nameValue1": "issuedBy",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 119,
                    "separator": ":",
                    "top": 3
                },
                {
                    "name": "Issued Time",
                    "example1": "19-Sep-2022 06:24:32 AM",
                    "nameValue1": "ticketDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":",
                    "top": 3
                }
            ]
        },
        {
            "width": 189.2,
            "topLayer": 100,
            "properties": [
                {
                    "name": "",
                    "example1": "dhaka",
                    "nameValue1": "fromStoppage",
                    "example2": "chittagong stoppage",
                    "nameValue2": "toStoppage",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 66,
                    "separator": "-"
                },
                {
                    "name": "Name",
                    "example1": "Arif Ibrahim",
                    "nameValue1": "passengerName",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Mobile",
                    "example1": "01717923954",
                    "nameValue1": "passengerPhone",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "PNR",
                    "example1": "XDMTGV98M2AH1",
                    "nameValue1": "pnrCode",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 500,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Seats No",
                    "example1": [
                        "C2"
                    ],
                    "nameValue1": "seatNumbers",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Fare",
                    "example1": 500,
                    "nameValue1": "totalAmount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Discount",
                    "example1": 0,
                    "nameValue1": "discount",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Payable",
                    "example1": 500,
                    "nameValue1": "payable",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Journey",
                    "example1": "19-Sep-2022 11:09:00 AM",
                    "nameValue1": "boardingTripDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 700,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":"
                },
                {
                    "name": "Boarding",
                    "example1": "fakirapool",
                    "nameValue1": "boardingPlace",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Dropping",
                    "example1": "moheshkhali",
                    "nameValue1": "droppingPoint",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Trip No",
                    "example1": "unique",
                    "nameValue1": "coach",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Issued By",
                    "example1": "counterman dhaka",
                    "nameValue1": "issuedBy",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 110,
                    "separator": ":"
                },
                {
                    "name": "Issued Time",
                    "example1": "19-Sep-2022 06:24:32 AM",
                    "nameValue1": "ticketDateTime",
                    "nameValue2": "",
                    "left": 10,
                    "fontWeight": 0,
                    "fontSize": 11,
                    "nameWidth": 53,
                    "nameValueWidth": 87,
                    "separator": ":"
                }
            ]
        }
    ],
    
};
const getters = {
    getTicketLayoutCounterman: (state) => state.ticketLayout,
    getCountermanTripById: (state) => state.tripById,
    getCountermanSeatPlanListForFilter: (state) => state.filter.seatPlanList,
    getCountermanAllRotes: (state) => state.allRoutes,
    getCountermanRouteSequenceByRouteId: (state) => (id) => state.routesForTrips.filter(item => item._id === id),
    getCountermanCouchs: (state) => state.coachs,
    getAllCoachListForFilterCounterman: (state) => state.filter.coach,
    getCountermanCoachListForFilter: (state) => state.filter.ship,
    getBoardingTripDateTime: (state) => state.boardingTripDateTime,
    getMaleFemaleCounter: (state) => state.maleFemaleCounter,
    getDefaultBoardingPlaceBasedOnStoppage: (state) => state.filter.defaultBoardingPlace,
    getDefaultDroppingPointBasedOnStoppage: (state) => state.filter.defaultDroppingPoint,
    getBoardingPlaceBasedOnStoppage: (state) => state.filter.boardingPlace,
    getDroppingPointBasedOnStoppage: (state) => state.filter.droppingPoint,
    getBlockedSeatsTicketsCounterman: (state) => state.blockedSeatsTicketsCounterman,
    getTicketByTripCounterman: (state) => state.ticketByTrip,
    getAllSupervisors: (state) => state.filter.supervisors,
    getAllDrivers: (state) => state.filter.drivers,

    getCountermanStoppageListForFilter: (state) => state.filter.stoppage,
    getCountermanRouteListForFilter: (state) => state.filter.route,

    getTicketsHistoryCounter: (state) => state.tickets.docs,
    getTotalTicketsHistoyCounter: (state) => state.tickets.totalDocs,
    getTripsForTicketing: (state) => state.trips.docs,
    getCountermanTrip: (state) => (id) =>
        state.trips.docs.filter(function (trip) {
            return trip._id === id;
        }),
    getTotalTripsForTicketing: (state) => state.trips.totalDocs,
    getTripForTicketing: (state) => state.trip,
    getRouteSequenceFilter: (state) => {
        let routeSequenceList = {}
        state.routeSequence.forEach(routeSequence => {
            routeSequenceList[routeSequence._id] = routeSequence.name;
        });
        return routeSequenceList
    },
    getBoardingPlaceFilter: (state) => {
        let boardingPlaceList = {}
        state.boardingPlace.forEach(boardingPlace => {
            boardingPlaceList[boardingPlace] = boardingPlace;
        });
        return boardingPlaceList
    },
    getDroppingPointFilter: (state) => {
        let droppingPointList = {}
        state.droppingPoint.forEach(droppingPoint => {
            droppingPointList[droppingPoint] = droppingPoint;
        });
        return droppingPointList
    },
    getSelectedSeat: (state) => state.seatDetails.selectedSeat,
    getSeatFare: (state) => state.seatDetails.seatFare,
    getTicketDetails: (state) => state.seatDetails.ticketDetails,
    getBookedSeatList: (state) => state.bookedSeatList,
    getSeatDetails: (state) => state.seatDetails,
    getTicketPurchasedDetails: (state) => state.ticketPurchasedDetails,
    getSelectedSeatsBookedDatabase: (state) => state.selectedSeatsBookedDatabase,
    getResetStatus: (state) => state.resetStatus,
    getSeatResetStatus: (state) => state.seatResetStatus,
    getSearchTickets: (state) => state.searchTickets,
    getSearchSingleTicket: (state) => (selectedTicket) => state.searchTickets.filter(ticket => ticket._id === selectedTicket),
    getSelectedSeatFareTotal: (state) => state.selectedSeatFareTotal,
};
const actions = {
    async getTicketLayoutCounterman({commit}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TICKET_LAYOUT_COUNTERMAN, {
                        headers: header,
                    })
                    .then((response) => {
                        if(response.status === 200 && response.data?.ticketLayout?.layers.length > 0){
                            commit("setTicketLayout", response.data.ticketLayout.layers)
                            resolve();
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
    async searchWithPassengerPhone(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE, {headers: header, params: payload})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getTripById({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setTripById", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TRIP_BY_ID, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        
                        commit("setTripById", response.data.trip);
                        resolve(response.data.trip);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCountermanAllSeatPlan({ commit }, payload) {
        if (await hasValidToken()) {
            commit("setSeatPlanAll", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTERMAN_ALL_SEAT_PLAN, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        let seatPlanList = {};
                        response.data.seatPlan.forEach((seatPlan) => {
                            seatPlanList[seatPlan._id] = seatPlan.name;
                        });
                        commit("setSeatPlanAll", seatPlanList);
                        resolve(response.data.seatPlan);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getCountermanAllCouch({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllRoutes", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTERMAN_ALL_COUCH, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        commit("setAllCouch", data.couch);
                        if (status === 200) {
                            let coachList = {}
                            data.coach.forEach(coach => {
                                coachList[coach._id] = coach.name;
                            });
                            commit("setCouchListInFilter", coachList)
                            resolve(data.coach);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    // async getRouteListForCounterman({commit}, payload) {
    //     if (await hasValidToken()) {
    //         commit("setRouteListInFilter", {});
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    //
    //         return new Promise((resolve, reject) => {
    //             axios.get(Apis.GET_ALL_COUNTERMAN_ROUTES, {
    //                     params: payload,
    //                     headers: header
    //                 })
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         let routeList = {}
    //                         data.route.forEach(route => {
    //                             routeList[route._id] = route.name;
    //                         });
    //                         commit("setRouteListInFilter", routeList)
    //                         resolve(data.route);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },
    
    async getAllRoutes({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAllRoutes", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTERMAN_ALL_ROUTES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        commit("setAllRoutes", data.route);
                        if (status === 200) {
                            let routeList = {}
                            data.route.forEach(route => {
                                routeList[route._id] = route.name;
                            });
                            commit("setRouteListInFilter", routeList)
                            resolve(data.route);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getCountermanAllTripFare(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTERMAN_FARE_ALL_BY_ROUTEID, {
                        headers: header,
                        params: payload,
                    })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    },
    async getCountermanAllRouteForTrips({commit}, payload) {
        if (await hasValidToken()) {
            commit("setRoutesForTrips", []);
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_COUNTERMAN_ALL_ROUTES_FOR_TRIPS, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit("setRoutesForTrips", response.data.routes);
                        resolve(response.data.routes);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async copyCountermanTrip({commit}, {tripData, fareType}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            
            return new Promise((resolve, reject) => {
                axios
                    .post(Apis.COPY_COUNTERMAN_TRIP_DATA, tripData, {
                        headers: header,
                        params: fareType,
                    })
                    .then((success) => {
                        commit("setTrips", success.data.trip);
                        resolve(successResponse(success));
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getRefundPayment(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REFUND_RESERVED_TICKET_PAYMENT, payload, {headers: header})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async payReservedTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.RESERVED_TICKET_PAYMENT, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async cancelReservationTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.RESERVATION_TICKET_CANCEL_COUNTERMAN, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message);
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getTicketByTrip({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken(),
                // 'x-no-compression': 'true'
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.TICKET_BY_TRIP, {headers: header, params: payload})
                    .then((response) => {
                        commit('setMaleFemaleCount', response.data)
                        commit("setTrip", response.data.trip);
                        commit("setRouteSequence", response.data.trip.route.routeSequence);
                        commit('setTicketByTrip', response.data.tickets)
                        commit("setBlockedSeatsTicketsCounterman", {tickets: response.data.tickets})
                        commit("setBoardingTripDateTime", response.data.trip.boardingTripDateTime)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async searchWithPnrOrPhone({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.SEARCH_WITH_PNR_OR_PHONE,{headers: header, params: payload})
                    .then((response) => {
                        commit('setSearchTickets', response.data.tickets)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    // async getStoppageListForCounterman({commit}, payload) {
    //     if (await hasValidToken()) {
    //         commit("setStoppageListInFilter", {});
    //         const header = {
    //             'Authorization': 'Bearer ' + getToken()
    //         };
    //
    //         return new Promise((resolve, reject) => {
    //             axios.get(Apis.GET_ALL_COUNTERMAN_STOPPAGES, {
    //                 params: payload,
    //                 headers: header
    //             })
    //                 .then(({data, status}) => {
    //                     if (status === 200) {
    //                         let stoppageList = {}
    //                         let boardingPlace = {}
    //                         let droppingPoint = {}
    //                         data.stoppage.forEach(stoppage => {
    //                             stoppageList[stoppage._id] = stoppage.name;
    //                             boardingPlace[stoppage._id] = stoppage.boardingPlace
    //                             droppingPoint[stoppage._id] = stoppage.droppingPoint
    //                         });
    //                         commit("setStoppageListInFilter", stoppageList)
    //                         commit("setBoardingPlaceFilter",boardingPlace)
    //                         commit("setDroppingPointFilter",droppingPoint)
    //                         resolve(data.stoppage);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     reject(errorResponse(error))
    //                 });
    //         });
    //     }
    // },

    async getStoppageListForCounterman({commit}, payload) {
        if (await hasValidToken()) {
            commit("setStoppageListInFilter", {});
            const header = {
                Authorization: "Bearer " + getToken(),
            };

            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERMAN_STOPPAGES, {
                        params: payload,
                        headers: header,
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let stoppageList = {};
                            let boardingPlace = {};
                            let droppingPoint = {};
                            let defaultBoardingPlace = {};
                            let defaultDroppingPoint = {};
                            data.stoppage.forEach((stoppage) => {
                                stoppageList[stoppage._id] = stoppage.name;
                                boardingPlace[stoppage._id] = stoppage.boardingPlace;
                                droppingPoint[stoppage._id] = stoppage.droppingPoint;
                                defaultBoardingPlace[stoppage._id] = stoppage.defaultBoardingPlace;
                                defaultDroppingPoint[stoppage._id] = stoppage.defaultDroppingPoint;
                            });
                            commit("setStoppageListInFilter", stoppageList);
                            commit("setBoardingPlaceFilter", boardingPlace);
                            commit("setDroppingPointFilter", droppingPoint);
                            commit("setDefaultBoardingPlaceFilter", defaultBoardingPlace);
                            commit("setDefaultDroppingPointFilter", defaultDroppingPoint);
                            resolve(data.stoppage);
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    
    async getCounterListForCounterman({commit}, payload) {
        if (await hasValidToken()) {
            commit("setCounterListInFilter", {});
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_COUNTERS, {
                        params: payload,
                        headers: header
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let counterList = {}
                            data.counter.forEach(counter => {
                                counterList[counter._id] = counter.name;
                            });
                            commit("setCounterListInFilter", counterList)
                            resolve(data.counter);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getShipListForCounterman({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_SHIPS, {
                        params: payload,
                        headers: header
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            let coachList = {}
                            data.coach.forEach(coach => {
                                coachList[coach._id] = coach.name;
                            });
                            commit("setShipListInFilter", coachList)
                            commit("setCoachs", data.coach)
                            resolve(data.coach);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getTicketsHistory({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.TICKETS_HISTORY_COUNTER, {
                        params: payload,
                        headers: header
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTicketsHistory", data.ticket)
                            resolve(data.ticket)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async ticketDelete(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_CANCEL, payload, {headers: header})
                    .then((response) => {
                        successToast('Ticket Cancelled Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async tripPass(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TRIP_PASS, payload, {headers: header})
                    .then((response) => {
                        successToast('Ticket Passed Successfully')
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async pnrTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.PNR_TICKET, {headers: header, params: payload})
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async updateTripCounterman(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.UPDATE_TRIP, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },


    async extraSeatPurchase(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.EXTRA_TICKET_SEATS, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async extraGoodsPurchase(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.EXTRA_TICKET_GOODS, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async confirmPassengerTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PURCHASE, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async reserveTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_RESERVE, payload, {headers: header})
                    .then((response) => {
                        successToast(response.data.message)
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getActiveTripsForTicketing({commit}, payload) {
        if (await hasValidToken()) {
            commit("setTrips", []);
            const header = {
                'Authorization': 'Bearer ' + getToken(),
                // 'x-no-compression': 'true'
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TRIPS_FOR_TICKETING, {
                        params: payload,
                        headers: header
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTrips", data.trip);
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllDriver({commit}) {
        if (await hasValidToken()) {
            commit("setDrivers", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ALL_DRIVER, {
                        headers: header
                    })
                    .then(({data}) => {
                        let driverList = {}
                        data.drivers.forEach(driver => {
                            driverList[driver._id] = driver.name;
                        });
                        commit("setDrivers", driverList);
                        resolve({'success': 'success'});

                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async getAllSupervisor({commit}) {
        if (await hasValidToken()) {
            commit("setSupervisors", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.ALL_SUPERVISOR, {
                        headers: header
                    })
                    .then(({data}) => {
                        let superVisorList = {}
                        data.supervisors.forEach(supervisor => {
                            superVisorList[supervisor._id] = supervisor.name;
                        });
                        commit("setSupervisors", superVisorList);
                        resolve({'success': 'success'});
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanLastTicket(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.LAST_TICKET_PRINT_COUNTERMAN, {
                        params: payload,
                        headers: header
                    })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data.ticket);
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async ticketPrintCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.TICKET_PRINT_COUNT, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data.ticket)
                    })
                    .catch(error => {
                        reject(error)
                    });
            });
        }
    },
    async reportPrintCount(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
    async reportPrintCountIncrease(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.REPORT_PRINT_COUNT_INCREASE, payload, {headers: header})
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setTicketLayout: (state, layout) => (state.ticketLayout = layout),
    setTripById: (state, trip) => (state.tripById = trip),
    setSeatPlanAll: (state, seatPlanList) =>
        (state.filter.seatPlanList = seatPlanList),
    setAllCouch: (state, couchs) => (state.couchs = couchs),
    setCouchListInFilter: (state, couchList) => (state.filter.coach = couchList),
    setAllRoutes: (state, routes) => (state.allRoutes = routes),
    setRoutesForTrips: (state, data) => (state.routesForTrips = data),
    setSelectedSeatFareTotal: (state, data) => state.selectedSeatFareTotal = data,
    setSearchTickets: (state, data) => state.searchTickets = data,
    setTrip: (state, data) => {
        console.log(data)
        //state.trip = data
    },
    setBoardingTripDateTime: (state, time) => state.boardingTripDateTime = time,
    setBlockedSeatsTicketsCounterman: (state, {tickets}) => {
        const seatWiseTickets = []
        const routeSequence = state.routeSequence.map((item) => item._id);
        for (const key in tickets) {
            if(routeSequence.includes(tickets[key].fromStoppage._id) && routeSequence.includes(tickets[key].toStoppage._id)) {
                seatWiseTickets[key] = tickets[key];
            }
        }
        state.blockedSeatsTicketsCounterman = seatWiseTickets
    },
    setMaleFemaleCount: (state, data) => {
        state.maleFemaleCounter = {
            maleSeatCounter: data.maleSeat,
            femaleSeatCounter: data.femaleSeat,
            totalSeat: data.maleSeat + data.femaleSeat,
        }
    },
    setTicketByTrip: (state, ticketByTrip) => (state.ticketByTrip = ticketByTrip),
    setSupervisors: (state, supervisors) => (state.filter.supervisors = supervisors),
    setDrivers: (state, drivers) => (state.filter.drivers = drivers),
    setStoppageListInFilter: (state, stoppageList) => (state.filter.stoppage = stoppageList),
    setBoardingPlaceFilter: (state, boardingPlace) => (state.filter.boardingPlace = boardingPlace),
    setDroppingPointFilter: (state, droppingPoint) => (state.filter.droppingPoint = droppingPoint),
    setDefaultBoardingPlaceFilter: (state, defaultBoardingPlace) => (state.filter.defaultBoardingPlace = defaultBoardingPlace),
    setDefaultDroppingPointFilter: (state, defaultDroppingPoint) => (state.filter.defaultDroppingPoint = defaultDroppingPoint),
    setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
    setCoachs: (state, coachs) => (state.coachs = coachs),
    setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
    setCounterListInFilter: (state, counterList) => (state.filter.counter = counterList),
    setTicketsHistory: (state, tickets) => (state.tickets = tickets),
    setTrips: (state, trips) => (state.trips = trips),
    setRouteSequence: (state, routeSequence) => (state.routeSequence = routeSequence),
    setBoardingPlace: (state, boardingPlace) => (state.boardingPlace = boardingPlace),
    setDroppingPoint: (state, droppingPoint) => (state.droppingPoint = droppingPoint),

    updateTrip: (state, trip) => state.trips.docs.filter((element, index) => {
        if (element._id === trip._id) {
            state.trips.docs.splice(index, 1, trip)
        }
    }),
    setSelectedSeat: (state, payload) => {
        state.seatDetails.selectedSeat = payload;

    },
    setSeatFare: (state, seatFareList) => {
        state.seatDetails.seatFare = seatFareList
    },
    setTicketDetails: (state, ticketDetails) => {
        state.seatDetails.ticketDetails = ticketDetails
    },
    resetTicketDetails: (state) => {
        state.seatDetails.selectedSeat = [];
        state.seatDetails.seatFare = [];
        state.seatDetails.ticketDetails = {};
        state.seatDetails.farePerSeat = [];
        //state.passengerDetails = {}
    },
    setBookedSeatList: (state, payload) => {
        state.bookedSeatList = payload;
    },
    setPassengerDetails: (state, payload) => {
        state.passengerDetails = payload;
    },
    setPurchasedTicket: (state, payload) => {
        state.ticketPurchasedDetails.purchasedTicket = payload;
    },
    setPurchaseStatus: (state, payload) => {
        state.ticketPurchasedDetails.purchaseStatus = payload;
    },
    setSelectedSeatsBookedDatabase: (state, payload) => {
        state.selectedSeatsBookedDatabase = payload;
    },
    setResetStatus: (state, payload) => {
        state.resetStatus = payload;
    },
    setSeatResetStatus: (state, payload) => {
        state.seatResetStatus = payload;
    },
    setSeatFareIntoSeatDetails: (state, payload) => {
        state.seatDetails.seatFare = payload;
    },

};

export default {
    state,
    getters,
    actions,
    mutations
};
