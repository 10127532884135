<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>

        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getDigitalTicketingAllStoppages"
              v-bind:id="'fromStoppageId'"
              v-model="routeData.fromStoppageId"
              v-bind:defaultOption="'--select city--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getDigitalTicketingAllStoppages"
              v-bind:label="'From City'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.fromStoppageId"
                  v-bind:error="errors.fromStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getDigitalTicketingAllStoppages"
              v-bind:id="'toStoppageId'"
              v-model="routeData.toStoppageId"
              v-bind:defaultOption="'--select city--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getDigitalTicketingAllStoppages"
              v-bind:label="'To City'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.toStoppageId"
                  v-bind:error="errors.toStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-bind:id="'routename'"
              v-model="routeData.name"
              v-bind:label="'Name'"
          ></ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-bind:id="'status'"
              v-model="routeData.status"
              v-bind:dropdown="status"
              v-bind:label="'Status'"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <div class="col-sm-3 col-form-label font_detail">
            <label class="label_font">Route Sequence:</label>
          </div>
          <div class="col-sm-9">
            <Multiselect
                id="multiselectId"
                v-model="routeData.routeSequence"
                :createTag="true"
                :options="$store.getters.getDigitalTicketingAllStoppages"
                :searchable="true"
                class="col font_detail w-100"
                mode="tags"
                placeholder="Select Route Sequence"
            />
            <Error
                v-if="errors.routeSequence"
                v-bind:error="errors.routeSequence"
            />
          </div>
        </div>

        <div class="form-group row my-1">
          <div class="col-sm-3 col-form-label font_detail">
            <label class="label_font">Search Route:</label>
          </div>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-sm-5 ">
                <div class="form-group row my-1 mt-0 pt-0">
                  <ModalDropdown
                      v-if="routeSequenceFilter"
                      v-bind:id="'routesequencefrom'"
                      v-model="searchPayload.fromStoppage"
                      v-bind:defaultOption="'--select from--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="routeSequenceFilter"
                      v-bind:label="''"
                  >
                  </ModalDropdown>
                </div>
              </div>
              <div class="col-sm-5">
                <ModalDropdown
                    v-if="routeSequenceFilter"
                    v-bind:id="'routesequenceto'"
                    v-model="searchPayload.toStoppage"
                    v-bind:defaultOption="'--select to--'"
                    v-bind:defaultValue="''"
                    v-bind:dropdown="routeSequenceFilter"
                    v-bind:label="''"
                >
                  <template v-slot:error>
                    <ModalError
                        v-if="errors.searchSequence"
                        v-bind:error="errors.searchSequence"
                    />
                  </template>
                </ModalDropdown>
              </div>
              <div class="col-sm-2">
                <div style="cursor: pointer; width: 22px; height: 22px;" class="mb-2 me-auto" @click="addSearchRoute">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="green" class="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--
        <div class="card">
          <div class="card-body">-->
<!--            <div class="form-group row my-1">-->
<!--              <ModalDropdown-->
<!--                  v-if="routeSequenceFilter"-->
<!--                  v-bind:id="'routesequencefrom'"-->
<!--                  v-model="searchPayload.fromStoppage"-->
<!--                  v-bind:defaultOption="'&#45;&#45;select from search&#45;&#45;'"-->
<!--                  v-bind:defaultValue="''"-->
<!--                  v-bind:dropdown="routeSequenceFilter"-->
<!--                  v-bind:label="'From Search'"-->
<!--              >-->
<!--              </ModalDropdown>-->
<!--            </div>-->
<!--            <div class="form-group row my-1">-->
<!--              <ModalDropdown-->
<!--                  v-if="routeSequenceFilter"-->
<!--                  v-bind:id="'routesequenceto'"-->
<!--                  v-model="searchPayload.toStoppage"-->
<!--                  v-bind:defaultOption="'&#45;&#45;select to search&#45;&#45;'"-->
<!--                  v-bind:defaultValue="''"-->
<!--                  v-bind:dropdown="routeSequenceFilter"-->
<!--                  v-bind:label="'To Search'"-->
<!--              >-->
<!--                <template v-slot:error>-->
<!--                  <ModalError-->
<!--                      v-if="errors.searchSequence"-->
<!--                      v-bind:error="errors.searchSequence"-->
<!--                  />-->
<!--                </template>-->
<!--              </ModalDropdown>-->
<!--            </div>-->
            <div class="row">
              <div class="col-sm-3">
              </div>
              <div class="col-sm-9">
                <div class="row" v-for="(sRoute, index) in routeData.searchedRoutes" :key="index">
                  <div class="col-md-10" :key="index">
                    <div class="alert alert-primary pt-0 pb-0 mb-1 text-black" role="alert" :key="index">
                      {{ routeSequenceFilter[sRoute.fromStoppage]  }} -> {{routeSequenceFilter[sRoute.toStoppage]}}
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div style="cursor: pointer; width: 22px; height: 22px;" class="me-auto" @click="removeSearchRoute(sRoute)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height=22 fill="red" class="bi bi-dash-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <p v-if="errors.searchedRoute" class="text-danger">{{errors.searchedRoute}}</p>

              </div>
            </div>



<!--          </div>-->
<!--        </div>-->

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!routesId"
                class="mt-3 py-2 submit_button font_detail float-end"
                type="submit"
                @click.prevent="addDtRoute"
            />
            <input
                v-else
                class="mt-3 py-2 submit_button font_detail float-end"
                type="submit"
                @click.prevent="updateRoute"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useRoute} from "vue-router";
import Multiselect from "@vueform/multiselect";

export default {
  name: "DtRoutesAddUpdateModal",
  props: {
    route: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let {requiredValidation, compareRouteSequenceValidation,searchRouteSequenceValidation,
      errors} =
        useInputValidation();
    let routeData = reactive({
      id: "",
      companyId: route.query.id,
      name: "",
      fromStoppageId: "",
      toStoppageId: "",
      status: 1,
      routeSequence: [],
      searchedRoutes:[],
    });
    const routesId = ref(props.route);
    const title = ref("Add DT Route");
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const firstWatchFrom = ref(true);
    const firstWatchTo = ref(true);

    const isFirstTimeWatch = ref(true);
    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    let routeSequenceFilter = ref({});
    const searchPayload = ref({
      fromStoppage: "",
      toStoppage: ""
    })

    watch(
        () => routeData.routeSequence,
        () => {
          compareRouteSequenceValidation(
              "routeSequence",
              routeData.fromStoppageId,
              routeData.toStoppageId,
              routeData.routeSequence[0],
              routeData.routeSequence.slice(-1)[0]
          );
          if(!isFirstTimeWatch.value){
            resetSearchRoute()
          }
          initRouteSequenceFilter()

          isFirstTimeWatch.value = false;
        }
    );

    watch(
        () => routeData.fromStoppageId,
        () => {
          if(routesId.value !== ""){
            if (routeData.fromStoppageId && routeData.toStoppageId && !firstWatchFrom.value) {
              initRouteName()
            }
          }else if (routeData.fromStoppageId && routeData.toStoppageId ) {
            initRouteName()
          }
          firstWatchFrom.value = false;
          requiredValidation("fromStoppageId", routeData.fromStoppageId);
        }
    );
    watch(
        () => routeData.toStoppageId,
        () => {
          if(routesId.value !== ""){
            if (routeData.fromStoppageId && routeData.toStoppageId && !firstWatchTo.value) {
              initRouteName()

            }
            firstWatchTo.value = false;

          }else if (routeData.fromStoppageId && routeData.toStoppageId) {
            initRouteName()
          }

          requiredValidation("toStoppageId", routeData.toStoppageId);
        }
    );

    watch(
        () => routeData.searchedRoutes,
        () => {
          requiredValidation("searchedRoute", routeData.searchedRoutes[0]?.fromStoppage);
        },
        {deep: true}
    );

    // watch(
    //     () => searchPayload.value,
    //     (value) => {
    //       if(value.fromStoppage && value.toStoppage){
    //         searchRouteSequenceValidation(
    //             "searchSequence",
    //             value.fromStoppage,
    //             value.toStoppage,
    //             Object.keys(routeSequenceFilter.value),
    //         )
    //       }
    //     },
    //     {
    //       deep: true
    //     }
    // );

    const resetSearchRoute = () => {
      routeData.searchedRoutes = [];
      routeSequenceFilter.value = {};
      searchPayload.value.fromStoppage = "";
      searchPayload.value.toStoppage = "";

    }

    const removeSearchRoute = (sRoute) => {
      routeData.searchedRoutes = routeData.searchedRoutes.filter((item) => (
        !(item.fromStoppage === sRoute.fromStoppage && item.toStoppage === sRoute.toStoppage)
      ))
    }

    const addSearchRoute = () => {
      if(searchPayload.value.fromStoppage && searchPayload.value.toStoppage){
        searchRouteSequenceValidation(
            "searchSequence",
            searchPayload.value.fromStoppage,
            searchPayload.value.toStoppage,
            Object.keys(routeSequenceFilter.value),
        )
      }
      if(!errors.searchSequence && !checkSequenceDuplicay() && searchPayload.value.fromStoppage && searchPayload.value.toStoppage ){
        let data = searchPayload.value;
        routeData.searchedRoutes.push({fromStoppage: data.fromStoppage, toStoppage: data.toStoppage});
      }
    }

    const checkSequenceDuplicay = () => {
      return routeData.searchedRoutes.filter((item)=> (item.fromStoppage === searchPayload.value.fromStoppage && item.toStoppage === searchPayload.value.toStoppage)).length > 0
    }

    const initRouteSequenceFilter = () => {
      routeSequenceFilter.value = {};
      routeData.routeSequence.forEach((routeId) => {
        routeSequenceFilter.value[routeId] = store.getters.getDigitalTicketingAllStoppages[routeId]
      })
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("fromStoppageId", routeData.fromStoppageId);
      requiredValidation("toStoppageId", routeData.toStoppageId);
      compareRouteSequenceValidation(
          "routeSequence",
          routeData.fromStoppageId,
          routeData.toStoppageId,
          routeData.routeSequence[0],
          routeData.routeSequence.slice(-1)[0]
      );
      requiredValidation("searchedRoute", routeData.searchedRoutes[0]?.fromStoppage);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const initRouteName = () => {
      routeData.name = store.getters.getDigitalTicketingAllStoppages[routeData.fromStoppageId] +
          "-" +
          store.getters.getDigitalTicketingAllStoppages[routeData.toStoppageId];
      routeData.name.trim()
    }
    const addDtRoute = () => {
      if (routeData.routeSequence.length === 0) {
        routeData.routeSequence.push(routeData.fromStoppageId);
        routeData.routeSequence.push(routeData.toStoppageId);
      }
      if (validate()) {

        showLoader();
        store
            .dispatch("addDtRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addDtRoute();
              }
            });
      }
    };
    const updateRoute = () => {
      if (routeData.routeSequence.length === 0) {
        routeData.routeSequence.push(routeData.fromStoppageId);
        routeData.routeSequence.push(routeData.toStoppageId);
      }
      if (validate()) {

        showLoader();
        store
            .dispatch("updateDtRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateRoute();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (routesId.value !== "") {
        const routeDetails = store.getters.getRoute(routesId.value);
        title.value = "Update DT Route";
        routeData.id = routeDetails[0]._id;
        routeData.fromStoppageId = routeDetails[0].fromStoppageId;
        routeData.toStoppageId = routeDetails[0].toStoppageId;
        routeData.name = routeDetails[0].name;
        routeData.routeSequence = routeDetails[0].routeSequence;
        routeData.searchedRoutes = routeDetails[0].searchedRoutes;
        routeData.status = routeDetails[0].status ? 1 : 0;

      }
    });

    return {
      routesId,
      routeData,
      errors,
      status,
      title,
      addDtRoute,
      updateRoute,
      closeModal,
      routeSequenceFilter,
      searchPayload,
      addSearchRoute,
      removeSearchRoute,
    };
  },
};
</script>

<style scoped></style>
