
import tokenCookie from "@/modules/tokenCookie";
import axios from "axios";
import Apis from "@/urls/apis";
import apiResponse from "@/modules/apiResponse";
const {errorResponse} = apiResponse();
const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();


const state = {
    stoppageAll: {},
    coachAll: {},
    counterAll: {},
    countermanAll: {},
    tripList: [],
    selectedTripDetails: {},
    ticketShortInfo: {},
    preTripCopy: {},
    ticketsInfo: [],
};
const getters = {
    getTicketsInfo: (state) => state.ticketsInfo,
    getPreTripCopy: (state) => state.preTripCopy,
    getAllStoppage: (state) => state.stoppageAll,
    getAllCoach: (state) => state.coachAll,
    getAllCounter: (state) => state.counterAll,
    getAllCounterman: (state) => state.countermanAll,
    getCountermanTripListByPage: (state) => state.tripList.docs,
    getCountermanTripByPage: (state) => (id) =>
        state.tripList.docs.filter(function (trip) {
            return trip._id === id;
        }),
    getCountermanTotalTripByPage: (state) => state.tripList.totalDocs,
    getTripForTicketingNew: (state) => state.selectedTripDetails,
    getTicketShortInfo: (state) => state.ticketShortInfo,

};
const actions = {
    async getCountermanByCounter({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_COUNTERMAN_BY_COUNTER, {
                        headers: header,
                        params: payload,
                    })
                    .then(({status, data}) => {
                        if(status === 200){
                            commit('setAllCounterman', data.countermen)
                            resolve()
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getTicketsInfo({commit}, payload) {
            if (await hasValidToken()) {
                const header = {
                    Authorization: "Bearer " + getToken(),
                };
                return new Promise((resolve, reject) => {
                    axios
                        .get(Apis.GET_TICKETS_INFO, {
                            headers: header,
                            params: payload,
                        })
                        .then(({status, data}) => {
                            if(status === 200){
                                commit('setTicketsInfo', data.tickets)
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(errorResponse(error));
                        });
                });
            }
        },

    async addOnProgressSeat(_, payload) {
            if (await hasValidToken()) {
                const header = {
                    Authorization: "Bearer " + getToken(),
                };
                return new Promise((resolve, reject) => {
                    axios.post(Apis.ADD_ON_PROGRESS_SEAT, payload, {headers: header})
                        .then(({status, data}) => {
                            if(status === 200){
                                resolve(data)
                            }
                        })
                        .catch((error) => {
                            reject(errorResponse(error));
                        });
                });
            }
        },

    async removeOnProgressSeat(_, payload) {
            if (await hasValidToken()) {
                const header = {
                    Authorization: "Bearer " + getToken(),
                };
                return new Promise((resolve, reject) => {
                    axios.post(Apis.REMOVE_ON_PROGRESS_SEAT, payload, {headers: header})
                        .then(({status, data}) => {
                            if(status === 200){
                                resolve(data)
                            }
                        })
                        .catch((error) => {
                            reject(errorResponse(error));
                        });
                });
            }
        },

    async getPreTripCopy({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_PRE_TRIP_COPY, {
                        headers: header,
                        params: payload,
                    })
                    .then(({status, data}) => {
                        if(status === 200){
                            commit('setPreTripCopy', data.trip)
                            resolve()
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getDashboardAll({commit}) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_ALL_DASHBOARD, {
                        headers: header,
                    })
                    .then(({status, data}) => {
                        if(status === 200){
                            commit('setStoppageAll', data.stoppage)
                            commit('setCoachAll', data.coach)
                            commit('setCounterAll', data.counter)
                            resolve()
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },


    async getTripListByPage({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TRIP_LIST_BY_PAGE, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit('setTripListByPage', response.data.trips)
                        resolve();
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getTicketByTripNew({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.TRIP_DETAILS, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        commit('setTripNew', response.data)
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getExtraTicketPrePurchase(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.EXTRA_TICKET_PRE_PURCHASE, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
    async getTripPassDetails(_, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.TRIP_PASS_DETAILS, {
                        params: payload,
                        headers: header,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getTicketShortInfo({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_TICKET_SHORT_INFO, {
                        params: payload,
                        headers: header,
                    })
                    .then(({status, data}) => {
                        if(status === 200){
                            commit('setTicketShortInfo', data.tickets[0])
                            resolve()
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },

    async getInProgressTicketShortInfo({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                Authorization: "Bearer " + getToken(),
            };
            return new Promise((resolve, reject) => {
                axios
                    .get(Apis.GET_IN_PROGRESS_TICKET_SHORT_INFO, {
                        params: payload,
                        headers: header,
                    })
                    .then(({status, data}) => {
                        if(status === 200){
                            console.log(data.onProgressSeats.dtReferenceId)
                            let seatData = {
                                phone: data?.onProgressSeats?.dtReferenceId ? 'N/A' : data?.onProgressSeats?.user?.phone,
                                counterman: data?.onProgressSeats?.dtReferenceId ? 'Jatri Online' : data?.onProgressSeats?.user?.name,
                                counter: data?.onProgressSeats?.dtReferenceId ? 'Online' : data?.onProgressSeats?.counter?.name,
                            }
                            commit('setTicketShortInfo', seatData)
                            resolve()
                        }
                    })
                    .catch((error) => {
                        reject(errorResponse(error));
                    });
            });
        }
    },
};
const mutations = {
    setAllCounterman: (state, data) => {
        state.countermanAll = data
    },
    setTicketsInfo: (state, data) => {
        state.ticketsInfo = data
    },
    setPreTripCopy: (state, data) => {
        state.preTripCopy = data
    },
    setStoppageAll: (state, data) => {
        state.stoppageAll = data
    },
    setCoachAll: (state, data) => {
        state.coachAll = data
    },
    setCounterAll: (state, data) => {
        state.counterAll = data
    },
    setTripListByPage: (state, tripList) => {
      state.tripList = tripList
    },
    setTripNew: (state, data) => {
        state.selectedTripDetails = data
    },
    setTicketShortInfo: (state, data) => {
        state.ticketShortInfo = data
    },

    setSeatInProgress: (state, seatPosition) => {
        let seatList = state.selectedTripDetails.seatList
        seatList[seatPosition.row][seatPosition.col].status = 'SEAT_IN_PROGRESS'
        state.selectedTripDetails.seatList = seatList
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
