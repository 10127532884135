<template>
  <div v-if="true">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'company'"
              v-bind:label="'Company'"
              v-bind:defaultOption="'--select company--'"
              v-model="pagination.company"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCompanyList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DropdownFilter
              v-bind:id="'counter'"
              v-bind:label="'Counter'"
              v-bind:defaultOption="'--select counter--'"
              v-model="pagination.counter"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCounterListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DropdownFilter
              v-bind:id="'counter'"
              v-bind:label="'Issue By'"
              v-bind:defaultOption="'--select counterman--'"
              v-model="pagination.issuedBy"
              v-bind:defaultValue="''"
              v-bind:dropdown="countermenFilterList"
          />
        </div>
        <div
            class="col-sm-6 col-md-2"
            v-if="pagination.company"
        >
          <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:defaultOption="'ALL'"
              v-model="pagination.status"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.QRHistoryStatusType"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="pagination.company">
          <DatePickerFilter
              :id="'datePickerDate'"
              v-model="pagination.date"
              :label="'Date'"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            pagination.company &&
            qrHistoryList?.docs?.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_QR_HISTORY_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="pagination.company === '' && selectCompany"
    >
      Please,Select a
      <a href="#" class="alert-link">company</a> from dropdown
    </div>
    <Table
        v-if="
        pagination.company !== '' &&
        filterActive &&
        qrHistoryList?.docs?.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Company Name</td>
          <td class="font_detail">Counter Name</td>
          <td class="font_detail">Issued By</td>
          <td class="font_detail">Trip Code</td>
          <td class="font_detail">Trip Datetime</td>
          <td class="font_detail">Seats</td>
          <td class="font_detail">Amount</td>
          <td class="font_detail">Status</td>
          <td class="font_detail">Action</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in qrHistoryList.docs"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 + (pagination.page - 1) * pagination.limit }}</td>
          <td class="font_detail">{{ item?.company?.name }}</td>
          <td class="font_detail">{{ item?.counter?.name }}</td>
          <td class="font_detail">{{ item?.issuedBy?.name }}</td>
          <td class="font_detail">{{ item?.coachName }}</td>
          <td class="font_detail">{{ dateTimeFormat(item?.tripDateTime) }}</td>
          <td class="font_detail">{{ item?.seats.join(', ') }}</td>
          <td class="font_detail">{{ item?.amount }}</td>
          <td class="font_detail">{{ item?.status }}</td>
          <td class="font_detail">
            <button
                v-if="item?.status === 'EXPIRED'"
                class="btn btn-primary m-1"
                @click="refundHandler(item._id)"
            >
              Refund
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">Total Amount: {{amountTotal}} Taka</td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
        </tr>
      </template>
            <template v-slot:pagination>
              <pagination
                  v-model="pagination.page"
                  :records="qrHistoryList.totalDocs"
                  :per-page="pagination.limit"
                  @paginate="getAllQRHistory"
                  :options="options"
              />
            </template>
    </Table>
  </div>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";
import moment from "moment";
import {onBeforeMount, ref, inject, watch, reactive} from "vue";
import store from "@/store";
import toast from "@/modules/Toast";
const {infoToast} = toast();

export default {
  name: "QRSettings",
  components: {
    DropdownFilter,
  },

  setup() {

    let selectCompany = ref(false);
    let filterActive = ref(false);
    let qrHistoryList = ref({});
    const countermenFilterList = ref([])
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    let amountTotal = ref(0);
    let pagination = reactive({
      page: 1,
      limit: 25,
      company: "",
      counter: "",
      issuedBy: "",
      status: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
    });

    const options = {
      texts: {
        count: "",
      },
    };

    const getAllQRHistory = () => {
      showLoader();
      store
          .dispatch("getQRHistoryList", pagination)
          .then((res) => {
            amountTotal.value = 0;
            qrHistoryList.value = []
            if(res.data?.docs?.length === 0){
                infoToast('No Data Available')
            } else {
              sumListData(res.data.docs);
            }
            qrHistoryList.value = res.data
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllQRHistory();
            }
          })
          .finally(() => hideLoader());
    };


    const getAllCompanyList = () => {
      showLoader();
      store.dispatch("getAllCompanyList").then(() => hideLoader())
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCompanyList();
            }
          });
    };

    const getAllCounter = () => {
      showLoader();
      store
          .dispatch("getCounterListForFilter", {
            companyId: pagination.company,
          })
          .finally(() => {
            hideLoader();
          })
    };


    const getAllCounterman = () => {
      showLoader();
      store.dispatch("getAllCountermenFilterList", {
        company: pagination.company,
        counter: pagination.counter,
      })
          .then((res) => {
            countermenFilterList.value = res
          })
          .catch((error) => {
            if (error.status === 401) {
              getAllCompanyList();
            }
          })
          .finally(() => {
            hideLoader();
          });
    };

    const filterReport = () => {
      selectCompany.value = true;
      if (pagination.company !== "") {
        filterActive.value = true;
        pagination.page = 1;
        getAllQRHistory()
      }
    };

    const sumListData = (histories) => {
      histories.forEach((data) => {
        amountTotal.value += data.amount;
      })
    }

    const refundHandler = (identifier) => {
      showLoader();
      store.dispatch("adminQRHistoryRefund", identifier)
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            hideLoader();
            getAllQRHistory()
          });
    }

    watch(
        () => pagination.company,
        () => {
          pagination.counter  = ""
          pagination.issuedBy  = ""
          if (pagination.company !== "") {
            getAllCounter();
          }
        }
    );

    watch(
        () => pagination.counter,
        () => {
          pagination.issuedBy  = ""
          if (pagination.company !== "" && pagination.counter  !== "") {
            getAllCounterman();
          }
        }
    );

    onBeforeMount(() => {
      getAllCompanyList();
    });

    return {
      dropdownVariables,
      getAllCompanyList,
      getAllCounterman,
      pagination,
      filterReport,
      qrHistoryList,
      countermenFilterList,
      selectCompany,
      filterActive,
      exportExcel,
      refundHandler,
      amountTotal,
      dateTimeFormat,
      getAllQRHistory,
      options
    };
  },
};
</script>

<style scoped></style>