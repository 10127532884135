<template>
  <div>
    <div>
      <div v-if="reportTabs">
        <ul
            id="myTab"
            class="nav nav-tabs justify-content-center nav-style"
            role="tablist"
        >
          <li
              v-for="(tab, index) in reportTabs"
              :key="index"
              class="nav-item"
              role="presentation"
          >
            <button
                :id="tab.name + '-tab'"
                :aria-controls="tab.path"
                aria-selected="true"
                class="nav-link"
                data-bs-toggle="tab"
                role="tab"
                type="button"
                v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
                @click="setActive(tab.name)"
            >
              {{ tab.title }}
            </button>
          </li>
        </ul>
        <div id="myTabContent" class="tab-content">
          <div
              v-for="(tab, index) in reportTabs"
              :id="tab.path"
              :key="index"
              :aria-labelledby="tab.name + '-tab'"
              class="tab-pane fade"
              role="tabpanel"
              v-bind:class="tab.name === activeTab ? 'show active' : ''"
          ></div>
          <TripReport
              v-if="activeTab === reportTabs.TRIP_REPORT_URL.name"
          />
          <Reservation
              v-if="activeTab === reportTabs.RESERVATION_REPORT_URL.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TripReport from "@/views/Counterman/Report/Children/Trip/TripReport";
import Reservation from "@/views/Counterman/Report/Children/Reservation/Reservation";
import {onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import web from "@/urls/web";
import store from "@/store";
import router from "@/router";

const {hasPermission} = permission();
const {Counterman} = roles();
export default {
  name: "CountermanReport",
  components: {TripReport, Reservation,},
  setup() {
    let activeTab = ref("");
    const reportTabs = web.Counterman.REPORT_URL.children;
    watch(
        () => store.getters.userRoles,
        (value) => {
          if (!value?.includes(Counterman.reports)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
          ? store.getters.getActiveTab
          : reportTabs.TRIP_REPORT_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      Counterman,
      TripReport,
      activeTab,
      reportTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
