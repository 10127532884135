<template>
  <Modal @close="closeModal">
    <template v-slot:title>PNR TICKET</template>
    <template v-slot:body>
      <div class="form-group row">
        <TicketInput
          v-model="pnrTicketData"
          v-bind:placeholder="'Pnr Ticket'"
          v-bind:label="'Pnr Ticket'"
          v-bind:id="'Pnr Ticket'"
          v-bind:type="'text'"
        />
        <Error v-if="errors.pnrTicket" v-bind:error="errors.pnrTicket" />
      </div>
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Confirm"
            @click.prevent="pnrTicket"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref, inject, watch } from "vue";
import useInputValidation from "@/modules/useInputValidations";
import store from "@/store";
export default {
  name: "PnrModal",
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    let pnrTicketData = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const closeModal = () => {
      emit("update:modelValue", false);
    };

    watch(
      () => pnrTicketData.value,
      () => {
        requiredValidation("pnrTicket", pnrTicketData.value);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function(key) {
        errors[key] = "";
      });
      requiredValidation("pnrTicket", pnrTicketData.value);

      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const pnrTicket = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("pnrTicketAgentIntercity", {
            code: pnrTicketData.value.toUpperCase(),
          })
          .then((response) => {
            try {
              emit("pnrPrint", response.data.ticket[0]);
              closeModal();
              hideLoader();
            } catch (e) {
              console.log(e);
            } finally {
              closeModal();
            }
          })
          .catch(() => {
            closeModal();
            hideLoader();
          });
      }
    };
    return {
      closeModal,
      pnrTicket,
      pnrTicketData,
      errors,
    };
  },
};
</script>

<style scoped></style>
