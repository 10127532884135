<template>
  <label :for="inputId" class="text-white">{{
      t(inputLabel, {}, {locale: $store.getters.getLang})
    }}</label>
  <select
      :id="inputId"
      v-model="input"
      aria-label=".form-select-sm example"
      class="form-select font_detail form-select-sm w-100"
      style="padding: 6px"
  >
    <option v-if="inputDefaultOption" :value="inputDefaultValue">
      {{ t(inputDefaultOption, {}, {locale: $store.getters.getLang}) }}
    </option>
    <option v-for="(type, index) in inputDropdown" :key="index" :value="index">
      {{ type }}
    </option>
  </select>
</template>

<script>
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "Dropdown",
  props: {
    modelValue: [String, Number, Boolean],
    dropdown: Object,
    defaultOption: String,
    defaultValue: String,
    label: String,
    id: String,
  },
  setup(props, {emit}) {
    const inputDropdown = ref(props.dropdown);
    const inputDefaultOption = ref(props.defaultOption);
    const inputDefaultValue = ref(props.defaultValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const input = ref(props.modelValue);
    const {t, locale} = useI18n();
    watch(
        () => input.value,
        () => {
          emit("update:modelValue", input.value);
        }
    );
    watch(
        () => props.modelValue,
        () => {
          input.value = props.modelValue;
        }
    );
    watch(
        () => props.dropdown,
        () => {
          inputDropdown.value = props.dropdown;
        }
    );
    return {
      t,
      locale,
      inputDropdown,
      inputDefaultOption,
      inputDefaultValue,
      inputLabel,
      inputId,
      input,
    };
  },
};
</script>

<style scoped></style>
