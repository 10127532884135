<template>
  <Modal :size="'xl'" @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <TextShow label="Routes" :text="$store.getters.getPreTripCopy.route.name"></TextShow>
            </div>

            <div  class="col-md-3">
              <DatePickerFilter
                  v-bind:id="'tripDate'"
                  v-model="tripData.date"
                  :isModal=true
                  v-bind:label="'Trip_Date'"
              >
                <template v-slot:error>
                  <ModalError v-if="errors.date" v-bind:error="errors.date"/>
                </template>
              </DatePickerFilter>
            </div>

            <div class="col-md-3">
              <TimePickerFilter
                  v-bind:id="'tripTime'"
                  v-model="tripData.tripTime"
                  :isModal=true
                  v-bind:label="'tripTime'"
              >
                <template v-slot:error>
                  <ModalError
                      v-if="errors.tripTime"
                      v-bind:error="errors.tripTime"
                  />
                </template>
              </TimePickerFilter>
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-bind:id="'coach'"
                  v-model="tripData.coach"
                  v-bind:defaultOption="'---select a coach---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="$store.getters.getAllCoach"
                  v-bind:hideLabel="'inactive'"

                  v-bind:label="'Coach'"
              />
              <Error v-if="errors.coach" v-bind:error="errors.coach"/>
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-bind:id="'seatplan'"
                  v-model="tripData.seatPlan"
                  v-bind:defaultOption="'---select a seat plan---'"
                  v-bind:defaultValue="''"
                  v-bind:dropdown="$store.getters.getPreTripCopy.seatPlanList"
                  v-bind:hideLabel="'inactive'"
                  v-bind:label="'Seat Plan'"
              />
              <Error v-if="errors.seatPlan" v-bind:error="errors.seatPlan"/>
            </div>
            <div class="col-md-3">
              <TextShow label="From Stoppage" :text="$store.getters.getPreTripCopy.fromStoppage.name"></TextShow>
            </div>
            <div class="col-md-3">
              <TextShow label="To Stoppage" :text="$store.getters.getPreTripCopy.toStoppage.name"></TextShow>
            </div>
            <div  class="col-md-3">
              <Dropdown
                  v-bind:id="'status'"
                  v-model="tripData.status"
                  v-bind:dropdown="status"
                  v-bind:defaultOption="'--select status--'"
                  v-bind:defaultValue="''"
                  v-bind:hideLabel="'inactive'"
                  v-bind:label="'Status'"
                  v-bind:trip="'active'"
              />
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                class="mt-3 py-2 submit_button font_detail float-end"
                type="submit"
                @click.prevent="copyTrip"
            />
          </div>
        </div>
      </form>

    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {computed, inject, onBeforeMount, ref,watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
import DatePickerFilter from "@/components/layouts/Filter/Inputs/DatePicker";

export default {
  name: "TripCopyModal",
  components: {DatePickerFilter},
  props: {
    trip: String,
    pagination: Object,
  },
  setup(props, {emit}) {
    let {requiredValidation, errors} = useInputValidation();

    let tripData = reactive({
      tripId: "",
      date: "",
      tripTime: "",
      route: "",
      coach: "",
      seatPlan: "",
      status: "ACTIVE",
    });

    const tripId = ref(props.trip);
    const isCopyActive = ref(props.isCopy)
    const showShipDropdown = ref(false);
    const ships = ref({});
    const direction = dropdownVariables.direction;
    const title = ref("Add Trips");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let error = ref("");
    let routeSequences = ref([]);
    const boardingMinutes = ref(0);

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    let allCoaches = ref("");
    let fareType = ref("");
    const firstWatch = ref(true);

    watch(
        () => tripData.coach,
        (value) => {
          if(!firstWatch.value){
            allCoaches.value = store.getters.getPreTripCopy.coachList;
            allCoaches.value.forEach((coach) => {
              if (coach._id === value) {
                tripData.seatPlan = coach.seatPlanId;
              }
            });
          }
          firstWatch.value = false;
        }
    );

    const validate = () => {
      if (error.value !== "") {
        return false;
      }
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("coach", tripData.coach);
      requiredValidation("seatPlan", tripData.seatPlan);
      requiredValidation("route", tripData.route);
      requiredValidation("tripTime", tripData.tripTime);
      requiredValidation("tripDate", tripData.date);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const copyTrip = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("copyCountermanTrip", {
              tripData: tripData,
              fareType: {
                fareType: fareType.value,
                page: props.pagination.page,
                limit: 25,
                companyId: store.getters.userCompany,
                status: "ACTIVE",
                date: moment(new Date()).format("yyyy-MM-DD"),
                upcoming: props.pagination.upcoming,
                from: store.getters.getPreTripCopy.fromStoppage._id,
                to: store.getters.getPreTripCopy.toStoppage._id,
                coach: store.getters.getPreTripCopy.selectedCoach,
              },
            })
            .then(() => {
              hideLoader();
              emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                copyTrip();
              }
            });
      }
    }

    const getPreTripCopy = ( ) => {
      showLoader()
      store
          .dispatch("getPreTripCopy", {
            tripId: tripId.value,
          })
          .then(( ) => {
            initTripData();
            hideLoader()
          }).catch(()=>{
            hideLoader()
      });
    }

    const initTripData = () => {
      const tripDetails = store.getters.getPreTripCopy;
      console.log(tripDetails)
      tripData.tripId = tripDetails._id;
      tripData.route = tripDetails.route._id;
      tripData.status = tripDetails.status;
      tripData.coach = tripDetails.selectedCoach;
      tripData.seatPlan = tripDetails.selectedSeatPlan;
      tripData.date = moment(new Date()).format("yyyy-MM-DD");
      tripData.tripTime = moment(tripDetails.tripDateTime)
          .utcOffset(0, false)
          .format("HH:mm:00");
    }

    onBeforeMount(() => {
      getPreTripCopy()
      title.value = "Copy Trip";
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      fareType.value = store.getters.userCompanyData.fareType;
    });

    return {
      tripId,
      errors,
      status,
      title,
      showShipDropdown,
      ships,
      direction,
      tripData,
      error,
      closeModal,
      routeSequences,
      boardingMinutes,
      isCopyActive,
      copyTrip,

    };
  },
};
</script>

<style scoped></style>
