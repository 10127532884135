<template>
  <Modal @close="modalClose">
    <template v-slot:title>Trip Bulk Cancel</template>
    <template v-slot:body>
      Do you want to Cancel {{ $store.getters.getTotalTrips }} trips in Bulk?
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <div class="float-end">
            <input
                type="submit"
                class="mt-3 me-5 py-2  submit_button  font_detail"
                value="No"
                @click="modalClose"
            />
            <input
                type="submit"
                class="mt-3 py-2 submit_button font_detail"
                value="Yes"
                @click.prevent="tripCancelInBulk"
            />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import {inject, onBeforeMount} from "vue";
import store from "@/store";
export default {
  name: "TripCancelModal",
  props: {
    params: Object,
  },
  emits: ['getAllTrip'],
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const tripCancelInBulk = () => {
      showLoader();
      store
          .dispatch("cancelTripsInBulk", props.params)
          .then(() => {
            emit("close");
            emit("getAllTrip");
            hideLoader();
          })
          .catch((error) => {
            emit("close");
            hideLoader();
            if(error.status===401){
              tripCancelInBulk()
            }
          });
    };


    const modalClose = () => {
      emit('close')
    }

    onBeforeMount(()=>{
    })

    return {
      tripCancelInBulk,
      props,
      modalClose,
    };
  },
};
</script>

<style scoped></style>
