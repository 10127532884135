<template>
  <div>
    <FilterTab>
      <template v-slot:title><h6>Boarding Places ({{stoppageName}})</h6></template>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <!--          <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'" v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                          v-bind:defaultValue="''"-->
          <!--                          v-bind:dropdown="dropdownVariables.status"/>-->
        </div>
      </template>
      <template v-slot:button2>
        <button
          v-if="hasPermission(Admin.companyDeployWrite)"
          class="edit_table_data font_detail"
          @click="goBack"
        >
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          v-if="hasPermission(Admin.companyDeployWrite)"
          class="edit_table_data font_detail"
          @click="addModal"
        >
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
        <button v-else class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
    </FilterTab>

    <Table>
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">NAME</td>
          <td
            v-if="hasPermission(Admin.companyDeployWrite)"
            class="font_detail"
          >
            ACTION
          </td>
        </tr>
      </template>
      <template v-slot:body v-if="$store.getters.getDigitalTicketingBoardingPlaces">
        <tr
          v-for="(boardingPlace, index) in $store.getters
            .getDigitalTicketingBoardingPlaces"
          :key="index"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ boardingPlace }}</td>

          <td v-if="hasPermission(Admin.companyDeployWrite)">
            <button
              class="edit_table_data font_detail edit_table_button"
              @click="updateModal(boardingPlace, index)"
            >
              <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
            </button>
            <button
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="header_delete_button font_detail"
              @click="deleteBoardingPlace(index, boardingPlace)"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button>
          </td>
        </tr>
      </template>
    </Table>
    <div v-if="showModal">
      <BoardingPlaceAddUpdateModal
        v-model="showModal"
        @getAllBoardingPlaces="getAllBoardingPlaces"
        v-bind:stoppage="idForStoppage"
        v-bind:boardingPlace="boardingPlaceId"
        :boardingPlaceName="boardingPlace"
      />
    </div>
    <div v-if="showModalDelete">
      <BoardingPlaceDeleteModal
        v-model="showModalDelete"
        @getAllBoardingPlaces="getAllBoardingPlaces"
        :stoppageId="idForStoppage"
        :index="boardingPlaceIndex"
        :boardingPlaceName="boardingPlace"
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import BoardingPlaceAddUpdateModal from "./BoardingPlaceAddUpdateModal";
import BoardingPlaceDeleteModal from "./BoardingPlaceDeleteModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "BoardingPlace",
  components: { BoardingPlaceAddUpdateModal, BoardingPlaceDeleteModal },
  props: {
    stoppageId: String,
    stoppageName: String
  },
  setup(props, { emit }) {
    let pagination = reactive({
      page: 1,
      limit: 25,
      stoppageId: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const idForStoppage = ref(props.stoppageId);
    const boardingPlaceId = ref("");
    const boardingPlace = ref("");
    const boardingPlaceIndex = ref(0);
    const showModal = ref(false);
    const showModalDelete = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllBoardingPlaces = () => {
      showLoader();
      store
        .dispatch("getDigitalTicketingBoardingPlaces", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllBoardingPlaces();
          }
        });
    };
    const goBack = () => {
      emit("toggle");
    };
    const addModal = () => {
      boardingPlaceId.value = "";
      showModal.value = true;
    };
    const updateModal = (bp) => {
      boardingPlaceId.value = bp;
      boardingPlace.value = bp;
      showModal.value = true;
    };

    const deleteBoardingPlace = (index, bp) => {
      showModalDelete.value = true;
      boardingPlaceIndex.value = index;
      boardingPlace.value = bp;
    };

    onBeforeMount(() => {
      if (idForStoppage.value) {
        pagination.stoppageId = idForStoppage.value;
        getAllBoardingPlaces();
      }
    });

    return {
      dropdownVariables,
      pagination,
      options,
      showModal,
      boardingPlaceId,
      idForStoppage,
      Admin,
      hasPermission,
      addModal,
      updateModal,
      getAllBoardingPlaces,
      goBack,
      boardingPlace,
      deleteBoardingPlace,
      showModalDelete,
      boardingPlaceIndex,
    };
  },
};
</script>

<style scoped>
.default .svg-inline--fa {
  font-size: 25px;
}
.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}
.bold {
  font-weight: bold;
}
</style>
