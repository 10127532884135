<template>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-md-12 mb-3">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">Seat</h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-sm-center justify-content-lg-around align-items-md-center align-items-lg-stretch flex-wrap flex-md-nowrap">
              <figure class="figure w-50">
                <h3>
                  {{ $store.getters.getDashboardTickets[0]?.totalSeatTicket || 0 }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Total Tickets</h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.totalSeatAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Total Amount</h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                 {{
                    $store.getters.getDashboardTickets[0]?.cashTicket || 0
                 }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Cash Tickets</h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.cashTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Cash Amount</h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTickets[0]?.qrTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>QR Tickets</h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.qrTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>QR Amount</h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  {{
                    $store.getters.getDashboardTickets[0]?.dtTicket || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>DT Tickets</h5>
                </figcaption>
              </figure>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.dtTicketAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>DT Amount</h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">Goods</h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-around align-items-stretch">
              <figure class="figure w-50">
                <h3>
                  {{ $store.getters.getDashboardTickets[0]?.goodsTicket || 0 }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Tickets</h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.goodsAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Amount</h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card text-center shadow">
          <h2 class="card-header background text-light">Extra Seat</h2>
          <div class="card-body backgroundBody">
            <div class="d-flex justify-content-around align-items-stretch">
              <figure class="figure w-50">
                <h3>
                  {{ $store.getters.getDashboardTickets[0]?.extraTicket || 0 }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Tickets</h5>
                </figcaption>
              </figure>
              <div class="dashedBorder"></div>
              <figure class="figure w-50">
                <h3>
                  &#2547;{{
                    $store.getters.getDashboardTickets[0]?.extraAmount || 0
                  }}
                </h3>
                <figcaption class="figure-caption">
                  <h5>Amount</h5>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardReports",
};
</script >

<style scoped>
.dashedBorder {
  border-right: 3px dashed #cf200d21;
}
@media (max-width: 767px) {
  .dashedBorder {display: none}
}
.background {
  background-color: #f04835d8;
}
.backgroundBody {
  background-color: #eee;
}
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>