<template>
  <div>
    <input
        class="form-check-input"
        type="checkbox"
        v-model="checked"
        :id="checkboxId"
    />
    <label class="form-check-label" :for="checkboxId">
      {{ t(checkboxLabel, {}, { locale: $store.getters.getLang }) }}</label
    >
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "CheckboxSingle",
  props: {
    modelValue: Boolean,
    label: String,
    id: String,
  },
  setup(props, { emit }) {
    let checked = ref(props.modelValue);
    const checkboxLabel = ref(props.label);
    const checkboxId = ref(props.id);
    const { t, locale } = useI18n();
    watch(
        () => props.modelValue,
        (value) => {
          checked.value = value
        }
    );
    watch(
        () => checked.value,
        (value) => {
          emit("update:modelValue", value);
        }
    );

    return { t, locale, checked, checkboxLabel,checkboxId };
  },
};
</script>

<style scoped>
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin-right: 7px;
}
</style>
