export default {
    seatColor: {
        AVAILABLE: '',
        SOLD_SELF_MALE: 'male',
        SOLD_SELF_FEMALE: 'female',
        SOLD_OTHER_MALE: 'maleOtherCounter',
        SOLD_OTHER_FEMALE: 'femaleOtherCounter',
        BOOKED_SELF: 'bg-secondary',
        BOOKED_OTHER: 'bg-dark',
        SOLD_DT: 'bg-info',
        BOOKED_QUOTA: 'bg-primary',
        SEAT_IN_PROGRESS: 'seat-in-progress'
    }
}