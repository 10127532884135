<template>
  <Modal @close="closeModal">
    <template v-slot:title>Cancel Ticket</template>
    <template v-slot:body>
      <div>
        <div class="row form-group">
          <ModalDropdown
            v-bind:id="'status'"
            v-model="ticketCancelData.cancellationCause"
            v-bind:default-option="'--select reason--'"
            v-bind:default-value="''"
            v-bind:dropdown="ticketCancelReason"
            v-bind:label="'Cancel reason'"
          >
                        <template v-slot:error>
                          <ModalError
                              v-if="errors.ReasonToCancelIssue"
                              v-bind:error="errors.ReasonToCancelIssue"
                          />
                        </template>
          </ModalDropdown>
        </div>
        <div
          v-if="ticketCancelData.cancellationCause === 'Other'"
          class="form-group row my-1"
        >
          <label class="col-sm-3 col-form-label font_detail"></label>
          <div class="col-12 col-md-9">
            <TextArea
              v-bind:id="'Cancel'"
              v-model="ticketCancelIssue"
              v-bind:placeholder="'Why are you cancelling Ticket?'"
              v-bind:type="'text'"
            >
            </TextArea>
                        <Error v-if="errors.TicketCancelIssue"
                               v-bind:error="errors.TicketCancelIssue"
                        />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3"></div>
          <div class="col-12 col-md-9">
            <button
              class="btn btn-danger d-block w-100 mt-2 font_detail"
              @click.prevent="cancelDtTicket"
            >
              {{ t("Cancel Ticket", {}, { locale: selectedLanguage }) }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import {computed, inject, onBeforeMount, reactive, ref, watch} from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";



export default {
  name: "CancelTicketModal",
  props: {
    ticketDetails: Object,
  },
  emits: ["ticketCancelled" ],
  setup(props, {emit}) {
    const ticket = ref(props.ticketDetails);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const ticketCancelReason = dropdownVariables.ticketCancelReasonDt;
    let {requiredValidation, errors} = useInputValidation();
    let ticketCancelIssue = ref("");

    const initialState = {
      companyId: ticket.value.company,
      ticketId: ticket.value._id,
      cancellableSeats: [...ticket.value.seatNumbers],
      cancellationCause: "",
    };
    const ticketCancelData = reactive({ ...initialState });

    const selectedLanguage = computed(() => store.getters.getLang);

    watch(()=> ticketCancelData.cancellationCause, () => {
      validateTicketCancel()
    })

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validateTicketCancel = () => {
      requiredValidation("ReasonToCancelIssue", ticketCancelData.cancellationCause);
      if (ticketCancelData.cancellationCause === 'Other') {
        requiredValidation("TicketCancelIssue", ticketCancelIssue.value);
      }
    }

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
     validateTicketCancel()
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const cancelDtTicket = () => {

      if (validate()){
        let ticketCancelPayload = { ...ticketCancelData };
        if (ticketCancelData.cancellationCause === "Other") {
          ticketCancelPayload = {
            ...ticketCancelData,
            cancellationCause: ticketCancelIssue.value,
          };
        }
        showLoader();
        store
            .dispatch("cancelDtTicket", ticketCancelPayload)
            .then(() => {
              hideLoader();
              emit("update:modelValue", false);
              store.commit('setCounterWiseSeatDetail', ticketCancelPayload.ticketId);
              emit('ticketCancelled')
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                cancelDtTicket();
              }
            });
      }

    };

    onBeforeMount(() => {});

    return {
      status,
      closeModal,
      cancelDtTicket,
      ticket,
      ticketCancelReason,
      ticketCancelIssue,
      ticketCancelData,
      selectedLanguage,
      t,
      locale,
      errors,

    };
  },
};
</script>

<style scoped></style>
