<template>
  <div>
    <div class="card mx-5 my-2">
      <div
        class="card-header"
        :id="'heading' + seatPlan[0]._id"
        data-toggle="collapse"
        :data-target="'#collapse' + seatPlan[0]._id"
        aria-expanded="true"
        :aria-controls="'collapse' + seatPlan[0]._id"
      >
        <div class="row my-1">
          <div class="col-md-2 bold">
            {{ seatPlan[0].name }}
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="col-md-6 text-center">
                Status:
                <span class="bold">{{
                  seatPlan[0].status === true ? "Active" : "Inactive"
                }}</span>
              </div>
              <div class="col-md-6 text-start">
                Seat Class:
                <span class="text-primary fw-bold">{{
                  seatPlan[0]?.seatClassId?.name || seatPlan[0].seatClass
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <button
              v-if="collapse"
              class="header_show_button font_detail float-end"
              @click="collapse = false"
            >
              <i class="fas fa-chevron-down"></i><span class="mx-1">Show</span>
            </button>
            <button
              v-else
              class="header_show_button font_detail float-end"
              @click="collapse = true"
            >
              <i class="fas fa-chevron-up"></i><span class="mx-1">Hide</span>
            </button>
            <button
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="header_edit_button font_detail float-end"
              @click="updateSeatClass()"
            >
              <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
            </button>
            <!-- <button
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="header_delete_button font_detail float-end"
              @click="deleteSeatClass()"
            >
              <i class="fas fa-trash"></i><span class="mx-1">Delete</span>
            </button> -->
          </div>
        </div>
      </div>
      <ul
        v-if="seatPlan[0]"
        :id="'collapse' + seatPlan[0]._id"
        :aria-labelledby="'heading' + seatPlan[0]._id"
        data-parent="#accordion"
        class="collapse list-group list-group-flush"
        :class="!collapse ? 'show' : ''"
      >
        <AddSeatRow
          v-if="hasPermission(Admin.companyDeployWrite)"
          :company="companyId"
          :seatPlanId="seatPlan[0]._id"
          :seatClassProps="seatPlan[0].seatClass"
          :seatClassId="seatPlan[0].seatClassId"
          @getSeatPlan="getSeatPlan"
        />
        <SeatList
          v-bind:seatList="seatPlan[0].seatList"
          v-bind:company="companyId"
          v-bind:seatPlanId="seatPlan[0]._id"
          @getSeatPlan="getSeatPlan"
        />
      </ul>
    </div>
    <div v-if="updateModal">
      <SeatPlanUpdateModal
        v-model="updateModal"
        v-bind:company="companyId"
        v-bind:seatClass="spId"
      />
    </div>
    <div v-if="deleteModal">
      <SeatPlanDeleteModal
        v-model="deleteModal"
        v-bind:company="companyId"
        v-bind:seatClass="spId"
      />
    </div>
  </div>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import { onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import SeatPlanUpdateModal from "./SeatPlanUpdateModal";
import SeatPlanDeleteModal from "./SeatPlanDeleteModal";
import AddSeatRow from "./AddSeatRow";
import SeatList from "./SeatList";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
// import { useRoute } from "vue-router";
import { reactive } from "@vue/reactivity";
const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "SeatClassCard",
  props: {
    seatPlanId: String,
    company: String,
  },
  components: {
    SeatPlanUpdateModal,
    SeatPlanDeleteModal,
    AddSeatRow,
    SeatList,
  },
  setup(props) {
    // const route = useRoute();
    let spId = ref(props.seatPlanId);
    let companyId = ref(props.company);
    let updateModal = ref(false);
    let deleteModal = ref(false);
    let collapse = ref(true);
    let seatPlan = ref({});
    const status = dropdownVariables.status;
    let selectedForBlocked = ref([]);
    let seatClassMain = reactive({
      Mixed: "Mixed",
    });

    // let pagination = reactive({
    //   page: 1,
    //   limit: 25,
    //   companyId: route.query.id,
    // });

    // const showLoader = inject("showLoader");
    // const hideLoader = inject("hideLoader");

    watch(
      () => updateModal.value,
      () => {
        if (updateModal.value === false) {
          getSeatPlan();
        }
      }
    );

    const getSeatPlan = () => {
      seatPlan.value = store.getters.getSeatPlan(spId.value);
    };
    const updateSeatClass = () => {
      updateModal.value = true;
    };
    const deleteSeatClass = () => {
      deleteModal.value = true;
    };

    // const getAllSeatClass = () => {
    //   showLoader();
    //   store
    //     .dispatch("getSeatClassByPage", pagination)
    //     .then(() => {
    //       let busClass = store.getters.getSeatClass;
    //       busClass.forEach((item) => {
    //         seatClassMain[item._id] = item.name;
    //       });
    //       hideLoader();
    //     })
    //     .catch((error) => {
    //       hideLoader();
    //       if (error.status === 401) {
    //         getAllSeatClass();
    //       }
    //     });
    // };

    onBeforeMount(() => {
      getSeatPlan();
      // getAllSeatClass();
    });

    return {
      status,
      seatPlan,
      spId,
      updateModal,
      companyId,
      deleteModal,
      collapse,
      Admin,
      hasPermission,
      updateSeatClass,
      deleteSeatClass,
      getSeatPlan,
      selectedForBlocked,
      seatClassMain,
    };
  },
};
</script>

<style scoped>
.header_edit_button {
  margin: 0 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #000;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_edit_button:hover,
.header_edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.header_delete_button {
  margin: 0 2px;
  border: 2px solid #ff072c;
  border-radius: 4px;
  background-color: #ff072c;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_delete_button:hover,
.header_delete_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.header_show_button {
  margin: 0 2px;
  border: 2px solid #15aabf;
  border-radius: 4px;
  background-color: #15aabf;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.header_show_button:hover,
.header_show_button:focus {
  border: 2px solid #15aabf;
  background-color: #fff;
  color: #15aabf;
  transition: 0.4s;
  outline: 0;
}

.bold {
  font-weight: bold;
}
</style>
