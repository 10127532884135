<template>
  <div id="hiddenDiv" class="d-none"></div>
  <CoachwiseTrip
      v-if="$store.getters.getSingleCompanyData?.allowCoachwiseTrip"
  />
  <div v-else-if="addUpdate">
    <TripsAddUpdateCopy
        :isCopy="copyActive"
        :pagination="pagination"
        v-bind:trip="tripId"
        @close="close"
        @getAllTrips="getAllTrips"
    />
  </div>
  <div v-else>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'from'"
              v-model="pagination.from"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:label="'From Stoppage'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'to'"
              v-model="pagination.to"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:label="'To Stoppage'"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'coach'"
              v-model="pagination.coach"
              v-bind:defaultOption="'--select coach--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getAllCoachListForFilterAdmin"
              v-bind:label="'Coach'"
          />
        </div>
        <div class="col-sm-6 col-md-4">
          <DateTimeRangePickerByDays v-model="date.range"/>
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'status'"
              v-model="pagination.status"
              v-bind:defaultValue="''"
              v-bind:dropdown="status"
              v-bind:label="'Status'"
          />
        </div>
      </template>
      <template v-if="hasPermission(Admin.tripBulkCancel) && $store.getters.getTotalTrips > 0 && pagination.status === 'ACTIVE'" v-slot:button2>
        <button class="edit_table_data font_detail me-4" @click="cancelTripBulk">
          <i class="fas fa-minus"></i><span class="mx-1 bold">Bulk Cancel</span>
        </button>
      </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div>
      <Table v-if="$store.getters.getTrips">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">TRIP TIME & DATE</td>
            <td class="font_detail">TRIP CODE</td>
            <td class="font_detail">ROUTE NAME</td>
            <td class="font_detail">COACH NAME</td>
            <td class="font_detail">FROM STOPPAGE</td>
            <td class="font_detail">TO STOPPAGE</td>
            <td class="font_detail">SEATPLAN NAME</td>
            <td class="font_detail">PLATFORM</td>
            <td class="font_detail fit-trip-info">TRIP INFO</td>
            <td class="font_detail">CREATED INFO</td>
            <td
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(trip, index) in $store.getters.getTrips" :key="trip._id">
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail bold">
              {{ dateFormat(trip.tripDateTime) }}
            </td>
            <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail">{{ trip.route.name }}</td>
            <td class="font_detail">{{ trip.coach.name }}</td>
            <td class="font_detail">
              <span
                  v-for="[key, value] of Object.entries(
                  $store.getters.getStoppageListForFilter
                )"
                  :key="key.index"
              >
                <span v-if="key === trip.route.fromStoppageId">
                  {{ value }}
                </span>
              </span>
            </td>
            <td class="font_detail">
              <span
                  v-for="[key, value] of Object.entries(
                  $store.getters.getStoppageListForFilter
                )"
                  :key="key.index"
              >
                <span v-if="key === trip.route.toStoppageId">
                  {{ value }}
                </span>
              </span>
            </td>
            <td class="font_detail">{{ trip?.seatPlan?.name }}</td>
            <td class="font_detail">
              <span
                  :class=" trip.digitalTicketing ? 'bg-online' : 'bg-secondary'"
                  class="badge"
              >
                {{ trip.digitalTicketing ? 'Online' : 'Offline' }}
              </span>
            </td>
            <td class="fit-trip-info">
              <span v-if="trip.status === 'ACTIVE'">Status: </span>
              <span
                  :class="'bg-' + dropdownVariables.tripStatus[trip.status].badge"
                  class="badge">
                {{
                  dropdownVariables.tripStatus[trip.status].value ===
                  "Cancelled"
                      ? !trip.cancelledBy
                          ? 'Cancelled' : `${trip?.cancellationCause === 'Bulk Cancel' ? 'Bulk' : ''} ${dropdownVariables.tripStatus[trip.status].value} by ${trip.cancelledBy.name
                      }`
                      : dropdownVariables.tripStatus[trip.status].value
                }}
              </span>

              <br v-if="dropdownVariables.tripStatus[trip.status].value ===
                  'Cancelled'"/>
              <small v-if="dropdownVariables.tripStatus[trip.status].value ===
                  'Cancelled'" class="text-muted">{{ dateFormat(trip.updatedAt, 6) }}</small>
              <br/>
              <span class="mt-2 my-1">Auto Renewal: </span>
              <span
                  :class=" trip.autoRenewal ? 'bg-success' : 'bg-secondary'"
                  class="badge my-1"
              >
                {{ trip.autoRenewal ? 'Yes' : 'No' }}
              </span>

              <span class=" ms-1">Quota: </span>
              <span
                  :class=" trip.quota ? 'bg-success' : 'bg-secondary'"
                  class="badge"
              >
                {{ trip.quota ? 'Active' : 'Inactive' }}
              </span>
            </td>
            <td class="font_detail">{{ trip?.createdBy?.name }} <br/> <small
                class="text-muted">{{ dateFormat(trip.createdAt, 6) }}</small></td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                  class="edit_table_data font_detail edit_table_button"
                  @click="updateTrip(trip._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
              <button
                  v-if="trip.status === 'ACTIVE'"
                  class="edit_table_data font_detail edit_table_button_update"
                  @click="cancel(trip)"
              >
                <i class="fas fa-trash"></i><span class="mx-1">Cancel</span>
              </button>
              <button
                  v-if="hasPermission(Admin.tripCopy)"
                  class="copy_table_data font_detail copy_table_button"
                  @click="copyTrip(trip._id)"
              >
                <i class="fas fa-copy"></i><span class="mx-1">Copy</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
              v-model="pagination.page"
              :options="options"
              :per-page="pagination.limit"
              :records="$store.getters.getTotalTrips"
              @paginate="getAllTrips"
          />
        </template>
      </Table>
    </div>
    <TripCancelModal
        v-if="activeCancelModal"
        :company="companyId"
        :trip="selectedTrip"
        @close="close"
        v-on:getAllTrip="getAllTrips"
    />
    <TripBulkCancelModal
        v-if="activeBulkCancelModal"
        :params="pagination"
        @close="close"
        v-on:getAllTrip="getAllTrips"
    />
  </div>
</template>

<script>
import moment from "moment";
import {reactive} from "@vue/reactivity";
import {computed, inject, onBeforeMount, ref, watch} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {useRoute} from "vue-router";
import TripsAddUpdateCopy from "@/views/Admin/CompanyDeploy/Children/Trips/TripsAddUpdateCopy";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CoachwiseTrip from "./CoachwiseTrip.vue";
import TripCancelModal from "@/views/Admin/CompanyDeploy/Children/Trips/TripCancelModal";
import TripBulkCancelModal from "@/views/Admin/CompanyDeploy/Children/Trips/TripBulkCancelModal";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Trips",
  components: {TripsAddUpdateCopy, CoachwiseTrip, TripCancelModal, TripBulkCancelModal},
  setup() {
    const route = useRoute();
    const addUpdate = ref(false);
    const copyActive = ref(false);
    const tripId = ref("");
    const companyId = ref(route.query.id)
    const selectedTrip = reactive({});
    const activeCancelModal = ref(false);
    const activeBulkCancelModal = ref(false);
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const fromDate = moment(new Date()).format("yyyy-MM-DD");
    const toDate = moment(new Date()).format("yyyy-MM-DD");
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
      from: "",
      to: "",
      coach: "",
      toDate: toDate,
      fromDate: fromDate,
      status: "ACTIVE",
    });

    let paginationNew = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
    });

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    watch(
        () => date.range.start,
        (value) => {
          pagination.fromDate = moment(value).format(
              "yyyy-MM-DD"
          );
        }
    );
    watch(
        () => date.range.end,
        (value) => {
          pagination.toDate = moment(value).format(
              "yyyy-MM-DD"
          );
        }
    );


    watch(
        () => pagination.status,
        () => {
          pagination.page = 1;
          getAllTrips();
        }
    );
    watch(
        () => pagination.from,
        () => {
          pagination.page = 1;
          getAllTrips();
        }
    );
    watch(
        () => pagination.to,
        () => {
          pagination.page = 1;
          getAllTrips();
        }
    );
    watch(
        () => pagination.fromDate,
        () => {
          pagination.page = 1;
          clickElement();
          getAllTrips();
        }
    );
    watch(
        () => pagination.toDate,
        () => {
          pagination.page = 1;
          clickElement();
          getAllTrips();
        }
    );
    watch(
        () => pagination.coach,
        () => {
          pagination.page = 1;
          getAllTrips();
        }
    );

    const clickElement = () => {
      let element = document.getElementById('hiddenDiv')
      let clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });

      element.dispatchEvent(clickEvent);
    }

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const close = () => {
      addUpdate.value = false;
      copyActive.value = false;
      activeCancelModal.value = false;
      activeBulkCancelModal.value = false;
    };
    const addModal = () => {
      tripId.value = "";
      addUpdate.value = true;
    };
    const updateTrip = (id) => {
      tripId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date, offSet = 0) => {
      return moment(String(date))
          .utcOffset(offSet, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const copyTrip = (id) => {
      tripId.value = id;
      addUpdate.value = true;
      copyActive.value = true;
    }

    const getAllRoutes = () => {
      showLoader();
      store
          .dispatch("getRoutesByPage", paginationNew)
          .then(() => {
            getStoppageList();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllRoutes();
            }
          });
    };
    const getAllTrips = () => {
      showLoader();
      store
          .dispatch("getTripsByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };
    const getAllStoppages = () => {
      showLoader();
      store
          .dispatch("getStoppagesByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllStoppages();
            }
          });
    };

    const getRouteListFilter = () => {
      store.dispatch("getRouteListFilter", {companyId: route.query.id}).then(() => {
        hideLoader()
      }).catch((error) => {
        hideLoader();
        if (error.status === 401) {
          getRouteListFilter();
        }
      });
    }
    const getStoppageList = () => {
      store
          .dispatch("getStoppageList", {companyId: pagination.companyId})
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getStoppageList();
            }
          });
    };
    const getAllCoach = () => {
      showLoader();
      store
          .dispatch("getAllCoachList", paginationNew)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            if (error.status === 401) {
              getAllCoach();
            }else {
              hideLoader()
            }
          });
    };

    const getAllSeatPlan = () => {
      store.dispatch("getAllSeatPlan", {companyId: route.query.id})
          .then(() => {
            hideLoader()
          })
          .catch(() => {
            hideLoader();
          })
    }

    const getAllRouteForTrips = () => {
      store
          .dispatch("getAllRouteForTrips", {companyId: route.query.id})
          .then(() => {
          })
          .catch(() => {
          });
    };

    const cancel = (trip) => {
      selectedTrip.value = trip
      activeCancelModal.value = true
    };

    const cancelTripBulk = () => {
      activeBulkCancelModal.value = true;
    }

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllRoutes();
        getAllRouteForTrips();
        getAllTrips();
        getAllCoach();
        getAllStoppages();
        getStoppageList();
        getRouteListFilter();
        getAllSeatPlan();
      }
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      tripId,
      Admin,
      cancel,
      hasPermission,
      getAllTrips,
      addModal,
      updateTrip,
      close,
      dateFormat,
      status,
      copyTrip,
      copyActive,
      activeCancelModal,
      selectedTrip,
      companyId,
      date,
      cancelTripBulk,
      activeBulkCancelModal,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.bg-online {
  background-color: #0b76b9;
}

.fit-trip-info{
  min-width: 260px;

}
</style>
