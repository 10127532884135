import DatePicker from "@/components/layouts/DateTime/DatePicker";
import TimePicker from "@/components/layouts/DateTime/TimePicker";
import Filter from "@/components/layouts/Filter/Filter";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import DatePickerFilter from "@/components/layouts/Filter/Inputs/DatePicker";
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DateTimeRangePickerByDays from "@/components/layouts/Filter/Inputs/DateTimeRangePickerByDays";
import TimePickerFilter from "@/components/layouts/Filter/Inputs/TimePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import Checkbox from "@/components/layouts/Inputs/Checkbox";
import Dropdown from "@/components/layouts/Inputs/Dropdown";
import Error from "@/components/layouts/Inputs/Error";
import Input from "@/components/layouts/Inputs/Input";
import TextArea from "@/components/layouts/Inputs/TextArea";
import ModalCheckCheck from "@/components/layouts/Modals/Inputs/CheckCheck";
import ModalCheckSwitch from "@/components/layouts/Modals/Inputs/CheckSwitch";
import ModalDropdown from "@/components/layouts/Modals/Inputs/Dropdown";
import ModalError from "@/components/layouts/Modals/Inputs/Error";
import ModalInput from "@/components/layouts/Modals/Inputs/Input";
import ModalMultiselect from "@/components/layouts/Modals/Inputs/MultiSelectDropdown";
import Modal from "@/components/layouts/Modals/Modal";
import TicketPrintPreview from "@/components/layouts/Print/TicketPrintPreview";
import TicketPrintPreviewForAgent from "@/components/layouts/Print/TicketPrintPreviewForAgent";
import TripReportPrintPreview from "@/components/layouts/Print/TripReportPrintPreview";
import TripReportPrintPreviewForAgent from "@/components/layouts/Print/TripReportPrintPreviewForAgent";
import UserReportPrintPreview from "@/components/layouts/Print/UserReportPrintPreview";
import UserReportPrintPreviewForAgent from "@/components/layouts/Print/UserReportPrintPreviewForAgent";
import Table from "@/components/layouts/Tables/Table";
import TableCard from "@/components/layouts/Tables/TableCard.vue";
import TableSimple from "@/components/layouts/Tables/TableSimple";
import TicketDropdownNew from "@/components/layouts/TicketInputNew/Dropdown";
import DropdownVerticalLabel from "@/components/layouts/TicketInputNew/DropdownVerticalLabel";
import TicketErrorNew from "@/components/layouts/TicketInputNew/Error";
import TicketInputNew from "@/components/layouts/TicketInputNew/Input";
import TicketInputPhoneNew from "@/components/layouts/TicketInputNew/InputPhone";
import TicketDropdown from "@/components/layouts/TicketInputs/Dropdown";
import TicketError from "@/components/layouts/TicketInputs/Error";
import TicketInput from "@/components/layouts/TicketInputs/Input";
import TextShow from "@/components/layouts/Inputs/TextShow";

export default {
    install: (app) => {
        app.component("Table", Table);
        app.component("TableCard", TableCard);
        app.component("TableSimple", TableSimple);
        app.component("Modal", Modal);
        app.component("ModalInput", ModalInput);
        app.component("TextArea", TextArea);
        app.component("ModalError", ModalError);
        app.component("ModalDropdown", ModalDropdown);
        app.component("ModalCheckSwitch", ModalCheckSwitch);
        app.component("ModalCheckCheck", ModalCheckCheck);
        app.component("TicketInput", TicketInput);
        app.component("TicketError", TicketError);
        app.component("TicketDropdown", TicketDropdown);
        app.component("TicketInputNew", TicketInputNew);
        app.component("TicketInputPhoneNew", TicketInputPhoneNew);
        app.component("TicketErrorNew", TicketErrorNew);
        app.component("TicketDropdownNew", TicketDropdownNew);
        app.component("DropdownVerticalLabel", DropdownVerticalLabel);
        app.component("Checkbox", Checkbox);
        app.component("Input", Input);
        app.component("Error", Error);
        app.component("Dropdown", Dropdown);
        app.component("FilterTab", FilterTab);
        app.component("Filter", Filter);
        app.component("ModalMultiselect", ModalMultiselect);
        app.component("DropdownFilter", DropdownFilter);
        app.component("DatePickerFilter", DatePickerFilter);
        app.component("DatePicker", DatePicker);
        app.component("TimePicker", TimePicker);
        app.component("TimePickerFilter", TimePickerFilter)
        app.component("DateTimeRangePicker", DateTimeRangePicker);
        app.component("DateTimeRangePickerByDays", DateTimeRangePickerByDays);
        app.component("TicketPrintPreview", TicketPrintPreview);
        app.component("TripReportPrintPreview", TripReportPrintPreview);
        app.component("TicketPrintPreviewForAgent", TicketPrintPreviewForAgent);
        app.component("UserReportPrintPreview", UserReportPrintPreview);
        app.component(
            "TripReportPrintPreviewForAgent",
            TripReportPrintPreviewForAgent
        );
        app.component(
            "UserReportPrintPreviewForAgent",
            UserReportPrintPreviewForAgent
        );
        app.component("TextShow", TextShow);
    },
};
