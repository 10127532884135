import apiResponse from "@/modules/apiResponse";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    reportsAgentIntercity: {},
    ticketHistoryAgentIntercity:{},
    reports: {},
};
const getters = {
    getAgentReport: (state) => state.reports,
    getCountermanReportAgentIntercity: (state) => state.reportsAgentIntercity,
    getTicketsHistoryAgentIntercity: (state) => state.ticketHistoryAgentIntercity.docs,
    getTicketsHistoryTotalDocsAgentIntercity: (state) => state.ticketHistoryAgentIntercity.totalDocs,
};
const actions = {
    async ticketHistoryAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TICKETS_HISTORY_AGENT_INTERCITY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTickesHistoryAgentIntercity", data.tickets)
                            resolve(data.tickets)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllCountermanReportAgentIntercity({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllCounterManReportAgentIntercity", data)
                            resolve(data.ship)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanTripReportAgentIntercity(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_COUNTERMAN_TRIPWISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllAgentReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_AGENT_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllAgentReport", data)
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAgentTripReport(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_AGENT_TRIP_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllCounterManReportAgentIntercity: (state, reports) => (state.reportsAgentIntercity = reports),
    setTickesHistoryAgentIntercity: (state, tickets) => (state.ticketHistoryAgentIntercity = tickets),
    setAllAgentReport: (state, reports) => (state.reports = reports),

};

export default {
    state,
    getters,
    actions,
    mutations
};