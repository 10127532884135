<template>
  <FilterTab>
    <template v-slot:title><h6>Ticket History</h6></template>
    <template v-slot:body>
      <div class="col-sm-6 col-md-2">
        <DropdownFilter
          v-bind:id="'seatType'"
          v-bind:label="'Seat Type'"
          v-model="pagination.ticketType"
          v-bind:dropdown="dropdownVariables.seatTypes"
        />
      </div>
    </template>
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i><span class="mx-1 bold">Go Back</span>
      </button>
    </template>
  </FilterTab>
  <div>
    <Table v-if="$store.getters.getTicketsHistoryAgentIntercity">
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">COACH</td>
          <td class="font_detail">STOPPAGE</td>
          <td class="font_detail">ISSUED BY</td>
          <td class="font_detail">COUNTER NAME</td>
          <td class="font_detail">TICKET TYPE</td>
          <td class="font_detail">SEAT NUMBERS</td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            SEAT CLASS
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            PNR CODE
          </td>
          <td class="font_detail" v-if="hasPermission(Agent.ticketCancel)">
            TICKET
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(ticket, index) in $store.getters
            .getTicketsHistoryAgentIntercity"
          :key="ticket._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ ticket?.coach?.name }}</td>
          <td class="font_detail">
            {{ ticket?.fromStoppage?.name }} - {{ ticket?.toStoppage?.name }}
          </td>
          <td class="font_detail">{{ ticket?.issuedBy?.name }}</td>
          <td class="font_detail">{{ ticket?.issuedBy?.counterId?.name }}</td>
          <td class="font_detail">{{ ticket?.ticketType }}</td>
          <td class="font_detail">{{ ticket?.seatNumbers.toString() }}</td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            <span v-if="ticket?.seatClass !== ''">
              {{ ticket?.seatClass }}
            </span>
            <span v-else> Mixed </span>
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            {{ ticket?.pnrCode }}
          </td>
          <td class="font_detail bold" v-if="hasPermission(Agent.ticketCancel)">
            <button
              class="btn btn-warning btn-sm"
              @click="ticketCancel(ticket, ticket.ticketType)"
            >
              <i></i><span class="mx-1">Cancel</span>
            </button>
          </td>

          <!-- <td class="font_detail">
            <span v-if="trip.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td> -->

          <!-- <td
            class="font_detail bold"
            v-if="hasPermission(Agent.ticketCancel)"
          >
            <button class="btn btn-warning btn-sm" @click="Ticket(trip._id)">
              <i></i><span class="mx-1">Ticket History</span>
            </button>
          </td> -->
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTicketsHistoryTotalDocsAgentIntercity"
          :per-page="pagination.limit"
          :options="options"
        />
      </template>
    </Table>
  </div>
  <TicketCancelModal
    @getAllTickets="getAllTickets"
    v-if="cancelTicketModal"
    v-model="cancelTicketModal"
    :ticketIdStore="ticketData"
    :ticketTotalSeats="cancellableSeats"
    :ticketTypeForProps="ticketTypeForProps"
  />
  <!-- </div> -->
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, watch, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TicketCancelModal from "./TicketCancelModal.vue";
export default {
  name: "Trips",
  props: {
    tripId: String,
    modelValue: Boolean,
  },
  components: {
    TicketCancelModal,
  },
  setup(props, { emit }) {
    const { hasPermission } = permission();
    const { Agent } = roles();
    let cancelTicketModal = ref(false);
    let ticketData = ref({});
    let cancellableSeats = ref([]);
    let ticketTypeForProps = ref("");
    // moment(new Date()).format("yyyy-MM-DD")
    let pagination = reactive({
      tripId: props.tripId,
      page: 1,
      limit: 25,
      ticketType: "SEAT",
    });
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const options = {
      texts: {
        count: "",
      },
    };
    // const status = computed(() => {
    //   let allTripStatus = {};
    //   for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
    //     allTripStatus[key] = value.value;
    //   }
    //   return allTripStatus;
    // });
    watch(
      () => pagination.ticketType,
      (value) => {
        pagination.ticketType = value;
        getAllTickets();
      }
    );
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllTickets = () => {
      showLoader();
      store
        .dispatch("ticketHistoryAgentIntercity", pagination)
        .then(() => {
          hideLoader();
        })
        .catch(() => {
          hideLoader();
        });
    };
    const goBack = () => {
      emit("update:modelValue", false);
    };
    const ticketCancel = (ticket, ticketType) => {
      ticketTypeForProps.value = ticketType;
      ticketData.value = ticket._id;
      cancellableSeats.value = ticket.seatNumbers;
      cancelTicketModal.value = true;
    };
    onBeforeMount(() => {
      getAllTickets();
    });
    return {
      pagination,
      dropdownVariables,
      close,
      dateFormat,
      // status,
      options,
      hasPermission,
      Agent,
      goBack,
      ticketCancel,
      getAllTickets,
      cancelTicketModal,
      cancellableSeats,
      ticketData,
      ticketTypeForProps,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.seat {
  background-color: yellow;
  margin: 3px;
  border: none;
  width: 60px;
  padding: 5px;
}
.seat:hover {
  background-color: red;
  color: white;
}
</style>
